import { Col, Input, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isConPlatform, isPINPlatform } from '../../utils';
import { IPageRenderListProps } from '../ContentPageRender';
import { comboRender, ContentPageEnum } from '../shared';
import HeaderButtons from './HeaderButtons';

type FormProps = FormComponentProps & IPageRenderListProps;
const { HOME, HOME_PUBLIC } = ContentPageEnum;

const ContentPageForm: FC<FormProps> = ({
  combos,
  editComponent,
  form,
  formHasChanged,
  handleChangeField,
  handleResetPage,
  handleSaveRecord,
  isLoading,
  params,
  selectedRow,
  setLoading,
  sortingComponents,
  values,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;
  const { componentId } = params;
  // ? Roles Select would be only visible to config the 'private home' type
  const [isRoleVisible, setIsRoleVisible] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    let isPrivatePage = false;

    form.resetFields(['idSpecialty', 'slug']);

    if (values?.publicPage !== undefined)
      if (
        typeof values?.publicPage === 'string' &&
        values?.publicPage === 'false'
      ) {
        isPrivatePage = true;
      } else {
        isPrivatePage = !values?.publicPage;
      }

    if (values?.idSpecialty && !isPrivatePage) {
      delete values.idSpecialty;
    }
    if(isPINPlatform()) {
      setIsRoleVisible(isPrivatePage);
    } else {
      // ? Roles Select would be only visible to config the 'private home' type
      if (Number(values?.type) === ContentPageEnum.HOME && isPrivatePage) {
        setIsRoleVisible(true);
      } else {
        setIsRoleVisible(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.type, values?.publicPage]);

  useEffect(() => {
    if (!values) {
      return;
    }
    const { footer, header, publicPage, type, idRoleType } =
      form.getFieldsValue();

    const {
      footer: _footer,
      header: _header,
      publicPage: _publicPage,
      type: _type,
      idSpecialty: _idSpecialty,
      idRoleType: _idRoleType,
    } = values;
    // Set default value to match fields value
    if (_publicPage === undefined) values.publicPage = '';
    if (_type === undefined) values.type = '';

    handleChangeField({
      id: 'header',
      type: 'checkSelect',
      value: header === 'true',
    });
    handleChangeField({
      id: 'footer',
      type: 'checkSelect',
      value: footer === 'true',
    });
    if (footer !== _footer?.toString() || header !== _header?.toString()) {
      form.setFieldsValue({
        ...values,
        footer,
        header,
        publicPage,
        type,
        idRoleType: idRoleType ? idRoleType : undefined,
        roleTypeKey: _idRoleType ? _idRoleType.idRoleType : undefined,
        idSpecialty: _idSpecialty ? _idSpecialty.toString() : undefined,
      });
    }
  });

  const renderFormItem = (
    key: string,
    component: JSX.Element,
    boolean?: boolean,
  ) => {
    let initialValue;
    let isMandatory = true;
    if (key === 'idRoleType') {
      initialValue =
        values && values[key]
          ? values.idRoleType.idRoleType.toString()
          : formatMessage({ id: 'contentPage.idRoleType' });
      isMandatory = false;
    } else if (key === 'roles') {
      initialValue = values && values[key]
        ? values[key].map((role: any) => {
          return role.idRoleType.toString();
        }) : [];
      isMandatory = false;
    }
    else if (key === 'idSpecialty') {
      initialValue = values && values[key] ? values[key].toString() : undefined;
      isMandatory = false;
    } else if (key === 'publicPage') {
      initialValue =
        values && typeof values[key] === 'boolean'
          ? values[key].toString()
          : '';
    } else if (boolean) {
      initialValue =
        values && typeof values[key] === 'boolean'
          ? values[key].toString()
          : 'true';
    } else {
      initialValue = values && values[key] ? values[key].toString() : '';
    }

    return (
      <Col xs={24} md={12} lg={6} xl={6}>
        <Form.Item label={formatMessage({ id: `contentPage.${key}` })}>
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [{ required: isMandatory }],
          })(component)}
        </Form.Item>
      </Col>
    );
  };

  const isSelectorDisabled = (key: string): boolean => {
    const { publicPage } = form.getFieldsValue();

    if (isConPlatform()) {
      switch (key) {
        case 'idRoleType':
          // ? Role select disabled except for the 'Home' type
          if (Number(values?.type) !== ContentPageEnum.HOME) return true;

          return !(publicPage === 'false');
        case 'idSpecialty':
          return values?.type !== ContentPageEnum.HOME;
        default:
          break;
      }
    }
    // ! Following code is only for PIN:
    // ! Select for Role is not yet rendered so the key would be always 'idSpecialty'
    // ! Comment this line to can select specialty in all of option of type when create/edit static pages
    // if (type && type.toString() !== ContentPageEnum.HOME.toString()) return true;
    if (publicPage) {
      const isPublicPage = publicPage.toString() === 'true';
      return isPublicPage;
    }
    return false;
  };

  return (
    <Form className="pageForm">
      <HeaderButtons
        {...{
          buttonDisabled: sortingComponents,
          form,
          formHasChanged,
          handleResetPage,
          handleSaveRecord,
          isLoading,
          setLoading,
          selectedRow,
        }}
      />
      <Row type="flex" gutter={[24, 0]} className="pageForm__container">
        {renderFormItem(
          'name',
          <Input
            disabled={editComponent}
            style={{ clear: 'both' }}
            size="large"
            placeholder={formatMessage({
              id: 'contentPage.name.placeholder',
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeField({
                id: 'name',
                type: 'text',
                value: e.target.value,
              })
            }
          />,
        )}
        {renderFormItem(
          'slug',
          <Input
            disabled={
              [HOME, HOME_PUBLIC].includes(Number(values?.type)) ||
              editComponent
            }
            style={{ clear: 'both' }}
            size="large"
            placeholder={formatMessage({
              id: 'contentPage.slug.placeholder',
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeField({
                id: 'slug',
                type: 'text',
                value: e.target.value,
              })
            }
          />,
        )}
        {renderFormItem(
          'header',
          <Select
            optionFilterProp="children"
            style={{ clear: 'both' }}
            size="large"
            disabled={editComponent}
            onChange={(value: string) => {
              handleChangeField({
                id: 'header',
                value: value === 'true',
                type: 'checkSelect',
              });
            }}
            allowClear={false}>
            <Select.Option key="header" value="true">
              {formatMessage({ id: 'checkbox.true' })}
            </Select.Option>
            <Select.Option key="header" value="false">
              {formatMessage({ id: 'checkbox.false' })}
            </Select.Option>
          </Select>,
          true,
        )}
        {renderFormItem(
          'footer',
          <Select
            optionFilterProp="children"
            style={{ clear: 'both' }}
            size="large"
            disabled={editComponent}
            onChange={(value: string) => {
              handleChangeField({
                id: 'footer',
                value: value === 'true',
                type: 'checkSelect',
              });
            }}
            allowClear={false}>
            <Select.Option key="footer" value="true">
              {formatMessage({ id: 'checkbox.true' })}
            </Select.Option>
            <Select.Option key="footer" value="false">
              {formatMessage({ id: 'checkbox.false' })}
            </Select.Option>
          </Select>,
          true,
        )}
        {renderFormItem(
          'type',
          <Select
            showSearch
            optionFilterProp="children"
            // allowClear={!field.hasOwnProperty('initialValue')}
            dropdownMatchSelectWidth={false}
            allowClear
            size="large"
            disabled={
              (values &&
                values.idContentPage !== undefined &&
                values.idContentPage !== null) ||
              editComponent
            }
            placeholder={formatMessage({ id: 'contentPage.type.placeholder' })}
            notFoundContent={formatMessage({
              id: 'combo.data.notfound',
            })}
            filterOption={(input: any, option: any) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value: any, data: any) => {
              handleChangeField({
                type: 'combo',
                id: 'type',
                value:
                  value >= 0 || typeof value === 'string' ? data.key : null,
              });
            }}>
            {comboRender(
              { comboId: 'contentPageType', key: 'type' },
              combos,
              componentId,
            )}
          </Select>,
        )}
        {renderFormItem(
          'publicPage',
          <Select
            optionFilterProp="children"
            style={{ clear: 'both' }}
            size="large"
            disabled={
              values?.idContentPage !== undefined &&
              values?.idContentPage !== null
            }
            onChange={(value: number) =>
              handleChangeField({
                id: 'publicPage',
                value,
                type: 'checkSelect',
              })
            }
            allowClear={false}>
            <Select.Option key="publicPage" value="true">
              {formatMessage({ id: 'checkbox.true' })}
            </Select.Option>
            <Select.Option key="publicPage" value="false">
              {formatMessage({ id: 'checkbox.false' })}
            </Select.Option>
          </Select>,
        )}
        {isConPlatform() &&
          isRoleVisible &&
          renderFormItem(
            'idRoleType',
            <Select
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              size="large"
              disabled={isSelectorDisabled('idRoleType')}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: 'roleTypeKey',
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}>
              {comboRender(
                { comboId: 'roleType', key: 'roleTypeKey' },
                combos,
                componentId,
              )}
            </Select>,
          )}
        {!isConPlatform() &&
          renderFormItem(
            'idSpecialty',
            <Select
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              allowClear
              size="large"
              disabled={isSelectorDisabled('idSpecialty')}
              placeholder={formatMessage({
                id: 'contentPage.idSpecialty.placeholder',
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: 'idSpecialty',
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}>
              {comboRender(
                { comboId: 'specialty', key: 'idSpecialty' },
                combos,
                componentId,
              )}
            </Select>,
          )}
        {isPINPlatform() && isRoleVisible &&
          renderFormItem(
            'roles',
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              allowClear
              size="large"
              disabled={isSelectorDisabled('roles')}
              placeholder={formatMessage({
                id: 'contentPage.roles.placeholder',
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: []) => {
                handleChangeField({
                  type: 'combo',
                  id: 'roles',
                  value: value,
                  multiSelectId: 'idRoleType',
                });
              }}>
              {comboRender(
                { comboId: 'roleType', key: 'roleTypeKey' },
                combos,
                componentId,
              )}
            </Select>,
          )
        }
      </Row>
    </Form>
  );
};

export default Form.create<FormProps>({ name: 'content_page_form' })(
  ContentPageForm,
);
