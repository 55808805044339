import { Button, Modal, notification, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import {
  ResourceTypeEnum,
  UserResourceStatusEnum,
} from '../../challenge/Enums';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export const checkExtension = (url: string) => {
  const extension = url?.split('.').pop();

  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'image';
    case 'mp4':
    case 'mpg':
    case 'ogg':
    case 'webm':
    case 'wmv':
      return 'video';
    default:
      return null;
  }
};

const statusResourceCodeToText = (statusCode: UserResourceStatusEnum) => {
  switch (statusCode) {
    case UserResourceStatusEnum.PENDING:
      return 'Pending';
    case UserResourceStatusEnum.VALIDATED:
      return 'Validated';
    case UserResourceStatusEnum.DENY:
      return 'Denied';
  }
};

export default function AssignPointsButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [extraDetails, setExtraDetails] = useState<any>(null);
  const [loadingExtraDetails, setLoadingExtraDetails] =
    useState<boolean>(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  const { statusResource } = selectedRow;

  let buttonsDisabled =
    isEmpty(selectedRow) ||
    ![UserResourceStatusEnum.PENDING].includes(statusResource);

  const handleValidate = async ({
    newStatusResource,
  }: {
    newStatusResource: number;
  }) => {
    let response;

    try {
      switch (selectedRow.idResource.idResourceType.idResourceType) {
        case ResourceTypeEnum.SELL_OUT:
          response = await api.putDataCall({
            dataPath: apiPaths.CALL.ASSIGN_POINTS_SELL_OUT,
            data: {
              userResource: {
                idUser: selectedRow.idUser.idUser,
                idUserResource: selectedRow.idUserResource,
                idResource: selectedRow.idResource.idResource,
                statusResource: newStatusResource,
              },
              idChallenge: selectedRow.idResource.idChallenge.idChallenge,
            },
            callConfig: {},
          });
          break;
        default:
          // ResourceTypeEnum.CHALLENGE_VISIBILITY
          response = await api.putDataCallById({
            dataPath: apiPaths.CALL.ASSIGN_POINTS,
            id: selectedRow.idResource.idResource,
            data: {
              idUser: selectedRow.idUser.idUser,
              statusResource: newStatusResource,
            },
            callConfig: {},
          });
          break;
      }
      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'assign-points.notification-title',
          }),
          description: formatMessage({
            id: 'assign-points.notification-description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      buttonsDisabled = true;
      selectedRow.statusResource = newStatusResource;
      await tableProps.handleReloadData();
      setShowDetailsModal(false);
    }
  };

  const newShow = async () => {
    const resourceType = selectedRow.idResource.idResourceType.idResourceType;

    if (ResourceTypeEnum.SELL_OUT === resourceType) {
      setLoadingExtraDetails(true);

      const selectedRange = await api.getDataCall({
        dataPath: apiPaths.CALL.GET_SELL_OUT_RANGES,
        callConfig: {
          params: {
            idChallenge: selectedRow?.idResource?.idChallenge?.idChallenge,
            idResource: selectedRow?.idResource?.idResource,
            idUser: selectedRow?.idUser?.idUser,
          },
        },
      });
      setExtraDetails(selectedRange?.data);
    }

    setShowDetailsModal(true);

    setLoadingExtraDetails(false);
  };

  const DetailsModalContent = () => {
    if (loadingExtraDetails)
      return <Spin className="assign-points-details-spinner" />;

    return (
      <>
        <b className="isdin-custom-modal__title">
          {`${
            extraDetails?.usedRange?.usersM2MList
              ? formatMessage({ id: 'assign-points.details.sell-out' })
              : formatMessage({
                  id: 'assign-points.details.challenge-visibility',
                })
          } -- ${statusResourceCodeToText(selectedRow.statusResource)}`}
        </b>
        <div>
          <b className="isdin-custom-modal__subtitle">
            {formatMessage({
              id: 'assign-points.details.reported-image',
            })}
            :
          </b>
          {checkExtension(selectedRow?.value) === 'image' ? (
            <img
              className="max-width-100"
              src={selectedRow?.value}
              alt="Report quantity"
              loading="lazy"
            />
          ) : (
            <video
              className="max-width-100"
              src={selectedRow?.value}
              controls={true}
            />
          )}
        </div>
        {extraDetails?.usedRange?.usersM2MList && (
          <>
            <div>
              <b className="isdin-custom-modal__subtitle">
                {formatMessage({
                  id: 'assign-points.details.reported-quantity',
                })}
                :
              </b>
              <p>{extraDetails?.usedRange.usersM2MList[0]?.value}</p>
            </div>
            <div>
              <b className="isdin-custom-modal__subtitle">
                {formatMessage({
                  id: 'assign-points.details.match-range',
                })}
                :
              </b>
              <p>{`${extraDetails?.usedRange.resourceDetailList[0]?.value} - ${extraDetails?.usedRange.resourceDetailList[1]?.value}`}</p>
            </div>
          </>
        )}
        <div>
          <b className="isdin-custom-modal__subtitle">
            {formatMessage({
              id: 'assign-points.details.actions',
            })}
            :
          </b>
          <div>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() =>
                handleValidate({
                  newStatusResource: UserResourceStatusEnum.VALIDATED,
                })
              }
              disabled={buttonsDisabled}
              type="primary">
              {formatMessage({ id: 'assign-points.button-validate' })}
              {` `}(
              {formatMessage(
                { id: 'page.challenge.{points}-points' },
                {
                  points:
                    extraDetails?.usedRange?.score?.points ||
                    selectedRow.idResource.score?.points ||
                    0,
                },
              )}
              )
            </Button>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() =>
                handleValidate({
                  newStatusResource: UserResourceStatusEnum.DENY,
                })
              }
              disabled={buttonsDisabled}
              type="primary">
              {formatMessage({ id: 'assign-points.button-deny' })}
            </Button>
            <Button
              disabled={isEmpty(selectedRow) || !selectedRow?.value}
              type="primary">
              <a
                href={selectedRow?.value}
                target={'_blank'}
                rel="noopener noreferrer">
                {formatMessage({ id: 'assign-points.button-download' })}
              </a>
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Button
        onClick={() => newShow()}
        disabled={isEmpty(selectedRow) || !selectedRow?.value}
        type="primary">
        {formatMessage({ id: 'assign-points.button-show' })}
      </Button>
      <Modal
        visible={showDetailsModal}
        onCancel={() => setShowDetailsModal(false)}
        footer={false}>
        <DetailsModalContent />
      </Modal>
    </>
  );
}
