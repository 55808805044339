import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ChallengeTypeEnum } from '../../challenge/Enums';
import { isTableProps } from '../../utils/propsUtils';
import { useIntl } from 'react-intl';
import { loadTimezoneName } from '../../utils/localStorage';
import moment from 'moment';


export default function TransmuteToButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const currentTimezone = loadTimezoneName();

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, isLoading } = tableProps.props;

    const detailEndDate = moment(selectedRow?.endDate)
      .tz(currentTimezone)
      .utc();

    const nowDate = moment().tz(currentTimezone).utc();

    const isCampaignExpired = moment(detailEndDate).isBefore(nowDate);

    let disabledButton = !isCampaignExpired || selectedRow?.transmuteTo;

    const handleTransmuteTo = async (
      idChallenge: number,
      newChallengeType: ChallengeTypeEnum,
    ) => {
      setLoading(true);
      try {
        const response = await api.postDataCallById({
          dataPath: apiPaths.CALL.TRANSMUTE_TO,
          id: idChallenge,
          callConfig: {},
          data: { newChallengeType },
        });

        if (response?.status === 201 && response.data) {
          await tableProps.props.setSelectedRow({
            componentId: tableProps.props.componentId,
            selectedRow: response.data.oldChallenge,
          });
          disabledButton = true;
          await tableProps.handleReloadData();
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    const openConfirmTransmutation = (
      idChallenge: number,
      newChallengeType: ChallengeTypeEnum,
    ) => {
      Modal.confirm({
        title: formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: formatMessage({ id: 'pop.accept' }),
        cancelText: formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          handleTransmuteTo(idChallenge, newChallengeType);
        },
        onCancel() {},
      });
    };

    const showButton = [ChallengeTypeEnum.CAMPAIGN].includes(
      selectedRow?.idChallengeType?.idChallengeType,
    );

    if (!showButton) return <React.Fragment />;

    return (
      <Button
        onClick={() =>
          openConfirmTransmutation(
            selectedRow.idChallenge,
            ChallengeTypeEnum.FORMATION,
          )
        }
        disabled={!selectedRow?.idChallenge || disabledButton}
        loading={isLoading || loading}
        type="primary">
        {formatMessage({ id: 'custom-buttons.transmute-to.training' })}
      </Button>
    );
  }
  return <></>;
}
