import config from '../../config';
import { dataType } from '../../export/DataExport';
import { IChallenge, IQuizAnswer, IResource } from '../ChallengeInterfaces';

const { COLUMN_DELIMITER, LINE_DELIMITER } = config.EXPORT;

interface Props {
  challenge: IChallenge;
  questions: IResource[];
  userAnswers: any[];
  sellInData: {
    productsData: any[];
    userData: { idUser: number; email: string };
    createDate: Date;
  }[];
  formatMessage: (id: { id: string }) => void;
}

const generateReport = (text: string, data: Props) => {
  let blob;
  const type = dataType.CSV.type;
  if (text !== null) blob = new Blob([text], { type: type });

  const link = document.createElement('a');
  if (link.download !== undefined && blob) {
    const fileName = `${data.challenge.name}_sellin_report.csv`;
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const trainingDataToCSV = (
  questions: IResource[],
  userAnswers: any[],
  formatMessage: (id: { id: string }) => void,
) => {
  if (!questions?.length) return null;

  let result = '';
  const answerColumns: { id: string; title: string }[] = [];

  const columns = [
    {
      id: 'idUser',
      title: formatMessage({
        id: 'reports.basics.idUser',
      }),
    },
    {
      id: 'email',
      title: formatMessage({
        id: 'reports.basics.email',
      }),
    },
  ];

  questions
    .sort((a, b) => a.order - b.order)
    .forEach((question) => {
      answerColumns.push({
        id: question.idResource.toString(),
        title: question.name,
      });
    });

  const docColumns = [...columns, ...answerColumns];

  docColumns.forEach((column) => (result += column.title + COLUMN_DELIMITER));

  result += LINE_DELIMITER;

  userAnswers?.forEach((user) => {
    result += user.idUser + COLUMN_DELIMITER;
    result += user.email + COLUMN_DELIMITER;

    let groupedByQuestionAnswers: any = {}; // A Question can have multiple answers if its a multi-answer question.

    user.resourceM2MList?.forEach((answer: IQuizAnswer) => {
      if (!groupedByQuestionAnswers[answer.idResource.indResource]) {
        groupedByQuestionAnswers[answer.idResource.indResource] = [];
      }
      groupedByQuestionAnswers[answer.idResource.indResource].push(answer);
    });

    Object.values(groupedByQuestionAnswers).forEach(
      (answer: any | IQuizAnswer[]) => {
        const answersValues = answer.map(
          (groupedAnswers: IQuizAnswer) => groupedAnswers.idResource.name,
        );
        result += answersValues.join(' | ') + COLUMN_DELIMITER;
      },
    );
    result += LINE_DELIMITER;
  });

  return result;
};

export function generateTrainingReport(data: Props) {
  const text = trainingDataToCSV(
    data.questions,
    data.userAnswers,
    data.formatMessage,
  );
  if (text) generateReport(text, data);
}

const sellInDataToCSV = (
  sellInData: {
    productsData: any[];
    userData: { idUser: number; email: string };
    createDate: Date;
  }[],
  formatMessage: (id: { id: string }) => void,
) => {
  if (!sellInData?.length) return null;

  let result = '';

  const columns = [
    {
      id: 'idUser',
      title: formatMessage({
        id: 'reports.basics.idUser',
      }),
    },
    {
      id: 'email',
      title: formatMessage({
        id: 'reports.basics.email',
      }),
    },
    {
      id: 'date',
      title: formatMessage({
        id: 'reports.sellin.order-date',
      }),
    },
  ];

  const productColumns: {
    id: number;
    title: string;
  }[] = [];

  let allProducts: { idProduct: number; name: string; pvl: number }[] = [];

  sellInData.forEach(
    (_sellInData) => (allProducts = [..._sellInData?.productsData]),
  );

  allProducts?.forEach((product) => {
    productColumns.push({
      id: product.idProduct,
      title: `${product.idProduct} | ${product.name} | PVL: ${product.pvl}`,
    });
  });

  const docColumns = [...columns, ...productColumns];

  docColumns.forEach((column) => (result += column.title + COLUMN_DELIMITER));

  result += LINE_DELIMITER;

  sellInData?.forEach((_sellInData) => {
    result += _sellInData.userData.idUser + COLUMN_DELIMITER;
    result += _sellInData.userData.email + COLUMN_DELIMITER;
    result += _sellInData.createDate + COLUMN_DELIMITER;
    _sellInData.productsData?.forEach((product) => {
      result += product.quantity + COLUMN_DELIMITER;
    });
    result += LINE_DELIMITER;
  });

  return result;
};

export function generateSellInReport(data: Props) {
  const text = sellInDataToCSV(data.sellInData, data.formatMessage);
  if (text) generateReport(text, data);
}
