import React, { useState } from 'react';
import { Button, Modal, Row, notification, List } from 'antd';
import { isEmpty } from 'lodash';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { useIntl } from 'react-intl';
import AddCenterTable from './AddCenterTable';
import './AddCenter.css';
import AddCenterForm from './AddCenterForm';
import { ComboData } from '../../combos/ComboInterfaces';
import { isTableProps } from '../../utils/propsUtils';

export default function AssignUsersButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const [centerList, setCenterList] = useState<{ [key: string]: any }[] | null>(
    null,
  );
  const [center, setCenter] = useState<{ [key: string]: any } | null>(null);
  const [centerCombo, setCenterCombo] = useState<ComboData[]>([]);
  const [rolesCombo, setRolesCombo] = useState<ComboData[]>([]);

  const intl = useIntl();

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow } = tableProps.props;

    const handleCloseModal = () => {
      if (center)
        Modal.confirm({
          title: intl.formatMessage({
            id: 'pop.title.changes-will-not-be-saved-are-you-sure',
          }),
          okText: intl.formatMessage({ id: 'pop.accept' }),
          cancelText: intl.formatMessage({ id: 'pop.cancel' }),
          maskClosable: true,
          onOk() {
            setCenterList(null);
            setCenter(null);
            setModalVisible(false);
          },
          onCancel() {},
        });
      else setModalVisible(false);
    };

    const handleEdit = (record: any) => {
      if (center === null) {
        setCenter(record);
      } else {
        // TODO: This modal configuration is used a tons, ¿maybe a FC?
        Modal.confirm({
          title: intl.formatMessage({
            id: 'pop.title.changes-will-not-be-saved-are-you-sure',
          }),
          okText: intl.formatMessage({ id: 'pop.accept' }),
          cancelText: intl.formatMessage({ id: 'pop.cancel' }),
          maskClosable: true,
          onOk() {
            setCenter(record);
          },
          onCancel() {},
        });
      }
    };

    const handleAddCenter = () => {
      setCenter({ idUser: { idUser: selectedRow.idUser } });
    };

    const handleSave = async () => {
      const idCenter = center!.idCenter;
      const idRole = center!.idRole;

      if (idCenter && idRole) {
        const userCenters = center!.idUserCenter
          ? await api.putDataCall({
              dataPath: apiPaths.CALL.USERSCENTER,
              data: { ...center },
              callConfig: {},
            })
          : await api.postDataCall({
              dataPath: apiPaths.CALL.USERSCENTER,
              data: { ...center },
              callConfig: {},
            });
        if (userCenters.status === 200) {
          setCenterList(userCenters.data);
          setCenter(null);
        } else {
          notification.warning({ message: 'Cannot update information' });
        }
      } else {
        const dataError: JSX.Element[] = [];

        if (!idRole) {
          dataError.push(<div>· Roles</div>);
        }
        if (!idCenter) {
          dataError.push(<div>· Center</div>);
        }

        notification.error({
          message: intl.formatMessage({
            id: 'alert.title.required',
          }),
          duration: 0,
          description: (
            <List
              size="small"
              renderItem={(item: JSX.Element) => <List.Item>{item}</List.Item>}
              dataSource={dataError}
            />
          ),
        });
      }
    };

    const handleEditValues = ({ id, value }: { id: string; value: any }) => {
      let newCenter: { [key: string]: any } = { ...center };

      if (!value && value !== 'false') newCenter[id] = undefined;
      else {
        newCenter =
          id === 'status'
            ? { ...newCenter, status: value }
            : { ...newCenter, [id]: { [id]: value } };
      }

      setCenter(newCenter);
    };

    const handleCancel = () => {
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setCenter(null);
        },
        onCancel() {},
      });
    };

    const AddCenterButton = (
      <Modal
        visible={modalVisible}
        className="assignModal"
        onCancel={handleCloseModal}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'60%'}>
        <Row className="assignCenter">
          <Row className="assignCenter__title">USER CENTERS</Row>
          <Row className="assignCenter__table">
            <AddCenterTable
              {...{ dataSource: centerList ? centerList : [], handleEdit }}
            />
          </Row>
          <Row
            type="flex"
            justify="end"
            className={
              centerList && centerList.length
                ? 'assignCenter__actionButtons'
                : 'assignCenter__actionButtons--noData'
            }>
            {center ? (
              <>
                <Button className="cancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className="saveButton"
                  type="primary"
                  onClick={handleSave}>
                  Save
                </Button>
              </>
            ) : (
              <Button
                className="addButton"
                type="primary"
                onClick={handleAddCenter}>
                Add Center
              </Button>
            )}
          </Row>
          <Row className="assignCenter__select">
            {center && (
              <AddCenterForm
                {...{ center, centerCombo, rolesCombo, handleEditValues }}
              />
            )}
          </Row>
        </Row>
      </Modal>
    );

    const handleOpenModal = async (selectedRow: IRow) => {
      const userCenters = await api.getDataCallById({
        dataPath: apiPaths.CALL.USERSCENTER,
        registerId: selectedRow.idUser,
        callConfig: {},
      });
      //fetch center
      const centerCombos = await api.getCombo({ id: 'center' });

      //fetch roles
      const rolesCombos = await api.getCombo({ id: 'roles' });

      if (
        userCenters.status === 200 &&
        centerCombos.status === 200 &&
        rolesCombos.status === 200
      ) {
        setCenterList(userCenters.data);
        setCenterCombo(centerCombos.data);
        setRolesCombo(rolesCombos.data);
        setModalVisible(true);
      } else notification.warning({ message: 'Cannot get user centers' });
    };

    return (
      <>
        <Button
          style={{ marginRight: '17px' }}
          onClick={() => handleOpenModal(selectedRow)}
          disabled={isEmpty(selectedRow)}
          type="primary">
          Centers
        </Button>
        {AddCenterButton}
      </>
    );
  }
  return <></>;
}
