import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isPINPlatform } from '../utils';

const ResourceTypeDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'resourceTyperesourceTypeSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                // {
                //   key: 'idResourceType',
                //   yPosition: 1,
                //   xPosition: 1,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //   key: 'name',
                //   yPosition: 1,
                //   xPosition: 2,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //   key: 'description',
                //   yPosition: 2,
                //   xPosition: 1,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //     key: 'optimisticLocking',
                //     yPosition: 1,
                //     xPosition: 4,
                //     mustRender: true,
                //     width: 6
                // },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType',
          type: 'number',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.description' }),
          key: 'description',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        // {
        //     title: 'Optimistic Locking',
        //     key: 'optimisticLocking',
        //                 type: 'number',
        //     operand: 'like',
        //     min: 0,
        //     length: 0,
        //     mandatory: false,
        //     disabled: false,
        // },
        {
          title: formatMessage({ id: 'resourceType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceTyperesourceTypeList',
        rowKey: 'idResourceType',
        panelHeader: 'Resource Type List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'resourceType.defaultValue' }),
          key: 'defaultValue',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        // {
        //      title: 'Optimistic Locking',
        //      key: 'optimisticLocking',
        //      position: 3,
        //      mustRender: true,
        //      align: 'left',
        //      sorter: false,
        //      visible: true,
        // },
        {
          title: formatMessage({ id: 'resourceType.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        isPINPlatform() && {
          title: 'Max points',
          key: 'maxPoints',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'resourceTyperesourceTypeEdit',
        panelHeader: 'Resource Type Edit',
        primaryKey: 'idResourceType',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'defaultValue',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'indResourceType.idResourceType',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'textMachine',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                isPINPlatform() && {
                  key: 'maxPoints',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'resourceType.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: isPINPlatform(),
        },
        {
          title: formatMessage({ id: 'resourceType.description' }),
          key: 'description',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: isPINPlatform(),
        },
        {
          title: formatMessage({ id: 'resourceType.defaultValue' }),
          key: 'defaultValue',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.ind' }),
          key: 'indResourceType.idResourceType',
          type: 'combo',
          comboId: 'resource_type',
          unique: false,
          mandatory: false,
          initialDisabled: isPINPlatform(),
          disabled: isPINPlatform(),
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: isPINPlatform(),
        },
        {
          title: formatMessage({ id: 'resourceType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialDisabled:!isPINPlatform(),
          behaviours: [
            [
              {
                key: 'idResourceType',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
          disabled: isPINPlatform(),
        },
        isPINPlatform() && {
          title: formatMessage({ id: 'resourceType.maxPoints' }),
          key: 'maxPoints',
          type: 'number',
          min: 0,
          validationType: 'number',
          mandatory: true,
          tooltip: formatMessage({ id: 'resource-type.maxPoints.tooltip' }),
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceTyperesourceTypeDetailList',
        rowKey: 'idResourceTypeD',
        panelHeader: 'Resource Type Detail List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'resourceTypeDetailview',
        navigationId: 'idResourceType.idResourceType',
        tabIndex: 1,
      },
      fields: [
        {
          title: formatMessage({ id: 'resourceType.id_detail' }),
          key: 'idResourceTypeD',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        // {
        //      title: 'Optimistic Locking',
        //      key: 'optimisticLocking',
        //      position: 2,
        //      mustRender: true,
        //      align: 'left',
        //      sorter: false,
        //      visible: true,
        // },
        {
          title: formatMessage({ id: 'resourceType.type' }),
          key: 'type',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.value' }),
          key: 'value',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.mandatory' }),
          key: 'mandatory',
          render: 'comboCustom',
          comboId: 'optional',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceTyperesourceTypeI18nList',
        rowKey: 'idReTI18n',
        panelHeader: 'Resource Type I18n List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'ResourceTypeI18nview',
        navigationId: 'idParent.idResourceType',
        tabIndex: 2,
        disabled: true,
      },
      fields: [
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id Parent',
          key: 'idParent.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id Re TI18n',
          key: 'idReTI18n',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Language',
          key: 'language',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Name',
          key: 'name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        // {
        //      title: 'Optimistic Locking',
        //      key: 'optimisticLocking',
        //      position: 5,
        //      mustRender: true,
        //      align: 'left',
        //      sorter: false,
        //      visible: true,
        // },
        {
          title: 'Status',
          key: 'status',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'ResourceTypeDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ResourceTypeDashboard;
