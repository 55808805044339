const {
  REACT_APP_BASE_URL,
  REACT_APP_FRONT_URL,
  REACT_APP_CLIENT_PORT,
  REACT_APP_CURRENT_VERSION,
  REACT_APP_ENVIRONMENT,
  REACT_APP_LIWW_URL,
  REACT_APP_DEFAULT_TIMEZONE,
  REACT_APP_TIMEZONE_NAME,
  REACT_APP_PLATFORM_CODE,
  REACT_APP_SECURITY,
  REACT_APP_BASE_URL_PIN
} = process.env;

const REACT_APP_AUTH_SERVER_USERNAME =
  process.env.REACT_APP_AUTH_SERVER_USERNAME || '';
const REACT_APP_AUTH_SERVER_PASSWORD =
  process.env.REACT_APP_AUTH_SERVER_PASSWORD || '';

const REACT_APP_WEBEAT_MEDIA_BUCKET =
  process.env.REACT_APP_WEBEAT_MEDIA_BUCKET || '';

const ROOT_URL = `${REACT_APP_BASE_URL}`;
const STATIC_URL = `${REACT_APP_BASE_URL}:${REACT_APP_CLIENT_PORT}/static`;
const SECURITY_LEVEL =
  REACT_APP_SECURITY === '0' ? 0 : REACT_APP_SECURITY === '1' ? 1 : 2;

const config: IConfig = {
  APP: {
    NAME: 'Isdin',
    FRONT_PIN_URL: `${REACT_APP_BASE_URL_PIN}`,
    ENVIRONMENT: REACT_APP_ENVIRONMENT || 'local',
    FRONT_URL: `${REACT_APP_FRONT_URL}`,
    VERSION: `${REACT_APP_CURRENT_VERSION}`,
    LIWW_URL: REACT_APP_LIWW_URL,
    PLATFORM: 'liww_backoffice',
    TIMEZONE: -4 || parseInt(REACT_APP_DEFAULT_TIMEZONE!), // TODO extract in json
    TIMEZONE_NAME: `${REACT_APP_TIMEZONE_NAME}` || 'America/New_York',
    CURRENCY: '$', // TODO extract in json
    CURRENCY_NAME: 'USD', // TODO extract in json
    PLATFORM_CODE: `${REACT_APP_PLATFORM_CODE}`,
  },
  API: {
    ROOT_URL,
    STATIC_URL, //DEPRECATED --> delete
  },
  BUCKET: {
    WEBEAT_BUCKET: REACT_APP_WEBEAT_MEDIA_BUCKET,
  },
  AUTOCOMPLETE: {
    FILTER_SIZE: 9,
    OPTIONS_SIZE: 9,
    INCREMENT_SIZE: 2,
  },
  REGISTER: {
    PRACTICE_TYPE: 'Centro_Medico',
  },
  QUERY: {
    AND: ',AND,',
    OR: ',OR,',
    ID_OPERATOR: '::',
  },
  LOCAL_STORAGE: {
    SESSION: 'isdin_session',
    TOKEN: 'isdin_token',
    BREADCRUMBS: 'isdin_breadcrumbs',
    CONFIG: 'isdin_config',
    FOREIGN_FK: 'nav_fk',
    IMPERSONATE: 'isdin_impersonate',
    PLATFORM: 'isdin_platform',
    PLATFORM_CODE: 'isdin_platform_code',
    TIMEZONE: 'isdin_timezone',
  },
  CLIENT: {
    USERNAME: REACT_APP_AUTH_SERVER_USERNAME,
    PASSWORD: REACT_APP_AUTH_SERVER_PASSWORD,
  },
  SECURITY: SECURITY_LEVEL, // 0 -> NO | 1 -> DEV | 2 -> TOKEN
  BREAKPOINTS: {
    XS: 480,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1600,
  },
  MENU: {
    LOGO_SHOW: true,
    THEME: 'dark', // options: 'dark' || 'light'
  },
  LOGIN: {
    LOGO_SHOW: true,
    FORGOT_PASSWORD: true,
    REMEMBER_ME: true,
  },
  USER: {
    USERID: 'admin',
  },
  PERMISSIONS: {
    LOAD_PERMISSIONS: true,
  },
  HEADER: {
    AVATAR_SHOW: true,
  },
  WELCOME: {
    SHOW: true,
  },
  FEEDBACK: {
    REVERT: {
      SHOW: false,
      DURATION: 3, // default is 3
    },
    MESSAGE: {
      SHOW: true,
      TOP_POSITION: 10,
      TIMEOUT_SUCCESS: 0.8,
      TIMEOUT_ERROR: 2,
      TIMEOUT_INFO: 1,
      DURATION: 1, // default is 3
    },
    CONFIRM: {
      RECORD_CHANGED: true,
      CREATE_RECORD: false,
      DUPLICATE_RECORD: true,
      DELETE_RECORD: true,
    },
  },
  COMPONENT: {
    FORM: {
      FIELD_SIZE: 'default',
      FIELD_MARGIN: 12,
      AVATAR_SIZE: 128,
      DROPDOWN_SIZE: 1,
      ADVANCED_SEARCH_SIZE: 1,
      RESET_ON_UNMOUNT: true,
    },
    TABLE: {
      SIZE: 'small',
      BORDERED: true,
      STRIPED: true,
      SHOW_QUICK_JUMPER: false,
      SHOW_SIZE_CHANGER: true,
      SHOW_TOTAL: true,
      AUTOCOLLAPSE: true,
      COLLAPSE_PERSISTANCE: false,
    },
    COLUMN: {
      SMALL: 60,
      MID: 100,
      LARGE: 160,
    },
    BREADCRUM: {
      SHOW: true,
    },
    EDIT: {
      DISABLE_QUERY_PARAMS_FIELD: true,
    },
  },
  EXPORT: {
    COLUMN_DELIMITER: ';',
    LINE_DELIMITER: '\n',
  },
  UPLOAD: {
    IMAGE_SIZE: 2,
    DOCUMENT_SIZE: 2,
  },
  LOCALE: {
    DEFAULT: 'en-US',
    FIXED: true,
  },
  CHALLENGE: {
    COVER_IMAGE_WIDTH: 604,
    COVER_IMAGE_HEIGHT: 385,
  },
  MARKETING_MATERIALS: {
    COVER_IMAGE_WIDTH: 486,
    COVER_IMAGE_HEIGHT: 367,
  },
  STATIC_PAGES: {
    BANNER_WIDTH: 1440,
    BANNER_HEIGHT: 676,
    BANNER_WIDTH_MOBILE: 769,
    BANNER_HEIGHT_MOBILE: 1044, //error?
    IMAGE_WIDTH: 553,
    IMAGE_HEIGHT: 553,
    IMAGE_WIDTH_MOBILE: 750,
    IMAGE_HEIGHT_MOBILE: 540,
    BACKGROUND_IMAGE_WIDTH: 1440,
    BACKGROUND_IMAGE_HEIGHT: 590,
    BACKGROUND_IMAGE_WIDTH_MOBILE: 750,
    BACKGROUND_IMAGE_HEIGHT_MOBILE: 540,
    PRODUCT_WIDTH: 600,
    PRODUCT_HEIGHT: 600,
  },
};

interface IConfig {
  APP: {
    NAME: string;
    ENVIRONMENT: string | undefined;
    FRONT_PIN_URL: string;
    FRONT_URL: string;
    VERSION: string | number;
    LIWW_URL: string | undefined;
    PLATFORM: string;
    TIMEZONE: number;
    TIMEZONE_NAME: string;
    CURRENCY: string;
    CURRENCY_NAME: string;
    PLATFORM_CODE: string;
  };
  API: {
    ROOT_URL: string;
    STATIC_URL: string;
  };
  BUCKET: {
    WEBEAT_BUCKET: string;
  };
  AUTOCOMPLETE: {
    FILTER_SIZE: number;
    OPTIONS_SIZE: number;
    INCREMENT_SIZE: number;
  };
  REGISTER: {
    PRACTICE_TYPE: string;
  };
  QUERY: {
    AND: ',AND,';
    OR: ',OR,';
    ID_OPERATOR: '::';
  };
  LOCAL_STORAGE: {
    SESSION: string;
    TOKEN: string;
    BREADCRUMBS: string;
    CONFIG: string;
    FOREIGN_FK: string;
    IMPERSONATE: string;
    PLATFORM: string;
    PLATFORM_CODE: string;
    TIMEZONE: string;
  };
  CLIENT: {
    USERNAME: string;
    PASSWORD: string;
  };
  SECURITY: 0 | 1 | 2; // 0 -> NO | 1 -> DEV | 2 -> TOKEN
  BREAKPOINTS: {
    XS: number;
    SM: number;
    MD: number;
    LG: number;
    XL: number;
    XXL: number;
  };
  MENU: {
    LOGO_SHOW: boolean;
    THEME: 'dark' | 'light';
  };
  LOGIN: {
    LOGO_SHOW: boolean;
    FORGOT_PASSWORD: boolean;
    REMEMBER_ME: boolean;
  };
  USER: {
    USERID: string;
  };
  PERMISSIONS: {
    LOAD_PERMISSIONS: boolean;
  };
  HEADER: {
    AVATAR_SHOW: boolean;
  };
  WELCOME: {
    SHOW: boolean;
  };
  FEEDBACK: {
    REVERT: {
      SHOW: boolean;
      DURATION: number; // default is 3
    };
    MESSAGE: {
      SHOW: boolean;
      TOP_POSITION: number;
      TIMEOUT_SUCCESS: number;
      TIMEOUT_ERROR: number;
      TIMEOUT_INFO: number;
      DURATION: number; // default is 3
    };
    CONFIRM: {
      RECORD_CHANGED: boolean;
      CREATE_RECORD: boolean;
      DUPLICATE_RECORD: boolean;
      DELETE_RECORD: boolean;
    };
  };
  COMPONENT: {
    FORM: {
      FIELD_SIZE: 'default';
      FIELD_MARGIN: number;
      AVATAR_SIZE: number;
      DROPDOWN_SIZE: number;
      ADVANCED_SEARCH_SIZE: number;
      RESET_ON_UNMOUNT: boolean;
    };
    TABLE: {
      SIZE: 'small';
      BORDERED: boolean;
      STRIPED: boolean;
      SHOW_QUICK_JUMPER: boolean;
      SHOW_SIZE_CHANGER: boolean;
      SHOW_TOTAL: boolean;
      AUTOCOLLAPSE: boolean;
      COLLAPSE_PERSISTANCE: boolean;
    };
    COLUMN: {
      SMALL: number;
      MID: number;
      LARGE: number;
    };
    BREADCRUM: {
      SHOW: boolean;
    };
    EDIT: {
      DISABLE_QUERY_PARAMS_FIELD: boolean;
    };
  };
  EXPORT: {
    COLUMN_DELIMITER: ';';
    LINE_DELIMITER: '\n';
  };
  UPLOAD: {
    IMAGE_SIZE: number;
    DOCUMENT_SIZE: number;
  };
  LOCALE: {
    DEFAULT: 'en-US' | 'es-ES';
    FIXED: boolean;
  };
  CHALLENGE: {
    COVER_IMAGE_WIDTH: number;
    COVER_IMAGE_HEIGHT: number;
  };
  MARKETING_MATERIALS: {
    COVER_IMAGE_WIDTH: number;
    COVER_IMAGE_HEIGHT: number;
  };
  STATIC_PAGES: {
    BANNER_HEIGHT: number;
    BANNER_WIDTH: number;
    BANNER_HEIGHT_MOBILE: number;
    BANNER_WIDTH_MOBILE: number;
    IMAGE_HEIGHT: number;
    IMAGE_WIDTH: number;
    IMAGE_HEIGHT_MOBILE: number;
    IMAGE_WIDTH_MOBILE: number;
    BACKGROUND_IMAGE_HEIGHT: number;
    BACKGROUND_IMAGE_WIDTH: number;
    BACKGROUND_IMAGE_HEIGHT_MOBILE: number;
    BACKGROUND_IMAGE_WIDTH_MOBILE: number;
    PRODUCT_HEIGHT: number;
    PRODUCT_WIDTH: number;
  };
}

export default config;
