import apiPaths from '../../../apiPaths';
import { S3BucketPathEnum } from '../../../shared';
import { getPlatformBaseUrl } from '../../../utils';
import { ResourceTypeDetailEnum } from '../../Enums';
import { UploadMediaCommandsHandler } from './UploadMedia';
import { CommandUploadPDF, CommandUploadURL } from './commands';

export class UploadMediaFactory {
  static createHandler(
    resourceTypeDetail: ResourceTypeDetailEnum,
  ): UploadMediaCommandsHandler {
    const commandsHandler = new UploadMediaCommandsHandler();

    switch (resourceTypeDetail) {
      case ResourceTypeDetailEnum.EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE:
        commandsHandler.addCommand(new CommandUploadURL());
        commandsHandler.addCommand(
          new CommandUploadPDF({
            requestActionUrl: `${getPlatformBaseUrl()}${
              apiPaths.UPLOAD_MEDIA
            }/${S3BucketPathEnum.EXTERNAL_REDEEM_POINTS}`,
          }),
        );
        break;

      default:
        throw new Error('Invalid resource type detail');
    }

    return commandsHandler;
  }
}
