import { Switch } from 'antd';
import React, { FC, Fragment } from 'react';
import Text from 'antd/lib/typography/Text';
import { ProductItem } from './products-item';

interface ActiveProductProps {
  fieldConfig: any;
  handleChange: (key: string, value: any) => void;
  constentState?: any;
  index: number;
}

const ActiveProduct: FC<ActiveProductProps> = ({
  constentState,
  fieldConfig,
  handleChange,
  index,
}) => {
  return (
    <Fragment>
      <div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}>
          <Switch
            checked={constentState[fieldConfig.key][index]['active']}
            disabled={true}
            onChange={(e) =>
              handleChange(
                fieldConfig.key,
                constentState[fieldConfig.key].map(
                  (item: ProductItem, idx: number) => {
                    return idx === item.order - 1
                      ? { ...item, active: e }
                      : item;
                  },
                ),
              )
            }
          />
          <Text>Active</Text>
        </div>
      </div>
    </Fragment>
  );
};

export default ActiveProduct;
