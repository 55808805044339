import React, { Fragment } from 'react';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import { DetailsTypesEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const { BOOLEAN, TEXT_AREA } = DetailsTypesEnum;

const Event = ({
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
}: IResourceTree): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .sort(
          (
            {
              detail: {
                idResourceTypeD: { type: typeA },
              },
            },
            {
              detail: {
                idResourceTypeD: { type: typeB },
              },
            },
          ) => {
            if (typeA === TEXT_AREA && typeB === BOOLEAN) return 1;
            if (typeA === BOOLEAN && typeB === TEXT_AREA) return -1;
            return 0;
          },
        )
        .map(({ index, detail }) =>
          RenderDetail({
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            handleDeleteImages,
            resource,
            mainIdResource: resource.idResource,
            values,
          }),
        )}
    </>
  );
};

export default Event;
