import { AxiosResponse } from 'axios';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';
import {
  ITransactionDetails,
  IBulkCreateTransactionResponse,
} from './massPointsManagement.interface';
import { MassPointsManagementPlatformEnum } from './MassPointsManagement';

export async function callBulkCreateTransactions(
  transactionDetails: ITransactionDetails,
  validateOnly: boolean,
  platform: MassPointsManagementPlatformEnum,
) {
  const dataPath =
    platform === MassPointsManagementPlatformEnum.WEBEAT
      ? apiPaths.WEBEAT.CALL.CUSTOMER_ASSIGN_POINTS_BY_EMAILS
      : apiPaths.CALL.CUSTOMER_BULK_CREATE_TRANSACTION;

  const data = {
    emails: transactionDetails.emails.value,
    transaction: {
      description: transactionDetails.description.value,
      amount: transactionDetails.amount.value,
    },
    validateOnly,
  };

  return (await api.postDataCall({
    dataPath,
    data,
    callConfig: {},
  })) as AxiosResponse<IBulkCreateTransactionResponse>;
}
