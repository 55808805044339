import React from 'react';
import { IUploadMediaCommands, IUploadMediaRenderProps } from '.';
import UploadMediaComponent from '../UploadMediaBase';

export class CommandUploadPDF implements IUploadMediaCommands {
  icon: string = 'file-pdf';
  id: string = 'pdf';
  label: string = 'PDF';
  requestActionUrl: string;

  constructor(params: { requestActionUrl: string }) {
    this.requestActionUrl = params.requestActionUrl;
  }

  render(props: IUploadMediaRenderProps): JSX.Element {
    return (
      <UploadMediaComponent
        {...props}
        requestUrl={this.requestActionUrl}
        fileTypes={['application/pdf']}
      />
    );
  }
}
