import React from 'react';
import { Icon, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IRecord } from '../app/AppInterfaces';
import '../tables/SmartTable.css';
import { CUSTOM_DEFAULT_SIZE } from './';

interface TableProps {
  columns: ColumnProps<IRecord>[];
  dataSource: IRecord[];
  handleSearchLess: () => Promise<void>;
  handleSearchMore: () => Promise<void>;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  loading: boolean;
  page: number;
  rowKey: string;
  selectedRow: IRecord;
  setSelectedRow: React.Dispatch<React.SetStateAction<IRecord>>;
  hideTableArrows?: boolean;
}

export default function CustomTable({
  columns,
  dataSource,
  handleTableChange,
  handleSearchLess,
  handleSearchMore,
  loading,
  page,
  rowKey,
  selectedRow,
  setSelectedRow,
  hideTableArrows
}: TableProps) {
  let tableData = [...dataSource];

  const rowClassName = (record: IRecord, index: number) => {
    return selectedRow[rowKey] === record[rowKey]
      ? 'row-selected'
      : 'row-striped';
  };

  const NotData = () => {
    return dataSource.length ? 'smartTableNotData' : 'smartTableBody';
  };

  const isPreviousDisabled = () => {
    if (loading || !dataSource.length) return true;
    return page === 0;
  };

  const isNextDisabled = () => {
    if (loading || !dataSource.length) return true;
    if (tableData.length < CUSTOM_DEFAULT_SIZE) return true;
    return false;
  };

  const searchLess = () => {
    if (!isPreviousDisabled()) handleSearchLess();
  };

  const searchMore = () => {
    if (!isNextDisabled()) handleSearchMore();
  };

  return (
    <div className="smartTable">
      <Table
        className={NotData()}
        columns={columns}
        dataSource={tableData}
        loading={loading}
        onChange={handleTableChange}
        rowKey={(record: IRecord) => record.saleforceID}
        rowClassName={(record: IRecord, index: number) =>
          rowClassName(record, index)
        }
        onRow={(record: IRecord) => ({
          onClick: () => {
            setSelectedRow(record);
          },
        })}
        size="small"
        pagination={false}
        scroll={{
          y: dataSource.length ? 'calc(100vh - 264px)' : '0',
        }}
      />
      {!hideTableArrows ? <Row type="flex" justify="end" style={{ marginTop: '10px' }}>
        <div style={{ marginRight: '15px' }}>
          <Icon
            className={isPreviousDisabled() ? 'button-disabled' : ''}
            type="left"
            onClick={searchLess}
          />
        </div>
        <div>
          <Icon
            className={isNextDisabled() ? 'button-disabled' : ''}
            type="right"
            onClick={searchMore}
          />
        </div>
      </Row> : null}

    </div>
  );
}
