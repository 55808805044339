import { Button, DatePicker, Form, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import Axios from 'axios';
import moment from 'moment';
import React, { FC, useState } from 'react';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ParametersEnum } from '../../shared/enums/parameters.enum';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function EditExpirationPointsDates<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleSetModalVisible = async (visible: boolean) => {
    setModalVisible(visible);
  };

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { dataSource } = tableProps;
    const { content } = dataSource;
    const { intl } = tableProps.props;
    const FormCustom: FC<FormComponentProps> = ({ form }) => {
      const [datesValues, setdatesValues] = useState<any>([]);
      const [disabled, setDisabled] = useState<boolean>(true);

      const handleChangeDate = (date: any, element: any) => {
        let newDate = null;
        if (date) {
          newDate = date.clone().format('YYYY-MM-DD HH:mm:ss');
          const formattedDate = new Date(date);
          formattedDate.setUTCSeconds(0);
          newDate = formattedDate.toUTCString();
          let dates = datesValues;
          let index = dates.findIndex(
            (date: any) => date.idParameter === element.idParameter,
          );

          element.value = newDate;
          if (index === -1) dates.push({ ...element, value: newDate });
          else dates[index].value = newDate;

          setdatesValues(dates);
          setDisabled(false);
        }
      };

      const handleSubmit = async () => {
        try {
          await Axios.all(
            datesValues.map((date: any) =>
              api.putDataCall({
                dataPath: apiPaths.CALL.PARAMETERS,
                data: date,
                callConfig: {},
              }),
            ),
          );
          handleSetModalVisible(false);
        } catch (error) {
          console.error(error);
        } finally {
          await tableProps.handleReloadData();
        }
      };

      return (
        <Form className="custom-form">
          {content.map((element) => {
            if (
              element?.idParameter === ParametersEnum.PERFORM_CADUCITY_DATE ||
              element?.idParameter === ParametersEnum.POINTS_CADUCITY_DATE ||
              element?.idParameter === ParametersEnum.SHOW_LABEL_DATE
            )
              return (
                <Form.Item>
                  <label>{intl.formatMessage({ id: element.name })}</label>
                  <DatePicker
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    defaultValue={moment(new Date(element.value))}
                    size="small"
                    onChange={(date: any | null) =>
                      handleChangeDate(date, element)
                    }
                  />
                </Form.Item>
              );
            return <React.Fragment />;
          })}
          <Form.Item className="form__bottomButtons--floatRight">
            <Button
              icon="save"
              title={intl.formatMessage({ id: 'form.save' })}
              onClick={() => handleSubmit()}
              disabled={disabled}>
              {intl.formatMessage({ id: 'form.save' })}
            </Button>
          </Form.Item>
        </Form>
      );
    };

    const FormWithProps = Form.create<FormComponentProps>()(FormCustom);

    const previewModal = (
      <Modal
        visible={modalVisible}
        className="previewModal"
        onCancel={() => handleSetModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'75%'}>
        <Row className="isdin-custom-modal__container">
          <Row className="isdin-custom-modal__title">
            {intl.formatMessage({ id: 'expiration_ponts_config_title' })}
          </Row>
          <Row></Row>
          <FormWithProps></FormWithProps>
          {/* <Divider></Divider> */}
        </Row>
      </Modal>
    );

    return (
      <>
        {previewModal}
        <Button onClick={() => handleSetModalVisible(true)} type="primary">
          {intl.formatMessage({ id: 'expiration_ponts_config_button' })}
        </Button>
      </>
    );
  }

  return <></>;
}
