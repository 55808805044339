import { Button, Modal, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { IRow, IToken } from '../app/AppInterfaces';
import { ISmartRenderProps } from '../tables/SmartTableRender';

interface CustomProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  parentProps: ISmartRenderProps;
}

export function UserTokensListModal({
  modalVisible,
  setModalVisible,
  parentProps,
}: CustomProps) {
  const [loading, setLoading] = useState(false);
  const [tokensList, setTokensList] = useState<IToken[]>([]);
  const [selectedRow, setSelectedRow] = useState<IRow>();

  useEffect(() => {
    setSelectedRow(parentProps.props.selectedRow);
  }, [parentProps.props.selectedRow]);

  useEffect(() => {
    if (selectedRow) setTokensList(selectedRow.tokensList);
  }, [selectedRow]);

  const { formatMessage } = useIntl();

  const handleCloseModal = () => {
    setLoading(false);
    setModalVisible(false);
  };

  const handleRemoveToken = async (e: React.MouseEvent, tokenID: number) => {
    setLoading(true);
    await api
      .deleteCall({
        dataPath: `${apiPaths.CALL.DELETE_TOKEN_USER}/${tokenID}`,
        callConfig: {},
      })
      .then(async (res) => {
        setTokensList(tokensList.filter(({ id }) => id !== tokenID));
      })
      .finally(() => {
        setLoading(false);
        parentProps.handleReloadData();
      });
  };

  const columns = [
    {
      dataIndex: 'accessToken',
      title: formatMessage({ id: 'users.token-accessToken' }),
      width: 180,
    },
    {
      dataIndex: 'createDate',
      title: formatMessage({ id: 'users.token-createDate' }),
      width: 203,
    },
    {
      dataIndex: 'updateDate',
      title: formatMessage({ id: 'users.tokenUpdateDate' }),
      width: 203,
    },
    {
      title: '',
      render: (token: IToken) => {
        return (
          <Button
            type="primary"
            onClick={(e: React.MouseEvent) => handleRemoveToken(e, token.id)}>
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      className="isdin-custom-modal"
      visible={modalVisible}
      footer={null}
      onCancel={handleCloseModal}>
      <Row className="isdin-custom-modal__content">
        <Row className="isdin-custom-modal__title">
          {formatMessage({ id: 'button-title.token-users' })}
        </Row>
        <Table
          columns={columns}
          loading={loading}
          rowKey={(token: IToken) => token.id.toString()}
          dataSource={tokensList}
        />
      </Row>
    </Modal>
  );
}
