import MassPointsManagementButton, {
  MassPointsManagementPlatformEnum,
} from './MassPointsManagement';
import React from 'react';

export default function WebeatMassPointsManagementButton() {
  return (
    <MassPointsManagementButton
      platform={MassPointsManagementPlatformEnum.WEBEAT}
    />
  );
}
