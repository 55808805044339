import { Row, Col } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';

interface ITextLinkItem {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ITextLinkItem;

const IncludedItem: FC<IGroupProps> = ({
  _resource,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList } = _resource;

  const IncludedItemDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) IncludedItemDetails.push({ index, detail });
  });

  if (!IncludedItemDetails) return <Fragment />;

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={24}>
        {IncludedItemDetails.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        ).map(({ detail, index }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === ResourceTypeDetailEnum.INCLUDED_ITEM_ICON) {
            combo =
              combos.challengechallengeEdit?.iconType?.iconType?.data ?? [];
          }

          return RenderDetail({
            combo,
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            values,
          });
        })}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IncludedItem);
