import { Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { IResource, IResourceDetail } from '../ChallengeInterfaces';
import {
  DetailsTypesEnum,
  isBooleanDetail,
  isNotification,
  ResourceTypeDetailEnum,
} from '../Enums';
import ResourceDetail from './ResourceDetail';

const { TEXT_AREA } = DetailsTypesEnum;
const { WATCH_AGAIN_ONLY_ATTENDED_USERS } = ResourceTypeDetailEnum;

interface IBasicResource {
  handleDeleteImages: (image: string, idResourceD: number) => void;
  form: WrappedFormUtils<any>;
  resource: IResource;
  editResource: boolean;
  handleEditResourceDetail: (idResourceD: number, value: any) => void;
  renderExtraFields: false | JSX.Element;
  intl: typeof InjectedIntlProps;
  isExperience?: boolean;
}

const BasicResource: FC<IBasicResource> = ({
  handleDeleteImages,
  form,
  resource,
  editResource,
  handleEditResourceDetail,
  renderExtraFields,
  intl,
  isExperience,
}) => {
  const { resourceDetailList, idResource } = resource;

  function orderTextarea(a: IResourceDetail, b: IResourceDetail) {
    if (a.idResourceTypeD.type === TEXT_AREA) {
      return -1;
    }
    if (b.idResourceTypeD.type === TEXT_AREA) {
      return 1;
    }
    return 0;
  }

  function orderBoolean(a: IResourceDetail, b: IResourceDetail) {
    if (isNotification(resource)) return 0;
    if (
      isBooleanDetail(a.idResourceTypeD.type) &&
      !isBooleanDetail(b.idResourceTypeD.type)
    )
      return -1;
    if (
      !isBooleanDetail(a.idResourceTypeD.type) &&
      isBooleanDetail(b.idResourceTypeD.type)
    )
      return 1;
    return 0;
  }

  function orderByOrder(a: IResourceDetail, b: IResourceDetail) {
    return a.order - b.order;
  }

  function undesiredComponents({
    status,
    idResourceTypeD: { idResourceTypeD },
  }: IResourceDetail) {
    return status && idResourceTypeD !== WATCH_AGAIN_ONLY_ATTENDED_USERS;
  }

  return (
    <Fragment key={idResource}>
      <Row gutter={[24, 0]} style={{ minHeight: '115px' }}>
        {renderExtraFields}
        {resourceDetailList &&
          resourceDetailList
            .filter(undesiredComponents)
            .sort(orderTextarea)
            .sort(orderByOrder)
            .sort(orderBoolean)
            .map(({ idResourceTypeD, idResourceD, value }: IResourceDetail) => (
              <ResourceDetail
                key={idResourceD}
                {...{
                  handleDeleteImages,
                  editResource,
                  form,
                  idResourceTypeD,
                  idResourceD,
                  handleEditResourceDetail,
                  resource,
                  resourceType: resource.idResourceType.name,
                  value,
                  intl,
                  isExperience,
                }}
              />
            ))}
      </Row>
    </Fragment>
  );
};

export default BasicResource;
