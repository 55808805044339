import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import { DetailsTypesEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const { BOOLEAN, TEXT_AREA, TEXT, IMAGE } = DetailsTypesEnum;

type ConnectedQuiz = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IResourceTree;

const Quiz: FC<ConnectedQuiz> = ({
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  resource,
}): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  return (
    <>
      {details
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .sort(
          (
            {
              detail: {
                idResourceTypeD: { type: typeA },
              },
            },
            {
              detail: {
                idResourceTypeD: { type: typeB },
              },
            },
          ) => {
            if (typeA === BOOLEAN || typeB === TEXT_AREA) return -1;
            if (typeA === TEXT_AREA || typeB === BOOLEAN) return 1;
            if (typeA === IMAGE && typeB === TEXT) return 1;
            return 0;
          },
        )
        .map(({ index, detail }) =>
          RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            resource,
            mainIdResource: resource.idResource,
          }),
        )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
