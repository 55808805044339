import React from 'react';

export default function TeenyiconsBagAltSolid(props) {
  return (
    <svg viewBox="0 0 15 15">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 1.5A1.5 1.5 0 0 1 2.5 0h10A1.5 1.5 0 0 1 14 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 13.5v-12Zm9 3a2.5 2.5 0 0 1-5 0V2H4v2.5a3.5 3.5 0 1 0 7 0V2h-1v2.5Z"
        clipRule="evenodd"></path>
    </svg>
  );
}
