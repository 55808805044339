import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const ActionsDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const platformComboFields = isConPlatform()
    ? []
    : [
        {
          title: formatMessage({ id: 'globalAction.actions' }),
          key: 'idAction',
          type: 'combo',
          comboId: 'actions',
          mandatory: false,
          disabled: false,
        },
      ];

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'actionsactionsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'actionsactionsList',
        rowKey: 'idAction',
        panelHeader: 'Actions List',
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0,
        customButtons: isConPlatform() ? [] : ['globalActionAssignPoints'],
      },
      fields: [
        {
          title: formatMessage({ id: 'boroles.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Points',
          key: 'score.points',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: isConPlatform(),
          render: 'number',
          precision: 0,
        },
        {
          title: 'ID Action',
          key: 'idAction',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: isConPlatform(),
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'actionsactionsEdit',
        panelHeader: 'Actions Edit Form',
        primaryKey: 'idAction',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: isConPlatform(),
                  width: 6,
                },
                {
                  key: 'description',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: isConPlatform(),
                  width: 10,
                },
                {
                  key: 'idAction',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: !isConPlatform(),
                  width: 4,
                },
                {
                  key: 'score.points',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: isConPlatform(),
                  width: 4,
                },
                {
                  key: 'status',
                  yPosition: isConPlatform() ? 3 : 0,
                  xPosition: isConPlatform() ? 0 : 1,
                  mustRender: true,
                  width: 4,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        ...platformComboFields,
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'boroles.description' }),
          key: 'description',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'globalAction.points' }),
          key: 'score.points',
          type: 'number',
          min: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialValue: 'true',
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idRole',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
  ];

  const componentId = 'ActionsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ActionsDashboard;
