import { Form, Input } from 'antd';
import React from 'react';
import { IUploadMediaCommandRenderProps, IUploadMediaCommands } from '.';

export class CommandUploadURL implements IUploadMediaCommands {
  icon: string = 'global';
  id: string = 'url';
  label: string = 'URL';

  validateURL = (
    rule: any,
    value: string,
    callback: (message?: string) => void,
  ) => {
    const urlRegex = /^(https?:\/\/[^\s]+\.[a-zA-Z]{2,}(?:[^\s]+)?)$/;

    if (!value) {
      callback('URL is required');
    }

    if (!urlRegex.test(value)) {
      callback('Invalid URL format. ie: https://www.termsconditions.com');
    }
    callback();
  };

  render(props: IUploadMediaCommandRenderProps): JSX.Element {
    const { setValue, value, isDisabled, form } = props;
    const parsedValue = JSON.parse(value);

    const _props = {
      disabled: isDisabled,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(`{"url": "${e.target.value}", "type":"url"}`),
      allowClear: true,
      defaultValue: parsedValue.url,
      value: parsedValue.url,
    };

    return (
      <Form.Item>
        {form?.getFieldDecorator('tc-url', {
          rules: [{ validator: this.validateURL }],
          initialValue: parsedValue.url,
        })(<Input {..._props} />)}
      </Form.Item>
    );
  }
}
