import React from 'react';
import { Row, Button, Form } from 'antd';
import { SearchFormRenderProps } from './SearchFormRender';

export const buttonsContainerBottom = (parentProps: SearchFormRenderProps) => {
  const { props, handleReset } = parentProps;
  const { isLoading, isM2M, m2mIsLoading, intl } = props;
  const loading = isM2M ? m2mIsLoading : isLoading;

  const handlCancel = () => {
    parentProps.handleChangeFilterVisibility();
  };

  const handleCleanFilter = () => {
    handleReset();
    parentProps.handleChangeFilterVisibility();
  };

  return (
    <Row className="form__bottomButtons">
      <Form.Item className="form__bottomButtons--floatLeft">
        <Button
          type="link"
          onClick={handleCleanFilter}
          title={intl.formatMessage({ id: 'form.clear' })}>
          {intl.formatMessage({ id: 'form.clear' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          title={intl.formatMessage({ id: 'form.search.close' })}
          disabled={loading}
          // loading={loading}
          onClick={handlCancel}>
          {intl.formatMessage({ id: 'form.search.close' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          // htmlType="submit"
          title={intl.formatMessage({ id: 'form.search.apply' })}
          type="primary"
          disabled={loading}
          loading={loading}
          onClick={(e: React.MouseEvent) => parentProps.handleSubmit(e)}>
          {intl.formatMessage({ id: 'form.apply' })}
        </Button>
      </Form.Item>
    </Row>
  );
};
