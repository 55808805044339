import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { appReducer, AppState } from './app/appReducer';
import { authReducer, AuthState } from './auth/authReducer';
import { searchReducer, SearchState } from './forms/search/searchReducer';
import { editReducer, EditState } from './forms/edit/editReducer';
import { tableReducer, TableState } from './tables/tableReducer';
import { m2mReducer, M2MState } from './m2m/m2mReducer';
import { comboReducer, ComboState } from './combos/comboReducer';
import {
  dashboardReducer,
  DashboardsState
} from './dashboards/dashboardReducer';
import { queryReducer, QueryState } from './app/queryReducer';
import { challengeReducer, ChallengeState } from './challenge/challengeReducer';
import {
  contentPageReducer,
  ContentPageState
} from './contentPage/redux/contentPageReducer';

export const reducers = (history: History) =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    combos: comboReducer,
    dashboards: dashboardReducer,
    edits: editReducer,
    m2m: m2mReducer,
    searches: searchReducer,
    tables: tableReducer,
    router: connectRouter(history),
    query: queryReducer,
    challenge: challengeReducer,
    contentPages: contentPageReducer
  });

export type ReducersState = {
  app: AppState;
  auth: AuthState;
  combos: ComboState;
  dashboards: DashboardsState;
  edits: EditState;
  m2m: M2MState;
  router: typeof connectRouter;
  searches: SearchState;
  tables: TableState;
  query: QueryState;
  challenge: ChallengeState;
  contentPages: ContentPageState;
};
