import React, { Fragment, useState } from 'react';
import { Button, Modal, Row, notification } from 'antd';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';
import { ShoppingCartOrderDetails } from './ShoppingCartOrderDetails';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { UserResourceEnum } from '../../challenge/Enums';

interface IOrderDetail {
  id: number;
  brand: string;
  name: string;
  qty: number;
  image: string;
}

export default function ShoppingCartOrderButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const [orderDetails, setOrderDetails] = useState<IOrderDetail[]>([]);
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const handleOpen = async () => {
    // If selected order is not yet processed we can't show up its details.
    if (
      selectedRow.statusResource !==
      (UserResourceEnum.SIGNED || UserResourceEnum.PROCESSED)
    ) {
      notification.info({
        message: formatMessage({
          id: 'component-modal-notification-info-title',
        }),
        description: formatMessage({
          id: 'component-modal-notification-info-description-orders',
        }),
      });

      return;
    }

    // Get order details from Magento by the order number
    try {
      const response = await api.getDataCallById({
        dataPath: apiPaths.CALL.CUSTOMER_ORDER_DETAILS,
        registerId: selectedRow.value,
        callConfig: {},
      });

      if (response?.status === 200 && response?.data) {
        setOrderDetails([...response.data]);

        setModalVisible(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        onClick={() => handleOpen()}
        disabled={isEmpty(selectedRow)}
        type="primary">
        {formatMessage({ id: 'shoppingCart-order.button' })}
      </Button>
      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={handleClose}
        onOk={handleClose}
        className="request-order-modal">
        <Row align="middle">
          <div className="request-order-modal__title">
            {formatMessage({ id: 'shoppingCart-order.button' })}
          </div>
          <ShoppingCartOrderDetails {...orderDetails} />
        </Row>
      </Modal>
    </>
  );
}
