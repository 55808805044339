import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ScoreDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'scorescoreSearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResource.idResource',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'idScore',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'optimisticLocking',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'points',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource.idResource',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'resource',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Id Score',
          key: 'idScore',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Points',
          key: 'points',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Status',
          key: 'status',

          type: 'checkselect',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'scorescoreList',
        rowKey: 'idScore',
        panelHeader: 'Score List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0
      },
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource.name',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Score',
          key: 'idScore',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Points',
          key: 'points',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Status',
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'scorescoreEdit',
        panelHeader: 'Score Edit',
        primaryKey: 'idScore',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResource.idResource',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'optimisticLocking',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'points',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource.idResource',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'resource',
          path: 'resource',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          type: 'number',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Points',
          key: 'points',
          type: 'number',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Status',
          key: 'status',

          type: 'checkSelect',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    }
  ];

  const componentId = 'ScoreDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ScoreDashboard;
