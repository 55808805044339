import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';
const CommunicationStatusDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'usersCommunicationsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'usersCommumicationsSearchCriteria',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Email',
          key: 'email',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'users.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'users.specialty' }),
          key: 'idSpecialty.idSpecialty',
          type: 'combo',
          comboId: 'specialty',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'users.usersSearchCriteria' }),
          key: 'usersCommumicationsSearchCriteria',
          type: 'comboCustom',
          comboId: 'usersCommumicationsSearchCriteria',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'email',
          isSearchCriteria: true,
        },
      ],
      settings: {
        canCreate: false,
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'usersCommunicationsListLiww',
        rowKey: 'uuidUser',
        panelHeader: 'Users List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'email',
      },
      fields: [
        {
          title: 'Email PIN',
          key: 'email',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ASC',
        },
        {
          title: 'Email SFMC',
          key: 'emailSFMC',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'First Name',
          key: 'firstName',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Last Name',
          key: 'lastName',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Specialty',
          key: 'specialty',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Uuid user',
          key: 'uuidUser',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id salesforce',
          key: 'idSalesforce',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Creation Date',
          key: 'creationDate',
          render: 'datetime',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Suscriber email',
          key: 'subscriberEmail',
          render: 'comboCustom',
          comboId: 'optional',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Opt In Date',
          key: 'optInDate',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Opt Out Date',
          key: 'optOutDate',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Suscriber Commercial',
          key: 'subscriberCommercial',
          position: 11,
          mustRender: true,
          render: 'comboCustom',
          comboId: 'optional',
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: 'Opt In Date Commercial',
          key: 'optInDateCommercial',
          position: 12,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Opt Out Date Commercial',
          key: 'optOutDateCommercial',
          position: 13,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'users.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 14,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: false,
        multiple: false,
        canCreate: false,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'CommunicationStatusDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
    userPermissions: state.app.userPermissions,
  };
};
export default connect(mapStateToProps, null)(CommunicationStatusDashboard);
