import React, { useState } from 'react';
import { Button, Card, Col, Row, Modal, Select } from 'antd';
import { IRow, IRecord } from '../app/AppInterfaces';
import { isEmpty } from 'lodash';
import { appComponents } from '../components';
import { IChallengeType } from './ChallengeInterfaces';
import apiPaths from '../apiPaths';
import { IWebeatChallengeRenderListProps } from './WebeatChallengeRender';

export default function WebeatChallengeSelector(props: IWebeatChallengeRenderListProps) {
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [selectedChallengeType, setSelectedChallengeType] = useState<
      number | null
      >(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { challengeType, challengeTemplate, intl, setLoading } = props;
  const { formatMessage } = intl;

  let challengeAvailable: { [key: string]: any }[] = [];

  if (!isEmpty(challengeType))
    challengeAvailable = [
      ...challengeAvailable,
      {
        idChallengeTemplate: 0,
        name: 'BLANK',
        description: 'Blank Challenge',
        challengeTypeList: challengeType,
      },
    ];

  if (!isEmpty(challengeTemplate))
    challengeAvailable = [...challengeAvailable, ...challengeTemplate];

  const handleCreateFromTemplate = async (type?: number | null) => {
    const {
      createChallenge,
      getTableData,
      setSelectedRow,
      params,
     queryParams,
    } = props;
   const targetId = appComponents[params.componentId].targetId;
    const { primaryKey } = params;

    let path: string = '';
    let id: number;

    if (selectedCard) {
      path = apiPaths.CALL.CHALLENGE;
      id = selectedCard;
    } else if (selectedCard === 0 && type !== undefined && type !== null) {
      path = apiPaths.CALL.CHALLENGE_FROM_TYPE;
      id = type!;
    }

    if (path !== '' && id! !== undefined) {
      try {
        setLoading(true);
        const newChallenge: any = await createChallenge({
          id,
          path,
          applyLoading: false,
        });

        if (newChallenge?.status === 200 || newChallenge?.status === 201) {
          const requestData: any = await getTableData({
             dataPath: `${appComponents[targetId].path}/Page/${newChallenge.data[primaryKey]}`,
             componentId: targetId,
             queryParams,
           });

           requestData?.data?.content?.forEach((element: IRecord) => {
             if (element[primaryKey] === newChallenge.data[primaryKey])
               setSelectedRow({
                 componentId: targetId,
                 selectedRow: element,
               });
           });
         }

          setSelectedRow({
            componentId: targetId,
            selectedRow: newChallenge.data,
          });
        } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectCard = (idChallengeType: number) => {
    if (selectedCard === idChallengeType) setSelectedCard(null);
    else setSelectedCard(idChallengeType);
  };

  const challengeTypeSelectionModal = (
      <Modal
          visible={modalVisible}
          okButtonProps={{ disabled: selectedChallengeType === null }}
          onOk={() => handleCreateFromTemplate(selectedChallengeType)}
          onCancel={() => setModalVisible(false)}
          title="Select Challenge type">
        <Row className="Challenge__modalRow">
          {challengeAvailable.length > 0 && (
              <Select
                  className="Challenge__modalSelector"
                  onChange={(value: any, data: any) => {
                    setSelectedChallengeType(value);
                  }}>
                {challengeAvailable[0].challengeTypeList
                    .filter((challenge: IChallengeType) => challenge.status)
                    .map((_challenge: IChallengeType) => (
                        <Select.Option
                            key={_challenge.idChallengeType}
                            value={_challenge.idChallengeType}>
                          {_challenge.name}
                        </Select.Option>
                    ))}
              </Select>
          )}
        </Row>
      </Modal>
  );

  const ChallengeTemplate = (challenge: IRow, selected: boolean) => {
    const cardClassName = selected
        ? 'challengeCard__selected'
        : 'challengeCard';

    return (
        <Col
            xl={6}
            lg={8}
            md={12}
            xs={12}
            key={challenge.idChallengeTemplate}
            onClick={() => handleSelectCard(challenge.idChallengeTemplate)}>
          <Row className={cardClassName} type="flex">
            <Card
                hoverable
                className="challengeCard__card"
                cover={
                  challenge.description
                      ? challenge.description
                      : formatMessage({ id: 'challengeType.noDescription' })
                }>
              <Card.Meta title={challenge.name} />
            </Card>
          </Row>
        </Col>
    );
  };

  return (
      <Row className="Challenge">
        {challengeTypeSelectionModal}
        <Row className="Challenge__header">
          <div className="Challenge__header--title">
            {formatMessage({ id: 'challengeType.new' })}
          </div>
          <div className="Challenge__header--description">
            {formatMessage({ id: 'challengeType.selectTemplate' })}
          </div>
        </Row>
        <Row type="flex">
          <Col span={24} className="templateSelector">
            <Row
                type="flex"
                align="top"
                justify="start"
                gutter={[24, 24]}
                className="templateSelector__container">
              {challengeAvailable
                  .filter((challenge) => challenge.status !== false)
                  .map((challenge) =>
                      ChallengeTemplate(
                          challenge,
                          selectedCard === challenge.idChallengeTemplate,
                      ),
                  )}
            </Row>
            <Row
                className="templateSelector__button"
                type="flex"
                align="middle"
                justify="center">
              {selectedCard === 0 ? (
                  <Button
                      type="primary"
                      disabled={selectedCard === null}
                      onClick={() => setModalVisible(true)}>
                    {formatMessage({ id: 'challengeType.create' })}
                  </Button>
              ) : (
                  <Button
                      type="primary"
                      disabled={selectedCard === null}
                      onClick={() => handleCreateFromTemplate()}>
                    {formatMessage({ id: 'challengeType.create' })}
                  </Button>
              )}
            </Row>
          </Col>
          {/* <Col xs={24} md={8} className="templateCreator">
          <TemplateCreator />
        </Col> */}
        </Row>
      </Row>
  );
}
