import React from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import I18nComponent from './I18nComponent';
import apiPaths from '../../apiPaths';
import { isTableProps } from '../../utils/propsUtils';


export default function RolesI18nButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, params } = tableProps.props;
    const { rowKey } = params;

    const path = apiPaths.CALL.MARKETING_MATERIALS_I18N;

    return I18nComponent({
      i18Key: 'idMaterialI18n',
      id: selectedRow[rowKey],
      originalValue: selectedRow.name,
      path,
      rowKey,
      selectedRow,
      contentKey: 'name',
      descriptionKey: 'description',
      originalDescription: selectedRow.description,
    });
  }
  return <></>;
}
