import { dashboardTypes } from './dashboardTypes';
import { Dispatch } from 'redux';

interface SetInitialDashboardState {
  type: dashboardTypes.SET_DASHBOARD_INITIAL_STATE;
  payload: { dashboardId: string };
}

export const setInitialDashboardState = ({
  dashboardId
}: {
  dashboardId: string;
}) => (dispatch: Dispatch<SetInitialDashboardState>) => {
  dispatch({
    type: dashboardTypes.SET_DASHBOARD_INITIAL_STATE,
    payload: { dashboardId }
  });
};

interface SetInitialState {
  type: dashboardTypes.DASHBOARD_INITIAL_STATE;
  payload: {
    dashboardId: string;
    layoutType: number;
    drawerVisible: boolean;
  };
}

export const setInitialState = ({
  dashboardId,
  layoutType,
  drawerVisible = false
}: {
  dashboardId: string;
  layoutType: number;
  drawerVisible: boolean;
}) => (dispatch: Dispatch<SetInitialState>) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_INITIAL_STATE,
    payload: {
      dashboardId,
      layoutType,
      drawerVisible
    }
  });
};

interface ResetDashboardSelectedTab {
  type: dashboardTypes.DASHBOARD_RESET_SELECTED_TAB;
  payload: { dashboardId: string };
}

export const resetDashboardSelectedTab = ({
  dashboardId
}: {
  dashboardId: string;
}) => (dispatch: Dispatch<ResetDashboardSelectedTab>) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_RESET_SELECTED_TAB,
    payload: { dashboardId }
  });
};

interface SetSelectedTab {
  type: dashboardTypes.DASHBOARD_SET_SELECTED_TAB;
  payload: {
    dashboardId: string;
    componentId: string;
    tabIndex: string;
  };
}
export const setSelectedTab = ({
  dashboardId,
  componentId,
  tabIndex
}: {
  dashboardId: string;
  componentId: string;
  tabIndex: string;
}) => (dispatch: Dispatch<SetSelectedTab>) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_SET_SELECTED_TAB,
    payload: { dashboardId, tabIndex, componentId }
  });
  return true;
};

interface SetDrawerVisibility {
  type: dashboardTypes.DASHBOARD_SET_DRAWER;
  payload: {
    dashboardId: string;
    visible: boolean;
  };
}

export const setDrawerVisibility = ({
  dashboardId,
  visible
}: {
  dashboardId: string;
  visible: boolean;
}) => (dispatch: Dispatch<SetDrawerVisibility>) => {
  dispatch({
    type: dashboardTypes.DASHBOARD_SET_DRAWER,
    payload: { dashboardId, visible }
  });
};

export type DashboardActionTypes =
  | SetInitialDashboardState
  | SetInitialState
  | ResetDashboardSelectedTab
  | SetSelectedTab
  | SetDrawerVisibility;
