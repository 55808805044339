import React, { FC } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import WebeatResource from './WebeatResource';
import { IResource } from '../ChallengeInterfaces';

interface IResourceForm extends FormComponentProps {
  challengeResources: IResource[];
  resource: IResource;
  index: number;
  primaryKey: string;
  refreshChallengeData: () => Promise<void>;
}

const WebeatResourceForm: FC<IResourceForm> = ({
  challengeResources,
  form,
  resource,
  index,
  refreshChallengeData,
}) => {
  return (
    <Form className="ResourceForm">
      <WebeatResource
        {...{
          challengeResources,
          collapse: true,
          form,
          index,
          refreshChallengeData,
          resource,
        }}
      />
    </Form>
  );
};

export default Form.create<IResourceForm>({ name: 'resource_form' })(
  WebeatResourceForm,
);
