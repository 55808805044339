import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const ParametersDashboard = (props: any) => {
  const platformComboFields = isConPlatform()
    ? []
    : [
        {
          title: props.intl.formatMessage({
            id: 'labels.parameter-type',
          }),
          key: 'parameterType.name',
          position: 3,
          mustRender: true,
          type: 'combo',
          comboId: 'boParametersType',
          mandatory: false,
          disabled: false,
          visible: true,
          render: 'text',
        },
      ];
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'parametersSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'parameterType.uuidParameterType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: props.intl.formatMessage({ id: 'labels.parameter-type' }),
          key: 'parameterType.uuidParameterType',
          type: 'combo',
          operand: 'equal',
          comboId: 'boParametersType',
          mandatory: false,
          disabled: false,
        },
        {
          title: props.intl.formatMessage({ id: 'challenge.name' }),
          key: 'parameterType.name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: props.intl.formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'parametersList',
        rowKey: 'idParameter',
        panelHeader: props.intl.formatMessage({
          id: 'labels.parameters',
        }),
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0,
        //customButtons: ['editExpirationPointsDates'],
        customButtons: ['uploadFilesButton'], //Todo This button allows to edit expirationPoints dates, NOW this behaivor is controlled by Magento.
      },
      fields: [
        ...platformComboFields,
        {
          title: props.intl.formatMessage({ id: 'boroles.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: props.intl.formatMessage({ id: 'boroles.value' }),
          key: 'value',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
          precision: 0,
        },
        {
          title: props.intl.formatMessage({ id: 'labels.specialty' }),
          key: 'parameterType.idSpecialty.name',
          position: 3,
          mustRender: true,
          type: 'text',
          mandatory: false,
          disabled: false,
          visible: true,
          render: 'text',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: '50',
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'parametersEdit',
        panelHeader: props.intl.formatMessage({
          id: 'labels.parameters-edit',
        }),
        primaryKey: 'idParameter',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'parameterType.name',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'parameterType.idSpecialty.name',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'value',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: props.intl.formatMessage({ id: 'labels.name' }),
          key: 'name',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: true,
        },
        {
          title: props.intl.formatMessage({
            id: 'labels.parameter-type',
          }),
          key: 'parameterType.name',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: true,
        },
        {
          title: props.intl.formatMessage({ id: 'labels.specialty' }),
          key: 'parameterType.idSpecialty.name',
          type: 'text',
          length: 100,
          mandatory: false,
          disabled: true,
        },
        {
          title: props.intl.formatMessage({ id: 'labels.value' }),
          key: 'value',
          type: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'parametersDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ParametersDashboard;
