import { IRow } from '../app/AppInterfaces';
import { ICompleteField } from '../forms/formInterfaces';

export default function customBehaviours(props: {
  field: ICompleteField;
  values: IRow;
  componentId: string;
  disabled?: boolean;
}): boolean {
  const { disabled } = props;
  let disabledField = disabled ? disabled : false;

  //custom layer

  return disabledField;
}
