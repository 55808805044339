import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import RenderDetail from '../TreeResource/TreeDetails';

type ConnectedSellOut = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IResourceTree;

const SellOut: FC<ConnectedSellOut> = ({
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  resource,
}): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items

  return (
    <>
      {details?.map(({ index, detail }) =>
        RenderDetail({
          accessToken,
          detail,
          detailArrayPosition: index,
          editRecursiveResource,
          editResource,
          form,
          resource,
          mainIdResource: resource.idResource,
        }),
      )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SellOut);
