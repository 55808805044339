import React, { FC } from 'react';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import { useIntl } from 'react-intl';
import { Checkbox, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import ScanLandingMarkdownInput from './ScanLandingMarkdown';
import UploadComponent from './UploadComponent';
import {
  UPLOAD_ACTION_VIDEO_MAIN,
  UPLOAD_ACTION_YOUTUBE_URL_MAIN,
} from '../../shared';
import { INPUT_MAX_LENGTH } from '../constants';

interface VideoProps {
  url: string;
  vertical?: boolean;
  thumbnail?: string;
}
export interface ScanLandingVideoComponentModel {
  title: string;
  description: string;
  autoplay?: boolean;
  controls?: boolean;
  mobile: VideoProps;
  tablet?: VideoProps;
  desktop?: VideoProps;
}

type DeviceOption = keyof Pick<
  ScanLandingVideoComponentModel,
  'mobile' | 'tablet' | 'desktop'
>;
const DEVICE_OPTIONS: DeviceOption[] = ['mobile', 'tablet', 'desktop'];
const REQUIRED_DEVICES: DeviceOption[] = ['mobile'];

const StyledCheckbox = ({ children, ...restProps }: CheckboxProps) => (
  <Checkbox {...restProps} style={{ fontSize: '16px' }}>
    {children}
  </Checkbox>
);

const ScanLandingVideoComponent: FC<
  ScanLandingComponentProps<ScanLandingVideoComponentModel> &
    ScanLandingComponentUploadProps
> = ({ index, form, values, token, disabled, handleOnChange }) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <div>
      <Form.Item
        label={formatMessage({ id: 'scanLanding.component.video.title' })}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
        }}>
        {getFieldDecorator(`components[${index}].model.title`, {
          initialValue: values.title ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.video.title.required-error',
              }),
            },
          ],
        })(
          <Input
            maxLength={INPUT_MAX_LENGTH}
            disabled={disabled}
            placeholder={formatMessage({
              id: 'scanLanding.component.video.title.placeholder',
            })}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'scanLanding.component.video.description',
        })}>
        {getFieldDecorator(`components[${index}].model.description`, {
          initialValue: values.description ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.video.description.required-error',
              }),
            },
          ],
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.description`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.description`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.description`, value);
            }}
          />,
        )}
      </Form.Item>
      <Row gutter={32} style={{ marginBottom: '24px' }}>
        <Col span={6}>
          {getFieldDecorator(`components[${index}].model.autoplay`, {
            initialValue: values.autoplay,
          })(
            <StyledCheckbox
              defaultChecked={values.autoplay}
              disabled={disabled}>
              <Tooltip
                title={formatMessage({
                  id: 'scanLanding.component.video.autoplay.tooltip',
                })}
                placement="bottomLeft">
                {formatMessage({
                  id: 'scanLanding.component.video.autoplay',
                })}
              </Tooltip>
            </StyledCheckbox>,
          )}
        </Col>
        <Col span={6}>
          {getFieldDecorator(`components[${index}].model.controls`, {
            initialValue: values.controls,
          })(
            <StyledCheckbox
              defaultChecked={values.controls}
              disabled={disabled}>
              <Tooltip
                title={formatMessage({
                  id: 'scanLanding.component.video.controls.tooltip',
                })}
                placement="bottomLeft">
                {formatMessage({
                  id: 'scanLanding.component.video.controls',
                })}
              </Tooltip>
            </StyledCheckbox>,
          )}
        </Col>
      </Row>
      <Collapse
        bordered={false}
        style={{
          fontSize: '16px',
          backgroundColor: '#f7f7f7',
        }}
        defaultActiveKey={REQUIRED_DEVICES.map((device) => `video.${device}`)}>
        {DEVICE_OPTIONS.map((device) => (
          <Collapse.Panel
            key={`video.${device}`}
            showArrow={false}
            header={formatMessage({
              id: `scanLanding.component.video.${device}`,
            })}>
            <Row style={{ marginBottom: '24px' }}>
              <Col span={6}>
                {getFieldDecorator(
                  `components[${index}].model.${device}.vertical`,
                  { initialValue: values[device]?.vertical },
                )(
                  <StyledCheckbox
                    defaultChecked={values[device]?.vertical}
                    disabled={disabled}>
                    <Tooltip
                      title={formatMessage({
                        id: 'scanLanding.component.video.vertical.tooltip',
                      })}
                      placement="right">
                      {formatMessage({
                        id: `scanLanding.component.video.vertical`,
                      })}
                    </Tooltip>
                  </StyledCheckbox>,
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col sm={24} md={12}>
                <Form.Item
                  label={formatMessage({
                    id: `scanLanding.component.video.url`,
                  })}>
                  {getFieldDecorator(
                    `components[${index}].model.${device}.url`,
                    {
                      initialValue: values[device]?.url ?? '',
                      rules: [
                        {
                          required: REQUIRED_DEVICES.includes(device),
                          message: formatMessage({
                            id: `scanLanding.component.video.url.required-error`,
                          }),
                        },
                      ],
                    },
                  )(
                    <UploadComponent
                      {...{
                        value: values[device]?.url ?? '',
                        setValue: (value) => {
                          handleOnChange(
                            `components[${index}].model.${device}.url`,
                            value,
                            true,
                          );
                        },
                        fieldName: `components[${index}].model.${device}.url`,
                        token,
                        disabled,
                        format: 'video',
                        uploadActions: [
                          UPLOAD_ACTION_VIDEO_MAIN,
                          UPLOAD_ACTION_YOUTUBE_URL_MAIN,
                        ],
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label={formatMessage({
                    id: `scanLanding.component.video.thumbnail`,
                  })}>
                  {getFieldDecorator(
                    `components[${index}].model.${device}.thumbnail`,
                    {
                      initialValue: values[device]?.thumbnail ?? '',
                    },
                  )(
                    <UploadComponent
                      {...{
                        value: values[device]?.thumbnail ?? '',
                        setValue: (value) => {
                          handleOnChange(
                            `components[${index}].model.${device}.thumbnail`,
                            value,
                            true,
                          );
                        },
                        fieldName: `components[${index}].model.${device}.thumbnail`,
                        token,
                        disabled,
                        format: 'image',
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ScanLandingVideoComponent;
