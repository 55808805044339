import { heroSectionFields } from './components/hero/hero.fields';
import { productsSectionFields } from './components/products/products.fields';
import { secondaryHeroSectionFields } from './components/secondaryHero/secondaryhero.fields';
import {
  HomeSectionListKey,
  WebeatHomeSectionType,
} from './WebeatHomeSectionEnum';

export const WebeatHomeSectionsConfig: {
  [key in keyof typeof WebeatHomeSectionType]: {
    fields: {
      key: string;
      label: string;
      subLabel?: string;
      type: string;
      placeholder: string;
      required?: boolean;
      maxLength?: number;
    }[];
  };
} = {
  [WebeatHomeSectionType.Hero]: {
    fields: heroSectionFields,
  },
  [WebeatHomeSectionType.Categories]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Categories',
        type: 'input',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.HeroBlock]: {
    fields: secondaryHeroSectionFields,
  },
  [WebeatHomeSectionType.Products]: {
    fields: productsSectionFields,
  },
  [WebeatHomeSectionType.Community]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Community',
        type: 'input',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Experience]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Experience',
        type: 'input',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Testimonials]: {
    fields: [],
  },
  [WebeatHomeSectionType.EnderHero]: {
    fields: [],
  },
};

export interface IHomeSection {
  active: boolean;
  key: HomeSectionListKey;
  content?: string;
  type: keyof typeof WebeatHomeSectionsConfig;
  required?: boolean;
}
