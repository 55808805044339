import { Button, Checkbox, Icon, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, Fragment } from 'react';
import UploadComponent from '../../../../../challenge/Components/UploadComponent';
import Item from 'antd/lib/list/Item';
import { typesOfSelectEnum, UPLOAD_ACTION_IMAGE } from '../../../../../shared';
import Text from 'antd/lib/typography/Text';
import FormItem from 'antd/lib/form/FormItem';
import { useSelector } from 'react-redux';
import { isValidUrl } from '../validate.fields';

interface ProductImageUploadProps {
  fieldConfig: any;
  handleChange: (key: string, value: any) => void;
  constentState?: any;
  index: number;
  homeId: string | undefined;
}

const ProductImageUpload: FC<ProductImageUploadProps> = ({
  fieldConfig,
  handleChange,
  constentState,
  index,
  homeId,
}) => {
  const DUMMY_IMAGE_UUID = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384y';
  const { auth } = useSelector((state: any) => state);
  return (
    <Fragment>
      <Title level={4}>{'*Image'}</Title>
      <Checkbox
        checked={constentState[fieldConfig.key][index]['checkFile'] || false}
        onChange={(e) =>
          handleChange(
            fieldConfig.key,
            constentState[fieldConfig.key].map((item: Item, idx: number) =>
              idx === index
                ? {
                    ...item,
                    checkFile: e.target.checked,
                    checkUrlImage: !e.target.checked,
                    file: null,
                  }
                : item,
            ),
          )
        }>
        Upload Image
      </Checkbox>
      <br />
      {constentState[fieldConfig.key][index]['checkFile'] && (
        <div className="webeat-home-section-hero-block">
          <br />
          <UploadComponent
            format={'image'}
            token={auth.accessToken}
            uploadActions={[UPLOAD_ACTION_IMAGE]}
            materialType={typesOfSelectEnum.IMAGE}
            primaryEntityId={homeId || DUMMY_IMAGE_UUID}
            value={constentState[fieldConfig.key][index]['file']}
            key={constentState[fieldConfig.key][index]['file']}
            module="home"
            handleChangeField={(data: any) => {
              handleChange(
                fieldConfig.key,
                constentState[fieldConfig.key].map((item: Item, idx: number) =>
                  idx === index ? { ...item, file: data } : item,
                ),
              );
            }}
            setValue={function (valueItem?: string | null | undefined): void {
              handleChange(
                fieldConfig.key,
                constentState[fieldConfig.key].map((item: Item, idx: number) =>
                  idx === index ? { ...item, file: valueItem } : item,
                ),
              );
            }}>
            <Button
              className="uploadButton uploadButton__margin-top"
              disabled={false}>
              <Icon type="upload" />
              <span>Upload </span>
            </Button>
          </UploadComponent>
          <Text>Recommended resolution: 608x760px </Text>
          <br />
        </div>
      )}
      <Checkbox
        checked={
          constentState[fieldConfig.key][index]['checkUrlImage'] || false
        }
        onChange={(e) =>
          handleChange(
            fieldConfig.key,
            constentState[fieldConfig.key].map((item: Item, idx: number) =>
              idx === index
                ? {
                    ...item,
                    checkUrlImage: e.target.checked,
                    checkFile: !e.target.checked,
                    url: null,
                  }
                : item,
            ),
          )
        }>
        Enter an URL Image
      </Checkbox>
      {constentState[fieldConfig.key][index]['checkUrlImage'] && (
        <Fragment>
          <br />
          <FormItem
            className="webeat-home-section-hero-block"
            colon={false}
            validateStatus={
              !isValidUrl(constentState[fieldConfig.key][index]['url'])
                ? 'error'
                : ''
            }
            required={fieldConfig.required}
            help={
              !isValidUrl(constentState[fieldConfig.key][index]['url'])
                ? 'The URL format is not valid.'
                : ''
            }>
            <Input
              placeholder="Enter an URL"
              value={constentState[fieldConfig.key][index]['url'] || ''}
              onChange={(e) =>
                handleChange(
                  fieldConfig.key,
                  constentState[fieldConfig.key].map(
                    (item: Item, idx: number) =>
                      idx === index ? { ...item, url: e.target.value } : item,
                  ),
                )
              }
            />
          </FormItem>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductImageUpload;
