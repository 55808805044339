import React, { useState } from 'react';
import { Button } from 'antd';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ChallengeTypeEnum } from '../../challenge/Enums';
import { generateTrainingReport } from '../../challenge/Components/GenerateReports';
import { isTableProps } from '../../utils/propsUtils';
import { useIntl } from 'react-intl';

export default function TrainingUsersButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, isLoading } = tableProps.props;

    const handleGenerateReport = async (idChallenge: number) => {
      setLoading(true);
      try {
        const response = await api.getDataCallById({
          dataPath: apiPaths.CALL.TRAINING_REPORT,
          registerId: idChallenge,
          callConfig: {},
        });

        setLoading(false);
        if (response?.status === 200 && response.data) {
          generateTrainingReport({ ...response.data, formatMessage });
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    const showButton = [
      ChallengeTypeEnum.FORMATION,
      ChallengeTypeEnum.TALK,
      ChallengeTypeEnum.CAMPAIGN,
      ChallengeTypeEnum.CONGRESS
    ].includes(selectedRow?.idChallengeType?.idChallengeType);

    if (showButton)
      return (
        <Button
          onClick={() => handleGenerateReport(selectedRow.idChallenge)}
          disabled={!selectedRow?.idChallenge}
          loading={isLoading || loading}
          type="primary">
          Training Report
        </Button>
      );
  }
  return <></>;
}
