import React from 'react';
import { connect } from 'react-redux';
import {
  getM2MTableData,
  addM2Mrecord,
  removeM2Mrecord,
  setGetSelected,
  setM2MTableParams
} from './m2mActions';
import { IFormComponent } from '../forms/formInterfaces';
import { ITableComponent } from '../tables/tableInterfaces';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import './M2M.css';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { TM2MEditButtonListProps } from './M2MEditButton';

interface OwnProps {
  isM2M: boolean;
}

export type M2MDashboardListProps = ReturnType<typeof mapDispatchToProps> &
  OwnProps &
  TM2MEditButtonListProps;

class M2MDashboard extends React.PureComponent<M2MDashboardListProps, {}> {
  constructor(props: M2MDashboardListProps) {
    super(props);
    const { components, setM2MTableParams } = this.props;
    components.forEach((component: ITableComponent | IFormComponent) => {
      if (component.params.type === 'table') {
        component.fields.forEach((field: any) => {
          if (field.defaultSortOrder !== undefined) {
            setM2MTableParams({
              field: field.key,
              sort: field.defaultSortOrder
            });
          }
        });
      }
    });
  }

  render() {
    const dashboardProps = this.props.customDashboard
      ? { ...this.props, customDashboard: this.props.dashboardId }
      : { ...this.props };
    return <DashboardInitializer {...dashboardProps} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getM2MTableData,
      addM2Mrecord,
      removeM2Mrecord,
      setGetSelected,
      setM2MTableParams
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(M2MDashboard);
