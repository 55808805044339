import React, { FC,  useState } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import '../Challenge.css';
import { Select, Row, Button, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FormattedMessage } from 'react-intl';

interface Props extends FormComponentProps {
  isLoading: boolean;
  users: null | string[];
  setUsers: React.Dispatch<React.SetStateAction<null | string[]>>;
  validateUsers: (list:string[]) => Promise<void>;
  actionType: boolean;
  setActionType: React.Dispatch<React.SetStateAction<boolean>>;
  restrictionsHaveChanged: boolean;
  setRestrictionsHaveChanged: React.Dispatch<React.SetStateAction<boolean>>;
  newUsers: string[];
  setNewUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const checkEmailValue = (originalValue: string): string | null => {
  const _value = originalValue.trim();
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isCorrect = re.test(String(_value).toLowerCase());
  return isCorrect ? _value : null;
};

export const addEmailToArray = (array: string[], value: string) => {
  const newArray = [...array];
  if (checkEmailValue(value) && !newArray.includes(checkEmailValue(value)!))
    newArray.push(checkEmailValue(value)!);

  return newArray;
};

const AdminVisibilityForm: FC<Props> = ({
  actionType,
  isLoading,
  form,
  restrictionsHaveChanged,
  users,
  newUsers,
  setActionType,
  setRestrictionsHaveChanged,
  setUsers,
  validateUsers,
  setNewUsers,
}) => {
  const [listUsers, setListUsers] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const addNewValues = async (value: string[]) => {
    if (!value || value.length === 0) {
      setNewUsers([]);
      await validateUsers([]);
      return;
    }
  
    const selectUsers: string[] = [...(newUsers?.slice() ?? [])];
  
    value.forEach((_user) => {
      if (!selectUsers.includes(_user)) {
        selectUsers.push(_user);
      }
    });
  
    setNewUsers(selectUsers);
    form.setFieldsValue({ note: [...selectUsers] });
    setRestrictionsHaveChanged(true);
  
    await validateUsers(selectUsers);
  };

  const handleRemoveUser = (record: any) => {

    const newUsers = [...users!];
    newUsers.forEach((user, i) => {
      if (user === record.email) newUsers.splice(record.key, 1);
    });

    const selectUsers: string[] = form.getFieldValue('note');
    selectUsers?.forEach((user: string, i: number) => {
      if (user === record.email) selectUsers.splice(record.key, 1);
    });
    form.setFieldsValue({ note: selectUsers });
    setRestrictionsHaveChanged(true);
    setUsers(newUsers);
  };

  const handleChangeAction = (value: boolean) => {
    setActionType(value);
    setRestrictionsHaveChanged(true);
  };

  const getDataSource = (users: null | string[]) => {
    if (users === null) return [];
    return users.map((_user: string, i: number) => {
      return { key: i, email: _user };
    });
  };

  const handleRemoveAllUsers = () => {
    const selectUsers: string[] = form.getFieldValue('note');
    selectUsers?.forEach((user: string, i: number) => {
      users?.forEach(record => {
        if (user === record) selectUsers.splice(i, 1);
      })
    });
    form.setFieldsValue({ note: selectUsers });
    setRestrictionsHaveChanged(true);
    setUsers([]);
  };

  const getColumns = [
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: ' ',
      key: 'action',
      render: (text: string, record: any) => (
        <Row type="flex" justify="end">
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => handleRemoveUser(record)}>
            Remove
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Form className="visibilityForm">
      {form.getFieldDecorator('actionType', {
        initialValue: actionType ? actionType.toString() : 'false',
      })(
        <Select
          style={{ maxWidth: '300px' }}
          onChange={(value: string, options: any) =>
            handleChangeAction(value === 'true' ? true : false)
          }>
          <Select.Option key={'true'} value={'true'}>
            White list: Only show on listed users
          </Select.Option>
          <Select.Option key={'false'} value={'false'}>
            Black list: Don't show on listed users
          </Select.Option>
        </Select>,
      )}
      <p className='visibilityForm__subtitle'>
        <FormattedMessage id={actionType?"visibility-form.whitelist":"visibility-form.blacklist" }/>
      </p>
      <TextArea
        style={{ height: '185px', resize: 'vertical', marginTop: '10px'}}
        rows={1000}
        cols={50}
        onChange={(e) => {
          const users: string[] = e.target.value
            .split('\n')
            .filter((user) => user !== '');
          setListUsers(users ?? []);
          setIsButtonDisabled(users.length === 0);
        }}
        placeholder="Users emails"
      />
      <Row
        type="flex"
        justify="end"
        className={
          users && users.length
            ? 'visibilityForm__button'
            : 'visibilityForm__footer'
        }>
      </Row>
      <Row
        className="visibilityForm__title"
        type="flex"
        justify="space-between">
        <div>Users List</div>
      </Row>
      <Row type="flex" justify="center">
        <Table
          className="visibilityForm__table"
          dataSource={getDataSource(users)}
          columns={getColumns}
          size="small"
        />
      </Row>
      <Row className= "visibilityForm__buttons" type="flex" justify="space-between"> 
        <Button
          disabled={isButtonDisabled && listUsers.length === 0 && !restrictionsHaveChanged}
          type="primary"
          loading={isLoading}
          onClick={() => addNewValues(listUsers)}>
          Save
        </Button>
        {users && users?.length > 0 && <Button className="visibilityForm__remove-all"
          type="primary"
          onClick={() => handleRemoveAllUsers()}>
          Remove all
        </Button>}
      </Row>
    </Form>
  );
};

export default Form.create<Props>({ name: 'challenge_visibility_form' })(
  AdminVisibilityForm,
);
