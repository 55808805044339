import React from 'react';
export default function app() {
  return (
    <>
      <svg className="svgIconFill" viewBox="0 0 270 270">
        <g>
          <g>
            <path
              d="M114,0H10C4.5,0,0,4.5,0,10v104c0,5.5,4.5,10,10,10h104c5.5,0,10-4.5,10-10V10C124,4.5,119.5,0,114,0z M104,104H20V20h84
			V104z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M260,0H156c-5.5,0-10,4.5-10,10v104c0,5.5,4.5,10,10,10h104c5.5,0,10-4.5,10-10V10C270,4.5,265.5,0,260,0z M250,104h-84
			V20h84V104z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M114,146H10c-5.5,0-10,4.5-10,10v104c0,5.5,4.5,10,10,10h104c5.5,0,10-4.5,10-10V156C124,150.5,119.5,146,114,146z
			 M104,250H20v-84h84V250z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M260,146H156c-5.5,0-10,4.5-10,10v104c0,5.5,4.5,10,10,10h104c5.5,0,10-4.5,10-10V156C270,150.5,265.5,146,260,146z
			 M250,250h-84v-84h84V250z"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
