import { ResourceTypeEnum } from '../../challenge/Enums';
import { isPINPlatform } from '../../utils/platformUtils';

const {
  VIDEO,
  IMAGE,
  TEXT: YOUTUBE_URL,
  VIDEO_URL_VIMEO,
  DOCUMENT,
  QUIZ: PDF_FILE,
} = ResourceTypeEnum;

export enum typesOfSelectEnum {
  YOUTUBE_URL = 1,
  VIDEO = 2,
  IMAGE = 3,
  FILE = 4,
  DOCUMENT = 5,
  IMAGE_URL = 70,
  VIDEO_URL_VIMEO = 88,
}

export interface IUploadActions {
  icon: string;
  label?: string;
  id: typesOfSelectEnum;
}

export const UPLOAD_ACTION_IMAGE: IUploadActions = {
  id: typesOfSelectEnum.IMAGE,
  icon: 'file-image',
  label: 'challenge.featured.coverImage',
};

export const UPLOAD_ACTION_IMAGE_URL: IUploadActions = {
  id: typesOfSelectEnum.IMAGE_URL,
  icon: 'global',
  label: 'challenge.media.image-url',
};

export const UPLOAD_ACTION_VIDEO: IUploadActions = {
  id: typesOfSelectEnum.VIDEO,
  icon: 'video-camera',
  label: 'challenge.media.load_video',
};

export const UPLOAD_ACTION_YOUTUBE_URL: IUploadActions = {
  id: typesOfSelectEnum.YOUTUBE_URL,
  icon: 'youtube',
  label: isPINPlatform()
    ? 'challenge.media.youtube.video-PIN'
    : 'challenge.media.youtube.video',
};

export const UPLOAD_ACTION_VIMEO_URL: IUploadActions = {
  id: typesOfSelectEnum.VIDEO_URL_VIMEO,
  icon: 'heat-map',
  label: isPINPlatform()
    ? 'challenge.media.vimeo.video-PIN'
    : 'challenge.media.vimeo.video',
};

export const UPLOAD_ACTION_VIDEO_MAIN: IUploadActions = {
  id: typesOfSelectEnum.VIDEO,
  icon: 'video-camera',
  // TODO fix this label
  label: '',
};

export const UPLOAD_ACTION_YOUTUBE_URL_MAIN: IUploadActions = {
  id: typesOfSelectEnum.YOUTUBE_URL,
  icon: 'youtube',
  // TODO fix this label
  label: '',
};

export const UPLOAD_ACTION_VIMEO_URL_MAIN: IUploadActions = {
  id: typesOfSelectEnum.VIDEO_URL_VIMEO,
  icon: 'heat-map',
  // TODO fix this label
  label: '',
};

export const UPLOAD_ACTION_FILE: IUploadActions = {
  id: typesOfSelectEnum.FILE,
  icon: 'file',
  label: 'challenge.media.file',
};

export const UPLOAD_ACTION_DOCUMENT: IUploadActions = {
  id: typesOfSelectEnum.DOCUMENT,
  icon: 'file',
  label: 'challenge.media.document',
};

export interface IFileDimensions {
  width: number;
  height: number;
}

export const isYoutubeFormat = (videoID: string) => {
  if (!videoID) return false;
  const idSymbols = /^[a-zA-Z0-9-_]{11}$/;
  const isValid = videoID.length === 11 && idSymbols.test(videoID);
  return isValid;
};

export const isVimeoFormat = (videoID: string) => {
  if (!videoID) return false;
  const idSymbols = /^[0-9-_]{9}$/;
  const isValid = videoID.length === 9 && idSymbols.test(videoID);
  return isValid;
};

export const isVideoFileFormat = (videoURL: string) => {
  if (!videoURL) return false;
  const VIDEO_VALID_FORMATS = ['ogg', 'mpeg', 'mp4', 'webm', 'x-msvideo'];
  const format = videoURL.split('.');

  const isValid = VIDEO_VALID_FORMATS.includes(format[format.length - 1]);

  return isValid;
};

export const isDocumentFileFormat = (videoURL: string) => {
  if (!videoURL) return false;
  const DOCUMENT_VALID_FORMATS = ['pdf', 'doc', 'xls'];
  const format = videoURL.split('.');

  const isValid = DOCUMENT_VALID_FORMATS.includes(format[format.length - 1]);

  return isValid;
};

export const isImageFileFormat = (imageUrl: string) => {
  if (!imageUrl) return false;
  const IMAGE_VALID_FORMATS = [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'webimage',
    'svg',
    'svg+xml',
    'webp',
  ];
  const format = imageUrl.toLowerCase().split('.');

  const isValid = IMAGE_VALID_FORMATS.includes(format[format.length - 1]);

  return isValid;
};

export const getFormatByMaterialType = (
  materialType?: typesOfSelectEnum,
): 'image' | 'pdf' | 'video' | 'document' => {
  switch (materialType) {
    case typesOfSelectEnum.VIDEO:
    case typesOfSelectEnum.YOUTUBE_URL:
    case typesOfSelectEnum.VIDEO_URL_VIMEO:
      return 'video';
    case typesOfSelectEnum.FILE:
      return 'pdf';
    case typesOfSelectEnum.DOCUMENT:
      return 'document';
    default:
      return 'image';
  }
};

export const getURLType = (
  value: string,
  format: 'image' | 'pdf' | 'video' | 'document',
  materialType?: typesOfSelectEnum,
  uploadActions?: IUploadActions[],
): ResourceTypeEnum => {
  let _type: ResourceTypeEnum = YOUTUBE_URL;
  switch (true) {
    case uploadActions && uploadActions?.length === 1:
      const action = uploadActions?.pop();
      switch (action?.id) {
        case UPLOAD_ACTION_IMAGE.id:
          _type = IMAGE;
          break;
        case UPLOAD_ACTION_VIDEO.id:
          _type = VIDEO;
          break;
        case UPLOAD_ACTION_YOUTUBE_URL.id:
          _type = YOUTUBE_URL;
          break;
        case UPLOAD_ACTION_VIMEO_URL.id:
          _type = VIDEO_URL_VIMEO;
          break;
        case UPLOAD_ACTION_DOCUMENT.id:
          _type = DOCUMENT;
      }
      break;

    case !!materialType:
      switch (materialType) {
        case typesOfSelectEnum.VIDEO:
          _type = VIDEO;
          break;
        case typesOfSelectEnum.YOUTUBE_URL:
          _type = YOUTUBE_URL;
          break;
        case typesOfSelectEnum.FILE:
          _type = PDF_FILE;
          break;
        case typesOfSelectEnum.DOCUMENT:
          _type = DOCUMENT;
          break;
        default:
          _type = IMAGE;
          break;
      }
      break;

    case isYoutubeFormat(value):
      _type = YOUTUBE_URL;
      break;
    case isVimeoFormat(value):
      _type = VIDEO_URL_VIMEO;
      break;
    case isVideoFileFormat(value):
      _type = VIDEO;
      break;
    case isImageFileFormat(value):
      _type = IMAGE;
      break;
    case isDocumentFileFormat(value):
      _type = DOCUMENT;
      break;
  }

  return _type;
};
