import { comboTypes } from './comboTypes';
import { ComboActionTypes } from './comboActions';
import { ComboData } from './ComboInterfaces';

export interface ComboState {
  combos: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          isLoading: boolean;
          data: ComboData[];
        };
      };
    };
  };
  combosCustom: {
    [key: string]: {
      data: ComboData[];
    };
  };
}

const initialState: ComboState = {
  combos: {},
  combosCustom: {},
};

export function comboReducer(
  state = initialState,
  action: ComboActionTypes,
): ComboState {
  switch (action.type) {
    case comboTypes.COMBO_INITIALIZE_COMPONENT: {
      return {
        ...state,
        combos: {
          ...state.combos,
          [action.payload.componentId]: {},
        },
      };
    }
    case comboTypes.COMBO_INITIALIZE_COMBO: {
      return {
        ...state,
        combos: {
          ...state.combos,
          [action.payload.componentId]: {
            ...state.combos[action.payload.componentId],
            [action.payload.fieldKey]: {},
          },
        },
      };
    }
    case comboTypes.COMBO_FETCHING_DATA:
      return {
        ...state,
        combos: {
          ...state.combos,
          [action.payload.componentId]: {
            ...state.combos[action.payload.componentId],
            [action.payload.fieldKey]: {
              ...state.combos[action.payload.componentId][
                action.payload.fieldKey
              ],
              [action.payload.comboId]: {
                ...state.combos[action.payload.componentId][
                  action.payload.fieldKey
                ][action.payload.comboId],
                isLoading: action.payload.isLoading,
              },
            },
          },
        },
      };
    case comboTypes.COMBO_FETCHED_DATA:
      return {
        ...state,
        combos: {
          ...state.combos,
          [action.payload.componentId]: {
            ...state.combos[action.payload.componentId],
            [action.payload.fieldKey]: {
              ...state.combos[action.payload.componentId][
                action.payload.fieldKey
              ],
              [action.payload.comboId]: {
                ...state.combos[action.payload.componentId][
                  action.payload.fieldKey
                ][action.payload.comboId],
                isLoading: action.payload.isLoading,
                data: [...action.payload.data],
              },
            },
          },
        },
      };
    case comboTypes.COMBO_ADD_CUSTOM:
      return {
        ...state,
        combosCustom: {
          ...action.payload.data,
        },
      };
    default:
      return state;
  }
}
