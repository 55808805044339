import React from 'react';
export default function center() {
  return (
    <svg viewBox="0 0 512 512" className="svgIconFill">
      <g id="XMLID_2340_">
        <g id="XMLID_630_">
          <g id="XMLID_631_">
            <path
              id="XMLID_806_"
              d="m502 444h-20v-388c0-5.523-4.478-10-10-10h-73.667v-36c0-5.523-4.478-10-10-10h-264.666c-5.522 0-10 4.477-10 10v36h-73.667c-5.522 0-10 4.477-10 10v388h-20c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h201.004c5.522 0 10-4.477 10-10s-4.478-10-10-10h-191.004v-28h472v28h-191.002c-5.522 0-10 4.477-10 10s4.478 10 10 10h201.002c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-368.333-424h244.666v26h-244.666zm112.333 424h-52v-60c0-30.782 22.553-56.385 52-61.182zm20 0v-121.182c29.447 4.797 52 30.4 52 61.182v60zm72 0v-60c0-45.215-36.785-82-82-82s-82 36.785-82 82v60h-124v-378h412v378z"
            />
            <path
              id="XMLID_825_"
              d="m136 110h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_828_"
              d="m232 110h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_831_"
              d="m328 110h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_834_"
              d="m424 110h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1471_"
              d="m136 206h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1482_"
              d="m232 206h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1487_"
              d="m328 206h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1490_"
              d="m424 206h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_632_"
              d="m136 302h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1513_"
              d="m424 302h-48c-5.522 0-10 4.477-10 10v48c0 5.523 4.478 10 10 10h48c5.522 0 10-4.477 10-10v-48c0-5.523-4.478-10-10-10zm-10 48h-28v-28h28z"
            />
            <path
              id="XMLID_1522_"
              d="m256 492c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.069 5.21 2.93 7.07c1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.069-2.93c1.86-1.86 2.931-4.44 2.931-7.07s-1.07-5.21-2.931-7.07c-1.859-1.86-4.439-2.93-7.069-2.93z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
