import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

interface PageWYSProps {
  title: string;
  value: string;
  readonly?: boolean;
  height?: number;
  handleEdit: (value: string) => void;
}

const WYSWYGLiteComponent = ({
  handleEdit,
  readonly,
  title,
  value,
}: PageWYSProps) => {
  const { formatMessage } = useIntl();
  const textArea = useRef(null);

  const handleEditorChange = (event: FocusEvent) => {
    const {
      // @ts-ignore
      target: { innerHTML },
    } = event;

    handleEdit(innerHTML);
  };
  useLayoutEffect(() => {
    let _textArea = Jodit.make(textArea.current || '#wyswygLiteComponent', {
      controls: {
        font: {
          list: {
            'Mulish,sans-serif': 'Mulish',
          },
        },
      },
      cleanHTML: {
        fillEmptyParagraph: false,
      },
      readonly,
      removeButtons: [
        'about',
        'dots',
        'brush',
        'font',
        'find',
        'selectall',
        'cut',
        'copy',
        'paste',
        'print',
        'undo',
        'redo',
        'video',
      ],
    });

    _textArea.events.on('changeElementValue', function (command) {
      console.log({ command });
    });

    _textArea.events.on('blur', (value: FocusEvent) =>
      handleEditorChange(value),
    );

    // @ts-ignore
    textArea.current = _textArea;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textArea]);

  useEffect(() => {
    const element = textArea.current;

    return () => {
      // @ts-ignore
      element.destruct();
      textArea.current = element;
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (textArea?.current?.value !== value) {
      // @ts-ignore
      textArea.current.value = value;
    }
  }, [value]);

  useEffect(() => {
    // @ts-ignore
    if (textArea?.current?.readonly !== readonly) {
      // @ts-ignore
      textArea.current.setReadOnly(readonly);
      // @ts-ignore
      textArea.current.setDisabled(readonly);
    }
  }, [readonly]);

  return (
    <>
      <div className="ChallengeBuilder__leftCol--longDescription">
        {title ?? formatMessage({ id: 'challenge.descriptionLong' })}
      </div>
      <div style={{ clear: 'both' }} />
      <textarea ref={textArea} />
    </>
  );
};

export default WYSWYGLiteComponent;
