import config from '../../config';
import { ITableComponent } from '../../tables/tableInterfaces';
import { ISearchComponent } from '../../forms/formInterfaces';

export const userSearch = (intl: any): ISearchComponent => {
  const { formatMessage } = intl;

  return {
    params: {
      type: 'search',
      componentId: 'limitexceptionsusersSearch',
    },
    groups: [
      {
        index: 0,
        title: '',
        mustRender: true,
        subgroups: [
          {
            index: 0,
            title: '',
            mustRender: true,
            yPosition: 0,
            xPosition: 0,
            width: 24,
            fields: [
              {
                key: 'email',
                yPosition: 0,
                xPosition: 0,
                mustRender: true,
                width: 12,
              },
            ],
          },
        ],
      },
    ],
    fields: [
      {
        title: formatMessage({id: 'users.email'}),
        key: 'email',
        type: 'text',
        operand: 'like',
        initialValue: '',
        min: 0,
        length: 50,
        mandatory: false,
        disabled: false,
        globalSearch: true,
      },
    ],
    settings: {
      fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
      fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
    },
  };
};


export const userTable = ({
  intl,
}: {
  intl: any;
}): ITableComponent => {
  const { formatMessage } = intl;

  return {
    params: {
      type: 'table',
      componentId: 'limitexceptionsusersList',
      rowKey: 'idUser',
      panelHeader: 'Users List',
      navigationParams: '',
      isMainTable: true,
      tableType: 0,
      prettierKey: 'name'
    },
    fields: [
      {
        title: formatMessage({id: 'users.firstName'}),
        key: 'firstname',
        position: 0,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: formatMessage({id: 'users.lastName'}),
        key: 'lastname',
        position: 0,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: false,
      },
      {
        title: formatMessage({id: 'users.email'}),
        key: 'email',
        position: 0,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: 'Salesforce ID',
        key: 'idSalesforce',
        position: 1,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: 'UUID',
        key: 'uuidUser',
        position: 2,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: 'Magento ID',
        key: 'customerId',
        position: 3,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: formatMessage({id: 'users.maxAllowedAnnual'}),
        key: 'max_total_products_by_period_annual',
        position: 4,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      },
      {
        title: formatMessage({id: 'users.status'}),
        key: 'userStatus',
        render: 'combo',
        comboId: 'userStatus',
        position: 5,
        mustRender: true,
        align: 'left',
        sorter: false,
        visible: true,
      }
    ],
    settings: {
      size: config.COMPONENT.TABLE.SIZE,
      bordered: config.COMPONENT.TABLE.BORDERED,
      editable: false,
      multiple: false,
      canCreate: false,
      // canDelete: false,
      scroll: { x: true },
    },
    pagination: {
      defaultPageSize: 50,
      pageSizeOptions: ['5', '10', '20', '50'],
      showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
      showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
      size: config.COMPONENT.TABLE.SIZE,
    },
  };
};
