import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const CacheManagerDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'cacheManagerSearch',
      },
      groups: [],
      fields: [],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },

    {
      params: {
        type: 'table',
        componentId: 'cacheManagerList',
        rowKey: 'key',
        panelHeader: 'Manage cache',
        navigationParams: props.query.params.q || '',
        prettierKey: 'key',
        isMainTable: true,
        tableType: 0,
        customButtons: ['cleanAllCacheButton', 'cleanCacheButton'],
      },
      fields: [
        {
          title: props.intl.formatMessage({ id: 'boroles.name' }),
          key: 'key',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: '50',
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'cacheManageDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default CacheManagerDashboard;
