import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Item from 'antd/lib/list/Item';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import React, { FC, Fragment } from 'react';
import {
  PRODUCT_DESCRIPTION_MAX_LENGTH,
} from './../constants';
interface ProductDescriptionProps {
  fieldConfig: any;
  handleChange: (key: string, value: any) => void;
  constentState?: any;
  index: number;
}
const ProductDescription: FC<ProductDescriptionProps> = ({
  fieldConfig,
  handleChange,
  constentState,
  index,
}) => {
  return (
    <Fragment>
      <Title level={4}>{'*Product Description'}</Title>
      <Text>{`It should contain a maximum caracters of: ${PRODUCT_DESCRIPTION_MAX_LENGTH}`}</Text>
      <FormItem
        className="webeat-home-section-hero-block"
        colon={false}
        validateStatus={
          constentState[fieldConfig.key][index]['description'] < PRODUCT_DESCRIPTION_MAX_LENGTH
            ? 'error'
            : ''
        }
        required={true}
        help={
          constentState[fieldConfig.key][index]['description'] < PRODUCT_DESCRIPTION_MAX_LENGTH
            ? `The description must be at least ${PRODUCT_DESCRIPTION_MAX_LENGTH} characters long.`
            : ''
        }>
        <Input
          value={constentState[fieldConfig.key][index]['description'] || ''}
          onChange={(e) =>
            handleChange(
              fieldConfig.key,
              constentState[fieldConfig.key].map((item: Item, idx: number) =>
                idx === index ? { ...item, description: e.target.value } : item,
              ),
            )
          }
          placeholder={'Enter a description'}
          maxLength={PRODUCT_DESCRIPTION_MAX_LENGTH}
        />
      </FormItem>
    </Fragment>
  );
};

export default ProductDescription;
