import React, { useEffect, useState } from 'react';
import * as api from '../../api';
import apiPaths from '../../apiPaths';

import { Modal, Row, Select } from 'antd';

import { ComboData } from '../../combos/ComboInterfaces';

interface ISurveyModal {
  id: number;
  surveyModalVisibility?: boolean;
  setSurveyModalVisibility: (visibility: boolean) => void;
  isMail?: boolean;
}

export default function AddSurveyModal({
  id,
  surveyModalVisibility,
  setSurveyModalVisibility,
}: ISurveyModal) {
  const [loading, setLoading] = useState(false);
  const [surveys, setSurveys] = useState<ComboData[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<string | null>(null);

  useEffect(() => {
    async function getInitialData() {
      setLoading(true);

      async function getCombos() {
        try {
          const response = await api.getCombo({
            id: apiPaths.COMBODATA.SURVEYS,
          });

          return response.data;
        } catch (err) {
          console.error(err);
          return [];
        }
      }

      async function getResourceSurvey() {
        try {
          const response = await api.getDataCallById({
            dataPath: apiPaths.CALL.MAIL_SURVEY,
            registerId: id,
            callConfig: {},
          });

          return response.data;
        } catch (err) {
          console.error(err);
          return {};
        }
      }

      try {
        let [combos, component] = await Promise.all([
          getCombos(),
          getResourceSurvey(),
        ]);
        if (combos.length) setSurveys(combos);
        if (component?.survey?.idSurvey)
          setSelectedSurvey(component?.survey.idSurvey.idSurvey);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.group(error);
      }
    }

    if (surveyModalVisibility) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyModalVisibility]);

  const handleSelect = (option: string) => {
    setSelectedSurvey(option);
  };

  const handleClose = () => {
    setLoading(false);
    setSurveys([]);
    setSelectedSurvey(null);
    setSurveyModalVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let data = { idSurvey: selectedSurvey, idMail: id };

      await api.putDataCall({
        dataPath: apiPaths.CALL.SURVEY_MAIL,
        data,
        callConfig: {},
      });

      handleClose();
    } catch (err) {
      setLoading(false);
      console.group(err);
    }
  };

  return (
    <Modal
      visible={surveyModalVisibility}
      onCancel={handleClose}
      onOk={handleSubmit}>
      <Row style={{ minHeight: '20vh' }}>
        <Row style={{ marginBottom: '60px', fontSize: '1.6rem' }}>
          SET A SURVEY
        </Row>
        {!surveys.length ? (
          <div>{loading ? 'Loading....' : 'No surveys available'}</div>
        ) : (
          <Select
            loading={loading}
            value={selectedSurvey?.toString()}
            onSelect={(value: string, option: React.ReactElement) => {
              handleSelect(value);
            }}>
            {surveys.map((survey: ComboData) => (
              <Select.Option value={survey.value.toString()} key={survey.value}>
                {survey.description}
              </Select.Option>
            ))}
          </Select>
        )}
      </Row>
    </Modal>
  );
}
