import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Row, Button, notification, Modal, Tabs, Input } from 'antd';

import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import './i18nComponent.css';

interface Props {
  i18Key: string;
  id: number;
  originalValue: string;
  path: string;
  rowKey: string;
  selectedRow: IRow;
  descriptionKey?: string;
  contentKey?: string;
  originalDescription?: string;
}

export default function I18nComponent(props: Props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [languages, setLanguages] = useState<IRow[]>([]);
  const [i18nData, setI18nData] = useState<IRow[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [selectedTab, setSelectedTab] = useState('0');

  const intl = useIntl();
  const {
    i18Key,
    id,
    originalValue,
    path,
    rowKey,
    selectedRow,
    descriptionKey,
    contentKey = 'content',
    originalDescription,
  } = props;

  useEffect(() => {
    if (!isEmpty(i18nData)) {
      setInputValue(getInputValue());
      if (descriptionKey)
        setDescriptionValue(getInputValue(undefined, descriptionKey));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nData]);

  const getInputValue = (key?: string, descriptionKey?: string) => {
    let value = '';
    const tabKey = key !== undefined ? key : selectedTab;
    let selectedLanguage = languages[parseInt(tabKey)].idLanguage;

    if (!i18nData.length) return value;
    i18nData.forEach((_translation) => {
      if (_translation.idLanguage === selectedLanguage)
        value = descriptionKey
          ? _translation[descriptionKey]
          : _translation[contentKey];
    });
    return value;
  };

  const renderModal = (callBack: Function) => {
    if (dataChanged) {
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          callBack();
        },
        onCancel() {},
      });
    } else {
      callBack();
    }
  };

  const handleCloseModal = () => {
    function closeModal() {
      setInputValue('');
      setDescriptionValue('');
      setI18nData([]);
      setModalVisible(false);
      setDataChanged(false);
    }

    renderModal(closeModal);
  };

  const handleOpenModal = async () => {
    let _languages: AxiosResponse<any> | null = null;

    if (!languages.length) {
      _languages = await api.getDataCall({
        dataPath: apiPaths.CALL.LANGUAGES,
        callConfig: {},
      });
    }

    const _i18n = await api.getDataCallById({
      dataPath: path,
      registerId: id,
      callConfig: {},
    });

    if (
      (languages.length || (!languages.length && _languages!.status === 200)) &&
      _i18n.status === 200
    ) {
      if (_languages !== null) {
        const filteredLanguages = _languages.data.content.filter(
          (_lang: IRow) => !_lang.defaultLanguage,
        );
        setLanguages(filteredLanguages);
      }
      setI18nData(_i18n.data);
      setModalVisible(true);
    } else {
      notification.warning({ message: 'Cannot get translations' });
    }
  };

  const handleSelectTab = (key: string) => {
    function changeTab() {
      setSelectedTab(key);
      setInputValue(getInputValue(key));
      if (descriptionKey)
        setDescriptionValue(getInputValue(key, descriptionKey));
      setDataChanged(false);
    }
    renderModal(changeTab);
  };

  const handleSaveTranslation = async () => {
    let i18nPrimaryKey;
    let response: AxiosResponse<any>;

    let data: { [key: string]: any } = {
      idLanguage: languages[parseInt(selectedTab)].idLanguage,
      idParent: { [rowKey]: selectedRow[rowKey] },
      language: languages[parseInt(selectedTab)].codDevelop,
      [contentKey]: inputValue,
    };

    if (descriptionKey) data = { ...data, description: descriptionValue };

    i18nData.forEach((_translation) => {
      if (_translation.idLanguage === data.idLanguage)
        i18nPrimaryKey = _translation[i18Key];
    });

    if (i18nPrimaryKey !== undefined)
      response = await api.putDataCall({
        dataPath: path,
        data: { ...data, [i18Key]: i18nPrimaryKey },
        callConfig: {},
      });
    else
      response = await api.postDataCall({
        dataPath: path,
        data,
      });

    if ((await response.status) === 200) {
      setI18nData(response.data);
      setDataChanged(false);
    } else {
      notification.warning({ message: 'Translation cannot be saved' });
    }
  };

  const handleResetTranslation = () => {
    function resetForm() {
      setInputValue(getInputValue());
      if (descriptionKey)
        setDescriptionValue(getInputValue(undefined, descriptionKey));
      setDataChanged(false);
    }

    renderModal(resetForm);
  };

  const handleChangeInput = (value: string) => {
    setDataChanged(true);
    setInputValue(value);
  };

  const handleChangeTitle = (value: string) => {
    setDataChanged(true);
    setDescriptionValue(value);
  };

  const renderTabs = languages.length && (
    <Tabs activeKey={selectedTab} onChange={handleSelectTab}>
      {languages.map((_language: IRow, i: number) => (
        <Tabs.TabPane tab={`${_language.name}`} key={i.toString()}>
          <Row className="i18n__originalText">
            Original value: {originalValue}
          </Row>
          <Row className="i18n__input">
            <Input
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value)
              }
            />
          </Row>
          {descriptionKey && (
            <>
              <Row className="i18n__originalText">
                Original value: {originalDescription}
              </Row>
              <Row className="i18n__input">
                <Input.TextArea
                  value={descriptionValue}
                  onChange={(
                    evt: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => handleChangeTitle(evt.target.value)}
                />
              </Row>
            </>
          )}
          <Row className="i18n__buttons" type="flex" justify="end">
            <Button type="primary" onClick={handleSaveTranslation}>
              Save
            </Button>
            <Button
              className="i18n__buttons--cancel"
              onClick={handleResetTranslation}>
              Cancel
            </Button>
          </Row>
        </Tabs.TabPane>
      ))}
    </Tabs>
  );

  return (
    <>
      <Button
        style={{ marginRight: '17px' }}
        onClick={() => handleOpenModal()}
        disabled={isEmpty(selectedRow)}
        type="primary">
        Translations
      </Button>
      <Modal
        visible={modalVisible}
        className="assignModal"
        onCancel={handleCloseModal}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'60%'}>
        <Row className="i18n__title">TRANSLATION</Row>
        <Row className="i18n__tabs">{renderTabs}</Row>
      </Modal>
    </>
  );
}
