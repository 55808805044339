import React from 'react';
import { useIntl } from 'react-intl';
import Modal from 'antd/es/modal';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Row, Button } from 'antd';
import { IRow } from '../../app/AppInterfaces';

interface IButtonsProps {
  buttonDisabled: boolean;
  form: WrappedFormUtils<any>;
  formHasChanged: boolean;
  isLoading: boolean;
  setLoading: any;
  handleResetPage: () => void;
  handleSaveRecord: (
    e: React.MouseEvent<Element, MouseEvent>,
    form: WrappedFormUtils<any>,
  ) => void;
  selectedRow: IRow;
}

export default function HeaderButtons({
  buttonDisabled,
  form,
  formHasChanged,
  handleResetPage,
  handleSaveRecord,
  isLoading,
  setLoading,
  selectedRow,
}: IButtonsProps) {
  /*   const [translationsModalVisible, setTranslationsModalVisible] = useState(
    false,
  ); */
  const intl = useIntl();

  const handleReset = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        handleResetPage();
        form.resetFields();
      },
      onCancel() {},
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {/* {ContentTranslations({
        isLoading,
        setLoading,
        selectedRow,
        setTranslationsModalVisible,
        translationsModalVisible,
        disabled: formHasChanged || buttonDisabled
      })} */}

      <Row
        type="flex"
        justify="end"
        // className="PageContent__headerButtons"
        style={{ minHeight: '50px' }}>
        <>
          <Button
            className="PageContent__headerButtons--save"
            type="primary"
            onClick={(e: React.MouseEvent) => handleSaveRecord(e, form)}
            loading={isLoading}
            disabled={!formHasChanged || buttonDisabled}>
            {intl.formatMessage({ id: 'form.save' })}
          </Button>
          <Button
            className="PageContent__headerButtons--restore"
            type="primary"
            onClick={(e: React.MouseEvent) => handleReset()}
            loading={isLoading}
            disabled={!formHasChanged || buttonDisabled}>
            {intl.formatMessage({ id: 'form.reset' })}
          </Button>
        </>
      </Row>
    </div>
  );
}
