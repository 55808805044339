import { ITableRenderListProps } from '../tables/TableRender';
import { EditFormRenderProps } from '../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';

export function isTableProps(
  table: ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
): table is ITableRenderListProps {
  return (table as ITableRenderListProps).props.params.type === 'table';
}

export function isEditProps(
  edit: ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
): edit is EditFormRenderProps {
  return (edit as EditFormRenderProps).props.params.type === 'edit';
}
