import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import AnswerResourceDermo from './AnswerResourceDermo';

const {
  QUESTION_VIDEO_IS_VERTICAL,
  QUESTION_IMAGE_URL,
  QUESTION_JUSTIFICATION,
  QUESTION_VIDEO_URL,
  QUESTION_SHOW_STATISTICS,
} = ResourceTypeDetailEnum;

interface IQuestionMedia {
  _detail: IResourceDetail;
  index: number;
  value: string;
}

interface QuestionResourceDermo {
  handleAddDetail?: (
    newDetail: IResourceDetail,
    parentResource?: IResource,
  ) => void;
  handleDeleteDetail?: (
    detailArrayPosition: number,
    parentResource?: IResource,
  ) => void;
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  questionIndex: number;
  values: IRow;
}

type QuestionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  QuestionResourceDermo;

const QuestionResourceDermo = (props: QuestionProps) => {
  const {
    //handleDeleteDetail,
    //handleAddDetail,
    _resource,
    editRecursiveResource,
    editResource,
    form,
    questionIndex,
    accessToken,
    combos,
    values,
  } = props;
  const questionResource = _resource;
  const details = _resource.resourceDetailList!;
  let detailsToRender: IndexedDetail[] = [];

  details.forEach((_detail: IResourceDetail, index: number) => {
    const { idResourceTypeD } = _detail.idResourceTypeD;

    switch (idResourceTypeD) {
      case QUESTION_VIDEO_IS_VERTICAL:
      case QUESTION_VIDEO_URL:
      case QUESTION_IMAGE_URL:
      case QUESTION_JUSTIFICATION:
      case QUESTION_SHOW_STATISTICS:
        break;
      default:
        detailsToRender.push({ index, detail: _detail });
        break;
    }
  });

  return (
    <>
      {/*
      // * --------------------------------------------  Render Question Details
      */}
      {detailsToRender
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === ResourceTypeDetailEnum.QUESTION_TYPE) {
            combo =
              combos.challengechallengeEdit?.type?.questionType?.data ?? [];
          }

          return RenderDetail({
            combo,
            accessToken,
            detail,
            detailArrayPosition: index,
            parentArrayPosition: questionIndex,
            editRecursiveResource,
            editResource,
            form,
            resource: _resource,
            mainIdResource: _resource.idResource,
            values,
          });
        })}
      {/*
      // * --------------------------------------------  Renders Answers
      */}
      {questionResource.resourceList?.length && (
        <>
          {questionResource.resourceList.map(
            (answer, answerIndex, questionAnswers) =>
              answer.status && (
                <AnswerResourceDermo
                  key={questionIndex.toString().concat(answerIndex.toString())}
                  {...{
                    form,
                    _resource: answer,
                    questionIndex,
                    answerIndex,
                    idQuestion: questionResource.idResource,
                    editResource,
                    answersNumber: questionResource.resourceList?.filter(
                      (_list) => _list.status,
                    ).length,
                    questionType: detailsToRender?.find(({ detail }) => {
                      return (
                        detail.idResourceTypeD.idResourceTypeD ===
                        ResourceTypeDetailEnum.QUESTION_TYPE
                      );
                    }),
                    questionAnswers: questionAnswers.filter((answer: any) => {
                      return answer.status;
                    }),
                  }}
                />
              ),
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionResourceDermo);
