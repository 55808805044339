import { Col, Input, Row, Select, Tabs } from 'antd';
import Form, { ValidationRule } from 'antd/lib/form';
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isConPlatform } from '../../utils';
import { comboRender, ContentPageEnum } from '../shared';
import HeaderButtons from './HeaderButtons';
import { IEditField } from '../../fields/FieldsInterfaces';
import WYSWYGComponent from './WYSWYGComponent';
import {
  FormProps,
  HOME,
  HOME_PUBLIC,
  RenderFormItemProps,
  StaticPageItemParams,
  splitCamelCase,
} from './WebeatPageFormUtils';
import WebeatStaticPageItemList from './WebeatStaticPageItemList';

const WebeatContentPageForm: FC<FormProps> = ({
  combos,
  editComponent,
  form,
  formHasChanged,
  handleChangeField,
  handleResetPage,
  handleSaveRecord,
  isLoading,
  params,
  selectedRow,
  setLoading,
  sortingComponents,
  values,
  token,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;
  const { componentId } = params;
  // Roles Select would be only visible to config the 'private home' type
  const [isRoleVisible, setIsRoleVisible] = useState(false);

  // If has files instaed an url in static page items
  const [hasFiles, setHasFiles] = useState<Map<number, boolean>>(new Map());

  // Seo Page
  const seoFieldsConfig: IEditField[] = [
    {
      title: formatMessage({ id: 'contentPage.pageTitle' }),
      key: 'pageTitle',
      type: 'text',
      length: 250,
      mandatory: false,
      disabled: false,
      initialDisabled: false,
      initialMandatory: false,
    },
    {
      title: formatMessage({ id: 'contentPage.pageDescription' }),
      key: 'pageDescription',
      type: 'text',
      length: 250,
      mandatory: false,
      disabled: false,
      initialDisabled: false,
      initialMandatory: false,
    },
    {
      title: formatMessage({ id: 'contentPage.pageKeywords' }),
      key: 'pageKeywords',
      type: 'text',
      length: 250,
      mandatory: false,
      disabled: false,
      initialDisabled: false,
      initialMandatory: false,
    },
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    let isPrivatePage = false;

    form.resetFields(['idSpecialty', 'slug']);

    if (values?.publicPage !== undefined)
      if (
        typeof values?.publicPage === 'string' &&
        values?.publicPage === 'false'
      ) {
        isPrivatePage = true;
      } else {
        isPrivatePage = !values?.publicPage;
      }

    if (values?.idSpecialty && !isPrivatePage) {
      delete values.idSpecialty;
    }
    // ? Roles Select would be only visible to config the 'private home' type
    if (Number(values?.type) === ContentPageEnum.HOME && isPrivatePage) {
      setIsRoleVisible(true);
    } else {
      setIsRoleVisible(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.type, values?.publicPage]);

  useEffect(() => {
    if (!values) return;
    const { footer, header, publicPage, type } = form.getFieldsValue();
    const {
      footer: _footer,
      header: _header,
      type: _type,
      idSpecialty: _idSpecialty,
    } = values;

    // Set default value to match fields value
    // if (_publicPage === undefined) values.publicPage = '';
    if (_type === undefined) values.type = '';

    handleChangeField({
      id: 'header',
      type: 'checkSelect',
      value: header === 'true',
    });
    handleChangeField({
      id: 'footer',
      type: 'checkSelect',
      value: footer === 'true',
    });
    handleChangeField({
      id: 'publicPage',
      type: 'checkSelect',
      value: publicPage === 'true',
    });

    if (footer !== _footer?.toString() || header !== _header?.toString()) {
      form.setFieldsValue({
        ...values,
        footer,
        header,
        publicPage,
        type,
        idSpecialty: _idSpecialty ? _idSpecialty.toString() : undefined,
      });
    }
  });

  // Disable, select ... methods
  const isSelectorDisabled = (key: string): boolean => {
    const { publicPage } = form.getFieldsValue();

    if (isConPlatform()) {
      switch (key) {
        case 'idRoleType':
          // ? Role select disabled except for the 'Home' type
          if (Number(values?.type) !== ContentPageEnum.HOME) return true;

          return !(publicPage === 'false');
        case 'idSpecialty':
          return values?.type !== ContentPageEnum.HOME;
        default:
          break;
      }
    }
    // ! Following code is only for PIN:
    // ! Select for Role is not yet rendered so the key would be always 'idSpecialty'
    // ! Comment this line to can select specialty in all of option of type when create/edit static pages
    // if (type && type.toString() !== ContentPageEnum.HOME.toString()) return true;
    if (publicPage) {
      const isPublicPage = publicPage.toString() === 'true';
      return isPublicPage;
    }
    return false;
  };

  // Control static page item isFileImage property
  useEffect(() => {
    if (values?.items) {
      const initMap = new Map<number, boolean>();
      setHasFiles(initMap);

      (values?.items || []).forEach(
        (item: StaticPageItemParams, index: number) => {
          initMap?.set(index, item.isFileImage ?? false);
        },
      );
      setHasFiles(initMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const onChangeIsFileImage = (e: any, idx: number) => {
    const currentMap = hasFiles;
    currentMap.set(idx, e.target.checked);
    setHasFiles(currentMap);

    const newValuesItems = [...values.items];
    newValuesItems[idx].isFileImage = e.target.checked;

    handleChangeField({
      type: 'checkSelect',
      id: 'items',
      value: [...newValuesItems],
    });
  };

  // Add or delete items from ingredients or brands page
  const addPageItem = () => {
    const newValuesItems = [
      ...(values.items ?? []),
      { image: '', name: null, category: '' },
    ];

    //Set new item.isFileImage prop to the existent map
    const newHasFilesIndex = newValuesItems.length - 1;
    setHasFiles(
      hasFiles.set(newHasFilesIndex >= 0 ? newHasFilesIndex : 0, false),
    );

    handleChangeField({
      id: 'items',
      type: 'Array',
      value: newValuesItems,
    });
  };
  const deletePageItem = (index: number) => {
    const newValuesItems = values.items
      ? values.items?.filter((_: any, idx: number) => idx !== index)
      : [];
    handleChangeField({
      id: 'items',
      type: 'Array',
      value: newValuesItems,
    });
  };

  // On change for items from ingredients or brands page
  const handleInnerChangePageItemProp = (
    value: any,
    key: string,
    idx: number,
  ) => {
    const newItemValues = {
      ...values.items[idx],
      [key]: value,
    };

    handleChangeField({
      id: 'items',
      type: 'Array',
      value: values.items?.map((item: any, index: number) =>
        index === idx ? newItemValues : item,
      ),
    });
  };

  // Custom on change footer seo Items
  const handleOnChangeFooterSeoProp = (
    value: any,
    key: string,
    idx: number,
  ) => {
    const newValues = {
      ...values?.staticPageFooterSeos?.[idx],
      [key]: value,
    };

    handleChangeField({
      id: 'staticPageFooterSeos',
      type: 'Array',
      value:
        values?.staticPageFooterSeos?.map((seo: any, index: number) =>
          index === idx ? newValues : seo,
        ) ?? '',
    });
  };

  // Render components methods
  const renderFormItem = (props: RenderFormItemProps) => {
    const {
      key,
      component,
      boolean,
      size,
      notMandatory,
      index,
      arrayKey,
      isCompoundLabel,
      maxLength,
    } = props;
    let initialValue;
    let isMandatory = !notMandatory;

    if (key === 'idRoleType') {
      initialValue =
        values && values[key]
          ? values.idRoleType
          : formatMessage({ id: 'contentPage.idRoleType' });
      isMandatory = false;
    } else if (key === 'legalAnnotations' || key === 'footerSeoTitle') {
      switch (key) {
        case 'legalAnnotations':
          initialValue = values && values[key] ? values.legalAnnotations : '';
          break;
        case 'footerSeoTitle':
          initialValue = values && values[key] ? values.footerSeoTitle : '';
          break;
        default:
          initialValue = '';
          break;
      }
      isMandatory = false;
    } else if (boolean) {
      initialValue =
        values && typeof values[key] === 'boolean'
          ? values[key].toString()
          : 'true';
    } else if (key === 'isBrand') {
      initialValue = values[key] || false;
    } else if ((index || index === 0) && arrayKey) {
      if (arrayKey.type === 'item') {
        initialValue = values.items[Number(index)]?.[arrayKey.paramName] ?? '';
        if (key.includes('order')) {
          initialValue = values.items[Number(index)]?.[arrayKey.paramName] ?? 0;
        }
        if (key.includes('isFileImage')) {
          initialValue = initialValue =
            values.items[Number(index)]?.[arrayKey.paramName] ?? false;
        }
      } else if (arrayKey.type === 'seo') {
        initialValue =
          values?.staticPageFooterSeos?.[Number(index)]?.[arrayKey.paramName] ??
          '';

        if (key.includes('description')) {
          const currentFormValues = form.getFieldsValue();

          isMandatory =
            currentFormValues?.[`staticPageFooterSeos`]?.[index]?.subtitle
              ?.length >= 1;
        }
      } else {
        initialValue = '';
      }
    } else {
      initialValue = values && values[key] ? values[key].toString() : '';
    }

    const rules: ValidationRule[] | undefined = [
      {
        required: isMandatory,
        message: `${
          key.includes('items') || key.includes('staticPageFooterSeos')
            ? splitCamelCase(key.split('.')[1])
            : splitCamelCase(key)
        } is required`,
      },
    ];

    if (maxLength) {
      rules.push({
        max: maxLength,
        message: `${
          key.includes('items') || key.includes('staticPageFooterSeos')
            ? splitCamelCase(key.split('.')[1])
            : splitCamelCase(key)
        } can only contain ${maxLength} characters.`,
      });
    }

    return (
      <Col
        xs={size?.xs ?? 24}
        md={size?.md ?? 12}
        lg={size?.lg ?? 6}
        xl={size?.xl ?? 6}>
        <Form.Item
          label={
            isCompoundLabel
              ? formatMessage(
                  {
                    id: `contentPage.items[{itemsIndex}].${key.split('.')[1]}`,
                  },
                  {
                    itemsIndex: index,
                  },
                )
              : formatMessage({ id: `contentPage.${key}` })
          }>
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules,
          })(component)}
        </Form.Item>
      </Col>
    );
  };

  const renderSeoFormItem = (field: IEditField) => {
    const key = field.key;
    const initialValue = values && values[key] ? values[key].toString() : '';

    return (
      <Col xs={24} md={12} key={key}>
        <Form.Item label={formatMessage({ id: `contentPage.${key}` })}>
          {getFieldDecorator(key, {
            initialValue: initialValue,
          })(
            <Input
              type={field.type}
              title={field.title}
              size="large"
              maxLength={field.length}
              placeholder={formatMessage({
                id: `contentPage.${key}.placeholder`,
              })}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeField({
                  type: field.type,
                  id: key,
                  value: e.target.value,
                })
              }
            />,
          )}
        </Form.Item>
      </Col>
    );
  };

  const renderFooterSeo = (idx: number): React.JSX.Element => {
    return (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}>
        <Col xs={24} sm={10}>
          {renderFormItem({
            key: `staticPageFooterSeos[${idx}].subtitle`,
            component: (
              <Input
                style={{ clear: 'both' }}
                size="large"
                placeholder="Subtitle"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleOnChangeFooterSeoProp(e.target.value, `subtitle`, idx)
                }
              />
            ),
            size: { lg: 24, xl: 24 },
            notMandatory: true,
            index: idx,
            arrayKey: { paramName: 'subtitle', type: 'seo' },
          })}
        </Col>
        <Col span={2}></Col>
        <Col xs={24} sm={12}>
          {renderFormItem({
            key: `staticPageFooterSeos[${idx}].description`,
            component: (
              <WYSWYGComponent
                title={''}
                height={400}
                handleEdit={(value) =>
                  handleOnChangeFooterSeoProp(value, `description`, idx)
                }
                value={
                  values?.[`staticPageFooterSeos`]?.[idx]?.[`description`] ?? ''
                }
              />
            ),
            size: { lg: 24, xl: 24 },
            index: idx,
            arrayKey: { paramName: 'description', type: 'seo' },
          })}
        </Col>
      </Row>
    );
  };

  return (
    <Form className="pageForm">
      <HeaderButtons
        {...{
          buttonDisabled: sortingComponents,
          form,
          formHasChanged,
          handleResetPage,
          handleSaveRecord,
          isLoading,
          setLoading,
          selectedRow,
        }}
      />
      <Tabs animated={false}>
        <Tabs.TabPane
          tab={formatMessage({ id: 'contentPage.group.static-page' })}
          key={'0'}
          className="tabContainer">
          <Row type="flex" gutter={[24, 0]} className="pageForm__container">
            {renderFormItem({
              key: 'name',
              component: (
                <Input
                  disabled={editComponent}
                  style={{ clear: 'both' }}
                  size="large"
                  placeholder={formatMessage({
                    id: 'contentPage.name.placeholder',
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField({
                      id: 'name',
                      type: 'text',
                      value: e.target.value,
                    })
                  }
                />
              ),
            })}
            {renderFormItem({
              key: 'slug',
              component: (
                <Input
                  disabled={
                    [HOME, HOME_PUBLIC].includes(Number(values?.type)) ||
                    editComponent
                  }
                  style={{ clear: 'both' }}
                  size="large"
                  placeholder={formatMessage({
                    id: 'contentPage.slug.placeholder',
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField({
                      id: 'slug',
                      type: 'text',
                      value: e.target.value,
                    })
                  }
                />
              ),
            })}
            {renderFormItem({
              key: 'header',
              component: (
                <Select
                  optionFilterProp="children"
                  style={{ clear: 'both' }}
                  size="large"
                  disabled={editComponent}
                  onChange={(value: string) =>
                    handleChangeField({
                      id: 'header',
                      value: value === 'true',
                      type: 'checkSelect',
                    })
                  }
                  allowClear={false}>
                  <Select.Option key="header" value="true">
                    {formatMessage({ id: 'checkbox.true' })}
                  </Select.Option>
                  <Select.Option key="header" value="false">
                    {formatMessage({ id: 'checkbox.false' })}
                  </Select.Option>
                </Select>
              ),
              boolean: true,
            })}
            {renderFormItem({
              key: 'footer',
              component: (
                <Select
                  optionFilterProp="children"
                  style={{ clear: 'both' }}
                  size="large"
                  disabled={editComponent}
                  onChange={(value: string) =>
                    handleChangeField({
                      id: 'footer',
                      value: value === 'true',
                      type: 'checkSelect',
                    })
                  }
                  allowClear={false}>
                  <Select.Option key="footer" value="true">
                    {formatMessage({ id: 'checkbox.true' })}
                  </Select.Option>
                  <Select.Option key="footer" value="false">
                    {formatMessage({ id: 'checkbox.false' })}
                  </Select.Option>
                </Select>
              ),
              boolean: true,
            })}
            {renderFormItem({
              key: 'type',
              component: (
                <Select
                  showSearch
                  optionFilterProp="children"
                  // allowClear={!field.hasOwnProperty('initialValue')}
                  dropdownMatchSelectWidth={false}
                  allowClear
                  size="large"
                  disabled={
                    (values &&
                      values.idContentPage !== undefined &&
                      values.idContentPage !== null) ||
                    editComponent
                  }
                  placeholder={formatMessage({
                    id: 'contentPage.type.placeholder',
                  })}
                  notFoundContent={formatMessage({
                    id: 'combo.data.notfound',
                  })}
                  filterOption={(input: any, option: any) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value: any, data: any) => {
                    handleChangeField({
                      type: 'combo',
                      id: 'type',
                      value:
                        value >= 0 || typeof value === 'string'
                          ? data.key
                          : null,
                    });
                  }}>
                  {comboRender(
                    { comboId: 'contentPageType', key: 'type' },
                    combos,
                    componentId,
                  )}
                </Select>
              ),
            })}
            {renderFormItem({
              key: 'publicPage',
              component: (
                <Select
                  optionFilterProp="children"
                  style={{ clear: 'both' }}
                  size="large"
                  disabled={editComponent}
                  onChange={(value: string) =>
                    handleChangeField({
                      id: 'publicPage',
                      value: value === 'true',
                      type: 'checkSelect',
                    })
                  }
                  allowClear={false}>
                  <Select.Option key="publicPage" value="true">
                    {formatMessage({ id: 'checkbox.true' })}
                  </Select.Option>
                  <Select.Option key="publicPage" value="false">
                    {formatMessage({ id: 'checkbox.false' })}
                  </Select.Option>
                </Select>
              ),
              boolean: true,
            })}
            {isConPlatform() &&
              isRoleVisible &&
              renderFormItem({
                key: 'idRoleType',
                component: (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    dropdownMatchSelectWidth={false}
                    size="large"
                    disabled={isSelectorDisabled('idRoleType')}
                    notFoundContent={formatMessage({
                      id: 'combo.data.notfound',
                    })}
                    filterOption={(input: any, option: any) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value: any, data: any) => {
                      handleChangeField({
                        type: 'combo',
                        id: 'idRoleType',
                        value:
                          value >= 0 || typeof value === 'string'
                            ? data.key
                            : null,
                      });
                    }}>
                    {comboRender(
                      { comboId: 'roleType', key: 'idRoleType' },
                      combos,
                      componentId,
                    )}
                  </Select>
                ),
              })}
            {/* List of ingredients optional fields */}
            {Number(values?.type) === ContentPageEnum.LIST_OF_INGREDIENTS && (
              <>
                {/* TODO: ill be added in future features*/}
                {/* {renderFormItem({
                  key: 'isBrand',
                  component: (
                    <Switch
                      defaultChecked={values.isBrand}
                      disabled={false}
                      onChange={(checked: boolean) =>
                        handleChangeField({
                          type: 'checked',
                          id: 'isBrand',
                          value: checked,
                        })
                      }
                    />
                  ),
                })} */}
                <Col span={24}></Col>
                {renderFormItem({
                  key: 'titleItemsPage',
                  component: (
                    <Input
                      style={{ clear: 'both' }}
                      size="large"
                      placeholder="Ingredients page title..."
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeField({
                          id: 'titleItemsPage',
                          type: 'text',
                          value: e.target.value,
                        })
                      }
                    />
                  ),
                  size: { lg: 12, xl: 12 },
                  maxLength: 40,
                })}
                <Col span={24}></Col>
                {renderFormItem({
                  key: 'descriptionItemsPage',
                  component: (
                    <WYSWYGComponent
                      title={''}
                      height={400}
                      handleEdit={(value) =>
                        handleChangeField({
                          id: 'descriptionItemsPage',
                          type: 'text',
                          value: value,
                        })
                      }
                      value={values['descriptionItemsPage']}
                    />
                  ),
                  size: { lg: 12, xl: 12 },
                  maxLength: 400,
                })}
                {renderFormItem({
                  key: 'legalAnnotations',
                  component: (
                    <Input
                      style={{ clear: 'both' }}
                      size="large"
                      placeholder="Legal annotations"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeField({
                          id: 'legalAnnotations',
                          type: 'text',
                          value: e.target.value,
                        })
                      }
                    />
                  ),
                  size: { lg: 12, xl: 12 },
                  maxLength: 200,
                })}
              </>
            )}
          </Row>
        </Tabs.TabPane>
        {/* Seo page items */}
        {!!values?.type &&
          Number(values?.type) !== ContentPageEnum.HOME &&
          Number(values?.type) !== ContentPageEnum.LIST_OF_INGREDIENTS && (
            <Tabs.TabPane
              tab={formatMessage({ id: 'contentPage.group.static-custom-seo' })}
              key={'1'}
              className="tabContainer">
              {seoFieldsConfig.map((field) => {
                return (
                  <Row gutter={12} key={field.key}>
                    {renderSeoFormItem(field)}
                  </Row>
                );
              })}
            </Tabs.TabPane>
          )}
        {/* Footer seo tab for items list page */}
        {Number(values?.type) === ContentPageEnum.LIST_OF_INGREDIENTS && (
          <Tabs.TabPane
            tab={'Items page Footer SEO'}
            key={'1'}
            className="tabContainer">
            <>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                <Col xs={24} sm={10}>
                  {renderFormItem({
                    key: 'footerSeoTitle',
                    component: (
                      <Input
                        style={{ clear: 'both' }}
                        size="large"
                        placeholder="Footer Seo Title"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeField({
                            id: 'footerSeoTitle',
                            type: 'text',
                            value: e.target.value,
                          })
                        }
                      />
                    ),
                    size: { lg: 24, xl: 24 },
                  })}
                </Col>
              </Row>
              {/* Render footer seos --> Max. 5 items */}
              <>
                {[0, 1, 2, 3, 4].map((seo) => {
                  return renderFooterSeo(seo);
                })}
              </>
            </>
          </Tabs.TabPane>
        )}
        {/* List of items - ingredients or brands */}
        {Number(values?.type) === ContentPageEnum.LIST_OF_INGREDIENTS && (
          <Tabs.TabPane
            tab={'Items page ingredients'}
            key={'2'}
            className="tabContainer"
            style={{ marginBottom: '20px' }}>
            <WebeatStaticPageItemList
              combos={combos}
              values={values}
              addPageItem={addPageItem}
              componentId={componentId}
              deletePageItem={deletePageItem}
              handleInnerChangePageItemProp={handleInnerChangePageItemProp}
              hasFiles={hasFiles}
              onChangeIsFileImage={onChangeIsFileImage}
              renderFormItem={renderFormItem}
              token={token}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Form>
  );
};

export default Form.create<FormProps>({ name: 'content_page_form' })(
  WebeatContentPageForm,
);
