import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatDermoEventDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL } = config.COMPONENT.COLUMN;

  const componentId = 'WebeatDermoEventDashboard';

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatDermoEventSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'address',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'dermoEventPage.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'dermoEventPage.address' }),
          key: 'address',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    // Table
    {
      params: {
        type: 'table',
        componentId: 'webeatDermoEventList',
        rowKey: 'id',
        panelHeader: 'Dermo event list',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'dermoEventPage.name' }),
          key: 'name',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'dermoEventPage.address' }),
          key: 'address',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'dermoEventPage.date' }),
          key: 'date',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoEventPage.active' }),
          key: 'active',
          render: 'comboCustom',
          comboId: 'optional',
          position: 3,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    // Edit
    {
      params: {
        type: 'edit',
        componentId: 'webeatDermoEventEdit',
        panelHeader: 'Page Edit',
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: 'Events',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 10,
                },
                {
                  key: 'address',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 10,
                },
                {
                  key: 'date',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 10,
                },
                {
                  key: 'active',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 10,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'dermoEventPage.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
          behaviours: [],
        },
        {
          title: formatMessage({ id: 'dermoEventPage.description' }),
          key: 'description',
          type: 'textarea',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 200,
          mandatory: true,
          disabled: false,
          behaviours: [],
        },
        {
          title: formatMessage({ id: 'dermoEventPage.address' }),
          key: 'address',
          type: 'textarea',
          validationType: 'text',
          length: 250,
          unique: false,
          min: 0,
          mandatory: true,
          disabled: false,
          behaviours: [],
        },
        {
          title: formatMessage({
            id: 'dermoEventPage.event-date',
          }),
          key: 'date',
          type: 'datetime',
          comboId: 'optional',
          mandatory: true,
          disabled: false,
          behaviours: [],
        },
        {
          title: formatMessage({ id: 'dermoEventPage.active' }),
          key: 'active',
          type: 'comboCustom',
          comboId: 'optional',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatDermoEventDashboard);
