import { Col, Select, Button, Row } from 'antd';
import React, { useState } from 'react';
import { IRow } from '../app/AppInterfaces';
interface Props {
  centers: IRow[];
  handleSubmit: (idUser: number, idCenter: number) => Promise<void>;
  loading: boolean;
  idUser: number;
}

export function CenterSelection({
  centers,
  handleSubmit,
  loading,
  idUser,
}: Props) {
  const [selectedCenter, setSelectedCenter] = useState<string>();
  const [selectedCenterName, setSelectedCenterName] = useState<string>();

  const handleSelectCenter = (value: string) => {
    let center = centers.find((_center) => value === _center.name);
    setSelectedCenter(center?.idCenter);
    setSelectedCenterName(center?.name);
  };

  const filteredCenters = centers.filter(
    (center) => !selectedCenterName?.includes(center.name),
  );

  return (
    <Row type="flex" gutter={[24, 24]}>
      <Col xs={24} md={14}>
        <Select
          showSearch={true}
          value={selectedCenterName}
          loading={loading}
          onChange={(value: string) => handleSelectCenter(value)}>
          {filteredCenters
            .filter((center) => center.idSalesforce !== null)
            .map((center) => (
              <Select.Option key={center.idCenter} value={center.name}>
                {center.name}
              </Select.Option>
            ))}
        </Select>
      </Col>
      <Col xs={24} md={10}>
        <Row type="flex" justify="end">
          <Button
            disabled={!selectedCenter}
            type="primary"
            onClick={() => handleSubmit(idUser, parseInt(selectedCenter!))}>
            Assign
          </Button>
        </Row>
      </Col>
    </Row>
  );
}
