import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditFormRenderProps } from '../../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../../tables/TableRender';
import './CreateDataReplicaButton.css';
import { CreateDataReplicaForm } from './CreateDataReplicaForm';

export default function CreateDataReplicaButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();

  const [modalVisible, setModalVisible] = useState(false);

  const handleToggleModal = (state: boolean) => {
    setModalVisible(state);
  };

  const handleCancelModal = () => {
    Modal.confirm({
      title: formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        setModalVisible(false);
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Button type="primary" onClick={() => handleToggleModal(true)}>
        Create
      </Button>
      <Modal
        className="isdin-custom-modal"
        visible={modalVisible}
        footer={null}
        onCancel={() => handleToggleModal(false)}>
        <CreateDataReplicaForm
          handleModalVisibility={handleToggleModal}
          handleCancelModal={handleCancelModal}
        />
      </Modal>
    </>
  );
}
