import React from 'react';

import { message, notification } from 'antd';
import modal from 'antd/es/modal';

import config from '../config';
import IntlMessage from './IntlMessage';

message.config({
  top: config.FEEDBACK.MESSAGE.TOP_POSITION,
  maxCount: 1,
  duration: config.FEEDBACK.MESSAGE.DURATION
});

const feedbackTypes = {
  T_MESSAGE: 'message',
  T_NOTIFICATION: 'notification',
  T_MODAL: 'modal'
};
export const { T_NOTIFICATION, T_MODAL, T_MESSAGE } = feedbackTypes;

const feedbackServices: any = {
  message,
  notification,
  modal
};

const feedbackMethods: IFeedbackMethods = {
  M_SUCCESS: 'success',
  M_ERROR: 'warning',
  M_INFO: 'info',
  M_WARNING: 'warning',
  M_LOADING: 'loading',
  M_CONFIRM: 'confirm'
};

interface IFeedbackMethods {
  M_INFO: string;
  M_WARNING: string;
  M_SUCCESS: string;
  M_ERROR: string;
  M_LOADING: string;
  M_CONFIRM: string;
}

export const {
  M_INFO,
  M_WARNING,
  M_SUCCESS,
  M_ERROR,
  M_LOADING,
  M_CONFIRM
} = feedbackMethods;

/**
 * Shows a feedback message following several rules defined in the `payload`
 * parameter.
 *
 * @param {object} payload Map containing feedback rules. Allowed rules are:
 * - `type`: {string} Shape of the feedback component to raise. De allowed types
 *    are `T_MODAL`, `T_NOTIFICATION` and `T_MESSAGE`.
 * - `message`: {string} Content to show in the feedback component to raise.
 * - `duration`: {number} Time in seconds the feedback component will wait
 *    before self-destroy. Does not work with `MODAL` feedback type.
 * - `method`: {string} Logging level of the message. Can be `M_SUCCESS`, `M_INFO`,
 *    `M_WARNING`, `M_ERROR`, `M_LOADING` (only `T_MESSAGE` type components) or
 *    `M_CONFIRM` (only `T_MODAL` type components).
 * - `modalProps` {object} Configuration for the modal to raise (only if using
 *    `T_MODAL` type). The properties of this object **must** reference the _antd_
 *    `Modal` component in order for this rule to work. Refer to the
 *    [_AntDesign_ docs](https://ant.design/components/modal/#Modal.method())
 *    for more information about which properties can be used when implementing
 *    their modal component. As exceptions for better usability, `title` and
 *    `content` properties can be defined at the top level of the payload object
 *    instead of inside `modalProps`, having priority over the same both keys in
 *    `modalProps`.
 */

interface IFeedBack {
  type: string;
  message?: any;
  modalProps?: {
    title?: string;
    content?: string;
    onOk: Function;
  };
  duration?: number;
  title?: string;
  method?: string;
  action?: string;
}

export const feedback = (payload: IFeedBack) => {
  if (!config.FEEDBACK.MESSAGE.SHOW) {
    return;
  }

  const { type, message, duration = 4, method, title, modalProps } = payload;

  const feedbackService =
    type in feedbackServices
      ? feedbackServices[type]
      : feedbackServices.message;

  const feedbackMethodName: any = method
    ? Object.values(feedbackMethods).includes(method) && method
      ? method
      : M_WARNING
    : M_INFO;

  if (!(feedbackMethodName in feedbackService)) {
    throw new Error(
      `Unknown method '${feedbackMethodName}' for '${type}' feedback service.`
    );
  }

  const notificationDuration = duration ? duration : 4;

  const showFeedback = feedbackService[feedbackMethodName];

  switch (type) {
    case T_NOTIFICATION:
      showFeedback({
        message: title ? (
          <IntlMessage id={title} />
        ) : (
          <IntlMessage id={message} />
        ),
        description: title && message ? <IntlMessage id={message} /> : '',
        notificationDuration
      });
      break;

    case T_MESSAGE:
      if (message)
        showFeedback(<IntlMessage id={message} />, notificationDuration, null);
      break;

    case T_MODAL:
      return showFeedback({
        ...modalProps,
        title: title ? <IntlMessage id={title} /> : modalProps!.title || null,
        content: message ? (
          <IntlMessage id={message} />
        ) : (
          modalProps!.content || null
        )
      });

    default:
      console.warn(`Unknown feedback type '${type}'`);
      break;
  }
};

/**
 * Shows a feedback message which shape and configuration depend on the output
 * of an API response. Some responses contain the destructured parameters of this
 * function as an object value; you can use the outputs to let this controller
 * raise the most convenient feedback component.
 *
 * @param {string} action Label key describing performed action. Used to choose
 * the convenient feedback component.
 * @param {number} status HTTP status code for the result of the operation to feed back.
 * @param {string} message I18N key of the message to show in the feedback component
 * (not mandatory).
 */

export const feedbackController = ({
  action,
  status,
  message: msg
}: {
  action: string;
  status: number | string;
  message?: string;
}) => {
  if (!config.FEEDBACK.MESSAGE.SHOW) {
    return;
  }

  switch (action) {
    case 'fetch':
      switch (status) {
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 400:
        case 500:
          feedback({
            message: 'search.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        case 200:
          return null;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'image':
      switch (status) {
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 404:
          feedback({
            message: 'image.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        case 400:
        case 500:
          feedback({
            message: 'image.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        case 200:
          return null;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'recovery':
      switch (status) {
        case 200:
          feedback({
            title: 'recovery.confirm',
            method: M_INFO,
            type: T_NOTIFICATION
          });
          break;
        case 404:
          feedback({
            title: 'recovery.error',
            method: M_ERROR,
            type: T_NOTIFICATION
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'recovery.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'update.password':
      switch (status) {
        case 200:
          return null;
        case 404:
          feedback({
            message: 'update.error.token',
            method: M_ERROR,
            type: T_NOTIFICATION
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'create.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'create':
      switch (status) {
        case 'loading':
          feedback({
            message: 'create.loading',
            method: M_LOADING,
            type: T_MESSAGE
          });
          break;
        case 200:
          feedback({
            message: 'create.success',
            method: M_SUCCESS,
            type: T_MESSAGE
          });
          break;
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'create.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'update':
      switch (status) {
        case 'loading':
          message.loading(<IntlMessage id="update.loading" />);
          feedback({
            message: 'update.loading',
            type: T_MESSAGE,
            method: M_LOADING
          });
          break;
        case 200:
          feedback({
            message: 'update.success',
            type: T_MESSAGE,
            method: M_SUCCESS
          });
          break;
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'create.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'delete':
      switch (status) {
        case 'loading':
          feedback({
            message: 'delete.loading',
            type: T_MESSAGE,
            method: M_LOADING
          });
          break;
        case 200:
          feedback({
            message: 'delete.success',
            type: T_MESSAGE,
            method: M_SUCCESS
          });
          break;
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'delete.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    case 'login':
      switch (status) {
        case 200:
          return null;
        case 400:
          feedback({
            message: 'login.session.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        case 401:
        case 500:
          feedback({
            message: 'login.session.error',
            type: T_NOTIFICATION,
            method: M_ERROR
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
    default:
      switch (status) {
        case 'loading':
          feedback({
            message: 'generic.loading',
            method: M_LOADING,
            type: T_MESSAGE
          });
          break;
        case 200:
          feedback({
            message: 'generic.success',
            method: M_SUCCESS,
            type: T_MESSAGE
          });
          break;
        case 401:
          feedback({
            message: 'server.session.expired',
            type: T_MODAL,
            method: M_ERROR
          });
          break;
        case 400:
        case 500:
          feedback({
            message: msg || 'generic.error',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
        default:
          feedback({
            message: 'server.notfound',
            type: T_NOTIFICATION,
            method: M_ERROR,
            title: 'error.title'
          });
          break;
      }
      break;
  }
};
