export const TITLE_MAX_LENGTH = 22;
export const SUBTITLE_MAX_LENGTH = 24;
export const DESCRIPTION_MAX_LENGTH = 94;
export const PRE_TITLE_MAX_LENGTH = 25;
export const PARAGRAPH_MAX_LENGTH = 80;
export const LEGAL_TEXT_MAX_LENGTH = 100;
export const CTA_MAX_LENGTH = 20;
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 60;

export const TITLE = 'title';
export const SUBTITLE = 'subtitle';
export const DESCRIPTION = 'description';
export const MEDIA_FILE = 'mediaFile';
export const MEDIA_URL = 'mediaUrl';
export const PRODUCT_DESCRIPTION = 'productDescription';
export const ORDER = 'order';
export const PRODUCT_LIST = 'productList';
