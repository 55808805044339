import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ChallengeTemplateDetailDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'challengeTemplateDetailchallengeTemplateDetailSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeTemplate.idChallengeTemplate',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({
            id: 'challengeTemplateDetail.challengeTemplate',
          }),
          key: 'idChallengeTemplate.idChallengeTemplate',
          type: 'text',
          comboId: 'challenge_template',
          operand: 'equal',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'challengeTemplateDetailchallengeTemplateDetailList',
        rowKey: 'idTemplateChRe',
        panelHeader: 'Challenge Template Details List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
      },
      fields: [
        {
          title: 'ID',
          key: 'idTemplateChRe',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'challengeTemplateDetail.challengeTemplate',
          }),
          key: 'idChallengeTemplate.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.resourceType' }),
          key: 'idResourceType.name',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.quantity' }),
          key: 'quantity',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'number',
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.order' }),
          key: 'order',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'number',
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'challengeTemplateDetailchallengeTemplateDetailEdit',
        panelHeader: 'Challenge Template Detail Edit',
        primaryKey: 'idTemplateChRe',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeTemplate.idChallengeTemplate',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idResourceType.idResourceType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'quantity',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'order',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'textMachine',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({
            id: 'challengeTemplateDetail.challengeTemplate',
          }),
          key: 'idChallengeTemplate.idChallengeTemplate',
          type: 'text',
          comboId: 'challenge_template',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.resourceType' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          comboId: 'resource_type',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.quantity' }),
          key: 'quantity',
          type: 'number',
          min: 0,
          initialValue: '1',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'challengeTemplateDetail.order' }),
          key: 'order',
          type: 'number',
          min: 0,
          initialValue: '1',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          disabled: true,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'ChallengeTemplateDetailDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ChallengeTemplateDetailDashboard;
