import { Button, Modal, Result, Row, Spin } from 'antd';
import React, { Fragment, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { isConExperience } from '../../challenge/ChallengeUtils';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isConPlatform } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

export default function ReplicateChallengeButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [content, setContent] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState<
    'error' | 'success' | 'warning' | null
  >();
  const selectorRef = useRef<any>();

  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;
  if (!isConPlatform) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;

  const { handleReloadData, props } = tableProps;
  const { selectedRow, isLoading } = props;
  const loadingButton = loading || isLoading;
  const showButton = isConExperience(selectedRow?.idChallengeType?.idChallengeType);

  const downloadFile = (value: string) => {
    const element = document.createElement('a');
    const file = new Blob([value], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `experience_${selectedRow.idChallenge}_${new Date(
      Date.now(),
    ).toString()}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleValidateFile = async (content: string, warnings: boolean) => {
    await api.postDataCall({
      dataPath: `${apiPaths.CALL.REPLICATE_VALIDATE}?warnings=${warnings}`,
      data: { data: content },
      callConfig: {},
    });
  };

  const handleUploadContent = async (content: string) => {
    await api.postDataCall({
      dataPath: apiPaths.CALL.REPLICATE,
      data: { data: content },
      callConfig: {},
    });
  };

  const handleUploadFile = async (content: string, warnings = true) => {
    setLoading(true);
    setUploading(true);
    setUploadResult(null);
    try {
      await handleValidateFile(content, warnings);
      await handleUploadContent(content);
      setUploadResult('success');
      handleReloadData();
    } catch (error) {
      if (error?.response?.data?.code === '9998') {
        setUploadResult('warning');
      } else {
        setUploadResult('error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectFile = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      setContent(text);
      handleUploadFile(text);
    };
    reader.readAsText(e.target.files[0]);
    selectorRef.current.value = '';
  };

  const handleGetReplica = async () => {
    setLoading(true);
    try {
      const response = await api.getDataCallById({
        dataPath: apiPaths.CALL.REPLICATE,
        registerId: selectedRow.uuidChallenge,
        callConfig: {},
      });

      downloadFile(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSelector = (e: any) => {
    selectorRef?.current?.click();
  };

  const handleCloseModal = () => {
    setUploading(false);
    setContent(undefined);
    setUploadResult(null);
    setLoading(false);
  };

  const getUploadResult = () => {
    const getExtraButtons = () => {
      return [
        <Button
          loading={loadingButton}
          disabled={loadingButton || !Boolean(content)}
          onClick={() => {
            if (!content) return;
            handleUploadFile(content, false);
          }}>
          {formatMessage({
            id: 'custom-buttons.replicate-challenge.upload-force',
          })}
        </Button>,
      ];
    };

    switch (uploadResult) {
      case null:
        return (
          <>
            <Row style={{ marginBottom: '40px', fontSize: '1.6rem' }}>
              {formatMessage({
                id: 'custom-buttons.replicate-challenge.uploading',
              })}
            </Row>
            <Row
              style={{ height: '15vh' }}
              type="flex"
              justify="center"
              align="middle">
              <Spin size="large" />
            </Row>
          </>
        );
      case 'success':
        return (
          <Result
            status="success"
            title={formatMessage({
              id: 'custom-buttons.replicate-challenge.upload-success',
            })}
          />
        );
      case 'error':
        return (
          <Result
            status="error"
            title={formatMessage({
              id: 'custom-buttons.replicate-challenge.upload-error',
            })}
          />
        );
      case 'warning':
        return (
          <Result
            status="warning"
            title={formatMessage({
              id: 'custom-buttons.replicate-challenge.upload-warning',
            })}
            subTitle={formatMessage({
              id: 'custom-buttons.replicate-challenge.upload-warning-advice',
            })}
            extra={getExtraButtons()}
          />
        );
    }
  };

  return (
    <>
      {showButton && (
        <Button
          type="primary"
          disabled={!selectedRow?.idChallenge}
          loading={loadingButton}
          style={{ marginRight: '10px' }}
          onClick={handleGetReplica}>
          {formatMessage({ id: 'custom-buttons.replicate-challenge.download' })}
        </Button>
      )}

      <input
        accept=".txt"
        style={{ display: 'none' }}
        onChange={(e) => handleSelectFile(e)}
        type="file"
        ref={selectorRef}
      />
      <Button
        type="primary"
        loading={loadingButton}
        onClick={(e) => handleOpenSelector(e)}>
        {formatMessage({ id: 'custom-buttons.replicate-challenge.upload' })}
      </Button>
      <Modal
        style={{ minHeight: '40vh' }}
        visible={uploading}
        onCancel={handleCloseModal}
        closable={Boolean(uploadResult)}
        footer={null}>
        {getUploadResult()}
      </Modal>
    </>
  );
}
