import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatHtmlContentDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatHtmlContentSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'feature',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'language',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'content',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'title',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'htmlContentPage.feature' }),
          key: 'feature',
          type: 'selector',
          operand: 'equal',
          min: 0,
          comboId: 'htmlContentFeature',
          selectorPath: '/html-content/features',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'htmlContentPage.language' }),
          key: 'language',
          type: 'comboCustom',
          comboId: 'webeatLanguage',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'htmlContentPage.content' }),
          key: 'content',
          type: 'text',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'htmlContentPage.title' }),
          key: 'title',
          type: 'text',
          operand: 'like',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatHtmlContentList',
        rowKey: 'id',
        panelHeader: 'Html content list',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'feature',
      },
      fields: [
        {
          title: formatMessage({ id: 'htmlContentPage.id' }),
          key: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'htmlContentPage.feature' }),
          key: 'feature',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'htmlContentPage.language' }),
          key: 'language',
          render: 'comboCustom',
          comboId: 'webeatLanguage',
          position: 2,
          mustRender: true,
          align: 'center',
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    // Dummy edit component to be replaced for custom component in DashboardComposer render flow
    {
      params: {
        type: 'edit',
        componentId: 'webeatHtmlContentEdit',
        panelHeader: 'Page Edit',
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'feature',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'language',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'content',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 16,
                },
                {
                  key: 'icon',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'title',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 10,
                },
                {
                  key: 'description',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 16,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'htmlContentPage.feature' }),
          key: 'feature',
          type: 'selector',
          mandatory: true,
          selectorPath: '/html-content/features',
          comboId: 'htmlContentFeature',
        },
        {
          title: formatMessage({ id: 'htmlContentPage.language' }),
          key: 'language',
          type: 'comboCustom',
          comboId: 'webeatLanguage',
          mandatory: true,
          initialDisabled: false,
          initialValue: 'es-ES',
        },
        {
          title: formatMessage({ id: 'userMenu.icon' }),
          key: 'icon',
          type: 'selector',
          comboId: 'menuIcons',
          selectorPath: '/design-resources/icon',
          mandatory: false,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'feature',
                operand: 'like',
                type: 'visible',
                value: 'CHECKOUT_GUEST_REGISTER',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'htmlContentPage.title' }),
          key: 'title',
          type: 'text',
          validationType: 'text',
          length: 60,
          mandatory: true,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialVisibility: false,
          initialValue: 'Crea tu cuenta, gana puntos y consigue recompensas',
          behaviours: [
            [
              {
                key: 'feature',
                operand: 'like',
                type: 'visible',
                value: 'CHECKOUT_GUEST_REGISTER',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'htmlContentPage.description' }),
          key: 'description',
          type: 'textarea',
          validationType: 'text',
          length: 240,
          mandatory: true,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialVisibility: false,
          initialValue: 'Únete a LOVE ISDIN y ahorra tiempo en próximas compras. Disfruta de muestras gratuitas y gana puntos al escanear el código QR de tus productos, que podrás canjear por recompensas.',
          behaviours: [
            [
              {
                key: 'feature',
                operand: 'like',
                type: 'visible',
                value: 'CHECKOUT_GUEST_REGISTER',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'htmlContentPage.content' }),
          key: 'content',
          type: 'wyswygLite',
          validationType: 'text',
          length: 2000,
          mandatory: true,
          disabled: false,
          initialDisabled: false,
          initialVisibility: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'feature',
                operand: 'like',
                type: 'visible',
                value: 'SHIPPING_CONDITION',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatHtmlContentDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatHtmlContentDashboard);
