import React, { FC } from 'react';
import { ScanLandingBlankComponentProps } from './interface';

const ScanLandingBlankComponent: FC<ScanLandingBlankComponentProps> = ({
  explanation,
}) => {
  return (
    <div>
      <p>{explanation}</p>
    </div>
  );
};

export default ScanLandingBlankComponent;
