import { notification } from 'antd';
import { useEffect, useState } from 'react';
import * as api from '../../../api';
import { IResource, IResourceDependency } from '../../ChallengeInterfaces';
import { ResourceTypeEnum } from '../../Enums';
import { IDependencyModal } from './DependencyModal.view';

const {
  ACTIVATION_BUTTON,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  CHECKOUT,
  WEBEAT_CHECKOUT,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  EVENT,
  EXPERIENCE_ACTIVATION,
  GENERIC_BUTTON,
  LINKED_CHALLENGES,
  PEARL,
  QUIZ_TYPEFORM,
  QUIZ,
  QUIZ_DERMO,
  REDEEM_PRODUCT,
  SELL_IN,
  SELL_OUT,
  SHOPPING_CART,
  STEPS,
  TEXT_LINK,
  SURVEY,
  ARN_SURVEY,
  VIDEO,
  BEAUTY_GUIDE,
  WEBEAT_LEARNING_QUIZ,
  WEBEAT_LEARNING_QUIZ_QUESTION,
} = ResourceTypeEnum;

const ALLOWED_RESOURCES = [
  ACTIVATION_BUTTON,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  CHECKOUT,
  WEBEAT_CHECKOUT,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  EVENT,
  EXPERIENCE_ACTIVATION,
  GENERIC_BUTTON,
  LINKED_CHALLENGES,
  PEARL,
  QUIZ_TYPEFORM,
  QUIZ,
  QUIZ_DERMO,
  REDEEM_PRODUCT,
  SELL_IN,
  SELL_OUT,
  SELL_OUT,
  SELL_OUT,
  SHOPPING_CART,
  STEPS,
  TEXT_LINK,
  SURVEY,
  ARN_SURVEY,
  VIDEO,
  BEAUTY_GUIDE,
  WEBEAT_LEARNING_QUIZ,
  WEBEAT_LEARNING_QUIZ_QUESTION,
];

const filterAvailableResources = (
  id: number,
  challengeResources: IResource[],
) => {
  let availableResources: IResource[] = [];

  challengeResources.forEach((resource) => {
    if (
      resource.indResource === null &&
      resource.idResource !== id &&
      resource.status &&
      ALLOWED_RESOURCES.includes(resource.idResourceType.idResourceType)
    ) {
      availableResources.push(resource);
    }
  });

  return availableResources;
};

const getComboType = async (option: ResourceTypeEnum) => {
  const response = await api.getWebeatResourceDependencyStatus(option);
  return response.data?.length ? response.data : [];
};

export default function WebeatDependencyModalController({
  idChallenge,
  resource,
  challengeResources,
  dependencyModalVisibility,
  refreshChallengeData,
  setDependencyModalVisibility,
}: IDependencyModal) {
  const { behavior, idResource, resourceDependencyList } = resource;

  const [availableResources, setAvailableResources] = useState<IResource[]>([]);
  const [selectedBehavior, setSelectedBehavior] = useState<string | null>(null);
  const [dependenciesList, setDependenciesList] = useState<
    IResourceDependency[]
  >([]);

  useEffect(() => {
    async function setModalInitialState() {
      const availableResources = filterAvailableResources(
        idResource,
        challengeResources,
      );

      setAvailableResources(availableResources);
      setSelectedBehavior(behavior?.toString() || null);

      const dependencyList =
        resourceDependencyList?.map((el) => ({ ...el, idResource })) || [];

      for (let i = 0; i < dependencyList.length; i++) {
        const selectedResource = availableResources.find(
          ({ idResource }) => idResource === dependencyList[i].idDependency,
        );

        if (!selectedResource) return;

        const newOptions = await getComboType(
          selectedResource.idResourceType.idResourceType,
        );

        dependencyList[i].options = newOptions;
      }

      setDependenciesList(dependencyList);
    }

    if (dependencyModalVisibility) {
      setModalInitialState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyModalVisibility, resource]);

  const handleSelectBehavior = (option: string) => {
    setSelectedBehavior(option);
  };

  const handleSelectMultiParent = async (value: any, index: number) => {
    const selectedResource = availableResources.find(
      ({ idResource }) => idResource === value,
    );

    if (!selectedResource) return;

    const newOptions = await getComboType(
      selectedResource.idResourceType.idResourceType,
    );

    setDependenciesList((current) => {
      const newList = [...current];
      for (let i = 0; i < newList.length; i++) {
        if (index !== i) continue;

        newList[i].idDependency = value;
        newList[i].options = newOptions;
      }

      return newList;
    });
  };

  const handleSelectMultiParentStatus = (value: any, index: number) => {
    setDependenciesList((current) => {
      const newList = [...current];
      newList[index].trigger = value;
      return newList;
    });
  };

  const handleRemoveDependency = (index: number) => {
    const newDependenciesList = [...dependenciesList];
    newDependenciesList?.splice(index, 1);
    setDependenciesList(newDependenciesList);
  };

  const handleClose = () => {
    setSelectedBehavior(null);
    setDependencyModalVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      let data: any = {
        idResource,
        behavior: selectedBehavior,
      };

      const isCorrectForm = dependenciesList?.every(
        ({ idResource, idDependency, trigger }) =>
          Boolean(idResource) && Boolean(idDependency) && Boolean(trigger),
      );

      if (!isCorrectForm || !selectedBehavior) {
        notification.error({
          message: 'Some configuration is missing.',
        });
        return;
      }

      data = {
        idChallenge,
        idResource,
        dependenciesList: dependenciesList.map((d) => ({
          id: d.idDependency,
          trigger: d.trigger,
        })),
        behavior: selectedBehavior,
      };

      const response = await api.webeatUpdateResourceDependencies(data);

      if (response?.status === 200) {
        refreshChallengeData();
      }

      handleClose();
    } catch (err) {
      console.group(err);
    }
  };

  return {
    availableResources,
    dependenciesList,
    handleClose,
    handleRemoveDependency,
    handleSelectBehavior,
    handleSelectMultiParent,
    handleSelectMultiParentStatus,
    handleSubmit,
    selectedBehavior,
    setDependenciesList,
  };
}
