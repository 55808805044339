import React, { FC, Fragment, useState } from 'react';
import { Button, Col, Icon, Modal, Row, Spin, Tooltip } from 'antd';
import apiPaths from '../../apiPaths';
import * as api from '../../api';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';
import './StatisticChallengeButton.css';
import { IResource } from '../../challenge/ChallengeInterfaces';
import { IRow } from '../../app/AppInterfaces';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
interface ResourceStatistics {
  idResource: number;
  resourceStatistics: Record<string | 'Total', any>;
}

type ResourceStatisticRow = IResource & {
  statistics: Record<string | 'Total', any>;
};

const ResourceRow: FC<{ resource: ResourceStatisticRow }> = (props) => {
  const { resource } = props;

  return (
    <Row className="statisticResource">
      <Col span={3}> {resource.idResource}</Col>
      <Col span={6}>{resource.idResourceType.name}</Col>
      <Col span={6}>{resource.name}</Col>
      <Col span={6}>
        {Object.keys(resource.statistics).map((key, index) => {
          return (
            <Row key={index}>
              {key +
                ': ' +
                (
                  resource.statistics as {
                    [key: string]: any;
                  }
                )[key]}
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

const ModalStatistics: FC<{
  handleSetModalVisible: Function;
  waiting: boolean;
  selectedRow: IRow;
  statisticsResources: ResourceStatistics[] | undefined;
}> = (props) => {
  const { handleSetModalVisible, waiting, selectedRow, statisticsResources } =
    props;
  const { formatMessage } = useIntl();

  const tooltipTitle = formatMessage({
    id: `users.statistics.message`,
  });

  const filteredResources: ResourceStatisticRow[] =
    selectedRow.resourceList.flatMap((resource: IResource) => {
      const statistics = statisticsResources?.find(
        (_resource) => resource.idResource === _resource.idResource,
      )?.resourceStatistics;

      return statistics ? [{ ...resource, statistics }] : [];
    });
  return (
    <Modal
      className="searchModal"
      visible
      onCancel={() => handleSetModalVisible(false)}
      style={{ paddingTop: '43px' }}
      closable
      destroyOnClose
      centered
      footer={null}
      width={'75%'}>
      <Row className="statisticResourceTitles">
        <Col span={3}>
          <FormattedMessage id="resource.id" />
        </Col>
        <Col span={6}>
          <FormattedMessage id="resource.idType" />
        </Col>
        <Col span={6}>
          <FormattedMessage id="resource.boName" />
        </Col>
        <Col span={6}>
          <FormattedMessage id="users.statistics" />
          <Tooltip className="fieldTooltip" title={tooltipTitle}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </Col>
      </Row>
      {waiting ? (
        <Row
          style={{ height: '15vh' }}
          type="flex"
          justify="center"
          align="middle">
          <Spin size="large" />
        </Row>
      ) : (
        selectedRow.resourceList &&
        filteredResources &&
        filteredResources.map((resourceRow) => {
          return (
            <ResourceRow resource={resourceRow} key={resourceRow.idResource} />
          );
        })
      )}
    </Modal>
  );
};

export default function StatisticChallengeButtonComponent<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [statisticsResources, setStatisticResources] =
    useState<ResourceStatistics[]>();
  const [loading, setLoading] = useState(false);
  if (!isTableProps(parentProps)) return <Fragment />;
  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow, params } = tableProps.props;
  const { rowKey } = params;

  const handleSetModalVisible = (visible: boolean) => setModalVisible(visible);
  const handleOnClickStatistics = async () => {
    setWaiting(true);
    handleSetModalVisible(true);
    await api
      .getDataCall({
        dataPath: `${apiPaths.CALL.RESOURCES_USERS_PROGRESS}/${selectedRow[rowKey]}`,
        callConfig: {},
      })
      .then((res) => {
        setStatisticResources(res.data);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setLoading(false);
      });
    setWaiting(false);
  };

  if (!selectedRow?.resourceList) return <></>;
  return (
    <>
      {modalVisible && !loading && (
        <ModalStatistics
          handleSetModalVisible={handleSetModalVisible}
          selectedRow={selectedRow}
          statisticsResources={statisticsResources}
          waiting={waiting}
        />
      )}

      <Button onClick={() => handleOnClickStatistics()} type="primary">
        Statistics
      </Button>
    </>
  );
}
