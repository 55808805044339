import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import { getFrontUrl } from '../../utils';
import { IRow } from '../../app/AppInterfaces';
import apiPaths from '../../apiPaths';
import * as api from '../../api';
import { FormattedMessage } from 'react-intl';

export default function PreviewStaticPageButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  return <ConnectedPreview selectedRow={selectedRow} />;
}

interface OwnButtonProps {
  selectedRow: IRow;
}

type ButtonProps = ReturnType<typeof mapStateToProps> & OwnButtonProps;

const ButtonPreviewComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [userToken, setUserToken] = useState('');
  const FRONT_URL = getFrontUrl(currentPlatform, platforms);
  const isPrivatePage = selectedRow.publicPage ? false : true;
  let selectedSlug;
  if (selectedRow.slug === '/') selectedSlug = '';
  else selectedSlug = selectedRow?.slug?.split('/')[1];

  useEffect(() => {
    async function fetchUserToken() {
      const userToken = await api.postDataCall({
        dataPath: apiPaths.CALL.PREVIEW,
        data: {},
        callConfig: {},
      });
      setUserToken(userToken.data.accessToken);
    }

    fetchUserToken();
  }, []);

  const privateModal = (
    <Modal
      visible={modalVisible}
      className="previewModalPage"
      onCancel={() => setModalVisible(false)}
      closable
      destroyOnClose
      centered
      footer={null}>
      <iframe
        title="iframe_frontoffice"
        height="100%"
        width="95%"
        src={`${FRONT_URL}impersonate?token=${userToken}&targetURL=/${selectedSlug}&redirect=1`}
        frameBorder="0"
      />
    </Modal>
  );

  const publicModal = (
    <Modal
      visible={modalVisible}
      className="previewModalPage"
      onCancel={() => setModalVisible(false)}
      closable
      destroyOnClose
      centered
      footer={null}>
      <iframe
        title="iframe_frontoffice"
        height="100%"
        width="95%"
        src={`${FRONT_URL}${selectedSlug}${
          selectedSlug?.length ? `&preview` : `/preview`
        }`}
        frameBorder="0"
      />
    </Modal>
  );

  return (
    <>
      {isPrivatePage ? privateModal : publicModal}
      <Button
        onClick={() => setModalVisible(true)}
        disabled={isEmpty(selectedRow)}
        type="primary">
        <FormattedMessage id="challenge.media.preview" />
      </Button>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};

const ConnectedPreview = connect(mapStateToProps, {})(ButtonPreviewComponent);
