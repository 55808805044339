import React, { Fragment } from 'react';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import RenderDetail from '../TreeResource/TreeDetails';

const ChallengeMaterials = ({
  editRecursiveResource,
  editResource,
  form,
  resource,
}: IResourceTree): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items

  // Add here custom validations

  return (
    <>
      {details.map(({ index, detail }) =>
        RenderDetail({
          detail,
          detailArrayPosition: index,
          editRecursiveResource,
          editResource,
          form,
          resource,
          mainIdResource: resource.idResource,
        }),
      )}
      {}
    </>
  );
};

export default ChallengeMaterials;
