/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';

interface Props {
  item: { svg: string; value: string };
  setSelectSVG: any;
  selectSVG: any;
  selectTempSvg: any;
}

const WebeatIllustrationSelectorItem: FC<Props> = ({
  item,
  setSelectSVG,
  selectSVG,
  selectTempSvg,
}) => {
  const selectedItem = selectTempSvg || selectSVG;

  const scrollToSelected = () => {
    const selected = document.getElementById(selectSVG);
    if (selected && !selectTempSvg) {
      selected.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    scrollToSelected();
  }, [scrollToSelected]);

  return (
    <div key={item.value}>
      <div
        id={item.value}
        className="webeat-svg-selector__item"
        style={
          selectedItem === item.value ? { border: ' 2px solid #df3f41' } : {}
        }
        onClick={() => {
          setSelectSVG(item.value);
        }}>
        <div
          className="webeat-svg-selector__item__svg"
          dangerouslySetInnerHTML={{ __html: item.svg }}
        />
      </div>
    </div>
  );
};

export default WebeatIllustrationSelectorItem;
