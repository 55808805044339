export interface ProductItem {
  id: string;
  order: number;
  name: string;
  active: boolean;
  file: any;
  description: string;
  url: string;
  checkUrlImage: boolean;
  checkFile: boolean;
  pageUrl: string;
}

// TODO - Update the items array with the correct properties

export const createMockItems = (count: number): ProductItem[] => {
  return Array.from({ length: count }, (_, index) => ({
    id: `${index + 1}`,
    order: index + 1,
    active: true,
    checkFile: true,
    file: null,
    url: '',
    checkUrlImage: false,
    name: '',
    description: '',
    pageUrl: '',
  }));
};

export const items = createMockItems(10);
