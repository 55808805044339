import React from 'react';
import { Button, Dropdown, Menu, Checkbox } from 'antd';
import { injectIntl } from 'react-intl';
import './SmartTable.css';

interface OwnProps {
  columnsConfig: any[];
  onClick: (e: { key: string }) => void;
  intl: any;
}

class SmartColumns extends React.PureComponent<OwnProps, { visible: boolean }> {
  state = {
    visible: false,
  };

  handleVisibleChange = (flag: boolean) => {
    this.setState({ visible: flag });
  };

  render() {
    const { columnsConfig, intl } = this.props;

    const menuOptions = columnsConfig.map((c) => {
      return (
        <Menu.Item key={c.key}>
          <Checkbox checked={c.visible} style={{ paddingRight: '10px' }} />
          <span>{c.title}</span>
        </Menu.Item>
      );
    });
    const menu = (
      <Menu
        onClick={(e: { key: string }) => {
          this.props.onClick(e);
        }}
      >
        {menuOptions}
      </Menu>
    );
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
      >
        <Button
          icon="bars"
          title={intl.formatMessage({ id: 'visibility' })}
          className="columns-settings"
        />
      </Dropdown>
    );
  }
}

export default injectIntl(SmartColumns);
