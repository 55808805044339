import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';
import _ from 'lodash';
import { getFrontPinUrl } from '../utils/getFrontPinUrl';

const MaintenanceModeDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, LARGE } = config.COMPONENT.COLUMN;


  const appUrl = getFrontPinUrl();

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'maintenanceModeSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'fromUrl',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'From url',
          key: 'fromUrl',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'disabled',
          operand: 'equal',
          min: 0,
          mandatory: false,
          disabled: false,
          initialValue: '',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'maintenanceModeTable',
        rowKey: 'idMaintenanceMode',
        panelHeader: 'Maintenance Mode List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'fromUrl',
        removeTitle: formatMessage({id: 'pop.title.disabled'}),
        removeButtonTitle: formatMessage({id: 'form.disable'}),
      },
      fields: [
        {
          title: 'Include child pages',
          key: 'includeChilds',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'optional',
        },
        {
          title: 'Status',
          key: 'status',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'disabled',
        },
        {
          title: 'From url',
          key: 'fromUrl',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
        },
        {
          title: 'To url',
          key: 'toUrl',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'maintenanceModeEdit',
        panelHeader: 'Maintenance Mode Edit',
        primaryKey: 'idMaterial',
        tabIndex: 0,
        removeTitle: formatMessage({id: 'pop.title.disabled'}),
        removeButtonTitle: formatMessage({id: 'form.disable'}),
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'fromUrl',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'includeChilds',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 3,
                },
                {
                  key: 'toUrl',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                }
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'disabled',
          operand: 'equal',
          min: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
        {
          title: 'From url',
          key: 'fromUrl',
          type: 'comboText',
          comboId: 'menuPagesUrl',
          alwaysDisabled: false,
          mandatory: true,
          placeholder: 'Select a page here or paste a link below',
          textPlaceholder: 'Paste a url or select a page from above',
          textAddonBefore: appUrl
        },
        {
          title: 'Include child pages',
          key: 'includeChilds',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
          min: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'false',
        },
        {
          title: 'To url',
          key: 'toUrl',
          type: 'comboText',
          comboId: 'menuPagesUrl',
          mandatory: true,
          placeholder: 'Select a page here or paste a link below',
          textPlaceholder: 'Paste a url or select a page from above',
          onSelectValue: (_u: any, {comboOptions, props}: any) => {
            return _.find(comboOptions, {value: props.values.toUrl?.replace(appUrl, '')})?.value
          },
          onSelectChange: (value: string) => {
            return appUrl + value;
          }
        },
      ],

      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
        hideDelete: true
      },
    },
  ];

  const componentId = 'MaintenanceModeDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};
export default connect(mapStateToProps, null)(MaintenanceModeDashboard);
