export enum UserResourceEnum {
  INACTIVE = 0,
  WAITING_LIST = 1,
  SIGNED = 2,
  IN_PROGRESS = 3,
  DONE = 4,
  REMAKE = 5,
  ATTENDED = 6,
  PROCESSED = 9,
}
