export enum contentPageTypes {
  PAGE_ADD_DETAIL = '[PAGE] add detail to component',
  PAGE_CREATE_COMPONENT = '[PAGE] create component',
  PAGE_DELETE_COMPONENT = '[PAGE] delete component',
  PAGE_DELETE_DETAIL = '[PAGE] delete component detail',
  PAGE_EDIT_DETAIL = '[PAGE] edit component detail',
  PAGE_EDITING_COMPONENT = '[PAGE] set editing component',
  PAGE_INITIAL_STATE = '[PAGE] set initial state',
  PAGE_RESET_COMPONENT = '[PAGE] reset component',
  PAGE_SAVE_SORTING = '[PAGE] save sorting components',
  PAGE_SET_COMPONENT_CHANGED = '[PAGE] set component has changed',
  PAGE_SET_LOADING = '[PAGE] set isLoading',
  PAGE_SET_SELECTED_COMPONENT = '[PAGE] set selected component',
  PAGE_SORTING_COMPONENTS = '[PAGE] set sorting components',
  PAGE_UPDATE_COMPONENT = '[PAGE] component updated',
}
