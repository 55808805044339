import { Button, Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteCall } from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function CleanAllCacheButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleSetModalVisible = (visible: boolean) => setModalVisible(visible);
  const { formatMessage } = useIntl();

  useState<boolean>(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const cleanCache = async () => {
    const resp = await deleteCall({
      dataPath: apiPaths.CALL.CACHE_MANAGER_ALL,
    });

    if (resp.data) parentProps.handleDeleteData();

    handleSetModalVisible(false);
  };

  return (
    <>
      <Button
        disabled={!(tableProps?.dataSource?.content?.length > 0)}
        onClick={() => handleSetModalVisible(true)}
        type="primary">
        {formatMessage({ id: 'cache-manager.clear-cache' })}
      </Button>
      <Modal
        visible={modalVisible}
        closable={false}
        centered
        onOk={cleanCache}
        onCancel={() => handleSetModalVisible(false)}>
        <FormattedMessage id="cache-manager.clean-message.are-you-sure-you-want-to-delete-all-caches" />
      </Modal>
    </>
  );
}
