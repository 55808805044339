import { Form, Input, InputNumber, Row, Button } from 'antd';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import {
  ITransactionDetail,
  ITransactionDetails,
} from './massPointsManagement.interface';
import { UploadEmailsButton } from './UploadEmailsButton';

export interface IEditStepProps {
  transactionDetails: ITransactionDetails;
  onTransactionDetailsChange: <Key extends keyof ITransactionDetails>(
    property: Key,
    value: ITransactionDetails[Key]['value'],
  ) => void;
}
export function EditStep({
  transactionDetails,
  onTransactionDetailsChange,
}: IEditStepProps) {
  const intl = useIntl();

  const handleEmailsChange = (newEmails: string[]) => {
    onTransactionDetailsChange('emails', newEmails);
  };

  const handleDescriptionChange = (newDescription: string | undefined) => {
    onTransactionDetailsChange('description', newDescription);
  };

  const handleAmountChange = (
    newAmount: number | string | null | undefined,
  ) => {
    if (newAmount === null || typeof newAmount === 'string') {
      newAmount = undefined;
    }
    onTransactionDetailsChange('amount', newAmount);
  };

  if (!transactionDetails) return null;

  return (
    <Form>
      <label>
        {intl.formatMessage({
          id: 'points.management.button.modal.input.description',
        })}
        <EditFormItem
          transactionDetail={transactionDetails.description}
          className="ant-form-item--margin-bottom-yes">
          <Input
            value={transactionDetails.description.value}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          />
        </EditFormItem>
      </label>
      <label>
        {intl.formatMessage({
          id: 'points.management.button.modal.input.points',
        })}
        <EditFormItem
          transactionDetail={transactionDetails.amount}
          className="ant-form-item--margin-bottom-yes">
          <InputNumber
            value={transactionDetails.amount.value}
            onChange={handleAmountChange}
          />
        </EditFormItem>
      </label>
      <label>
        <UploadEmailsButton
          emails={transactionDetails.emails}
          onChange={handleEmailsChange}
        />
      </label>
    </Form>
  );
}

function EditFormItem({
  children,
  transactionDetail,
  ...props
}: {
  children: ReactNode;
  transactionDetail: ITransactionDetail<any>;
  [key: string]: unknown;
}) {
  return (
    <Form.Item
      colon={false}
      validateStatus={transactionDetail.validationError ? 'error' : 'success'}
      help={transactionDetail.validationError}
      {...props}>
      {children}
    </Form.Item>
  );
}

export interface IEditStepFooterProps {
  onCloseModalClick: CallableFunction;
  onValidateClick: CallableFunction;
}
export function EditStepFooter({
  onCloseModalClick,
  onValidateClick,
}: IEditStepFooterProps) {
  const intl = useIntl();

  return (
    <Row>
      <Button onClick={() => onCloseModalClick()}>
        {intl.formatMessage({
          id: 'form.edit.close',
        })}
      </Button>
      <Button type="primary" onClick={() => onValidateClick()}>
        {intl.formatMessage({
          id: 'points.management.button.modal.form.validate',
        })}
      </Button>
    </Row>
  );
}
