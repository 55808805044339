import React from 'react';
import { connect } from 'react-redux';
import ComponentsInitializer from './ComponentsInitializer';
import { setInitialDashboardState } from './dashboardActions';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { ReducersState } from '../reducers';
import { IFormComponent } from '../forms/formInterfaces';
import { ITableComponent } from '../tables/tableInterfaces';

interface OwnProps {
  dashboardId: string;
  components: (ITableComponent | IFormComponent)[];
  history?: any;
  isM2M?: boolean;
  isModalDashboard?: boolean;
  modalDashboardConfig?: any; //TODO define modalDashboard config interface
  isAdvancedSearch?: boolean;
  advancedSearchConfig?: any; //TODO define advancedSearch config interface
  customDashboard?: string;
}
export type IDashboardInitializerPropsList = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps> &
  OwnProps;

class DashboardInitializer extends React.PureComponent<
  IDashboardInitializerPropsList,
  {}
> {
  constructor(props: IDashboardInitializerPropsList) {
    super(props);
    const { setInitialDashboardState, dashboardId, dashboards } = this.props;
    if (!dashboards[dashboardId]) {
      setInitialDashboardState({ dashboardId });
    }
  }

  render() {
    return <ComponentsInitializer {...this.props} />;
  }
}

const mapStateToProps = (state: ReducersState) => {
  return {
    dashboards: state.dashboards
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ setInitialDashboardState }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardInitializer);
