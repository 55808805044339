import React, { FC } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import Component from './Component';
import { IPageComponent } from '../shared';
import { IPageProps } from '../PageComponents';

interface Props extends FormComponentProps {
  component: IPageComponent;
}
export type ComponentProps = IPageProps & Props;

const ResourceForm: FC<ComponentProps> = (props) => {
  return (
    <Form className="ResourceForm">
      <Component {...props} />
    </Form>
  );
};

export const ComponentForm = Form.create<ComponentProps>({
  name: 'resource_form',
})(ResourceForm);
