import { TDashboardComposerListProps } from '../dashboards/DashboardComposer';

import BaseAction from './actions/BaseAction';

export interface IMethods {
  table?: (
    parentProps: TDashboardComposerListProps
  ) => TDashboardComposerListProps;
  edit?: (
    parentProps: TDashboardComposerListProps
  ) => TDashboardComposerListProps;
}

interface iCustomActions {
  [key: string]: IMethods;
}

const customActions: iCustomActions = {
  BaseAction
};

export default customActions;
