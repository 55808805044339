import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Col, Input, InputNumber, Row, Tooltip, Icon } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';

import { IRow } from '../app/AppInterfaces';
interface Props extends FormComponentProps {
  values: IRow;
  handleChangeField: ({
    id,
    value,
    type,
  }: {
    id: string;
    value: any;
    type: string;
  }) => void;
}

export const ChallengeReplayForm: FC<Props> = ({
  form,
  values,
  handleChangeField,
}) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  const getInputLabel = (titleId: string, tooltipId: string) => (
    <span>
      {formatMessage({ id: titleId })}
      <Tooltip
        className="fieldTooltip"
        title={formatMessage({
          id: tooltipId,
        })}>
        <Icon type="question-circle-o" />
      </Tooltip>
    </span>
  );

  const validateButtonTitles = (
    rule: any,
    inputValue: string,
    callback: any,
  ) => {
    if (inputValue && inputValue.split('##').length !== 3) {
      callback(
        'It is necessary to enter 3 titles separated by ##, as in the example of the tooltip.',
      );
    } else if (
      inputValue &&
      inputValue.split('##').some((title: string) => !title)
    ) {
      callback('Each of the 3 titles are required.');
    } else if (
      inputValue &&
      inputValue.split('##').some((title: string) => title.length > 23)
    ) {
      callback('Each title cannot exceed 23 characters.');
    } else {
      callback();
    }
  };

  return (
    <Row type="flex" gutter={6} className="ChallengeBuilder__content">
      <Col xs={24} md={4}>
        <Form.Item
          label={getInputLabel(
            'challenge.availableReplays',
            'challenge.availableReplays.tooltip',
          )}>
          {getFieldDecorator('availableReplays', {
            initialValue: values?.availableReplays ?? 0,
          })(
            <InputNumber
              size="large"
              placeholder={formatMessage({
                id: 'challenge.availableReplays',
              })}
              onChange={(value) =>
                handleChangeField({
                  id: 'availableReplays',
                  type: 'number',
                  value,
                })
              }
              min={0}
            />,
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={20}>
        <Form.Item
          label={getInputLabel(
            'challenge.replayButtonTitles',
            'challenge.replayButtonTitles.tooltip',
          )}>
          {getFieldDecorator('replayButtonTitles', {
            initialValue: values?.replayButtonTitles || '',
            rules: [
              {
                required: values.availableReplays,
                message: formatMessage({
                  id: 'challenge.replayButtonTitles.mandatory',
                }),
              },
              { validator: validateButtonTitles },
            ],
          })(
            <Input
              size="large"
              placeholder={formatMessage({
                id: 'challenge.replayButtonTitles',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeField({
                  id: 'replayButtonTitles',
                  type: 'text',
                  value: e.target.value,
                })
              }
            />,
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={getInputLabel(
            'challenge.replayButtonDescriptions',
            'challenge.replayButtonDescriptions.tooltip',
          )}>
          {getFieldDecorator('replayButtonDescriptions', {
            initialValue: values?.replayButtonDescriptions || '',
            rules: [
              {
                required: values.availableReplays,
                message: formatMessage({
                  id: 'challenge.replayButtonDescriptions.mandatory',
                }),
              },
            ],
          })(
            <Input
              size="large"
              placeholder={formatMessage({
                id: 'challenge.replayButtonDescriptions',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeField({
                  id: 'replayButtonDescriptions',
                  type: 'text',
                  value: e.target.value,
                })
              }
            />,
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={getInputLabel(
            'challenge.replayButtonUrl',
            'challenge.replayButtonUrl.tooltip',
          )}>
          {getFieldDecorator('replayButtonUrl', {
            initialValue: values?.replayButtonUrl || '',
            rules: [
              {
                required: values.availableReplays,
                message: formatMessage({
                  id: 'challenge.replayButtonUrl.mandatory',
                }),
              },
            ],
          })(
            <Input
              size="large"
              placeholder={formatMessage({
                id: 'challenge.replayButtonUrl',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeField({
                  id: 'replayButtonUrl',
                  type: 'text',
                  value: e.target.value,
                })
              }
            />,
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};
