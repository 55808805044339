/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { Button, Col, Modal, Row } from 'antd';
import UploadComponent from '../../challenge/Components/UploadComponent';
import apiPaths from '../../apiPaths';
import * as api from '../../api';
import { isTableProps } from '../../utils/propsUtils';

const VADEMECUM_ID = 4;

interface IParameter {
  idParameter: number;
  name: string;
  value: string;
}

export default function AddVademecumButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const [vademecum, setVademecum] = useState<IParameter>({} as IParameter);
    const [newVademecum, setNewVademecum] =
      useState<undefined | string | null>(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isLoading, token } = tableProps.props;

    useEffect(() => {
      async function getVademecum() {
        try {
          setLoading(true);
          const response = await api.getDataCallById({
            dataPath: apiPaths.CALL.PARAMETERS,
            registerId: VADEMECUM_ID,
            callConfig: {},
          });
          setLoading(false);

          if (response?.status === 200 && response?.data)
            setVademecum(response.data);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      }

      getVademecum();
    }, []);

    const handleClose = () => {
      setNewVademecum(undefined);
      setModalVisible(false);
    };

    const handleSubmit = () => {
      async function saveVademecum() {
        try {
          const data = { ...vademecum, value: newVademecum };

          setLoading(true);
          const response = await api.putDataCall({
            dataPath: apiPaths.CALL.PARAMETERS,
            data,
            callConfig: {},
          });
          setLoading(false);

          if (response?.status === 200 && response?.data) {
            setVademecum(response.data);
            setModalVisible(false);
            setNewVademecum(undefined);
          }
        } catch (err) {
          setLoading(false);

          console.error(err);
        }
      }

      if (newVademecum) saveVademecum();
      else setModalVisible(false);
    };

    const setValue = (value?: string | null) => {
      setNewVademecum(value);
    };

    const downloadCurrentVademecum = () => {
      const win = window.open(`${vademecum.value}`, '_blank');
      if (win) win.focus();
    };

    return (
      <>
        <Button
          style={{ marginRight: '10px' }}
          onClick={() => setModalVisible(true)}
          type="primary"
          loading={isLoading}>
          Upload Vademecum
        </Button>
        <Modal
          visible={modalVisible}
          onCancel={handleClose}
          onOk={handleSubmit}>
          <Row style={{ minHeight: '20vh' }}>
            <Row style={{ marginBottom: '60px', fontSize: '1.6rem' }}>
              Vademecum Management
            </Row>
            <Row type="flex" gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <Row type="flex" justify="center">
                  <Button
                    onClick={downloadCurrentVademecum}
                    disabled={!vademecum?.value}>
                    Download current Vademecum
                  </Button>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="center" type="flex">
                  <UploadComponent
                    {...{
                      disabled: loading,
                      loading,
                      format: 'pdf',
                      setValue,
                      token,
                      value: vademecum?.value ?? '',
                    }}>
                    <Button>Upload new Vademecum</Button>
                  </UploadComponent>
                </Row>
              </Col>
            </Row>
          </Row>
        </Modal>
      </>
    );
  }
  return <></>;
}
