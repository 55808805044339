import React from 'react';
import { connect } from 'react-redux';
import DashboardComposer from './DashboardComposer';
import { setTableInitialState } from '../tables/tableActions';
import { setEditInitialState } from '../forms/edit/editActions';
import { setSearchInitialState } from '../forms/search/searchActions';
import { ReducersState } from '../reducers';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { IDashboardInitializerPropsList } from './DashboardInitializer';

export type IComponentsInitializerListProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps> &
  IDashboardInitializerPropsList;

class ComponentsInitializer extends React.PureComponent<
  IComponentsInitializerListProps,
  {}
> {
  constructor(props: IComponentsInitializerListProps) {
    super(props);
    const {
      components,
      setTableInitialState,
      setEditInitialState,
      setSearchInitialState,
      tableComponents,
      searchComponents,
      editComponents
    } = props;

    components.forEach(component => {
      const { params } = component;
      const { componentId, type } = params;

      switch (type) {
        case 'search':
          if (!searchComponents[componentId]) {
            setSearchInitialState({ componentId });
          }

          break;
        case 'edit':
          if (!editComponents[componentId]) {
            setEditInitialState({ componentId });
          }
          break;
        case 'table':
          // !Should be called from FC ChallengeUsersButton
          if (componentId === 'challengeusersList') {
            setTableInitialState({ componentId });
          }
          if (!tableComponents[componentId]) {
            setTableInitialState({ componentId });
          }
          break;
        default:
          break;
      }
    });
  }

  render() {
    return <DashboardComposer {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { setSearchInitialState, setEditInitialState, setTableInitialState },
    dispatch
  );

const mapStateToProps = (state: ReducersState) => {
  return {
    searchComponents: state.searches,
    tableComponents: state.tables,
    editComponents: state.edits
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentsInitializer);
