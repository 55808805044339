import { Button, Modal, Result, Row, Spin } from 'antd';
import React, { Fragment, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { isConPlatform } from '../../utils';

export default function InvalidateQrCsvParserButton() {
  const [invalidateQr, setInvalidateQr] = useState<string[]>([]);
  const [notValidQrs, setNotValidQrs] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState<
    'error' | 'confirmation' | 'success' | 'invalidFormat' | null
  >();
  const selectorRef = useRef<any>();
  const { formatMessage } = useIntl();

  if (!isConPlatform) return <Fragment />;

  const loadingButton = loading;

  const handleUploadFile = async (content: string) => {
    setLoading(true);
    setUploading(true);
    setUploadResult(null);

    const qrPattern = /^\s*(\b[A-Za-z0-9]{24}\b)\s*$/;
    const arr = content.split(/;?\r\n|;?\n|;?\r/);

    let validatedQrs = [];
    let notValidatedQrs = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].match(qrPattern)) {
        validatedQrs.push(arr[i].trim());
      } else if (arr[i] === '') {
      } else {
        notValidatedQrs.push(arr[i]);
      }
    }

    if (validatedQrs.length === 0) {
      setUploadResult('invalidFormat');
      setInvalidateQr([]);
      setNotValidQrs([]);
    } else {
      setUploadResult('confirmation');
      setInvalidateQr(validatedQrs);
      setNotValidQrs(notValidatedQrs);
    }
  };

  const handleUploadContent = async (validatedQrs: string[]) => {
    await api.putDataCall({
      dataPath: apiPaths.CALL.DEACTIVATE_QRS,
      data: { qrCodes: validatedQrs },
      callConfig: {},
    });
  };

  const handleConfirmInvalidation = async () => {
    if (invalidateQr.length !== 0) {
      try {
        await handleUploadContent(invalidateQr);
        setUploadResult('success');
      } catch (error) {
        setUploadResult('error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectFile = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      await handleUploadFile(text);
    };
    reader.readAsText(e.target.files[0]);
    selectorRef.current.value = '';
  };

  const handleOpenSelector = (e: any) => {
    selectorRef?.current?.click();
  };

  const handleCloseModal = () => {
    setUploading(false);
    setUploadResult(null);
    setLoading(false);
    setInvalidateQr([]);
    setNotValidQrs([]);
  };

  const getNotValidQrsList = () => {
    let list = [];
    for (let i = 0; i < notValidQrs.length; i++) {
      list[i] = <li key={notValidQrs[i]}>{notValidQrs[i]}</li>;
    }

    return <ul>{list}</ul>;
  };

  const getUploadResult = () => {
    switch (uploadResult) {
      case null:
        return (
          <>
            <Row style={{ marginBottom: '40px', fontSize: '1.6rem' }}>
              {formatMessage({
                id: 'custom-buttons.activate-qr.uploading',
              })}
            </Row>
            <Row
              style={{ height: '15vh' }}
              type="flex"
              justify="center"
              align="middle">
              <Spin size="large" />
            </Row>
          </>
        );
      case 'confirmation':
        return (
          <>
            {notValidQrs.length !== 0 && (
              <>
                <Row
                  style={{
                    marginBottom: '20px',
                    fontSize: '1.1rem',
                    fontWeight: 400,
                  }}>
                  {formatMessage({
                    id: 'custom-buttons.activate-qr.not-uploading',
                  })}
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                  {getNotValidQrsList()}
                </Row>{' '}
              </>
            )}
            <Row
              justify="center"
              style={{ marginBottom: '20px', fontSize: '1.6rem' }}>
              {formatMessage({
                id: 'custom-buttons.confirm-qr.confirmation',
              })}
            </Row>
            <Row
              style={{ height: '15vh' }}
              type="flex"
              justify="space-around"
              align="middle">
              <Button
                type="primary"
                size="large"
                onClick={handleConfirmInvalidation}>
                {formatMessage({ id: 'checkbox.true' })}
              </Button>
              <Button type="primary" size="large" onClick={handleCloseModal}>
                {formatMessage({ id: 'checkbox.false' })}
              </Button>
            </Row>
          </>
        );
      case 'success':
        return (
          <Result
            status="success"
            title={formatMessage({
              id: 'custom-buttons.activate-qr.upload-success',
            })}
          />
        );
      case 'error':
        return (
          <Result
            status="error"
            title={formatMessage({
              id: 'custom-buttons.activate-qr.upload-error',
            })}
          />
        );
      case 'invalidFormat':
        return (
          <Result
            status="error"
            title={formatMessage({
              id: 'custom-buttons.activate-qr.invalid-format',
            })}
          />
        );
    }
  };

  return (
    <>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        onChange={(e) => handleSelectFile(e)}
        type="file"
        ref={selectorRef}
      />
      <Button
        type="primary"
        loading={loadingButton}
        onClick={(e) => handleOpenSelector(e)}>
        {formatMessage({ id: 'custom-buttons.activate-qr.upload' })}
      </Button>
      <Modal
        style={{ minHeight: '40vh' }}
        visible={uploading}
        onCancel={handleCloseModal}
        closable={Boolean(uploadResult)}
        footer={null}>
        {getUploadResult()}
      </Modal>
    </>
  );
}
