import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';
import { connect } from 'react-redux';

const CampaignDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL } = config.COMPONENT.COLUMN;
  const componentId = 'CampaignDashboard';

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'campaignSearch',
        disableFindPageByIdRequests: true,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'activation',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'type',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'campaign.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'campaign.activation' }),
          key: 'activation',
          type: 'comboCustom',
          comboId: 'optional',
          globalSearch: true,
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'campaign.type' }),
          key: 'type',
          type: 'comboCustom',
          comboId: 'campaignType',
          globalSearch: true,
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'campaignList',
        rowKey: 'id',
        panelHeader: formatMessage({ id: 'campaign.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: 0,
        prettierKey: 'name',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: formatMessage({ id: 'campaign.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'campaign.activation' }),
          key: 'activation',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'optional',
        },
        {
          title: formatMessage({ id: 'campaign.type' }),
          key: 'type',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'campaignType',
        },
        {
          title: formatMessage({ id: 'campaign.activation_date' }),
          key: 'activationDate',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'campaign.expiration_date' }),
          key: 'expirationDate',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'campaign.coupon_expiration' }),
          key: 'couponExpiration',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'campaignPageEdit',
        panelHeader: formatMessage({ id: 'campaign.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'activation',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'type',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'couponExpiration',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'campaignDuration',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'activationDate',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'expirationDate',
                  yPosition: 6,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'campaign.id' }),
          key: 'id',
          type: 'text',
          disabled: true,
        },
        {
          title: formatMessage({ id: 'campaign.name' }),
          key: 'name',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'campaign.activation' }),
          key: 'activation',
          type: 'comboCustom',
          comboId: 'optional',
          mandatory: true,
          disabled: false,
          initialValue: false,
        },
        {
          title: formatMessage({ id: 'campaign.type' }),
          key: 'type',
          type: 'comboCustom',
          comboId: 'campaignType',
          mandatory: true,
          disabled: false,
          initialValue: 0,
        },
        {
          title: formatMessage({ id: 'campaign.coupon_expiration' }),
          key: 'couponExpiration',
          type: 'number',
          mandatory: false,
          disabled: false,
          initialValue: 15,
          min: 1,
        },
        {
          title: formatMessage({ id: 'campaign.campaign_duration' }),
          key: 'campaignDuration',
          type: 'number',
          mandatory: false,
          disabled: false,
          min: 1,
          tooltip: formatMessage({
            id: 'campaign.campaign_duration.tooltip',
          }),
        },
        {
          title: formatMessage({ id: 'campaign.activation_date' }),
          key: 'activationDate',
          type: 'datetime',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'campaign.expiration_date' }),
          key: 'expirationDate',
          type: 'datetime',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'campaignCampaignConsumablesList',
        rowKey: 'id',
        panelHeader: 'Products',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'campaign-consumable-dashboard',
        navigationId: 'campaignId',
        prettierKey: 'campaignId',
        disableFindPageByIdRequests: true,
        tabIndex: 1,
        disableRowClick: true, // Implement for prevent bug with tab tables onClick row
      },
      fields: [
        {
          title: 'Campaign ID',
          key: 'campaignId',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Consumable ID',
          key: 'consumableId',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Order',
          key: 'order',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: false,
        multiple: false,
        canCreate: false,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(CampaignDashboard);
