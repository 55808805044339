import { useEffect, useState } from 'react';

interface Props {
  value: string;
  parentProps: any;
  imagesData: { svg: string; value: string }[];
}

const useWebeatSVGSelector = ({ value, parentProps, imagesData }: Props) => {
  const SVG_SIZE = '30';
  const { handleChangeField } = parentProps;

  // States
  const [open, setOpen] = useState(false);
  const [selectSVG, setSelectSVG] = useState<string | null>(null);
  const [tmpSelectSVG, setTmpSelectSvg] = useState<string | null>(null);

  useEffect(() => {
    setSelectSVG(value || null);
    setTmpSelectSvg(value || null);
  }, [value]);

  // Search SVG State
  const [SVGs, setSVGs] =
    useState<{ svg: string; value: string }[]>(imagesData);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const newSVGs = imagesData.filter((i) =>
        i.value.toLowerCase().includes(e.target.value.toLowerCase()),
      );

      setSVGs(newSVGs);
    } else {
      setSVGs(imagesData);
    }
  };

  // Covert string to SVG and set width
  const setWidthInSVG = (svgString: string, width: string): string => {
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(svgString, 'image/svg+xml');

    const svgElement = svgDocument.documentElement;
    svgElement.setAttribute('width', width);
    svgElement.removeAttribute('height');

    return new XMLSerializer().serializeToString(svgElement);
  };

  const modifiedSVGs = SVGs.map((i: { svg: string; value: string }) => ({
    ...i,
    svg: setWidthInSVG(i.svg, SVG_SIZE),
  }));

  const handleClear = (field: { key: string; type: string }) => {
    setSelectSVG(null);
    setTmpSelectSvg(null);
    handleChangeField({
      type: field.type,
      id: field.key,
      value: '',
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setTmpSelectSvg(null);
  };

  const handleOk = (field: { key: string; type: string }) => {
    handleChangeField({
      type: field.type,
      id: field.key,
      value: tmpSelectSVG,
    });

    setSelectSVG((prev) => tmpSelectSVG ?? prev);
    setOpen(false);
  };

  return {
    open,
    setOpen,
    selectSVG,
    setSelectSVG,
    modifiedSVGs,
    handleSearch,
    handleChangeField,
    tmpSelectSVG,
    setTmpSelectSvg,
    handleClear,
    handleCancel,
    handleOk,
  };
};

export default useWebeatSVGSelector;
