const {
  REACT_APP_MOCK_PLATFORMS,
  REACT_APP_MOCK_USER,
  REACT_APP_MOCK_MENU,
  REACT_APP_MOCK_PERMISSIONS,
  REACT_APP_MOCK_AUTH_PLATFORMS,
  REACT_APP_WEBEAT_PLATFORM,
} = process.env;

const configDev = {
  WHY_DID_YOU_UPDATE_EN: false,
  LOAD_MOCK_MENU: REACT_APP_MOCK_MENU === 'true' || false,
  LOAD_MOCK_PERMISSIONS: REACT_APP_MOCK_PERMISSIONS === 'true' || false,
  LOAD_MOCK_PARAMS: true,
  LOAD_MOCK_PLATFORMS: REACT_APP_MOCK_PLATFORMS === 'true' || false,
  LOAD_MOCK_AUTH_PLATFORMS: REACT_APP_MOCK_AUTH_PLATFORMS === 'true' || false,
  LOAD_MOCK_USER: REACT_APP_MOCK_USER === 'true' || false,
  AUTO_LOGIN: false,
  SAVE_CONFIG: false,
  WEBEAT_PLATFORM:
    typeof REACT_APP_WEBEAT_PLATFORM === 'string'
      ? REACT_APP_WEBEAT_PLATFORM
      : null,
};

export default configDev;
