export enum CheckoutStatusEnum {
  DELETED = 0,
  CREATED = 1,
  CSV_GENERATED = 2,
  ERROR_CSV = 3,
  SAP_PROCESSED = 4,
  SAP_ERROR = 5,
  CREATED_USER_SHIPPING = 6,
  PROCESSED_USER_SHIPPING = 7,
  ERROR_USER_SHIPPING = 8,
}

export enum CheckoutShippingTypeEnum {
  ALL = 0,
  PHARMACY = 1,
  USER_ADDRESS = 2,
}
