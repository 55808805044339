import React, { FC, useState } from 'react';

import { Collapse } from 'antd';

import { items, ProductItem } from './products-item';
import ActiveProduct from './ActiveProduct';
import ProductSelectorItem from './ProductSelectorItem';
import ProductImageUpload from './ProductImageUpload';
import ProductDescription from './ProductDescription';

export type ItemTypes = 'Variation_base_product' | 'Product_bundle';

interface ProductsDragableComponentProps {
  homeId: string | undefined;
  value: any;
  fieldConfig: any;
  handleChange: (key: string, value: any) => void;
  constentState?: any;
}

const ProductsDragableComponent: FC<ProductsDragableComponentProps> = ({
  homeId,
  fieldConfig,
  handleChange,
  constentState,
}) => {
  constentState[fieldConfig.key] = Array.isArray(constentState[fieldConfig.key])
    ? constentState[fieldConfig.key]
    : items;

  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const onDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (dropIndex: number) => {
    if (draggedItemIndex === null) return;

    const updatedItems = [...constentState[fieldConfig.key]];
    const [draggedItem] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);

    updatedItems.forEach((item, index) => {
      if (item) {
        item.order = index + 1;
      }
    });

    handleChange(fieldConfig.key, updatedItems);
    setDraggedItemIndex(null);
  };

  return (
    <div>
      {constentState[fieldConfig.key].map(
        (item: ProductItem, index: number) => (
          <div
            key={index}
            draggable
            onDragStart={() => onDragStart(index)}
            onDragOver={onDragOver}
            onDrop={() => onDrop(index)}>
            <Collapse accordion>
              <Collapse.Panel
                className={
                  item.active
                    ? 'webeat-home-sections__panel--active'
                    : 'webeat-home-sections__panel--inactive'
                }
                header={item.name || 'Configure New Product'}
                key={index}>
                <ActiveProduct
                  constentState={constentState}
                  fieldConfig={fieldConfig}
                  handleChange={handleChange}
                  index={index}
                />
                <br />

                <ProductSelectorItem
                  constentState={constentState}
                  fieldConfig={fieldConfig}
                  handleChange={handleChange}
                  index={index}
                />
                <br />
                <br />
                <ProductImageUpload
                  constentState={constentState}
                  fieldConfig={fieldConfig}
                  handleChange={handleChange}
                  index={index}
                  homeId={homeId}
                />
                <br />
                <br />
                <ProductDescription
                  constentState={constentState}
                  fieldConfig={fieldConfig}
                  handleChange={handleChange}
                  index={index}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        ),
      )}
    </div>
  );
};

export default ProductsDragableComponent;
