import React from 'react';
import { useIntl } from 'react-intl';
import { IFailedEmail } from './massPointsManagement.interface';
import { FailedEmailReasonEnum } from './massPointsManagement.enum';
import './FailedEmailsList.css';

const FAILED_EMAIL_REASON_I18N_KEYS = {
  [FailedEmailReasonEnum.UNKNOWN]:
    'points.management.bulk.failed-email-reason.unknown',
  [FailedEmailReasonEnum.NON_EXISTENT]:
    'points.management.bulk.failed-email-reason.non_existent',
  [FailedEmailReasonEnum.UNSET_UUID]:
    'points.management.bulk.failed-email-reason.unset_uuid',
  [FailedEmailReasonEnum.POINTS_API_ERROR]:
    'points.management.bulk.failed-email-reason.points_api_error',
};

export interface IFailedEmailsListProps {
  failedEmails: IFailedEmail[];
}
export function FailedEmailsList({ failedEmails }: IFailedEmailsListProps) {
  const intl = useIntl();
  return (
    <div className="failed-emails-container" role="list">
      {failedEmails.map((failedEmail) => {
        const genericReason = FAILED_EMAIL_REASON_I18N_KEYS[failedEmail.reason]
          ? intl.formatMessage({
              id: FAILED_EMAIL_REASON_I18N_KEYS[failedEmail.reason],
            })
          : failedEmail.reason;
        return (
          <div className="failed-email" role="listitem" key={failedEmail.email}>
            <span className="failed-email__email">{failedEmail.email}:</span>
            <span className="failed-email__reason">
              {genericReason}
              {failedEmail.extraInformation && (
                <>: {failedEmail.extraInformation}</>
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
}
