import React, { FC } from 'react';
import { Button, Input, Modal } from 'antd';
import WebeatSVGSelectorItem from './WebeatSVGSelectorItem';
import useWebeatSVGSelector from './hooks/useWebeatSVGSelector';
import { useIntl } from 'react-intl';
import './WebeatSVGSelectorStyles.css';

export const WebeatSVGSelector: FC<{
  imagesData: any;
  value: string;
  parentProps: any;
  field: { key: string; type: string };
}> = ({ imagesData, value, parentProps, field }) => {
  const {
    selectSVG,
    setOpen,
    handleSearch,
    open,
    modifiedSVGs,
    tmpSelectSVG,
    setTmpSelectSvg,
    handleClear,
    handleCancel,
    handleOk,
  } = useWebeatSVGSelector({
    value,
    parentProps,
    imagesData,
  });

  const { formatMessage } = useIntl();

  const emptyStateMessage =
    field.key === 'illustration'
      ? formatMessage({ id: 'userMenu.emptyIllustration' })
      : formatMessage({ id: 'userMenu.emptyIcon' });

  return (
    <>
      <Button
        type="primary"
        value={selectSVG ?? value}
        onClick={() => {
          setOpen(true);
          setTmpSelectSvg(null);
        }}
        className="webeat-svg-selector__button">
        {selectSVG ?? emptyStateMessage}
      </Button>
      <Modal
        title={
          <Input.Search
            placeholder={selectSVG ?? emptyStateMessage}
            name="search"
            onChange={handleSearch}
          />
        }
        className="webeat-svg-selector__modal"
        centered
        bodyStyle={{ overflowX: 'scroll' }}
        closable={false}
        visible={open}
        footer={[
          <div className="webeat-svg-selector-container__modal">
            <Button key="Clear" onClick={() => handleClear(field)}>
              {formatMessage({ id: 'userMenu.modal.button.clear' })}
            </Button>
            <div>
              <Button key="cancel" onClick={handleCancel}>
                {formatMessage({ id: 'userMenu.modal.button.cancel' })}
              </Button>

              <Button key="ok" type="primary" onClick={() => handleOk(field)}>
                {formatMessage({ id: 'userMenu.modal.button.ok' })}
              </Button>
            </div>
          </div>,
        ]}
        onCancel={() => {
          setOpen(false);
        }}>
        <div className="webeat-svg-selector__container">
          {modifiedSVGs.map((i: { svg: string; value: string }) => (
            <WebeatSVGSelectorItem
              item={i}
              setSelectSVG={setTmpSelectSvg}
              selectSVG={selectSVG}
              selectTempSvg={tmpSelectSVG}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};
