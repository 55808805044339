import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Switch,
  TimePicker,
} from 'antd';
import { SubscriptionModal } from '../interfaces/form-common.interfaces';
import FormItem from 'antd/lib/form/FormItem';
import { BlackListForm } from './blacklist-form';
import * as api from '../../../api';

import { feedback } from '../../../utils';

import {
  combineDateAndTime,
  formatDate,
  localeHour,
} from '../subscription-modal-utils/common-methods';
import {
  validateDateField,
  validateSubscriptionModalForm,
} from '../subscription-modal-utils/validate-fields';
import moment from 'moment';

export const SubscriptionModalForm = ({
  subscriptionModalData,
  setSubscriptionModalData,
}: {
  subscriptionModalData: SubscriptionModal;
  setSubscriptionModalData: any;
}) => {
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const [noStartDate, setNoStartDate] = useState(false);
  const [noFinishDate, setNoFinishDate] = useState(false);

  const [modalData, setModalData] = useState<SubscriptionModal>({
    ...subscriptionModalData,
    initialTime: localeHour(subscriptionModalData.initialDate) || '00:00',
    finalTime: localeHour(subscriptionModalData.finalDate) || '00:00',
    initialDate: formatDate(subscriptionModalData.initialDate),
    finalDate: formatDate(subscriptionModalData.finalDate),
  });

  const handleChangeInput = ({
    key,
    value,
  }: {
    key: string;
    value: string | number | boolean;
  }) => {
    if (key === 'enabled' && !value) {
      setNoStartDate(false);
      setNoFinishDate(false);
    }

    setModalData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return { [key]: value };
    });

    setErrors((prev: any) => ({ ...prev, [key]: '' }));
  };

  const handleSubscriptionModalSubmit = async (e: any) => {
    e.preventDefault();
    setLoad(true);
    setErrors({});

    let initialDateTime = combineDateAndTime(
      modalData.initialDate,
      modalData.initialTime,
    );
    let finalDateTime = combineDateAndTime(
      modalData.finalDate,
      modalData.finalTime,
    );

    if (noStartDate) {
      initialDateTime = new Date('1970-12-30').toISOString();
    }

    if (noFinishDate) {
      finalDateTime = new Date('2099-12-31').toISOString();
    }

    const fieldErrors = validateSubscriptionModalForm(modalData);

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      setLoad(false);
      return;
    }

    const transformInitialDate = new Date(initialDateTime).getTime();
    const transformFinalDate = new Date(finalDateTime).getTime();

    const validateDateFields = validateDateField({
      initialDate: transformInitialDate,
      finalDate: transformFinalDate,
    });

    if (Object.keys(validateDateFields).length > 0) {
      setErrors(validateDateFields);
      setLoad(false);
      return;
    }

    if (transformFinalDate < transformInitialDate) {
      feedback({
        type: 'notification',
        method: 'warning',
        message: 'The initial date must be before the final date',
        duration: 5,
      });
      return setLoad(false);
    }

    const res = await api.postDataCall({
      dataPath: `/subscription-modal`,
      data: JSON.stringify({
        ...modalData,
        initialDate: new Date(initialDateTime),
        finalDate: new Date(finalDateTime),
        delayTime: +modalData.delayTime,
      }),
    });

    if (res.status !== 200) {
      feedback({
        type: 'notification',
        method: 'warning',
        message: res.data.message,
        duration: 5,
      });
      return setLoad(false);
    }

    feedback({
      type: 'notification',
      method: 'success',
      message:
        'The subscription modal has been saved correctly. It will be available in a few seconds.',
      duration: 5,
    });

    setLoad(false);
  };

  const handleDateChange = (key: string, date: any) => {
    const dateString = date ? date.format('YYYY-MM-DD') : '';
    handleChangeInput({ key, value: dateString });
  };

  const handleTimeChange = (key: string, time: any) => {
    const timeString = time ? time.format('HH:mm:ss') : '';
    handleChangeInput({ key, value: timeString });
  };

  useEffect(() => {
    if (subscriptionModalData.initialDate === '1970-12-30T00:00:00.000Z') {
      setNoStartDate(true)
    }
    if (subscriptionModalData.finalDate === '2099-12-31T00:00:00.000Z') {
      setNoFinishDate(true)
    }
  }, [
    subscriptionModalData.initialDate,
    subscriptionModalData.finalDate
  ]);

  return (
    <Form onSubmit={handleSubscriptionModalSubmit}>
      <Row style={{ marginTop: 50 }}>
        <h2>{'*Time'}</h2>
        <p style={{ marginTop: -10 }}>
          Introduce the number of seconds after it will apear the modal.
          <br />
          It will count the seconds when the user accept de cookies pop-up.
        </p>
        <Col span={5}>
          <FormItem
            colon={false}
            validateStatus={errors.delayTime ? 'error' : ''}
            help={errors.delayTime}>
            <Input
              placeholder={
                subscriptionModalData.delayTime
                  ? subscriptionModalData.delayTime.toString()
                  : 'Time'
              }
              value={+modalData?.delayTime || 0}
              onChange={(e) =>
                handleChangeInput({
                  key: 'delayTime',
                  value: +e.target.value,
                })
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: 60 }}>
        <h2>{'*Blacklist'}</h2>
        <p style={{ marginTop: -10 }}>
          Introduce the url or UTM parameters you want to exclude the
          subscription modal. If it is empty the modal will appear in all the
          website
        </p>
        <Row style={{ marginTop: 20 }}>
          <BlackListForm
            subscriptionModalData={subscriptionModalData}
            setSubscriptionModalData={setSubscriptionModalData}
            type={'url'}
          />
        </Row>
        <br />
        <Row>
          <BlackListForm
            subscriptionModalData={subscriptionModalData}
            setSubscriptionModalData={setSubscriptionModalData}
            type={'utm'}
          />
        </Row>
      </Row>

      <Row style={{ marginTop: 60 }}>
        <h4>
          <b>Enable subscription modal for this country</b> (If it is enable,
          the modal will be active for this country)
        </h4>
        <FormItem colon={false}>
          <Col span={1}>
            <Switch
              key={'enabled'}
              checked={modalData.enabled}
              onChange={(e) =>
                handleChangeInput({
                  key: 'enabled',
                  value: e,
                })
              }
            />
          </Col>
          <Col span={3}>
            <p>Active PopUp</p>
          </Col>
        </FormItem>
      </Row>

      <Row style={{ marginTop: 40 }}>
        <h2>{'Dates'}</h2>
        <p style={{ marginTop: -10 }}>
          If it is enable, the modal will be active for this country.{' '}
        </p>
        <Row>
          <Col span={20} style={{ display: 'flex', gap: 10 }}>
            <Checkbox
              style={{ borderRadius: 20 }}
              onChange={() => setNoStartDate(!noStartDate)}
              checked={noStartDate}
              disabled={!modalData.enabled}
            />
            <p>
              <b>No Start Date</b>
            </p>
          </Col>
          <Col span={20} style={{ display: 'flex', gap: 10 }}>
            <Checkbox
              style={{ borderRadius: 20 }}
              onChange={() => setNoFinishDate(!noFinishDate)}
              checked={noFinishDate}
              disabled={!modalData.enabled}
            />
            <p>
              <b>No Finish Date</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <FormItem
              colon={false}
              help={errors.initialDate || errors.ErrorDate}
              validateStatus={
                errors.initialDate || errors.ErrorDate ? 'error' : ''
              }>
              <DatePicker
                value={moment(modalData.initialDate, 'YYYY-MM-DD')}
                key={'initialDate'}
                format="DD-MM-YYYY"
                onChange={(e) => handleDateChange('initialDate', e)}
                disabled={!modalData.enabled || noStartDate}
              />
            </FormItem>
          </Col>
          <Col span={5} style={{ marginLeft: 10 }}>
            <FormItem
              colon={false}
              help={errors.finalDate || errors.ErrorDate}
              validateStatus={
                (errors.finalDate && !noFinishDate) || errors.ErrorDate
                  ? 'error'
                  : ''
              }>
              <DatePicker
                value={moment(modalData.finalDate, 'YYYY-MM-DD')}
                key={'finalDate'}
                format="DD-MM-YYYY"
                onChange={(e) => handleDateChange('finalDate', e)}
                disabled={!modalData.enabled || noFinishDate}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <FormItem colon={false}>
              <TimePicker
                value={moment(modalData.initialTime, 'HH:mm:ss')}
                onChange={(e) => handleTimeChange('initialTime', e)}
                size="default"
                disabled={!modalData.enabled || noStartDate}
              />
            </FormItem>
          </Col>

          <Col span={5} style={{ marginLeft: 10 }}>
            <FormItem colon={false}>
              <TimePicker
                size="default"
                value={moment(modalData.finalTime, 'HH:mm:ss')}
                onChange={(e) => handleTimeChange('finalTime', e)}
                disabled={!modalData.enabled || noFinishDate}
              />
            </FormItem>
          </Col>
        </Row>
      </Row>
      <Button
        disabled={load}
        type="primary"
        htmlType="submit"
        className="login-form-button"
        style={{ marginTop: 50 }}>
        Save Page
      </Button>
    </Form>
  );
};
