import { FormComponentProps } from 'antd/lib/form';
import { IPageRenderListProps } from '../ContentPageRender';
import { ContentPageEnum } from '../shared';

// CONSTANTS
export const DUMMY_IMAGE_UUID = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384f';
export const { HOME, HOME_PUBLIC } = ContentPageEnum;

// INTERFACES & TYPES
export type FormProps = FormComponentProps & IPageRenderListProps;

export interface FormItemSize {
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
export interface StaticPageItemParams {
  image: string;
  name: string;
  category: string;
  order?: number;
  id?: string;
  brandDescription?: string;
  isFileImage?: boolean;
}
export interface StaticPageFooterSeo {
  subtitle?: string;
  description: string;
  position?: number;
  id?: string;
}

export interface StaticPageChildType {
  paramName: string;
  type: 'item' | 'seo';
}

export interface RenderFormItemProps {
  key: string;
  component: JSX.Element;
  boolean?: boolean; // If your field is a boolean selector
  size?: FormItemSize;
  notMandatory?: boolean;
  index?: number;
  arrayKey?: StaticPageChildType;
  isCompoundLabel?: boolean;
  maxLength?: number;
}

// METHODS

// Split Camel Case static page keys
export const splitCamelCase = (input: string): string => {
  // Use a regular expression to find camel case occurrences
  const camelCaseRegex = /([a-z])([A-Z])/g;
  // Replace camel case occurrences with a space
  const stringWithSpaces = input.replace(camelCaseRegex, '$1 $2');
  // Split the string into an array based on spaces
  const resultArray = stringWithSpaces.split(' ');
  // Join the array into a single string with spaces

  const res = resultArray.join(' ');

  // Order items page title and description error string message
  if (res.toLocaleLowerCase().includes('items')) {
    const replacedResult = res
      .toLocaleLowerCase()
      .replace('items', 'Ingredients');
    const resultantArr = replacedResult.split(' ');
    const result = `${resultantArr[1]} ${resultantArr[2].toLocaleLowerCase()} ${
      resultantArr[0]
    }`;
    return result;
  } else {
    return res;
  }
};

// Print Name or New Item on panel header
export const printHeaderName = (input: string): string => {
  if (input && input.length) {
    return input;
  } else {
    return 'New Item';
  }
};
