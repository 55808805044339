import { Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import { isWebeatPlatform } from '../../../utils';

const { STEPS_CARD_CONTENT } = ResourceTypeDetailEnum;
const WEBEAT_SKIP_STEP_DETAILS = [
  ResourceTypeDetailEnum.STEPS_CARD_NAME,
  ResourceTypeDetailEnum.STEPS_CARD_TEXT_LINK,
];

interface IStepsContent {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IStepsContent;

const StepsContent: FC<IGroupProps> = ({
  _resource,
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList } = _resource;

  const stepsContentDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) stepsContentDetails.push({ index, detail });
  });

  if (!stepsContentDetails) return <Fragment />;

  return (
    <Row gutter={[24, 0]}>
      {stepsContentDetails
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .filter((item) => {
          if (
            WEBEAT_SKIP_STEP_DETAILS.indexOf(
              item.detail.idResourceTypeD.idResourceTypeD,
            ) > -1 &&
            isWebeatPlatform()
          )
            return false;
          return true;
        })
        .map(({ detail, index }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let maxLength: number | undefined = undefined;

          if (idResourceTypeD === STEPS_CARD_CONTENT) {
            maxLength = 280;
          }

          return RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            maxLength,
            values,
          });
        })}
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepsContent);
