import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatSearchManagementDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  // const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatSearchManagementsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 4,
              fields: [
                {
                  key: 'platform',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Can only see the current platform',
          key: 'platform',
          type: 'text',
          operand: 'like',
          globalSearch: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatSearchManagementsList',
        rowKey: 'platform',
        panelHeader: 'Search Management Register',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'productNameAndSize',
      },
      fields: [
        {
          title: 'Plataform',
          key: 'platform',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Category',
          key: 'category',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Search Suggestion 1',
          key: 'suggestionOne',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Search Suggestion 2',
          key: 'suggestionTwo',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Search Suggestion 3',
          key: 'suggestionThree',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Search Suggestion 4',
          key: 'suggestionFour',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatSearchManagementsEdit',
        panelHeader: 'QR Product Edit',
        primaryKey: 'idSearchManagement',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'platform',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'category',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'suggestionOne',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'suggestionTwo',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'suggestionThree',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'suggestionFour',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Platform',
          key: 'platform',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          initialValue: props.currentPlatform,
          disabled: true,
          visible: false
        },
        {
          title: 'Category',
          key: 'category',
          type: 'selector',
          comboId: 'gridCategory',
          selectorPath: '/grid/categories',
          operand: 'equal',
          length: 50,
          mandatory: true,
          disabled: false,
          initialValue: '',
        },
        {
          title: 'Search Suggestion 1',
          key: 'suggestionOne',
          type: 'text',
          unique: true,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Search Suggestion 2',
          key: 'suggestionTwo',
          type: 'text',
          unique: true,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Search Suggestion 3',
          key: 'suggestionThree',
          type: 'text',
          unique: true,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Search Suggestion 4',
          key: 'suggestionFour',
          type: 'text',
          unique: true,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatSearchManagementDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatSearchManagementDashboard;
