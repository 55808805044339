import { Button, Col, Icon, Input, Popconfirm, Row } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { UPLOAD_ACTION_VIDEO, UPLOAD_ACTION_YOUTUBE_URL } from '../../shared';
import { ReducersState } from '../../reducers';
import {
  createResourceFromTemplate,
  editRecursiveResource,
  handleAddParentResource,
  handleRemoveParentResource,
} from '../challengeActions';
import { IResource } from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';
import './Resource.css';
import { blankImage, blankVideo } from './ResourceConfigs';
import UploadComponent from './UploadComponent';

const { GALLERY_IMAGE, GALLERY_VIDEO } = ResourceTypeEnum;

interface IGallery {
  handleDeleteImages: (image: string, idResourceD: number) => void;
  form: WrappedFormUtils<any>;
  editResource: boolean;
  resourceList: IResource[];
}

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IGallery;

function Gallery({
                   handleDeleteImages,
                   form,
                   editResource,
                   resourceList,
                   handleAddParentResource,
                   handleRemoveParentResource,
                   selectedResource,
                   editRecursiveResource,
                   accessToken,
                 }: Props) {
  const images: { resource: IResource; index: number }[] = [];
  const videos: { resource: IResource; index: number }[] = [];
  const intl = useIntl();

  const handleAddMedia = async (isVideo: boolean) => {
    const newResource = isVideo
      ? blankVideo(selectedResource)
      : blankImage(selectedResource);
    handleAddParentResource(newResource);
  };

  const renderVideoUpload = (setValue: () => void, value: string) => {
    return (
      <Form.Item>
        <UploadComponent
          {...{
            token: accessToken,
            disabled: !editResource,
            setValue,
            uploadActions: [UPLOAD_ACTION_VIDEO, UPLOAD_ACTION_YOUTUBE_URL],
            value,
            children: (
              <Button
                className="uploadButton uploadButton__margin-top"
                disabled={!editResource}>
                <Icon type="upload" />{' '}
                {intl.formatMessage({
                  id: 'upload.buttonTitle',
                })}
              </Button>
            ),
            primaryEntityId: selectedResource.idChallenge,
            module: 'challenge',
          }}
        />
      </Form.Item>
    );
  };

  const renderImageUpload = (
    _resource: { resource: IResource; index: number },
    value: string,
  ) => {
    return (
      <Row type="flex" gutter={[12, 0]}>
        <Col xs={24}>
          <Form.Item>
            <UploadComponent
              {...{
                token: accessToken,
                disabled: !editResource,
                setValue: function(value?: string | null) {
                  editRecursiveResource({
                    value,
                    parentArrayPosition: _resource.index,
                    detailArrayPosition: 0,
                  });
                },
                value,
                handleChangeField: function() {
                  if (_resource.resource.resourceDetailList?.length) {
                    handleDeleteImages(
                      _resource.resource.resourceDetailList[0].value,
                      _resource.resource.resourceDetailList[0].idResourceD,
                    );
                  }
                },
                primaryEntityId: selectedResource.idChallenge,
                module: 'challenge',
              }}>
              <Button
                className="uploadButton uploadButton__margin-top"
                disabled={!editResource}>
                <Icon type="upload" />{' '}
                {intl.formatMessage({
                  id: 'upload.buttonTitle',
                })}
              </Button>
            </UploadComponent>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderMediaType = (
    resources: { resource: IResource; index: number }[],
    isVideo?: boolean,
  ) => {
    const title = isVideo ? 'Video name' : 'Image name';
    const description = isVideo ? 'Video Type' : 'Upload image';

    return (
      <Row className="gallery__media">
        <Row className="gallery__media--title">
          <Col xs={24} md={6} xl={5}>
            {title}
          </Col>

          <Col xs={24} md={18} xl={19}>
            {description}
          </Col>
        </Row>
        {resources.map((_resource, i) => {
          let _value: string = '';

          if (
            _resource.resource.resourceDetailList &&
            _resource.resource.resourceDetailList.length
          ) {
            _value = _resource.resource.resourceDetailList[0].value;
          }

          const setValue = (value?: string) => {
            editRecursiveResource({
              value,
              parentArrayPosition: _resource.index,
              detailArrayPosition: 0,
            });
          };

          return (
            _resource.resource.status && (
              <Row
                key={i}
                type="flex"
                className="gallery__row"
                gutter={[12, 0]}>
                <Col xs={24} md={6} xl={5}>
                  <Form.Item>
                    {form.getFieldDecorator(
                      title.concat(_resource.index.toString()),
                      {
                        initialValue: _resource.resource.name
                          ? _resource.resource.name
                          : '',
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'update.required',
                            }),
                          },
                        ],
                      },
                    )(
                      <Input
                        disabled={!editResource}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          editRecursiveResource({
                            value: e.target.value,
                            parentArrayPosition: _resource.index,
                          })
                        }
                      />,
                    )}
                  </Form.Item>
                </Col>
                {_resource.resource.resourceDetailList?.length && (
                  <Col xs={22} md={16} xl={17}>
                    {isVideo
                      ? renderVideoUpload(setValue, _value)
                      : renderImageUpload(_resource, _value)}
                  </Col>
                )}
                {editResource && (
                  <Col xs={2}>
                    <Popconfirm
                      title={intl.formatMessage({ id: 'pop.title.delete' })}
                      icon={
                        <Icon
                          type="exclamation-circle"
                          style={{ color: 'red' }}
                        />
                      }
                      okText={intl.formatMessage({ id: 'pop.accept' })}
                      cancelText={intl.formatMessage({ id: 'pop.cancel' })}
                      onConfirm={() =>
                        handleRemoveParentResource(_resource.index)
                      }>
                      <Button
                        className="QuizQuestion__deleteButton"
                        icon="close"
                      />
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            )
          );
        })}
      </Row>
    );
  };

  if (resourceList && resourceList.length) {
    resourceList.forEach((_resource: IResource, index: number) => {
      if (_resource.idResourceType.idResourceType === GALLERY_IMAGE)
        images.push({ resource: _resource, index });
      else if (_resource.idResourceType.idResourceType === GALLERY_VIDEO)
        videos.push({ resource: _resource, index });
    });
  }

  return (
    <Row>
      <Row className="gallery__header" type="flex" justify="end">
        {editResource && (
          <>
            <Button
              type="primary"
              className="gallery__button"
              onClick={() => handleAddMedia(false)}>
              Add Image
            </Button>
            <Button
              type="primary"
              className="gallery__button"
              onClick={() => handleAddMedia(true)}>
              Add Video
            </Button>
          </>
        )}
      </Row>
      {!resourceList ||
        (isEmpty(resourceList.filter((_resource) => _resource.status)) &&
          'No media elements')}
      {!isEmpty(images.filter((_resource) => _resource.resource.status)) &&
        renderMediaType(images)}
      {!isEmpty(videos.filter((_resource) => _resource.resource.status)) &&
        renderMediaType(videos, true)}
      <Row className="gallery__content"></Row>
    </Row>
  );
}

const mapStateToProps = (state: ReducersState) => {
  return {
    selectedResource: state.challenge.selectedResource,
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createResourceFromTemplate,
      handleAddParentResource,
      handleRemoveParentResource,
      editRecursiveResource,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
