import { isProduction } from '../../utils';

export const booleanFields = [
  { key: 'active' },
  ...(!isProduction()
    ? [{ key: 'isTest', helper: 'challenge.tooltip.isTest' }]
    : []),
  { key: 'featured' },
  { key: 'showList' },
  { key: 'showPoints' },
  { key: 'showHeader' },
  { key: 'showImageHeader' },
];

export const challengeDates = [
  { key: 'startVisDate' },
  { key: 'startDate' },
  { key: 'endDate' },
  { key: 'endVisDate' },
];
