import React, { useState } from 'react';
import { Button, Modal, Row } from 'antd';
import { isEmpty } from 'lodash';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow, IRecord } from '../../app/AppInterfaces';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { useIntl } from 'react-intl';
import BookUsersForm from './BookUsersForm';
import { appComponents } from '../../components';
import { renderErrors } from '../../challenge/ChallengeUtils';
import moment from 'moment';
import { IResource } from '../../challenge/ChallengeInterfaces';
import { ResourceTypeEnum, UserResourceEnum } from '../../challenge/Enums';
import { isTableProps } from '../../utils/propsUtils';
import { loadTimezoneName } from '../../utils';

const getTalks = (challengeRow: IRow) => {
  const resourceList: IResource[] = challengeRow.resourceList;
  return resourceList.filter(
    (_resource: IResource) =>
      _resource.status &&
      _resource.idResourceType.idResourceType === ResourceTypeEnum.EVENT,
  );
};

export default function AssignUsersButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const idChallenge = parentProps.props.modalDashboardConfig.idChallenge;
  const challengeRow = parentProps.props.modalDashboardConfig.challengeRow;

  const [modalVisible, setModalVisible] = useState(false);
  const [newUsers, setNewUsers] = useState<string[]>([]);
  const [usersHaveChanged, setUsersHaveChanged] = useState(false);
  const [talks] = useState<IResource[]>(getTalks(challengeRow));
  const [selectedTalk, setSelectedTalk] = useState<null | string>(null);

  const intl = useIntl();

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, getTableData, setSelectedRow, params } =
      tableProps.props;
    const { componentId, rowKey } = params;

    const handleCloseModal = () => {
      if (usersHaveChanged)
        Modal.confirm({
          title: intl.formatMessage({
            id: 'pop.title.changes-will-not-be-saved-are-you-sure',
          }),
          okText: intl.formatMessage({ id: 'pop.accept' }),
          cancelText: intl.formatMessage({ id: 'pop.cancel' }),
          maskClosable: true,
          onOk() {
            setNewUsers([]);
            setModalVisible(false);
            setUsersHaveChanged(false);
            setSelectedTalk(null);
          },
          onCancel() {},
        });
      else setModalVisible(false);
    };

    const handleAddUsers = async () => {
      if (!isEmpty(newUsers) && idChallenge) {
        const response = await api.postDataCall({
          dataPath: apiPaths.CALL.BOOK_USERS,
          data: {
            idResource: talks.length > 1 ? selectedTalk : talks[0].idResource,
            users: newUsers,
          },
          callConfig: {},
        });

        if ((await response) && response.status === 200) {
          setNewUsers([]);
          if (response.data.errorsArray && response.data.errorsArray.length)
            renderErrors(response.data.errorsArray, intl);

          await getTableData({
            dataPath: appComponents[componentId].path,
            componentId: componentId,
          });

          setSelectedRow({
            componentId,
            selectedRow: {},
          });
          setSelectedTalk(null);
          setModalVisible(false);
        }
      }
    };

    const assignUsersButton = (
      <Modal
        visible={modalVisible}
        className="assignModal"
        onCancel={handleCloseModal}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'60%'}>
        <Row className="assignModal__title">Book new users</Row>
        <Row className="assignModal__select">
          <BookUsersForm
            {...{
              talks,
              newUsers,
              selectedTalk,
              setNewUsers,
              setUsersHaveChanged,
              setSelectedTalk,
            }}
          />
        </Row>
        <Row className="assignModal__footer" type="flex" justify="start">
          <Button
            onClick={handleAddUsers}
            disabled={isEmpty(newUsers)}
            type="primary">
            Save
          </Button>
        </Row>
      </Modal>
    );

    const changeUserResourceStatus = async (
      selectedRow: IRow,
      path: string,
    ) => {
      const { idUserResource } = selectedRow;

      const response = await api.putDataCall({
        dataPath: path,
        data: { idUserResource },
        callConfig: {},
      });

      if ((await response) && response.status === 200) {
        const newRecords: any = await getTableData({
          dataPath: appComponents[componentId].path,
          componentId: componentId,
        });
        let newSelectedRow: IRow = {};
        'data' in newRecords &&
          newRecords.data.content.forEach((record: IRecord) => {
            if (record[rowKey] === selectedRow[rowKey]) newSelectedRow = record;
          });

        setSelectedRow({
          componentId,
          selectedRow: newSelectedRow,
        });
      }
    };

    const handleDeleteUser = async (selectedRow: IRow) => {
      await changeUserResourceStatus(selectedRow, apiPaths.CALL.UNSUBSCRIBE);
    };

    const handleBookUsers = async (
      bookButtonMultiple: boolean,
      selectedRow: IRow,
    ) => {
      if (bookButtonMultiple) setModalVisible(true);
      else {
        await changeUserResourceStatus(selectedRow, apiPaths.CALL.SUBSCRIBE);
      }
    };

    const handleAttendUser = async (selectedRow: IRow) => {
      await changeUserResourceStatus(selectedRow, apiPaths.CALL.EVENT_ATTEND);
    };

    let bookButtonMultiple: boolean = !selectedRow || isEmpty(selectedRow);
    let editSingleUser: boolean = true;
    let deleteDisabled: boolean =
      isEmpty(selectedRow) ||
      (selectedRow.resourceM2MList &&
        !isEmpty(selectedRow.resourceM2MList) &&
        selectedRow.resourceM2MList[0].statusResource ===
          UserResourceEnum.INACTIVE)
        ? true
        : false; //2 === 'SIGNED STATUS'

    if (!(!selectedRow || isEmpty(selectedRow))) {
      if (
        selectedRow.resourceM2MList &&
        !isEmpty(selectedRow.resourceM2MList)
      ) {
        editSingleUser =
          selectedRow.resourceM2MList[0].statusResource !==
          (UserResourceEnum.SIGNED || UserResourceEnum.PROCESSED); //2 === 'SIGNED STATUS'
      }
    }

    const currentTimezone = loadTimezoneName();

    const challengeEndDate = moment(challengeRow.endDate)
      .tz(currentTimezone)
      .format();
    const nowDate = moment().tz(currentTimezone).format();

    const hideButtons =
      !challengeRow ||
      !challengeRow.endDate ||
      moment(nowDate).isAfter(challengeEndDate);

    const userStatus: UserResourceEnum = isEmpty(selectedRow?.resourceM2MList)
      ? null
      : selectedRow.resourceM2MList[0]?.statusResource;

    let canPerformAttendance =
      !isEmpty(selectedRow) &&
      userStatus === (UserResourceEnum.SIGNED || UserResourceEnum.PROCESSED);

    return !hideButtons ? (
      <>
        <Button
          style={{ marginRight: '17px' }}
          onClick={() => handleBookUsers(bookButtonMultiple, selectedRow)}
          disabled={!editSingleUser || canPerformAttendance}
          type="primary">
          {bookButtonMultiple ? 'Book Users' : 'Book User'}
        </Button>

        <Button
          style={{ marginRight: '17px' }}
          onClick={() => handleAttendUser(selectedRow)}
          disabled={!canPerformAttendance}
          type="primary">
          Attend User
        </Button>

        <Button
          onClick={() => handleDeleteUser(selectedRow)}
          disabled={deleteDisabled}
          type="primary">
          Delete User
        </Button>

        {assignUsersButton}
      </>
    ) : (
      <></>
    );
  }
  return <></>;
}
