import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';
import { ComboData } from '../../../combos/ComboInterfaces';
import { setLoading } from '../../../contentPage/redux/contentPageActions';
import { IQrProduct } from './QrProductInterfaces';
import { connect } from 'react-redux';
import { ReducersState } from '../../../reducers';

interface OwnProps {
  disabled: boolean;
  qrProduct: IQrProduct | undefined;
  loading: boolean;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
  setQrProduct: React.Dispatch<
    React.SetStateAction<IQrProduct | undefined>
  >;
  visible: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & FormComponentProps & OwnProps;

const QrBulkEditForm: FC<Props> = ({
  disabled,
  form,
  qrProduct,
  loading,
  setValidated,
  setQrProduct,
  visible,
  combos
}) => {
  const { formatMessage } = useIntl();
  const [buList, setBuList] = useState<[]>();

  const { getFieldDecorator, validateFields } = form;

  const productGroupCombo: ComboData[] = combos.qrProductEdit?.productGroup.productGroupCombo.data;

  useEffect(() => {
    if (buList) return;

    setLoading(true);
    getBuList()
      .then((res) => {
        if (!res?.data) {
          console.error('BU has no data!');
          return;
        }
        setBuList(res.data);
      })
      .catch((err) => {
        console.error('Error fetching BU list', err);
      })
      .finally(() => setLoading(false));
  }, [buList]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields(async (err: any) => {
      if (!err) {
        setValidated(true);
        setQrProduct({
          ...qrProduct,
          productGroup: form.getFieldValue('productGroup') || '',
          bu: form.getFieldValue('bu') || '',
          points: form.getFieldValue('points') || '',
        });
      } else setValidated(false);
    });
  };

  async function getBuList() {
    try {
      const BUlistResponse = await api.getCombo({
        id: apiPaths.COMBODATA.QR_PRODUCT_BU,
      });
      return BUlistResponse;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Form>
      <Row type="flex" align="top" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} md={8}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.product.group',
            })}>
            {getFieldDecorator('productGroup', {
              initialValue: null
            })(
              <Select
                showSearch
                style={{ width: '100%', height: '50px' }}
                disabled={disabled}
              >
                {productGroupCombo
                  ?.filter(
                    ({ description, value }: ComboData) => value && description,
                  )
                  .map((item: ComboData) => (
                    <Select.Option
                      key={item.value.toString()}
                      value={item.value}>
                      {item.description}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.bu',
            })}>
            {getFieldDecorator('bu', {
              initialValue: '',
            })(
              <Select
                showSearch
                style={{ width: '100%', height: '50px' }}
                disabled={disabled}
              >
                {buList
                  ?.filter(
                    ({ description, value }: ComboData) => value && description,
                  )
                  .map((item: ComboData) => (
                    <Select.Option
                      key={item.value.toString()}
                      value={item.description}>
                      {item.description}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.points',
            })}>
            {getFieldDecorator('points', {
              initialValue: null,
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
                {
                  type: 'number'
                }
              ],
            })(<InputNumber type="number" disabled={disabled} style={{width: '100%'}} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row
        type="flex"
        align="top"
        justify="space-between"
        style={{ marginTop: '10px' }}>
        <Col xs={24}>
          <Button
            disabled={disabled}
            htmlType="button"
            icon="check"
            loading={loading}
            onClick={handleSubmit}
            type="primary">
            {formatMessage({
              id: 'points.management.button.modal.form.validate',
            })}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const QrBulkEditFormWithProps = Form.create<Props>({
  onFieldsChange(props, fields) {
    props.setValidated(false);
  },
})(QrBulkEditForm);

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

export default connect(mapStateToProps)(QrBulkEditFormWithProps);

