import { dashboardTypes } from './dashboardTypes';
import { DashboardActionTypes } from './dashboardActions';

export interface DashboardState {
  layoutType: number | null;
  drawerVisible: boolean;
  selectedTab: string;
  selectedComponent: string;
}

export interface DashboardsState {
  [key: string]: DashboardState;
}

const initialState: DashboardsState = {};

export function dashboardReducer(
  state = initialState,
  action: DashboardActionTypes
): DashboardsState {
  switch (action.type) {
    case dashboardTypes.SET_DASHBOARD_INITIAL_STATE:
      return {
        ...state,
        [action.payload.dashboardId]: {
          layoutType: null,
          drawerVisible: false,
          selectedTab: '',
          selectedComponent: ''
        }
      };
    case dashboardTypes.DASHBOARD_INITIAL_STATE:
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...state[action.payload.dashboardId],
          layoutType: action.payload.layoutType,
          drawerVisible: action.payload.drawerVisible
        }
      };
    case dashboardTypes.DASHBOARD_SET_SELECTED_TAB:
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...state[action.payload.dashboardId],
          selectedTab: action.payload.tabIndex,
          selectedComponent: action.payload.componentId
        }
      };
    case dashboardTypes.DASHBOARD_SET_DRAWER:
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...state[action.payload.dashboardId],
          drawerVisible: action.payload.visible
        }
      };
    case dashboardTypes.DASHBOARD_RESET_SELECTED_TAB:
      const { selectedTab, selectedComponent, ...restProps } = state[
        action.payload.dashboardId
      ];
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...restProps,
          selectedTab: '0',
          selectedComponent: ''
        }
      };
    default:
      return state;
  }
}
