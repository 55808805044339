import React from 'react';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { history } from '../store';
import { ReducersState } from '../reducers';

import configDev from '../configDev';
import config from '../config';

import { access } from './appActions';
import { login, logout } from '../auth/authActions';
import { setInitialBreadcrumb } from './queryActions';

import PrivateRoute from '../auth/PrivateRoute';
import MainWrapper from './MainWrapper';
import Login from '../auth/Login';
import UpdatePassword from '../auth/UpdatePassword';

import { MenuItem } from './AppInterfaces';

type TRoutesListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Routes extends React.PureComponent<TRoutesListProps, {}> {
  constructor(props: TRoutesListProps) {
    super(props);
    this.authenticate();
  }

  async authenticate() {
    const { isAuthorized, userPermissions, menu, access, login, logout } =
      this.props;
    if (config.SECURITY === 0 && !isAuthorized) login();
    if (isAuthorized && (menu === null || userPermissions === null)) {
      try {
        // const response = await access({ user: 'user' });
        const response: any = await access();

        if ((await response.status) !== 200) logout();
      } catch (e) {
        console.error(e);
        logout();
      }
    }
  }

  componentDidUpdate() {
    this.authenticate();
  }

  renderMenuRoutes = (menu: MenuItem[]) => {
    return menu.map((item: MenuItem) => {
      return (
        <PrivateRoute
          key={item.id}
          path={item.route}
          render={() => <MainWrapper menu={menu} />}
        />
      );
    });
  };

  render() {
    const {
      isAuthorized,
      menu,
      setInitialBreadcrumb,
      userPermissions,
      appParams,
    } = this.props;
    if (!configDev.SAVE_CONFIG) {
      localStorage.removeItem(
        `${config.LOCAL_STORAGE.CONFIG}_${config.APP.NAME}`,
      );
    }
    let routes;

    switch (true) {
      case history.location.pathname === '/password/update':
        this.props.logout();
        routes = (
          <Switch>
            <Route replace path="/password/update" component={UpdatePassword} />
          </Switch>
        );
        break;
      case !isAuthorized:
        routes = (
          <Switch>
            <Route replace path="/login" component={Login} />
            <Route replace path="/password/update" component={UpdatePassword} />
            <Route path="*" render={() => <Redirect to="/login" />} />
          </Switch>
        );
        break;
      case isAuthorized && (menu === null || userPermissions === null):
        return <Spin className="notFound" size="large" />;
      case isAuthorized &&
        isEmpty(menu) &&
        userPermissions !== null &&
        appParams !== null:
        this.props.logout();
        break;
      default:
        if (!menu?.length) {
          this.props.logout();
          return;
        }

        const { route, nameOfMenu, icon } = menu[0];

        const initialRoute = config.WELCOME.SHOW
          ? '/Home'
          : isEmpty(menu[0].subMenu)
          ? menu[0].route
          : menu[0].subMenu[0].route;

        setInitialBreadcrumb({
          path: route,
          name: nameOfMenu,
          icon,
        });

        routes = (
          <Switch>
            <Route
              exact
              replace
              path="/"
              render={() => <Redirect to={initialRoute} />}
            />
            <Route
              replace
              path="/login"
              render={() => <Redirect to={initialRoute} />}
            />
            <PrivateRoute path="*" render={() => <MainWrapper menu={menu} />} />
            {this.renderMenuRoutes(menu)}
          </Switch>
        );
        break;
    }

    return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
  }
}

const mapStateToProps = (state: ReducersState) => ({
  isAuthorized: state.auth.isAuthorized,
  menu: state.app.menu,
  userPermissions: state.app.permissions,
  appParams: state.app.appParams,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ access, login, logout, setInitialBreadcrumb }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
