import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import DashboardInitializer from '../../dashboards/DashboardInitializer';
import {
  userSearch,
  userTable,
} from '../advancedSearch/ChallengeUsersDashboard';
import { ChallengeTypeEnum } from '../../challenge/Enums';
import { isTableProps } from '../../utils/propsUtils';
import apiPaths from '../../apiPaths';

export default function ChallengeUsersButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const handleSetModalVisible = (visible: boolean) => setModalVisible(visible);
  const idChallenge = parentProps.props?.selectedRow?.idChallenge;
  const { getComboData } = parentProps.props;

  useEffect(() => {
    const comboId = 'resource_type_byChallenge';
    let queryParams = {
      id: comboId,
      param: idChallenge,
    };
    setTimeout(() => {
      getComboData({
        dataPath: apiPaths.COMBO,
        comboId,
        fieldKey: 'idResource.idResourceType.idResourceType',
        componentId: 'challengeusersSearch',
        queryParams,
      });
    }, 10000);
  }, [idChallenge, getComboData]);
  
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, history } = tableProps.props;
    const dashboardId = 'ChallengeUsersDashboard';
    const isChallengeTalk =
      !isEmpty(selectedRow) &&
      selectedRow.idChallengeType.idChallengeType === ChallengeTypeEnum.TALK;

    const searchModal = (
      <Modal
        visible={modalVisible}
        className="searchModal"
        onCancel={() => handleSetModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'75%'}>
        <DashboardInitializer
          dashboardId={dashboardId}
          customDashboard={dashboardId}
          components={[
            userSearch(parentProps.props.intl),
            userTable({ intl: parentProps.props.intl, isChallengeTalk }),
          ]}
          history={history}
          isModalDashboard
          modalDashboardConfig={{
            initialParams: {
              key: 'idChallenge',
              value:
                parentProps.props.selectedRow[parentProps.props.params.rowKey],
            },
            idChallenge: parentProps.props.selectedRow.idChallenge,
            challengeRow: parentProps.props.selectedRow,
          }}
        />
      </Modal>
    );

    return (
      <>
        {searchModal}
        <Button
          onClick={() => handleSetModalVisible(true)}
          disabled={
            isEmpty(selectedRow) ||
            ![ChallengeTypeEnum.TALK, ChallengeTypeEnum.CAMPAIGN, ChallengeTypeEnum.CONGRESS].includes(
              selectedRow.idChallengeType.idChallengeType,
            )
          }
          type="primary">
          Challenge Users
        </Button>
      </>
    );
  }
  return <></>;
}
