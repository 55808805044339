import React, { useState } from 'react';
import { Resizable } from 'react-resizable';

type Axis = 'both' | 'x' | 'y' | 'none';

type ResizeCallbackData = {
  node: HTMLElement;
  size: { width: number; height: number };
};

interface IResizableProps {
  children?: React.ReactElement<any>;
  className?: string;
  width: number;
  height: number;
  handleSize?: [number, number];
  lockAspectRatio?: boolean;
  axis?: Axis;
  minConstraints?: [number, number];
  maxConstraints?: [number, number];
  onClick?: any;
  onResizeStop?: (
    e: React.SyntheticEvent<any>,
    data: ResizeCallbackData
  ) => any;
  onResizeStart?: (
    e: React.SyntheticEvent<any>,
    data: ResizeCallbackData
  ) => any;
  onResize?: (e: React.SyntheticEvent<any>, data: ResizeCallbackData) => any;
  draggableOpts?: any;
}

const ResizeableColumn = (props: IResizableProps) => {
  const { onResize, width, onClick, ...restProps } = props;

  const [allowClick, setAllowClick] = useState(true);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onMouseDown={(e: MouseEvent) => {
        setAllowClick(true);
      }}
      onResizeStart={(e: Event) => {
        setAllowClick(false);
      }}
      onClick={(e: MouseEvent) =>
        allowClick && typeof onClick === 'function' && onClick(e)
      }
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableColumn;
