import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { connect } from 'react-redux';
import { ReducersState } from '../reducers';

const CampaignConsumableDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'campaignConsumableSearch',
      },
      groups: [],
      fields: [
        {
          title: '',
          key: 'campaignId',
          value: 'campaignId',
          type: 'text',
          operand: 'equal',
          mandatory: false,
          disabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'campaignConsumableList',
        rowKey: 'id',
        panelHeader: '',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'order',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: 'Campaign ID',
          key: 'campaignId',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Consumable ID',
          key: 'consumableId',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Order',
          key: 'order',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          defaultSortOrder: 'ascend',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: false,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'campaignConsumableEdit',
        panelHeader: 'Edit Campaign Consumable',
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
                {
                  key: 'campaignId',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'consumableId',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'order',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'ID',
          key: 'id',
          type: 'number',
          mandatory: false,
          disabled: true,
        },
        {
          title: 'Campaign ID',
          key: 'campaignId',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Consumable ID',
          key: 'consumableId',
          type: 'selector',
          selectorPath: 'consumable/get-consumable-id-list?natures=8&natures=9',
          multiSelect: false,
          min: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Order',
          key: 'order',
          type: 'number',
          mandatory: true,
          initialDisabled: true,
          initialMandatory: false,
          behaviours: [
            [
              {
                key: 'id',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
            [
              {
                key: 'id',
                operand: 'notEmpty',
                type: 'mandatory',
              },
            ]
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'CampaignConsumableDashboard';

  return (
    <DashboardInitializer
      componentId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(CampaignConsumableDashboard);
