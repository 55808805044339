import { Button, Col, Modal, notification, Row } from 'antd';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import UploadComponent from '../../challenge/Components/UploadComponent';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import {
  typesOfSelectEnum,
  UPLOAD_ACTION_DOCUMENT,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_VIDEO_MAIN,
} from '../../shared';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isConPlatform } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

const mapStateToProps = (state: ReducersState) => {
  return {
    token: state.auth.accessToken,
  };
};

interface OwnProps {
  disabled: boolean;
  setValue: (value?: string | null, fileType?: typesOfSelectEnum) => void;
  token: string;
  value: any | any[];
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ConnectedUpload = connect(mapStateToProps)(
  ({ disabled, setValue, token, value }: Props) => {
    const { formatMessage } = useIntl();
    const myCustomAction = [
      { ...UPLOAD_ACTION_VIDEO_MAIN, label: 'video' },
      { ...UPLOAD_ACTION_DOCUMENT, label: 'document' },
      { ...UPLOAD_ACTION_IMAGE, label: 'image' },
    ];
    return (
      <UploadComponent
        {...{
          disabled,
          uploadActions: myCustomAction,
          setValue,
          token,
          value,
        }}>
        <Row className="isdin-custom-modal">
          <Button
            className="ant-btn ant-btn-primary"
            disabled={disabled}
            size="small"
            type="primary"
            icon="upload"
            style={{ width: 350, height: 30 }}>
            {formatMessage({ id: 'uploadFilesModal.uploadFile' })}
          </Button>
        </Row>
      </UploadComponent>
    );
  },
);

function UploadFilesButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [fileUrl, setFileUrl] = useState('');
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();
  const showUploader = fileUrl === '';

  if (!isTableProps(parentProps)) return <Fragment />;
  if (!isConPlatform) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { isLoading } = tableProps.props;

  function handleChangeImageUrl(value?: string | null) {
    setFileUrl(value ?? '');
  }

  const copyLink = () => {
    var copyText = document.getElementById('textLink')?.outerHTML;
    if (!copyText) return;
    const link1 = copyText.split('>')[1];
    const link = link1.split('<')[0];
    navigator.clipboard.writeText(link);
    notification.success({
      message: formatMessage({ id: 'uploadFileModal.linkCopied' }),
      duration: 3,
    });
    return link;
  };

  const showModal = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setFileUrl('');
  };

  return (
    <>
      <Button type="primary" icon="upload" onClick={showModal}>
        Upload Files
      </Button>
      <Modal
        className="isdin-custom-modal small"
        visible={visible}
        footer={null}
        onCancel={onClose}
        style={{ width: '200px' }}
        destroyOnClose>
        <Row className="isdin-custom-modal__content">
          <Row className="isdin-custom-modal__title">
            {formatMessage({ id: 'uploadFilesModal.title' })}
          </Row>
        </Row>

        {showUploader && (
          <>
            <Row className="isdin-custom-modal__subtitle">
              {formatMessage({ id: 'uploadFilesModal.selectFile' })}
            </Row>
            <ConnectedUpload
              disabled={isLoading}
              setValue={handleChangeImageUrl}
              value={fileUrl}
            />
          </>
        )}
        {!showUploader && (
          <>
            <Row className="isdin-custom-modal__subtitleUp">
              {formatMessage({ id: 'uploadFilesModal.uploadLink' })}
            </Row>

            <Row className="isdin-custom-modal__link">
              <Col md={20}>
                <em id="textLink">{fileUrl} </em>
              </Col>
              <Col md={4}>
                <Button type="primary" icon="copy" onClick={copyLink}></Button>
              </Col>
            </Row>
            {fileUrl && (
              <img className="isdin-custom-modal__image" src={fileUrl} alt="" />
            )}
          </>
        )}
      </Modal>
    </>
  );
}
export default UploadFilesButton;
