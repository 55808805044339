import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatResourceTypeDetailDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatResourceTypeDetailSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResourceType.idResourceType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'mandatory',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'resource_type',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.details.id' }),
          key: 'idResourceTypeD',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.details.type' }),
          key: 'type',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceType.details.value' }),
          key: 'value',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'resourceType.details.mandatory' }),
          key: 'mandatory',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'resourceType.details.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatResourceTypeDetailList',
        rowKey: 'idResourceTypeD',
        panelHeader: 'Resource Type Detail List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'value',
      },
      fields: [
        {
          title: formatMessage({ id: 'resourceType.details.id' }),
          key: 'idResourceTypeD',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.details.type' }),
          key: 'type',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.details.value' }),
          key: 'value',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.defaultValue' }),
          key: 'defaultValue',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.details.mandatory' }),
          key: 'mandatory',
          render: 'comboCustom',
          comboId: 'optional',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.details.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatResourceTypeDetailEdit',
        panelHeader: 'Resource Type Detail Edit',
        primaryKey: 'idResourceTypeD',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResourceType.idResourceType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'type',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'value',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'defaultValue',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'mandatory',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'textMachine',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'status',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'resourceTypeDetail.resourceType' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'resource_type',
          path: 'resource-type',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceTypeDetail.type' }),
          key: 'type',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceTypeDetail.value' }),
          key: 'value',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceTypeDetail.defaultValue' }),
          key: 'defaultValue',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'resourceTypeDetail.mandatory' }),
          key: 'mandatory',
          type: 'comboCustom',
          comboId: 'optional',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'resourceTypeDetail.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idResourceTypeD',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceTypeDetailresourceTypeDI18nList',
        rowKey: 'idReTDI18n',
        panelHeader: 'Resource Type DI18n List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'ResourceTypeDI18nview',
        navigationId: 'idParent.idResourceTypeD',
        tabIndex: 1,
        disabled: true,
      },
      fields: [
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id Parent',
          key: 'idParent.type',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id Re TDI18n',
          key: 'idReTDI18n',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Language',
          key: 'language',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Name',
          key: 'name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Status',
          key: 'status',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'WebeatResourceTypeDetailDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatResourceTypeDetailDashboard;
