import React, { FC } from 'react';
import { Button, Col, Form, Icon, Row, Select, Tooltip } from 'antd';
import { ScanLandingFormProps } from '../ScanLandingForm';
import { useIntl } from 'react-intl';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IRow } from '../../app/AppInterfaces';
import { FormItemProps } from 'antd/lib/form';

interface ScanLandingConsumablePlatformSelectorProps
  extends Pick<
    ScanLandingFormProps,
    'form' | 'disabled' | 'platformsCombo' | 'consumablesCombo'
  > {
  values: IRow[];
  handleAddRow: () => void;
  handleRemoveRow: (row: number) => void;
}

const isFieldRequired = ({
  form,
  index,
  values,
  fieldName,
  errorMessage,
}: {
  form: WrappedFormUtils<any>;
  values: IRow[];
  index: number;
  fieldName: 'platforms' | 'consumables';
  errorMessage: string;
}): Pick<FormItemProps, 'required' | 'validateStatus' | 'help'> => {
  const platformsSelected = Boolean(
    form.getFieldValue(`consumablesByPlatform[${index}].platforms`)?.length ??
      (values && values[index]['platforms']?.length),
  );
  const consumablesSelected = Boolean(
    form.getFieldValue(`consumablesByPlatform[${index}].consumables`)?.length ??
      (values && values[index]['consumables']?.length),
  );

  if (
    (fieldName === 'platforms' && platformsSelected) ||
    (fieldName === 'consumables' && consumablesSelected)
  ) {
    return { required: true };
  } else if (platformsSelected || consumablesSelected) {
    return {
      required: true,
      validateStatus: 'error',
      help: errorMessage,
    };
  } else {
    return { required: false };
  }
};

const ScanLandingConsumablePlatformSelector: FC<
  ScanLandingConsumablePlatformSelectorProps
> = ({
  form,
  values,
  disabled,
  platformsCombo,
  consumablesCombo,
  handleAddRow,
  handleRemoveRow,
}) => {
  // Constants
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <div style={{ margin: '32px 0' }}>
      {disabled ? null : (
        <Row
          gutter={16}
          type="flex"
          align="middle"
          justify="start"
          style={{ marginBottom: '8px' }}>
          <Col>
            <Button onClick={handleAddRow}>
              <Icon type="plus" />
            </Button>
          </Col>
          <Col style={{ display: 'flex' }}>
            <Tooltip
              title={formatMessage({
                id: 'scanLanding.consumablesByPlatform.add-button.tooltip',
              })}
              placement="right">
              <Icon type="question-circle" />
            </Tooltip>
          </Col>
        </Row>
      )}
      {values.map((_, index) => (
        <Row key={index} type="flex" justify="space-between">
          <Col sm={24} md={11}>
            <Form.Item
              label={formatMessage({ id: 'scanLanding.platforms' })}
              {...isFieldRequired({
                form,
                values,
                index,
                fieldName: 'platforms',
                errorMessage: formatMessage({
                  id: 'scanLanding.platforms.required-error',
                }),
              })}>
              {getFieldDecorator(`consumablesByPlatform[${index}].platforms`, {
                initialValue: values ? values[index]['platforms'] : [],
              })(
                <Select
                  key={`${index}-platforms-selector`}
                  mode="multiple"
                  style={{ width: '100%' }}
                  showArrow
                  filterOption
                  optionFilterProp="children"
                  maxTagCount={30}
                  disabled={disabled}
                  placeholder={formatMessage({
                    id: 'scanLanding.platforms.placeholder',
                  })}>
                  {platformsCombo?.map((platform) => (
                    <Select.Option key={platform.platform_code}>
                      {platform.description}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col sm={24} md={11}>
            <Form.Item
              label={formatMessage({ id: 'scanLanding.consumables' })}
              {...isFieldRequired({
                form,
                values,
                index,
                fieldName: 'consumables',
                errorMessage: formatMessage({
                  id: 'scanLanding.consumables.required-error',
                }),
              })}>
              {getFieldDecorator(
                `consumablesByPlatform[${index}].consumables`,
                {
                  initialValue: values ? values[index]['consumables'] : [],
                },
              )(
                <Select
                  key={`${index}-consumables-selector`}
                  mode="multiple"
                  style={{ width: '100%' }}
                  showArrow
                  filterOption
                  optionFilterProp="children"
                  maxTagCount={20}
                  disabled={disabled}
                  placeholder={formatMessage({
                    id: 'scanLanding.consumables.placeholder',
                  })}>
                  {consumablesCombo?.map((consumable) => (
                    <Select.Option
                      key={consumable.value}
                      value={consumable.value}>
                      {consumable.description}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          {disabled ? null : (
            <Button
              onClick={() => handleRemoveRow(index)}
              style={{ marginTop: '32px' }}>
              <Icon type="delete" />
            </Button>
          )}
        </Row>
      ))}
    </div>
  );
};

export default ScanLandingConsumablePlatformSelector;
