import { Col, Icon, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  IUploadMediaCommands,
  IUploadMediaCommandsHandler,
  IUploadMediaRenderProps,
} from './commands';

export class UploadMediaCommandsHandler implements IUploadMediaCommandsHandler {
  private commands: IUploadMediaCommands[] = [];
  private requestUrl: string = '';

  public addCommand(command: IUploadMediaCommands) {
    this.commands.push(command);
  }

  private getCommand(commandId: string): IUploadMediaCommands {
    return this.commands.find(({ id }) => id === commandId)!;
  }

  public renderCommandSelector(props: IUploadMediaRenderProps): JSX.Element {
    const { isDisabled, value, setValue } = props;
    const { formatMessage } = useIntl();
    const parsedValue = JSON.parse(value);
    const [selectedCommandID, setSelectedCommandID] = useState<string>(
      parsedValue?.type,
    );

    useEffect(() => {
      setSelectedCommandID(parsedValue?.type)
    },[parsedValue])

    return (
      <Row type="flex" gutter={[12, 0]}>
        <Col span={6}>
          <Select
            disabled={isDisabled}
            defaultValue={selectedCommandID}
            value={selectedCommandID}
            onChange={(_value: string) => {
              setValue(`{"url": "${parsedValue.url}", "type":"${_value}"}`);
              setSelectedCommandID(_value);
            }}
            className="fileTypeSelect"
            dropdownClassName="fileTypeSelect--options">
            {this.commands.map(({ id, icon, label }: IUploadMediaCommands) => {
              return (
                <Select.Option value={id} className="fileTypeSelect" key={id}>
                  <Icon type={icon} style={{ paddingRight: '1rem' }} />
                  {formatMessage({
                    id: label,
                  })}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={18}>
          {selectedCommandID &&
            this.getCommand(selectedCommandID).render({
              ...props,
              requestUrl: this.requestUrl,
              value,
            })}
        </Col>
      </Row>
    );
  }
}
