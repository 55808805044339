import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const BoUsersDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'bousersusersSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                // {
                //   key: 'email',
                //   yPosition: 0,
                //   xPosition: 0,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //   key: 'name',
                //   yPosition: 0,
                //   xPosition: 1,
                //   mustRender: true,
                //   width: 12
                // },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'bousers.email' }),
          key: 'email',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'bousers.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'bousers.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: 'true',
          operand: 'equal',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'bousersusersList',
        rowKey: 'idUser',
        panelHeader: formatMessage({ id: 'bousers.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'bousers.id' }),
          key: 'idUser',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: config.COMPONENT.COLUMN.SMALL,
        },
        {
          title: formatMessage({ id: 'bousers.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'bousers.email' }),
          key: 'email',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
        },

        {
          title: formatMessage({ id: 'bousers.status' }),
          key: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: config.COMPONENT.COLUMN.MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'bousersusersEdit',
        panelHeader: formatMessage({ id: 'bousers.edit' }),
        primaryKey: 'idUser',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idUser',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
                {
                  key: 'email',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },

                {
                  key: 'password',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },

                {
                  key: 'textMachine',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'bousers.id' }),
          key: 'idUser',
          type: 'number',
        },
        {
          title: formatMessage({ id: 'bousers.email' }),
          key: 'email',
          type: 'email',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: true,
          disabled: false,
          placeholder: formatMessage({ id: 'bousers.placeholder.email' }),
        },
        {
          title: formatMessage({ id: 'bousers.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: true,
          disabled: false,
          placeholder: formatMessage({ id: 'bousers.placeholder.name' }),
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'bousers.password' }),
          key: 'password',
          type: 'password',
          mandatory: true,
          initialDisabled: false,
          placeholder: formatMessage({ id: 'bousers.placeholder.password' }),
          behaviours: [
            [
              {
                key: 'idUser',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'bousers.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idUser',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'bousersrolesList',
        rowKey: 'idRole',
        panelHeader: formatMessage({ id: 'bousers.rolesList' }),
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        tabIndex: 1,
        navigationId: 'idUser.idUser',
      },
      fields: [
        {
          title: formatMessage({ id: 'bousers.description' }),
          key: 'description',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'bousers.idrole' }),
          key: 'idRole',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'bousers.name' }),
          key: 'name',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'bousers.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
      m2m: {
        dashboardId: 'M2MUserRolesDashboard',
        path: '/bousersroles',
        primaryKey: 'idUserRol',
        joinKey: 'idUser.idUser',
        joinForeignKey: 'idRole.idRole',
        components: [
          {
            params: {
              type: 'search',
              componentId: 'm2musersrolesSearch',
              panelHeader: 'Buscador de Roles',
              panelKey: 'm2musersrolesSearch',
              navigationParams: props.query.params.q || '',
              yPosition: 0,
              xPosition: 0,
              width: 24,
              collapsible: false,
              collapsed: false,
              isTabItem: false,
            },
            groups: [
              {
                index: 0,
                title: '',
                mustRender: true,
                subgroups: [
                  {
                    index: 0,
                    title: '',
                    mustRender: true,
                    yPosition: 0,
                    xPosition: 0,
                    width: 24,
                    fields: [
                      {
                        key: 'status',
                        mustRender: true,
                        yPosition: 0,
                        xPosition: 0,
                        width: 12,
                      },
                    ],
                  },
                ],
              },
            ],
            fields: [
              {
                title: formatMessage({ id: 'generic.status' }),
                key: 'status',
                type: 'comboCustom',
                comboId: 'status',
                operand: 'equal',
                min: 0,
                length: 0,
                mandatory: false,
                disabled: false,
                initialValue: 'true',
              },
              {
                title: formatMessage({ id: 'boroles.name' }),
                key: 'name',
                type: 'text',
                operand: 'like',
                mandatory: false,
                disabled: false,
                globalSearch: true,
              },
            ],
            settings: {
              fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
              fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
            },
          },
          {
            params: {
              type: 'table',
              componentId: 'm2musersrolesList',
              rowKey: 'idRole',
              panelHeader: 'Roles List',
              panelKey: 'm2musersrolesList',
              isMainTable: true,
              navigationParams: props.query.params.q || '',
            },
            fields: [
              {
                title: formatMessage({ id: 'boroles.id' }),
                key: 'idRole',
                position: 0,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: SMALL,
              },
              {
                title: formatMessage({ id: 'boroles.name' }),
                key: 'name',
                position: 1,
                mustRender: true,
                align: 'left',
                sorter: true,
                visible: true,
                defaultSortOrder: 'ascend',
              },
              {
                title: formatMessage({ id: 'generic.status' }),
                key: 'status',
                position: 4,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                render: 'comboCustom',
                comboId: 'status',
                width: MID,
              },
            ],
            settings: {
              size: config.COMPONENT.TABLE.SIZE,
              bordered: config.COMPONENT.TABLE.BORDERED,
              editable: false,
              multiple: true,
              canCreate: false,
              canDelete: false,
              scroll: { x: true },
            },
            pagination: {
              defaultPageSize: 50,
              pageSizeOptions: ['5', '10', '20', '50'],
              showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
              showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
              showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
              size: config.COMPONENT.TABLE.SIZE,
            },
          },
        ],
      },
    },
  ];

  const componentId = 'BoUsersDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default BoUsersDashboard;
