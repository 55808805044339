import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from './authActions';
import { Row, Col, Form, Icon, Input, Button, Progress } from 'antd';
import { feedbackController } from '../utils/feedback';
import config from '../config';
import configDev from '../configDev';
import mockData from '../mockData';
import ForgotPassword from './ForgotPassword';
import { ReducersState } from '../reducers';
import './Login.css';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form/Form';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { IRow } from '../app/AppInterfaces';

const FormItem = Form.Item;

interface OwnProps {
  intl: typeof InjectedIntlProps;
}

type TLoginListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class Login extends React.Component<
  TLoginListProps & FormComponentProps & OwnProps,
  {}
> {
  async componentDidMount() {
    if (configDev.AUTO_LOGIN) {
      const { login, feedback } = this.props;
      try {
        const statusSuccess: any = await login(mockData.LOGIN_VALUES);
        const action: string = statusSuccess.action;
        const status: number = statusSuccess.status;

        feedback({
          action,
          status,
        });
      } catch (err) {
        console.error('Login:handleSubmit -> ' + err);
      }
    }
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { form, login, feedback, visible } = this.props;
    if (visible) return;
    form.validateFields(async (err: any, values: IRow) => {
      if (err) return;

      try {
        const statusSuccess: any = await login(values);
        const action: string = statusSuccess.action;
        const status: number = statusSuccess.status;

        feedback({
          action,
          status,
        });
      } catch (err) {
        console.error('Login:handleSubmit -> ' + err);
      }
    });
  };

  render(): JSX.Element {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { isAuthorized, accessToken, isLoading, form, intl } = this.props;

    const { getFieldDecorator } = form;

    if ((isAuthorized || accessToken) && from.pathname !== '/') {
      return <Redirect to={from} />;
    }

    const loginLogo = config.LOGIN.LOGO_SHOW && (
      <div>
        <div className="logo" />
        <div className="enviroment text">{`${config.APP.ENVIRONMENT} v${config.APP.VERSION}`}</div>
      </div>
    );
    const loginLogoResponsive = config.LOGIN.LOGO_SHOW && (
      <div>
        <div className="logoMovile" />
        <div className="enviromentMovile">{`${config.APP.ENVIRONMENT} v${config.APP.VERSION}`}</div>
      </div>
    );

    return (
      <Row className="login">
        <Col span={14} className="login__leftContent">
          <div className="text">
            <span>{intl.formatMessage({ id: 'login.welcome' })}</span>
          </div>
          {loginLogo}
        </Col>

        <Row>
          {loginLogoResponsive}

          <Col span={10} className="login__rightContent">
            <Progress
              percent={100}
              status={isLoading ? 'active' : 'normal'}
              showInfo={false}
              strokeColor={{
                from: '#CB0827',
                to: '#CB0827',
              }}
              className="progressBar"
            />
            <Form onSubmit={this.handleSubmit} hideRequiredMark>
              <label>{intl.formatMessage({ id: 'login.msg' })}</label>
              <FormItem colon={false}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'login.msn.user' }),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="user" />}
                    placeholder={intl.formatMessage({ id: 'login.pc.user' })}
                  />,
                )}
              </FormItem>
              <FormItem colon={false}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'login.msn.pass' }),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder={intl.formatMessage({ id: 'login.pc.pass' })}
                  />,
                )}
              </FormItem>
              <ForgotPassword />
              <Button
                className="buttonLogin"
                type="primary"
                htmlType="submit"
                loading={isLoading}>
                {intl.formatMessage({ id: 'login.submit' })}
              </Button>
            </Form>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: ReducersState) => {
  return {
    isAuthorized: state.auth.isAuthorized,
    isLoading: state.auth.isLoading,
    accessToken: state.auth.accessToken,
    visible: state.auth.visible,
    location: state.router.location,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ login, feedback: feedbackController }, dispatch);

const ConnectedLogin = injectIntl(
  Form.create()(connect(mapStateToProps, mapDispatchToProps)(Login)),
);
export default ConnectedLogin;
