import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { CON_PlatformID_Enum } from '../shared';

const { CON_PERU, CON_COLOMBIA, CON_CHILE, CON_ARGENTINA, CON_BRAZIL } =
  CON_PlatformID_Enum;

const ConCheckout = (props: any) => {
  const { formatMessage } = props.intl;
  const { MID, SMALL } = config.COMPONENT.COLUMN;
  let currentPlatform =
    localStorage
      .getItem(
        config.LOCAL_STORAGE.PLATFORM_CODE +
          '_' +
          config.APP.NAME +
          '_' +
          config.APP.ENVIRONMENT,
      )
      ?.replace(/["']/g, '') || null;

  let cityLit = 'City';
  let regionLit = 'Region name';
  let zipCodeLit = 'Zip code';
  let districtLit = 'Distrito';
  let commentLit = 'Info adicional';

  switch (currentPlatform) {
    case CON_PERU: {
      cityLit = 'Departamento';
      regionLit = 'Provincia';
      zipCodeLit = 'ID';
      break;
    }
    case CON_COLOMBIA: {
      regionLit = 'Departamento';
      zipCodeLit = 'ID';
      commentLit = 'Apartamento';
      break;
    }
    case CON_CHILE: {
      zipCodeLit = 'RUT';
      districtLit = 'Comuna';
      break;
    }
    case CON_BRAZIL: {
      districtLit = 'Bairro';
      break;
    }
  }

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'concheckoutconcheckoutSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'BSTKD',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Checkout idOrder',
          key: 'BSTKD',
          type: 'number',
          operand: 'like',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Product EAN',
          key: 'EAN',
          type: 'text',
          operand: 'like',
          validationType: 'text',
        },
        {
          title: 'Email',
          key: 'email',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          globalSearch: true,
        },
        {
          title: 'Shipping type',
          key: 'type',
          type: 'combo',
          comboId: 'checkoutAddressType',
          operand: 'equal',
        },
        {
          title: 'Product type',
          key: 'product_type',
          type: 'combo',
          comboId: 'checkoutProductType',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'combo',
          comboId: 'checkoutStatus',
          operand: 'equal',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'concheckoutconcheckoutList',
        rowKey: 'BSTKD',
        panelHeader: 'Consumer-ES checkout List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'BSTKD',
      },
      fields: [
        {
          title: 'Checkout order id',
          key: 'BSTKD',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Shipping type',
          key: 'type',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          render: 'combo',
          comboId: 'checkoutAddressType',
          width: SMALL,
        },
        {
          title: 'Creation date',
          key: 'BSTDK',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          render: 'date',
          width: SMALL,
        },
        {
          title: 'Product EAN',
          key: 'EAN',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Product type',
          key: 'product_type',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          render: 'combo',
          comboId: 'checkoutProductType',
          width: SMALL,
        },
        {
          title: 'Product name',
          key: 'product_name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Email',
          key: 'email',
          position: 4,
          mustRender: true,
          // render: 'combo',
          // comboId: 'usersByUuid',
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Status',
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'checkoutStatus',
          width: SMALL,
        },
        {
          title: 'Pharmacy id sap',
          key: 'S_CODDROPPOINT',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Delivery nº',
          key: 'deliveryNumber',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Address',
          key: 'address',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        currentPlatform !== CON_PERU
          ? {
              title: cityLit,
              key: 'city',
              position: 6,
              mustRender: true,
              align: 'left',
              sorter: false,
              visible: false,
              width: SMALL,
            }
          : {
              title: cityLit,
              key: 'city',
              position: 6,
              mustRender: true,
              align: 'left',
              render: 'combo',
              comboId: 'departmentList',
              sorter: false,
              visible: false,
              width: SMALL,
            },
        currentPlatform !== CON_PERU
          ? {
              title: regionLit,
              key: 'regionName',
              position: 6,
              mustRender: true,
              render: 'combo',
              comboId: 'regionList',
              align: 'left',
              sorter: false,
              visible: false,
              width: MID,
            }
          : {
              title: regionLit,
              key: 'regionName',
              position: 6,
              mustRender: true,
              render: 'combo',
              comboId: 'provinceList',
              align: 'left',
              sorter: false,
              visible: false,
              width: MID,
            },
        [CON_PERU, CON_CHILE].includes(
          currentPlatform as CON_PlatformID_Enum,
        ) && {
          title: districtLit,
          key: 'district',
          position: 6,
          mustRender: true,
          render: 'combo',
          comboId: currentPlatform === CON_PERU ? 'districtList' : 'comunaList',
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        currentPlatform === CON_BRAZIL && {
          title: districtLit,
          key: 'district',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        currentPlatform === CON_ARGENTINA && {
          title: 'Número',
          key: 'streetNumber',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        currentPlatform === CON_COLOMBIA && {
          title: commentLit,
          key: 'comment',
          position: 6,
          mustRender: true,
          comboId: 'comunaList',
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: zipCodeLit,
          key: 'zipCode',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: 'Name',
          key: 'name',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Surname',
          key: 'surname',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Phone',
          key: 'phone',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Id Resource',
          key: 'idResource',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'ConCheckout';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ConCheckout;
