import { editTypes } from './editTypes';
import { EditActionTypes } from './editActions';
import { IGroupField } from '../formInterfaces';
import { IRow } from '../../app/AppInterfaces';

interface edit {
  targetId: string;
  fieldsConfig: IGroupField[];
  values: IRow;
  selectedTab: string;
  resetFormTrigger: boolean;
  parentData: IRow;
  isLoading: boolean;
}

export interface EditState {
  [key: string]: edit;
}

const initialState: EditState = {};

export function editReducer(
  state = initialState,
  action: EditActionTypes
): EditState {
  switch (action.type) {
    case editTypes.SET_INITIAL_EDIT_STATE:
      return {
        ...state,
        [action.payload.componentId]: {} as edit
      };
    case editTypes.EDIT_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {
          targetId: action.payload.targetId,
          fieldsConfig: action.payload.fieldsConfig,
          values: action.payload.values,
          selectedTab: action.payload.selectedTab,
          resetFormTrigger: action.payload.resetFormTrigger,
          parentData: {}
        } as edit
      };
    case editTypes.EDIT_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {} as edit
      };
    case editTypes.EDIT_SET_VALUES:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values
        } as edit
      };
    case editTypes.EDIT_CLEAN_RESET_FORM_TRIGGER:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case editTypes.EDIT_RESET_FORM:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values,
          fieldsConfig: action.payload.fieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case editTypes.EDIT_FETCHING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_FETCHED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_SENDING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_SENT_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_CREATING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_CREATED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case editTypes.EDIT_CHANGE_TAB:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedTab: action.payload.selectedTab
        }
      };
    case editTypes.EDIT_SENDING_DATA_ERROR:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: false
        }
      };

    case editTypes.EDIT_SET_FIELDSCONFIG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          fieldsConfig: action.payload.newFieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case editTypes.EDIT_FETCH_PARENT_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          parentData: action.payload.data
        }
      };

    default:
      return state;
  }
}
