import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
// import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../webeatChallengeActions';
import {
  IndexedDetail,
  IResource,
  IResourceDetail,
} from '../../ChallengeInterfaces';
// import { ResourceTypeDetailEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import WebeatLearningQuizAnswerResource from './LearningQuizAnswerResource';

// const {
//   LEARNING_QUIZ_QUESTION_TYPE,
// } = ResourceTypeDetailEnum;

interface LearningQuizQuestionResource {
  handleAddDetail?: (
    newDetail: IResourceDetail,
    parentResource?: IResource,
  ) => void;
  handleDeleteDetail?: (
    detailArrayPosition: number,
    parentResource?: IResource,
  ) => void;
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  questionIndex: number;
  values: IRow;
}

type QuestionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LearningQuizQuestionResource;

const LearningQuizQuestionResource = (props: QuestionProps) => {
  const {
    _resource,
    editRecursiveResource,
    editResource,
    form,
    questionIndex,
    accessToken,
    // combos,
    values,
  } = props;
  const questionResource = _resource;
  const details = _resource.resourceDetailList!;
  let detailsToRender: IndexedDetail[] = [];
  
  details.forEach((_detail: IResourceDetail, index: number) => {
    // const { idResourceTypeD } = _detail.idResourceTypeD;
    // if (idResourceTypeD === LEARNING_QUIZ_QUESTION_TYPE) 
    // _detail.order = nextOrderValue++;

      detailsToRender.push({ index, detail: _detail });

    // switch (idResourceTypeD) {
    //   case LEARNING_QUIZ_QUESTION_TYPE:
    //     break;
    //     default:
    //     detailsToRender.push({ index, detail: _detail });
    //     break;
    // }
  });
  return (
    <>
      {/* RENDER QUESTION DETAILS */}
      {detailsToRender
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          // const { idResourceTypeD } = detail.idResourceTypeD;
          // let combo: ComboData[] | undefined = undefined;
          // if (idResourceTypeD === ResourceTypeDetailEnum.LEARNING_QUIZ_QUESTION_TYPE) {
          //   combo =
          //     combos.challengechallengeEdit?.learningQuizQuestionType?.learningQuizQuestionType?.data ?? [];
          // }
          return RenderDetail({
            // combo,
            accessToken,
            detail,
            detailArrayPosition: index,
            parentArrayPosition: questionIndex,
            editRecursiveResource,
            editResource,
            form,
            resource: _resource,
            mainIdResource: _resource.idResource,
            values,
          });
        })}
      {/* RENDER ANSWERS */}
      {questionResource.resourceList?.length && (
        <>
          {questionResource.resourceList.sort((a, b) => a.order.valueOf() - b.order.valueOf())
          .map(
            (answer, answerIndex, questionAnswers) =>
              answer.status && (
                <WebeatLearningQuizAnswerResource
                  key={questionIndex.toString().concat(answerIndex.toString())}
                  {...{
                    form,
                    _resource: answer,
                    questionIndex,
                    answerIndex,
                    idQuestion: questionResource.idResource,
                    editResource,
                    answersNumber: questionResource.resourceList?.filter(
                      (_list) => _list.status,
                    ).length,
                    // questionType: detailsToRender?.find(({ detail }) => {
                    //   return (
                    //     detail.idResourceTypeD.idResourceTypeD ===
                    //     ResourceTypeDetailEnum.LEARNING_QUIZ_QUESTION_TYPE
                    //   );
                    // }),
                    questionAnswers: questionAnswers.filter((answer: any) => {
                      return answer.status;
                    }),
                  }}
                />
              ),
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    // combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearningQuizQuestionResource);
