import { challengeTypes } from './challengeTypes';
import { ChallengeActionTypes } from './challengeActions';
import {
  IChallengeType,
  IResourceType,
  IChallengeTemplate,
  IResource,
  IBrand,
} from './ChallengeInterfaces';

export interface ChallengeState {
  availableResources: IResourceType[];
  brands: IBrand[];
  challengeType: IChallengeType[];
  challengeTemplate: IChallengeTemplate[];
  selectedResource: IResource;
  resourceHasChanged: boolean;
  isLoading: boolean;
  editingChallenge: boolean;
  editingResource: boolean;
  sortingResources: boolean;
}

const initialState: ChallengeState = {
  availableResources: [] as IResourceType[],
  brands: [] as IBrand[],
  challengeType: [] as IChallengeType[],
  challengeTemplate: [] as IChallengeTemplate[],
  selectedResource: {} as IResource,
  resourceHasChanged: false,
  isLoading: false,
  editingChallenge: false,
  editingResource: false,
  sortingResources: false,
};

export function challengeReducer(
  state = initialState,
  action: ChallengeActionTypes,
): ChallengeState {
  switch (action.type) {
    case challengeTypes.CHALLENGE_SET_LOADING:
    case challengeTypes.CHALLENGE_INITIAL_STATE:
    case challengeTypes.CHALLENGE_CREATE:
    case challengeTypes.CHALLENGE_SET_EDITING_CHALLENGE:
    case challengeTypes.CHALLENGE_SET_EDITING_RESOURCE:
    case challengeTypes.CHALLENGE_SET_SELECTED_RESOURCE:
    case challengeTypes.CHALLENGE_UPDATE_DETAIL:
    case challengeTypes.CHALLENGE_SET_RESOURCE_CHANGED:
    case challengeTypes.CHALLENGE_RESOURCE_UPDATED:
    case challengeTypes.CHALLENGE_RESET_QUIZ:
    case challengeTypes.CHALLENGE_FETCH_TEMPLATES:
    case challengeTypes.CHALLENGE_SET_QUIZ:
    case challengeTypes.CHALLENGE_SORTING_RESOURCES:
    case challengeTypes.CHALLENGE_SAVE_SORTING:
    case challengeTypes.CHALLENGE_SET_BRANDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
