import React, { FC } from 'react';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import { useIntl } from 'react-intl';
import { Collapse, Form, Input } from 'antd';
import ScanLandingMarkdownInput from './ScanLandingMarkdown';
import UploadComponent from './UploadComponent';
import { INPUT_MAX_LENGTH } from '../constants';

interface ImageProps {
  url: string;
}
export interface ScanLandingImageComponentModel {
  title: string;
  description: string;
  desktop?: ImageProps;
  tablet?: ImageProps;
  mobile: ImageProps;
}

type DeviceOptions = Array<
  keyof Pick<ScanLandingImageComponentModel, 'mobile' | 'tablet' | 'desktop'>
>;
const DEVICE_OPTIONS: DeviceOptions = ['mobile', 'tablet', 'desktop'];
const REQUIRED_DEVICES: DeviceOptions = ['mobile'];

const ScanLandingImageComponent: FC<
  ScanLandingComponentProps<ScanLandingImageComponentModel> &
    ScanLandingComponentUploadProps
> = ({ index, form, values, token, disabled, handleOnChange }) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <div>
      <Form.Item
        label={formatMessage({ id: 'scanLanding.component.image.title' })}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
        }}>
        {getFieldDecorator(`components[${index}].model.title`, {
          initialValue: values.title ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.image.title.required-error',
              }),
            },
          ],
        })(
          <Input
            maxLength={INPUT_MAX_LENGTH}
            disabled={disabled}
            placeholder={formatMessage({
              id: 'scanLanding.component.image.title.placeholder',
            })}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'scanLanding.component.image.description',
        })}>
        {getFieldDecorator(`components[${index}].model.description`, {
          initialValue: values.description ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.image.description.required-error',
              }),
            },
          ],
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.description`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.description`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.description`, value);
            }}
          />,
        )}
      </Form.Item>
      <Collapse
        bordered={false}
        style={{
          fontSize: '16px',
          backgroundColor: '#f7f7f7',
        }}
        defaultActiveKey={REQUIRED_DEVICES.map((device) => `image.${device}`)}>
        {DEVICE_OPTIONS.map((device) => (
          <Collapse.Panel
            key={`image.${device}`}
            showArrow={false}
            header={formatMessage({
              id: `scanLanding.component.image.${device}`,
            })}>
            <Form.Item
              label={formatMessage({ id: `scanLanding.component.image.url` })}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
              }}>
              {getFieldDecorator(`components[${index}].model.${device}.url`, {
                initialValue: values[device]?.url ?? '',
                rules: [
                  {
                    required: REQUIRED_DEVICES.includes(device),
                    message: formatMessage({
                      id: `scanLanding.component.image.url.required-error`,
                    }),
                  },
                ],
              })(
                <UploadComponent
                  {...{
                    value: values[device]?.url ?? '',
                    setValue: (value) => {
                      handleOnChange(
                        `components[${index}].model.${device}.url`,
                        value,
                        true,
                      );
                    },
                    fieldName: `components[${index}].model.${device}.url`,
                    token,
                    disabled,
                    format: 'image',
                  }}
                />,
              )}
            </Form.Item>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ScanLandingImageComponent;
