import React, { FC, useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { isEmpty } from 'lodash';
import ScanLandingForm from './ScanLandingForm';
import { IEditParams } from '../forms/formInterfaces';
import { IEditField } from '../fields/FieldsInterfaces';
import { ReducersState } from '../reducers';
import { appComponents } from '../components';
import { injectIntl } from 'react-intl';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { setFormData, setInitialState } from '../forms/edit/editActions';
import {
  setSelectedRow,
  getTableData,
  setFormStateFlag,
} from '../tables/tableActions';
import { connect } from 'react-redux';
import ScanLandingTemplateSelector from './ScanLandingTemplateSelector';
import { isConsumablesDefaultPlatform } from '../utils';

const ALLOWED_PLATFORM_CODES = ['con', 'webeat'];

interface IScanLandingProps {
  intl: typeof InjectedIntlProps;
  params: IEditParams;
  fields: IEditField[];
  dashboardId: string;
}

export type IScanLandingListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IScanLandingProps;

const ScanLanding: FC<IScanLandingListProps> = (props) => {
  const { selectedRow, params, drawerVisible, setInitialState, setFormData } =
    props;
  const { componentId } = params;

  useEffect(() => {
    setInitialState({
      componentId,
      targetId: appComponents[componentId].targetId,
      fieldsConfig: [],
      values: {},
      selectedTab: '0',
      resetFormTrigger: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update formData when selectedRow changes
  useEffect(() => {
    setFormData({
      componentId,
      values: selectedRow,
    });
  }, [selectedRow, componentId, setFormData]);

  // Clear formData when form is closed
  useEffect(() => {
    if (!drawerVisible) {
      setFormData({ componentId, values: {} });
    }
  }, [drawerVisible, componentId, setFormData]);

  return isEmpty(selectedRow) ? (
    <ScanLandingTemplateSelector {...props} />
  ) : (
    <ScanLandingForm {...props} />
  );
};

const mapStateToProps = (state: ReducersState, ownProps: IScanLandingProps) => {
  return {
    formHasChanged:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .formHasChanged,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    targetId: state.edits[ownProps.params.componentId].targetId,
    values: state.edits[ownProps.params.componentId].values,
    drawerVisible: state.dashboards[ownProps.dashboardId].drawerVisible,
    platformsCombo: state.app.platforms?.filter((platform) =>
      ALLOWED_PLATFORM_CODES.includes(platform.platform_type),
    ),
    consumablesCombo:
      state.combos.combos[ownProps.params.componentId]?.consumableId
        ?.consumableId?.data,
    token: state.auth.accessToken,
    disabled: !isConsumablesDefaultPlatform(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setFormData,
      setInitialState,
      setSelectedRow,
      getTableData,
      setFormStateFlag,
    },
    dispatch,
  );

const InjectedScanLanding = injectIntl(ScanLanding);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedScanLanding);
