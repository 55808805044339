import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { deleteCall } from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function CleanCacheButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();

  useState<boolean>(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const cleanCache = async () => {
    const resp = await deleteCall({
      dataPath: `${apiPaths.CALL.CACHE_MANAGER}/${selectedRow.key}`,
    });
    if (resp.data) parentProps.handleDeleteData();
  };

  return (
    <>
      <Button
        onClick={() => cleanCache()}
        disabled={isEmpty(selectedRow) || !selectedRow?.key}
        type="primary">
        {formatMessage({ id: 'cache-manager.remove-key' })}
      </Button>
    </>
  );
}
