import { ResourceTypeDetailEnum } from '../../challenge/Enums';

// * Use DEFAULT for media files (images, docs, etc) not related to specific resources..
// * .. the API will figure out which type of file it is

export enum S3BucketPathEnum {
  DEFAULT = 0,
  RESOURCE_CHALLENGE_VISIBILITY = 1,
  SELL_OUT_REPORTS = 2,
  RESOURCE_PEARL = 3,
  SCIENTIFIC_MATERIALS = 4,
  MARKETING_MATERIALS = 5,
  CHALLENGE_MATERIALS = 6,
  EXTERNAL_REDEEM_POINTS = 7,
}

export const ResourceDetailsMap = new Map<
  ResourceTypeDetailEnum,
  S3BucketPathEnum
>();

ResourceDetailsMap.set(
  ResourceTypeDetailEnum.MATERIAL_CONTENT,
  S3BucketPathEnum.CHALLENGE_MATERIALS,
);
ResourceDetailsMap.set(
  ResourceTypeDetailEnum.MATERIAL_IMAGE,
  S3BucketPathEnum.CHALLENGE_MATERIALS,
);
