import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatCustomersDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatCustomersDashboardSearch',
      },
      groups: [],
      fields: [
        {
          title: 'Search By Email or First Name',
          key: 'firstName',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Search By Email or First Name',
          key: 'email',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatCustomersDashboardList',
        rowKey: 'idCustomer',
        panelHeader: 'Users List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'email',
        customButtons: ['webeatMassPointsManagementButton'],
      },
      fields: [
        {
          title: formatMessage({ id: 'customerPage.id' }),
          key: 'idCustomer',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'customerPage.email' }),
          key: 'email',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'customerPage.firstName' }),
          key: 'firstName',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.lastName' }),
          key: 'lastName',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.platform' }),
          key: 'platform',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.creationDate' }),
          key: 'createdDate',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'date',
        },
        {
          title: formatMessage({ id: 'customerPage.newsletterSubscription' }),
          key: 'newsletterSubscription',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.active' }),
          key: 'active',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.birthday' }),
          key: 'birthday',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'customerPage.blacklisted' }),
          key: 'blacklisted',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: false,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatCustomersDashboardEdit',
        panelHeader: formatMessage({ id: 'menu.edit' }),
        primaryKey: 'idCustomer',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: 'Editar customer',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'email',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'active',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'blacklisted',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'customerPage.email' }),
          key: 'email',
          type: 'text',
          validationType: 'text',
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'customerPage.status' }),
          key: 'status',
          type: 'text',
          validationType: 'text',
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'customerPage.active' }),
          key: 'active',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'customerPage.blacklisted' }),
          key: 'blacklisted',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          mandatory: true,
          disabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: false,
        canCreate: false,
        canDelete: false,
      },
    },
  ];

  const componentId = 'WebeatCustomersDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatCustomersDashboard;
