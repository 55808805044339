import { Button, Col, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { Dispatch } from 'redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { SetQuiz } from '../../challengeActions';
import { IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { getDetail } from '../../ChallengeUtils';
import { ResourceTypeDetailEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const {
  SELL_IN_PRODUCT_ID: PRODUCT_ID,
  SELL_IN_PRODUCT_MAX_ITEMS: MAX_ITEMS,
  SELL_IN_PRODUCT_MIN_ITEMS: MIN_ITEMS,
  SELL_IN_PRODUCT_DEFAULT_ITEMS: DEFAULT_ITEMS,
} = ResourceTypeDetailEnum;

interface IProductsList {
  combo?: ComboData[];
  editRecursiveResource: ({
    value,
    parentArrayPosition,
    childArrayPosition,
    detailArrayPosition,
  }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  groupIndex: number;
  resource: IResource;
  handleRemoveParentResource: (
    questionArrayPosition: number,
  ) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
}

const SellInProductContainer: FC<IProductsList> = ({
  combo,
  editResource,
  editRecursiveResource,
  form,
  handleRemoveParentResource,
  groupIndex,
  resource,
}) => {
  const { idResource: groupIdResource, resourceDetailList } = resource;

  if (!resourceDetailList) return <React.Fragment />;

  const [productDetail, productDetailIndex] = getDetail(
    resourceDetailList,
    PRODUCT_ID,
  );
  const [maxDetail, maxDetailIndex] = getDetail(resourceDetailList, MAX_ITEMS);
  const [minDetail, minDetailIndex] = getDetail(resourceDetailList, MIN_ITEMS);
  const [defaultDetail, defaultDetailIndex] = getDetail(
    resourceDetailList,
    DEFAULT_ITEMS,
  );

  if (!productDetail || !maxDetail || !minDetail || !defaultDetail)
    return <React.Fragment />;

  const renderProductDetail = ({
    combo,
    detail,
    detailArrayPosition,
  }: {
    combo?: ComboData[];
    detail: IResourceDetail;
    detailArrayPosition?: number;
  }) =>
    RenderDetail({
      combo,
      detail,
      editRecursiveResource,
      editResource,
      form,
      parentArrayPosition: groupIndex,
      // childArrayPosition: productIndex,
      detailArrayPosition,
      resource,
      mainIdResource: groupIdResource,
    });

  return (
    <Row gutter={[0, 24]} className="shopping-product">
      <Row type="flex" justify="end" className="delete-button-row">
        {editResource && (
          <Button
            icon="close"
            className="buttonNoFrame delete-button"
            onClick={() => {
              handleRemoveParentResource(groupIndex);
            }}
          />
        )}
      </Row>

      <Row type="flex" justify="center">
        <div className="no-image" />
      </Row>
      <Col span={23}>
        {renderProductDetail({
          combo,
          detail: productDetail,
          detailArrayPosition: productDetailIndex!,
        })}
      </Col>

      <Col span={8}>
        {renderProductDetail({
          detail: minDetail,
          detailArrayPosition: minDetailIndex!,
        })}
      </Col>
      <Col span={8}>
        {renderProductDetail({
          detail: maxDetail,
          detailArrayPosition: maxDetailIndex!,
        })}
      </Col>
      <Col span={8}>
        {renderProductDetail({
          detail: defaultDetail,
          detailArrayPosition: defaultDetailIndex!,
        })}
      </Col>
    </Row>
  );
};

export default SellInProductContainer;
