import { authTypes } from './authTypes';

export interface AuthState {
  isAuthorized: boolean;
  isLoading: boolean;
  visible: boolean;
  refreshToken: string;
  accessToken: string;
}

const initialState: AuthState = {
  isAuthorized: false,
  isLoading: false,
  visible: false,
  refreshToken: '',
  accessToken: ''
};

export function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case authTypes.AUTH_USER:
      return {
        ...state,
        isAuthorized: true
      };
    case authTypes.UNAUTH_USER:
      return {
        ...state,
        isAuthorized: false
      };
    case authTypes.SET_TOKENS:
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken
      };
    case authTypes.REMOVE_TOKEN:
      return {
        ...initialState
      };
    case authTypes.ENABLE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case authTypes.DISABLE_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case authTypes.SUCCESS_MODAL:
      return {
        ...state,
        visible: action.payload.visible
      };
    default:
      return state;
  }
}
