import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { IRow } from '../app/AppInterfaces';
import { ReducersState } from '../reducers';
import { setSelectedRow, updateSelectedRow } from '../tables/tableActions';
import { TableData } from '../tables/tableInterfaces';
import {
  IBrand,
  IChallengeTemplate,
  IChallengeType,
  IResource,
  IResourceDetail,
  IResourceType,
} from './ChallengeInterfaces';
import { ResourceTypeEnum } from './Enums';
import { challengeTypes } from './challengeTypes';

export interface SetChallengeLoading {
  type: challengeTypes.CHALLENGE_SET_LOADING;
  payload: { isLoading: boolean };
}

export interface SetChallengeEdition {
  type: challengeTypes.CHALLENGE_SET_EDITING_CHALLENGE;
  payload: { editingChallenge: boolean };
}

export const setEditionChallenge =
  (value: boolean) => (dispatch: Dispatch<SetChallengeEdition>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_EDITING_CHALLENGE,
      payload: { editingChallenge: value },
    });
  };

export interface SetResourceEdition {
  type: challengeTypes.CHALLENGE_SET_EDITING_RESOURCE;
  payload: { editingResource: boolean };
}

export const setEditionResource =
  (value: boolean) => (dispatch: Dispatch<SetResourceEdition>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_EDITING_RESOURCE,
      payload: { editingResource: value },
    });
  };

interface SetChallengeInitialState {
  type: challengeTypes.CHALLENGE_INITIAL_STATE;
  payload: {
    editingChallenge: boolean;
    editingResource: boolean;
    sortingResources: boolean;
  };
}

interface FetchTemplates {
  type: challengeTypes.CHALLENGE_FETCH_TEMPLATES;
  payload: {
    availableResources: IResourceType[];
    challengeTemplate?: IChallengeTemplate[];
    challengeType: IChallengeType[];
    isLoading: boolean;
  };
}

export const setChallengeInitialState =
  () =>
    async (
      dispatch: Dispatch<
        SetChallengeLoading | SetChallengeInitialState | FetchTemplates
      >,
      getState: () => ReducersState,
    ) => {
      const challengeTemplate = getState().challenge.challengeTemplate;
      const challengeType = getState().challenge.challengeType;
      const availableResources = getState().challenge.availableResources;

      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: true },
      });
      let _challengeType, _challengeTemplate, _availableResources;

      dispatch({
        type: challengeTypes.CHALLENGE_INITIAL_STATE,
        payload: {
          editingChallenge: false,
          editingResource: false,
          sortingResources: false,
        },
      });
      try {
        if (isEmpty(challengeType))
          _challengeType = await api.getDataCall({
            dataPath: apiPaths.CALL.CHALLENGETYPE,
            callConfig: {},
          });

        if (isEmpty(challengeTemplate))
          _challengeTemplate = await api.getDataCall({
            dataPath: apiPaths.CALL.CHALLENGETEMPLATE,
            callConfig: {},
          });
        if (isEmpty(availableResources))
          _availableResources = await api.getDataCall({
            dataPath: apiPaths.CALL.RESOURCETYPE,
            callConfig: {},
          });

        if (
          _challengeType?.data &&
          _challengeTemplate?.data &&
          _availableResources?.data
        ) {
          dispatch({
            type: challengeTypes.CHALLENGE_FETCH_TEMPLATES,
            payload: {
              challengeType: _challengeType.data.content,
              challengeTemplate: _challengeTemplate.data.content,
              availableResources: _availableResources.data.content,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: challengeTypes.CHALLENGE_SET_LOADING,
            payload: { isLoading: false },
          });
        }
        return {
          action: 'fetch',
          status: 200,
        };
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: false },
        });

        if (!err.response) return { action: 'fetch', status: {} };

        const status = {
          action: 'fetch',
          status: err.response.status,
          message: err.response.data.message,
        };

        return status;
      }
    };

export const setWebeatChallengeInitialState =
  () =>
    async (
      dispatch: Dispatch<
        SetChallengeLoading | SetChallengeInitialState | FetchTemplates
      >,
      getState: () => ReducersState,
    ) => {
      // const challengeTemplate = getState().challenge.challengeTemplate;
      const challengeType = getState().challenge.challengeType;
      const availableResources = getState().challenge.availableResources;

      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: true },
      });
      let _challengeType, /* _challengeTemplate , */ _availableResources;

      dispatch({
        type: challengeTypes.CHALLENGE_INITIAL_STATE,
        payload: {
          editingChallenge: false,
          editingResource: false,
          sortingResources: false,
        },
      });
      try {
        if (isEmpty(challengeType))
          _challengeType = await api.getDataCall({
            dataPath: apiPaths.WEBEAT.CALL.CHALLENGE_TYPE,
            callConfig: {},
          });

        /* if (isEmpty(challengeTemplate))
            _challengeTemplate = await api.getDataCall({
              dataPath: apiPaths.CALL.CHALLENGETEMPLATE,
              callConfig: {},
            }); */
        if (isEmpty(availableResources))
          _availableResources = await api.getDataCall({
            dataPath: apiPaths.WEBEAT.CALL.RESOURCE_TYPE,
            callConfig: {},
          });

        if (
          _challengeType?.data &&
          // _challengeTemplate?.data &&
          _availableResources?.data
        ) {
          dispatch({
            type: challengeTypes.CHALLENGE_FETCH_TEMPLATES,
            payload: {
              challengeType: _challengeType.data.content,
              challengeTemplate: undefined,
              availableResources: _availableResources.data.content,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: challengeTypes.CHALLENGE_SET_LOADING,
            payload: { isLoading: false },
          });
        }
        return {
          action: 'fetch',
          status: 200,
        };
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: false },
        });
        if (!err.response) return { action: 'fetch', status: {} };
        return {
          action: 'fetch',
          status: err.response.status,
          message: err.response.data.message,
        };
      }
    };

interface CreateChallenge {
  type: challengeTypes.CHALLENGE_CREATE;
  payload: { isLoading: boolean };
}

export interface ICreateChallengeResponse {
  action: 'create' | 'fetch';
  status: number;
  data?: any;
}

export const createChallenge =
  ({
     id,
     path,
     applyLoading,
   }: {
    id: number;
    path: string;
    applyLoading: boolean;
  }) =>
    async (
      dispatch: Dispatch<SetChallengeLoading | CreateChallenge>,
    ): Promise<ICreateChallengeResponse> => {
      if (applyLoading)
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: true },
        });

      try {
        const response = await api.postDataCallById({
          dataPath: path,
          id,
        });

        //2. get new data from challenge
        if (applyLoading && response.data) {
          dispatch({
            type: challengeTypes.CHALLENGE_CREATE,
            payload: { isLoading: false },
          });
        }

        const status: ICreateChallengeResponse = {
          action: 'create',
          status: response.status,
          data: response.data,
        };

        return status;
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: false },
        });
        if (!err.response) return { action: 'fetch', status: 400 };

        const status: ICreateChallengeResponse = {
          action: 'fetch',
          status: Number(err.response.status),
          data: err.response.data.message,
        };

        return status;
      }
    };

export const setLoading =
  (isLoading: boolean) => (dispatch: Dispatch<SetChallengeLoading>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_LOADING,
      payload: { isLoading },
    });
  };

export const createResourceFromTemplate =
  (
    idResourceType: ResourceTypeEnum,
    data: { idChallenge: number; order: number; indResource?: number },
  ) =>
    async (dispatch: Dispatch<SetChallengeLoading>) => {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: true },
      });
      try {
        const response = await api.postDataCallById({
          dataPath: apiPaths.CALL.RESOURCE_FROM_TEMPLATE,
          id: idResourceType,
          data,
        });

        //2. get new data from challenge
        if (response.data) {
          dispatch({
            type: challengeTypes.CHALLENGE_SET_LOADING,
            payload: { isLoading: false },
          });
        }

        const status = {
          action: 'create',
          status: response.status,
          data: response.data,
        };
        return status;
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: false },
        });
        if (!err.response) return { action: 'fetch', status: {} };
        const status = {
          action: 'fetch',
          status: err.response.status,
          message: err.response.data.message,
        };
        return status;
      }
    };

interface UpdateResource {
  type: challengeTypes.CHALLENGE_RESOURCE_UPDATED;
  payload: { isLoading: boolean; resourceHasChanged: boolean };
}

export const updateResource =
  (resource: IResource) =>
    async (dispatch: Dispatch<SetChallengeLoading | UpdateResource>) => {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: true },
      });
      try {
        const response = await api.putDataCall({
          dataPath: apiPaths.CALL.RESOURCE_RECURSIVE,
          data: { ...resource },
          callConfig: {},
        });
        dispatch({
          type: challengeTypes.CHALLENGE_RESOURCE_UPDATED,
          payload: { isLoading: false, resourceHasChanged: false },
        });
        const status = {
          action: 'update',
          status: response.status,
          data: response.data,
        };
        return status;
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SET_LOADING,
          payload: { isLoading: false },
        });
        if (!err.response) return { action: 'fetch', status: {} };
        const status = {
          action: 'fetch',
          status: err.response.status,
          message: err.response.data.message,
        };
        return status;
      }
    };

export const deleteResource =
  (resourceId: number) => async (dispatch: Dispatch<SetChallengeLoading>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_LOADING,
      payload: { isLoading: true },
    });

    try {
      const response = await api.deleteDataCallById({
        dataPath: apiPaths.CALL.RESOURCE,
        registerId: resourceId,
        callConfig: {},
      });
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: false },
      });
      const status = {
        action: 'delete',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: false },
      });

      if (!err.response) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

export interface SetSelectedResource {
  type: challengeTypes.CHALLENGE_SET_SELECTED_RESOURCE;
  payload: { selectedResource: IResource };
}

export const setSelectedResource =
  (resource?: IResource) => (dispatch: Dispatch<SetSelectedResource>) => {
    const selectedResource =
      resource !== undefined
        ? JSON.parse(JSON.stringify(resource))
        : ({} as IResource);
    dispatch({
      type: challengeTypes.CHALLENGE_SET_SELECTED_RESOURCE,
      payload: { selectedResource },
    });
  };

export interface UpdateResourceDetail {
  type: challengeTypes.CHALLENGE_UPDATE_DETAIL;
  payload: { selectedResource: IResource };
}

export const updateResourceDetail =
  (idResourceD: number, value: any) =>
    (dispatch: Dispatch<UpdateResourceDetail>, getState: () => ReducersState) => {
      let selectedResource = { ...getState().challenge.selectedResource };
      let control = false;
      selectedResource.resourceDetailList?.forEach((_resourceDetail) => {
        if (_resourceDetail.idResourceD === idResourceD) {
          _resourceDetail.value = value;
          control = true;
        }
      });

      if (
        !control &&
        selectedResource.resourceList &&
        selectedResource.resourceList.length
      )
        selectedResource.resourceList.forEach((_resource) => {
          if (!control)
            _resource.resourceDetailList?.forEach((_resourceDetail) => {
              if (_resourceDetail.idResourceD === idResourceD) {
                _resourceDetail.value = value;
                control = true;
              }
            });
        });

      dispatch({
        type: challengeTypes.CHALLENGE_UPDATE_DETAIL,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export interface SetResourceHasChanged {
  type: challengeTypes.CHALLENGE_SET_RESOURCE_CHANGED;
  payload: { resourceHasChanged: boolean };
}

export const setResourceHasChanged =
  (value: boolean) => (dispatch: Dispatch<SetResourceHasChanged>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_RESOURCE_CHANGED,
      payload: { resourceHasChanged: value },
    });
  };

export interface SetQuiz {
  type: challengeTypes.CHALLENGE_SET_QUIZ;
  payload: { selectedResource: IResource; resourceHasChanged: boolean };
}

export const setQuiz =
  (selectedResource: IResource) => (dispatch: Dispatch<SetQuiz>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_QUIZ,
      payload: { selectedResource, resourceHasChanged: true },
    });
  };

export interface ResetQuiz {
  type: challengeTypes.CHALLENGE_RESET_QUIZ;
  payload: {
    selectedResource: IResource;
    resourceHasChanged: boolean;
    editingResource: boolean;
  };
}

export const resetQuiz =
  () => (dispatch: Dispatch<ResetQuiz>, getState: () => ReducersState) => {
    //TODO change este qui
    dispatch({
      type: challengeTypes.CHALLENGE_RESET_QUIZ,
      payload: {
        selectedResource: {} as IResource,
        // selectedResource: JSON.parse(JSON.stringify(quiz)),
        resourceHasChanged: false,
        editingResource: false,
      },
    });
  };

export const resetChallenge =
  () => (dispatch: any, getState: () => ReducersState) => {
    const data: TableData = {
      ...getState().tables['challengechallengeList'].data,
    };
    const selectedRow: IRow = { ...getState().tables['challengechallengeList'] }
      .selectedRow;

    if (data && data.content && data.content.length) {
      data.content.forEach((row) => {
        if (row.idChallenge === selectedRow.idChallenge) {
          setSelectedRow({
            componentId: 'challengechallengeList',
            selectedRow: row,
          });
        }
      });
    }
    setResourceHasChanged(false);
  };

export const editRecursiveResource =
  ({
     value,
     parentArrayPosition,
     childArrayPosition,
     detailArrayPosition,
   }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      let resource: IResource = { ...getState().challenge.selectedResource };
      if (resource?.resourceList)
        if (
          parentArrayPosition === undefined &&
          detailArrayPosition !== undefined
        ) {
          resource.resourceDetailList![detailArrayPosition].value = value;
        } else if (
          parentArrayPosition !== undefined &&
          childArrayPosition === undefined
        ) {
          if (detailArrayPosition !== undefined) {
            resource.resourceList![parentArrayPosition].resourceDetailList![
              detailArrayPosition
              ].value = value;
          } else {
            resource.resourceList![parentArrayPosition].name = value;
          }
        } else if (
          parentArrayPosition !== undefined &&
          childArrayPosition !== undefined
        ) {
          if (detailArrayPosition !== undefined) {
            resource.resourceList![parentArrayPosition].resourceList![
              childArrayPosition
              ].resourceDetailList![detailArrayPosition].value = value;
          } else {
            resource.resourceList![parentArrayPosition].resourceList![
              childArrayPosition
              ].name = value;
          }
        }

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource: resource, resourceHasChanged: true },
      });
    };

export const editChildResourceCode =
  (idResource: number, value: any) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      let resource: IResource = { ...getState().challenge.selectedResource };

      resource.resourceList?.forEach(({ idResource: childId }, index) => {
        if (idResource === childId) resource.resourceList![index].code = value;
      });

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource: resource, resourceHasChanged: true },
      });
    };

export const handleAddParentResource =
  (newResource: IResource) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };
      selectedResource.resourceList!.push(newResource);

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export const handleAddDetail =
  (newDetail: IResourceDetail, parentResource?: IResource) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };

      if (parentResource) {
        parentResource.resourceDetailList!.push(newDetail);
      } else {
        selectedResource.resourceDetailList!.push(newDetail);
      }
      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export const handleDeleteDetail =
  (detailArrayPosition: number, parentResource?: IResource) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };
      if (detailArrayPosition) {
        if (parentResource) {
          parentResource.resourceDetailList![detailArrayPosition].status = false;
        } else {
          selectedResource.resourceDetailList![detailArrayPosition].status =
            false;
        }
      }

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export const handleRemoveParentResource =
  (questionArrayPosition: number) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };

      if (
        selectedResource.resourceList![questionArrayPosition].idResource === -1
      ) {
        selectedResource.resourceList!.splice(questionArrayPosition, 1);
      } else {
        selectedResource.resourceList![questionArrayPosition].status = false;
      }

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export const handleAddChildResource =
  (newResource: IResource, questionArrayPosition: number) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };
      selectedResource.resourceList![questionArrayPosition].resourceList!.push(
        newResource,
      );

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export const handleRemoveChildResource =
  ({
     idAnswer,
     questionArrayPosition,
     answerArrayPosition,
   }: {
    idAnswer: number;
    idQuestion: number;
    questionArrayPosition: number;
    answerArrayPosition?: number;
  }) =>
    (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => {
      const selectedResource = { ...getState().challenge.selectedResource };

      if (idAnswer !== -1) {
        selectedResource.resourceList!.forEach((question: IResource) => {
          question.resourceList!.forEach((answer: IResource) => {
            if (answer.idResource === idAnswer) {
              answer.status = false;
            }
          });
        });
      } else {
        (answerArrayPosition || answerArrayPosition === 0) &&
        selectedResource.resourceList![
          questionArrayPosition
          ].resourceList!.splice(answerArrayPosition, 1);
      }

      dispatch({
        type: challengeTypes.CHALLENGE_SET_QUIZ,
        payload: { selectedResource, resourceHasChanged: true },
      });
    };

export interface SetEditSorting {
  type: challengeTypes.CHALLENGE_SORTING_RESOURCES;
  payload: { sortingResources: boolean };
}

export const setEditSorting =
  (sortingResources: boolean) => (dispatch: Dispatch<SetEditSorting>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SORTING_RESOURCES,
      payload: { sortingResources },
    });
  };

export interface SaveSorting {
  type: challengeTypes.CHALLENGE_SAVE_SORTING;
  payload: { isLoading: boolean; sortingResources: boolean };
}

export const saveSorting =
  (
    idChallenge: number,
    primaryKey: string,
    componentListId: string,
    sortingResources: { idResource: number; order: number }[],
  ) =>
    async (dispatch: any) => {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: true },
      });

      try {
        const response = await api.putDataCall({
          dataPath: `${apiPaths.CALL.CHALLENGE}/order`,
          data: { idChallenge, resources: sortingResources },
          callConfig: {},
        });

        if ('status' in response && response.status === 200)
          dispatch(updateSelectedRow(response.data, primaryKey, componentListId));

        dispatch({
          type: challengeTypes.CHALLENGE_SAVE_SORTING,
          payload: { isLoading: false, sortingResources: false },
        });
      } catch (err) {
        dispatch({
          type: challengeTypes.CHALLENGE_SAVE_SORTING,
          payload: { isLoading: false, sortingResources: false },
        });
      }
    };

interface SetBrands {
  type: challengeTypes.CHALLENGE_SET_BRANDS;
  payload: {
    brands: IBrand[];
    isLoading: boolean;
  };
}

export const getBrands =
  () => async (dispatch: Dispatch<SetChallengeLoading | SetBrands>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const response = await api.getDataCall({
        dataPath: apiPaths.CALL.BRANDS,
        callConfig: {},
      });
      if (response.data)
        dispatch({
          type: challengeTypes.CHALLENGE_SET_BRANDS,
          payload: { brands: response.data, isLoading: false },
        });
    } catch (err) {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };

export type ChallengeActionTypes =
  | SaveSorting
  | SetEditSorting
  | SetChallengeInitialState
  | SetChallengeLoading
  | CreateChallenge
  | SetChallengeEdition
  | SetResourceEdition
  | SetSelectedResource
  | UpdateResourceDetail
  | SetResourceHasChanged
  | UpdateResource
  | SetQuiz
  | ResetQuiz
  | FetchTemplates
  | SetBrands;
