import { ProductItem } from './components';
import {
  DESCRIPTION,
  DESCRIPTION_MAX_LENGTH,
  PRODUCT_DESCRIPTION_MAX_LENGTH,
  SUBTITLE,
  SUBTITLE_MAX_LENGTH,
  TITLE,
  TITLE_MAX_LENGTH,
} from './constants';

export const validateProductsFields = (fields: any) => {
  let control: boolean = true;
  let errorMessage: string = '';

  const parseFields = JSON.parse(fields);

  if (
    typeof parseFields?.[TITLE] !== 'string' ||
    parseFields[TITLE].length > TITLE_MAX_LENGTH ||
    !parseFields[TITLE].length
  ) {
    return {
      control: false,
      errorMessage: `[PRODUCTS] TITLE is required and should be less than ${TITLE_MAX_LENGTH} characters`,
    };
  }

  if (
    typeof parseFields?.[SUBTITLE] !== 'string' ||
    parseFields[SUBTITLE].length > SUBTITLE_MAX_LENGTH ||
    !parseFields[SUBTITLE].length
  ) {
    return {
      control: false,
      errorMessage: `[PRODUCTS] SUBTITLE is required and should be less than ${SUBTITLE_MAX_LENGTH} characters`,
    };
  }

  if (
    typeof parseFields?.[DESCRIPTION] !== 'string' ||
    parseFields[DESCRIPTION].length > DESCRIPTION_MAX_LENGTH ||
    !parseFields[DESCRIPTION].length
  ) {
    return {
      control: false,
      errorMessage: `[PRODUCTS] DESCRIPTION is required and should be less than ${DESCRIPTION_MAX_LENGTH} characters`,
    };
  }

  parseFields['productList'].forEach((product: ProductItem) => {
    if (!product.description.length) {
      control = false;
      errorMessage = `[PRODUCTS]:\n ${product.name} Description is required and should be less than ${PRODUCT_DESCRIPTION_MAX_LENGTH} characters`;
    }

    if (!product.checkFile && !product.checkUrlImage) {
      control = false;
      errorMessage = `[PRODUCTS]:\n ${product.name} URL IMAGE or FILE is required`;
    }

    if (!!product.checkUrlImage && !isValidUrl(product.url)) {
      console.log('product.urlImage', product);
      control = false;
      errorMessage = `[PRODUCTS]: \n ${product.name} URL IMAGE or FILE is required`;
    }

    if (!!product.checkFile && !product.file) {
      console.log('product.file', product);
      control = false;
      errorMessage = `[PRODUCTS]: \n ${product.name} URL IMAGE or FILE is required`;
    }
  });

  return {
    control,
    errorMessage,
  };
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
