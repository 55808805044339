import React from 'react';
import TableRender from './TableRender';
import customDashboards from '../extensions/customDashboards';
import { ISmartTableListProps } from './SmartTable';
import { TableData, Column } from './tableInterfaces';
import { IRecord, IRow } from '../app/AppInterfaces';
import { PaginationConfig, SorterResult } from 'antd/lib/table';

export interface ISmartRenderProps {
  dataSource: TableData;
  isLoading: boolean;
  props: ISmartTableListProps;
  onChangeMultipleRows: (
    selectedRowKeys: number[] | string[],
    selectedRows: any[],
  ) => void;
  onSelectAllRows: (
    elected: boolean,
    selectedRows: IRecord[],
    changeRows: IRecord[],
  ) => void;
  onSelectMultipleRows: (
    record: IRecord,
    selected: boolean,
    selectedRows: Object[],
    nativeEvent: Event,
  ) => void;
  handleResize: (index: number) => void;
  handleOpenFile: ({
    fkFolder,
    docName,
  }: {
    fkFolder: string;
    docName: string;
  }) => void;
  tableTotalWidth: (columns: Column[]) => number;
  onSelectRow: (record: IRow) => void;
  handleChangeDrawerVisibility: (visible: boolean) => void;
  handleTableChange: (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<IRecord>,
    extra: any,
  ) => void;
  handleReloadData: () => Promise<void>;
  handleSetM2MSwitch: (checked: boolean) => Promise<void>;
  handleDeleteData: () => Promise<void>;
  onChangeColumnVisibility: (e: { key: string }) => void;
}

export default function SmartTableRender(parentProps: ISmartRenderProps) {
  const componentProps =
    parentProps.props.customDashboard &&
    customDashboards[parentProps.props.customDashboard] &&
    customDashboards[parentProps.props.customDashboard].table !== undefined
      ? customDashboards[parentProps.props.customDashboard].table!(parentProps)
      : { ...parentProps };

  return <TableRender {...componentProps} />;
}
