import { Button, Col, Form, Icon, Popconfirm, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import {
  isImageFileFormat,
  typesOfSelectEnum,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_VIDEO,
  UPLOAD_ACTION_YOUTUBE_URL,
} from '../../../shared';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { QuizQuestionTypeEnum, ResourceTypeDetailEnum, ResourceTypeEnum } from '../../Enums';
import '../Resource.css';
import { blankImageDetail } from '../ResourceConfigs';
import RenderDetail from '../TreeResource/TreeDetails';
import Upload from '../UploadComponent';
import AnswerResource from './AnswerResource';
import WYSWYGComponent from '../../../contentPage/components/WYSWYGComponent';
import { IRow } from '../../../app/AppInterfaces';

const { QUESTION_VIDEO_IS_VERTICAL, QUESTION_IMAGE_URL, QUESTION_JUSTIFICATION, QUESTION_VIDEO_URL } =
  ResourceTypeDetailEnum;

const MAX_OPTIONA_IMAGE_BY_QUESTION = 3;

interface IQuestionMedia {
  _detail: IResourceDetail;
  index: number;
  value: string;
}

interface QuestionResource {
  handleAddDetail?: (
    newDetail: IResourceDetail,
    parentResource?: IResource,
  ) => void;
  handleDeleteDetail?: (
    detailArrayPosition: number,
    parentResource?: IResource,
  ) => void;
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  questionIndex: number;
  values: IRow;
}

type QuestionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  QuestionResource;

const QuestionComponent = (props: QuestionProps) => {
  const {
    handleDeleteDetail,
    handleAddDetail,
    _resource,
    editRecursiveResource,
    editResource,
    form,
    handleDeleteImages,
    questionIndex,
    accessToken,
    combos,
    values,
  } = props;
  const questionResource = _resource;
  const details = _resource.resourceDetailList!;
  const intl = useIntl();

  const currentQuestionType: QuizQuestionTypeEnum =
    questionResource.resourceDetailList?.find(
      ({ idResourceTypeD }) =>
        idResourceTypeD.idResourceTypeD ===
        ResourceTypeDetailEnum.QUESTION_TYPE,
    )?.value;

  let detailsToRender: IndexedDetail[] = [];

  let optionalImages: IQuestionMedia[] = [];
  let mainMediaData: { video?: IQuestionMedia; image?: IQuestionMedia, videoIsVertical?: IQuestionMedia } = {};

  let justification: IQuestionMedia = {} as IQuestionMedia;

  details.forEach((_detail: IResourceDetail, index: number) => {
    const { idResourceTypeD } = _detail.idResourceTypeD;
    const questionMedia: IQuestionMedia = {
      _detail,
      index,
      value: _detail.value,
    };

    switch (idResourceTypeD) {
      case QUESTION_VIDEO_IS_VERTICAL:
        mainMediaData.videoIsVertical = questionMedia;
        break;
      case QUESTION_VIDEO_URL:
        mainMediaData.video = questionMedia;
        break;
      case QUESTION_IMAGE_URL:
        if (mainMediaData.image) {
          optionalImages.push(questionMedia);
          break;
        }
        mainMediaData.image = questionMedia;
        break;
      case QUESTION_JUSTIFICATION:
        justification = questionMedia;
        break;
      default:
        detailsToRender.push({ index, detail: _detail });
        break;
    }
  });

  const justificationResource = (
    <Row className="QuestionJustification">
      <Col span={24}>
        <Form.Item>
          <WYSWYGComponent
            title={intl.formatMessage({
              id: 'tree-resource.question.justification',
            })}
            readonly={!editResource}
            handleEdit={(value: any) => {
              editRecursiveResource({
                value,
                parentArrayPosition: questionIndex,
                detailArrayPosition: justification.index,
              });
            }}
            value={justification._detail?.value}
          />
        </Form.Item>
      </Col>
    </Row>
  );

  const renderMediaResource = (details: IQuestionMedia[]) => {
    const _mainMediaData = mainMediaData.video?.value
      ? mainMediaData.video
      : mainMediaData.image;

    return (
      <Row justify="end" className="QuizQuestion__media">
        {renderMediaUploadComponente({
          mediaData: _mainMediaData,
          isMainUpload: true,
        })}
        {details
          .filter(({ _detail: { status } }) => status)
          .map((mediaData) =>
            renderMediaUploadComponente({
              mediaData,
              isMainUpload: false,
            }),
          )}
      </Row>
    );
  };

  const renderMediaUploadComponente = ({
                                         mediaData,
                                         isMainUpload,
                                       }: {
    mediaData: IQuestionMedia | undefined;
    isMainUpload: boolean;
  }): JSX.Element => {
    if (!mediaData) return <React.Fragment />;

    const { _detail, index } = mediaData;

    return (
      <Row style={{ paddingLeft: '1vw' }}>
        <Col span={23}>
          <Form.Item
            key={index}
            className="Quiz__input"
            label={intl.formatMessage({
              id: `challenge.media.attach-media-${
                isMainUpload ? 'main' : 'optional-image'
              }`,
            })}>
            <Upload
              {...{
                token: props.accessToken,
                disabled: !editResource,
                format: isMainUpload ? 'video' : 'image',
                value: _detail.value,
                isVerticalVideo: mainMediaData.videoIsVertical?.value === 'true',
                setIsVerticalVideo: (isVertical) => {
                  editRecursiveResource({
                    value: isVertical ? 'true' : 'false',
                    parentArrayPosition: questionIndex,
                    detailArrayPosition: mainMediaData.videoIsVertical?.index,
                  });
                },
                showIsVerticalVideo: !!mainMediaData.videoIsVertical,
                uploadActions: isMainUpload
                  ? [
                    UPLOAD_ACTION_VIDEO,
                    UPLOAD_ACTION_YOUTUBE_URL,
                    UPLOAD_ACTION_IMAGE,
                  ]
                  : [UPLOAD_ACTION_IMAGE],
                setValue: function(
                  value?: string | null,
                  fileType?: ResourceTypeEnum | typesOfSelectEnum,
                ) {
                  editRecursiveResource({
                    value,
                    parentArrayPosition: questionIndex,
                    detailArrayPosition: isMainUpload
                      ? fileType === ResourceTypeEnum.IMAGE
                        ? mainMediaData.image?.index
                        : mainMediaData.video?.index
                      : index,
                  });
                  if (isMainUpload) {
                    // Remove index of previous selected type
                    const indexToRemove = fileType === ResourceTypeEnum.IMAGE
                      ? mainMediaData.video?.index
                      : mainMediaData.image?.index;
                    editRecursiveResource({
                      value: null,
                      parentArrayPosition: questionIndex,
                      detailArrayPosition: indexToRemove,
                    });
                  }
                },
                handleChangeField:
                  _detail.value && isImageFileFormat(_detail.value)
                    ? function() {
                      handleDeleteImages(_detail.value, _detail.idResourceD);
                    }
                    : undefined,
                primaryEntityId: _resource.idChallenge,
                module: 'challenge',
              }}>
              <Button
                className="uploadButton uploadButton__margin-top"
                disabled={!editResource}>
                <Icon type="upload" />
                {intl.formatMessage({
                  id: 'upload.buttonTitle',
                })}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        {isMainUpload ? (
          <React.Fragment />
        ) : (
          <Col className="QuizQuestion__btnDeleteImage" span={1}>
            {editResource && (
              <Popconfirm
                title={intl.formatMessage({ id: 'pop.title.delete' })}
                icon={
                  <Icon type="exclamation-circle" style={{ color: 'red' }} />
                }
                okText={intl.formatMessage({ id: 'pop.accept' })}
                cancelText={intl.formatMessage({ id: 'pop.cancel' })}
                onConfirm={() => handleDeleteDetail!(index, _resource)}>
                <Button className="QuizQuestion__deleteButton" icon="close" />
              </Popconfirm>
            )}
          </Col>
        )}
      </Row>
    );
  };
  return (
    <>
      {detailsToRender
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === ResourceTypeDetailEnum.QUESTION_TYPE) {
            combo =
              combos.challengechallengeEdit?.type?.questionType?.data ?? [];
          }

          return RenderDetail({
            combo,
            accessToken,
            detail,
            detailArrayPosition: index,
            parentArrayPosition: questionIndex,
            editRecursiveResource,
            editResource,
            form,
            resource: _resource,
            mainIdResource: _resource.idResource,
            values,
          });
        })}
      {questionResource.resourceList?.length && (
        <>
          {questionResource.resourceList.map(
            (answer, answerIndex) =>
              answer.status && (
                <AnswerResource
                  key={questionIndex.toString().concat(answerIndex.toString())}
                  value={
                    answer &&
                    answer.resourceDetailList &&
                    answer.resourceDetailList[0].value.toString()
                  }
                  {...{
                    form,
                    _resource: answer,
                    allResources: questionResource.resourceList,
                    questionIndex,
                    answerIndex,
                    idQuestion: questionResource.idResource,
                    editResource,
                    answersNumber: questionResource.resourceList?.filter(
                      (_list) => _list.status,
                    ).length,
                    currentQuestionType,
                  }}
                />
              ),
          )}
        </>
      )}
      {renderMediaResource(optionalImages)}

      {handleAddDetail && (
        <Row className="Quiz__addQuestion" type="flex" justify="center">
          <Button
            disabled={
              !editResource ||
              optionalImages.filter(({ _detail: { status } }) => status)
                .length >= MAX_OPTIONA_IMAGE_BY_QUESTION
            }
            type="primary"
            onClick={() => handleAddDetail(blankImageDetail(), _resource)}>
            {intl.formatMessage({ id: 'challenge.addImage' })}
          </Button>
        </Row>
      )}

      {justificationResource}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionComponent);
