import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ReducersState } from '../../../reducers';
import {
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { DetailsTypesEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import GroupProducts from './Products';

const { NUMBER, TEXT, TEXT_AREA, WYSWYG } = DetailsTypesEnum;

interface IShoppingGroup {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IShoppingGroup;

const ShoppingGroup: FC<IGroupProps> = ({
  _resource,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleRemoveChildResource,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList } = _resource;

  const groupDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) groupDetails.push({ index, detail });
  });

  return (
    <>
      {groupDetails
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .sort(
          (
            {
              detail: {
                idResourceTypeD: { type: typeA },
              },
            },
            {
              detail: {
                idResourceTypeD: { type: typeB },
              },
            },
          ) => {
            if (
              typeA === WYSWYG ||
              typeA === TEXT_AREA ||
              (typeB === TEXT && typeA === NUMBER)
            )
              return 1;

            if (
              typeB === WYSWYG ||
              typeB === TEXT_AREA ||
              (typeB === NUMBER && typeA === TEXT)
            )
              return -1;

            return 0;
          },
        )
        .map(({ detail, index }) =>
          RenderDetail({
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
          }),
        )}
      <GroupProducts
        combo={
          combos.challengechallengeEdit?.products?.shoppingCartProducts?.data ||
          []
        }
        editResource={editResource}
        editRecursiveResource={editRecursiveResource}
        form={form}
        handleRemoveChildResource={handleRemoveChildResource}
        groupIndex={groupIndex}
        mainIdResource={mainIdResource}
        resource={_resource}
        values={values}
      />
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingGroup);
