import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatFaqQuestionDashboard = (props: any) => {
  const { formatMessage } = props.intl;

  const { SMALL, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatFaqQuestionSearch',
      },
      groups: [],
      fields: [
        {
          title: '',
          key: 'idSection',
          value: 'idSection',
          type: 'text',
          operand: 'equal',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'faqQuestion.slug' }),
          key: 'slug',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'faqQuestion.question' }),
          key: 'question',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'faqQuestion.answer' }),
          key: 'answer',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatFaqQuestionList',
        rowKey: 'id',
        panelHeader: '',
        navigationParams: props.query.params.q,
        isMainTable: true,
        tableType: 0,
        prettierKey: 'order',
      },
      fields: [
        {
          title: formatMessage({ id: 'faqQuestion.id' }),
          key: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'faqQuestion.order' }),
          key: 'order',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'faqQuestion.slug' }),
          key: 'slug',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'faqQuestion.question' }),
          key: 'question',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'faqQuestion.answer' }),
          key: 'answer',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatFaqQuestionEdit',
        panelHeader: formatMessage({ id: 'faqQuestion.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'order',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'slug',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 16,
                },
                {
                  key: 'question',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'answer',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'faqQuestion.order' }),
          key: 'order',
          type: 'number',
          unique: false,
          min: 1,
          validationType: 'number',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'faqQuestion.slug' }),
          key: 'slug',
          type: 'text',
          unique: false,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'faqQuestion.question' }),
          key: 'question',
          type: 'textarea',
          unique: false,
          validationType: 'text',
          length: 500,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'faqQuestion.answer' }),
          key: 'answer',
          type: 'markdown',
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];
  const componentId = 'WebeatFaqQuestionDashboard';
  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatFaqQuestionDashboard);
