import React from 'react';
export default function staticPages() {
  return (
    <svg viewBox="0 0 511.205 511.205" className="svgIconFill">
      <g>
        <path d="m7.5 109.351c4.142 0 7.5-3.358 7.5-7.5v-13.593h443.599v71.054c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-127.707c0-17.371-14.132-31.503-31.503-31.503h-410.594c-17.37 0-31.502 14.132-31.502 31.503v70.246c0 4.142 3.358 7.5 7.5 7.5zm24.002-94.249h410.594c9.1 0 16.503 7.403 16.503 16.503v41.653h-443.599v-41.653c0-9.1 7.403-16.503 16.502-16.503z" />
        <path d="m165.293 458.701h-133.791c-9.1 0-16.502-7.403-16.502-16.502v-308.385c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v308.385c0 17.371 14.132 31.502 31.502 31.502h133.791c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m475.966 181.811h-252.935c-19.431 0-35.238 15.808-35.238 35.239v97.444c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-50.333h293.412v211.703c0 11.159-9.079 20.238-20.238 20.238h-252.936c-11.159 0-20.238-9.079-20.238-20.238v-129.407c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v129.407c0 19.431 15.808 35.238 35.238 35.238h252.935c19.431 0 35.238-15.808 35.238-35.238v-258.814c.001-19.431-15.807-35.239-35.238-35.239zm-273.173 67.35v-32.111c0-11.16 9.079-20.239 20.238-20.239h252.935c11.159 0 20.238 9.079 20.238 20.239v32.111z" />
        <path d="m45.96 51.302h5.632c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-5.632c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m98.126 51.302h5.632c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-5.632c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m150.292 51.302h5.632c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-5.632c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m437.835 365.169h-31.581c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h31.581c12.605 0 22.86-10.255 22.86-22.86v-31.841c0-12.605-10.255-22.86-22.86-22.86h-176.672c-12.605 0-22.861 10.255-22.861 22.86v31.841c0 12.605 10.255 22.86 22.861 22.86h113.129c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-113.129c-4.334 0-7.861-3.526-7.861-7.86v-31.841c0-4.334 3.527-7.86 7.861-7.86h176.673c4.334 0 7.86 3.526 7.86 7.86v31.841c0 4.334-3.527 7.86-7.861 7.86z" />
        <path d="m78.855 150.051h318.012c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-318.012c-9.162 0-16.616 7.454-16.616 16.616v93.53c0 9.162 7.454 16.616 16.616 16.616h67.567c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-67.567c-.891 0-1.616-.725-1.616-1.616v-93.53c0-.891.725-1.616 1.616-1.616z" />
        <path d="m146.169 293.608h-76.43c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h76.43c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m146.169 349.831h-76.43c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h76.43c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m245.802 444.933h207.394c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-207.394c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
      </g>
    </svg>
  );
}
