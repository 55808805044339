import { ISmartRenderProps } from '../tables/SmartTableRender';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';
import { EditFormRenderProps } from '../forms/edit/EditFormRender';

import BaseDashboard from './dashboards/BaseDashboard';

export interface IMethods {
  search?: (parentProps: SearchFormRenderProps) => SearchFormRenderProps;
  table?: (parentProps: ISmartRenderProps) => ISmartRenderProps;
  edit?: (parentProps: EditFormRenderProps) => EditFormRenderProps;
}

interface ICustomDashboards {
  [key: string]: IMethods;
}

const customDashboards: ICustomDashboards = {
  BaseDashboard
};

export default customDashboards;
