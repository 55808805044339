import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import { isEmpty, clone } from 'lodash';
import SmartTableRender from './SmartTableRender';
import './SmartTable.css';

import config from '../config';
import * as api from '../api';
// import { DragableHeaderCell } from './DragableColumn';

import {
  setInitialState,
  setColumnsConfig,
  getTableData,
  deleteTableData,
  setSelectedRows,
  setFormStateFlag,
  displayImageModal,
  loadImageModal,
  resetTableData,
  setChildSelectedRow,
  setLoadData,
  setInitialTableParams,
  resetTableContent,
  target,
} from './tableActions';
import { setFormData } from '../forms/edit/editActions';
import {
  navigate,
  returnToParent,
  setBreadcrumbsChild,
} from '../app/queryActions';
import { setDrawerVisibility } from '../dashboards/dashboardActions';
import { logout } from '../auth/authActions';
import { feedback, feedbackController } from '../utils/feedback';
import { appComponents } from '../components';
import { loadConfig, saveConfig } from '../utils/localStorage';
import configDev from '../configDev';
import './SmartTable.css';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { ReducersState } from '../reducers';
import {
  TableParams,
  Column,
  QueryParams,
  TableSettings,
  TableData,
} from './tableInterfaces';
import {
  TDashboardComposerListProps,
  isTable,
} from '../dashboards/DashboardComposer';
import { IRow, IRecord } from '../app/AppInterfaces';
import { SorterResult, PaginationConfig } from 'antd/lib/table';
import { IEditField } from '../fields/FieldsInterfaces';
interface OwnProps {
  params: TableParams;
  fields: Column[];
  m2m?: any;
  settings: TableSettings;
  pagination: PaginationConfig;
  parentFormHasChanged?: boolean;
  dashboardId: string;
}

export type ISmartTableListProps = ReturnType<typeof mapDispatchToProp> &
  ReturnType<typeof mapStateToProps> &
  TDashboardComposerListProps &
  OwnProps;

class SmartTable extends React.PureComponent<ISmartTableListProps, {}> {
  constructor(props: ISmartTableListProps) {
    super(props);
    const { params, fields, setInitialTableParams } = this.props;
    const { componentId } = params;
    let tableParams = {} as QueryParams;

    fields.forEach((col) => {
      if (
        col.hasOwnProperty('defaultSortOrder') &&
        !isEmpty(col.defaultSortOrder)
      ) {
        tableParams = {
          sort: col.defaultSortOrder === 'descend' ? 'DESC' : 'ASC',
          field: col.key,
        };
      }
    });
    setInitialTableParams({
      componentId,
      sort: tableParams.sort,
      field: tableParams.field,
    });
  }

  componentDidMount() {
    const {
      params,
      fields,
      pagination,
      columnsConfig,
      queryParams,
      getTableData,
      filters,
      setInitialState,
      loadData,
      path,
      dataPath,
      setLoadData,
      isM2M,
      getM2MTableData,
      getSelected,
      primaryKey,
      joinKey,
      mainKeyValue,
      foreignKey,
      navigationId,
      qParams,
      selectedRow,
      breadcrumbs,
      data,
    } = this.props;
    const {
      componentId,
      isMainTable,
      prettierKey,
      rowKey,
      disableFindPageByIdRequests,
    } = params;

    let userConfig = loadConfig();
    let query: QueryParams = { q: '' };

    query = { ...queryParams };
    query = { ...qParams };
    fields.forEach((col) => {
      if (
        col.hasOwnProperty('defaultSortOrder') &&
        !isEmpty(col.defaultSortOrder)
      ) {
        query = {
          ...query,
          sort: col.defaultSortOrder === 'descend' ? 'DESC' : 'ASC',
          field: col.key,
        };
      }
    });

    let tableParams: QueryParams = {
      size: pagination.defaultPageSize ? pagination.defaultPageSize : 50,
    };
    tableParams =
      isMainTable === false
        ? { ...queryParams }
        : isM2M
          ? tableParams
          : { ...query, ...tableParams };

    const tableData: TableData = isMainTable
      ? ({} as TableData)
      : data && !isEmpty(data)
        ? data
        : ({} as TableData);

    if (!columnsConfig) {
      const targets: target[] = appComponents[componentId].targetsId;
      const initialState = {
        componentId,
        data: tableData,
        filters: '', //TODO arreglar filtros
        prettierKey: prettierKey,
        queryParams: tableParams,
        rowKey,
        selectedRow: {} as IRow,
        selectedRowKeys: [] as number[],
        targetsId: targets,
      };

      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['columnsConfig']
      ) {
        const cols: Column[] =
          userConfig[config.USER.USERID][componentId]['columnsConfig'];
        setInitialState({
          ...initialState,
          columns: cols,
        });
      } else {
        setInitialState({
          ...initialState,
          columns: fields,
        });
        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) userConfig = {};
          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                columnsConfig: fields,
              },
            },
          };
          saveConfig(newConfig);
        }
      }
    }

    if ((isMainTable === undefined || isMainTable) && loadData !== false) {
      //TODO FIX getTableData -->
      if (isM2M)
        getM2MTableData({
          m2mDataPath: this.getM2MDataPath(path!),
          dataPath: this.getM2MDataPath(dataPath),
          queryParams: tableParams,
          primaryKey: primaryKey!,
          joinKey: joinKey!,
          mainKeyValue,
          foreignKey,
          getSelected,
          navigationId: navigationId!,
        });
      else {
        const dataPath =
          (!selectedRow || isEmpty(selectedRow)) &&
          !isEmpty(breadcrumbs[breadcrumbs.length - 1].child) &&
          !disableFindPageByIdRequests
            ? `${appComponents[componentId].path}/Page/${
                breadcrumbs[breadcrumbs.length - 1].child.value
              }`
            : `${appComponents[componentId].path}`;
        getTableData({
          dataPath,
          componentId,
          queryParams:
            qParams.q && !isEmpty(qParams.q)
              ? {
                  ...qParams,
                  q: filters
                    ? filters + config.QUERY.AND + qParams.q
                    : qParams.q,
                }
              : qParams,
        });
      }
    }
    if (!loadData) setLoadData({ componentId });
  }

  componentDidUpdate(prevProps: ISmartTableListProps) {
    const {
      selectedRow,
      resetTableData,
      targetsId,
      qParams,
      params,
      data,
      setSelectedRow,
      setChildSelectedRow,
      resetTableContent,
      routerKey,
      setBreadcrumbsChild,
      breadcrumbs,
      dashboardId,
      isM2M,
      isAdvancedSearch,
    } = this.props;
    const { q } = qParams;
    const { rowKey, prettierKey, isMainTable, componentId, tableType } = params;
    if (
      q === undefined &&
      isMainTable !== false &&
      prevProps.qParams.q !== undefined
    ) {
      resetTableContent({ componentId });
    } else if (q === prevProps.qParams.q && routerKey !== prevProps.routerKey) {
      setSelectedRow({
        componentId,
        selectedRow: {},
      });
    }

    if (prevProps.selectedRow !== selectedRow && isEmpty(selectedRow)) {
      if (!isEmpty(targetsId)) {
        targetsId.forEach((targetId) => {
          resetTableData({
            componentId: targetId.id,
          });
        });
      }
    }

    if (isMainTable !== false && !isM2M && !isAdvancedSearch) {
      if (
        isEmpty(selectedRow) &&
        !isEmpty(breadcrumbs[breadcrumbs.length - 1].child)
      ) {
        if (isEmpty(data)) {
          //delete child
          //redirect al path del padre
        } else {
          data.content.forEach((row) => {
            if (
              row[rowKey].toString() ===
              breadcrumbs[breadcrumbs.length - 1].child.value.toString()
            ) {
              setSelectedRow({ componentId, selectedRow: row });

              if (!isEmpty(targetsId)) {
                this.updateTargetTables({
                  targetsId,
                  selectedRow,
                  record: row,
                  rowKey,
                });
                setChildSelectedRow({
                  targetsId,
                  record: row,
                  prettierKey,
                  rowKey,
                });
              }
            }
          });
        }
      }
      //SET BREADCRUMBS child
      if (
        tableType === 0 &&
        prevProps.selectedRow !== selectedRow &&
        prevProps.selectedRow !== undefined
      )
        setBreadcrumbsChild({
          selectedRow,
          prettierKey,
          rowKey,
          dashboardId,
        });
    }
  }

  // onCellChange = (key, key) => value => {
  //   this.props.updateTableRecord({ id: key, field: key, value });
  // };

  onSelectRow = (record: IRow) => {
    const {
      targetsId,
      params,
      selectedRow,
      setSelectedRow,
      setChildSelectedRow,
      isM2M,
      isAdvancedSearch,
      dashboardId,
      setBreadcrumbsChild,
    } = this.props;
    const { componentId, rowKey, prettierKey } = params;

    if (!isM2M && !params.disableRowClick) {
      setSelectedRow({
        componentId,
        selectedRow: record[rowKey] === selectedRow[rowKey] ? {} : record,
      });
      if (!isAdvancedSearch) {
        setBreadcrumbsChild({
          selectedRow: record[rowKey] === selectedRow[rowKey] ? {} : record,
          prettierKey,
          rowKey,
          dashboardId,
        });
        if (!isEmpty(targetsId)) {
          this.updateTargetTables({ targetsId, selectedRow, record, rowKey });
          setChildSelectedRow({
            targetsId,
            record: record[rowKey] === selectedRow[rowKey] ? {} : record,
            prettierKey,
            rowKey,
          });
        }
      }
    }
  };

  /**
   * Updates the data of the child tables with the id of the selectedRow
   * @param  {Object[]} targetsId - array of the child tables id
   * @param {Object} selectedRow - data of the selected row in main table
   * @param {Object} record - the clicked row of the main table that will be selected
   * @param {String} rowKey - the key of the rows of the main table
   */
  updateTargetTables = async ({
    targetsId,
    record,
    rowKey,
    selectedRow,
  }: {
    targetsId: target[];
    record: IRow;
    rowKey: string;
    selectedRow: IRow;
  }) => {
    const { getTableData, resetTableData, selectedComponent } = this.props;
    targetsId.forEach(async (targetId) => {
      if (appComponents[targetId.id].type === 'table') {
        if (
          selectedComponent === targetId.id &&
          (!selectedRow || record[rowKey] !== selectedRow[rowKey])
        ) {
          let navigationExtensions = [] as { [key: string]: string }[];
          const secundaryComponents = this.props.components;
          secundaryComponents.forEach((component) => {
            if (
              component.params.componentId === targetId.id &&
              component.params.hasOwnProperty('navigationExtensions')
            ) {
              if (isTable(component))
                navigationExtensions = component.params.navigationExtensions!;
            }
          });

          let queryParams = { q: '' };

          if (!isEmpty(navigationExtensions))
            navigationExtensions!.forEach((element) => {
              if (element.hasOwnProperty('value'))
                queryParams.q += `${element.key}${config.QUERY.ID_OPERATOR}${element.value},`;
              else
                queryParams.q += `${element.key}${config.QUERY.ID_OPERATOR}${
                  record[element.key]
                },`;
            });

          queryParams.q += `${targetId.path}${config.QUERY.ID_OPERATOR}${record[rowKey]}`;

          await getTableData({
            dataPath: appComponents[targetId.id].path,
            componentId: targetId.id,
            queryParams,
            foreignFilters: 'status::true',
          });
        } else {
          resetTableData({
            componentId: targetId.id,
          });
        }
      }
    });
  };

  onChangeMultipleRows = (
    selectedRowKeys: number[] | string[],
    selectedRows: IRecord[],
  ) => {
    const { setSelectedRows, params } = this.props;
    const { componentId } = params;
    setSelectedRows({ componentId, selectedRowKeys });
  };

  handleSetM2MSwitch = async (checked: boolean) => {
    const {
      primaryKey,
      getM2MTableData,
      mainKeyValue,
      foreignKey,
      path,
      dataPath,
      joinKey,
      setGetSelected,
      navigationId,
    } = this.props;

    await getM2MTableData({
      m2mDataPath: this.getM2MDataPath(path!),
      dataPath: this.getM2MDataPath(dataPath),
      queryParams: {},
      primaryKey: primaryKey!,
      joinKey: joinKey!,
      mainKeyValue,
      foreignKey,
      getSelected: checked,
      navigationId,
    });
    setGetSelected({ getSelected: checked });
  };

  onSelectMultipleRows = async (
    record: IRecord,
    selected: boolean,
    selectedRows: Object[],
    nativeEvent: Event,
  ) => {
    const {
      isM2M,
      addM2Mrecord,
      removeM2Mrecord,
      primaryKey,
      getM2MTableData,
      mainKeyValue,
      foreignKey,
      joinForeignKey,
      joinKey,
      path,
      dataPath,
      getSelected,
      navigationId,
    } = this.props;
    if (!isM2M) return;

    if (selected) {
      await addM2Mrecord({
        dataPath: this.getM2MDataPath(path!),
        foreignKey,
        record,
        mainKeyValue,
        joinForeignKey,
        joinKey: joinKey!,
      });
      await getM2MTableData({
        m2mDataPath: this.getM2MDataPath(path!),
        dataPath: this.getM2MDataPath(dataPath),
        queryParams: {},
        primaryKey: primaryKey!,
        mainKeyValue,
        joinKey: joinKey!,
        foreignKey,
        getSelected,
        navigationId,
      });
    } else {
      await removeM2Mrecord({
        dataPath: this.getM2MDataPath(path!),
        record,
        m2mPrimaryKey: primaryKey!,
      });
      await getM2MTableData({
        m2mDataPath: this.getM2MDataPath(path!),
        dataPath: this.getM2MDataPath(dataPath),
        queryParams: {},
        primaryKey: primaryKey!,
        mainKeyValue,
        joinKey: joinKey!,
        foreignKey,
        getSelected,
        navigationId,
      });
    }
  };

  onSelectAllRows = (
    selected: boolean,
    selectedRows: IRecord[],
    changeRows: IRecord[],
  ) => {
    const { isM2M, params, setSelectedRows } = this.props;
    if (!selected && !isM2M) {
      //todo select all rows
      const { componentId } = params;
      setSelectedRows({ componentId, selectedRowKeys: [] });
    }
  };

  handleTableChange = async (
    pagination: PaginationConfig,
    filters: { [key: string]: string[] },
    sorter: SorterResult<IRecord>,
    extra: any,
  ) => {
    const {
      isM2M,
      params,
      getTableData,
      columnsConfig,
      setColumnsConfig,
      joinKey,
      foreignKey,
      mainKeyValue,
      primaryKey,
      getM2MTableData,
      getSelected,
      navigationId,
      path,
      dataPath,
    } = this.props;
    const { componentId } = params;
    const page = isEmpty(pagination) ? undefined : pagination.current! - 1;
    const size = isEmpty(pagination) ? undefined : pagination.pageSize!;
    let sort;
    if (!isEmpty(sorter)) {
      if (sorter.order === 'ascend') sort = 'ASC';
      else if (sorter.order === 'descend') sort = 'DESC';
      else sort = sorter.order;
    } else sort = undefined;

    const field = isEmpty(sorter) ? undefined : sorter.columnKey;
    let newColumnsConfig = columnsConfig.slice();
    newColumnsConfig.forEach((col) => {
      if (col.hasOwnProperty('defaultSortOrder') && col.key !== field)
        delete col['defaultSortOrder'];
      if (col.key === field) col.defaultSortOrder = sorter.order;
    });
    setColumnsConfig({ componentId, columns: newColumnsConfig });
    const queryParams: QueryParams = {
      page,
      size,
      sort,
      field,
    };

    isM2M
      ? await getM2MTableData({
          m2mDataPath: this.getM2MDataPath(path!),
          dataPath: this.getM2MDataPath(dataPath),
          queryParams,
          primaryKey: primaryKey!,
          mainKeyValue,
          joinKey: joinKey!,
          foreignKey,
          getSelected,
          navigationId,
        })
      : await getTableData({
          dataPath: appComponents[componentId].path,
          componentId,
          queryParams,
        });
  };

  handleReloadData = async () => {
    const {
      isM2M,
      params: { componentId },
      getTableData,
      getM2MTableData,
      dataPath,
      path,
    } = this.props;

    const m2mProps = {
      m2mDataPath: this.getM2MDataPath(path!),
      dataPath: this.getM2MDataPath(dataPath),
      queryParams: {},
      primaryKey: this.props.primaryKey!,
      mainKeyValue: this.props.mainKeyValue,
      joinKey: this.props.joinKey!,
      foreignKey: this.props.foreignKey,
      getSelected: this.props.getSelected,
      navigationId: this.props.navigationId,
    };

    // const foreignFilters = isMainTable ? undefined : 'status::true';
    isM2M
      ? await getM2MTableData({
          ...m2mProps,
        })
      : await getTableData({
          dataPath: appComponents[componentId].path,
          componentId,
          foreignFilters: undefined,
        });
  };

  getM2MDataPath = (path: string): string => {
    const { pathExtended, pathExtendedData } = this.props;

    const _key = pathExtendedData?.key ?? '';
    const _value = pathExtendedData?.value ?? '';

    const paths = {
      default: path!,
      extended: pathExtended?.replace(`{{${_key}}}`, _value.toString())!,
    };

    return pathExtendedData ? paths.extended : paths.default;
  };

  handleAddData = () => {
    const { params, setSelectedRow } = this.props;
    const { componentId } = params;
    setSelectedRow({
      componentId,
      selectedRow: {},
    });
  };

  handleReloadCombo = () => {
    const editComponent = this.props.components.find(
      (component) => component.params.type === 'edit',
    );
    const editFields = editComponent?.fields as IEditField[];

    const combosToReload: IEditField[] = editFields?.filter(
      (field: IEditField) => field.type === 'combo' && field.reloadComponentIds,
    );

    combosToReload?.map(async ({ comboId, key, reloadComponentIds }) => {
      if (comboId && reloadComponentIds) {
        for (const id of reloadComponentIds) {
          const comboDataParameters = {
            dataPath: 'combodata',
            componentId: id,
            fieldKey: key,
            queryParams: { id: comboId },
            comboId,
          };
          await this.props.getComboData(comboDataParameters);
        }
      }
    });
  };

  handleDeleteData = async () => {
    const { params, selectedRow, deleteTableData, setSelectedRow } = this.props;
    const { rowKey, componentId } = params;

    feedback({
      type: 'message',
      method: 'loading',
      message: 'generic.loading',
    });

    const result: any = await deleteTableData({
      dataPath: appComponents[componentId].path,
      componentId,
      selectedRowKeys: [selectedRow[rowKey]],
      primaryKey: rowKey,
      primaryKeyValue: selectedRow[rowKey],
    });

    this.handleReloadCombo();

    if ('status' in result && result['status'] === 200)
      setSelectedRow({
        componentId,
        selectedRow: {},
      });
  };

  handleChangeDrawerVisibility = async (visible: boolean) => {
    const { dashboardId, setDrawerVisibility } = this.props;
    await setDrawerVisibility({ dashboardId, visible });
    return true;
  };

  handleOpenFile = async ({
    fkFolder,
    docName,
  }: {
    fkFolder: string;
    docName: string;
  }) => {
    let format;
    //TODO check callConfig params -> NestJS api params
    const callConfig = { params: { fkFolder, docName } };

    const response = await api.getDataCall({ dataPath: '/docs', callConfig });

    if (response.status === 200) {
      if (docName.indexOf('.') >= 0) {
        format = docName.split('.')[1];

        let mimeType;
        switch (format) {
          case 'pdf':
            mimeType = 'application/';
            break;
          case 'jpg':
          case 'jpeg:':
          case 'png':
            mimeType = 'image/';
            break;
          default:
            mimeType = 'application/';
            break;
        }
        if (mimeType) mimeType += format.toString();

        const link = document.createElement('a');
        link.setAttribute(
          'href',
          `data:${mimeType};base64,${response.data.file}`,
        );
        link.setAttribute('download', docName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  showDeleteConfirm = () => {
    const { selectedRowKeys, intl } = this.props;

    const selecteds: string[] = [];
    selectedRowKeys.forEach((element: string | number) => {
      selecteds.push(`id: ${element}`);
    });

    Modal.confirm({
      title: intl.formatMessage({ id: 'pop.title.deleteMult' }),
      content: selecteds,
      okText: intl.formatMessage({ id: 'pop.accept' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk: () => {
        this.handleDeleteData();
      },
      onCancel() {},
    });
  };

  moveColumn = (dragIndex: number, hoverIndex: number) => {
    let { columnsConfig, params, setColumnsConfig } = this.props;
    const { componentId } = params;
    const temp = columnsConfig[dragIndex];
    columnsConfig.splice(dragIndex, 1);
    columnsConfig.splice(hoverIndex, 0, temp);
    columnsConfig = clone(columnsConfig);
    columnsConfig.forEach((c, i) => {
      c.position = i;
    });
    setColumnsConfig({
      componentId,
      columns: columnsConfig,
    });
  };

  onDragEnd = (fromIndex: number, toIndex: number) => {
    const { columnsConfig, params, setColumnsConfig } = this.props;
    const { componentId } = params;
    const columnsCopy = columnsConfig.slice();
    const item = columnsCopy.splice(fromIndex, 1)[0];
    columnsCopy.splice(toIndex, 0, item);
    setColumnsConfig({
      componentId,
      columns: columnsCopy,
    });
  };

  handleResize =
    (index: number) =>
    (e: any, { size }: { size: IRow }) => {
      const { columnsConfig, params, setColumnsConfig } = this.props;
      const { componentId } = params;
      const nextColumns = [...columnsConfig];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      setColumnsConfig({
        componentId,
        columns: nextColumns,
      });
    };

  onChangeColumnVisibility = (e: { key: string }) => {
    const { params, columnsConfig, setColumnsConfig } = this.props;
    const { componentId } = params;
    let columnsCopy = columnsConfig.map((c) => {
      if (e.key === c.key) {
        c.visible = !c.visible;
      }
      return c;
    });
    setColumnsConfig({
      componentId,
      columns: columnsCopy,
    });
  };

  tableTotalWidth = (columns: Column[]) => {
    let totalWidth = 0;
    columns.forEach((column: Column) => {
      if (column.visible && column.hasOwnProperty('width'))
        totalWidth += column.width!;
    });
    return totalWidth;
  };

  render() {
    const {
      data,
      selectedRowKeys,
      isM2M,
      m2mData,
      m2mIsLoading,
      m2mSelectedRowKeys,
    } = this.props;
    let { isLoading } = this.props;

    let rowSelection = {
      selectedRowKeys: selectedRowKeys || [],
      onChange: this.onChangeMultipleRows,
      onSelectAll: this.onSelectAllRows,
      onSelect: this.onSelectMultipleRows,
    };

    let dataSource;

    if (isM2M) {
      dataSource = m2mData;
      rowSelection.selectedRowKeys = m2mSelectedRowKeys;
      isLoading = m2mIsLoading;
    } else {
      dataSource = data;
    }

    if (dataSource) {
      const renderProps = {
        dataSource: dataSource,
        isLoading: isLoading,
        ...this,
      };
      return <SmartTableRender {...renderProps} />;
    } else {
      return null;
    }
  }
}

const mapDispatchToProp = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setInitialState,
      setColumnsConfig,
      getTableData,
      deleteTableData,
      setSelectedRows,
      setFormStateFlag,
      feedback: feedbackController,
      resetTableData,
      displayImageModal,
      loadImageModal,
      logout,
      setDrawerVisibility,
      navigate,
      setChildSelectedRow,
      setLoadData,
      setInitialTableParams,
      resetTableContent,
      returnToParent,
      setFormData,
      setBreadcrumbsChild,
    },
    dispatch,
  );

const mapStateToProps = (state: ReducersState, ownProps: OwnProps) => {
  return {
    targetsId: state.tables[ownProps.params.componentId].targetsId,
    loadData: state.tables[ownProps.params.componentId].loadData,
    columnsConfig: state.tables[ownProps.params.componentId].columnsConfig,
    filters: state.tables[ownProps.params.componentId].filters,
    data: state.tables[ownProps.params.componentId].data,
    m2mData: state.m2m.data,
    m2mQueryParams: state.m2m.queryParams,
    m2mSelectedRowKeys: state.m2m.selectedRecordsId,
    m2mIsLoading: state.m2m.isLoading,
    getSelected: state.m2m.getSelected,
    selectedRowKeys: state.tables[ownProps.params.componentId].selectedRowKeys,
    selectedRow: state.tables[ownProps.params.componentId].selectedRow,
    parentSelectedRow:
      state.tables[ownProps.params.componentId].parentSelectedRow,
    parentPrettierKey:
      state.tables[ownProps.params.componentId].parentPrettierKey,
    parentRowKey: state.tables[ownProps.params.componentId].parentRowKey,
    formHasChanged: state.tables[ownProps.params.componentId].formHasChanged,
    isLoading: state.tables[ownProps.params.componentId].isLoading,
    queryParams: state.tables[ownProps.params.componentId].queryParams,
    // visible: state.tables.imageModal.visible,
    layoutType: state.dashboards[ownProps.dashboardId].layoutType,
    // imageIsLoading: state.tables.imageModal.isLoading,
    combos: state.combos,
    token: state.auth.accessToken,
    dashboards: state.dashboards,
    qParams: state.query.params,
    breadcrumbs: state.query.breadcrumbs,
    currentPath: state.router.location.pathname,
    selectedComponent: state.dashboards[ownProps.dashboardId].selectedComponent,
    routerKey: state.router.location.key,
    pathExtendedData: state.m2m.pathExtended!,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProp,
)(injectIntl(SmartTable));
