import React, { FC } from 'react';
import { Row, Col, Select } from 'antd';
import { ComboData } from '../../combos/ComboInterfaces';

interface Props {
  center: { [key: string]: any };
  centerCombo: ComboData[];
  rolesCombo: ComboData[];
  handleEditValues: ({ id, value }: { id: string; value: any }) => void;
}

const AddCentersForm: FC<Props> = ({
  center,
  centerCombo,
  rolesCombo,
  handleEditValues
}) => {
  const centerValue: any = center.idCenter
    ? center.idCenter.idCenter.toString()
    : undefined;
  const roleValue: any = center.idRole
    ? center.idRole.idRole.toString()
    : undefined;
  const statusValue: any =
    center.idUserCenter === undefined
      ? 'true'
      : center.status !== undefined && center.status !== null
      ? center.status.toString()
      : 'true';

  const renderCenters = centerCombo.map(_center => (
    <Select.Option
      key={_center.value.toString()}
      value={_center.value.toString()}>
      {_center.description}
    </Select.Option>
  ));

  const renderRoles = rolesCombo.map(_role => (
    <Select.Option key={_role.value.toString()} value={_role.value.toString()}>
      {_role.description}
    </Select.Option>
  ));

  return (
    <Row type="flex" gutter={[24, 0]}>
      <Col md={24} lg={12}>
        <Row className="assignCenter__select--title">
          <div style={{ display: 'block' }}>Center</div>
          <Select
            className="assignCenter__select--input"
            value={centerValue}
            onChange={(value: string) =>
              handleEditValues({ id: 'idCenter', value })
            }
            placeholder="Select center"
            allowClear>
            {renderCenters}
          </Select>
        </Row>
      </Col>
      <Col md={24} lg={6}>
        <Row className="assignCenter__select--title">
          <div style={{ display: 'block' }}>Role</div>
          <Select
            className="assignCenter__select--input"
            value={roleValue}
            onChange={(value: string) =>
              handleEditValues({ id: 'idRole', value })
            }
            placeholder="Select role"
            allowClear>
            {renderRoles}
          </Select>
        </Row>
      </Col>
      <Col md={24} lg={6}>
        <Row className="assignCenter__select--title">
          <div style={{ display: 'block' }}>Status</div>
          <Select
            className="assignCenter__select--input"
            value={statusValue}
            onChange={(value: string) =>
              handleEditValues({ id: 'status', value })
            }
            disabled={center.status === undefined}>
            <Select.Option key={'true'} value={'true'}>
              Active
            </Select.Option>
            <Select.Option key={'false'} value={'false'}>
              Deleted
            </Select.Option>
          </Select>
        </Row>
      </Col>
    </Row>
  );
};

export default AddCentersForm;
