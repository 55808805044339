import { Button, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isPINPlatform } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

export default function ResetPasswordButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const handleResetPassword = async () => {
    try {
      let resetRequest = async () => {
        const encodedEmail = encodeURIComponent(selectedRow.email);
        return api.getDataCall({
          dataPath: `${apiPaths.CALL.RESET_PASSWORD_FROM_BO}?email=${encodedEmail}`,
          callConfig: {},
        });
      };

      if (isPINPlatform()) {
        resetRequest = async () =>
          api.postDataCall({
            dataPath: apiPaths.CALL.RESET_PASSWORD_FROM_BO_PIN,
            data: {
              email: selectedRow.email,
            },
          });
      }

      const response = await resetRequest();

      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'users.reset-password.notification.title',
          }),
          description: formatMessage({
            id: 'users.reset-password.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      await tableProps.handleReloadData();
      setShowConfirmModal(false);
    }
  };

  const buttonsDisabled = isEmpty(selectedRow);

  return (
    <>
      <Button
        onClick={() => setShowConfirmModal(true)}
        disabled={buttonsDisabled}
        type="primary">
        {formatMessage({ id: 'users.reset-password' })}
      </Button>
      <Modal
        visible={showConfirmModal}
        closable={false}
        centered
        onOk={handleResetPassword}
        onCancel={() => setShowConfirmModal(false)}
        className="modalConfirm">
        <FormattedMessage
          id="update-password.modal-text"
          values={{
            user: selectedRow.email,
            strong: (chunks: string) => <strong>{chunks}</strong>,
          }}
        />
      </Modal>
    </>
  );
}
