import React from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Icon,
  Dropdown,
  Input,
  Divider,
  Badge,
} from 'antd';
import { isEmpty } from 'lodash';
import customDashboards from '../../extensions/customDashboards';

import formRender from '../formRender';
import { checkBehaviour } from '../behaviourUtils';

import { buttonsContainerBottom } from './searchFormButtons';
import customButtonsFile from '../../extensions/customButtons';
import { getModifiedFieldsNumber } from '../formConstructor';
import '../FormStyles.css';
import { ISearchFormListProps } from './SearchForm';
import {
  IGroupField,
  ICompleteField,
  IButtonPermission,
} from '../formInterfaces';
import { GlobalSearch } from './searchReducer';
import { IRow, IRecord } from '../../app/AppInterfaces';
import config from '../../config';

const ButtonGroup = Button.Group;

const checkButtonPermissions = (
  button: IButtonPermission,
  userPermissions: string[],
) => {
  let disabled;
  userPermissions.forEach((permission) => {
    button.permissions.forEach((perm) => {
      if (permission === perm.name) {
        disabled = perm.disabled;
      }
    });
  });
  return disabled;
};

const checkButtonBehaviours = (button: IButtonPermission, values: IRow) => {
  let disabled;
  button.behaviours!.forEach((behaviour) => {
    if (checkBehaviour(behaviour, values)) disabled = !button.defaultDisabled;
  });
  if (disabled === undefined) return button.defaultDisabled;
  else return disabled;
};

const checkDisabledButton = (
  values: IRow,
  userPermissions: string[],
  button?: IButtonPermission | boolean,
) => {
  let disabled;

  if (typeof button === 'object') {
    button.permissions
      ? (disabled = checkButtonPermissions(button, userPermissions))
      : (disabled = checkButtonBehaviours(button, values));
    if (disabled === undefined) disabled = button.defaultDisabled;
  } else if (button === undefined) {
    return false;
  } else disabled = !button;

  return disabled;
};

const dropDownButton = (componentProps: SearchFormRenderProps) => {
  const count = getModifiedFieldsNumber(
    componentProps.props.fields,
    componentProps.props.values,
  );
  return (
    <Dropdown
      overlay={dropDownFields(componentProps)}
      trigger={['click']}
      placement="bottomCenter"
      overlayClassName="formDropdown"
      visible={componentProps.props.filterVisible}>
      {isEmpty(componentProps.props.fields) ? null : (
        <Badge
          count={count}
          title={`Se han aplicado ${count} filtros de búsqueda`}
          style={{ backgroundColor: '#faad14', zIndex: 999 }}>
          <Button
            size="default"
            onClick={() => componentProps.handleChangeFilterVisibility()}>
            {window.innerWidth > config.BREAKPOINTS.MD &&
              componentProps.props.intl.formatMessage({ id: 'form.filter' })}
            <Icon type="sliders" />
          </Button>
        </Badge>
      )}
    </Dropdown>
  );
};

const dropDownFields = (componentProps: SearchFormRenderProps) => {
  const { customButtons } = componentProps.props.params;
  return (
    <>
      <Row>
        {customButtons
          ? customButtons.map((customButton) =>
              customButtonsFile[customButton](componentProps),
            )
          : null}
      </Row>
      {formRender(componentProps)}
      <Divider className="form__divider" />

      {buttonsContainerBottom ? buttonsContainerBottom(componentProps) : null}
    </>
  );
};

const cleanButton = (componentProps: SearchFormRenderProps) => {
  const { handleReset } = componentProps;
  return (
    <Button
      title={componentProps.props.intl.formatMessage({
        id: 'form.clear',
      })}
      onClick={handleReset}>
      <Icon type="highlight" />
    </Button>
  );
};

const addNewRecordButton = (componentProps: SearchFormRenderProps) => {
  const { values, userPermissions, options } = componentProps.props;
  const { canCreate } = options;
  const addDisabled = checkDisabledButton(values, userPermissions!, canCreate);

  return (
    addDisabled !== undefined && (
      <Button
        icon="file-add"
        disabled={addDisabled}
        type="primary"
        style={{ float: 'right' }}
        onClick={componentProps.handleAddData}
        title={componentProps.props.intl.formatMessage({ id: 'form.new' })}>
        {window.innerWidth > config.BREAKPOINTS.MD &&
          componentProps.props.intl.formatMessage({ id: 'form.new' })}
      </Button>
    )
  );
};

export default function searchFormRender(parentProps: SearchFormRenderProps) {
  const componentProps =
    parentProps.props.customDashboard &&
    customDashboards[parentProps.props.customDashboard] &&
    customDashboards[parentProps.props.customDashboard].search !== undefined
      ? customDashboards[parentProps.props.customDashboard].search!(parentProps)
      : { ...parentProps };

  const { handleChangeField, globalSearchField, handleSubmit } = componentProps;

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <Row className="form__front">
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={6}
          xxl={6}
          key={globalSearchField.type}>
          <Form.Item>
            {componentProps.props.form.getFieldDecorator(
              globalSearchField.key,
              {
                initialValue:
                  componentProps.props.values[globalSearchField.key],
              },
            )(
              <Input.Search
                type={globalSearchField.type}
                title="Text search"
                style={{ verticalAlign: 'top' }}
                placeholder={globalSearchField.title}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeField({
                    type: globalSearchField.type,
                    id: globalSearchField.key,
                    value: e.target.value,
                  })
                }
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  // This is to relaunch an api call after clearing the search (the "x" button on the search field)
                  // In newer versions of Ant Design, this approach can be improved by either passing a custom component to the allowClear prop or by adding a custom function to the onClear prop.
                  if (e.target.value !== '') return;

                  await handleChangeField({
                    type: globalSearchField.type,
                    id: globalSearchField.key,
                    value: e.target.value,
                  });
                  handleSubmit(e);
                }}
                enterButton={
                  <Button
                    title={componentProps.props.intl.formatMessage({
                      id: 'form.search',
                    })}
                    icon="search"
                    htmlType="submit"
                  />
                }
                allowClear
              />,
            )}
          </Form.Item>
        </Col>
        {parentProps.props.groups.length ? (
          <ButtonGroup style={{ marginTop: '-1.5px' }}>
            {dropDownButton(componentProps)}
            {cleanButton(componentProps)}
          </ButtonGroup>
        ) : null}
        {(componentProps.props.layoutType === 0 ||
          componentProps.props.layoutType === 1) &&
          addNewRecordButton(componentProps)}
      </Row>
    </Form>
  );
}

export interface SearchFormRenderProps {
  props: ISearchFormListProps;
  globalSearchField: GlobalSearch;
  getData: (values: IRow) => void;
  setInitialValues: (fieldsConfig: IGroupField[]) => IRecord;
  handleSubmit: (e: React.FormEvent) => void;
  handleReset: () => void;
  handleChangeTab: (activeKey: string) => void;
  handleChangeField: ({
    type,
    id,
    value,
    format,
  }: {
    type: string;
    id: string;
    value: any;
    format?: string | undefined;
  }) => Promise<void>;
  handleAddData: () => void;
  handleChangeFilterVisibility: () => void;
  updateChildCombo: (comboId: string, id: string | number, key: string) => void;
  setParentValue: (key: string, value: any) => void;
  handleChangeFieldOperator: (f: ICompleteField, operand: string) => void;
  handleChildBehaviours: (
    targetKey: string,
    behavioursDisplayed: any,
    forceVisible?: boolean,
    forceDisabled?: boolean,
  ) => void;
  changeFieldsConfig: (
    fieldsConfig: IGroupField[],
    fieldsConfigBehaviourSelector: (field: ICompleteField) => ICompleteField,
  ) => IGroupField[];
}
