import React, { Fragment } from 'react';
import { InjectedIntlProps } from 'react-intl';
import Collapsible from './Components/Collapsible';
import ChallengeOptionsForm from './ChallengeOptionsForm';
import { ComboData } from '../combos/ComboInterfaces';
import { IRow } from '../app/AppInterfaces';

export interface IChallengeOptions {
  values: { [key: string]: any };
  intl: typeof InjectedIntlProps;
  editingResource: boolean;
  handleEditComponent: (component: string, value: boolean) => void;
  handleChangeField: ({
    id,
    value,
    type,
    multiSelectId,
  }: {
    id: string;
    value: any;
    type: string;
    multiSelectId?: string;
  }) => void;
  UploadImage: (key: string) => JSX.Element;
  UploadVideo: (key: string) => JSX.Element;
  formHasChanged: boolean;
  brandCombo: ComboData[];
  // FIXME: BORRAR: categories AHORA ES filters
  categoriesCombo: ComboData[];
  filtersCombo: ComboData[];
  filterTypeCombo: ComboData[];
  specialtyCombo: ComboData[];
  contentTypeCombo: ComboData[];
  roleTypeCombo: ComboData[];
  challengeTagCombo: ComboData[];
  getMultiSelectValues: ({
    id,
    multiSelectId,
    values,
    value,
  }: {
    id: string;
    multiSelectId: string;
    values: IRow;
    value: any;
  }) => {
    [x: string]: any;
  };
  isAnnualCampaign: boolean;
  isExperience: boolean;
  isExperienceProduct: boolean;
}

export default function ChallengeConfig(props: IChallengeOptions) {
  const {
    intl: { formatMessage },
    UploadImage,
    UploadVideo,
    isAnnualCampaign,
    isExperience,
    isExperienceProduct,
  } = props;

  return (
    <Collapsible
      title={formatMessage({ id: 'challenge.config' })}
      collapsible={false}
      defaultCollapsed={false}>
      <Fragment>
        <ChallengeOptionsForm {...props} />
        {UploadImage('coverImage')}
        {!isExperienceProduct
          ? (isAnnualCampaign || isExperience) &&
            UploadImage('coverImageMobile')
          : null}
        {!isExperience && UploadImage('endImage')}
        {!isExperience && isAnnualCampaign && UploadImage('endImageMobile')}
        {UploadVideo('coverVideo')}
      </Fragment>
    </Collapsible>
  );
}
