export enum MenuEnum {
  PRIVATE_MENU = '2',
  PUBLIC_FOOTER_PARENT = '3',
  PUBLIC_FOOTER_CHILD = '4',
  PRIVATE_FOOTER_PARENT = '5',
  PRIVATE_FOOTER_CHILD = '6',
}

export enum MenuWebeatEnum {
  PUBLIC_HEADER = 1,
  PUBLIC_HEADER_CHILD = 2,
  PRIVATE_HEADER = 3,
  PRIVATE_HEADER_CHILD = 4,
  FOOTER = 5,
  FOOTER_CHILD = 5,
  FOOTER_POLICY = 6,
}
