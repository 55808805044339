import { MEDIA_FILE, MEDIA_URL } from './constants';

export const productsHandler = (key: string, contentState: any) => {
  if (key === MEDIA_FILE) {
    contentState[MEDIA_FILE] = {
      ...contentState[MEDIA_FILE],
      checked: !contentState[MEDIA_FILE]?.checked,
    };
    contentState[MEDIA_URL] = {
      ...contentState[MEDIA_URL],
      url: '',
    };

    return contentState;
  }

  if (key === MEDIA_URL) {
    contentState[MEDIA_URL] = {
      ...contentState[MEDIA_URL],
      checked: !contentState[MEDIA_URL]?.checked,
    };
    contentState[MEDIA_FILE] = {
      ...contentState[MEDIA_FILE],
      file: null,
    };

    return contentState;
  }
};
