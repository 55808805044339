import React, { FC, Fragment } from 'react'
import { Row }from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import { IndexedDetail, IResource,} from '../../ChallengeInterfaces';
import { ReducersState } from '../../../reducers';
import { ResourceTypeDetailEnum } from '../../Enums/';
import RenderDetail from '../TreeResource/TreeDetails';
import { ComboData } from '../../../combos/ComboInterfaces';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { editRecursiveResource } from '../../challengeActions';
import { IRow } from '../../../app/AppInterfaces';

const {
    CHECKOUT_PRODUCT_EAN,
    CHECKOUT_PRODUCT_ORIGINAL_STOCK
} = ResourceTypeDetailEnum;

interface ICheckoutSupplementaryProduct {
    _resource: IResource;
    editResource: boolean;
    form: WrappedFormUtils<any>;
    handleDeleteImages: (image: string, idResourceD: number) => void;
    groupIndex: number;
    mainIdResource: number;
    values: IRow;
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ICheckoutSupplementaryProduct;

const CheckoutSupplementaryProduct: FC<Props> = ({
    _resource,
    accessToken,
    combos,
    editRecursiveResource,
    editResource,
    form,
    handleDeleteImages,
    groupIndex,
    mainIdResource,
    values
}) => {
    const { resourceDetailList } = _resource;

    const checkoutProductDetails: IndexedDetail[] = [];


    resourceDetailList?.forEach((detail, index) => {
        if (detail.status) checkoutProductDetails.push({ index, detail });
    });

    if (!checkoutProductDetails) return <Fragment />;

    return (
        <Row gutter={[24, 0]}>
            {checkoutProductDetails
                .sort(
                    ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
                        orderA - orderB,
                )
                .map(({ detail, index }) => {
                    const { idResourceTypeD } = detail.idResourceTypeD;

                    let combo: ComboData[] | undefined = undefined;

                    if (idResourceTypeD === CHECKOUT_PRODUCT_EAN && CHECKOUT_PRODUCT_ORIGINAL_STOCK) {
                        combo =
                            combos.challengechallengeEdit?.checkoutProductType
                                ?.checkoutProductType?.data ?? [];
                    }

                    return RenderDetail({
                        accessToken,
                        combo,
                        detail,
                        detailArrayPosition: index,
                        editResource,
                        editRecursiveResource,
                        form,
                        handleDeleteImages,
                        mainIdResource,
                        resource: _resource,
                        parentArrayPosition: groupIndex,
                        values
                    });
                })}
        </Row>
    );
};

const mapStateToProps = (state: ReducersState) => {
    return {
        accessToken: state.auth.accessToken,
        combos: state.combos.combos,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSupplementaryProduct);