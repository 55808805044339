import { Icon, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React from 'react';
import { FC } from 'react';

export interface ScanLandingLabelWithTooltipProps {
  label: string;
  tooltipTitle: string;
  tooltipPlacement?: TooltipPlacement;
  icon?: string;
}

const ScanLandingLabelWithTooltip: FC<ScanLandingLabelWithTooltipProps> = ({
  label,
  tooltipTitle,
  tooltipPlacement,
  icon,
}) => {
  return (
    <>
      {label}
      <span style={{ marginLeft: '8px' }}>
        <Tooltip title={tooltipTitle} placement={tooltipPlacement ?? 'right'}>
          <Icon
            style={{ color: '#000000a6', fontSize: '15px' }}
            type={icon ?? 'question-circle'}
          />
        </Tooltip>
      </span>
    </>
  );
};

export default ScanLandingLabelWithTooltip;
