import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ResourceDetailDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'resourceDetailresourceDetailSearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResource.idResource',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'idResourceD',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'idResourceTypeD.idResourceTypeD',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'optimisticLocking',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'value',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource.idResource',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'resource',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Id Resource D',
          key: 'idResourceD',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Id Resource Type D',
          key: 'idResourceTypeD.idResourceTypeD',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'resource_type_detail',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Status',
          key: 'status',

          type: 'checkselect',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Value',
          key: 'value',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceDetailresourceDetailList',
        rowKey: 'idResourceD',
        panelHeader: 'Resource Detail List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0
      },
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Resource D',
          key: 'idResourceD',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Resource Type D',
          key: 'idResourceTypeD',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Status',
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Value',
          key: 'value',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'resourceDetailresourceDetailEdit',
        panelHeader: 'Resource Detail Edit',
        primaryKey: 'idResourceD',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idResource.idResource',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'idResourceTypeD.idResourceTypeD',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'optimisticLocking',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'value',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: 'Id Resource',
          key: 'idResource.idResource',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'resource',
          path: 'resource',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Id Resource Type D',
          key: 'idResourceTypeD.idResourceTypeD',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'resource_type_detail',
          path: 'resourcetypedetail',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          type: 'number',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Status',
          key: 'status',

          type: 'checkSelect',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Value',
          key: 'value',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceDetailresourceDI18nList',
        rowKey: 'idReDI18n',
        panelHeader: 'Resource DI18n List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'ResourceDI18nview',
        navigationId: 'idParent.idResourceD',
        tabIndex: 8
      },
      fields: [
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Parent',
          key: 'idParent.value',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Re DI18n',
          key: 'idReDI18n',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Language',
          key: 'language',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Name',
          key: 'name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Status',
          key: 'status',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    }
  ];

  const componentId = 'ResourceDetailDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ResourceDetailDashboard;
