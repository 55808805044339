import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatQrProductDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatQrProductsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'productType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'productGroup',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idProduct',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'showGrid',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'qrNature',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.code' }),
          key: 'productCode',
          type: 'text',
          operand: 'like',
          globalSearch: true,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.and.size',
          }),
          key: 'productNameAndSize',
          type: 'text',
          operand: 'like',
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'productType',
          type: 'selector',
          selectorPath: 'qr-products/qr-types',
          comboId: 'productTypeCombo',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'productGroup',
          type: 'selector',
          selectorPath: 'qr-products/qr-groups',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idProduct',
          type: 'text',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.qrNature' }),
          key: 'qrNature',
          type: 'selector',
          selectorPath: 'qr-products/qr-nature',
          comboId: 'natureQRCombo',
          operand: 'equal',
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.grid.title',
          }),
          key: 'showGrid',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatQrProductsList',
        rowKey: 'idQrProduct',
        panelHeader: 'QR Product List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'productNameAndSize',
        customButtons: ['bulkEditLandingInfo', 'bulkEditQrProducts'],
      },
      fields: [
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product' }),
          key: 'idQrProduct',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.code' }),
          key: 'productCode',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.and.size',
          }),
          key: 'productNameAndSize',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.landing',
          }),
          key: 'productNameLanding',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idProduct',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.status' }),
          key: 'productStatus',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.bu' }),
          key: 'bu',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.points' }),
          key: 'points',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'productType',
          position: 10,

          type: 'selector',
          selectorPath: 'qr-products/qr-types',
          comboId: 'productTypeCombo',
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'productGroup',
          position: 11,
          type: 'selector',
          selectorPath: 'qr-products/qr-groups',
          comboId: 'productGroupCombo',
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.qrNature' }),
          key: 'qrNature',
          position: 12,

          type: 'selector',
          selectorPath: 'qr-products/qr-nature',

          comboId: 'natureQRCombo',
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.multi.client',
          }),
          key: 'multiClient',
          position: 13,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          render: 'check',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: false,
        multiple: true,
        canCreate: false,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatQrProductsEdit',
        panelHeader: 'QR Product Edit',
        primaryKey: 'idQrProduct',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'productCode',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'productNameAndSize',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 16,
                },
                {
                  key: 'productType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'idProduct',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'productStatus',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'qrNature',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'bu',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'productGroup',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'points',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'multiClient',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'qrStartDate',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'qrEndDate',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.code' }),
          key: 'productCode',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.and.size',
          }),
          key: 'productNameAndSize',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idProduct',
          type: 'number',
          mandatory: false,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.status' }),
          key: 'productStatus',
          type: 'comboCustom',
          comboId: 'productStatus',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.bu' }),
          key: 'bu',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.points' }),
          key: 'points',
          type: 'number',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'productType',
          type: 'selector',
          selectorPath: 'qr-products/qr-types',
          comboId: 'productTypeCombo',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.qrNature' }),
          key: 'qrNature',
          type: 'selector',
          selectorPath: 'qr-products/qr-nature',
          comboId: 'natureQRCombo',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'idQrProduct',
                value: '',
                operand: 'empty',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'qrNature',
                value: '9',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'qrNature',
                value: '10',
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'productGroup',
          type: 'selector',
          selectorPath: 'qr-products/qr-groups',
          comboId: 'productGroupCombo',
          mandatory: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.multi.client',
          }),
          key: 'multiClient',
          type: 'comboCustom',
          comboId: 'optional',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.qr.start.date',
          }),
          key: 'qrStartDate',
          type: 'datetime',
          comboId: 'optional',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.qr.end.date',
          }),
          key: 'qrEndDate',
          type: 'datetime',
          comboId: 'optional',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatQrProductDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatQrProductDashboard;
