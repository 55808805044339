import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useIntl } from 'react-intl';

interface IOrderDetail {
  id: number;
  brand: string;
  name: string;
  qty: number;
  image: string;
}

export const ShoppingCartOrderDetails = (orderDetails: any) => {
  const { formatMessage } = useIntl();
  const orderProducts: IOrderDetail[] = [];

  if (!orderDetails) return null;

  for (const orderDetail in orderDetails) {
    orderProducts.push(orderDetails[orderDetail]);
  }

  const productsTableColumns: ColumnProps<IOrderDetail>[] = [
    {
      dataIndex: 'image',
      title: '',
      width: '100px',
      render: (image: string) =>
        image && (
          <div>
            <img
              style={{ maxHeight: 80, maxWidth: 80 }}
              src={image}
              alt="request-product"
            />
          </div>
        ),
    },
    {
      dataIndex: 'brand',
      title: formatMessage({ id: 'request-order.group-name' }),
      width: '130px',
    },
    {
      dataIndex: 'name',
      title: formatMessage({ id: 'request-order.name' }),
      width: '300px',
    },
    {
      align: 'center',
      dataIndex: 'qty',
      title: formatMessage({ id: 'request-order.quantity' }),
      width: '60px',
    },
  ];

  return (
    <Table
      size="small"
      bordered={false}
      columns={productsTableColumns}
      rowKey={({ id }: IOrderDetail) => id?.toString()}
      dataSource={orderProducts}
      pagination={false}
    />
  );
};
