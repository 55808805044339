import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import { reducers } from './reducers';
import { loadState } from './utils/localStorage';

const persistedState = loadState();
export const history = createBrowserHistory();

const store = createStore(
  reducers(history),
  persistedState,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);

store.subscribe(throttle(() => {}, 1000));

export default store;
