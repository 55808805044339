import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatTagDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatTagSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  mustRender: true,
                  yPosition: 0,
                  xPosition: 0,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'tags.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'tags.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatTagList',
        rowKey: 'idTag',
        panelHeader: 'Tags List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'tag.id' }),
          key: 'idTag',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({ id: 'tag.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ASC',
        },
        {
          title: formatMessage({ id: 'tag.content' }),
          key: 'content',
          render: 'text',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'tag.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'number',
          precision: 0,
        },
        // {
        //   title: formatMessage({ id: 'tag.challengeType' }),
        //   key: 'challengeFilter',
        //   position: 3,
        //   mustRender: true,
        //   align: 'center',
        //   sorter: false,
        //   visible: true,
        //   width: SMALL,
        //   render: 'combo',
        //   comboId: 'challengeFilterType',
        // },
        {
          title: formatMessage({ id: 'tag.showMenu' }),
          key: 'showMenu',
          render: 'comboCustom',
          comboId: 'optional',
          position: 4,
          mustRender: true,
          align: 'center',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'tag.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatTagEdit',
        panelHeader: formatMessage({ id: 'tag.edit' }),
        primaryKey: 'idTag',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'order',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'challengeFilter',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'image',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'showMenu',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'content',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'tag.id' }),
          key: 'idTag',
          type: 'number',
        },
        {
          title: formatMessage({ id: 'tag.name' }),
          key: 'name',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: true,
          length: 50,
        },
        {
          title: formatMessage({ id: 'tag.order' }),
          key: 'order',
          type: 'number',
          precision: 0,
          disabled: false,
          mandatory: true,
          min: 0,
        },
        {
          title: formatMessage({ id: 'tag.image' }),
          key: 'image',
          type: 'selector',
          selectorPath: '/design-resources/illustration',
          comboId: 'tagImage',
          disabled: false,
          mandatory: true,
        },

        {
          title: formatMessage({ id: 'tag.showMenu' }),
          key: 'showMenu',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },

        {
          title: formatMessage({ id: 'tag.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'tag.challengeType' }),
          key: 'challengeFilter',
          type: 'selector',
          selectorPath: '/tag/challenge-types',
          comboId: 'challengeFilterType',
        },
        {
          title: formatMessage({ id: 'tag.content' }),
          key: 'content',
          type: 'textarea',
          validationType: 'text',
          initialValue: '',
          disabled: false,
          mandatory: false,
          length: 250,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatTagDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatTagDashboard;
