import React from 'react';
import { Form } from 'antd';

import formRender from '../formRender';
import { feedback } from '../../utils/feedback';
import { buttonsContainerBottom, buttonsContainerTop } from './editFormButtons';
import customDashboards from '../../extensions/customDashboards';

import { ICompleteField, IGroupField } from '../formInterfaces';
import { IEditField } from '../../fields/FieldsInterfaces';
import { IEditFormListProps } from './EditForm';
import { IRow } from '../../app/AppInterfaces';

export default function EditFormRender(parentProps: EditFormRenderProps) {
  const componentProps =
    parentProps.props.customDashboard &&
    customDashboards[parentProps.props.customDashboard] &&
    customDashboards[parentProps.props.customDashboard].edit !== undefined
      ? customDashboards[parentProps.props.customDashboard].edit!(parentProps)
      : { ...parentProps };

  return (
    <Form className="form" /*onSubmit={componentProps.handleCreateRecord}*/>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100vh - 163px)',
        }}>
        {buttonsContainerTop(componentProps)}
        {formRender(componentProps)}
      </div>
      {buttonsContainerBottom(componentProps)}
    </Form>
  );
}

export interface EditFormRenderProps {
  props: IEditFormListProps;
  setBehaviourInitialState: (field: ICompleteField) => ICompleteField;
  setFormInitialValues: (
    fields: IEditField[],
    userPermissions: string[],
  ) => IRow;
  handleChangeField: ({
    id,
    value,
    path,
    type,
    multiSelectId,
    deleteImage,
  }: {
    type: string;
    id: string;
    value: any;
    path?: string;
    multiSelectId?: string;
    deleteImage?: string;
  }) => void;
  handleUpdateRecord: (
    e?: React.MouseEvent,
    customButton?: { [key: string]: boolean },
    drawerOptions?: {
      setDrawerVisibility: Function;
      dashboardId: string;
      visible: boolean;
    },
  ) => void;
  handleCreateRecord: (
    e?: React.MouseEvent,
    customButton?: { [key: string]: boolean },
    select?: boolean,
    drawerOptions?: {
      setDrawerVisibility: Function;
      dashboardId: string;
      visible: boolean;
    },
  ) => void;
  handleChangeTab: (activeKey: string) => void;
  handleGroupMandatory: () => void;
  handleAddData: () => void;
  handleDeleteData: () => void;
  handleResetForm: () => void;
  handleCloseForm: () => void;
  handleRevertRecord: (e: React.MouseEvent) => void;
  openNotification: () => void;
  setParentValue: (key: string, value: any) => void;
  handleChildBehaviours: (
    targetKey: string,
    behavioursDisplayed: any,
    forceVisible?: boolean,
    forceDisabled?: boolean,
  ) => void;
  handleResourceError: () => typeof feedback;
  updateChildCombo: (comboId: string, id: string | number, key: string) => void;
  changeFieldsConfig: (
    fieldsConfig: IGroupField[],
    fieldsConfigBehaviourSelector: (field: ICompleteField) => ICompleteField,
  ) => IGroupField[];
}
