import { default as enUS_var } from './var/var_en_US.json';
import { default as enUS_static } from './static/en_US.json';
// import { default as esES_var } from './var/var_es_ES.json';
// import { default as esES_static } from './static/es_ES.json';

const varKeys = Array.from(
  new Set([
    ...Object.keys(enUS_var),
    // , ...Object.keys(esES_var)
  ]),
);
const staticKeys = Array.from(
  new Set([
    ...Object.keys(enUS_static),
    // ...Object.keys(esES_static)
  ]),
);

export const validate = (
  language: string,
  jsonToCompare: {},
  exampleSchema: string[],
) => {
  const arrayToCompare = Object.keys(jsonToCompare);

  const difference = exampleSchema
    .filter((labelFromSchema) => !arrayToCompare.includes(labelFromSchema))
    .concat(
      arrayToCompare.filter(
        (labelFromCompare) => !arrayToCompare.includes(labelFromCompare),
      ),
    );

  if (difference?.length) console.error(language, difference);
};

export const checkTranslationsLabels = async () => {
  validate('en-US var', enUS_var, varKeys);
  validate('en-US static', enUS_static, staticKeys);
  // validate('es-ES var', esES_var, varKeys);
  // validate('es-ES static', esES_static, staticKeys);
  return;
};
