import React, { Fragment, useEffect, useState } from 'react';
import apiPaths from '../apiPaths';
import { CustomDashboard } from '../isdinCustomDashboards';
import { ColumnProps } from 'antd/lib/table';
import { IRecord } from '../app/AppInterfaces';
import { Button } from 'antd';
import { UsersWithCatalogLimitExceptionsModal } from '../isdinCustomDashboards/UsersWithCatalogLimitExceptionsModal';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setSearchInitialState } from '../forms/search/searchActions';
import { setEditInitialState } from '../forms/edit/editActions';
import { setTableInitialState } from '../tables/tableActions';
import { ReducersState } from '../reducers';
import { connect } from 'react-redux';

const CatalogLimitsDashboard = ({setTableInitialState, ...props}: any) => {

  const { formatMessage } = props.intl;

  useEffect(() => {
    setTableInitialState({componentId: 'limitexceptionsusersList'});
  }, [setTableInitialState]);

  const columns: ColumnProps<IRecord>[] = [
    {
      title: formatMessage({ id: 'catalogLimit.period' }),
      key: 'period',
      dataIndex: 'period',
      defaultSortOrder: 'descend',
    },
    {
      title: formatMessage({ id: 'catalogLimit.maxSpecificProductByPeriod' }),
      key: 'maxSpecificProductByPeriod',
      dataIndex: 'maxSpecificProductByPeriod',
    },
    {
      title: formatMessage({ id: 'catalogLimit.maxTotalProductsByPeriod' }),
      key: 'maxTotalProductsByPeriod',
      dataIndex: 'maxTotalProductsByPeriod',
    },
    {
      title: formatMessage({ id: 'catalogLimit.notificationThreshold' }),
      key: 'notificationThreshold',
      dataIndex: 'notificationThreshold',
    },
    {
      title: formatMessage({ id: 'catalogLimit.validOrderTypes' }),
      key: 'validOrderTypes',
      dataIndex: 'validOrderTypes',
    },
  ]

  function DisplayUsers(
    selectedRow: IRecord,
    loading: boolean,
    reloadData: () => Promise<void>,
  ) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
      <Fragment key="user-info_button">
        <Button
          loading={loading}
          style={{ marginRight: '20px' }}
          onClick={() => {setModalVisible(true)}}
          type="primary">
          {formatMessage({ id: 'catalogLimit.exceptionUsers' })}
        </Button>
        <UsersWithCatalogLimitExceptionsModal
          {...{
            modalVisible,
            selectedRow,
            setModalVisible,
            reloadData,
            ...props
          }}
        />
      </Fragment>
    );
  }

  return (
    <>
      <CustomDashboard
        entityKey={'content'}
        intl={props.intl}
        path={apiPaths.CALL.CATALOG_LIMITS}
        columns={columns}
        rowKey="period"
        searchField="period"
        customButtons={[DisplayUsers]}
        hideSearch={true}
        hideTableArrows={true}
      />
    </>
  );
}



const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { setSearchInitialState, setEditInitialState, setTableInitialState },
    dispatch
  );

const mapStateToProps = (state: ReducersState) => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogLimitsDashboard);
