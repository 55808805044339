/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Tabs } from 'antd';

import { SubscriptionModalForm } from './components/subcription-modal-form';
import FirstPage from './components/first-page';
import ThankyouForm from './components/thankyou-form';
import { feedback } from '../../utils';
import { SubscriptionModal } from './interfaces/form-common.interfaces';
import Text from 'antd/lib/typography/Text';
import { Empty } from 'antd';
import * as api from '../../api';

export const WebeatSubscriptionModalEditForm = () => {
  const { tables } = useSelector((state: any) => state);

  const subscriptionModalId =
    tables['webeatSubscriptionModalList'].selectedRow.id;

  const [selectedTab, setSelectedTab] = useState('General configuration');
  const [subscriptionModalData, setSubscriptionModalData] =
    useState<SubscriptionModal>();

  const handleSelectTab = (key: string) => {
    setSelectedTab(key);
  };

  const fetchSubscriptionModalData = async () => {
    try {
      const res = await api.getDataCall({
        dataPath: '/subscription-modal',
        callConfig: {},
      });

      if (res.status !== 200) {
        throw new Error('Failed to fetch subscription modal data');
      }

      if (res.data && res.data.content && res.data.content.length === 0) {
        return feedback({
          type: 'notification',
          method: 'warning',
          message: 'No subscription modal data found',
          duration: 5,
        });
      }

      setSubscriptionModalData(
        res.data.content?.filter(
          (item: any) => item.id === subscriptionModalId,
        )[0],
      );
    } catch (error) {
      feedback({
        type: 'notification',
        method: 'error',
        message: 'An error occurred while fetching subscription modal data',
        duration: 5,
      });
    }
  };

  useEffect(() => {
    fetchSubscriptionModalData();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h1>Modal subscripción</h1>

      {!subscriptionModalData ? (
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<Text>No Data</Text>}
            />
          </Col>
        </Row>
      ) : (
        <Tabs activeKey={selectedTab} onChange={handleSelectTab}>
          <Tabs.TabPane
            tab={'General configuration'}
            key="General configuration">
            <SubscriptionModalForm
              subscriptionModalData={subscriptionModalData}
              setSubscriptionModalData={setSubscriptionModalData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'First page'} key="First page">
            <Row className="i18n__challenge--input">
              <FirstPage
                subscriptionModalData={subscriptionModalData}
                setSubscriptionModalData={setSubscriptionModalData}
              />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Thank you page'} key="Thank you page">
            <Row className="i18n__challenge--input">
              <ThankyouForm
                subscriptionModalData={subscriptionModalData}
                setSubscriptionModalData={setSubscriptionModalData}
              />
            </Row>
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};
