/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect, useState } from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { isTableProps } from '../../utils/propsUtils';
import { isEmpty } from 'lodash';
import { FormComponentProps } from 'antd/lib/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useIntl } from 'react-intl';
import { WrappedFormUtils } from 'antd/es/form/Form';
import * as api from '../../api';
import apiPaths from '../../apiPaths';

interface IPointsManagement {
  userId?: string | null;
  description: string;
  amount: string | number | null;
  actionType?: string;
  entityType?: any;
  entityId?: any;
  actionId?: string;
  status?: string;
}

const MANUAL_ENTITY_TYPE = {
  id_resource_type: '0',
  name: 'MANUAL',
};

const QR_ENTITY_TYPE = {
  id_resource_type: '1',
  name: 'QR',
};

export default function PointsManagementButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;

    const [modalVisible, setModalVisible] = useState(false);
    const [isFormConfirm, setFormConfirm] = useState(false);
    const [entityTypes, setEntityTypes] = useState<any>(null);
    const [entityTypeSelected, setEntityTypeSelected] = useState<any>(null);
    const [entityIds, setEntityIds] = useState<any>(null);
    const [entityIdSelected, setEntityIdSelected] = useState<any>(null);
    const [pointsData, setPointsData] =
      useState<IPointsManagement | null>(null);
    const [loading, setLoading] = useState(false);
    const { isLoading, selectedRow } = parentProps.props || {};
    const { email } = selectedRow || {};
    const intl = useIntl();

    const showActionId = entityTypeSelected === QR_ENTITY_TYPE.id_resource_type;
    const showEntityId = ![
      MANUAL_ENTITY_TYPE.id_resource_type,
      QR_ENTITY_TYPE.id_resource_type,
    ].includes(entityTypeSelected);

    const getComboData = (id: string, param?: string | number) => {
      return api
        .getCombo({ id, param })
        .then((response) => response.data)
        .catch((err) => console.error(err));
    };

    const getInitialData = () => ({
      userId: tableProps.props?.selectedRow?.uuidUser ?? null,
      description: '',
      amount: '',
      actionType: 'Manual',
      entityType: '',
      entityId: '',
      actionId: '/N',
      status: 'valid',
    });

    useEffect(() => {
      setPointsData(getInitialData());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow]);

    useEffect(() => {
      const clearData = () => {
        setFormConfirm(false);
        setEntityTypeSelected(null);
        setEntityIdSelected(null);
      };
      clearData();
    }, [modalVisible]);

    useEffect(() => {
      if (!entityTypes) {
        getComboData(apiPaths.COMBODATA.POINTS_ENTITY_TYPE).then((res) =>
          setEntityTypes([...res, MANUAL_ENTITY_TYPE, QR_ENTITY_TYPE]),
        );
      }
    }, [entityTypes, getComboData]);

    const handleModalClose = () => {
      setPointsData(getInitialData());
      setModalVisible(false);
    };

    const saveData = () => {
      setLoading(true);
      return api.postDataCall({
        dataPath: apiPaths.CALL.CUSTOMER_CONSUME_POINTS,
        data: pointsData,
        callConfig: {},
      });
    };

    const handleModalSubmit = () => {
      saveData()
        .then(() =>
          setTimeout(() => {
            handleModalClose();
          }, 600),
        )
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    };

    const setPointsFormData = (form: WrappedFormUtils) => {
      setFormConfirm(true);
      setPointsData({
        ...pointsData,
        amount: Number(form?.getFieldValue('amount')),
        description: form?.getFieldValue('description'),
        actionId: form?.getFieldValue('actionId') || '/N',
        entityType: entityTypes.filter(
          (entity: any) =>
            entity.id_resource_type.toString() === entityTypeSelected,
        )[0]?.name,
        entityId: entityIdSelected,
      });
    };

    const FormPoints: FC<FormComponentProps> = ({ form }) => {
      const { getFieldDecorator, validateFields } = form;

      const getInput = () =>
        isFormConfirm ? <Input disabled={true} /> : <Input />;

      const getSelect = (
        list: any,
        attributeId: any,
        attributeShow: string,
        disabled: boolean,
        onChange?: any,
      ) => (
        <Select
          style={{ width: '100%', height: '50px' }}
          onChange={onChange}
          disabled={disabled}>
          {list?.map((_obj: any) => (
            <Select.Option
              key={_obj[attributeId].toString()}
              value={_obj[attributeId].toString()}>
              {_obj[attributeShow]}
            </Select.Option>
          ))}
        </Select>
      );

      const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        validateFields(async (err: any) => {
          if (!err) {
            setPointsFormData(form);
          }
        });
      };

      const handleEntityTypeChange = (value: string) => {
        setEntityTypeSelected(value);
        if (value === MANUAL_ENTITY_TYPE.id_resource_type) {
          setEntityIdSelected('0');
          return setEntityIds([{ id_resource: '0', name: 'Manual' }]);
        }

        if (value === QR_ENTITY_TYPE.id_resource_type) {
          setEntityIdSelected('1');
          return setEntityIds([{ id_resource: '1', name: 'QR' }]);
        }

        setEntityIdSelected(null);
        return getComboData(apiPaths.COMBODATA.POINTS_ENTITY_ID, value).then(
          (res) => setEntityIds(res),
        );
      };

      const handleEntityIdChange = (value: string) => {
        setEntityIdSelected(value);
      };

      return pointsData ? (
        <>
          <label>
            {intl.formatMessage({ id: 'points.management.button.user' })}
          </label>
          <Input
            value={email?.toString() ?? ''}
            disabled={true}
            style={{ marginBottom: '20px' }}
          />
          <Form onSubmit={handleSubmit} aria-disabled={true}>
            <label>
              {intl.formatMessage({
                id: 'points.management.button.modal.input.entity-type',
              })}
            </label>
            <FormItem colon={false} hasFeedback>
              {getFieldDecorator('entityType', {
                initialValue: entityTypeSelected,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'points.management.button.modal.input.entity-type.msn',
                    }),
                  },
                ],
              })(
                getSelect(
                  entityTypes,
                  'id_resource_type',
                  'name',
                  isFormConfirm,
                  handleEntityTypeChange,
                ),
              )}
            </FormItem>
            {showEntityId && (
              <>
                <label>
                  {intl.formatMessage({
                    id: 'points.management.button.modal.input.entity-id',
                  })}
                </label>
                <FormItem colon={false} hasFeedback>
                  {getFieldDecorator('entityId', {
                    initialValue: entityIdSelected,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'points.management.button.modal.input.entity-id.msn',
                        }),
                      },
                    ],
                  })(
                    getSelect(
                      entityIds,
                      'id_resource',
                      'id_resource',
                      !!!entityTypeSelected || isFormConfirm,
                      handleEntityIdChange,
                    ),
                  )}
                </FormItem>
              </>
            )}
            {showActionId && (
              <>
                <label>
                  {intl.formatMessage({
                    id: 'points.management.button.modal.input.action-id',
                  })}
                </label>
                <FormItem colon={false} hasFeedback>
                  {getFieldDecorator('actionId', {
                    initialValue: pointsData.actionId,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'points.management.button.modal.input.action-id.msn',
                        }),
                      },
                    ],
                  })(getInput())}
                </FormItem>
              </>
            )}
            <label>
              {intl.formatMessage({
                id: 'points.management.button.modal.input.description',
              })}
            </label>
            <FormItem colon={false} hasFeedback>
              {getFieldDecorator('description', {
                initialValue: pointsData.description,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'points.management.button.modal.input.description.msn',
                    }),
                  },
                ],
              })(getInput())}
            </FormItem>
            <label>
              {intl.formatMessage({
                id: 'points.management.button.modal.input.points',
              })}
            </label>
            <FormItem colon={false} hasFeedback>
              {getFieldDecorator('amount', {
                initialValue: pointsData.amount,
                rules: [
                  {
                    pattern: RegExp('^-?\\d+\\.?\\d*$'),
                    required: true,
                    message: intl.formatMessage({
                      id: 'points.management.button.modal.input.points.msn',
                    }),
                  },
                ],
              })(getInput())}
            </FormItem>
            <Row style={{ marginTop: '10px' }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isFormConfirm}
                loading={isLoading}>
                {intl.formatMessage({
                  id: 'points.management.button.modal.form.validate',
                })}
              </Button>
            </Row>
          </Form>
        </>
      ) : (
        <></>
      );
    };

    const getConfirmationModule = () => {
      return (
        <Row style={{ marginBottom: '15px' }}>
          <h1>
            {intl.formatMessage({
              id: 'points.management.button.modal.form.confirm',
            })}
          </h1>
        </Row>
      );
    };

    const getModalFooter = () => {
      return (
        <Row>
          <Button
            type="danger"
            htmlType="submit"
            onClick={handleModalClose}
            loading={isLoading}>
            {intl.formatMessage({ id: 'form.edit.cancel' })}
          </Button>
          <Button
            type="primary"
            disabled={!isFormConfirm}
            onClick={handleModalSubmit}
            loading={isLoading}>
            {intl.formatMessage({
              id: 'points.management.button.modal.form.submit',
            })}
          </Button>
        </Row>
      );
    };

    const FormPointsWithProps = Form.create<FormComponentProps>()(FormPoints);

    return (
      <>
        <Button
          style={{ marginRight: '10px' }}
          onClick={() => setModalVisible(true)}
          type="primary"
          disabled={isEmpty(selectedRow)}
          loading={isLoading}>
          Points Management
        </Button>
        <Modal
          style={{ minHeight: '40vh' }}
          visible={modalVisible}
          onCancel={handleModalClose}
          closable={false}
          footer={getModalFooter()}>
          <Row style={{ marginBottom: '40px', fontSize: '1.6rem' }}>
            {intl.formatMessage({ id: 'points.management.button.title' })}
          </Row>
          <Row>
            {isFormConfirm && !loading && getConfirmationModule()}
            <FormPointsWithProps />
          </Row>
        </Modal>
      </>
    );
  }
  return <></>;
}
