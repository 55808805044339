import * as React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { ReducersState } from '../reducers';

import { goBackToRoute, returnToParent, navigate } from '../app/queryActions';
import { setDrawerVisibility } from '../dashboards/dashboardActions';

import { IBreadcrumb } from './SmartBreadcrumbInterfaces';
import { Breadcrumb, Icon } from 'antd';

import './SmartBreadcrumb.css';

type ISmartBreadcrumb = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;
/**
 * It's the Breadcrumb component, it will save the elements on the menu we are navigating
 * and will only print the name with a link to the page.
 */
export class SmartBreadcrumb extends React.PureComponent<ISmartBreadcrumb, {}> {
  render() {
    const { breadcrumbs, setDrawerVisibility } = this.props;
    let composedBreadcrumbs: JSX.Element[] = [];

    breadcrumbs.forEach((bc: IBreadcrumb, key: number) => {
      composedBreadcrumbs.push(
        <Breadcrumb.Item key={key}>
          <span
            className="breadcrumbs-link breadcrumbs-link__main-element"
            onClick={() => {
              this.props.goBackToRoute(bc.path);
            }}>
            {bc.name}
          </span>
        </Breadcrumb.Item>
      );

      if (!isEmpty(bc.child))
        composedBreadcrumbs.push(
          <Breadcrumb.Item key={bc.child.name}>
            <span
              className="breadcrumbs-link"
              onClick={() => {
                if (key < breadcrumbs.length - 1)
                  this.props.returnToParent({
                    breadcrumbs,
                    setDrawerVisibility,
                    key
                  });
              }}>
              {bc.child.name}
            </span>
          </Breadcrumb.Item>
        );
    });

    return (
      <div className="breadcrumb">
        <span className="breadcrumb__main--bold">
          {breadcrumbs[breadcrumbs.length - 1].name}
          <span className="breadcrumb__main--normal">|</span>
        </span>
        <span>
          <Icon
            type="home"
            className="breadcrumb__homeIcon"
            onClick={() => this.props.navigate({ path: '/' })}
          />
          <span style={{ padding: '0 6px 0 6px' }}>{'>'}</span>
        </span>
        <Breadcrumb className="breadcrumb__chain" separator=">">
          {composedBreadcrumbs}
        </Breadcrumb>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { goBackToRoute, setDrawerVisibility, returnToParent, navigate },
    dispatch
  );

const mapStateToProps = (state: ReducersState) => {
  const { breadcrumbs } = state.query;

  return { breadcrumbs };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartBreadcrumb);
