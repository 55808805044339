import { Avatar, Dropdown, Icon, Layout, Menu } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setCurrentPlatform } from '../app/appActions';
import { logout } from '../auth/authActions';
import config from '../config';
import { ReducersState } from '../reducers';
import { feedback, M_CONFIRM, T_MODAL } from '../utils/feedback';
import { renderPlatformSelector } from '../welcome/Welcome';
import './HeaderContent.css';

interface OwnProp {
  menuState: number;
}

type THeaderContentListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OwnProp;

class HeaderContent extends React.PureComponent<THeaderContentListProps, {}> {
  handleLogout = () => {
    const { logout } = this.props;
    const modalProps = {
      onOk: logout,
    };
    feedback({
      type: T_MODAL,
      title: 'logout.confirm.title',
      method: M_CONFIRM,
      modalProps,
    });
  };

  render() {
    const { currentPlatform, platforms, setCurrentPlatform, userData, currentPlatformCode } =
      this.props;
    const userMenuOptions = (
      <Menu>
        <Menu.Item onClick={this.handleLogout}>
          <Icon type="logout" />
          <span>
            <FormattedMessage id="logout.title" />
          </span>
        </Menu.Item>
      </Menu>
    );

    const firstLetterName = userData?.name
      ? userData.name[0].toUpperCase()
      : 'U';
    const firstLetterSurname =
      userData?.surname && userData.surname[0].toUpperCase();

    const platformSelector = () => {
      const extraAction: () => void = () => {
        window.location.reload();
      };
      return (
        <div>
          {renderPlatformSelector(
            platforms,
            currentPlatform,
            setCurrentPlatform,
            extraAction,
            currentPlatformCode
          )}
        </div>
      );
    };

    const avatar = config.HEADER.AVATAR_SHOW && (
      <Avatar size="default" className="configUser__avatar">
        {firstLetterName}
        {firstLetterSurname}
      </Avatar>
    );

    const userElements = config.SECURITY !== 0 && (
      <Dropdown
        overlay={userMenuOptions}
        placement="bottomRight"
        trigger={['click']}>
        <div className="configUser">
          {avatar}
          <span className="configUser__user">
            {window.innerWidth > config.BREAKPOINTS.MD &&
              (isEmpty(userData) || !userData ? 'Userinfo' : userData.name)}
          </span>
          <Icon type="down" />
        </div>
      </Dropdown>
    );

    return (
      <Layout.Header className="header">
        <div className="header__menuRight">{userElements}</div>
        <div className="header__menuRight header__platformSelector">
          {platformSelector()}
        </div>
      </Layout.Header>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ logout, setCurrentPlatform }, dispatch);

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    currentPlatformCode: state.app.currentPlatformCode,
    platforms: state.app.platforms,
    userData: state.app.user,
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContent),
);
