import React, { useEffect } from 'react';
import { ReducersState } from '../../../reducers';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import {
  IndexedDetail,
  IResource,
  IResourceDetail,
} from '../../ChallengeInterfaces';
import { Row, Input, Checkbox, Col, Button, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import '../Resource.css';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import {
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../webeatChallengeActions';
// import { QuizQuestionTypeEnum } from '../../Enums';

interface LearningQuizAnswerResource {
  form: WrappedFormUtils<any>;
  _resource: IResource;
  questionIndex: number;
  answerIndex: number;
  idQuestion: number;
  editResource: boolean;
  answersNumber?: number;
  questionType?: IndexedDetail;
  questionAnswers: IResource[];
}

type AnswerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LearningQuizAnswerResource;

function LearningQuizAnswerResource(props: AnswerProps) {
  const {
    _resource,
    form,
    questionIndex,
    answerIndex,
    idQuestion,
    editResource,
    answersNumber,
    handleRemoveChildResource,
    editRecursiveResource,
    // questionType,
    questionAnswers,
  } = props;
  const answerResource = _resource;
  const details = _resource.resourceDetailList!;
  // const isAllValidQuestionType = false;
  const intl = useIntl();

  let correct: { _detail: IResourceDetail; index: number };

  details.forEach((_detail, i) => {
    if (_detail.idResourceTypeD.type === 'boolean')
      correct = { _detail, index: i };
  });

  const deleteButtonDisable = !answersNumber || answersNumber <= 1;
  useEffect(() => {
    if (answerResource.resourceDetailList?.[0]?.idResourceD === -1) {
      editRecursiveResource({
        value: 'true',
        parentArrayPosition: questionIndex,
        childArrayPosition: answerIndex,
        detailArrayPosition: correct!.index,
      });
    }
    if (answerResource.resourceDetailList?.[0]?.idResourceD === -1
      && numberOfCorrectAnswers() > 1) {
      editRecursiveResource({
        value: 'false',
        parentArrayPosition: questionIndex,
        childArrayPosition: answerIndex,
        detailArrayPosition: correct!.index,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex, answerIndex, correct!.index]);

  const numberOfCorrectAnswers = () => {
    let num = 0
    questionAnswers.forEach((answer) => {
      if (answer.resourceDetailList?.[0]?.value.toString() === 'true') num = num + 1;
    })
    return num
  }

  const handleCheckboxChange = (checkboxChangeEvent: CheckboxChangeEvent) => {
    let hasTrueAnswer = false;

    questionAnswers.forEach((answer: IResource) => {
      if (
        answer.resourceDetailList?.[0]?.value.toString() === 'true' &&
        answer.indResource !== answer.idResource
      ) {
        hasTrueAnswer = true;
      }
    });

    if (checkboxChangeEvent.target.checked) {
      // if (hasTrueAnswer) {
      //   notification.warning({
      //     message: intl.formatMessage({ id: 'answer_dermo.answer-no-more-than-correct-answer' }),
      //     duration: 3,
      //   });

      //   return;
      // }
      return editRecursiveResource({
        value: checkboxChangeEvent.target.checked.toString(),
        parentArrayPosition: questionIndex,
        childArrayPosition: answerIndex,
        detailArrayPosition: correct!.index,
      });
    } else {
      if (hasTrueAnswer) {
        if (numberOfCorrectAnswers() === 1) {
          notification.warning({
            message: intl.formatMessage({ id: 'answer_dermo.answer-one-correct-at-least' }),
            duration: 3,
          });

          return;
        }
        return editRecursiveResource({
          value: checkboxChangeEvent.target.checked.toString(),
          parentArrayPosition: questionIndex,
          childArrayPosition: answerIndex,
          detailArrayPosition: correct!.index,
        });
      }
    }
  }

  const handleRemoveAnswer = () => {
    let canBeRemoved = false;

    questionAnswers.forEach((answer: IResource) => {
      if (
        answer.resourceDetailList?.[0]?.value.toString() === 'true' &&
        // answer.idResource !== answerResource.idResource &&
        answer.indResource !== answer.idResource
      ) {
        canBeRemoved = true;
      }
    });

    if (canBeRemoved) {
      return handleRemoveChildResource({
        idAnswer: answerResource.idResource,
        idQuestion,
        questionArrayPosition: questionIndex,
        answerArrayPosition: answerIndex,
      });
    } else {
      notification.warning({
        message: intl.formatMessage({ id: 'answer_dermo.answer-one-correct-at-least' }),
        duration: 3,
      });

      return;
    }
  }

  return correct! ? (
    editResource ? (
      //EDITABLE REPLY = TRUE
      <Row className="QuizAnswer" type="flex" gutter={[24, 24]}>
        <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
          <Form.Item className="Quiz__input" style={{ float: 'left' }}>
            <Button
              disabled={deleteButtonDisable}
              icon="minus-circle"
              className={
                deleteButtonDisable ? 'deleteButton__disabled' : 'deleteButton'
              }
              onClick={handleRemoveAnswer}
            />
          </Form.Item>
          <Form.Item className="Quiz__input" style={{ float: 'left' }}>
            Answer {answerIndex + 1}
          </Form.Item>
        </Col>

        <Col xs={22} md={16} xl={17}>
          <Form.Item className="Quiz__input">
            {form.getFieldDecorator(
              'answer'.concat(questionIndex.toString(), answerIndex.toString()),
              {
                initialValue: answerResource.name,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'update.required' }),
                    max: 250,
                  },
                ],
              },
            )(
              <Input
                onBlur={(e) => {
                  editRecursiveResource({
                    value: e.target.value,
                    parentArrayPosition: questionIndex,
                    childArrayPosition: answerIndex,
                  });
                }}
                maxLength={250}
              />,
            )}
          </Form.Item>
        </Col>
        <Col xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Item className="Quiz__input">
            {form.getFieldDecorator(
              questionIndex.toString().concat(answerIndex.toString()),
            )(
              <Checkbox
                className="QuizAnswer__checkbox"
                checked={
                  (correct!._detail.value === 'true' ||
                    correct!._detail.value === true
                    ? true
                    : false)
                  // || isAllValidQuestionType
                }
                // disabled={isAllValidQuestionType}
                onChange={(e: CheckboxChangeEvent) => {
                  handleCheckboxChange(e);
                }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    ) : (
      //EDITABLE REPLY = FALSE
      <Row className="QuizAnswer">
        <Row className="QuizAnswer__container" type="flex" gutter={[24, 24]}>
          <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
            <div className="QuizQuestion__media--ellipsis">
              Answer {answerIndex + 1}
            </div>
          </Col>
          <Col xs={22} md={16} xl={17}>
            {answerResource.name}
          </Col>
          <Col xs={2}>
            <Form.Item className="Quiz__input">
              {form.getFieldDecorator(
                questionIndex.toString().concat(answerIndex.toString()),
              )(
                <Checkbox
                  className="QuizAnswer__checkbox"
                  disabled
                  checked={
                    (correct! && correct!._detail.value === 'true'
                      ? true
                      : false)
                    // || isAllValidQuestionType
                  }
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Row>
    )
  ) : null;
}

const mapStateToProps = (state: ReducersState) => {
  return {
    editing:
      state.challenge.editingChallenge || state.challenge.editingResource,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearningQuizAnswerResource);
