import * as React from 'react';

import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Form, Input, Col, Button, Row, Result, Modal } from 'antd';
import { connect } from 'react-redux';
import { passwordUpdate } from '../auth/authActions';
import './UpdatePassword.css';
import { ReducersState } from '../reducers';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IRow } from '../app/AppInterfaces';

const FormItem = Form.Item;

interface IUpdatePasswordListState {
  confirmValue: boolean;
}
interface OwnProps {
  intl: any;
}

type IUpdatePasswordListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  FormComponentProps &
  OwnProps;

class UpdatePassword extends React.PureComponent<
  IUpdatePasswordListProps,
  IUpdatePasswordListState
> {
  state = {
    confirmValue: false
  };

  compareToFirstPassword = (rule: any, values: IRow, callback: Function) => {
    const { form, intl } = this.props;
    if (values && values !== form.getFieldValue('password')) {
      callback(intl.formatMessage({ id: 'password.inconsistent' }));
    } else {
      callback();
    }
  };

  handleValidate = (rule: any, values: IRow, callback: Function) => {
    const { form } = this.props;
    if (values && this.state.confirmValue) {
      form.validateFields(['confirm'], { force: true });
    } else {
      callback();
    }
  };

  handleConfirmBlur = (values: IRow) => {
    this.setState({ confirmValue: this.state.confirmValue || !!values });
  };

  handleConfirmPassword = () => {
    const { form, passwordUpdate, queryParams } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const token = queryParams;
        if (token !== undefined) {
          const newPassword = values.password;
          const data = {
            ...token,
            newPassword
          };
          if (values.hasOwnProperty('confirm')) delete values.confirm;
          passwordUpdate(data);
        }
      }
    });
  };

  render() {
    const { intl, form, visible, queryParams } = this.props;
    const { getFieldDecorator } = form;

    const modalConfirm = (
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        className="modalConfirm">
        <Result
          status="success"
          className="boxSuccess"
          title={intl.formatMessage({ id: 'password.update' })}
          extra={[
            <Button type="primary" href="/login">
              {intl.formatMessage({ id: 'login.msg' })}
            </Button>
          ]}
        />
      </Modal>
    );

    return !queryParams ? (
      <Redirect to={{ pathname: '/login' }} />
    ) : (
      <Row className="updatePassword">
        <Col>
          <Row className="updatePassword__logo--row">
            <div className="updatePassword__logo" />
          </Row>
          <Col className="updatePassword__boxContent">
            <Row style={{ width: '100%' }}>
              <Form>
                <Row type="flex" justify="center">
                  <Row>
                    <label className="label--text">
                      {intl.formatMessage({ id: 'password.recovery' })}
                    </label>
                  </Row>
                  <Row className="updatePassword__row">
                    <FormItem
                      label={intl.formatMessage({ id: 'password.password' })}
                      colon={false}
                      hasFeedback>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'password.confirm'
                            }),
                            validator: this.handleValidate
                          }
                        ]
                      })(<Input.Password className="updatePassword__input" />)}
                    </FormItem>
                  </Row>
                  <Row className="updatePassword__row">
                    <FormItem
                      label={intl.formatMessage({ id: 'password.confirm' })}
                      hasFeedback>
                      {getFieldDecorator('confirm', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'password.confirm.error'
                            })
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(<Input.Password className="updatePassword__input" />)}
                    </FormItem>
                  </Row>
                </Row>

                <Row type="flex" justify="center">
                  <Button type="primary" onClick={this.handleConfirmPassword}>
                    {intl.formatMessage({ id: 'password.submit' })}
                  </Button>
                </Row>
                {modalConfirm}
              </Form>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: ReducersState) => {
  return {
    visible: state.auth.visible,
    queryParams: state.query.params
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ passwordUpdate }, dispatch);

const injectedUpdatePassword = injectIntl(Form.create()(UpdatePassword));

export default connect(mapStateToProps, { passwordUpdate })(
  injectedUpdatePassword
);
