import configDev from '../configDev';
import {
  CONFIG_KEY,
  FK_KEY,
  PLATFORM_TIMEZONE,
  STATE_KEY,
} from './app.constants';
import config from '../config';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(STATE_KEY, serializedState);
};

export const loadConfig = () => {
  if (configDev.SAVE_CONFIG) {
    try {
      const serializedConfig = localStorage.getItem(CONFIG_KEY);
      return serializedConfig ? JSON.parse(serializedConfig) : undefined;
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export const saveConfig = (userConfig: any) => {
  try {
    const serializedConfig = JSON.stringify(userConfig);
    localStorage.setItem(CONFIG_KEY, serializedConfig);
  } catch (err) {}
};

export const loadForeignFK = () => {
  try {
    const serializedFK = localStorage.getItem(FK_KEY);
    if (serializedFK !== undefined) localStorage.removeItem(FK_KEY);
    return serializedFK ? JSON.parse(serializedFK) : undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveForeignPK = (foreignPk: string) => {
  try {
    const serializedFK = JSON.stringify(foreignPk);
    localStorage.setItem(FK_KEY, serializedFK);
  } catch (err) {}
};

export const loadTimezoneName = () => {
  return loadItem(PLATFORM_TIMEZONE) || config.APP.TIMEZONE_NAME;
};

export const loadItem = (key: string) => {
  const serializedItem = localStorage.getItem(key);
  return serializedItem ? JSON.parse(serializedItem) : undefined;
};

export const saveItem = (key: string, value: string) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (err) {}
};
