import { Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useIntl } from 'react-intl';
import { IRow } from '../../app/AppInterfaces';
import { getCurrencyValue } from '../../utils/platformUtils';

interface IProduct {
  description: string;
  id: number;
  image: string;
  manufacturer: number;
  name: string;
  nationalCode: string;
  pvl: number;
  pvpr: number;
  quantity: number;
}

interface IExtendedProduct extends IProduct {
  groupName: string;
  total: number;
}

interface IBrandProduct {
  manufactureId: number;
  products: IProduct[];
  groupName?: string;
}

interface IProps {
  loading: boolean;
  orders: IRow[];
}

export const OrderDetails = ({ requestContent }: IRow) => {
  const { formatMessage } = useIntl();
  if (!requestContent) return null;
  const orderContent: IRow = JSON.parse(requestContent);

  if (!orderContent?.brands?.length) return null;
  const orderProducts: IExtendedProduct[] = [];
  let orderTotal = 0;

  orderContent.brands.forEach(
    ({ products, groupName = '-' }: IBrandProduct) => {
      if (!products?.length) return;
      products.forEach((product: IProduct) => {
        const total = +product.quantity * +product.pvl || 0;
        orderTotal += total;
        orderProducts.push({ ...product, groupName, total });
      });
    },
  );

  const productsTableColumns: ColumnProps<IExtendedProduct>[] = [
    {
      dataIndex: 'image',
      title: '',
      width: '100px',
      render: (image: string) =>
        image && (
          <div>
            <img
              style={{ maxHeight: 80, maxWidth: 80 }}
              src={image}
              alt="request-product"
            />
          </div>
        ),
    },
    {
      dataIndex: 'groupName',
      title: formatMessage({ id: 'request-order.group-name' }),
      width: '130px',
    },
    {
      dataIndex: 'name',
      title: formatMessage({ id: 'request-order.name' }),
      width: '300px',
    },
    {
      align: 'center',
      dataIndex: 'quantity',
      title: formatMessage({ id: 'request-order.quantity' }),
      width: '60px',
    },
    {
      align: 'center',
      dataIndex: 'pvl',
      title: formatMessage({ id: 'request-order.pvl' }),
      width: '130px',
      render: (pvl: number) => getCurrencyValue(pvl),
    },
    {
      align: 'center',
      dataIndex: 'total',
      title: formatMessage({ id: 'request-order.total' }),
      width: '130px',
      render: (total: number) => getCurrencyValue(total),
    },
  ];

  return (
    <Table
      size="small"
      bordered={false}
      columns={productsTableColumns}
      rowKey={({ id }: IExtendedProduct) => id.toString()}
      dataSource={orderProducts}
      pagination={false}
      footer={() => (
        <Row
          type="flex"
          justify="end"
          style={{ paddingRight: 35, fontWeight: 600 }}>
          {formatMessage(
            { id: 'request-order.total-order' },
            { total: getCurrencyValue(orderTotal) },
          )}
        </Row>
      )}
    />
  );
};
