import React from 'react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const textAlign = {
  name: 'Align',
  keyCommand: 'align',
  buttonProps: { 'aria-label': 'Align center', title: 'Align center' },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="currentColor"
        d="M352 64c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<center>${state.selectedText}\n</center>`;
    if (!state.selectedText) {
      modifyText = `<center></center>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorPrimary30 = {
  name: 'colorPrimary30',
  keyCommand: 'primary30',
  buttonProps: { 'aria-label': 'Color Primary 30', title: 'Color Primary 30' },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#414141"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#414141" style="color:#414141">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#414141" style="color:#414141"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorPrimary40 = {
  name: 'colorPrimary40',
  keyCommand: 'primary40',
  buttonProps: { 'aria-label': 'Color Primary 40', title: 'Color Primary 40' },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#D43742"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#D43742" style="color:#D43742">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#D43742" style="color:#D43742"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondary10 = {
  name: 'ColorSecondary10',
  keyCommand: 'secondary10',
  buttonProps: {
    'aria-label': 'Color Secondary 10',
    title: 'Color Secondary 10',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#8F000A"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#8F000A" style="color:#8F000A">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#8F000A" style="color:#8F000A"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondary20 = {
  name: 'ColorSecondary20',
  keyCommand: 'secondary20',
  buttonProps: {
    'aria-label': 'Color Secondary 20',
    title: 'Color Secondary 20',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#AE010D"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#AE010D" style="color:#AE010D">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#AE010D" style="color:#AE010D"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondary50 = {
  name: 'ColorSecondary50',
  keyCommand: 'secondary50',
  buttonProps: {
    'aria-label': 'Color Secondary 50',
    title: 'Color Secondary 50',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#C84952"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#C84952" style="color:#C84952">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#C84952" style="color:#C84952"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondary90 = {
  name: 'ColorSecondary90',
  keyCommand: 'secondary90',
  buttonProps: {
    'aria-label': 'Color Secondary 90',
    title: 'Color Secondary 90',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#FBCCCF"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#FBCCCF" style="color:#FBCCCF">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#FBCCCF" style="color:#FBCCCF"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondary100 = {
  name: 'ColorSecondary100',
  keyCommand: 'secondary100',
  buttonProps: {
    'aria-label': 'Color Secondary 100',
    title: 'Color Secondary 100',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#FFECED"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#FFECED" style="color:#FFECED">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#FFECED" style="color:#FFECED"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const textColorSecondaryDisabled = {
  name: 'ColorSecondaryDisabled',
  keyCommand: 'secondaryDisabled',
  buttonProps: {
    'aria-label': 'Color Secondary Disabled',
    title: 'Color Secondary Disabled',
  },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="#FEB7BC"
        d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<span color="#FEB7BC" style="color:#FEB7BC">${state.selectedText}\n</span>`;
    if (!state.selectedText) {
      modifyText = `<span color="#FEB7BC" style="color:#FEB7BC"></span>`;
    }
    api.replaceSelection(modifyText);
  },
};

export const MarkdownInputComponent = (props: any) => {
  return props.editMode ? (
    <div className="container" style={{ height: '360px' }}>
      <FormattedMessage
        id="markdown-info-message"
        values={{
          link: 'here',
          a: (chunks: string) => (
            <a
              href="https://commonmark.org/help/"
              rel="noopener noreferrer"
              target="_blank">
              {chunks}
            </a>
          ),
        }}
      />
      <MDEditor
        value={props.value || ''}
        onChange={(_value: any) => {
          props.handleEdit(_value);
        }}
        commands={[
          // Custom Toolbars
          commands.group(
            [
              commands.title1,
              commands.title2,
              commands.title3,
              commands.title4,
              commands.title5,
            ],
            {
              name: 'title',
              groupName: 'title',
              buttonProps: {
                'aria-label': 'Insert title',
                title: 'Title',
              },
            },
          ),
          commands.divider,
          commands.bold,
          commands.divider,
          commands.italic,
          commands.divider,
          commands.orderedListCommand,
          commands.divider,
          commands.unorderedListCommand,
          commands.divider,
          commands.link,
          commands.divider,
          textAlign,
          commands.divider,
          commands.group(
            [
              textColorPrimary30,
              textColorPrimary40,
              textColorSecondary10,
              textColorSecondary20,
              textColorSecondary50,
            ],
            {
              name: 'Colors',
              groupName: 'Colors',
              buttonProps: {
                'aria-label': 'Insert color',
                title: 'Colors',
              },
              icon: (
                <svg width="12" height="12" viewBox="0 0 520 520">
                  <path
                    fill="currentColor"
                    d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z"
                  />
                </svg>
              ),
            },
          ),
        ]}
        preview="edit"
        height={350}
      />
    </div>
  ) : (
    <div className="container">
      <ReactMarkdown children={props.value} rehypePlugins={[rehypeRaw]} />
    </div>
  );
};
