import { Button, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { userDermoStatus } from '../../challenge/Enums/userDermoStatus.enum';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function ValidateDermoButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const handleDermoValidation = async () => {
    try {
      // TO DO DERMO_MAIL
      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.UPDATE_DERMO_USER,
        data: {
          roleType: parseInt(selectedRow.roleType),
          idUser: selectedRow.idUser,
          userStatus: userDermoStatus.VALIDATED
        },
        callConfig: {},
      });

      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'dermo.validate.notification.title',
          }),
          description: formatMessage({
            id: 'dermo.validate.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      await tableProps.handleReloadData();
      setShowConfirmModal(false);
    }
  };

  const buttonsDisabled = (
    isEmpty(selectedRow) ||
    selectedRow.userStatus === userDermoStatus.VALIDATED ||
    selectedRow.status === false) 

  return (
    <>
      <Button
        onClick={() => setShowConfirmModal(true)}
        disabled={buttonsDisabled}
        type="primary">
        {formatMessage({ id: 'dermo.validate.button' })}
      </Button>
      <Modal
        visible={showConfirmModal}
        closable={false}
        centered
        onOk={handleDermoValidation}
        onCancel={() => setShowConfirmModal(false)}
        className="modalConfirm">
        <FormattedMessage
          id="validation.dermoUser.text"
          values={{
            user: selectedRow.email,
            strong: (chunks: string) => <strong>{chunks}</strong>,
          }}
        />
      </Modal>
    </>
  );
}
