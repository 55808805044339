import { ResourceTypeEnum } from '../challenge/Enums';
import {
  typesOfSelectEnum,
  UPLOAD_ACTION_DOCUMENT,
  UPLOAD_ACTION_FILE,
  UPLOAD_ACTION_VIDEO,
} from '../shared';

const { VIDEO, DOCUMENT } = ResourceTypeEnum;

export const getValidFormats = (
  format: 'image' | 'pdf' | 'video' | 'document',
  selectedAction: typesOfSelectEnum,
  fileType: ResourceTypeEnum,
): string[] => {
  if (format === 'pdf' || selectedAction === UPLOAD_ACTION_FILE.id)
    return ['application/pdf', 'application/wps-office.pdf'];

  if (
    (format === 'video' && fileType === VIDEO) ||
    selectedAction === UPLOAD_ACTION_VIDEO.id
  )
    return [
      'audio/ogg',
      'video/mpeg',
      'video/mp4',
      'video/webm',
      'video/x-msvideo',
    ];
  if (
    (format === 'document' && fileType === DOCUMENT) ||
    selectedAction === UPLOAD_ACTION_DOCUMENT.id
  )
    return [
      'application/pdf',
      'application/xls',
      'application/msword',
      'application/wps-office.pdf',
      'application/word',
      'application/vnd.ms-excel',
      'text/csv',
    ];

  return [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'image/webp',
  ];
};
