import { Col, InputNumber, Modal, Row } from 'antd';
import Axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import { ComboData } from '../../combos/ComboInterfaces';
import config from '../../config';
import { ReducersState } from '../../reducers';
import { isConPlatform } from '../../utils';
import '../Challenge.css';
import { SetChallengeLoading } from '../challengeActions';
import { usePrevious } from '../ChallengeBuilder';
import { IResource, IScore } from '../ChallengeInterfaces';
import { ChallengeTypeEnum, ResourceTypeEnum } from '../Enums';
import AdminPointsForm from './AdminPointsForm';
import {
  getAccumulateScore,
  getMaxChildScore,
  getScoreDefaultInit,
  getValidScore,
} from './utils';

const {
  CHECKOUT,
  CHECKOUT_PRODUCT,
  CHECKOUT_PAYMENT_GATEWAY,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT,
  EVENT,
  QUESTION,
  QUIZ,
  QUIZ_DERMO,
  CHALLENGE_MATERIALS,
  MATERIAL,
  SELL_OUT,
  SELL_OUT_POINT_RANGE,
  SURVEY,
} = ResourceTypeEnum;

interface IAdminPoints {
  pointsModalVisible: boolean;
  setPointsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: IRow;
  constructResources: (resources: IRow) => IResource[];
  setLoading: (
    isLoading: boolean,
  ) => (dispatch: Dispatch<SetChallengeLoading>) => void;
  isLoading: boolean;
  combos: any;
  refreshChallengeData: () => Promise<void>;
}

const AdminPoints = (props: IAdminPoints) => {
  const [resources, setResources] = useState<null | IResource[]>(null);
  const [likePoints, setLikePoints] = useState(0);
  const [pointsHaveChanged, setPointsHaveChanged] = useState(false);
  const intl = useIntl();
  const {
    isLoading,
    pointsModalVisible,
    selectedRow,
    constructResources,
    setLoading,
    setPointsModalVisible,
    combos,
  } = props;
  const prevSelectedRow = usePrevious(selectedRow);
  const specialtyCombo: ComboData[] =
    combos.combos.challengechallengeEdit?.specialty?.specialty?.data || [];

  useEffect(() => {
    if (
      !selectedRow ||
      isEmpty(selectedRow) ||
      (!pointsModalVisible && resources !== null)
    )
      setResources(null);
    else if (
      prevSelectedRow !== selectedRow ||
      (pointsModalVisible && resources === null)
    )
      (async () => await fetchPoints())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, pointsModalVisible]);

  const fetchPoints = async () => {
    const idChallenge = selectedRow?.idChallenge;
    setPointsHaveChanged(false);

    if (!idChallenge) return;

    setLoading(true);
    try {
      const [call1Response, call2Response] = await Promise.all([
        api.getDataCallById({
          dataPath: apiPaths.CALL.CHALLENGE_POINTS,
          registerId: idChallenge,
          callConfig: {},
        }),
        api.getDataCallById({
          dataPath: apiPaths.CALL.LIKE_POINTS,
          registerId: idChallenge,
          callConfig: {},
        }),
      ]);

      setResources(constructResources(call1Response.data));
      setLikePoints(call2Response.data?.score ? call2Response.data.score : 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (pointsHaveChanged)
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setPointsModalVisible(false);
          setPointsHaveChanged(false);
        },
        onCancel() {},
      });
    else setPointsModalVisible(false);
  };

  const { idChallengeType } = selectedRow?.idChallengeType;
  const handleEditLikePoints = (value: number | undefined) => {
    setPointsHaveChanged(true);
    if (
      !value ||
      (idChallengeType === ChallengeTypeEnum.EXPERIENCE && value > 0) ||
      (idChallengeType === ChallengeTypeEnum.CONTEST && value < 0) ||
      (idChallengeType === ChallengeTypeEnum.FORMATION_CON && value < 0)
    )
      setLikePoints(0);
    else setLikePoints(value);
  };

  const handleSavePoints = async () => {
    if (!pointsHaveChanged) return;

    setLoading(true);

    try {
      await Axios.all([
        api.putDataCall({
          dataPath: apiPaths.CALL.SCORE_RESOURCE,
          data: getScoreList(resources),
          callConfig: {},
        }),
        api.putDataCall({
          dataPath: apiPaths.CALL.SCORE_CHALLENGE,
          data: { idChallenge: selectedRow.idChallenge, points: likePoints },
          callConfig: {},
        }),
      ]);

      await props.refreshChallengeData();
      setPointsModalVisible(false);
      setPointsHaveChanged(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getScoreList = (resource: IResource[] | null | undefined): IScore[] => {
    const scoreList: IScore[] = [];

    resource?.forEach((resource) => {
      scoreList.push(getScoreDefaultInit(resource));
      resource.scoresSpecialty?.forEach((score) => scoreList.push(score));

      const _scoreList = getScoreList(resource.resourceList);

      _scoreList.forEach((score) => scoreList.push(score));
    });

    return scoreList;
  };

  const getChallengePoints = (resourceList: IResource[]): number => {
    let totalScore = 0;
    let higherEventScore = 0;
    let higherSurveyScore = 0;

    resourceList.forEach((_resource: IResource) => {
      const { idResourceType } = _resource.idResourceType;
      switch (idResourceType) {
        case EVENT:
          let eventScore = 0;
          if (_resource.status) {
            _resource.resourceList?.forEach((eventResource) => {
              eventScore += getValidScore(eventResource);
            });
            if (Math.abs(eventScore) > Math.abs(higherEventScore))
              higherEventScore = eventScore;
          }
          break;

        case SURVEY:
          let surveyScore = 0;
          if (_resource.status) {
            surveyScore += getValidScore(_resource);
            if (Math.abs(surveyScore) > Math.abs(higherSurveyScore))
              higherSurveyScore += surveyScore;
          }
          break;
        case QUIZ:
        case QUIZ_DERMO:
          totalScore += getAccumulateScore(_resource, QUESTION);
          break;
        case SELL_OUT:
          totalScore += getAccumulateScore(_resource, SELL_OUT_POINT_RANGE);
          break;
        case CHALLENGE_MATERIALS:
          totalScore += getAccumulateScore(_resource, CHALLENGE_MATERIALS);
          break;
        case CHECKOUT:
        case CHECKOUT_PAYMENT_GATEWAY:
          totalScore += getMaxChildScore(_resource);
          break;
        case MATERIAL:
        case CHECKOUT_PRODUCT:
        case CHECKOUT_PAYMENT_GATEWAY_PRODUCT:
          break;
        default:
          totalScore += getValidScore(_resource);
          break;
      }
    });
    return totalScore + higherEventScore + higherSurveyScore;
  };

  return (
    selectedRow && (
      <Modal
        className="pointsModal"
        style={{
          minWidth:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '800px',
          maxHeight:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '60vw',
          minHeight:
            window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '400px',
        }}
        visible={pointsModalVisible}
        onOk={handleSavePoints}
        onCancel={handleCloseModal}
        cancelText={intl.formatMessage({ id: 'form.edit.cancel' })}
        okText={intl.formatMessage({ id: 'form.save' })}>
        <Row>
          <Row className="pointsModal__title">
            {intl.formatMessage({ id: 'points-admin.title' })}
          </Row>
          {isLoading ? (
            <Row className="pointsModal__loading" />
          ) : (
            <>
              {!isConPlatform() && (
                <Row className="pointsModal__likePoints">
                  <Col xs={16}>
                    {intl.formatMessage({ id: 'points-admin.like' })}
                  </Col>
                  <Col xs={8}>
                    <InputNumber
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: 700,
                        width: '98%',
                        color: 'black',
                      }}
                      value={likePoints}
                      size="small"
                      onChange={(value: number | undefined) =>
                        handleEditLikePoints(value)
                      }
                    />
                  </Col>
                </Row>
              )}
              <Row className="pointsModal__totalPoints">
                <Col xs={16}>
                  {intl.formatMessage({ id: 'points-admin.total' })}
                </Col>
                <Col xs={8}>
                  <InputNumber
                    style={{
                      backgroundColor: 'transparent',
                      fontWeight: 700,
                      width: '98%',
                      color: 'black',
                    }}
                    disabled
                    value={
                      resources !== null ? getChallengePoints(resources) : 0
                    }
                    size="small"
                  />
                </Col>
              </Row>
              {resources && (
                <AdminPointsForm
                  {...{
                    specialtyCombo,
                    resourceList: resources,
                    idChallengeType,
                    setResources,
                    setPointsHaveChanged,
                  }}
                />
              )}
            </>
          )}
        </Row>
      </Modal>
    )
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos,
  };
};

export default connect(mapStateToProps)(AdminPoints);
