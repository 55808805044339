import { ComboDataPlatform } from '../combos/ComboInterfaces';
import config from '../config';
import { getPlatformBaseUrl } from '../utils';
import { AppActionTypes } from './appActions';
import {
  IRecord,
  IUserPermission,
  IUserPlatform,
  MenuItem,
} from './AppInterfaces';
import { appTypes } from './appTypes';

export interface AppState {
  appParams: IRecord | null;
  currentPlatform: string;
  currentPlatformCode: string
  locale: string;
  menu: MenuItem[] | null;
  menuState: number | string;
  permissions: string[] | null;
  platforms: ComboDataPlatform[] | null;
  user: IRecord | null; //TODO define user interface
  userPermissions: IUserPermission[];
  userPlatforms: IUserPlatform[];
}

const initialState: AppState = {
  appParams: null,
  currentPlatform: getPlatformBaseUrl(),
  currentPlatformCode: config.APP.PLATFORM_CODE,
  locale: config.LOCALE.DEFAULT,
  menu: null,
  menuState: 2,
  permissions: null,
  platforms: null,
  user: null,
  userPermissions: [],
  userPlatforms: [],
};

export function appReducer(
  state = initialState,
  action: AppActionTypes,
): AppState {
  switch (action.type) {
    case appTypes.APP_SET_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    case appTypes.APP_ACCESS_DATA:
    case appTypes.APP_SET_CURRENT_PLATFORM:
      return {
        ...state,
        ...action.payload,
      };
    case appTypes.APP_MENU_STATE:
      return {
        ...state,
        menuState: action.payload.menuState,
      };
    case appTypes.APP_REMOVE_STORE:
      return {
        ...state,
        permissions: null,
        userPermissions: [],
        userPlatforms: [],
        appParams: null,
        user: null,
        menu: null,
      };
    case appTypes.ROUTER_LOCATION_CHANGE:
      const { pathname } = action.payload.location;
      let { menuState } = state;

      if (pathname === '/Home') {
        menuState = 0;
      } else if (menuState === 0) {
        menuState = 1;
      }

      return {
        ...state,
        menuState,
      };
    default:
      return state;
  }
}
