import React from 'react';
export default function staticPages() {
  return (
    <svg viewBox="0 0 64 64" className="svgIconFill">
      <g>
        <path d="m33 29h2v6h-2z" />
        <path d="m37 29h2v6h-2z" />
        <path d="m37 57c0 2.206-1.794 4-4 4h-26c-2.206 0-4-1.794-4-4v-46c0-2.206 1.794-4 4-4h4v2h-4c-1.103 0-2 .897-2 2v46c0 1.103.897 2 2 2h26c1.103 0 2-.897 2-2v-8h-2v8h-26v-4h4v-1.584c0-1.121.669-2.122 1.753-2.57 1.047-.436 2.233-.203 3.025.589l1.12 1.123 5.658-5.658-1.12-1.12c-.794-.794-1.026-1.982-.57-3.074.428-1.037 1.428-1.706 2.55-1.706h1.584v-8h-1.584c-1.121 0-2.122-.669-2.57-1.753-.436-1.044-.204-2.232.589-3.025l1.122-1.121-5.658-5.658-1.119 1.122c-.793.794-1.982 1.024-3.074.57-1.037-.429-1.706-1.43-1.706-2.551v-1.584h-4v-6h4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2h4v4h2v-4c0-1.103-.897-2-2-2h-4v-2h4c2.206 0 4 1.794 4 4v4h2v-4c0-3.309-2.691-6-6-6h-8v-2c0-1.103-.897-2-2-2h-6c-1.103 0-2 .897-2 2v2h-8c-3.309 0-6 2.691-6 6v46c0 3.309 2.691 6 6 6h26c3.309 0 6-2.691 6-6v-8h-2zm-30-26c2.206 0 4 1.794 4 4s-1.794 4-4 4zm0 10c3.309 0 6-2.691 6-6s-2.691-6-6-6v-2c4.411 0 8 3.589 8 8s-3.589 8-8 8zm2.018-22c.152 1.762 1.269 3.298 2.966 4 1.665.694 3.545.399 4.903-.74l2.853 2.854c-1.14 1.357-1.434 3.241-.723 4.945.685 1.657 2.22 2.771 3.982 2.924v4.035c-1.763.152-3.299 1.27-4 2.966-.695 1.665-.4 3.546.741 4.903l-2.854 2.854c-1.356-1.139-3.24-1.433-4.947-.723-1.655.685-2.769 2.22-2.921 3.982h-2.018v-6c5.514 0 10-4.486 10-10s-4.486-10-10-10v-6zm7.982-12v-4h6v4h4v4h-14v-4z" />
        <path d="m27 19h26v-2.079l7.256 5.079-7.256 5.079v-2.079h-26v2h24v3.921l12.744-8.921-12.744-8.921v3.921h-24z" />
        <path d="m29 21h2v2h-2z" />
        <path d="m35 21h2v2h-2z" />
        <path d="m41 21h2v2h-2z" />
        <path d="m51 37h-24v2h26v-2.079l7.256 5.079-7.256 5.079v-2.079h-26v2h24v3.921l12.744-8.921-12.744-8.921z" />
        <path d="m29 41h2v2h-2z" />
        <path d="m35 41h2v2h-2z" />
        <path d="m41 41h2v2h-2z" />
        <path d="m27 49h4v2h-4z" />
        <path d="m21 49h4v2h-4z" />
        <path d="m21 53h10v2h-10z" />
      </g>
    </svg>
  );
}
