import React, { useState, FC } from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { Button, Form, InputNumber, Modal, Row, Empty, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { isTableProps } from '../../utils';
import './GlobalActionAssignPoints.css'
import { IRow } from '../../app/AppInterfaces';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

export default function GlobalActionAssignPoints <
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
  >(parentProps: T)  {
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ action, setAction ] = useState<IRow>({});
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (isTableProps(parentProps)) {
      const tableProps: ITableRenderListProps = parentProps;
      setAction(tableProps.props.selectedRow);
    }
  }, [parentProps])

  const save = async() => {
    await api.putDataCall({
      dataPath: apiPaths.CALL.ACTIONS,
      data: action,
      callConfig: {}
    });
    setModalVisible(false);
  }

  const updatePoints = (points: string, scoreIndex: number) => {
    let currentScores = action.scores;
    currentScores[scoreIndex].points = points;

    setAction({
      ...action,
      scores: currentScores
    })
  }

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { intl, selectedRow } = tableProps.props;

    const FormCustom: FC<FormComponentProps> = ({ form }) => {
      return (
        <div>
          {
            action.scores?.map((score: any, index: number) => {
              return (
                <Row
                  gutter={[0, 8]}
                  key={score.idSpecialty.idSpecialty}
                >
                  <Col span={10}>
                    <label>{score.idSpecialty.description}</label>
                  </Col>

                  <Col>
                    <InputNumber
                      size="small"
                      type="number"
                      key={`input_${score.idSpecialty.idSpecialty}`}
                      value={score.points}
                      onBlur={e => updatePoints(e.target.value, index)}
                    />
                  </Col>
                </Row>
              )
            })
          }
          <Row className="container-buttons">
            <Button
              type="primary"
              color="danger"
              htmlType="submit"
              onClick={save}
            >
              {intl.formatMessage({ id: 'form.save' })}
            </Button>
          </Row>
        </div>
      )
    }
    const SpecialtyPointsFormComponent = Form.create<FormComponentProps>()(FormCustom);

    const previewModal = (
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'40%'}>
        {
          action.scores?.length ?
            <Row>
              <h2 className="">{ action.name }</h2>
              <SpecialtyPointsFormComponent />
            </Row>
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </Modal>
    );

    return (
      <>
        {previewModal}
        <Button
          onClick={() => setModalVisible(true)}
          type="primary"
          disabled={isEmpty(selectedRow)}
        >
          {formatMessage({ id: 'globalAction.assign_points' })}
        </Button>
      </>
    )
  }
  return <></>;
}