import { Column } from './tableInterfaces';
import { Primitive } from '../app/AppInterfaces';

enum EmptyValueReplacement {
  GRADUATION_YEAR = 'N/A',
  ID_SALESFORCE = 'Pending validation...',
}

export const replaceEmptyValues = (
  column: Column,
  value: Primitive,
): Primitive => {
  const emptyValueReplacements: { [key: string]: Primitive } = {
    graduationYear: EmptyValueReplacement.GRADUATION_YEAR,
    idSalesforce: EmptyValueReplacement.ID_SALESFORCE,
  };

  return value || emptyValueReplacements[column.key];
};
