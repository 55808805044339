import { Button, Col, Form, Icon, Input, Row, Select } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { ICompleteField } from '../forms/formInterfaces';
import { UPLOAD_ACTION_IMAGE, } from '../shared';
import { ComboData } from '../combos/ComboInterfaces';
import UploadComponent from '../challenge/Components/UploadComponent';

 export const WebeatImageSelector = (field: ICompleteField, parentProps: any) => {
  const { formatMessage } = useIntl();
  const { key, index } = field;
  const { handleChangeField, props } = parentProps;
  const { accessToken, form, setFormData, setFormStateFlag, params, targetId, values } = props;
  const { getFieldDecorator } = form;
  const fieldPrefix = field.module === 'grid' ? 'idGrid.' : '';

  const options = [
    {
        description: 'Image',
        value: 'image',
    },
    {
        description: 'Url',
        value: 'url',
    },
  ];

  let initialValue = values[key] || undefined;

  const customChangeField = (valuesToChange: { [key: string]: string | null }) => {
    setFormData({
      componentId: params.componentId,
      values: {
        ...values,
        ...valuesToChange
      },
    });
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: true,
    });
  }

  const onChangeSelector = (value: any, data: any) => {
    if (!value) {
      customChangeField({
        [`${fieldPrefix}banner${index}Id`]: null,
        [`${fieldPrefix}banner${index}Image`]: null,
        [`${fieldPrefix}banner${index}Url`]: null,
        [`${fieldPrefix}banner${index}Type`]: null});
    } else {
      customChangeField({
        [`${fieldPrefix}banner${index}Image`]: null,
        [`${fieldPrefix}banner${index}Type`]: value});
    }
  }
  
  const imageTypeSelector = (
    <Row>
      <Col span={6}>
        <Form.Item
          label={formatMessage(
            { id: 'custom-field.banner{number}Type' },
            { number: index })}>
          {getFieldDecorator(key, {
            initialValue: initialValue,
          })
          (<Select
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            allowClear
            size="default"
            placeholder={formatMessage(
              { id: 'custom-field.banner{number}Type.placeholder' },
              { number: index })}
            notFoundContent={formatMessage({
              id: 'combo.data.notfound',
            })}
            onChange={onChangeSelector}>
            {options.map(
              (option: ComboData) => {
                return (
                  <Select.Option
                    key={option.value.toString()}
                    value={option.value.toString()}>
                    {option.description}
                  </Select.Option>
                );
              },
            )}
          </Select>,
          )}
        </Form.Item>
      </Col>
    </Row>
  );

  const imageUpload = (
    <>
    {getFieldDecorator(`${fieldPrefix}banner${index}Image`, {
      rules: [
        {
          required: !!values[`${fieldPrefix}banner${index}Url`],
          message: formatMessage({ id: 'update.required' }),
        },
      ],
    })(
      UploadComponent({
        format: 'image',
        token: accessToken,
        value: values[`${fieldPrefix}banner${index}Image`],
        uploadActions: [UPLOAD_ACTION_IMAGE],
        handleChangeField,
        setValue: (value?: string | null) => {
          handleChangeField({
            type: 'image',
            id: `${fieldPrefix}banner${index}Image`,
            value,
          })
          if (!value) {
            handleChangeField({
              type: 'text',
              id: `${fieldPrefix}banner${index}Url`,
              value: null,
            })
            onChangeSelector(null, null)
          }
        },
        children: (
          <Button
            className="uploadButton uploadButton__margin-top">
            <Icon type="upload" />
            {formatMessage({
              id: 'upload.buttonTitle',
            })}
          </Button>
        ),
        primaryEntityId: values[params.primaryKey],
        module: field.module
      }))}
    </>
  );


  const imageUrl = (
    <>
    {getFieldDecorator(`${fieldPrefix}banner${index}Image`, {
      initialValue: values[`${fieldPrefix}banner${index}Image`],
       rules: [
       {
         required: !!values[`${fieldPrefix}banner${index}Url`],
         message: formatMessage({ id: 'update.required' }),
       },
     ],
   })(
    <Input
      type='text'
      title={formatMessage(
        { id: 'custom-field.banner{number}.image' },
        { number: index },
      )}
      maxLength={250}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeField({
          type: 'text',
          id: `${fieldPrefix}banner${index}Image`,
          value: e.target.value,
        })}/>,
    )}
    </>
  );

  const redirectUrl = (
    <Col span={12}>
      <Form.Item label={formatMessage(
        { id: 'custom-field.banner{number}.url' },
        { number: index },
      )}>
        {getFieldDecorator(`${fieldPrefix}banner${index}Url`, {
          initialValue: values[`${fieldPrefix}banner${index}Url`],
          rules: [
            {
              required: !!values[`${fieldPrefix}banner${index}Image`],
              message: formatMessage({ id: 'update.required' }),
            },
          ],
        })(
        <Input
          type='text'
          title={formatMessage(
            { id: 'custom-field.banner{number}.url' },
            { number: index },
          )}
          maxLength={250}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField({
              type: 'text',
              id: `${fieldPrefix}banner${index}Url`,
              value: e.target.value,
            })
          }
        />,
      )}
      </Form.Item>
    </Col>
  );

  const showImageSection = values[params.primaryKey] && (field.module === 'grid' || values.type < 5);

  return (
    <>
    {showImageSection && (
      <Col xs={24}>
      {imageTypeSelector}
      {values[key] && (
        <>
          <Col span={10}>
            <Form.Item
              label={formatMessage(
                { id: 'custom-field.banner{number}.image' },
                { number: index },
              )}
            >
              {values[key] === 'image' ? imageUpload : imageUrl }
            </Form.Item>
          </Col>
          {redirectUrl}
        </>)}
      </Col>)}
    </>)
};

