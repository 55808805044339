import { tableTypes } from './tableTypes';
import { TableActionTypes, target } from './tableActions';
import { QueryParams, TableData } from './tableInterfaces';
import { IRow } from '../app/AppInterfaces';

interface Table {
  queryParams: QueryParams;
  loadData: boolean;
  isLoading: boolean;
  filters: string;
  prettierKey: string;
  selectedRow: IRow;
  selectedRowKeys: number[] | string[];
  targetsId: target[];
  columnsConfig: any[];
  rowKey: string;
  data: TableData;
  formHasChanged: boolean;
  parentSelectedRow?: IRow;
  parentPrettierKey?: string;
  parentRowKey?: string;
  imageModalVisible?: boolean;
  imageModalLoading?: boolean;
}

export interface TableState {
  [key: string]: Table;
}

let initialState: TableState = {};

export function tableReducer(
  state = initialState,
  action: TableActionTypes,
): TableState {
  switch (action.type) {
    case tableTypes.SET_INITIAL_TABLE_STATE:
      return {
        ...state,
        [action.payload.componentId]: {} as Table,
      };
    case tableTypes.TABLE_INITIAL_STATE:
    case tableTypes.TABLE_UPDATE_SELECTED_ROW:
      const { componentId, ...payload } = action.payload;
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          ...payload,
        },
      };
    case tableTypes.TABLE_RESET_CONTENT: {
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: {} as TableData,
          selectedRow: {},
          selectedRowKeys: [],
          queryParams: {},
        },
      };
    }
    case tableTypes.TABLE_SET_INITIAL_PARAMS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: {
            ...state[action.payload.componentId].queryParams,
            sort: action.payload.sort,
            field: action.payload.field,
          },
        },
      };
    case tableTypes.TABLE_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: {} as QueryParams,
          data: {} as TableData,
          selectedRow: {},
          selectedRowKeys: [],
          parentSelectedRow: {},
          formHasChanged: false,
        },
      };
    case tableTypes.TABLE_LOAD_DATA_TRIGGER:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          loadData: false,
          isLoading: true,
        },
      };
    case tableTypes.TABLE_SET_LOAD_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          loadData: true,
        },
      };
    case tableTypes.TABLE_CONFIGURED_COLUMNS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          columnsConfig: action.payload.columns,
        },
      };
    case tableTypes.TABLE_FETCHING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: action.payload.queryParams,
          isLoading: action.payload.isLoading,
        },
      };
    case tableTypes.TABLE_FETCHED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: action.payload.data,
          isLoading: action.payload.isLoading,
        },
      };
    case tableTypes.TABLE_SELECTED_ROWS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRowKeys: action.payload.selectedRowKeys,
        },
      };
    case tableTypes.TABLE_SET_CHILD_ROW: {
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          parentSelectedRow: action.payload.parentSelectedRow,
          parentPrettierKey: action.payload.parentPrettierKey,
          parentRowKey: action.payload.parentRowKey,
        },
      };
    }
    case tableTypes.TABLE_SELECTED_ROW:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRow: action.payload.selectedRow,
        },
      };
    case tableTypes.TABLE_DELETING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading,
        },
      };
    case tableTypes.TABLE_DELETED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRowKeys: action.payload.selectedRowKeys,
          isLoading: action.payload.isLoading,
        },
      };
    case tableTypes.TABLE_SET_FORM_STATE_FLAG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          formHasChanged: action.payload.formHasChanged,
        },
      };
    case tableTypes.TABLE_FETCHING_IMAGE_MODAL:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          imageModalVisible: action.payload.visible,
          imageModalLoading: action.payload.isLoading,
        },
      };
    case tableTypes.TABLE_FETCHED_IMAGE_MODAL:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          imageModalVisible: action.payload.visible,
          imageModalLoading: action.payload.isLoading,
        },
      };

    case tableTypes.TABLE_SEARCH_SENDING_DATA_ERROR:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: false,
        },
      };
    case tableTypes.RESET_TABLE_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: action.payload.data,
          queryParams: action.payload.queryParams,
          parentSelectedRow: {},
        },
      };
    case tableTypes.TABLE_UPDATE_QUERY_PARAMS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: action.payload.queryParams,
        },
      };
    default:
      return state;
  }
}
