import React, { FC, Fragment } from 'react';
import { IResource } from '../../ChallengeInterfaces';
import { Form, Input, Row, Divider } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils<any>;
  editResource: boolean;
  resourceList: IResource[];
  handleEditResourceDetail: (idResourceD: number, value: any) => void;
}

const FaqsResources: FC<Props> = ({
  editResource,
  form,
  resourceList,
  handleEditResourceDetail
}): JSX.Element => {
  const faqs: { resource: IResource; index: number }[] = [];

  const jsxFaqs: JSX.Element[] = [];

  resourceList &&
    resourceList.length &&
    resourceList.forEach((_resource, index) => {
      faqs.push({ resource: _resource, index });
    });

  faqs
    .sort(function (
      a: { resource: IResource; index: number },
      b: { resource: IResource; index: number }
    ) {
      return a.resource.order - b.resource.order;
    })
    .forEach(
      (_faq, i) =>
        _faq.resource.resourceDetailList &&
        _faq.resource.resourceDetailList.length &&
        jsxFaqs.push(
          <Form.Item key={i} className="Quiz__Faq" label={_faq.resource.name}>
            {editResource
              ? form.getFieldDecorator(
                  _faq.resource.resourceDetailList![0].idResourceD.toString(),
                  {
                    initialValue: _faq.resource.resourceDetailList![0].value
                  }
                )(
                  <Input.TextArea
                    onBlur={e =>
                      handleEditResourceDetail(
                        _faq.resource.resourceDetailList![0].idResourceD,
                        e.target.value
                      )
                    }
                  />
                )
              : _faq.resource.resourceDetailList![0].value}
          </Form.Item>
        )
    );
  return (
    <Row>
      {jsxFaqs.map((faq, index) => {
        if (index === 0) return faq;
        else
          return (
            <Fragment key={index}>
              <Divider className="resourceDivider" />
              {faq}
            </Fragment>
          );
      })}
    </Row>
  );
};

export default FaqsResources;
