import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { inRange } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import Upload from '../../challenge/Components/UploadComponent';
import { ChallengeTypeEnum } from '../../challenge/Enums';
import { isConPlatform } from '../../utils';
import {
  DetailProps,
  PageComponentEnum,
  PageDetailEnum,
  positionCombo,
  renderSelector,
  videoTypeCombo,
  VideoTypesEnum,
} from '../shared';
import { MAX_FAQ_ITEMS } from './ComponentButtons';
import {
  checkDuplicatedFaqsOrders,
  checkDuplicatedOrders,
  setFileDimensions,
} from './componentsUtils';
import WYSWYGComponent from './WYSWYGComponent';

const {
  BANNER,
  CAROUSEL,
  TEXT: TEXT_COMPONENT,
  FAQ,
  CHALLENGE_TYPE,
  LINKED_IMAGE,
  PRODUCT_SECTION,
  REDEEM_POINTS,
  VIDEO_SECTION,
} = PageComponentEnum;
const filterNumber = CHALLENGE_TYPE * 10;
const {
  IMAGE,
  LINK,
  TEXT,
  VIDEO,
  WYSWYG,
  PRODUCT,
  PRODUCT_ITEM,
  USER_POINTS,
  FAQ_ITEM,
} = PageDetailEnum;

export default function ComponentDetail(props: DetailProps) {
  const {
    handleDeleteImages,
    accessToken,
    activeComponent,
    componentType,
    detail,
    form,
    handleDeleteDetail,
    handleEditDetail,
    index,
    comboItems,
    challengeType,
  } = props;
  const { position, slug, type, value, valueMobile, order } = detail;
  const { formatMessage } = useIntl();

  const positionButtonD =
    !activeComponent ||
    [
      CAROUSEL,
      BANNER,
      REDEEM_POINTS,
      PRODUCT_SECTION,
      VIDEO_SECTION,
      FAQ,
    ].includes(componentType) ||
    inRange(componentType, filterNumber, filterNumber + 10);
  const isFullWidth =
    [WYSWYG, PRODUCT, LINK].includes(type) ||
    (type === TEXT &&
      ([TEXT_COMPONENT, BANNER].includes(componentType) ||
        inRange(componentType, filterNumber, filterNumber + 10)));

  const isSlugDisabled = !activeComponent || [TEXT, LINK].includes(type);
  const rowGutter = componentType === VIDEO_SECTION ? 0 : 24;

  const isVideoSection = componentType === VIDEO_SECTION;
  const isLightVideo =
    isVideoSection && String(position) === VideoTypesEnum.LIGHT;
  /*------------ HANDLERS  ----------- */

  const handleEditWYS = (value: string) => {
    handleEditDetail({ index, key: 'value', value });
    let fieldKey = 'detail'.concat(index.toString());
    form.setFieldsValue({ [fieldKey]: value });
  };

  const handleSelectCombo = (value: string) => {
    handleEditDetail({ index, key: 'value', value });
  };

  const handleSelectPosition = (value: string) => {
    handleEditDetail({ index, key: 'position', value });
  };

  /*------------ DETAILS COMPONENTS ----------- */
  const buildSelector = (key: string): React.ReactNode => {
    return renderSelector({
      combo: comboItems,
      disabled: !activeComponent,
      form,
      showValue: key === 'redeemPointProduct',
      handleEdit: handleSelectCombo,
      formatMessage,
      key: key.concat(index.toString()),
      required: isVideoSection ? false : true,
      value,
    });
  };

  const renderImageComponent = ({
    value,
    key,
    forceDisable = false,
  }: {
    value?: any;
    key: 'position' | 'slug' | 'value' | 'valueMobile' | 'order';
    forceDisable?: boolean;
  }) => {
    return (
      <Form.Item label={key === 'value' ? 'DESKTOP IMAGE' : 'MOBILE IMAGE'}>
        <Upload
          {...{
            disabled: forceDisable || !activeComponent,
            setValue: function (value?: string | null) {
              handleEditDetail({ index, key, value });
              let fieldKey = 'detail'.concat(key, index.toString());
              form.setFieldsValue({ [fieldKey]: value });
            },
            token: accessToken,
            value,
            fileDimensions: setFileDimensions({
              componentType,
              desktop: key === 'value' ? true : false,
              position,
              type,
            }),
            handleChangeField: function () {
              handleDeleteImages(value, index, key);
            },
          }}>
          <Button
            className="uploadButton uploadButton__margin-top"
            disabled={forceDisable || !activeComponent}>
            <Icon type="upload" /> {formatMessage({ id: 'upload.buttonTitle' })}
          </Button>
        </Upload>
      </Form.Item>
    );
  };

  const renderOrderDetail = componentType === CAROUSEL && (
    <Form.Item label="ORDER">
      {form.getFieldDecorator('detail-order'.concat(index.toString()), {
        initialValue: order,
        rules: [
          {
            validator: (_: any, value: number) => {
              return checkDuplicatedOrders({ form, index, value });
            },
            message: 'Duplicated',
          },
        ],
      })(
        <InputNumber
          disabled={!activeComponent}
          min={1}
          max={6}
          onChange={(_value: number | undefined) => {
            handleEditDetail({
              index,
              key: 'order',
              value: _value ?? 1,
            });
          }}
        />,
      )}
    </Form.Item>
  );

  const imageDetail = () => {
    switch (componentType) {
      case CAROUSEL:
        return (
          <Row type="flex" className="Component__uploadRow" gutter={[24, 0]}>
            <Col xs={3}>{renderOrderDetail}</Col>
            <Col xs={21} md={11}>
              {renderImageComponent({ value, key: 'value' })}
            </Col>
            <Col xs={24} md={10}>
              {renderImageComponent({ value: valueMobile, key: 'valueMobile' })}
            </Col>
          </Row>
        );
      case BANNER:
      case LINKED_IMAGE:
        return (
          <Row type="flex" className="Component__uploadRow" gutter={[24, 0]}>
            <Col xs={24} md={12}>
              {renderImageComponent({ value, key: 'value' })}
            </Col>
            <Col xs={24} md={12}>
              {renderImageComponent({ value: valueMobile, key: 'valueMobile' })}
            </Col>
          </Row>
        );
      default:
        return (
          <Row className="Component__uploadRow">
            {renderImageComponent({ value, key: 'value' })}
          </Row>
        );
    }
  };

  const getTextDetail = () =>
    form.getFieldDecorator('detailA'.concat(index.toString()), {
      initialValue: value,
      rules: [
        {
          required: true,
          message: formatMessage({ id: 'update.required' }),
        },
      ],
    })(
      <TextArea
        disabled={!activeComponent}
        onBlur={(e) => {
          handleEditDetail({ index, key: 'value', value: e.target.value });
        }}
      />,
    );

  const getUserPointsDetail = () =>
    form.getFieldDecorator('detailA'.concat(index.toString()), {
      initialValue: value,
      rules: [
        {
          required: true,
          message: formatMessage({ id: 'update.required' }),
        },
      ],
    })(
      <TextArea
        disabled={!activeComponent}
        onBlur={(e) => {
          handleEditDetail({ index, key: 'value', value: e.target.value });
        }}
      />,
    );

  const carouselVideoDetail = () => (
    <Row gutter={[32, 0]}>
      <Col span={20}>
        <Form.Item label="Youtube video ID">
          {form.getFieldDecorator('detailB'.concat(index.toString()), {
            initialValue: value,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Input
              disabled={!activeComponent}
              onBlur={(e) => {
                handleEditDetail({
                  index,
                  key: 'value',
                  value: e.target.value,
                });
              }}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={4} style={{ textAlign: 'center' }}>
        <Form.Item label="Autoplay">
          {form.getFieldDecorator('autoplay'.concat(index.toString()), {
            initialValue: Boolean(position),
            valuePropName: 'checked',
          })(
            <Checkbox
              className="Component__checkbox-autoplay"
              disabled={!activeComponent}
              onChange={(e: CheckboxChangeEvent) => {
                handleEditDetail({
                  index,
                  key: 'position',
                  value: Number(e.target.checked),
                });
              }}
            />,
          )}
        </Form.Item>
      </Col>
    </Row>
  );

  const baseVideoDetail = () => (
    <Row gutter={[32, 0]}>
      <Col span={14}>
        <Form.Item label="YOUTUBE VIDEO ID">
          {form.getFieldDecorator('slug'.concat(index.toString()), {
            initialValue: slug,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Input
              disabled={!activeComponent}
              onBlur={(e) => {
                handleEditDetail({
                  index,
                  key: 'slug',
                  value: e.target.value,
                });
              }}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label="TYPE">
          {form.getFieldDecorator('type'.concat(index.toString()), {
            initialValue: String(position),
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            renderSelector({
              combo: videoTypeCombo,
              disabled: !activeComponent,
              form,
              handleEdit: handleSelectPosition,
              formatMessage,
              key: 'position'.concat(index.toString()),
              required: true,
              value: String(position),
            }),
          )}
        </Form.Item>
      </Col>
    </Row>
  );

  const getVideoDetail = () =>
    componentType === CAROUSEL ? (
      <Row type="flex" className="Component__uploadRow" gutter={[24, 0]}>
        <Col xs={3}>{renderOrderDetail}</Col>
        <Col xs={21}>{carouselVideoDetail()}</Col>
      </Row>
    ) : (
      baseVideoDetail()
    );

  const renderProductItemComponent = () => (
    <Row type="flex" className="Component__uploadRow" gutter={[24, 0]}>
      <Col xs={3}>
        <Form.Item label="ORDER">
          {form.getFieldDecorator('detail-order'.concat(index.toString()), {
            initialValue: order,
            rules: [
              {
                validator: (_: any, value: number) => {
                  return checkDuplicatedOrders({ form, index, value });
                },
                message: 'Duplicated',
              },
            ],
          })(
            <InputNumber
              disabled={!activeComponent}
              min={1}
              onChange={(_value: number | undefined) => {
                handleEditDetail({
                  index,
                  key: 'order',
                  value: _value ?? 1,
                });
              }}
            />,
          )}
        </Form.Item>
      </Col>
      <Col xs={21}>
        <Form.Item label="PRODUCT SELECTOR">
          {form.getFieldDecorator('value'.concat(index.toString()), {
            initialValue: value,
            rules: [
              {
                required: type === IMAGE,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            renderSelector({
              showValue: true,
              combo: comboItems,
              disabled: !activeComponent,
              form,
              handleEdit: handleSelectCombo,
              formatMessage,
              key: 'value',
              required: true,
              value: String(value),
            }),
          )}
        </Form.Item>
      </Col>
    </Row>
  );

  const wyswygDetail = activeComponent ? (
    <WYSWYGComponent
      title={formatMessage({ id: 'component.content' })}
      readonly={!activeComponent}
      handleEdit={handleEditWYS}
      value={value}
    />
  ) : (
    <Input.TextArea disabled value={value} />
  );

  const renderFaqItemComponent = () => {
    const arrayMaxLength = [...Array(MAX_FAQ_ITEMS).keys()].map((i) => i + 1);

    return (
      <Row type="flex" className="Component__faq-item" gutter={[24, 0]}>
        <Col xs={3}>
          <Form.Item label="ORDER">
            {form.getFieldDecorator('detail-order'.concat(index.toString()), {
              initialValue: order,
              rules: [
                {
                  validator: (_: any, value: number) => {
                    return checkDuplicatedFaqsOrders({ form, index, value });
                  },
                  message: 'Duplicated',
                },
              ],
            })(
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                disabled={!activeComponent}
                onChange={(_value: string | undefined) =>
                  handleEditDetail({
                    index,
                    key: 'order',
                    value: _value ?? 1,
                  })
                }>
                {arrayMaxLength?.map((option) => (
                  <Select.Option key={option} value={option.toString()}>
                    {option}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col xs={21}>
          <Form.Item label="QUESTION">
            {form.getFieldDecorator('question'.concat(index.toString()), {
              initialValue: valueMobile,
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              <TextArea
                disabled={!activeComponent}
                onBlur={(e) => {
                  handleEditDetail({
                    index,
                    key: 'valueMobile',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Form.Item>
          <WYSWYGComponent
            title={formatMessage({ id: 'challenge.answer' })}
            readonly={!activeComponent}
            handleEdit={handleEditWYS}
            value={value}
          />
        </Col>
      </Row>
    );
  };

  /*------------ RENDER DETAILS FUNCTIONS ----------- */

  const renderInput = () => {
    let detail;
    let label;

    switch (type) {
      case IMAGE:
        return imageDetail();
      case LINK:
        label = 'LINK';
        detail = buildSelector('detail');
        break;
      case TEXT:
        label = 'TEXT';
        detail = getTextDetail();
        break;
      case USER_POINTS:
        label = 'USER_POINTS';
        detail = getUserPointsDetail();
        break;
      case VIDEO:
        return getVideoDetail();
      case WYSWYG:
        return wyswygDetail;
      case PRODUCT_ITEM:
        return renderProductItemComponent();
      case PRODUCT:
        label = 'PRODUCT';
        detail = buildSelector('redeemPointProduct');
        break;
      case FAQ_ITEM:
        return renderFaqItemComponent();
      default:
        return;
    }
    return <Form.Item label={label}>{detail}</Form.Item>;
  };

  const getInputLength = () => {
    switch (true) {
      case isFullWidth:
        return { xs: 22, md: 22 };
      case componentType === VIDEO_SECTION:
        return { xs: 22, md: 8 };
      case positionButtonD:
        return { xs: 22, md: 16 };
      default:
        return { xs: 22, md: 13 };
    }
  };

  const renderDetail = () => {
    if (isFullWidth) return <Col {...getInputLength()}>{renderInput()}</Col>;

    return (
      <>
        <Col {...getInputLength()}>{renderInput()}</Col>
        {!positionButtonD && challengeType !== ChallengeTypeEnum.CAMPAIGN && (
          <Col xs={24} md={3}>
            <Form.Item label="POSITION">
              {renderSelector({
                combo: positionCombo,
                disabled: positionButtonD,
                form,
                handleEdit: handleSelectPosition,
                formatMessage,
                key: 'position'.concat(index.toString()),
                required: true,
                value: position,
              })}
            </Form.Item>
          </Col>
        )}
        {/* TODO: Enlazar con el combo de rutas que se utiliza en la rutas del menú */}
        {type !== TEXT &&
          ![PRODUCT_SECTION, VIDEO_SECTION, FAQ].includes(componentType) && (
            <Col xs={24} md={6}>
              <Row className="Component__uploadRow">
                <Form.Item
                  label={
                    isConPlatform() &&
                    componentType === CAROUSEL &&
                    type === IMAGE ? (
                      <span>
                        {'URL'}
                        <Tooltip
                          className="fieldTooltip"
                          title={formatMessage({
                            id: 'resourceType.carousel.image.tooltip.youtube',
                          })}>
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    ) : (
                      'URL'
                    )
                  }>
                  {form.getFieldDecorator('slug'.concat(index.toString()), {
                    initialValue: slug,
                    rules: [
                      {
                        required: type === IMAGE,
                        message: formatMessage({ id: 'update.required' }),
                      },
                    ],
                  })(
                    <Input
                      disabled={isSlugDisabled}
                      placeholder={isSlugDisabled ? '' : 'Page URL'}
                      onBlur={(e) => {
                        handleEditDetail({
                          index,
                          key: 'slug',
                          value: e.target.value,
                        });
                      }}
                    />,
                  )}
                </Form.Item>
              </Row>
            </Col>
          )}
        {isLightVideo && (
          <>
            <Col xs={24} md={7}>
              {renderImageComponent({
                value,
                key: 'value',
                forceDisable: !isLightVideo,
              })}
            </Col>
            <Col xs={24} md={7}>
              {renderImageComponent({
                value: valueMobile,
                key: 'valueMobile',
                forceDisable: !isLightVideo,
              })}
            </Col>
          </>
        )}
      </>
    );
  };

  return (
    <Row type="flex" gutter={[24, rowGutter]}>
      {componentType !== VIDEO_SECTION && (
        <Col xs={2} style={{ fontWeight: 600, placeSelf: 'center' }}>
          <Row type="flex" justify="center" align="top">
            <Form.Item>
              <Button
                className="deleteButton buttonNoFrame"
                disabled={!activeComponent}
                icon="minus-circle"
                onClick={() => {
                  handleDeleteDetail(index);
                }}
              />
            </Form.Item>
          </Row>
        </Col>
      )}
      {renderDetail()}
    </Row>
  );
}
