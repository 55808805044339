import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const MailingButtonsDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'mailingButtonsSearch',
      },
      groups: [],
      fields: [],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },

    {
      params: {
        type: 'table',
        componentId: 'mailingButtonsList',
        rowKey: 'idMailButton',
        panelHeader: 'MailButtons',
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0
      },
      fields: [
        {
          title: props.intl.formatMessage({ id: 'mailingButton.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: props.intl.formatMessage({ id: 'mailingButton.value' }),
          key: 'value',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
          precision: 0,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: '50',
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'mailingButtonsEdit',
        panelHeader: props.intl.formatMessage({ id: 'mailingButton.edit' }),
        primaryKey: 'idMailButton',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'value',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: props.intl.formatMessage({ id: 'mailingButton.name' }),
          key: 'name',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: true,
          length: 50,
        },
        {
          title: props.intl.formatMessage({ id: 'mailingButton.value' }),
          key: 'value',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: false,
          length: 100,
        },
        {
          title: props.intl.formatMessage({ id: 'mailingButton.id' }),
          key: 'idMailButton',
          type: 'number',
        },
        {
          title: props.intl.formatMessage({ id: 'mailingButton.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialDisabled: false,
          mandatory: true,
          behaviours: [
            [{ key: 'idMailButton', type: 'disabled', operand: 'notEmpty' }],
          ],
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
  ];

  const componentId = 'MailingButtonsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default MailingButtonsDashboard;
