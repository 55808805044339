import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import {
  isConsumablesDefaultPlatform,
  loadItem,
  PLATFORM_CODE_KEY,
} from '../utils';
import { ConsumableNatureEnum } from '../shared/enums/consumables.enum';

const ConsumableDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;
  const platformCode = loadItem(PLATFORM_CODE_KEY);

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'consumableSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'defaultName',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'nature',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'consumableId',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'consumable.name' }),
          key: 'defaultName',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'consumable.nature' }),
          key: 'nature',
          type: 'comboCustom',
          comboId: 'consumableNature',
          operand: 'equal',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'consumable.id' }),
          key: 'consumableId',
          type: 'number',
          operand: 'equal',
          globalSearch: true,
          mandatory: false,
          disabled: false,
          numberGlobalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: isConsumablesDefaultPlatform(),
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'consumableList',
        rowKey: 'id',
        panelHeader: formatMessage({ id: 'consumable.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'title',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: formatMessage({ id: 'consumable.id' }),
          key: 'id',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.parent.id' }),
          key: 'parentId',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.name' }),
          key: 'defaultName',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          with: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.image' }),
          key: 'defaultImage',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          with: SMALL,
        },
        {
          title: `${platformCode} name`,
          key: 'platformName',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          with: SMALL,
        },
        {
          title: `${platformCode} image`,
          key: 'platformImage',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          with: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.landing.title' }),
          key: 'defaultLanding',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.maxScans' }),
          key: 'maxScans',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'consumable.defaultScanPoints',
          }),
          key: 'defaultScanPoints',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'consumable.pointsOperator',
          }),
          key: 'defaultBonusPointsOperator',
          render: 'comboCustom',
          comboId: 'ConsumablePointsOperator',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.bonusPoints' }),
          key: 'defaultBonusPoints',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: `${platformCode} points`,
          key: 'platformScanPoints',
          position: 12,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: `${platformCode} points operator`,
          key: 'platformBonusPointsOperator',
          render: 'comboCustom',
          comboId: 'ConsumablePointsOperator',
          position: 13,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: `${platformCode} bonus points`,
          key: 'platformBonusPoints',
          position: 14,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'consumable.business.unit',
          }),
          key: 'businessUnit',
          position: 15,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.family' }),
          key: 'family',
          position: 16,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: 'Nature',
          key: 'nature',
          render: 'comboCustom',
          comboId: 'consumableNature',
          position: 17,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.treatment.days' }),
          key: 'treatmentDays',
          position: 18,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumable.expiration.date' }),
          key: 'expiration',
          position: 19,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
          render: 'datetime',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: isConsumablesDefaultPlatform(),
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'consumablePageEdit',
        panelHeader: 'Edit Consumable',
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'nature',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'natureType',
                  yPosition: 0,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'maxScans',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'treatmentDays',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'businessUnit',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'family',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'expiration',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'defaultLanding',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'defaultName',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'defaultImage',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'defaultScanPoints',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'defaultBonusPointsOperator',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'defaultBonusPoints',
                  yPosition: 4,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'defaultPricePoints',
                  yPosition: 4,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformName',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformImage',
                  yPosition: 5,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformScanPoints',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformBonusPointsOperator',
                  yPosition: 6,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformBonusPoints',
                  yPosition: 6,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platformPricePoints',
                  yPosition: 6,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'consumable.name' }),
          key: 'defaultName',
          type: 'text',
          unique: true,
          min: 0,
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: `${platformCode} name`,
          key: 'platformName',
          type: 'text',
          unique: true,
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'consumable.landing.title' }),
          key: 'defaultLanding',
          type: 'text',
          unique: false,
          min: 0,
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: formatMessage({ id: 'consumable.id' }),
          key: 'id',
          type: 'number',
          unique: true,
          min: 1,
          mandatory: true,
          disabled: false,
          disabledOnEdit: true,
        },
        {
          title: formatMessage({ id: 'consumable.parent.id' }),
          key: 'parentId',
          type: 'number',
          unique: false,
          min: 1,
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
          initialMandatory: false,
          initialDisabled: false,
          behaviours: isConsumablesDefaultPlatform()
            ? [
                [
                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI,
                    operand: 'like',
                  },
                ],
                [
                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI_SAP,
                    operand: 'like',
                  },
                ],
                [
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI_SAP,
                    operand: 'notLike',
                  },
                ],
              ]
            : [],
        },
        {
          title: formatMessage({ id: 'consumable.image' }),
          key: 'defaultImage',
          type: 'imageConsumable',
          unique: true,
          min: 0,
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
          module: 'consumable',
          initialMandatory: true,
          behaviours: [
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'like',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'like',
              },
            ],
          ],
        },
        {
          title: `${platformCode} image`,
          key: 'platformImage',
          type: 'imageConsumable',
          unique: true,
          min: 0,
          mandatory: false,
          disabled: false,
          module: 'consumable',
        },
        {
          title: formatMessage({ id: 'consumable.nature' }),
          key: 'nature',
          type: 'comboCustom',
          comboId: 'consumableNature',
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
          initialValue: ConsumableNatureEnum.PRODUCT,
          behaviours: [
            [
              {
                key: 'treatmentDays',
                value: '10',
                operand: 'like',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: 'Nature Type',
          key: 'natureType',
          type: 'comboCustom',
          comboId: 'ConsumableNatureTypes',
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
          initialDisabled: true,
          behaviours: isConsumablesDefaultPlatform()
            ? [
                [
                  {
                    key: 'nature',
                    value: ConsumableNatureEnum.PROMO,
                    operand: 'like',
                    type: 'disabled',
                  },
                ],
              ]
            : [],
        },
        {
          title: formatMessage({ id: 'consumable.defaultScanPoints' }),
          key: 'defaultScanPoints',
          type: 'number',
          min: 0,
          max: 9999,
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
          initialValue: 0,
          initialDisabled: true,
          behaviours: isConsumablesDefaultPlatform()
            ? [
                [
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI_SAP,
                    operand: 'notLike',
                  },
                ],
              ]
            : [],
        },
        {
          title: formatMessage({ id: 'consumable.pointsOperator' }),
          key: 'defaultBonusPointsOperator',
          type: 'comboCustom',
          comboId: 'ConsumablePointsOperator',
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
          initialDisabled: true,
          initialMandatory: true,
          behaviours: isConsumablesDefaultPlatform()
            ? [
                [
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI_SAP,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'defaultScanPoints',
                    value: 0,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'defaultScanPoints',
                    value: '',
                    operand: 'notLike',
                  },
                ],
                [
                  {
                    type: 'mandatory',
                    key: 'defaultScanPoints',
                    value: 0,
                    operand: 'like',
                  },
                ],
                [
                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.PRODUCT,
                    operand: 'notLike',
                  },

                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.PRODUCT_SAP,
                    operand: 'notLike',
                  },
                ],
              ]
            : [],
        },
        {
          title: formatMessage({ id: 'consumable.bonusPoints' }),
          key: 'defaultBonusPoints',
          type: 'number',
          min: 0,
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
          initialDisabled: true,
          max: 9999,
          initialMandatory: true,
          behaviours: isConsumablesDefaultPlatform()
            ? [
                [
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'nature',
                    value: ConsumableNatureEnum.DI_SAP,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'defaultScanPoints',
                    value: 0,
                    operand: 'notLike',
                  },
                  {
                    type: 'disabled',
                    key: 'defaultScanPoints',
                    value: '',
                    operand: 'notLike',
                  },
                ],
                [
                  {
                    type: 'mandatory',
                    key: 'defaultScanPoints',
                    value: 0,
                    operand: 'like',
                  },
                ],
                [
                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.PRODUCT,
                    operand: 'notLike',
                  },

                  {
                    type: 'mandatory',
                    key: 'nature',
                    value: ConsumableNatureEnum.PRODUCT_SAP,
                    operand: 'notLike',
                  },
                ],
              ]
            : [],
        },
        {
          title: `${platformCode} points`,
          key: 'platformScanPoints',
          type: 'number',
          min: 0,
          max: 9999,
          mandatory: false,
          disabled: false,
          tooltip: formatMessage({
            id: 'consumable.platformScanPoints.tooltip',
          }),
          initialDisabled: false,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'like',
              },
            ],
            [
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'like',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'consumable.price.points' }),
          key: 'defaultPricePoints',
          type: 'number',
          min: 0,
          mandatory: false,
          disabled: true,
          initialDisabled: true,
        },
        {
          title: `${platformCode} price points`,
          key: 'platformPricePoints',
          type: 'number',
          min: 0,
          mandatory: false,
          disabled: false,
          initialDisabled: true,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT_SAP,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.PROMO,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: `${platformCode} points operator`,
          key: 'platformBonusPointsOperator',
          type: 'comboCustom',
          comboId: 'ConsumablePointsOperator',
          mandatory: false,
          disabled: false,
          initialDisabled: true,
          initialMandatory: false,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT,
                operand: 'like',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT_SAP,
                operand: 'like',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: `${platformCode} bonus points`,
          key: 'platformBonusPoints',
          type: 'number',
          min: 0,
          max: 9999,
          mandatory: false,
          disabled: false,
          initialDisabled: true,
          initialMandatory: false,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT,
                operand: 'like',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.PRODUCT_SAP,
                operand: 'like',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: '',
                operand: 'notLike',
              },
              {
                type: 'mandatory',
                key: 'platformScanPoints',
                value: 0,
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'consumable.maxScans' }),
          key: 'maxScans',
          type: 'number',
          min: 0,
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: formatMessage({ id: 'consumable.treatment.days' }),
          key: 'treatmentDays',
          type: 'number',
          min: 0,
          initialValue: 15,
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
          initialDisabled: true,
          initialMandatory: false,
          behaviours: [
            [
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'notLike',
              },
              {
                type: 'disabled',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'notLike',
              },
            ],
            [
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.DI,
                operand: 'notLike',
              },
              {
                type: 'mandatory',
                key: 'nature',
                value: ConsumableNatureEnum.DI_SAP,
                operand: 'notLike',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'consumable.business.unit' }),
          key: 'businessUnit',
          type: 'string',
          mandatory: true,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: formatMessage({ id: 'consumable.family' }),
          key: 'family',
          type: 'number',
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: formatMessage({ id: 'consumable.expiration.date' }),
          key: 'expiration',
          type: 'datetime',
          mandatory: false,
          disabled: !isConsumablesDefaultPlatform(),
        },
        {
          title: `${platformCode} price points`,
          key: 'platformPricePoints',
          type: 'number',
          min: 0,
          mandatory: false,
          disabled: true,
          initialDisabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: isConsumablesDefaultPlatform(),
        canDelete: false,
      },
    },
  ];

  const componentId = 'ConsumableDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ConsumableDashboard;
