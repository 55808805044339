import { Col, Input, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Dispatch } from 'redux';
import { IRow } from '../app/AppInterfaces';
import { ComboData } from '../combos/ComboInterfaces';
import WYSWYGComponent from '../contentPage/components/WYSWYGComponent';
import { UpdateRecordProps } from './Challenge';
import { SetChallengeLoading } from './challengeActions';
import ChallengeHeaderButtons from './ChallengeHeaderButtons';
import { IResource } from './ChallengeInterfaces';
import { isConExperience } from './ChallengeUtils';
import { ChallengeTypeEnum } from './Enums';
import { ChallengeReplayForm } from './ChallengeReplayForm';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props extends FormComponentProps {
  intl: any;
  editingResource: boolean;
  selectedRow: IRow;
  values: IRow;
  handleEditComponent: (component: string, value: boolean) => void;
  handleChangeField: ({
    id,
    value,
    type,
  }: {
    id: string;
    value: any;
    type: string;
  }) => void;
  buttonDisabled: boolean;
  constructResources: (resources: IRow) => IResource[];
  formHasChanged: boolean;
  handleResetChallenge: () => void;
  handleUpdateRecord: (
    e: React.MouseEvent,
    props: UpdateRecordProps,
    form: WrappedFormUtils<any>,
  ) => Promise<void>;
  isLoading: boolean;
  setLoading: (
    isLoading: boolean,
  ) => (dispatch: Dispatch<SetChallengeLoading>) => void;
  sortingResources: boolean;
  updateProps: UpdateRecordProps;
  refreshChallengeData: () => Promise<void>;
  challengeCategories: ComboData[];
}

const myForm: FC<Props> = ({
  buttonDisabled,
  constructResources,
  editingResource,
  form,
  formHasChanged,
  handleChangeField,
  handleResetChallenge,
  handleUpdateRecord,
  intl,
  isLoading,
  selectedRow,
  setLoading,
  sortingResources,
  values,
  updateProps,
  refreshChallengeData,
  challengeCategories,
}) => {
  const { formatMessage } = intl;
  const { getFieldDecorator } = form;
  const isExperience = isConExperience(
    selectedRow?.idChallengeType?.idChallengeType,
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const preIZoomCode = values?.startDate
    ? moment(values?.startDate).format('YYYY_MM_')
    : 'YYYY_MM_';
  const postIZoomCode = `_${values?.idChallengeType?.name.toLowerCase()}`;
  const iZoomPosition = 2;
  const tailSplit = 3;

  return (
    <Form className="challengeForm">
      <ChallengeHeaderButtons
        {...{
          buttonDisabled,
          constructResources,
          form,
          formHasChanged,
          isLoading,
          handleResetChallenge,
          handleUpdateRecord,
          selectedRow,
          setLoading,
          sortingResources,
          updateProps,
          refreshChallengeData,
        }}
      />

      <Row>
        <div className="ChallengeBuilder__title">
          <Form.Item label={formatMessage({ id: 'challenge.name' })}>
            {getFieldDecorator('name', {
              initialValue: values ? values['name'] : '',
            })(
              <Input
                disabled={editingResource}
                style={{ clear: 'both' }}
                size="large"
                placeholder={formatMessage({
                  id: 'challenge.title.placeholder',
                })}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeField({
                    id: 'name',
                    type: 'text',
                    value: e.target.value,
                  })
                }
              />,
            )}
          </Form.Item>
        </div>
        <Row
          type="flex"
          gutter={[12, 12]}
          className="ChallengeBuilder__content">
          <Col xs={24} md={12}>
            <Form.Item label={formatMessage({ id: 'challenge.description' })}>
              {getFieldDecorator('description', {
                initialValue: values ? values['description'] : '',
              })(
                <Input
                  disabled={editingResource}
                  style={{ clear: 'both' }}
                  size="large"
                  placeholder={formatMessage({
                    id: 'challenge.description',
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField({
                      id: 'description',
                      type: 'text',
                      value: e.target.value,
                    })
                  }
                />,
              )}
            </Form.Item>
          </Col>
          {isExperience && (
            <>
              <Col xs={6} md={4}>
                <Form.Item
                  label={formatMessage({ id: 'challenge.descriptionShort' })}>
                  {getFieldDecorator('descriptionShort', {
                    initialValue: values ? values['descriptionShort'] : '',
                  })(
                    <Input
                      disabled={editingResource}
                      style={{ clear: 'both' }}
                      size="large"
                      placeholder={formatMessage({
                        id: 'challenge.descriptionShort',
                      })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeField({
                          id: 'descriptionShort',
                          type: 'text',
                          value: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={formatMessage({ id: 'challenge.challengeCategory' })}>
                  {getFieldDecorator('challengeCategory', {
                    initialValue: values ? values['challengeCategory'] : '',
                  })(
                    <Select
                      allowClear
                      showSearch
                      dropdownMatchSelectWidth={false}
                      disabled={editingResource}
                      onChange={(value: string | null) =>
                        handleChangeField({
                          type: 'combo',
                          id: 'challengeCategory',
                          value,
                        })
                      }>
                      {challengeCategories?.map((option) => (
                        <Select.Option
                          key={option.value}
                          value={option.value.toString()}>
                          {option.description}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </>
          )}
          {ChallengeTypeEnum.EXPERIENCE ===
            selectedRow?.idChallengeType?.idChallengeType && (
            <Col xs={6} md={2}>
              <Form.Item label={formatMessage({ id: 'challenge.idCampaign' })}>
                {getFieldDecorator('idCampaign', {
                  initialValue: values ? values['idCampaign'] : '',
                })(
                  <Input
                    disabled={editingResource}
                    style={{ clear: 'both' }}
                    size="large"
                    placeholder={formatMessage({
                      id: 'challenge.idCampaign',
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeField({
                        id: 'idCampaign',
                        type: 'text',
                        value: e.target.value,
                      })
                    }
                  />,
                )}
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={12}>
            <Form.Item label={formatMessage({ id: 'challenge.code' })}>
              {getFieldDecorator('code', {
                initialValue: values['code']
                  ? values['code'].split('_', tailSplit)[iZoomPosition]
                  : '',
              })(
                <Input
                  disabled={editingResource}
                  style={{ clear: 'both' }}
                  size="large"
                  addonAfter={postIZoomCode}
                  addonBefore={preIZoomCode}
                  placeholder={formatMessage({
                    id: 'challenge.code',
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const iZoomValue = e.target.value.replace(/_/gm, '-');
                    return handleChangeField({
                      id: 'code',
                      type: 'text',
                      value: `${preIZoomCode}${iZoomValue}${postIZoomCode}`,
                    });
                  }}
                />,
              )}
            </Form.Item>
          </Col>
          {isExperience ? (
            <Col xs={24}>
              <ChallengeReplayForm {...{ form, values, handleChangeField }} />
            </Col>
          ) : null}
        </Row>
      </Row>
      <WYSWYGComponent
        title={formatMessage({ id: 'challenge.descriptionLong' })}
        readonly={editingResource}
        height={300}
        handleEdit={(value) =>
          handleChangeField({ id: 'descriptionLong', type: 'text', value })
        }
        value={values['descriptionLong']}
      />
    </Form>
  );
};

export default Form.create<Props>({ name: 'challenge_form' })(myForm);
