export enum editTypes {
  EDIT_INITIAL_STATE = '[EDIT] set initial state',
  EDIT_SET_VALUES = '[EDIT] load data',
  EDIT_SENDING_DATA = '[EDIT] sending data',
  EDIT_SENT_DATA = '[EDIT] sent data',
  EDIT_SENDING_DATA_ERROR = '[EDIT] sending data error',
  EDIT_FETCHING_DATA = '[EDIT] fetching foreign data',
  EDIT_FETCHED_DATA = '[EDIT] fetched foreign data',
  EDIT_CREATING_DATA = '[EDIT] creating data',
  EDIT_CREATED_DATA = '[EDIT] created data',
  EDIT_SET_FIELDSCONFIG = '[EDIT] set fieldsConfig',
  SET_INITIAL_EDIT_STATE = '[EDIT] set initial component state',
  EDIT_CHANGE_TAB = '[EDIT] tab changed',
  EDIT_RESET_FORM = '[EDIT] reset edit form',
  EDIT_CLEAN_RESET_FORM_TRIGGER = '[EDIT] reset clean component',
  EDIT_RESET_COMPONENT = '[EDIT] reset edit form component',
  EDIT_FETCH_PARENT_DATA = '[EDIT] Fetch initial parent foreignData'
}
