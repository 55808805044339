import React, { Fragment, useState } from 'react';
import { Button, Modal, Row } from 'antd';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';
import { isEmpty } from 'lodash';
import { OrderDetails } from './OrderDetails';
import { useIntl } from 'react-intl';

export default function RequestOrderButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const handleOpen = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  return (
    <>
      <Button
        onClick={() => handleOpen()}
        disabled={isEmpty(selectedRow) || !selectedRow?.requestContent}
        type="primary">
        {formatMessage({ id: 'request-order.button' })}
      </Button>
      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={handleClose}
        onOk={handleClose}
        className="request-order-modal">
        <Row type="flex" align="middle">
          <div className="request-order-modal__title">
            {formatMessage({ id: 'request-order.button' })}
          </div>
          <OrderDetails requestContent={selectedRow.requestContent} />
        </Row>
      </Modal>
    </>
  );
}
