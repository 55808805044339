import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const { CHECKOUT_DONATION_PRODUCT } =
  ResourceTypeDetailEnum;

type Props = ReturnType<typeof mapStateToProps> & IResourceTree;

const CheckoutDonation = ({
  accessToken,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
}: Props): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });
  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  return (
    <>
    
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === CHECKOUT_DONATION_PRODUCT) {
            combo =
              combos.challengechallengeEdit?.checkoutAddressType
                ?.checkoutAddressType?.data ?? [];
          }

          return RenderDetail({
            accessToken,
            combo,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            handleDeleteImages,
            resource,
            mainIdResource: resource.idResource,
            values,
          });
        })}
    </>
  );
};
const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};
export default connect(mapStateToProps, {})(CheckoutDonation);
