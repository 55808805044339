import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { Dispatch } from 'redux';

import { contentPageTypes } from './contentPageTypes';
import { IKey, IPageComponent, IPageDetail } from '../shared';
import { initialState } from './contentPageReducer';
import { updateSelectedRow } from '../../tables/tableActions';
import { ReducersState } from '../../reducers';
import { isEmpty } from 'lodash';
import { ComboData } from '../../combos/ComboInterfaces';

/* ----------------- REDUX INTERFACE ----------------- */
export interface AddDetail {
  type: contentPageTypes.PAGE_ADD_DETAIL;
  payload: { selectedComponent: IPageComponent; componentHasChanged: boolean };
}

interface CreateComponent {
  type: contentPageTypes.PAGE_CREATE_COMPONENT;
  payload: {
    isLoading: boolean;
  };
}

interface DeleteComponent {
  type: contentPageTypes.PAGE_DELETE_COMPONENT;
  payload: { isLoading: boolean };
}

interface DeleteDetail {
  type: contentPageTypes.PAGE_DELETE_DETAIL;
  payload: { componentHasChanged: boolean; selectedComponent: IPageComponent };
}

interface EditDetail {
  type: contentPageTypes.PAGE_EDIT_DETAIL;
  payload: { componentHasChanged: boolean; selectedComponent: IPageComponent };
}

interface ResetComponent {
  type: contentPageTypes.PAGE_RESET_COMPONENT;
  payload: {
    componentHasChanged: boolean;
    editComponent: boolean;
    selectedComponent: IPageComponent;
  };
}
interface SaveSorting {
  type: contentPageTypes.PAGE_SAVE_SORTING;
  payload: {
    isLoading: boolean;
    sortingComponents: boolean;
  };
}

interface SetComponentHasChanged {
  type: contentPageTypes.PAGE_SET_COMPONENT_CHANGED;
  payload: { componentHasChanged: boolean };
}

interface SetEditComponent {
  type: contentPageTypes.PAGE_EDITING_COMPONENT;
  payload: { editComponent: boolean };
}

interface SetLoading {
  type: contentPageTypes.PAGE_SET_LOADING;
  payload: { isLoading: boolean };
}

interface SetPageInitialState {
  type: contentPageTypes.PAGE_INITIAL_STATE;
  payload: {
    componentHasChanged: boolean;
    editComponent: boolean;
    isLoading: boolean;
    selectedComponent: IPageComponent;
    sortingComponents: boolean;
    challengeTypeCombo: ComboData[];
  };
}

interface SetSelectedComponent {
  type: contentPageTypes.PAGE_SET_SELECTED_COMPONENT;
  payload: { selectedComponent: IPageComponent };
}

interface SetSortingComponents {
  type: contentPageTypes.PAGE_SORTING_COMPONENTS;
  payload: {
    sortingComponents: boolean;
  };
}

interface UpdateComponent {
  type: contentPageTypes.PAGE_UPDATE_COMPONENT;
  payload: {
    isLoading: boolean;
    componentHasChanged: boolean;
  };
}

export type ContentPageActionTypes =
  | AddDetail
  | EditDetail
  | CreateComponent
  | DeleteComponent
  | DeleteDetail
  | ResetComponent
  | SaveSorting
  | SetEditComponent
  | SetLoading
  | SetPageInitialState
  | SetSelectedComponent
  | SetSortingComponents
  | SetComponentHasChanged
  | UpdateComponent;

/* ----------------- REDUX ACTIONS ----------------- */

export const addDetail =
  (newDetail: IPageDetail) =>
  (dispatch: Dispatch<AddDetail>, getState: () => ReducersState) => {
    const selectedComponent = { ...getState().contentPages.selectedComponent };
    selectedComponent.componentDetails.push(newDetail);

    dispatch({
      type: contentPageTypes.PAGE_ADD_DETAIL,
      payload: { selectedComponent, componentHasChanged: true },
    });
  };

export const createComponent =
  (data: { idContentPage: number; type: number; order: number }) =>
  async (dispatch: Dispatch<SetLoading | CreateComponent>) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const response = await api.postDataCall({
        dataPath: apiPaths.CALL.PAGE_COMPONENT,
        data,
      });

      if (response.data)
        dispatch({
          type: contentPageTypes.PAGE_CREATE_COMPONENT,
          payload: { isLoading: false },
        });
      return { action: 'create', status: response.status, data: response.data };
    } catch {
      dispatch({
        type: contentPageTypes.PAGE_CREATE_COMPONENT,
        payload: { isLoading: false },
      });
    }
  };

export const deleteComponent =
  (idContentComponent: number) =>
  async (dispatch: Dispatch<DeleteComponent | SetLoading>) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const response = await api.deleteDataCallById({
        dataPath: apiPaths.CALL.PAGE_COMPONENT,
        registerId: idContentComponent,
        callConfig: {},
      });
      dispatch({
        type: contentPageTypes.PAGE_DELETE_COMPONENT,
        payload: { isLoading: false },
      });

      const status = {
        action: 'delete',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: contentPageTypes.PAGE_DELETE_COMPONENT,
        payload: { isLoading: false },
      });

      if (!err.response) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

export const deleteDetail =
  (index: number) =>
  (dispatch: Dispatch<DeleteDetail>, getState: () => ReducersState) => {
    const selectedComponent = { ...getState().contentPages.selectedComponent };

    if (selectedComponent.componentDetails[index].idContentPageDetail) {
      selectedComponent.componentDetails[index].status = false;
    } else {
      selectedComponent.componentDetails.splice(index, 1);
    }
    dispatch({
      type: contentPageTypes.PAGE_DELETE_DETAIL,
      payload: { componentHasChanged: true, selectedComponent },
    });
  };

export const editDetail =
  ({ index, key, value }: { index: number; key: IKey; value: any }) =>
  (dispatch: Dispatch<EditDetail>, getState: () => ReducersState) => {
    const selectedComponent = getState().contentPages.selectedComponent;
    if (!selectedComponent?.componentDetails?.[index]) return;
    if (key === 'order') {
      if (!value) selectedComponent.componentDetails[index][key] = 0;
      if (typeof value === 'string')
        selectedComponent.componentDetails[index][key] = parseInt(value);
      else if (typeof value === 'number')
        selectedComponent.componentDetails[index][key] = value;
      // @ts-ignore
    } else selectedComponent.componentDetails[index][key] = value;
    dispatch({
      type: contentPageTypes.PAGE_EDIT_DETAIL,
      payload: { componentHasChanged: true, selectedComponent },
    });
  };

export const resetComponent = () => (dispatch: Dispatch<ResetComponent>) => {
  dispatch({
    type: contentPageTypes.PAGE_RESET_COMPONENT,
    payload: {
      editComponent: false,
      componentHasChanged: false,
      selectedComponent: {} as IPageComponent,
    },
  });
};

export const saveSorting =
  (
    idContentPage: number,
    primaryKey: string,
    componentListId: string,
    components: { idContentComponent: number; order: number }[],
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_LOADING,
      payload: { isLoading: true },
    });

    try {
      const response = await api.putDataCall({
        dataPath: `${apiPaths.CALL.CONTENT_PAGE}/order`,
        data: { idContentPage, components },
        callConfig: {},
      });

      if ('status' in response && response.status === 200)
        await dispatch(
          updateSelectedRow(response.data, primaryKey, componentListId),
        );

      dispatch({
        type: contentPageTypes.PAGE_SAVE_SORTING,
        payload: { isLoading: false, sortingComponents: false },
      });
    } catch (err) {
      dispatch({
        type: contentPageTypes.PAGE_SAVE_SORTING,
        payload: { isLoading: false, sortingComponents: false },
      });
    }
  };

export const setComponentHasChanged =
  (componentHasChanged: boolean) =>
  (dispatch: Dispatch<SetComponentHasChanged>) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_COMPONENT_CHANGED,
      payload: { componentHasChanged },
    });
  };

export const setEditComponent =
  (editComponent: boolean) => (dispatch: Dispatch<SetEditComponent>) => {
    dispatch({
      type: contentPageTypes.PAGE_EDITING_COMPONENT,
      payload: { editComponent },
    });
  };

export const setLoading =
  (isLoading: boolean) => (dispatch: Dispatch<SetLoading>) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_LOADING,
      payload: { isLoading },
    });
  };

export const setPageInitialState =
  () =>
  async (
    dispatch: Dispatch<SetPageInitialState>,
    getState: () => ReducersState,
  ) => {
    let challengeTypeCombo = getState().contentPages.challengeTypeCombo;
    let productsCombo = getState().contentPages.productsCombo;
    let gridProductsCombo = getState().contentPages.gridProductsCombo;

    if (isEmpty(gridProductsCombo)) {
      try {
        const qrProductsResponse = await api.getCombo({
          id: 'gridProducts',
        });
        gridProductsCombo = qrProductsResponse.data;
      } catch (error) {
        console.error(error);
      }
    }

    if (isEmpty(productsCombo)) {
      try {
        const productsResponse = await api.getCombo({
          id: 'redeemPointsProductCombo',
        });
        productsCombo = productsResponse.data;
      } catch (error) {
        console.error(error);
      }
    }

    dispatch({
      type: contentPageTypes.PAGE_INITIAL_STATE,
      payload: {
        ...initialState,
        challengeTypeCombo,
        productsCombo,
        gridProductsCombo,
      },
    });
  };

export const setSelectedComponent =
  (component: IPageComponent) => (dispatch: Dispatch<SetSelectedComponent>) => {
    const selectedComponent =
      component !== undefined
        ? JSON.parse(JSON.stringify(component))
        : ({} as IPageComponent);

    dispatch({
      type: contentPageTypes.PAGE_SET_SELECTED_COMPONENT,
      payload: { selectedComponent },
    });
  };

export const setSortingComponents =
  (sortingComponents: boolean) =>
  (dispatch: Dispatch<SetSortingComponents>) => {
    dispatch({
      type: contentPageTypes.PAGE_SORTING_COMPONENTS,
      payload: { sortingComponents },
    });
  };

export const updateComponent =
  (component: IPageComponent) =>
  async (dispatch: Dispatch<SetLoading | UpdateComponent>) => {
    dispatch({
      type: contentPageTypes.PAGE_SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.PAGE_COMPONENT_RECURSIVE,
        data: { ...component },
        callConfig: {},
      });
      dispatch({
        type: contentPageTypes.PAGE_UPDATE_COMPONENT,
        payload: { isLoading: false, componentHasChanged: false },
      });
      const status = {
        action: 'update',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: contentPageTypes.PAGE_SET_LOADING,
        payload: { isLoading: false },
      });
      if (!err.response) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };
