import config from '../config';
import { isEmpty } from 'lodash';
import { Column } from '../tables/tableInterfaces';
import { GlobalSearch } from '../forms/search/searchReducer';
import { ISearchField, AutocompleteConfig } from '../fields/FieldsInterfaces';

const getOperator = (operand: string | undefined): string => {
  switch (operand) {
    case 'like':
      return ':';
    case 'ilike':
      return ':i:';
    case 'equal':
      return '::';
    case 'notequal':
      return '!';
    case 'greater':
      return '@';
    case 'less':
      return '-';
    case 'greater|equal':
      return '*';
    case 'less|equal':
      return '$';
    default:
      return ':';
  }
};

/**
 *
 * It allow go into inside the fields and replace the key
 * @param {*} fields
 * @param {*} values
 */
export const qComposer = (
  fields: ISearchField[],
  values: object,
  globalSearchField: GlobalSearch
) => {
  const searchCriteriaFieldIndex = fields.findIndex(field => field.isSearchCriteria);
  if (searchCriteriaFieldIndex >= 0) {
    globalSearchField.fieldTargets = [values[fields[searchCriteriaFieldIndex].key as keyof object]];
  }

  if (!isEmpty(values)) {
    let qArray: string[] = [];
    let newValues: { [key: string]: string } = { ...values };
    fields.filter(field => !field.isSearchCriteria).forEach(f => {
      if (f.type === 'rangerpicker') {
        for (let key in newValues) {
          if (key === f.key) {
            const index = newValues[key].indexOf('.');
            const firstDate = newValues[key].slice(0, index);
            const secondDate = newValues[key].slice(index + 1);
            qArray.push(key + '*' + firstDate);
            qArray.push(key + '$' + secondDate);
          }
        }
      } else {
        if (
          !isEmpty(f.operand) &&
          newValues[f.key] !== null &&
          newValues[f.key] !== undefined &&
          newValues[f.key] !== ''
        ) {
          const operator = getOperator(f.operand);
          qArray.push(f.key + operator + newValues[f.key]);
        }
      }
    });
    let queryString = qArray.join(config.QUERY.AND);
    if (newValues['textSearch']) {
      let globalArray: string[] = [];
      globalSearchField.fieldTargets.forEach(target => {
        const fieldOperator = fields.find(field => field.key === target)?.operand;
        const operator = getOperator(fieldOperator);
        globalArray.push([target] + operator + newValues['textSearch'].replaceAll(',', ''));
      });
      if (!isEmpty(globalArray))
        queryString += isEmpty(queryString)
          ? globalArray.join(config.QUERY.OR)
          : config.QUERY.AND + globalArray.join(config.QUERY.OR);
    }
    return queryString;
  }
};

export const switchMenuState = (
  pathname: string,
  menuState: number | string
) => {
  if (pathname === '/Home') {
    return 0;
  } else {
    switch (menuState) {
      case 0:
        return 1;
      case 1:
        return window.innerWidth < config.BREAKPOINTS.SM ? 0 : 2;
      case 2:
        return 1;
      default:
        return 1;
    }
  }
};

export const qInputComposer = (
  autocompleteConfig: AutocompleteConfig,
  value: string
) => {
  const { fieldTargets } = autocompleteConfig;
  if (!isEmpty(value)) {
    let qArray: string[] = [];
    fieldTargets.forEach(target => {
      if (
        !isEmpty(autocompleteConfig.operand) &&
        value !== null &&
        value !== undefined &&
        value !== ''
      ) {
        const operator = getOperator(autocompleteConfig.operand);
        qArray.push(target + operator + value);
      }
    });
    return qArray.join(config.QUERY.OR);
  }
};

export const filterComposer = (columns: Column[]) => {
  return columns
    .filter(function (c) {
      return !isEmpty(c.filter);
    })
    .map(c => {
      return c.key + ':' + c.filter;
    })
    .join();
};

/**
 * Parse a JS File Object to a Base64 String representation
 * @param {File} file - File object.
 * @returns A promise that contains the String representation of the file or an error.
 */
export const fileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
