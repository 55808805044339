import { IRow } from '../app/AppInterfaces';
import { MenuEnum } from '../shared';

export const getMutatedValues = (componentId: string, values: IRow) => {
  switch (componentId) {
    case 'menumenuEdit':
      return mutateMenuValues(values);
    default:
      return values;
  }
};

export const mutateMenuValues = (values: IRow) => {
  const mutatedValues = { ...values };

  switch (mutatedValues.type) {
    case parseInt(MenuEnum.PUBLIC_FOOTER_PARENT):
    case parseInt(MenuEnum.PRIVATE_FOOTER_PARENT):
    case MenuEnum.PUBLIC_FOOTER_PARENT:
    case MenuEnum.PRIVATE_FOOTER_PARENT:
      mutatedValues.disabled = 'false';
      mutatedValues.visible = 'true';
      mutatedValues.parentId = null;
      mutatedValues.contentPageId = null;
      mutatedValues.route = null;
      mutatedValues.absolute = 'false';
      break;
    default:
      break;
  }

  return mutatedValues;
};
