import { comboTypes } from './comboTypes';
import { ComboData } from './ComboInterfaces';
import * as api from '../api';
import { Dispatch } from 'redux';

interface InitializeComponentCombos {
  type: comboTypes.COMBO_INITIALIZE_COMPONENT;
  payload: { componentId: string };
}
export const initializeComponentCombos =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<InitializeComponentCombos>) => {
    dispatch({
      type: comboTypes.COMBO_INITIALIZE_COMPONENT,
      payload: { componentId },
    });
  };

interface GetComboDataFetching {
  type: comboTypes.COMBO_FETCHING_DATA;
  payload: {
    comboId: string;
    componentId: string;
    fieldKey: string;
    isLoading: boolean;
  };
}

interface GetComboDataFetched {
  type: comboTypes.COMBO_FETCHED_DATA;
  payload: {
    componentId: string;
    data: ComboData[];
    fieldKey: string;
    isLoading: boolean;
    comboId: string;
  };
}

interface InitializeCombo {
  type: comboTypes.COMBO_INITIALIZE_COMBO;
  payload: { componentId: string; fieldKey: string };
}

export const getComboData =
  ({
    dataPath,
    componentId,
    fieldKey,
    queryParams = {},
    comboId,
  }: {
    dataPath: string;
    componentId: string;
    fieldKey: string;
    queryParams: object;
    comboId: string;
  }) =>
  async (
    dispatch: Dispatch<
      GetComboDataFetching | InitializeCombo | GetComboDataFetched
    >,
  ) => {
    dispatch({
      type: comboTypes.COMBO_INITIALIZE_COMBO,
      payload: { componentId, fieldKey },
    });
    dispatch({
      type: comboTypes.COMBO_FETCHING_DATA,
      payload: { componentId, fieldKey, comboId, isLoading: true },
    });
    const callConfig = {
      params: queryParams,
    };
    try {
      const response = await api.getDataCall({ dataPath, callConfig });
      if (response.data)
        dispatch({
          type: comboTypes.COMBO_FETCHED_DATA,
          payload: {
            componentId,
            fieldKey,
            comboId,
            data: response.data,
            isLoading: false,
          },
        });
      const status = { action: 'fetch', status: response.status };
      return status;
    } catch (err) {
      if (!err.response) return { action: 'fetch', status: {} };
      const status = { action: 'fetch', status: err.response.status };
      return status;
    }
  };

interface AddCustomCombos {
  type: comboTypes.COMBO_ADD_CUSTOM;
  payload: { data: any };
}

export const addCustomCombos =
  (data: any) => (dispatch: Dispatch<AddCustomCombos>) => {
    dispatch({
      type: comboTypes.COMBO_ADD_CUSTOM,
      payload: { data },
    });
  };

export type ComboActionTypes =
  | GetComboDataFetching
  | GetComboDataFetched
  | AddCustomCombos
  | InitializeComponentCombos
  | InitializeCombo;
