export enum ScanLandingComponentTypesEnum {
  MAIN = 'main',
  IMAGE = 'image',
  IMAGE_BANNER = 'image-banner',
  VIDEO = 'video',
  VIDEO_BANNER = 'video-banner',
  REVIEW = 'review',
  HOW_TO_USE = 'how-to-use',
  RESULTS = 'results',
}

export enum ScanLandingMainComponentTypesEnum {
  IMAGE = 'image',
  PRODUCT = 'product',
}
