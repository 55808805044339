import MassPointsManagementButton, {
  MassPointsManagementPlatformEnum,
} from './MassPointsManagement';
import React from 'react';

export default function LiconMassPointsManagementButton() {
  return (
    <MassPointsManagementButton
      platform={MassPointsManagementPlatformEnum.LICON}
    />
  );
}
