import { Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import { IRow } from '../../../app/AppInterfaces';

const {
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_TYPE
} = ResourceTypeDetailEnum;

interface ICheckoutPaymentGatewayProduct {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ICheckoutPaymentGatewayProduct;

const CheckoutPaymentGatewayProduct: FC<IGroupProps> = ({
  _resource,
  accessToken,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  groupIndex,
  mainIdResource,
  values
}) => {
  const { resourceDetailList } = _resource;

  const checkoutProductPaymentGatewayDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) checkoutProductPaymentGatewayDetails.push({ index, detail });
  });

  if (!checkoutProductPaymentGatewayDetails) return <Fragment />;

  return (
    <Row gutter={[24, 0]}>
      {checkoutProductPaymentGatewayDetails
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ detail, index }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;

          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === CHECKOUT_PAYMENT_GATEWAY_PRODUCT_TYPE) {
            combo =
              combos.challengechallengeEdit?.checkoutProductType
                ?.checkoutProductType?.data ?? [];
          }

          return RenderDetail({
            accessToken,
            combo,
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            values
          });
        })}
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentGatewayProduct);
