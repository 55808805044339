import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import {
  IndexedDetail,
  IResourceDetail,
  IResourceTree,
} from '../../ChallengeInterfaces';
import {
  ExternalRedeemPointsTypeEnum,
  ResourceTypeDetailEnum,
} from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';
import { Divider, Row } from 'antd';
import { useIntl } from 'react-intl';
import '../Collapsible';
import { editRecursiveResource } from '../../challengeActions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

const {
  EXTERNAL_REDEEM_POINTS_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TYPE,
  EXTERNAL_REDEEM_POINTS_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL,
} = ResourceTypeDetailEnum;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IResourceTree;

const ExternalRedeemPoints = ({
  accessToken,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];
  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  const setDisabledDetails = (detail: IResourceDetail): boolean => {
    const { idResourceTypeD } = detail.idResourceTypeD;

    let isEnabled: boolean =
      resource.resourceDetailList?.some(
        (detail: IResourceDetail) =>
          detail.value === ExternalRedeemPointsTypeEnum.EXTERNAL_REDEEM_ISD,
      ) ?? false;

    if (
      !isEnabled &&
      [
        EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
        EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
      ].includes(idResourceTypeD)
    ) {
      isEnabled = false;
    } else isEnabled = true;

    return isEnabled;
  };

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  details.sort(
    ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
      orderA - orderB,
  );

  function buildSectionTittle(sectionTitle: string): JSX.Element {
    return (
      <>
        <Divider className="resourceDivider" />
        <Row className="header">
          {formatMessage({
            id: sectionTitle,
          })}
        </Row>
      </>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Row className="external-redeem-points">
          {details
            .filter((detail) => {
              const { idResourceTypeD } = detail.detail.idResourceTypeD;
              return [
                EXTERNAL_REDEEM_POINTS_DESCRIPTION,
                EXTERNAL_REDEEM_POINTS_TYPE,
                EXTERNAL_REDEEM_POINTS_BUTTON_LABEL,
              ].includes(idResourceTypeD);
            })
            .map(({ index, detail }) => {
              const { idResourceTypeD } = detail.idResourceTypeD;
              let combo: ComboData[] = [];

              if (idResourceTypeD === EXTERNAL_REDEEM_POINTS_TYPE) {
                combo =
                  combos.challengechallengeEdit?.externalRedeemPointsType
                    ?.externalRedeemPointsType?.data ?? [];
              }

              const renderDetailProps = {
                accessToken,
                combo,
                detail,
                detailArrayPosition: index,
                editRecursiveResource,
                editResource,
                form,
                handleDeleteImages,
                resource,
                mainIdResource: resource.idResource,
                values,
              };

              return RenderDetail(renderDetailProps);
            })}
        </Row>

        <Row className="external-redeem-points">
          {details
            .filter((detail) => {
              const { idResourceTypeD } = detail.detail.idResourceTypeD;
              return [
                EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
                EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE,
                EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL,
              ].includes(idResourceTypeD);
            })
            .map(({ index, detail }) => {
              const { idResourceTypeD } = detail.idResourceTypeD;
              const renderDetailProps = {
                accessToken,
                combos,
                detail,
                detailArrayPosition: index,
                editRecursiveResource,
                editResource: editResource
                  ? setDisabledDetails(detail)
                  : editResource,
                form,
                handleDeleteImages,
                resource,
                mainIdResource: resource.idResource,
                values,
              };

              return (
                <>
                  {idResourceTypeD ===
                    EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION &&
                    buildSectionTittle('external_redeem_points_tc_modal_label')}
                  <>{RenderDetail(renderDetailProps)}</>
                </>
              );
            })}
        </Row>

        <Row className="external-redeem-points">
          {details
            .filter((detail) => {
              const { idResourceTypeD } = detail.detail.idResourceTypeD;
              return [
                EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE,
                EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION,
                EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
                EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL,
              ].includes(idResourceTypeD);
            })
            .map(({ index, detail }) => {
              const { idResourceTypeD } = detail.idResourceTypeD;
              const renderDetailProps = {
                accessToken,
                combo: undefined,
                detail,
                detailArrayPosition: index,
                editRecursiveResource,
                editResource: editResource
                  ? setDisabledDetails(detail)
                  : editResource,
                form,
                handleDeleteImages,
                resource,
                mainIdResource: resource.idResource,
                values,
              };

              return (
                <>
                  {idResourceTypeD ===
                    ResourceTypeDetailEnum.EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE &&
                    buildSectionTittle(
                      'external_redeem_points_complete_modal_label',
                    )}
                  <>{RenderDetail(renderDetailProps)}</>
                </>
              );
            })}
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalRedeemPoints);
