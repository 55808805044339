import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const DermoAdminDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'dermoAdmindermoAdminSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'email',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idCenter.name',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idRegion.name',
                  yPosition: 0,
                  xPosition: 3,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'dermo.email' }),
          key: 'email',
          type: 'text',
          operand: 'like',
          min: 0,
          length: 50,
          mandatory: false,
          disabled: false,
          initialValue: '',
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'dermo.center' }),
          key: 'idCenter.name',
          type: 'text',
          operand: 'like',
          min: 0,
          length: 50,
          mandatory: false,
          disabled: false,
          initialValue: '',
        },
        {
          title: formatMessage({ id: 'dermo.region' }),
          key: 'idRegion.name',
          type: 'text',
          operand: 'like',
          min: 0,
          length: 50,
          mandatory: false,
          disabled: false,
          initialValue: '',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'dermoAdmindermoAdminList',
        rowKey: 'email',
        panelHeader: 'Dermo Users List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'users.email' }),
          key: 'email',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,   
        },
        {
          title: formatMessage({ id: 'dermo.center' }),
          key: 'idCenter.name',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          // defaultSortOrder: 'ASC',
        },
        {
          title: formatMessage({ id: 'dermo.region' }),
          key: 'idRegion.name',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'dermoAdmindermoAdminEdit',
        panelHeader: formatMessage({ id: 'dermo.pos.edit' }),
        primaryKey: 'idUser',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idDermoCenter',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'idDermoRegion',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                }
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'dermo.center' }),
          key: 'idDermoCenter',
          type: 'combo',
          comboId: 'dermoAdminCenter',
        },
        {
          title: formatMessage({ id: 'dermo.region' }),
          key: 'idDermoRegion',
          type: 'combo',
          comboId: 'regionsByCenter',
          behaviours: [
            [
             {
               key: 'idDermoCenter',
               type: 'combo',
               params: true,
             },
           ],
          ]
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: false,
        canDelete: false,
      },
    },
  ];

  const componentId = 'DermoAdminDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default DermoAdminDashboard;