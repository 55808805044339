import { Button, Form, Icon, Upload, notification } from 'antd';
import {
  UploadChangeParam as AntdUploadChangeParam,
  UploadFile as AntdUploadFile,
  UploadProps as AntdUploadProps,
} from 'antd/lib/upload/interface';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../../config';
import { ReducersState } from '../../../reducers';
import { IUploadMediaCommandRenderProps } from './commands';
import { PLATFORM_CODE_KEY, loadItem } from '../../../utils';

type Props = ReturnType<typeof mapStateToProps> &
  IUploadMediaCommandRenderProps;

function UploadMediaComponent({
  accessToken,
  isDisabled,
  setValue,
  value,
  children,
  requestUrl,
  fileTypes,
  form,
}: Props): JSX.Element {
  const [fileList, setFileList] = useState<AntdUploadFile[]>([]);
  const parsedValue = JSON.parse(value);

  useEffect(() => {
    if (parsedValue.url?.length > 0) {
      setFileList([
        {
          uid: '-1',
          name: parsedValue.url,
          status: 'done',
          url: parsedValue.url,
        } as AntdUploadFile,
      ]);
    }
  }, [parsedValue.url]);

  const isMediaLoaded = fileList.length > 0;

  const handleChange = (info: AntdUploadChangeParam) => {
    setFileList(info.fileList);

    if (info.file.status === 'done') {
      notification.success({
        message: `${info.file.name} file uploaded successfully`,
        duration: 3,
      });
      setValue(`{"url": "${info.file.response.url}", "type":"pdf"}`);
    } else if (info.file.status === 'error') {
      notification.error({
        message: 'file upload failed.',
        duration: 3,
      });
    } else if (info.file.status === 'removed') {
      notification.warn({
        message: `${info.file.name} file deleted successfully`,
        duration: 3,
      });
      setValue(`{"url": "", "type":"pdf"}`);
    }
  };

  const uploadProps: Partial<AntdUploadProps> = {
    disabled: isDisabled,
    name: 'upload',
    accept: fileTypes?.join(','),
    action: requestUrl,
    headers: { 
      Authorization: accessToken, 
      platform: config.APP.PLATFORM,
      'x-isdin-platform': loadItem(PLATFORM_CODE_KEY) || config.APP.PLATFORM_CODE,
    },
    onChange: handleChange,
    listType: 'text',
    fileList,
  };

  const uploadButton = (
    <Button>
      <Icon type="upload" /> Click to Upload
    </Button>
  );

  return (
    <Form.Item>
      {form?.getFieldDecorator('upload', {
        valuePropName: 'fileList',
        getValueFromEvent: (e: any) => e.fileList,
        rules: [{ required: true, message: 'Please upload a file' }],
        initialValue: fileList,
      })(
        <Upload {...uploadProps}>
          {isMediaLoaded ? <Fragment /> : uploadButton}
          {children}
        </Upload>,
      )}
    </Form.Item>
  );
}

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, {})(UploadMediaComponent);
