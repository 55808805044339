import {
  TITLE,
  SUBTITLE,
  DESCRIPTION,
  TITLE_MAX_LENGTH,
  SUBTITLE_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
} from './constants';

export type ProductComponentType =
  | 'title'
  | 'input'
  | 'upload'
  | 'media-url'
  | 'list'
  | 'products-dragable';

interface Field {
  key: string;
  label: string;
  subLabel?: string;
  type: ProductComponentType;
  placeholder: string;
  required?: boolean;
  maxLength?: number;
}

export const productsSectionFields: Field[] = [
  {
    key: 'title',
    label: '*Title',
    subLabel: `It should contain a maximum caracters of: ${TITLE_MAX_LENGTH}.`,
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: TITLE,
    label: '',
    type: 'input',
    placeholder: '',
    required: true,
    maxLength: TITLE_MAX_LENGTH,
  },

  {
    key: 'title',
    label: '*Subtitle',
    subLabel: `It should contain a maximum caracters of: ${SUBTITLE_MAX_LENGTH}.`,
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: SUBTITLE,
    label: '',
    type: 'input',
    placeholder: '',
    required: true,
    maxLength: SUBTITLE_MAX_LENGTH,
  },

  {
    key: 'title',
    label: '*Description',
    subLabel: `It should contain a maximum caracters of: ${DESCRIPTION_MAX_LENGTH}.`,
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: DESCRIPTION,
    label: '',
    type: 'input',
    placeholder: '',
    required: true,
    maxLength: DESCRIPTION_MAX_LENGTH,
  },

  {
    key: 'title',
    label: '*Product List',
    subLabel: '',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: 'productList',
    label: '',
    type: 'products-dragable',
    placeholder: '',
    required: false,
  },
];
