import { Button, Col, Icon, Input, Popconfirm, Row } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ReducersState } from '../../../reducers';
import { IResource, IndexedDetail } from '../../ChallengeInterfaces';
import {
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../challengeActions';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';

interface ISpeaker {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  parentArrayPosition: number;
  childArrayPosition?: number;
  mainIdResource: number;
  canEditResourceName: boolean;
  speakersNumber: number;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ISpeaker;

const Speaker: FC<IGroupProps> = ({
  _resource,
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  parentArrayPosition,
  mainIdResource,
  childArrayPosition,
  handleRemoveChildResource,
  canEditResourceName,
  speakersNumber,
}) => {
  const { formatMessage } = useIntl();

  const { resourceDetailList } = _resource;

  const speakerDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) speakerDetails.push({ index, detail });
  });

  if (!speakerDetails) return <Fragment />;

  const isDisabledDeleteButton = speakersNumber <= 1;

  const componentToEditName = () =>
    editResource ? (
      <Row className="QuizQuestion" type="flex" gutter={[24, 0]}>
        <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }} order={0}>
          <Form.Item className="Quiz__input">
            {`${_resource?.idResourceType?.name}`}
          </Form.Item>
        </Col>
        <Col xs={22} md={16} xl={17} order={1}>
          <Form.Item className="Quiz__input">
            {form.getFieldDecorator(
              _resource.idResourceType.name.concat(
                childArrayPosition?.toString() ||
                  _resource.idResource?.toString() ||
                  '',
              ),
              {
                initialValue: _resource.name,
                rules: [
                  {
                    required: true,
                    message: formatMessage({ id: 'update.required' }),
                    max: 50,
                  },
                ],
              },
            )(
              <Input
                onBlur={(e) =>
                  editRecursiveResource({
                    value: e.target.value,
                    parentArrayPosition: parentArrayPosition,
                    childArrayPosition: childArrayPosition,
                  })
                }
                maxLength={50}
              />,
            )}
          </Form.Item>
        </Col>
        <Col xs={2} order={2}>
          <Form.Item className="Quiz__input">
            <Popconfirm
              title={formatMessage({ id: 'pop.title.delete' })}
              icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
              okText={formatMessage({ id: 'pop.accept' })}
              cancelText={formatMessage({ id: 'pop.cancel' })}
              onConfirm={() => {
                handleRemoveChildResource({
                  idAnswer: _resource.idResource,
                  questionArrayPosition: parentArrayPosition,
                  answerArrayPosition: childArrayPosition,
                  idQuestion: 0,
                });
              }}
              disabled={isDisabledDeleteButton}>
              <Button
                className={
                  isDisabledDeleteButton
                    ? 'QuizQuestion__deleteButton__disabled'
                    : 'QuizQuestion__deleteButton'
                }
                icon="close"
                disabled={isDisabledDeleteButton}
              />
            </Popconfirm>
          </Form.Item>
        </Col>
      </Row>
    ) : (
      <Row className="QuizQuestion" type="flex" gutter={[24, 24]}>
        <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
          {_resource?.idResourceType?.name}
        </Col>
        <Col xs={24} md={18} xl={19}>
          <div className="QuizQuestion__media--ellipsis">{_resource.name}</div>
        </Col>
      </Row>
    );

  return (
    <Row gutter={[24, 0]}>
      {canEditResourceName && componentToEditName()}
      {speakerDetails
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ detail, index }) =>
          RenderDetail({
            accessToken,
            detail,
            parentArrayPosition: parentArrayPosition,
            childArrayPosition: childArrayPosition,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
          }),
        )}
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Speaker);
