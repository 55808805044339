import React from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { userSearch, userTable } from '../extensions/advancedSearch/LimitExceptionsUsersDashboard';


export function UsersWithCatalogLimitExceptionsModal({
  modalVisible,
  setModalVisible,
  ...props
}: any) {
  const handleSetModalVisible = (visible: boolean) => setModalVisible(visible);
  const intl = useIntl();

    const dashboardId = 'ChallengeUsersDashboard';



    return (
      <Modal
        visible={modalVisible}
        className="searchModal"
        onCancel={() => handleSetModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'75%'}>
        <DashboardInitializer
          dashboardId={dashboardId}
          customDashboard={dashboardId}
          components={[
            userSearch(intl),
            userTable({ intl }),
          ]}
          history={props.history}
          isModalDashboard
          modalDashboardConfig={{
            initialParams: {
              key: 'idUser',
              value: null,
            }
          }}
        />
      </Modal>
    );

}
