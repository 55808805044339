import React from 'react';
import { FailedEmailsList } from './FailedEmailsList';
import { IFailedEmail } from './massPointsManagement.interface';
import { useIntl } from 'react-intl';
import { Row, Button, Icon } from 'antd';

export interface ICompleteStepProps {
  failedEmails: IFailedEmail[] | null;
}
export function CompleteStep({ failedEmails }: ICompleteStepProps) {
  const intl = useIntl();

  if (failedEmails?.length === undefined) return null;

  if (failedEmails.length > 0) {
    return (
      <>
        <p>
          {intl.formatMessage(
            {
              id: 'points.management.bulk.modal.complete.successful-with-exceptions',
            },
            { numUsers: failedEmails.length },
          )}
        </p>
        <FailedEmailsList failedEmails={failedEmails} />
      </>
    );
  } else {
    return (
      <p>
        <Icon type="check-circle" />{' '}
        {intl.formatMessage({
          id: 'points.management.bulk.modal.complete.successful',
        })}
      </p>
    );
  }
}

export interface ICompleteStepFooterProps {
  isLoading: boolean;
  onCloseModalClick: CallableFunction;
}
export function CompleteStepFooter({
  isLoading,
  onCloseModalClick,
}: ICompleteStepFooterProps) {
  const intl = useIntl();

  return (
    <Row>
      <Button
        type="primary"
        onClick={() => onCloseModalClick()}
        loading={isLoading}>
        {intl.formatMessage({ id: 'form.edit.close' })}
      </Button>
    </Row>
  );
}
