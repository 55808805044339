import React from 'react';

export default function deliveryNote() {
  return (
    <>
      <svg viewBox="-72 0 480 480" className="svgIconFill">
        <path d="m128 48h80v16h-80zm0 0" />
        <path d="m120 88h-72v48h72zm-16 32h-40v-16h40zm0 0" />
        <path d="m136 104h72v16h-72zm0 0" />
        <path d="m120 152h-72v48h72zm-16 32h-40v-16h40zm0 0" />
        <path d="m136 168h72v16h-72zm0 0" />
        <path d="m120 216h-72v48h72zm-16 32h-40v-16h40zm0 0" />
        <path d="m136 232h72v16h-72zm0 0" />
        <path d="m120 280h-72v48h72zm-16 32h-40v-16h40zm0 0" />
        <path d="m136 296h72v16h-72zm0 0" />
        <path d="m48 344h240v16h-240zm0 0" />
        <path d="m48 376h56v16h-56zm0 0" />
        <path d="m128 376h88v16h-88zm0 0" />
        <path d="m224 104h24v16h-24zm0 0" />
        <path d="m264 104h24v16h-24zm0 0" />
        <path d="m224 168h24v16h-24zm0 0" />
        <path d="m264 168h24v16h-24zm0 0" />
        <path d="m224 232h24v16h-24zm0 0" />
        <path d="m264 232h24v16h-24zm0 0" />
        <path d="m224 296h24v16h-24zm0 0" />
        <path d="m264 296h24v16h-24zm0 0" />
        <path d="m312 0h-288c-13.601562 0-24 10.398438-24 24v432c0 13.601562 10.398438 24 24 24h243.199219l68.800781-76.800781v-379.199219c0-13.601562-10.398438-24-24-24zm8 396.800781-48 53.597657v-34.398438c0-4.800781 3.199219-8 8-8h16v-16h-16c-13.601562 0-24 10.398438-24 24v48h-232c-4.800781 0-8-3.199219-8-8v-432c0-4.800781 3.199219-8 8-8h288c4.800781 0 8 3.199219 8 8zm0 0" />
      </svg>
    </>
  );
}
