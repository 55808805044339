import { push } from 'connected-react-router';
import config from '../config';
import { isEmpty } from 'lodash';
import { IRow, MenuItem } from './AppInterfaces';
import { ReducersState } from '../reducers';

import { queryTypes } from './queryTypes';
import { Dispatch, AnyAction } from 'redux';
import {
  IBreadcrumb,
  IBreadcrumbChild
} from '../breadcrumb/SmartBreadcrumbInterfaces';

interface GetQueryParams {
  type: queryTypes.QUERY_LOAD_QUERYPARAMS;
}
/**
 * Switches app flag to get initial query params
 */
export const getQueryParams = () => (dispatch: Dispatch<GetQueryParams>) => {
  dispatch({ type: queryTypes.QUERY_LOAD_QUERYPARAMS });
};

interface SetInitialQueryParams {
  type: queryTypes.QUERY_SET_INITIAL_PARAMS;
  payload: { initialParams: string };
}

export const setInitialQueryParams = ({
  initialParams
}: {
  initialParams: string;
}) => (dispatch: Dispatch<SetInitialQueryParams>) => {
  let params: string = '';
  if (initialParams.indexOf('?q=') >= 0) params = initialParams.split('?q=')[1];
  dispatch({
    type: queryTypes.QUERY_SET_INITIAL_PARAMS,
    payload: { initialParams: params }
  });
};

interface SetBreadcrumbsChild {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}
export const setBreadcrumbsChild = ({
  selectedRow,
  prettierKey,
  rowKey,
  dashboardId
}: {
  selectedRow: IRow;
  prettierKey?: string;
  rowKey: string;
  dashboardId: string;
}) => (
  dispatch: Dispatch<SetBreadcrumbsChild>,
  getState: () => ReducersState
) => {
  const currentPath = getState().router.location.pathname;
  let breadcrumbs = getState().query.breadcrumbs.slice();
  breadcrumbs[breadcrumbs.length - 1].child = isEmpty(selectedRow)
    ? ({} as IBreadcrumbChild)
    : {
        name:
          prettierKey !== undefined
            ? selectedRow[prettierKey]
            : selectedRow[rowKey],
        goBack: true,
        path: `${currentPath}?q=${rowKey}${config.QUERY.ID_OPERATOR}${selectedRow[rowKey]}`,
        rowKey,
        value: selectedRow[rowKey],
        dashboardId
      };
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

interface ReturnToParent {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}

/**
 * This function navigates to previous dashboard and pops breadcrumbs
 * to previous dashboard selected element
 * @param {Array} breadcrumbs - Actual app breadcrumbs
 */
export const returnToParent = ({
  breadcrumbs,
  setDrawerVisibility,
  key
}: {
  breadcrumbs: IBreadcrumb[];
  setDrawerVisibility: Function;
  key?: number;
}) => (dispatch: Dispatch<ReturnToParent>) => {
  if (key !== undefined) breadcrumbs.length = key + 1;
  else breadcrumbs.pop();
  let path = breadcrumbs[breadcrumbs.length - 1].child.path;
  if (breadcrumbs[breadcrumbs.length - 1].child.returnExtension)
    path = path + breadcrumbs[breadcrumbs.length - 1].child.returnExtension;
  const { dashboardId } = breadcrumbs[breadcrumbs.length - 1].child;
  dispatch(push(path));
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });

  setDrawerVisibility({ dashboardId, visible: true });
};

interface ResetBreadcrumbs {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}

export const resetBreadcrumbs = ({
  path,
  search
}: {
  path: string;
  search: string;
}) => (dispatch: Dispatch<ResetBreadcrumbs>, getState: () => ReducersState) => {
  const menu = getState().app.menu;
  let name, icon;
  menu!.forEach((menuItem: MenuItem) => {
    if (menuItem.route === path) {
      name = menuItem.nameOfMenu;
      icon = menuItem.icon;
    }
    if (menuItem.subMenu) {
      menuItem.subMenu.forEach((subMenuItem: MenuItem) => {
        if (subMenuItem.route === path) {
          name = subMenuItem.nameOfMenu;
          icon = subMenuItem.icon;
        }
      });
    }
  });

  let breadcrumbs = [
    {
      path: search !== '' ? path.concat(search) : path,
      name,
      icon,
      absolutePath: path,
      child: {} as IBreadcrumbChild
    }
  ];
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

interface SetInitialBreadcrumb {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}

/**
 * This function is called before logging in. It removes all breadcrumbs and sets it to the initial route where the user is redirected to.
 *
 * @param {string} path Where the user is redirected to.
 * @param {string} name Display name for this step in the breadcrumbs component.
 * @param {string} icon AntD icon `CSS` class to display for this step in the breadcrumbs component.
 * @param {boolean} hasLink Allow the component to have link to its path.
 */
export const setInitialBreadcrumb = ({
  path,
  name,
  icon
}: {
  path: string;
  name: string;
  icon?: number;
}) => (
  dispatch: Dispatch<SetInitialBreadcrumb>,
  getState: () => ReducersState
) => {
  const actualPath = getState().router.location.pathname.concat(
    getState().router.location.search
  );
  let breadcrumbs = getState().query.breadcrumbs.slice();
  let absolutePath = '';
  if (
    breadcrumbs &&
    breadcrumbs.length > 0 &&
    (breadcrumbs[breadcrumbs.length - 1].path === actualPath ||
      (!isEmpty(breadcrumbs[breadcrumbs.length - 1].child) &&
        breadcrumbs[breadcrumbs.length - 1].child.path))
  )
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  else {
    const pathname = getState().router.location.pathname;
    const menu = getState().app.menu;
    breadcrumbs = [];
    menu!.forEach((menuItem: MenuItem) => {
      if (menuItem.route === pathname) {
        path = menuItem.route;
        name = menuItem.nameOfMenu;
        icon = menuItem.icon;
        absolutePath = menuItem.route;
      }
      if (menuItem.subMenu) {
        menuItem.subMenu.forEach(subMenuItem => {
          if (subMenuItem.route === pathname) {
            path = subMenuItem.route;
            name = subMenuItem.nameOfMenu;
            icon = subMenuItem.icon;
            absolutePath = subMenuItem.route;
          }
        });
      }
    });

    breadcrumbs.push({
      path,
      name,
      icon,
      absolutePath,
      child: {} as IBreadcrumbChild
    });

    saveBreadcrumbs(breadcrumbs);
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  }
};

/**
 * Navigates to specified `path` leaving a breadcrumb that displays `name`
 * and `icon`.
 *
 * @param {string} path Where to navigate to.
 * @param {string} name Display name for this step in the breadcrumbs component.
 * @param {string} icon AntD icon `CSS` class to display for this step in the breadcrumbs component.
 * @param {boolean} absolute Whether this navigation should clean the breadcrumbs buffer (`true`) or not (`false`).
 */
export const navigate = ({
  path,
  name,
  icon,
  absolute,
  returnExtension
}: {
  path: string;
  name?: string;
  icon?: number;
  absolute?: boolean;
  returnExtension?: string;
}) => (
  dispatch: Dispatch<SetInitialBreadcrumb>,
  getState: () => ReducersState
) => {
  // const menu = getState().app.menu; //TODO FIX
  const menu: MenuItem[] | null = getState().app.menu;
  let newName = name;
  let newIcon = icon;
  let breadcrumbs = getState().query.breadcrumbs.slice();
  const location = getState().router.location;

  if (path) {
    dispatch(push(path, location.pathname !== path));
  }

  if (absolute) {
    breadcrumbs.length = 0;
  }

  //TODO remove this (necesario para FKTHIRD & FKTHIRDTYPE)
  if (returnExtension && returnExtension !== '' && breadcrumbs.length > 1) {
    //breadcrumbs.lenght > 1 necesario
    breadcrumbs[breadcrumbs.length - 1].child.returnExtension = returnExtension;
  }

  let basicPath = path.indexOf('?q=') >= 0 ? path.split('?q=')[0] : path;
  if (newIcon === undefined || newName === undefined) {
    menu!.forEach((m: MenuItem) => {
      if (m.route === basicPath) {
        newIcon = m.icon;
        newName = m.nameOfMenu;
      }
      if (m.subMenu) {
        m.subMenu.forEach((sub: MenuItem) => {
          if (sub.route === basicPath) {
            newIcon = sub.icon;
            newName = sub.nameOfMenu;
          }
        });
      }
    });
  }

  breadcrumbs.push({
    path,
    name: newName,
    icon: newIcon,
    absolutePath: basicPath,
    child: {} as IBreadcrumbChild
  });
  saveBreadcrumbs(breadcrumbs);

  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

interface SetBreadcrumbs {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}

export const setBreadcrumbs = ({
  breadcrumbs,
  index
}: {
  breadcrumbs: IBreadcrumb[];
  index: number;
}) => (dispatch: Dispatch<SetBreadcrumbs>) => {
  const newBreadcrumbs = breadcrumbs.slice();
  newBreadcrumbs.length = index + 1;
  newBreadcrumbs[newBreadcrumbs.length - 1].child = {} as IBreadcrumbChild;
  saveBreadcrumbs(newBreadcrumbs);

  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs: newBreadcrumbs }
  });
};

interface EditNavigationState {
  type: queryTypes.QUERY_SET_COMPONENT_NAVIGATION;
  payload: { component: string };
}

export const editNavigationState = ({ component }: { component: string }) => (
  dispatch: Dispatch<EditNavigationState>
) => {
  dispatch({
    type: queryTypes.QUERY_SET_COMPONENT_NAVIGATION,
    payload: { component }
  });
};

interface GoBackToRoute {
  type: queryTypes.QUERY_GO_BACK;
  payload: { newBreadcrumbs: IBreadcrumb[] };
}
/**
 * Navigates to a `path` existing in the current `breadcrumbs` state node. Cleans the
 * breadcrumbs state to hold the proper crumbs.
 *
 * @param {string} path Path saved in breadcrumbs.
 */
export const goBackToRoute = (path: string, setDrawerVisibility?: Function) => (
  dispatch: Dispatch<GoBackToRoute>,
  getState: () => ReducersState
) => {
  dispatch(push(path));

  let newBreadcrumbs = getState().query.breadcrumbs.slice();
  let dashboardId;

  const breadcrumbIndex = getState().query.breadcrumbs.findIndex(
    (bc: IBreadcrumb) =>
      setDrawerVisibility ? bc.child.path === path : bc.path === path
  );

  newBreadcrumbs.length = breadcrumbIndex + 1;

  if (!setDrawerVisibility)
    newBreadcrumbs[newBreadcrumbs.length - 1].child = {} as IBreadcrumbChild;
  else
    dashboardId = newBreadcrumbs[newBreadcrumbs.length - 1].child.dashboardId;

  saveBreadcrumbs(newBreadcrumbs);

  dispatch({
    type: queryTypes.QUERY_GO_BACK,
    payload: { newBreadcrumbs }
  });

  setDrawerVisibility && setDrawerVisibility({ dashboardId, visible: true });
};

interface CleanBreadcrumb {
  type: queryTypes.QUERY_PUSH_BREADCRUMB;
  payload: { breadcrumbs: IBreadcrumb[] };
}

export const cleanBreadcrumb = () => (
  dispatch: Dispatch<CleanBreadcrumb>,
  getState: () => ReducersState
) => {
  let breadcrumbs = getState().query.breadcrumbs.slice();
  if (!isEmpty(breadcrumbs[breadcrumbs.length - 1].child)) {
    dispatch(push(breadcrumbs[breadcrumbs.length - 1].path));
    breadcrumbs[breadcrumbs.length - 1].child = {} as IBreadcrumbChild;

    saveBreadcrumbs(breadcrumbs);
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  }
};

/**
 * This function dispatches a new route when creating a new record
 * and pushes this new element (which will be on selectedRow) to the breadcrumbs
 * @param {String} currentPath - Entity base path
 * @param {String} primaryKey - Entity primary key
 * @param {Array} row - New record
 * @param {String} prettierKey - Row attribute which will be displayed in the breadcrumbs
 */
export const navigateAfterCreate = ({
  currentPath,
  primaryKey,
  row
}: {
  currentPath: string;
  primaryKey: string;
  row: IRow;
}) => (dispatch: Dispatch<AnyAction>) => {
  let path = `${currentPath}?q=${primaryKey}${config.QUERY.ID_OPERATOR}${row[primaryKey]}`;
  dispatch(push(path));
};

const saveBreadcrumbs = (breadcrumbs: IBreadcrumb[]) =>
  localStorage.setItem(
    config.LOCAL_STORAGE.BREADCRUMBS,
    JSON.stringify(breadcrumbs)
  );
interface SetNavigation {
  type: queryTypes.QUERY_SET_NAVIGATION;
  payload: { editFormNavigation: true; searchFormNavigation: true };
}

interface RouterLocationChange {
  type: queryTypes.ROUTER_LOCATION_CHANGE;
  payload: { location: any };
}

export type QueryActionTypes =
  | CleanBreadcrumb
  | RouterLocationChange
  | SetNavigation
  | GoBackToRoute
  | EditNavigationState
  | GetQueryParams
  | SetBreadcrumbsChild
  | SetInitialQueryParams
  | ReturnToParent
  | ResetBreadcrumbs
  | SetInitialBreadcrumb
  | SetBreadcrumbs;
