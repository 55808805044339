import React from 'react';
import fieldRender from './fieldRender';
import BehaviourField from '../fields/BehaviourField';
import { checkElementsBehaviours } from './behaviourUtils';
import { Tabs, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import './FormStyles.css';
import { SearchFormRenderProps } from './search/SearchFormRender';
import { ICompleteField, IFieldSubgroups, IGroupField } from './formInterfaces';
import { EditFormRenderProps } from './edit/EditFormRender';

const TabPane = Tabs.TabPane;

const checkDisabledTabs = (groups: IGroupField[], index: number): number => {
  if (!isEmpty(groups)) {
    if (groups[index].disabled) {
      if (groups[index].mustRender !== false || groups[index].visible !== false)
        index += 1;
      if (index + 1 > groups.length) return 0;
      return checkDisabledTabs(groups, index);
    } else return index;
  }
  return 0;
};

function isSubgroup(
  subgroup: IFieldSubgroups | ICompleteField
): subgroup is IFieldSubgroups {
  if ((subgroup as IFieldSubgroups).fieldRow) {
    return true;
  }
  return false;
}

function isField(
  field: IFieldSubgroups | ICompleteField
): field is ICompleteField {
  if ((field as ICompleteField).type) {
    return true;
  }
  return false;
}

/**
 *
 * @param {*} parentProps Inherited props from EditForm
 * @returns Edit form
 * This function maps 'fieldConfig' and builds the Edit Form. Groups, subgroups and fields will be displayed according 'fieldsConfig' structure (build on the Edit Constructor).
 */
export default function formRender(
  parentProps: SearchFormRenderProps | EditFormRenderProps
) {
  const { props } = parentProps;
  const { values, fieldsConfig } = props;
  const checkedFieldsConfig: IGroupField[] = checkElementsBehaviours(
    fieldsConfig,
    values
  );
  return (
    <>
      {fieldsConfig.length > 1
        ? tabRender(checkedFieldsConfig, parentProps)
        : RowRender(
            checkedFieldsConfig[0].subgroupRows,
            parentProps,
            false,
            checkedFieldsConfig[0].disabled
          )}
    </>
  );
}

const tabRender = (
  groups: IGroupField[],
  parentProps: SearchFormRenderProps | EditFormRenderProps
) => {
  const { handleChangeTab, props } = parentProps;
  const { selectedTab } = props;
  const index = checkDisabledTabs(groups, 0).toString();
  const invisibleGroups: IGroupField[] = [];
  return (
    <>
      <Tabs
        defaultActiveKey={`${index}`}
        activeKey={selectedTab}
        animated={false}
        onChange={handleChangeTab}>
        {groups.map(group => {
          if (group.hasOwnProperty('forceDisabled'))
            group.disabled = group.forceDisabled;
          if (
            group.mustRender !== false &&
            group.visible !== false &&
            group.forceVisible !== false
          )
            return (
              <TabPane
                disabled={group.disabled}
                tab={group.title}
                key={group.index.toString()}
                forceRender
                className="tabContainer">
                {RowRender(group.subgroupRows, parentProps, false)}
              </TabPane>
            );
          else if (group.visible === false) invisibleGroups.push(group);
          return null;
        })}
      </Tabs>
      {!isEmpty(invisibleGroups)
        ? invisibleGroups.map(g => {
            return (
              <Col className="noDisplay" key={g.index}>
                {RowRender(g.subgroupRows, parentProps, false)}
              </Col>
            );
          })
        : null}
    </>
  );
};

const RowRender = <
  T extends IFieldSubgroups | ICompleteField,
  U extends SearchFormRenderProps | EditFormRenderProps
>(
  rows: T[][],
  parentProps: U,
  isFieldsRow: boolean,
  isParentDisabled?: boolean
) => {
  const { options, values } = parentProps.props;
  return (
    <>
      {rows.map((row, i) => {
        const checkedRow: T[] = isFieldsRow
          ? row
          : checkElementsBehaviours(row, values);

        return (
          <Row gutter={options.fieldGutter} key={i}>
            {checkedRow.map(subElement => {
              if (isSubgroup(subElement)) {
                if (isParentDisabled !== undefined)
                  subElement.disabled = isParentDisabled;
                return subgroupRender(subElement, parentProps);
              } else if (isField(subElement)) {
                if (isParentDisabled !== undefined) {
                  subElement.isParentDisabled = isParentDisabled;
                }
                if (
                  subElement.hasOwnProperty('initialVisibility') &&
                  !subElement.visible
                )
                  return BehaviourField({
                    parentProps,
                    field: subElement,
                    hidden: true
                  });
                else if (
                  subElement.forceVisible === false ||
                  (subElement.visible === false && !subElement.forceVisible)
                ) {
                  let width = subElement.noWidth ? 0 : subElement.width;
                  return <Col md={width} key={subElement.key} />;
                }
                return (
                  subElement.mustRender !== false &&
                  fieldRender(subElement, parentProps)
                );
              } else return null;
            })}
          </Row>
        );
      })}
    </>
  );
};

const subgroupRender = (
  fieldsSubgroup: IFieldSubgroups,
  parentProps: SearchFormRenderProps | EditFormRenderProps
) => {
  if (fieldsSubgroup.mustRender === false) return;

  if (fieldsSubgroup.visible === false && !fieldsSubgroup.forceVisible)
    return (
      <Col className="noDisplay" key={fieldsSubgroup.index}>
        {RowRender(
          fieldsSubgroup.fieldRow,
          parentProps,
          true,
          fieldsSubgroup.disabled
        )}
      </Col>
    );

  let isSubgroupEmpty = true;
  fieldsSubgroup.fieldRow.forEach((fieldRow: ICompleteField[]) => {
    fieldRow.forEach((field: ICompleteField) => {
      if (
        field.forceVisible !== false ||
        (field.visible !== false && field.forceVisible === undefined)
      ) {
        isSubgroupEmpty = false;
      }
    });
  });

  if (fieldsSubgroup.title) {
    let subgroupClassname = 'subgroups';
    let boxClassname = 'subgroups--box';
    if (isSubgroupEmpty) {
      subgroupClassname = 'subgroups--empty';
      boxClassname = '';
    }

    return (
      <Col md={fieldsSubgroup.width} key={fieldsSubgroup.index}>
        {isSubgroupEmpty ? null : (
          <div className={subgroupClassname}>{fieldsSubgroup.title} </div>
        )}
        <div className={boxClassname}>
          {RowRender(
            fieldsSubgroup.fieldRow,
            parentProps,
            true,
            fieldsSubgroup.disabled
          )}
        </div>
      </Col>
    );
  } else {
    return (
      <Col md={fieldsSubgroup.width} key={fieldsSubgroup.index}>
        {RowRender(
          fieldsSubgroup.fieldRow,
          parentProps,
          true,
          fieldsSubgroup.disabled
        )}
      </Col>
    );
  }
};
