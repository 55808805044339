import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ParametersTypesDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'parametersTypesSearch',
      },
      groups: [],
      fields: [],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },

    {
      params: {
        type: 'table',
        componentId: 'parametersTypesList',
        rowKey: 'idParameterType',
        panelHeader: 'Parameters Types',
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0,
        //customButtons: ['editExpirationPointsDates'],
        // customButtons: ['uploadFilesButton'], //Todo This button allows to edit expirationPoints dates, NOW this behaivor is controlled by Magento.
      },
      fields: [
        {
          title: props.intl.formatMessage({ id: 'boroles.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: props.intl.formatMessage({ id: 'boroles.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
          precision: 0,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: '50',
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'parametersTypesDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ParametersTypesDashboard;
