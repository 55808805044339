import { Button, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import config from '../../config';
import { PLATFORM_CODE_KEY, loadItem } from '../../utils';

export default function ValidateUserEmailButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const { selectedRow } = parentProps.props;

  const tableProps: ITableRenderListProps = parentProps;

  const handleValidateUser = async () => {
    try {
      const response = await api.putDataCall({
        dataPath: `${apiPaths.CALL.VALIDATE_USER_EMAIL}/${selectedRow.idUser}`,
        data: {
          idUser: selectedRow.idUser,
        },
        callConfig: {},
      });

      // Generate coupons as we do on "normal" validation
      // (when the users validate from the email)
      const consumedQrList = response?.data.consumedQrList as any[];
      const consumedQRs = response?.data.consumedQRs as any[];
      const credentials = response?.data.credentials;
      if (consumedQrList.length && credentials) {
        const accessToken = credentials?.accessToken;
        const authHeaders = {
          Authorization:
            accessToken.indexOf('Bearer') < 0
              ? `Bearer ${accessToken}`
              : accessToken,
          'Content-Type': 'application/json',
          platform: config.APP.PLATFORM,
          /*
           * @todo Remove {platform-code} header when the backend is ready.
           */
          'platform-code':
            loadItem(PLATFORM_CODE_KEY) || config.APP.PLATFORM_CODE,
          'x-isdin-platform':
            loadItem(PLATFORM_CODE_KEY) || config.APP.PLATFORM_CODE,
        };
        await Promise.all(
          consumedQrList.map(async (consumedQrFromList: any, index) => {
            const productId = consumedQrFromList?.entityId;
            await api.getDataCall({
              dataPath: `${apiPaths.CONSUMABLES.GET_PRODUCT_LANDING}/${productId}`,
              callConfig: {},
            });
            const consumedQr = consumedQRs[index];
            const qr = {
              apiKey: consumedQr.apiKey,
              code: consumedQr.qrCode,
              scanId: consumedQr.scanID,
              userId: consumedQr.scanUserId,
            };

            await api.postDataCallALT({
              dataPath: apiPaths.CONSUMABLES.SCAN_QR,
              data: { qr, variantId: parseInt(productId) },
              callConfig: { headers: authHeaders },
            });
          }),
        );
      }

      if (response?.status === 200) {
        notification.info({
          message: formatMessage({
            id: 'validateUser.notification.title',
          }),
          description: formatMessage({
            id: 'validateUser.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: formatMessage({
          id: 'validateUser.notification.error.title',
        }),
        description: formatMessage({
          id: 'validateUser.notification.error.description',
        }),
      });
    } finally {
      await tableProps.handleReloadData();
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        disabled={isEmpty(selectedRow) || selectedRow.validatedEmail}
        onClick={() => setShowConfirmModal(true)}>
        {formatMessage({ id: 'validateUser.button' })}
      </Button>
      <Modal
        className="modalConfirm"
        visible={showConfirmModal}
        closable={false}
        centered
        onCancel={() => setShowConfirmModal(false)}
        onOk={handleValidateUser}>
        <p>{formatMessage({ id: 'validateUser.modal-description' })}</p>
      </Modal>
    </>
  );
}
