export enum FailedEmailReasonEnum {
  UNKNOWN = 'unknown',
  NON_EXISTENT = 'non_existent',
  UNSET_UUID = 'unset_uuid',
  POINTS_API_ERROR = 'points_api_error',
}

export enum Step {
  Edit,
  Validate,
  Complete,
}
