import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const CountryDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'countrycountrySearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 12
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'country.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true
        },
        {
          title: 'id',
          key: 'idCountry',
          type: 'number',
          operand: 'like'
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'like',
          mandatory: false,
          disabled: false,
          initialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'countrycountryList',
        rowKey: 'idCountry',
        panelHeader: 'Country List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name'
      },
      fields: [
        {
          title: formatMessage({ id: 'country.id' }),
          key: 'idCountry',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL
        },
        {
          title: formatMessage({ id: 'country.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'countrycountryEdit',
        panelHeader: 'Country Edit',
        primaryKey: 'idCountry',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'country.id' }),
          key: 'idCountry',
          type: 'number'
        },
        {
          title: formatMessage({ id: 'country.name' }),
          key: 'name',
          type: 'text',
          length: 25,
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idCountry',
                operand: 'notEmpty',
                type: 'disabled'
              }
            ]
          ],
          initialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'countrylanguagesList',
        rowKey: 'idLanguage',
        panelHeader: 'Languages List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'Languagesview',
        navigationId: 'idCountry.idCountry',
        tabIndex: 8
      },
      fields: [
        {
          title: 'Cod Develop',
          key: 'codDevelop',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Country',
          key: 'idCountry.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Name',
          key: 'name',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Status',
          key: 'status',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    }
  ];

  const componentId = 'CountryDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default CountryDashboard;
