import { Select } from 'antd';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import {
  getM2MTableData,
  resetM2M,
  setM2MModalVisibility,
  setM2MPathExtended,
  setM2MTableParams,
} from '../../m2m/m2mActions';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { getTableData } from '../../tables/tableActions';
import { isTableProps } from '../../utils/propsUtils';
import { DisclaimerType } from '../../fields/CustomFields.enum';
import _ from 'lodash';
import { ComboData } from '../../combos/ComboInterfaces';

export default function DisclaimerSpecialtiesAndRolesSelect<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;

  return <DisclaimerSpecialtiesAndRolesSelectComponent tableProps={tableProps} />;
}

interface OwnButtonProps {
  tableProps: ITableRenderListProps;
}

type ButtonProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnButtonProps;

const _DisclaimerSpecialtiesAndRolesSelectComponent: FC<ButtonProps> = (
  componentProps,
) => {

  const {
    tableProps: { props },
    specialtiesRoleTypesMatrix,
    setM2MPathExtended,
    setM2MModalVisibility,
  } = componentProps;


  const handleChangeM2MModalVisibility = (
    isVisible: boolean,
    idSpecialty: string,
  ) => {
    setM2MPathExtended({
      key: 'associate-with-specialty',
      value: idSpecialty,
    });
    setM2MModalVisibility(isVisible);
  };

  return (
    <>
      <Select
        style={{width: '300px', maxWidth: '50%'}}
        showSearch
        placeholder="Select one option"
        optionFilterProp="children"
        value={undefined}
        disabled={
          props.disabled ||
          props.parentSelectedRow.disclaimerType ===
          DisclaimerType.COMMERCIAL
        }
        onChange={(value: string) => handleChangeM2MModalVisibility(true, value)}>
        {specialtiesRoleTypesMatrix.map((specialtiesRoleTypesMatrixItem) => {
          return (
            <Select.Option value={specialtiesRoleTypesMatrixItem.value}>{specialtiesRoleTypesMatrixItem.description}</Select.Option>
          );
        })}
      </Select>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  const roleTypes = state.combos.combos.disclaimersEdit.roleTypes.roleType.data ?? [];
  const specialties = state.combos.combos.disclaimersEdit.specialty.specialty.data ?? [];
  const specialtiesRoleTypesMatrix: ComboData[] = [];
  _.forEach(specialties, (specialty) => {
    specialtiesRoleTypesMatrix.push({
      value: specialty.value + '-null',
      description: specialty.description,
    });
  })
  _.forEach(specialties, (specialty) => {
    _.forEach(roleTypes, (roleType) => {
      specialtiesRoleTypesMatrix.push({
        value: specialty.value+'-'+roleType.value,
        description: specialty.description + ' + ' + roleType.description,
      });
    })
  })
  return {
    roleTypes,
    specialties,
    specialtiesRoleTypesMatrix,
    isM2MModalVisible: state.m2m.visible,
    selectedM2MDashboard: state.m2m.activeDashboard,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setM2MModalVisibility,
      resetM2M,
      getTableData,
      setM2MTableParams,
      getM2MTableData,
      setM2MPathExtended,
    },
    dispatch,
  );

// TODO: Move this to a separate file
const DisclaimerSpecialtiesAndRolesSelectComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_DisclaimerSpecialtiesAndRolesSelectComponent);
