import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ResourceDashboard = (props: any) => {
  const { SMALL, MID } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'resourceresourceSearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'endDate',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'idChallenge.idChallenge',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'idResourceType.idResourceType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },

                {
                  key: 'startDate',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'resource.end' }),
          key: 'endDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.endVisDate' }),
          key: 'endVisDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.idChallenge' }),
          key: 'idChallenge.idChallenge',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'challenge',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.id' }),
          key: 'idResource',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.idType' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          operand: 'like',
          min: 0,
          comboId: 'resource_type',
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.ind' }),
          key: 'indResource',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true
        },
        {
          title: formatMessage({ id: 'resource.start' }),
          key: 'startDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.startVisDate' }),
          key: 'startVisDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: true,
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceresourceList',
        rowKey: 'idResource',
        panelHeader: 'Resource List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0
      },
      fields: [
        {
          title: formatMessage({ id: 'resource.id' }),
          key: 'idResource',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL
        },
        {
          title: formatMessage({ id: 'resource.name' }),
          key: 'name',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'resource.end' }),
          key: 'endDate',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime'
        },
        {
          title: formatMessage({ id: 'resource.endVisDate' }),
          key: 'endVisDate',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime'
        },
        {
          title: formatMessage({ id: 'resource.idChallenge' }),
          key: 'idChallenge.idChallenge',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'combo',
          comboId: 'challenge'
        },

        {
          title: formatMessage({ id: 'resource.idType' }),
          key: 'idResourceType.idResourceType',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'combo',
          comboId: 'resource_type'
        },
        {
          title: formatMessage({ id: 'resource.ind' }),
          key: 'indResource',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'combo',
          comboId: 'resource'
        },
        {
          title: formatMessage({ id: 'resource.start' }),
          key: 'startDate',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime'
        },
        {
          title: formatMessage({ id: 'resource.startVisDate' }),
          key: 'startVisDate',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime'
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          position: 13,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: SMALL
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'resourceresourceEdit',
        panelHeader: formatMessage({ id: 'resource.edit' }),
        primaryKey: 'idResource',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                // ROW 0
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'idChallenge.idChallenge',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'idResourceType.idResourceType',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                //ROW 1
                {
                  key: 'endDate',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'endVisDate',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6
                },

                {
                  key: 'startDate',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'startVisDate',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                },
                // ROW 3
                {
                  key: 'indResource',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'resource.id' }),
          key: 'idResource',
          type: 'number',
          mandatory: false
        },
        {
          title: formatMessage({ id: 'resource.end' }),
          key: 'endDate',
          type: 'datetime',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.endVisDate' }),
          key: 'endVisDate',
          type: 'datetime',
          unique: false,
          min: 0,
          validationType: 'text',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.idChallenge' }),
          key: 'idChallenge.idChallenge',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'challenge',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.idType' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'resource_type',
          length: 0,
          mandatory: true,
          disabled: false,
          behaviours: [
            [
              {
                type: 'disabled',
                operand: 'notEmpty',
                key: 'idResource'
              }
            ]
          ]
        },
        {
          title: formatMessage({ id: 'resource.ind' }),
          key: 'indResource',
          type: 'number',
          unique: false,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.start' }),
          key: 'startDate',
          type: 'datetime',
          unique: false,
          min: 0,
          validationType: 'text',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.startVisDate' }),
          key: 'startVisDate',
          type: 'datetime',
          unique: false,
          min: 0,
          validationType: 'text',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: true
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceresourceDetailList',
        rowKey: 'idResourceD',
        panelHeader: formatMessage({ id: 'resource.details.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'ResourceDetailview',
        navigationId: 'idResource.idResource',
        tabIndex: 1
      },
      fields: [
        {
          title: formatMessage({ id: 'resource.details.id' }),
          key: 'idResourceD',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL
        },
        {
          title: formatMessage({ id: 'resource.details.type' }),
          key: 'idResourceTypeD.idResourceTypeD',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'resource_type_detail',
          width: MID
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID
        },
        {
          title: formatMessage({ id: 'resource.details.value' }),
          key: 'value',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourceresourceI18nList',
        rowKey: 'idReI18n',
        panelHeader: 'Resource I18n List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'ResourceI18nview',
        navigationId: 'idParent.idResource',
        tabIndex: 2,
        disabled: true
      },
      fields: [
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Parent',
          key: 'idParent.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Re I18n',
          key: 'idReI18n',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Language',
          key: 'language',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'resource.name' }),
          key: 'name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'resourcescoreList',
        rowKey: 'idScore',
        panelHeader: 'Score List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'Scoreview',
        navigationId: 'idResource.idResource',
        tabIndex: 3,
        disabled: true
      },
      fields: [
        {
          title: formatMessage({ id: 'resource.id' }),
          key: 'idResource.name',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Score',
          key: 'idScore',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Optimistic Locking',
          key: 'optimisticLocking',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Points',
          key: 'points',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'resource.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    }
  ];

  const componentId = 'ResourceDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ResourceDashboard;
