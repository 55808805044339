import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const PearlsDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'pearlsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallenge.idChallenge',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'statusResource',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idResource.idResource',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'assign-points.challenge' }),
          key: 'idChallenge.idChallenge',
          type: 'combo',
          comboId: 'pearlCombo',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'assign-points.status-resource' }),
          key: 'statusResource',
          type: 'combo',
          comboId: 'pearlStatus',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'assign-points.resource' }),
          key: 'idResource.idResource',
          type: 'combo',
          comboId: 'boPearlsResources',
          operand: 'equal',
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType.idResourceType',
          type: 'combo',
          comboId: 'boAssignPointsResourcesTypes',
          operand: 'equal',
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'pearlsList',
        rowKey: 'idUserResource',
        panelHeader: 'Pearls List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'idUserResource',
        customButtons: ['pearlsButton', 'pearlsReviewerButton'],
      },
      fields: [
        {
          title: formatMessage({ id: 'assign-points.id-challenge' }),
          key: 'idResource.idChallenge.idChallenge',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: LARGE,
          defaultSortOrder: 'descend',
        },
        {
          title: formatMessage({ id: 'assign-points.name-challenge' }),
          key: 'idResource.idChallenge.idChallenge',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
          render: 'combo',
          comboId: 'challenge',
        },
        {
          title: formatMessage({ id: 'assign-points.id-resource' }),
          key: 'idResource.idResource',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'assign-points.name-resource' }),
          key: 'idResource.name',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'assign-points.name-user' }),
          key: 'userName',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'assign-points.status' }),
          key: 'statusResource',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
          render: 'combo',
          comboId: 'pearlStatus',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'PearlsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default PearlsDashboard;
