export enum ThankYouPageTypes {
  PROMO = 'promo',
  PROMO_CONCERN = 'promo_concern',
  BASIC = 'basic',
  CONCERN = 'concern',
}

export enum FirstPageTypes {
  URL = 'url',
  MEDIA = 'media',
}

export enum BlackListTypes {
  URL = 'url',
  UTM = 'utm',
}
