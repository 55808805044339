import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { UserTokensListModal } from '../../isdinCustomDashboards/UserTokensListModal';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function TokensUserList<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const intl = useIntl();
  const [visibleModalTokensUser, setVisibleModalTokensUser] = useState(false);

  if (!isTableProps(parentProps)) return <Fragment />;

  const { selectedRow } = parentProps.props;

  return (
    <>
      <Button
        type="primary"
        disabled={isEmpty(selectedRow)}
        onClick={() => setVisibleModalTokensUser(true)}>
        {intl.formatMessage({ id: 'button-title.token-users' })}
      </Button>
      <UserTokensListModal
        {...{
          parentProps,
          modalVisible: visibleModalTokensUser,
          setModalVisible: setVisibleModalTokensUser,
        }}
      />
    </>
  );
}
