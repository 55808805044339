import React, { useState, useEffect } from 'react';
import { Modal, Row } from 'antd';
import { Dispatch } from 'redux';
import { isEmpty } from 'lodash';
import config from '../../config';
import * as api from '../../api';
import apiPaths from '../../apiPaths';

import AdminVisibilityForm from './AdminVisibilityForm';
import { IRow } from '../../app/AppInterfaces';
import { SetChallengeLoading } from '../challengeActions';
import { usePrevious } from '../ChallengeBuilder';
import { useIntl } from 'react-intl';
import { renderErrors } from '../ChallengeUtils';

interface IAdminVisibility {
  isLoading: boolean;
  visibilityModalVisible: boolean;
  setLoading: (
    isLoading: boolean,
  ) => (dispatch: Dispatch<SetChallengeLoading>) => void;
  selectedRow: IRow;
  setVisibilityModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminVisibility(props: IAdminVisibility) {
  const {
    isLoading,
    selectedRow,
    visibilityModalVisible,
    setLoading,
    setVisibilityModalVisible,
  } = props;

  const [users, setUsers] = useState<null | string[]>(null);
  const [newUsers, setNewUsers] = useState<string[]>([]);
  const [actionType, setActionType] = useState(false);
  const [restrictionsHaveChanged, setRestrictionsHaveChanged] = useState(false);

  const intl = useIntl();
  const prevSelectedRow = usePrevious(selectedRow);

  useEffect(() => {
    if (
      !selectedRow ||
      isEmpty(selectedRow) ||
      (visibilityModalVisible === false && users !== null)
    )
      setUsers(null);
    else if (
      prevSelectedRow !== selectedRow ||
      (visibilityModalVisible === true && users === null)
    )
      (async () => await fetchRestrictions())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, visibilityModalVisible]);

  const fetchRestrictions = async () => {
    const idChallenge = selectedRow.idChallenge;
    setRestrictionsHaveChanged(false);
    if (idChallenge) {
      setLoading(true);
      const _restrictions = await api.getDataCallById({
        dataPath: apiPaths.CALL.CHALLENGE_USERS_RESTRICTIONS,
        registerId: idChallenge,
        callConfig: {},
      });
      if ('status' in _restrictions && _restrictions.status === 200) {
        let fetchedActionType = false;
        const fetchedUsers: string[] = [];
        await _restrictions.data.forEach((_restriction: IRow) => {
          if (fetchedActionType === false && _restriction.actionType === true)
            fetchedActionType = true;
          if (_restriction.idUser && _restriction.idUser !== null)
            fetchedUsers.push(_restriction.idUser.email);
        });

        setActionType(fetchedActionType);
        setUsers(fetchedUsers);
      }

      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (restrictionsHaveChanged)
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pop.title.changes-will-not-be-saved-are-you-sure',
        }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setNewUsers([]);
          setVisibilityModalVisible(false);
          setRestrictionsHaveChanged(false);
        },
        onCancel() {},
      });
    else setVisibilityModalVisible(false);
  };

  const validateUsers = async (newUsers: string[]) => {
    if (selectedRow.idChallenge) {
      setLoading(true);
      let payloadUsers: { email: string }[] = [];
      if (users && users.length)
        users.forEach((_user) => {
          if (!newUsers.includes(_user)) payloadUsers.push({ email: _user });
        });
      if (newUsers && newUsers.length)
        newUsers.forEach((_user) => {
          payloadUsers.push({ email: _user });
        });

      const updatedRestrictions = await api.putDataCallById({
        dataPath: apiPaths.CALL.CHALLENGE_USERS_RESTRICTIONS,
        id: selectedRow.idChallenge,
        data: { users: payloadUsers, actionType },
        callConfig: {},
      });

      const newRestrictions = await api.getDataCallById({
        dataPath: apiPaths.CALL.CHALLENGE_USERS_RESTRICTIONS,
        registerId: selectedRow.idChallenge,
        callConfig: {},
      });

      if ('status' in updatedRestrictions && updatedRestrictions.status === 200) {
        if (updatedRestrictions.data.errorsArray && updatedRestrictions.data.errorsArray.length)
          renderErrors(updatedRestrictions.data.errorsArray, intl);

        if (newRestrictions.data) {
          const fetchedUsers: string[] = [];
          newRestrictions.data.forEach((_user: IRow) => {
            fetchedUsers.push(_user.idUser.email);
          });
          setUsers(fetchedUsers);
          setNewUsers([]);
          setRestrictionsHaveChanged(false);
        }
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      className="pointsModal"
      footer={null}
      style={{
        minWidth: window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '800px',
        maxHeight: window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '60vw',
        minHeight:
          window.innerWidth < config.BREAKPOINTS.MD ? '100vw' : '400px',
      }}
      visible={visibilityModalVisible}
      onCancel={handleCloseModal}>
      <Row className="pointsModal__content">
        <Row className="pointsModal__title">Visibility Administration</Row>
        {isLoading ? (
          <Row className="pointsModal__loading" />
        ) : (
          <AdminVisibilityForm
            {...{
              users,
              setUsers,
              validateUsers,
              actionType,
              setActionType,
              restrictionsHaveChanged,
              setRestrictionsHaveChanged,
              newUsers,
              setNewUsers,
              isLoading,
            }}
          />
        )}
      </Row>
    </Modal>
  );
}
