import { Button, Modal, Row /*notification */ } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, /*useEffect*/ useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import apiPaths from '../../apiPaths';
import UploadComponent from '../../challenge/Components/UploadComponent';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import { typesOfSelectEnum, UPLOAD_ACTION_DOCUMENT } from '../../shared';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

interface OwnProps {
  disabled: boolean;
  setValue: (value?: string | null, fileType?: typesOfSelectEnum) => void;
  token: string;
  value: any | any[];
  idArnSurvey: number;
}

const mapStateToProps = (state: ReducersState) => {
  return {
    token: state.auth.accessToken,
  };
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ConnectedUpload = connect(mapStateToProps)(
  ({ disabled, setValue, token, value, idArnSurvey }: Props) => {
    const myCustomAction = [{ ...UPLOAD_ACTION_DOCUMENT, label: 'document' }];
    const { formatMessage } = useIntl();
    return (
      <UploadComponent
        {...{
          disabled,
          uploadActions: myCustomAction,
          format: 'document',
          setValue,
          token,
          value,
          customUploadPath: `${apiPaths.UPLOAD_ARN_TOKEN}/${idArnSurvey}`,
        }}>
        <Row className="isdin-custom-modal">
          <Button
            className="ant-btn ant-btn-primary"
            disabled={disabled}
            size="small"
            type="primary"
            icon="upload"
            style={{ width: 350, height: 30 }}>
            {formatMessage({ id: 'uploadFilesModal.uploadFile' })}
          </Button>
        </Row>
      </UploadComponent>
    );
  },
);

export default function TokensArnSurveyButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { isLoading, selectedRow } = tableProps.props;

  function handleChangeDocumentUrl(value?: string | null) {
    setFileUrl(value ?? '');

    setShowConfirmModal(false);
  }

  return (
    <>
      <Button
        style={{ marginRight: '20px' }}
        onClick={() => setShowConfirmModal(true)}
        disabled={isEmpty(selectedRow)}
        type="primary">
        {formatMessage({ id: 'survey.arn.button-tokens' })}
      </Button>
      <Modal
        visible={showConfirmModal}
        centered
        footer={null}
        onCancel={() => setShowConfirmModal(false)}
        destroyOnClose>
        <Row className="isdin-custom-modal__content">
          <Row className="isdin-custom-modal__title">
            {formatMessage({ id: 'survey.arn.modal-tokens.title' })}
          </Row>
          <Row className="isdin-custom-modal__subtitle">
            {formatMessage({ id: 'survey.arn.modal-tokens.subtitle' })}
          </Row>
          <ConnectedUpload
            disabled={isLoading}
            setValue={handleChangeDocumentUrl}
            value={fileUrl}
            idArnSurvey={selectedRow.idArnSurvey}
          />
        </Row>
      </Modal>
    </>
  );
}
