export enum challengeTypes {
  CHALLENGE_INITIAL_STATE = '[CHALLENGE] set initial state',
  CHALLENGE_SET_LOADING = '[CHALLENGE] Set isLoading',
  CHALLENGE_CREATE = '[CHALLENGE] New challenge created',
  CHALLENGE_SET_EDITING_CHALLENGE = '[CHALLENGE] Set editingChallenge',
  CHALLENGE_SET_EDITING_RESOURCE = '[CHALLENGE] Set editingResource',
  CHALLENGE_SET_SELECTED_RESOURCE = '[CHALLENGE] Set selected Resource',
  CHALLENGE_UPDATE_DETAIL = '[CHALLENGE] Update resource detail',
  CHALLENGE_SET_RESOURCE_CHANGED = '[CHALLENGE] Set resourceHasChanged',
  CHALLENGE_RESOURCE_UPDATED = '[CHALLENGE] Resource updated',
  CHALLENGE_SET_QUIZ = '[CHALLENGE] Set quiz',
  CHALLENGE_RESET_QUIZ = '[CHALLENGE] Reset Quiz component',
  CHALLENGE_FETCH_TEMPLATES = '[CHALLENGE] Fetch templates',
  CHALLENGE_SORTING_RESOURCES = '[CHALLENGE] Sorting Resources',
  CHALLENGE_SAVE_SORTING = '[CHALLENGE] Save sorted resources',
  CHALLENGE_SET_BRANDS = '[CHALLENGE] Fetch Isdin Brands',
}
