import React, { FC } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import WebeatComponent from './WebeatComponent';
import { IPageComponent } from '../shared';
import { IPageProps } from '../WebeatPageComponents';

interface Props extends FormComponentProps {
  component: IPageComponent;
}

export type ComponentProps = IPageProps & Props;

const ResourceForm: FC<ComponentProps> = (props) => {
  return (
    <Form className="ResourceForm">
      <WebeatComponent {...props} />
    </Form>
  );
};

export const WebeatComponentForm = Form.create<ComponentProps>({
  name: 'resource_form',
})(ResourceForm);
