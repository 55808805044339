import React from 'react';
export default function language() {
  return (
    <svg viewBox="0 0 512.012 512.012" className="svgIconFill">
      <g>
        <path d="m495.266 394.775c11.112-23.435 16.742-48.57 16.742-74.794 0-93.61-74.304-170.285-167.347-174.787-20.968-75.53-89.845-128.213-168.653-128.213-97.047 0-176 78.505-176 175 0 26.224 5.63 51.359 16.742 74.794l-16.451 82.265c-2.094 10.472 7.144 19.728 17.618 17.656l83.279-16.465c20.862 9.896 43.054 15.426 66.17 16.529 29.148 104.951 146.217 157.593 243.452 111.471 91.207 18.032 84.094 16.75 86.189 16.75 9.479 0 16.56-8.686 14.709-17.941zm-385.17-73.6c-3.001-1.534-6.433-2.013-9.737-1.359l-66.24 13.097 13.049-65.25c.663-3.315.187-6.759-1.354-9.77-10.487-20.516-15.806-42.691-15.806-65.912 0-79.953 65.495-145 146-145 62.915 0 118.319 40.91 137.681 99.417-91.706 11.725-160.775 94.472-151.914 189.891-18.075-1.737-35.391-6.787-51.679-15.114zm354.753 74.488 13.049 65.25-66.24-13.097c-3.306-.653-6.736-.175-9.737 1.359-20.516 10.487-42.691 15.806-65.912 15.806-79.953 0-145-65.047-145-145s65.047-145 145-145c80.505 0 146 65.047 146 145 0 23.221-5.318 45.396-15.806 65.912-1.541 3.011-2.017 6.454-1.354 9.77z" />
      </g>
    </svg>
  );
}
