import React, { FC, useState } from 'react';
import { Button, Card, Col, Row, Tag } from 'antd';
import { isUndefined } from 'lodash';
import { IScanLandingListProps } from './ScanLanding';
import {
  TEMPLATE_OPTIONS,
  emptyImageBannerComponentModel,
  emptyImageComponentModel,
  emptyMainComponentModel,
  emptyReviewComponentModel,
  emptyVideoBannerComponentModel,
  emptyVideoComponentModel,
} from './constants';
import { ScanLandingComponentTypesEnum } from './enums';
import { appComponents } from '../components';
import { ScanLandingApiModel } from './ScanLandingForm';

const ScanLandingTemplateSelector: FC<IScanLandingListProps> = ({
  intl,
  params,
  setSelectedRow,
}) => {
  const { formatMessage } = intl;
  const targetId = appComponents[params.componentId].targetId;
  const [selectedCard, setSelectedCard] = useState<number>();

  // Handlers
  const handleOnClickCard = (card: number) => {
    setSelectedCard(card);
  };

  const handleOnClickButton = async () => {
    if (isUndefined(selectedCard)) {
      return;
    }

    const newValues: ScanLandingApiModel = {
      status: true,
      name: '',
      consumablesByPlatform: [{ consumables: [], platforms: [] }],
      components: TEMPLATE_OPTIONS[selectedCard].components.map(
        (component, index) => {
          let model = {};
          switch (component.type) {
            case ScanLandingComponentTypesEnum.MAIN:
              model = emptyMainComponentModel;
              break;
            case ScanLandingComponentTypesEnum.IMAGE:
              model = emptyImageComponentModel;
              break;
            case ScanLandingComponentTypesEnum.IMAGE_BANNER:
              model = emptyImageBannerComponentModel;
              break;
            case ScanLandingComponentTypesEnum.VIDEO:
              model = emptyVideoComponentModel;
              break;
            case ScanLandingComponentTypesEnum.VIDEO_BANNER:
              model = emptyVideoBannerComponentModel;
              break;
            case ScanLandingComponentTypesEnum.REVIEW:
              model = emptyReviewComponentModel;
              break;
          }
          return { type: component.type, position: index, model };
        },
      ),
    };
    setSelectedRow({ componentId: targetId, selectedRow: newValues });
  };

  return (
    <section style={{ margin: '16px 24px' }}>
      <h1>{formatMessage({ id: 'scanLanding.template-selector.title' })}</h1>
      <h3>{formatMessage({ id: 'scanLanding.template-selector.subtitle' })}</h3>
      <Row
        type="flex"
        gutter={[16, 16]}
        style={{
          backgroundColor: '#f0efef',
          padding: '8px',
          margin: '16px 0',
        }}>
        {TEMPLATE_OPTIONS.map((template, index) => (
          <Col span={4} key={index}>
            <Card
              style={{ height: '100%', borderColor: '#000' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '16px 24px',
                width: '100%',
              }}
              hoverable
              bordered={selectedCard === index}
              onClick={() => handleOnClickCard(index)}
              onDoubleClick={handleOnClickButton}>
              {template.components.map((component, indexComponent) => (
                <Tag
                  key={`${index}-${indexComponent}`}
                  style={{
                    margin: '4px',
                    padding: '4px 12px',
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                  }}>
                  {component.name}
                </Tag>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
      <Row type="flex" justify="end">
        <Button
          type="primary"
          disabled={isUndefined(selectedCard)}
          onClick={handleOnClickButton}>
          {formatMessage({ id: 'scanLanding.template-selector.button' })}
        </Button>
      </Row>
    </section>
  );
};

export default ScanLandingTemplateSelector;
