import { Icon, Layout } from 'antd';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../reducers';

import config from '../config';
import './MainWrapper.css';

import { IBreadcrumb } from '../breadcrumb/SmartBreadcrumbInterfaces';
import { MenuItem } from './AppInterfaces';

import { setMenuState } from './appActions';
import {
  getQueryParams,
  navigate,
  resetBreadcrumbs,
  setBreadcrumbs,
  setInitialQueryParams,
} from './queryActions';

import SmartBreadCrumb from '../breadcrumb/SmartBreadcrumb';
import DashboardRouter from '../dashboardsConfig/DashboardRouter';
import HeaderContent from '../header/HeaderContent';
import { feedback } from '../utils';
import SideMenu from './SideMenu';

const { Header, Sider, Content } = Layout;

interface OwnProps {
  menu: MenuItem[] | null;
}
export type TMainWrapperListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class MainWrapper extends React.PureComponent<TMainWrapperListProps, {}> {
  constructor(props: TMainWrapperListProps) {
    super(props);
    const { router, query, setInitialQueryParams, getQueryParams } = this.props;
    const { location } = router;
    if (!query.queryParamsLoaded) {
      getQueryParams();
      if (!isEmpty(location.search))
        setInitialQueryParams({ initialParams: location.search });
    }
  }

  isInHomePage = () => {
    const { pathname } = this.props;
    return pathname === '/Home';
  };

  mustRenderLayout = () => {
    const { pathname } = this.props;
    return pathname !== '/Home' && pathname !== '/';
  };

  switchMenuState = (pathname: string, menuState: number | string) => {
    if (pathname === '/Home') {
      return 0;
    } else {
      switch (menuState) {
        case 0:
          return 1;
        case 1:
          return window.innerWidth < config.BREAKPOINTS.MD ? 0 : 2;
        case 2:
          return 1;
        default:
          return 1;
      }
    }
  };

  handleChangeMenuState = (menuState: number | string, broken?: boolean) => {
    const { setMenuState, pathname } = this.props;
    // Change menu size action (when menu button is pressed)

    if (!menuState && menuState !== 0) return;

    if (typeof broken === 'undefined') {
      setMenuState({
        menuState: this.switchMenuState(pathname, menuState),
      });
      // Swith between collapsed menu and full-collapsed menu
    } else if (typeof broken !== 'undefined') {
      if (menuState === 1 || (menuState === 2 && broken)) {
        setMenuState({
          menuState: 0,
        });
      }
    }
  };

  siderClassName = (menuState: number | string) => {
    switch (menuState) {
      case 0:
        return '';
      case 1:
        return 'mainWrapper__sider--collapsed';
      case 2:
        return 'mainWrapper__sider';
      default:
        return '';
    }
  };

  render() {
    const {
      pathname,
      menu,
      menuState,
      setBreadcrumbs,
      router,
      breadcrumbs,
      resetBreadcrumbs,
    } = this.props;
    const userCanAccessToDashboard = menu?.some((menuItem) =>
      menuItem.subMenu.some(({ route, subMenu }) => route === pathname || subMenu.some(({ route }) => route === pathname)),
    );

    if (!userCanAccessToDashboard && !['/Home'].includes(pathname)) {
      feedback({
        type: 'notification',
        method: 'warning',
        message:
          'No tienes permsisos para acceder a esta pantalla. Contacta con el Administrador.',
        duration: 10,
      });

      return <Redirect to={{ pathname: '/Home' }} />;
    }

    const mainStyle = cn(
      'main',
      menuState !== 2
        ? {
            collapsed: true,
          }
        : { normal: true },
    );

    const logoWrapper = (
      <div
        className={menuState === 0 ? 'logoWrapperResponsive' : 'logoWrapper'}>
        <div
          className={
            menuState === 1 || menuState === 0
              ? 'logoWrapper__logoCollapsed'
              : 'logoWrapper__logoExtended'
          }
        />
      </div>
    );

    const showLogo =
      config.MENU.LOGO_SHOW && config.WELCOME.SHOW ? (
        <Link to={'/Home'}>{logoWrapper}</Link>
      ) : (
        logoWrapper
      );

    const header = this.mustRenderLayout() && (
      <Header>
        <HeaderContent menuState={menuState} />
      </Header>
    );
    const breadcrumb = config.COMPONENT.BREADCRUM.SHOW &&
      this.mustRenderLayout() && <SmartBreadCrumb />;

    const contentClassName = () =>
      this.isInHomePage() ? 'main__content--home' : 'main__content';

    if (pathname !== '/Home') {
      if (
        !isEmpty(breadcrumbs) &&
        breadcrumbs[breadcrumbs.length - 1].absolutePath !== pathname
      ) {
        const index = breadcrumbs.findIndex(
          (bc: IBreadcrumb) => bc.absolutePath === pathname,
        );
        index === -1
          ? resetBreadcrumbs({ path: pathname, search: router.location.search })
          : setBreadcrumbs({ breadcrumbs, index });
      }
    }
    
    return (
      <Layout className="mainWrapper">
        {!this.isInHomePage() && (
          <Sider
            width={220}
            collapsedWidth={menuState === 0 ? 0 : 60}
            breakpoint={'md'}
            onBreakpoint={(broken: boolean) =>
              this.handleChangeMenuState(menuState, broken)
            }
            onCollapse={(collapsed: boolean, type: string) =>
              this.handleChangeMenuState(menuState)
            }
            collapsible
            collapsed={menuState === 1 || menuState === 0}
            className={this.siderClassName(menuState)}
            reverseArrow={
              menuState === 1 && window.innerWidth < config.BREAKPOINTS.MD
            }>
            {showLogo}
            <SideMenu
              menu={menu}
              pathname={pathname}
              navigate={this.props.navigate}
            />
          </Sider>
        )}

        <Layout className={mainStyle}>
          {header}
          <Content className={contentClassName()}>
            {breadcrumb}
            <DashboardRouter {...this.props} />
          </Content>
        </Layout>
        {this.props.challengeLoading ? (
          <div className="LoadingModal">
            <Icon type="loading" />
          </div>
        ) : null}
      </Layout>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setMenuState,
      navigate,
      setInitialQueryParams,
      getQueryParams,
      setBreadcrumbs,
      resetBreadcrumbs,
    },
    dispatch,
  );

const mapStateToProps = (state: ReducersState) => ({
  token: state.auth.refreshToken,
  menuState: state.app.menuState,
  pathname: state.router.location.pathname,
  query: state.query,
  router: state.router,
  breadcrumbs: state.query.breadcrumbs,
  challengeLoading: state.challenge.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainWrapper);
