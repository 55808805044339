import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const MarketingMaterialsDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;
  const platform =
    props.platforms.filter(
      (platform: any) => platform.value === props.currentPlatform,
    )[0] ?? [];
  const customButtons = platform?.platform_code !== 'pin-pt' && [
    'addVademecumButton',
  ];

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'marketingMaterialsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Name',
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },

        {
          title: 'Type',
          key: 'type',
          type: 'combo',
          operand: 'equal',
          min: 0,
          comboId: 'materialType',
          length: 0,
          mandatory: false,
          disabled: false,
        },

        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'marketingMaterialsTable',
        rowKey: 'idMaterial',
        panelHeader: 'Marketing Materials List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
        // customButtons: ['materialsI18nButton', 'addVademecumButton'],
        // customButtons: ['addVademecumButton'],
        customButtons,
      },
      fields: [
        {
          title: 'Name',
          key: 'name',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Type',
          key: 'type',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          render: 'combo',
          comboId: 'materialType',
        },
        {
          //TODO IMPLEMENT M2M
          title: 'Brand',
          key: 'brand',
          position: 1,
          mustRender: false,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          render: 'combo',
          comboId: 'bobrands',
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'comboCustom',
          comboId: 'status',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'marketingMaterialsEdit',
        panelHeader: 'Marketing Materials Edit',
        primaryKey: 'idMaterial',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 16,
                },
                {
                  key: 'order',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'type',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'brand',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'categories',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'description',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'uploads',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'idChallenge.idChallenge',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Name',
          key: 'name',
          type: 'text',
          length: 50,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Type',
          key: 'type',
          type: 'combo',
          comboId: 'materialType',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Categories',
          key: 'categories',
          type: 'combo',
          comboId: 'categories',
          multiSelect: true,
          multiSelectId: 'idCategory',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Brand',
          key: 'brand',
          type: 'combo',
          comboId: 'bobrands',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Order',
          key: 'order',
          type: 'number',
          min: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Description',
          key: 'description',
          type: 'textarea',
          length: 90,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idMaterial',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
        },
        {
          title: 'Featured',
          key: 'featured',
          type: 'checkSelect',
          unique: false,
          mandatory: true,
          // initialDisabled: true,
          // behaviours: [
          //   [
          //     {
          //       key: 'id_menu',
          //       operand: 'notEmpty',
          //       type: 'disabled',
          //     },
          //   ],
          // ],
          initialValue: 'true',
        },
        {
          title: 'Uploads',
          key: 'uploads',
          type: 'customField',
        },
        {
          title: 'Training',
          key: 'idChallenge.idChallenge',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'trainingCombo',
          mandatory: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'type',
                operand: 'like',
                type: 'visible',
                value: '5',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'MarketingMaterialsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};
export default connect(mapStateToProps, null)(MarketingMaterialsDashboard);
// export default MarketingMaterialsDashboard;
