import React, { useState } from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { Button } from 'antd';
import AddSurveyModal from '../../challenge/Components/AddSurveyModal';
import { isEmpty } from 'lodash';
import { isTableProps } from '../../utils/propsUtils';

export default function AddSurveyMailButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [modalVisible, setModalVisible] = useState(false);
    const { selectedRow, params } = tableProps.props;
    const { rowKey } = params;

    return (
      <>
        <Button
          style={{ marginRight: '17px' }}
          onClick={() => setModalVisible(true)}
          disabled={isEmpty(selectedRow)}
          type="primary">
          Add survey
        </Button>
        <AddSurveyModal
          id={selectedRow[rowKey]}
          surveyModalVisibility={modalVisible}
          setSurveyModalVisibility={setModalVisible}
        />
      </>
    );
  }
  return <></>;
}
