import { Button, Col, Modal, notification, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { UserResourceStatusEnum } from '../../challenge/Enums';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import { IPearlDetail } from '../../challenge/ChallengeInterfaces';

export default function PearlsButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showModal, setShowModal] = useState(false);
  const [pearlValue, setPearlValue] = useState<IPearlDetail | null>(null);
  const [selectedImage, setSelectedImage] = useState('');
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  const { statusResource } = selectedRow;

  const handleValidate = async ({
    newStatusResource,
  }: {
    newStatusResource: number;
  }) => {
    let response;
    try {
      response = await api.putDataCall({
        dataPath: apiPaths.CALL.PEARLS,
        data: {
          idUserResource: selectedRow.idUserResource,
          statusPearl: newStatusResource,
        },
        callConfig: {},
      });

      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'assign-points.notification-title',
          }),
          description: formatMessage({
            id: 'assign-points.notification-description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowModal(false);
      await tableProps.handleReloadData();
    }
  };

  const handleShowModal = async ({ selectedRow }: { selectedRow: any }) => {
    setPearlValue(JSON.parse(selectedRow.value));
    setShowModal(true);
  };

  const handleCloseModal = async () => {
    setShowModal(false);
  };

  const buttonsDisabled =
    isEmpty(selectedRow) ||
    ![UserResourceStatusEnum.PENDING].includes(statusResource);

  return (
    <>
      <Button
        disabled={isEmpty(selectedRow) || !selectedRow?.value}
        type="primary"
        onClick={() => handleShowModal({ selectedRow: selectedRow })}>
        {formatMessage({ id: 'pearls.button-show' })}
      </Button>

      <Modal
        visible={showModal}
        footer={false}
        onCancel={handleCloseModal}
        title={formatMessage({ id: 'pearls.detail' })}>
        <Row>
          <Col span={12} style={{ padding: '1rem' }}>
            <Row>{pearlValue?.title}</Row>
            <Row style={{ marginTop: '1rem' }}>
              <p>{pearlValue?.description}</p>
            </Row>
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            <div>
              <img
                src={
                  selectedImage !== '' ? selectedImage : pearlValue?.image[0]
                }
                height={'150px'}
                alt={'selectedImage'}
                className="max-width-100"
              />
            </div>
            <Row style={{ marginTop: '1rem' }}>
              {pearlValue?.image.map((value, index) => {
                return (
                  <Col span={6} style={{ margin: '.5rem', cursor: 'pointer' }}>
                    <img
                      key={index}
                      src={value}
                      className="max-width-100"
                      alt={value}
                      height={'50px'}
                      onClick={() => setSelectedImage(value)}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={12}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() =>
                handleValidate({
                  newStatusResource: UserResourceStatusEnum.DENY,
                })
              }
              disabled={buttonsDisabled}
              type="primary">
              {formatMessage({ id: 'assign-points.button-deny' })}
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() =>
                handleValidate({
                  newStatusResource: UserResourceStatusEnum.VALIDATED,
                })
              }
              disabled={buttonsDisabled}
              type="primary">
              {formatMessage({ id: 'assign-points.button-validate' })}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
