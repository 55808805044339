import React from 'react';
import { Button } from 'antd';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';

export default function BaseButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parenProps: T) {
  return <Button> Test button</Button>;
}
