import React from 'react';
import ContentPageForm from './components/PageForm';
import PageComponents from './PageComponents';
import { IPageListProps } from './ContentPage';
import { IPageRenderProps } from './shared';

export type IPageRenderListProps = IPageListProps & IPageRenderProps;
export default function ContentPageRender(props: IPageRenderListProps) {
  const { params, refreshPageData, values } = props;

  return (
    <>
      <ContentPageForm {...props} />
      {values && values.idContentPage && (
        <PageComponents
          {...{
            params,
            refreshPageData,
          }}
        />
      )}
    </>
  );
}
