import { contentPageTypes } from './contentPageTypes';
import { ContentPageActionTypes } from './contentPageActions';
import { IPageComponent } from '../shared';
import { ComboData } from '../../combos/ComboInterfaces';

export interface ContentPageState {
  componentHasChanged: boolean;
  editComponent: boolean;
  isLoading: boolean;
  selectedComponent: IPageComponent;
  sortingComponents: boolean;
  challengeTypeCombo: ComboData[];
  productsCombo: ComboData[];
  gridProductsCombo: ComboData[];
}

export const initialState: ContentPageState = {
  componentHasChanged: false,
  editComponent: false,
  isLoading: false,
  selectedComponent: {} as IPageComponent,
  sortingComponents: false,
  challengeTypeCombo: [],
  productsCombo: [],
  gridProductsCombo: [],
};

export function contentPageReducer(
  state = initialState,
  action: ContentPageActionTypes,
): ContentPageState {
  switch (action.type) {
    case contentPageTypes.PAGE_ADD_DETAIL:
    case contentPageTypes.PAGE_CREATE_COMPONENT:
    case contentPageTypes.PAGE_DELETE_COMPONENT:
    case contentPageTypes.PAGE_DELETE_DETAIL:
    case contentPageTypes.PAGE_EDIT_DETAIL:
    case contentPageTypes.PAGE_EDITING_COMPONENT:
    case contentPageTypes.PAGE_INITIAL_STATE:
    case contentPageTypes.PAGE_RESET_COMPONENT:
    case contentPageTypes.PAGE_SAVE_SORTING:
    case contentPageTypes.PAGE_SET_COMPONENT_CHANGED:
    case contentPageTypes.PAGE_SET_LOADING:
    case contentPageTypes.PAGE_SET_SELECTED_COMPONENT:
    case contentPageTypes.PAGE_SORTING_COMPONENTS:
    case contentPageTypes.PAGE_UPDATE_COMPONENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
