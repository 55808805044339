import { Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import {
  IndexedDetail,
  IResource,
  IResourceDetail,
} from '../../ChallengeInterfaces';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';
import { ComboData } from '../../../combos/ComboInterfaces';
import {
  ExternalRedeemPointsTypeEnum,
  ResourceTypeDetailEnum,
} from '../../Enums';
import { IRow } from '../../../app/AppInterfaces';

const {
  EXTERNAL_REDEEM_POINTS_POINTS_VALUE,
  EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE,
} = ResourceTypeDetailEnum;

const { EXTERNAL_REDEEM_DONATION, EXTERNAL_REDEEM_ISD } =
  ExternalRedeemPointsTypeEnum;

interface IExternalRedeemPointsPoints {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IExternalRedeemPointsPoints;

const ExternalRedeemPointsPoints: FC<IGroupProps> = ({
  _resource,
  selectedResource,
  accessToken,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const [externalRedeemPointsType, setExternalRedeemPointsType] =
    useState<string>('');
  const { resourceDetailList } = _resource;

  const externalRedeemPointsPointsDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status)
      externalRedeemPointsPointsDetails.push({ index, detail });
  });

  useEffect(() => {
    const typeResourceDetail = selectedResource?.resourceDetailList?.find(
      (resourceDetail: IResourceDetail) =>
        resourceDetail.idResourceTypeD.idResourceTypeD ===
        ResourceTypeDetailEnum.EXTERNAL_REDEEM_POINTS_TYPE,
    );

    if (typeResourceDetail?.value === EXTERNAL_REDEEM_DONATION)
      setExternalRedeemPointsType(EXTERNAL_REDEEM_ISD);
    else setExternalRedeemPointsType(EXTERNAL_REDEEM_DONATION);
  }, [selectedResource, externalRedeemPointsType]);

  if (!externalRedeemPointsPointsDetails) return <Fragment />;

  const setDisabledDetails = (detail: IResourceDetail): boolean => {
    const { idResourceTypeD } = detail.idResourceTypeD;

    if (
      [
        EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE,
        EXTERNAL_REDEEM_POINTS_POINTS_VALUE,
      ].includes(idResourceTypeD)
    )
      return true;

    return false;
  };

  return (
    <Row gutter={[24, 0]}>
      {externalRedeemPointsPointsDetails
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ detail, index }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;

          let combo: ComboData[] | undefined = undefined;

          if (idResourceTypeD === EXTERNAL_REDEEM_POINTS_POINTS_VALUE) {
            combo =
              combos.challengechallengeEdit?.checkoutProductType
                ?.checkoutProductType?.data ?? [];
          }

          return RenderDetail({
            accessToken,
            combo,
            detail,
            detailArrayPosition: index,
            editResource:
              editResource &&
              externalRedeemPointsType ===
                ExternalRedeemPointsTypeEnum.EXTERNAL_REDEEM_ISD
                ? setDisabledDetails(detail)
                : editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            values,
          });
        })}
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
    combos: state.combos.combos,
    selectedResource: state.challenge.selectedResource,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalRedeemPointsPoints);
