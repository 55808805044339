import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';
import { PIN_PlatformID_Enum } from '../shared';
import { isConPlatform, isPINPlatform } from '../utils';
import { BoPermissionsEnum } from '../challenge/Enums/permissions.enum';
import { IUserPermission } from '../app/AppInterfaces';

const UsersDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { MID } = config.COMPONENT.COLUMN;
  const platform =
    props.platforms.filter(
      (platform: any) => platform.value === props.currentPlatform,
    )[0] ?? [];

  const customButtons =
    platform?.platform_type === 'con'
      ? [
          'impersonateButton',
          'pointsManagementButton',
          'resetPasswordButton',
          'tokensUserList',
          'softDeleteUserButton',
          'validateUserButton',
          'liconMassPointsManagementButton',
        ]
      : [
          'impersonateButton',
          'resetPasswordButton',
          'deleteCustomerCartItems',
          'deleteMassiveUsers',
          'tokensUserList',
        ];

  const platformTableFields = isConPlatform()
    ? [
        {
          title: formatMessage({ id: 'challenge.role' }),
          key: 'userRoles',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.country' }),
          key: 'idCountry.name',
          render: 'text',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.language' }),
          key: 'idLanguage.name',
          render: 'text',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
      ]
    : [
        {
          title: formatMessage({ id: 'users.specialty' }),
          key: 'idSpecialty',
          render: 'text',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.resident' }),
          key: 'isUserResident',
          render: 'comboCustom',
          comboId: 'optional',
          position: 16,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.graduation-year' }),
          key: 'graduationYear',
          render: 'text',
          position: 17,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
      ];

  const permissionToEditEmailNameSurname = props.userPermissions?.find(
    (permission: IUserPermission) =>
      permission.textMachine === BoPermissionsEnum.LIWW_EMAIL_NAME_SURNAME_UPDATE,
  );

  const components = [
    {
      params: {
        type: 'search',
        componentId: isPINPlatform()
          ? 'usersusersSearchLiww'
          : 'usersusersSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'userStatus',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'blacklisted',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                isPINPlatform() && {
                  key: 'usersSearchCriteria',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                isPINPlatform() && {
                  key: 'isUserResident',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Email',
          key: 'email',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 50,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: 'Id User',
          key: 'idUser',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'users.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
        !isConPlatform() && {
          title: formatMessage({ id: 'users.specialty' }),
          key: 'idSpecialty.idSpecialty',
          type: 'combo',
          comboId: 'specialty',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'users.userStatus' }),
          key: 'userStatus',
          type: 'combo',
          comboId: 'userStatus',
          operand: 'equal',
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'users.blacklisted' }),
          key: 'blacklisted',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          //initialValue: false,
        },
        isPINPlatform() && {
          title: formatMessage({ id: 'users.usersSearchCriteria' }),
          key: 'usersSearchCriteria',
          type: 'comboCustom',
          comboId: 'usersSearchCriteria',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'email',
          isSearchCriteria: true,
        },
        isPINPlatform() && {
          title: formatMessage({ id: 'users.resident' }),
          key: 'isUserResident',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        canCreate: false,
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: isPINPlatform() ? 'usersusersListLiww' : 'usersusersList',
        rowKey: 'idUser',
        panelHeader: 'Users List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'email',
        customButtons,
      },
      fields: [
        ...platformTableFields,
        {
          title: formatMessage({ id: 'users.id' }),
          key: 'idUser',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({ id: 'users.email' }),
          key: 'email',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ASC',
        },
        {
          title: 'Uuid user',
          key: 'uuidUser',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id salesforce',
          key: 'idSalesforce',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Id magento',
          key: 'customerId',
          position: 2,
          mustRender: isPINPlatform(),
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({ id: 'users.userStatus' }),
          key: 'userStatus',
          render: 'combo',
          comboId: 'userStatus',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'labels.create-date' }),
          key: 'createDate',
          render: 'datetime',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.lastUpdated' }),
          key: 'updateDate',
          render: 'datetime',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.lastLogin' }),
          key: 'lastLogin',
          render: 'datetime',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.isMigrated' }),
          key: 'isMigrated',
          render: 'comboCustom',
          comboId: 'optional',
          position: 12,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: isPINPlatform(),
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.blacklisted' }),
          key: 'blacklisted',
          render: 'comboCustom',
          comboId: 'optional',
          position: 13,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: isConPlatform(),
          width: MID,
        },
        {
          title: formatMessage({ id: 'users.isPasswordSet' }),
          key: 'password',
          render: 'text',
          position: 14,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: isPINPlatform(),
          width: MID,
        },
        {
          title: 'Disabled date',
          key: 'disableDate',
          render: 'datetime',
          position: 15,
          mustRender: isPINPlatform(),
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: false,
        canDelete:
          platform?.platform_type === 'con' ||
          platform?.platform_code === PIN_PlatformID_Enum.PIN_USA
            ? false
            : {
                defaultDisabled: true,
                behaviours: [
                  {
                    type: 'disabled',
                    key: 'userStatus',
                    value: '3',
                    operand: 'like',
                  },
                ],
              },
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: !isPINPlatform() ? 'usersusersEdit' : 'usersusersEditLiww',
        panelHeader: formatMessage({ id: 'users.edit' }),
        primaryKey: 'idUser',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                isPINPlatform() && {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                  mandatory: true
                },
                isPINPlatform() && {
                  key: 'surname',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                  mandatory: true
                },
                {
                  key: 'email',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                !isConPlatform() && {
                  key: 'idSpecialty',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'userStatus',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  title: formatMessage({ id: 'users.userStatus' }),
                  key: 'userStatus',
                  type: 'combo',
                  comboId: 'userStatus',
                  alwaysDisabled: true,
                },
                {
                  key: 'blacklisted',
                  yPosition: 7,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                  visible: isConPlatform(),
                },
              ],
            },
          ],
        },
      ],
      fields: [
        isPINPlatform() && {
          title: 'Name',
          key: 'name',
          type: 'text',
          disabled: true // !permissionToEditEmailNameSurname,
        },
        isPINPlatform() && {
          title: 'Surname',
          key: 'surname',
          type: 'text',
          disabled: true // !permissionToEditEmailNameSurname,
        },
        {
          title: 'Email',
          key: 'email',
          type: 'text',
          disabled: !permissionToEditEmailNameSurname,
        },
        {
          title: 'Id User',
          key: 'idUser',
          type: 'number',
        },
        {
          title: formatMessage({ id: 'users.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          disabled: isPINPlatform() ? false : true,
        },
        !isConPlatform() && {
          title: formatMessage({ id: 'users.specialty' }),
          key: 'idSpecialty',
          type: 'text',
          alwaysDisabled: true,
        },
        {
          title: formatMessage({ id: 'users.userStatus' }),
          key: 'userStatus',
          type: 'combo',
          comboId: 'userStatus',
          alwaysDisabled: true,
        },
        {
          title: formatMessage({ id: 'users.blacklisted' }),
          key: 'blacklisted',
          type: 'comboCustom',
          comboId: 'optional',
          alwaysDisabled: false,
        },
        {
          title: formatMessage({ id: 'users.userStatus' }),
          key: 'userStatus',
          type: 'combo',
          comboId: 'userStatus',
          alwaysDisabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEditClose: true,
        canCreate: false,
        canDelete:
          platform?.platform_type !== 'con' &&
          platform?.platform_code === PIN_PlatformID_Enum.PIN_USA,
        askConfirm: isPINPlatform() ? true : false
      },
    },
  ];

  const componentId = 'UsersDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
    userPermissions: state.app.userPermissions,
  };
};
export default connect(mapStateToProps, null)(UsersDashboard);
