import React from 'react';
import config from '../config';
import moment from 'moment';
import { Form, Tooltip, Icon, Col, Menu, Dropdown, Button } from 'antd';

import BehaviourField from '../fields/BehaviourField';
import Fields from '../fields/Fields';
// import advancedSearch from '../fields/advancedSearch';

import { ICompleteField } from './formInterfaces';
import { SearchFormRenderProps } from './search/SearchFormRender';
import { EditFormRenderProps } from './edit/EditFormRender';

import './FormStyles.css';
import CustomField from '../fields/CustomFields';
import WYSWYGComponent from '../contentPage/components/WYSWYGComponent';
import { IRow } from '../app/AppInterfaces';
import { isEmpty } from 'lodash';

/**
 * This functions build a Dropdown, which shows a menu that will allow the user to switch
 * between multiple search options (>,<,>=, <=, ...)
 * This function will be launched if operand != 'like'
 * @param {Object} field to be modified
 * @param {Object} options style options
 * @return Dropdown, Menu & corresponding Menu.Items
 */

function isSearch(
  search: SearchFormRenderProps | EditFormRenderProps,
): search is SearchFormRenderProps {
  if ((search as SearchFormRenderProps).props.params.type === 'search') {
    return true;
  }
  return false;
}

const operandSelector = <T extends SearchFormRenderProps | EditFormRenderProps>(
  field: ICompleteField,
  parentProps: T,
) => {
  const { props } = parentProps;
  const { intl, options } = props;
  const handleChangeFieldOperator:
    | ((f: ICompleteField, operand: string) => void)
    | null = isSearch(parentProps)
    ? parentProps.handleChangeFieldOperator
    : null;
  const queryOptions = [
    {
      key: 0,
      description: intl.formatMessage({ id: 'search.operator.greater' }),
      operand: 'greater',
      icon: 'right',
    },
    {
      key: 1,
      description: intl.formatMessage({ id: 'search.operator.lower' }),
      operand: 'less',
      icon: 'left',
    },
    {
      key: 2,
      description: intl.formatMessage({ id: 'search.operator.greaterequal' }),
      operand: 'greater|equal',
      icon: 'caret-right',
    },
    {
      key: 3,
      description: intl.formatMessage({ id: 'search.operator.lowerequal' }),
      operand: 'less|equal',
      icon: 'caret-left',
    },
  ];

  const menuItems: JSX.Element[] = [];
  queryOptions.forEach((option) => {
    menuItems.push(
      <Menu.Item
        key={option.operand}
        onClick={() =>
          switchOperatorHandle(option.operand, field, handleChangeFieldOperator)
        }>
        <Icon type={option.icon} />
        {option.description}
      </Menu.Item>,
    );
  });

  const menu = (
    <Menu
      selectedKeys={[field.operand!]}
      defaultSelectedKeys={[field.operand!]}>
      {menuItems}
    </Menu>
  );
  if (field.operand !== 'like') {
    // const operandSelected = queryOptions.filter(obj => {
    //   return obj.operand == field.operand;
    // });
    return (
      <Dropdown overlay={menu} className="dropdownAlign">
        <Button style={{ marginLeft: 8 }} size={options.fieldSize}>
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }
};

/**
 * This function takes the new operand and sets the new Field in Redux state
 * This will allow to make different queries (greater/lower than ...)
 * @param {String} op New operand
 * @param {Object} field which will change the operand
 */
const switchOperatorHandle = (
  op: string,
  selectedField: ICompleteField,
  handleChangeFieldOperator:
    | ((f: ICompleteField, operand: string) => void)
    | null,
) => {
  handleChangeFieldOperator!(selectedField, op);
};

const getMultiSelectValues = (field: ICompleteField, values: IRow) => {
  const { key, multiSelectId } = field;
  if (!values || !multiSelectId) return [];
  const selectValues = values?.[key] || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a[multiSelectId] - b[multiSelectId])
    .map((value: IRow) => value[multiSelectId].toString());
};

export function getValueByPath(object: IRow, pathString: string) {
  // eslint-disable-next-line no-useless-escape
  let pathArray = pathString.split(/[\.\[\]]/).filter(Boolean);
  let currentObject = object;

  for (let i = 0; i < pathArray.length; i++) {
    const key = pathArray[i];

    const arrayIndex = parseInt(key, 10);
    if (!isNaN(arrayIndex)) {
      if (Array.isArray(currentObject) && arrayIndex < currentObject.length) {
        currentObject = currentObject[arrayIndex];
      } else {
        return undefined;
      }
    } else if (currentObject && currentObject[key] !== undefined) {
      currentObject = currentObject[key];
    } else {
      return undefined;
    }
  }
  return currentObject;
}

export default function fieldRender(
  field: ICompleteField,
  parentProps: SearchFormRenderProps | EditFormRenderProps,
) {
  const { props } = parentProps;
  const { form, intl, values, combos, params } = props;
  const { getFieldDecorator } = form;
  const { componentId, type } = params;

  const timeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS+0000';
  let value = field.multiSelect
    ? getMultiSelectValues(field, values)
    : values[field.key] || getValueByPath(values, field.key);

  if (
    (values.hasOwnProperty(field.key) || values[field.key] === 0) &&
    value !== null
  ) {
    switch (field.type) {
      case 'date':
      case 'datetime':
        value = moment(values[field.key], timeFormat);
        break;
      case 'combo':
      case 'advancedSearch':
        if (
          combos.combos[componentId] &&
          combos.combos[componentId][field.key] &&
          combos.combos[componentId][field.key][field.comboId!] &&
          combos.combos[componentId][field.key][field.comboId!].hasOwnProperty(
            'isLoading',
          ) &&
          !combos.combos[componentId][field.key][field.comboId!].isLoading
        ) {
          if (!field.multiSelect) value = values[field.key].toString();
        } else value = 'Cargando...';
        break;
      case 'selector':
        if (
          combos.combos[componentId] &&
          combos.combos[componentId][field.key] &&
          combos.combos[componentId][field.key][field.comboId!] &&
          combos.combos[componentId][field.key][field.comboId!].hasOwnProperty(
            'isLoading',
          ) &&
          !combos.combos[componentId][field.key][field.comboId!].isLoading
        ) {
          if (!field.multiSelect) value = values[field.key].toString();
          else if (field.multiSelect && !field.multiSelectId)
            value = values[field.key].map((value: any) => value.toString());
        } else value = 'Cargando...';
        break;
      case 'comboCustom':
      case 'radio':
        value = values[field.key].toString();
        break;
      case 'time':
        value = moment(values[field.key], timeFormat).utc();
        value.add(2, 'hours');
        break;
      case 'currency':
      case 'percent':
        value = parseFloat(value).toFixed(2);
        break;
      case 'checkSelect':
      case 'check':
        if (values[field.key] !== undefined) {
          value = value.toString();
        }
        break;
      case 'rangerpicker':
        value = value.split('.');
        const newValue: moment.Moment[] = [];
        value.forEach((v: string) => {
          newValue.push(moment(new Date(v)).utc());
        });
        value = newValue;
        break;
      default:
        break;
    }
  }

  let fieldRules: { [key: string]: any }[] = [
    {
      required: field.type !== 'checkSelect' && field.mandatory,
      message: intl.formatMessage({ id: 'update.required' }),
    },
  ];
  if (field.type === 'email') fieldRules.push({ type: field.type });

  if (
    (type === 'edit' ||
      field.operand === 'like' ||
      field.operand === 'equal' ||
      field.operand === undefined) &&
    field.type !== 'advancedSearch'
  ) {
    if (field.type === 'wysiwyg') {
      const value = values[field.key];
      return (
        <Col lg={field.width} md={24} key={field.key}>
          <Form.Item>
            <WYSWYGComponent
              {...{
                title: field.title,
                value,
                handleEdit: (value: string) => {
                  if (value)
                    parentProps.handleChangeField({
                      id: field.key,
                      type: 'text',
                      value,
                    });
                },
              }}
            />
          </Form.Item>
        </Col>
      );
    } else {
      return (
        <Col lg={field.width} md={24} key={field.key}>
          {field.type === 'customField' ? (
            CustomField({ field, parentProps, values, componentId })
          ) : (
            <Form.Item
              label={
                field.type !== 'check' &&
                (field.tooltip ? (
                  <span>
                    {'parentFieldValue' in field &&
                    values[field.parentFieldValue!]
                      ? values[field.parentFieldValue!]
                      : field.title}
                    <Tooltip className="fieldTooltip" title={field.tooltip}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                ) : 'parentFieldValue' in field &&
                  values[field.parentFieldValue!] ? (
                  values[field.parentFieldValue!]
                ) : (
                  field.title
                ))
              }
              colon={false}
              extra={field.extra}>
              {getFieldDecorator(field.key, {
                initialValue: value,
                rules: fieldRules,
              })(
                field.behaviours !== undefined
                  ? BehaviourField({
                      parentProps,
                      field,
                      hidden: false,
                    })
                  : Fields(field, parentProps),
              )}
            </Form.Item>
          )}
        </Col>
      );
    }
  } else {
    return (
      <div key={field.key.concat('addon')}>
        <Col
          md={config.COMPONENT.FORM.ADVANCED_SEARCH_SIZE}
          key={'addon'.concat(field.key)}
          className="dropdownCustom">
          {/*TODO CHECK VISIBILITY*/}
          {getFieldDecorator('search'.concat(field.key))(
            field.type === 'advancedSearch' ? (
              <h3>advanced search to-do</h3>
            ) : (
              // ? advancedSearch({ parentProps, field })
              operandSelector(field, parentProps)
            ),
          )}
        </Col>
        <Col
          md={field.width - config.COMPONENT.FORM.ADVANCED_SEARCH_SIZE}
          key={field.key}
          className="dropdownItem">
          <Form.Item
            label={
              field.type !== 'check' &&
              (field.tooltip ? (
                <span>
                  {field.title}
                  <Tooltip className="fieldTooltip" title={field.tooltip}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              ) : (
                field.title
              ))
            }
            colon={false}
            extra={field.extra}>
            {getFieldDecorator(field.key, {
              initialValue: value,
              rules: [
                {
                  required: field.type !== 'checkSelect' && field.mandatory,
                  message: intl.formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              field.behaviours !== undefined
                ? BehaviourField({
                    parentProps,
                    field,
                    hidden: false,
                  })
                : Fields(field, parentProps),
            )}
          </Form.Item>
        </Col>
      </div>
    );
  }
}
