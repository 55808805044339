import React, { useEffect, useState } from 'react';
import { Modal, Row, Table } from 'antd';
import * as api from '../api';
import apiPaths from '../apiPaths';

import { CenterSelection } from './';
import { IRecord, IRow } from '../app/AppInterfaces';
import { useIntl } from 'react-intl';

interface Props {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const getUsersWithoutCenter = (data: IRow[]) => {
  const users: IRow[] = [];

  if (!data.length) return users;

  data.forEach((_user: IRow) => {
    let hasCenter = false;
    if (_user.usersCenter) {
      _user.usersCenter
        ?.filter((request: IRow) => request.status)
        .forEach((request: IRow) => {
          if (request.userRequest !== 0) hasCenter = true;
        });
    }
    if (!hasCenter) users.push(_user);
  });

  return users;
};

export function UsersWithoutCenterModal({
  modalVisible,
  setModalVisible,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IRow[]>([]);
  const [centers, setCenters] = useState<IRow[]>([]);
  const { formatMessage } = useIntl();

  async function getUserByUUid() {
    async function getEntity(dataPath: string): Promise<IRow[]> {
      try {
        const response = await api.getDataCall({
          dataPath,
          callConfig: {},
        });
        if (
          response?.status === 200 &&
          response.data &&
          Array.isArray(response.data)
        )
          return dataPath === apiPaths.CALL.USERS_NO_CENTER
            ? getUsersWithoutCenter(response.data)
            : response.data;

        return [];
      } catch (err) {
        console.group(err);
        return [];
      }
    }

    try {
      setLoading(true);

      const [responseUsers, responseCenters] = await Promise.all([
        getEntity(apiPaths.CALL.USERS_NO_CENTER),
        getEntity(apiPaths.CALL.CENTERS_ACTIVE),
      ]);

      if (responseUsers.length) setUsers(responseUsers);
      if (responseCenters.length) setCenters(responseCenters);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.group(err);
    }
  }

  useEffect(() => {
    if (modalVisible) getUserByUUid();
  }, [modalVisible]);

  const handleSubmit = async (idUser: number, idCenter: number) => {
    try {
      setLoading(true);

      const response = await api.postDataCall({
        dataPath: apiPaths.CALL.CENTERS_USER_ASSIGN,
        data: { idUser, idCenter },
      });

      if (response?.status === 200) await getUserByUUid();

      setModalVisible(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setLoading(false);
    setUsers([]);
    setModalVisible(false);
  };

  const columns = [
    {
      dataIndex: 'email',
      title: 'Email',
      width: '300px',
    },
    {
      dataIndex: 'idUser',
      title: 'Select center',
      render: (idUser: number) => (
        <CenterSelection
          {...{
            idUser,
            centers,
            handleSubmit,
            loading,
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      className="isdin-custom-modal"
      visible={modalVisible}
      footer={null}
      onCancel={handleCloseModal}>
      <Row className="isdin-custom-modal__content">
        <Row className="isdin-custom-modal__title">
          {formatMessage({ id: 'users.deleteUserCenter.noUsersTitle' })}
        </Row>
        <Table
          columns={columns}
          loading={loading}
          rowKey={(record: IRecord) => record.uuidUser}
          dataSource={users.filter((user) => user.idSalesforce !== null)}
        />
      </Row>
    </Modal>
  );
}
