import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { SetQuiz } from '../../challengeActions';
import { IResource } from '../../ChallengeInterfaces';
import '../Resource.css';
import SellInProductContainer from './SellInProductContainer';

interface ISellInGroup {
  editRecursiveResource: ({
    value,
    parentArrayPosition,
    childArrayPosition,
    detailArrayPosition,
  }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleRemoveParentResource: (
    questionArrayPosition: number,
  ) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  resource: IResource;
  resourceIndex: number;
}

type OwnProps = ReturnType<typeof mapStateToProps> & ISellInGroup;

const SellInProduct: FC<OwnProps> = ({
  combos,
  editRecursiveResource,
  editResource,
  form,
  resourceIndex,
  handleRemoveParentResource,
  resource,
}) => {
  return (
    <SellInProductContainer
      combo={
        combos.challengechallengeEdit?.products?.shoppingCartProducts?.data ||
        []
      }
      editRecursiveResource={editRecursiveResource}
      editResource={editResource}
      form={form}
      groupIndex={resourceIndex}
      handleRemoveParentResource={handleRemoveParentResource}
      resource={resource}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

export default connect(mapStateToProps)(SellInProduct);
