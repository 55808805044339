import React, { FC, Fragment, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { IRow } from '../../app/AppInterfaces';
import {
  DUMMY_IMAGE_UUID,
  RenderFormItemProps,
  StaticPageItemParams,
  printHeaderName,
} from './WebeatPageFormUtils';
import UploadComponent from '../../challenge/Components/UploadComponent';
import { UPLOAD_ACTION_IMAGE } from '../../shared/models';
import { useIntl } from 'react-intl';
import { ContentPageEnum, comboRender } from '../shared';
import { ComboState } from '../../combos/comboReducer';

export interface WebeatStaticPageItemListParams {
  values: IRow;
  token: string;
  hasFiles: Map<number, boolean>;
  combos: ComboState;
  componentId: string;
  addPageItem: () => void;
  deletePageItem: (index: number) => void;
  renderFormItem: (props: RenderFormItemProps) => React.JSX.Element;
  handleInnerChangePageItemProp: (value: any, key: string, idx: number) => void;
  onChangeIsFileImage: (e: any, idx: number) => void;
  // forceUpdate: (value: React.SetStateAction<number>) => void;
}

const { Panel } = Collapse;

const WebeatStaticPageItemList: FC<WebeatStaticPageItemListParams> = ({
  values,
  token,
  hasFiles,
  combos,
  componentId,
  addPageItem,
  deletePageItem,
  renderFormItem,
  handleInnerChangePageItemProp,
  onChangeIsFileImage,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [, forceUpdate] = useState<number>(0);

  return (
    <Fragment>
      <Card
        title="Static page items"
        bodyStyle={{ padding: '10px' }}
        style={{ marginBottom: '20px' }}
        extra={<Button onClick={addPageItem}>Add page items</Button>}>
        <Collapse>
          {values.items &&
            values.items.map((item: StaticPageItemParams, idx: number) => (
              <Panel
                key={idx}
                header={printHeaderName(item.name)}
                extra={
                  <Button onClick={() => deletePageItem(idx)}>
                    Delete page items
                  </Button>
                }>
                <Row>
                  <Col xs={24} sm={12} md={6}>
                    {renderFormItem({
                      key: `items[${idx}].name`,
                      component: (
                        <Input
                          style={{ clear: 'both' }}
                          size="large"
                          placeholder="Name"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInnerChangePageItemProp(
                              e.target.value,
                              'name',
                              idx,
                            )
                          }
                        />
                      ),
                      size: { lg: 24, xl: 24 },
                      index: idx,
                      arrayKey: { paramName: 'name', type: 'item' },
                      isCompoundLabel: true,
                      maxLength: 40,
                    })}
                  </Col>
                  <Col span={2}></Col>
                  <Col xs={24} sm={12} md={6}>
                    {renderFormItem({
                      key: `items[${idx}].isFileImage`,
                      component: (
                        <Checkbox
                          onChange={(e) => {
                            onChangeIsFileImage(e, idx);
                          }}
                          defaultChecked={item.isFileImage ?? false}>
                          Would you like to upload an image?
                        </Checkbox>
                      ),
                      size: { lg: 24, xl: 24 },
                      notMandatory: true,
                      index: idx,
                      arrayKey: { paramName: 'isFileImage', type: 'item' },
                      isCompoundLabel: true,
                    })}
                  </Col>
                  <Col span={2}></Col>
                  <Col xs={24} sm={12} md={6}>
                    {hasFiles.get(idx)
                      ? renderFormItem({
                          key: `items[${idx}].image`,
                          component: (
                            <UploadComponent
                              format={'image'}
                              token={token}
                              uploadActions={[UPLOAD_ACTION_IMAGE]}
                              value={item.image}
                              module="static-page"
                              primaryEntityId={
                                values.idContentPage || DUMMY_IMAGE_UUID
                              }
                              handleChangeField={(data: any) => {
                                handleInnerChangePageItemProp(
                                  data.value,
                                  'image',
                                  idx,
                                );
                                forceUpdate(Math.random());
                              }}
                              setValue={function (
                                value?: string | null | undefined,
                              ): void {
                                handleInnerChangePageItemProp(
                                  value,
                                  'image',
                                  idx,
                                );
                                forceUpdate(Math.random());
                              }}>
                              <Button className="uploadButton uploadButton__margin-top">
                                <Icon type="upload" />
                              </Button>
                            </UploadComponent>
                          ),
                          size: { lg: 24, xl: 24 },
                          index: idx,
                          arrayKey: { paramName: 'image', type: 'item' },
                          isCompoundLabel: true,
                        })
                      : renderFormItem({
                          key: `items[${idx}].image`,
                          component: (
                            <Input
                              style={{ clear: 'both' }}
                              size="large"
                              placeholder="Image url"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                handleInnerChangePageItemProp(
                                  e.target.value,
                                  'image',
                                  idx,
                                )
                              }
                            />
                          ),
                          size: { lg: 24, xl: 24 },
                          index: idx,
                          arrayKey: { paramName: 'image', type: 'item' },
                          isCompoundLabel: true,
                        })}
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} md={6}>
                    {renderFormItem({
                      key: `items[${idx}].category`,
                      component: (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          dropdownMatchSelectWidth={false}
                          allowClear
                          size="large"
                          defaultValue={values.items[idx].category}
                          placeholder={'category'}
                          notFoundContent={formatMessage({
                            id: 'combo.data.notfound',
                          })}
                          filterOption={(input: any, option: any) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value: any, data: any) => {
                            handleInnerChangePageItemProp(
                              value >= 0 || typeof value === 'string'
                                ? data.key
                                : null,
                              'category',
                              idx,
                            );
                          }}
                          onBlur={(value: any) =>
                            handleInnerChangePageItemProp(
                              value,
                              'category',
                              idx,
                            )
                          }>
                          {comboRender(
                            {
                              comboId: 'menuGridTypesForStaticPage',
                              key: 'gridTypes',
                            },
                            combos,
                            componentId,
                          )}
                        </Select>
                      ),
                      size: { lg: 24, xl: 24 },
                      index: idx,
                      arrayKey: { paramName: 'category', type: 'item' },
                      isCompoundLabel: true,
                    })}
                  </Col>
                  <Col span={2}></Col>
                  <Col xs={24} sm={12} md={6}>
                    {renderFormItem({
                      key: `items[${idx}].order`,
                      component: (
                        <InputNumber
                          style={{ clear: 'both' }}
                          size="large"
                          placeholder="Order"
                          value={item.order ?? 0}
                          defaultValue={item.order ?? 0}
                          onChange={(value) =>
                            handleInnerChangePageItemProp(value, 'order', idx)
                          }
                          onBlur={(e) =>
                            handleInnerChangePageItemProp(
                              e.target.value,
                              'order',
                              idx,
                            )
                          }
                        />
                      ),
                      size: { lg: 24, xl: 24 },
                      notMandatory: true,
                      index: idx,
                      arrayKey: { paramName: 'order', type: 'item' },
                      isCompoundLabel: true,
                    })}
                  </Col>
                  <Col span={2}></Col>
                  {/* This field should be shown ONLY in case is not an ingredient */}
                  {Number(values?.type) === ContentPageEnum.LIST_OF_BRANDS && (
                    <Col xs={24} sm={12} md={6}>
                      {renderFormItem({
                        key: `items[${idx}].brandDescription`,
                        component: (
                          <Input
                            style={{ clear: 'both' }}
                            size="large"
                            placeholder="Brand description"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleInnerChangePageItemProp(
                                e.target.value,
                                'brandDescription',
                                idx,
                              )
                            }
                          />
                        ),
                        size: { lg: 24, xl: 24 },
                        notMandatory: true,
                        index: idx,
                        arrayKey: {
                          paramName: 'brandDescription',
                          type: 'item',
                        },
                        isCompoundLabel: true,
                      })}
                    </Col>
                  )}
                </Row>
              </Panel>
            ))}
        </Collapse>
      </Card>
    </Fragment>
  );
};

export default WebeatStaticPageItemList;
