import React from 'react';

export default function customer() {
  return (
    <>
      <svg viewBox="0 0 512 512" className="svgIconFill">
        <path d="M256.76,103.95c-38.69,0-70.16,33.25-70.16,74.13c0,40.87,31.47,74.12,70.16,74.12s70.16-33.25,70.16-74.12    C326.92,137.2,295.45,103.95,256.76,103.95z M256.76,222.2c-22.15,0-40.16-19.79-40.16-44.12c0-24.33,18.01-44.13,40.16-44.13    s40.16,19.8,40.16,44.13C296.92,202.41,278.91,222.2,256.76,222.2z" />
        <path d="M406.15,113.92h-0.48c-33.56,0.28-60.62,29.29-60.33,64.66c0.29,35.2,27.56,63.65,60.91,63.65h0.48    c16.45-0.14,31.81-7.07,43.24-19.52c11.16-12.17,17.23-28.2,17.09-45.14C466.77,142.37,439.5,113.92,406.15,113.92z     M427.86,202.42c-5.74,6.26-13.33,9.74-21.38,9.81h-0.23c-16.91,0-30.76-15.15-30.91-33.9c-0.16-18.83,13.56-34.27,30.58-34.41    h0.23c16.91,0,30.76,15.16,30.91,33.9C437.14,187.14,433.87,195.88,427.86,202.42z" />
        <path d="M421.7,249.73h-31c-23.85,0-45.57,9.3-61.72,24.46c-15.72-9.3-34.03-14.64-53.58-14.64h-37.29    c-19.93,0-38.59,5.55-54.5,15.18c-16.22-15.48-38.17-25-62.31-25h-31c-49.79,0-90.3,40.51-90.3,90.3v38.23h132.54v29.79h248.43    v-29.79H512v-38.23C512,290.24,471.49,249.73,421.7,249.73z M133.89,348.26H30v-8.23c0-33.25,27.05-60.3,60.3-60.3h31    c14.8,0,28.37,5.36,38.88,14.25C146.61,308.82,137.22,327.54,133.89,348.26z M350.97,378.05H300.4h-88.8h-49.06v-12.93    c0-5.79,0.66-11.44,1.9-16.86c2.43-10.64,7.12-20.44,13.53-28.85c6.37-8.37,14.45-15.37,23.72-20.48    c10.81-5.98,23.23-9.38,36.42-9.38h37.29c12.73,0,24.72,3.16,35.25,8.74c9.26,4.9,17.39,11.68,23.87,19.82    c6.94,8.7,11.99,18.95,14.55,30.15c1.24,5.42,1.9,11.07,1.9,16.86V378.05z M482,348.26H379.62c-3.39-21.05-13.03-40.04-26.94-55    c10.38-8.46,23.62-13.53,38.02-13.53h31c33.25,0,60.3,27.05,60.3,60.3V348.26z" />
        <path d="M105.75,113.92h-0.48c-33.56,0.28-60.62,29.29-60.33,64.66c0.29,35.2,27.56,63.65,60.91,63.65h0.48    c16.45-0.14,31.81-7.07,43.24-19.52c11.16-12.17,17.23-28.2,17.09-45.14C166.37,142.37,139.1,113.92,105.75,113.92z     M127.46,202.42c-5.74,6.26-13.33,9.74-21.38,9.81h-0.23c-16.91,0-30.76-15.15-30.91-33.9c-0.16-18.83,13.56-34.27,30.58-34.41    h0.23c16.91,0,30.76,15.16,30.91,33.9C136.74,187.14,133.47,195.88,127.46,202.42z" />
      </svg>
    </>
  );
}
