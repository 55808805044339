import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const CategoryDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, LARGE } = config.COMPONENT.COLUMN;
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'categorySearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'label.id' }),
          key: 'idCategory',
          type: 'number',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'label.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'categoryList',
        rowKey: 'idCategory',
        panelHeader: 'Category List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'label.id' }),
          key: 'idCategory',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'label.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'label.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'label.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'categoryEdit',
        panelHeader: 'Challenge Edit',
        primaryKey: 'idCategory',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'textMachine',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'label.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.description' }),
          key: 'description',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idChallengeType',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'CategoryDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default CategoryDashboard;
