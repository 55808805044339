import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const BoRolesDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'borolesrolesSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'borolesrolesList',
        rowKey: 'idRole',
        panelHeader: formatMessage({ id: 'boroles.list' }),
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0,
      },
      fields: [
        {
          title: formatMessage({ id: 'boroles.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'boroles.id' }),
          key: 'idRole',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: MID,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'borolesrolesEdit',
        panelHeader: formatMessage({ id: 'boroles.edit' }),
        primaryKey: 'idRole',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'textMachine',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'status',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'boroles.description' }),
          key: 'description',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          placeholder: formatMessage({ id: 'boroles.placeholder.description' }),
        },
        {
          title: formatMessage({ id: 'boroles.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: true,
          disabled: false,
          placeholder: formatMessage({ id: 'boroles.placeholder.name' }),
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          initialValue: 'true',
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idRole',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'borolespermissionsList',
        rowKey: 'idPermissions',
        panelHeader: formatMessage({ id: 'boroles.permissions' }),
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        tabIndex: 1,
        navigationId: 'idRole.idRole',
      },
      fields: [
        {
          title: formatMessage({ id: 'permissions.id' }),
          key: 'idPermissions',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'permissions.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'permissions.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'permissions.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
      m2m: {
        dashboardId: 'M2MRolesPermissionsDashboard',
        path: '/borolespermissions',
        primaryKey: 'idRolePerm',
        joinKey: 'idRole.idRole',
        joinForeignKey: 'idPermissions.idPermissions',
        components: [
          {
            params: {
              type: 'search',
              componentId: 'm2mrolespermissionsSearch',
              panelHeader: 'Permissions Search ---',
              panelKey: 'm2mrolespermissionsSearch',
              navigationParams: props.query.params.q || '',
              isTabItem: false,
            },
            groups: [
              {
                index: 0,
                title: '',
                mustRender: true,
                subgroups: [
                  {
                    index: 0,
                    title: '',
                    mustRender: true,
                    yPosition: 0,
                    xPosition: 0,
                    width: 24,
                    fields: [
                      {
                        key: 'status',
                        mustRender: true,
                        yPosition: 0,
                        xPosition: 0,
                        width: 12,
                      },
                    ],
                  },
                ],
              },
            ],
            fields: [
              {
                title: formatMessage({ id: 'permissions.status' }),
                key: 'status',
                type: 'comboCustom',
                comboId: 'status',
                operand: 'equal',
                min: 0,
                length: 0,
                mandatory: false,
                disabled: false,
                initialValue: 'true',
              },
              {
                title: formatMessage({ id: 'permissions.name' }),
                key: 'name',
                type: 'text',
                operand: 'like',
                min: 0,
                validationType: 'text',
                length: 20,
                mandatory: false,
                disabled: false,
                globalSearch: true,
              },
            ],
            settings: {
              fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
              fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
            },
          },
          {
            params: {
              type: 'table',
              componentId: 'm2mrolespermissionsList',
              rowKey: 'idPermissions',
              panelHeader: 'Permissions List --',
              panelKey: 'm2mrolespermissionsList',
              isMainTable: true,
              navigationParams: props.query.params.q || '',
            },
            fields: [
              {
                title: formatMessage({ id: 'permissions.id' }),
                key: 'idPermissions',
                position: 0,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                width: SMALL,
              },
              {
                title: formatMessage({ id: 'permissions.name' }),
                key: 'name',
                position: 1,
                mustRender: true,
                align: 'left',
                sorter: true,
                visible: true,
                width: MID,
                defaultSortOrder: 'ascend',
              },
              {
                title: formatMessage({ id: 'permissions.description' }),
                key: 'description',
                position: 2,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: LARGE,
              },
              // {
              //      title: 'Optimistic Locking',
              //      key: 'optimisticLocking',
              //      position: 3,
              //      mustRender: true,
              //      align: 'left',
              //      sorter: false,
              //      visible: true,
              // },
              {
                title: formatMessage({ id: 'permissions.status' }),
                key: 'status',
                render: 'comboCustom',
                comboId: 'status',
                position: 3,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                width: SMALL,
              },
            ],
            settings: {
              size: config.COMPONENT.TABLE.SIZE,
              bordered: config.COMPONENT.TABLE.BORDERED,
              editable: false,
              multiple: true,
              canCreate: false,
              canDelete: false,
              scroll: { x: true },
            },
            pagination: {
              defaultPageSize: 50,
              pageSizeOptions: ['5', '10', '20', '50'],
              showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
              showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
              showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
              size: config.COMPONENT.TABLE.SIZE,
            },
          },
        ],
      },
    },
  ];

  const componentId = 'BoRolesDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default BoRolesDashboard;
