export enum queryTypes {
  ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
  QUERY_SET_PARAMS = '[QUERY] Set query parameters',
  QUERY_PUSH_BREADCRUMB = '[QUERY] Push a new breadcrumb',
  QUERY_GO_BACK = '[QUERY] Going back to a previous breadcrumb',
  QUERY_SAVE_BREADCRUMBS = '[QUERY] Save breadcrumbs to local storage',
  QUERY_SET_NAVIGATION = '[QUERY] Set navigation params',
  QUERY_SET_COMPONENT_NAVIGATION = '[QUERY] Set component navigation',
  QUERY_SET_INITIAL_PARAMS = '[QUERY] Set initial query params',
  QUERY_LOAD_QUERYPARAMS = '[QUERY] Load initial query params'
}
