import {
  ENABLE_BANNER,
  ENABLE_MEDIA,
  MEDIA_FILE,
  MEDIA_URL,
  MEDIA_VIDEO,
} from './constants';

export const heroHandler = (key: string, contentState: any) => {
  if (key === MEDIA_FILE) {
    contentState[MEDIA_FILE] = {
      ...contentState[MEDIA_FILE],
      checked: !contentState[MEDIA_FILE]?.checked,
    };

    contentState[MEDIA_URL] = {
      ...contentState[MEDIA_URL],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };
    contentState[MEDIA_VIDEO] = {
      ...contentState[MEDIA_VIDEO],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };

    return contentState;
  }

  if (key === MEDIA_URL) {
    contentState[MEDIA_URL] = {
      ...contentState[MEDIA_URL],
      checked: !contentState[MEDIA_URL]?.checked,
    };

    contentState[MEDIA_FILE] = {
      ...contentState[MEDIA_FILE],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };

    contentState[MEDIA_VIDEO] = {
      ...contentState[MEDIA_VIDEO],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };
    return contentState;
  }

  if (key === MEDIA_VIDEO) {
    contentState[MEDIA_VIDEO] = {
      ...contentState[MEDIA_VIDEO],
      checked: !contentState[MEDIA_VIDEO]?.checked,
    };

    contentState[MEDIA_FILE] = {
      ...contentState[MEDIA_FILE],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };

    contentState[MEDIA_URL] = {
      ...contentState[MEDIA_URL],
      checked: false,
      desktopLarge: null,
      desktop: null,
      mobile: null,
    };
    return contentState;
  }

  if (key === ENABLE_BANNER || key === ENABLE_MEDIA) {
    Object.keys(contentState).forEach((currentKey) => {
      if (currentKey !== ENABLE_BANNER && currentKey !== ENABLE_MEDIA) {
        delete contentState[currentKey];
      }
    });

    if (contentState[ENABLE_BANNER] === contentState[ENABLE_MEDIA]) {
      contentState[ENABLE_BANNER] = true;
      contentState[ENABLE_MEDIA] = false;
    }

    if (key === ENABLE_BANNER) {
      contentState[ENABLE_BANNER] = !contentState[ENABLE_BANNER];
      contentState[ENABLE_MEDIA] = !contentState[ENABLE_BANNER];
    } else if (key === ENABLE_MEDIA) {
      contentState[ENABLE_MEDIA] = !contentState[ENABLE_MEDIA];
      contentState[ENABLE_BANNER] = !contentState[ENABLE_MEDIA];
    }
    return;
  }
};
