import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatDataReplicaDashboard = (props: any) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatDataReplicaSearch',
      },
      groups: [],
      fields: [],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatDataReplicaList',
        rowKey: 'id',
        panelHeader: 'Data Replica',
        navigationParams: props.query.params.q || '',
        prettierKey: 'id',
        isMainTable: true,
        tableType: 0,
        customButtons: ['createDataReplicaButton'],
      },
      fields: [
        {
          title: 'ID',
          key: 'id',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: 'Modules',
          key: 'modules',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Origin',
          key: 'origin',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Destination',
          key: 'target',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'State',
          key: 'status',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: 'Execution date',
          key: 'executionDate',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'webeat-datetime',
        },
        {
          title: 'Creation date',
          key: 'createDate',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'webeat-datetime',
          defaultSortOrder: 'descend',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  const componentId = 'WebeatDataReplicaDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatDataReplicaDashboard;
