import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import config from '../config';

import { Col } from 'antd';
import Fields from './Fields';

import { ICompleteField } from '../forms/formInterfaces';
import { Primitive, IRow } from '../app/AppInterfaces';
import { IEditField, ISearchField } from './FieldsInterfaces';
import { IBehaviourField } from '../dashboards/DashboardInterfaces';

export default function BehaviourField(props: IBehaviourField) {
  const { field, hidden, parentProps } = props;
  const {
    handleChildBehaviours,
    handleChangeField,
    updateChildCombo,
    setParentValue,
  } = parentProps;
  const {
    fields,
    form,
    values,
    formHasChanged,
    params,
    queryParams,
    selectedRow,
    setFormData,
  } = parentProps.props;
  const { componentId } = params;
  const { q } = queryParams;

  const { forceVisible, isParentDisabled } = field;
  let paramsDisabled;

  if (!isEmpty(q)) {
    q!.split(config.QUERY.AND).forEach((element: string) => {
      const key = element.split(config.QUERY.ID_OPERATOR)[0];
      const value = element.split(config.QUERY.ID_OPERATOR)[1];
      if (value && key === field.key) {
        paramsDisabled = true;
      }
    });
  }

  const checkOperands = (
    behaviourValue: Omit<Primitive, undefined & null>,
    operand: string,
    fieldValue?: Primitive,
  ) => {
    // Verificamos que no sea undefined ni null por un problema con el comportamiento
    // de determinados componentes (TimePicker, DatePicker)
    // TODO: Corregir la función handleChange de estos componentes para que no haya
    // dispatch innecesarios

    if (operand === 'empty') {
      if (fieldValue === undefined) return true;
    } else if (operand === 'anyValue') {
      return Boolean(fieldValue === 0 || fieldValue);
    } else {
      if (fieldValue === undefined || fieldValue === null) return false;
      switch (operand) {
        case 'like':
          return fieldValue.toString() === behaviourValue.toString();
        case 'notLike':
          return fieldValue.toString() !== behaviourValue.toString();
        case 'greater':
          return fieldValue.toString() > behaviourValue.toString();
        case 'less':
          return fieldValue.toString() < behaviourValue.toString();
        case 'greater|equal':
          return fieldValue.toString() >= behaviourValue.toString();
        case 'less|equal':
          return fieldValue.toString() <= behaviourValue.toString();
        default:
          return true;
      }
    }
  };

  interface IBehaviourComponent {
    visible?: boolean;
    mandatory?: boolean;
    disabled?: boolean;
  }
  /**
   * This function sets the behaviour of a combo child field
   * @param {Object} field - Combo field
   * @param {Boolean} condition - true = combo parent has values
   */
  const setComboState = (
    field: ICompleteField,
    condition: boolean,
  ): IBehaviourComponent => {
    let behavioursComponent: IBehaviourComponent = {};
    if (condition) {
      if (field.hasOwnProperty('initialDisabled'))
        behavioursComponent['disabled'] = field.initialDisabled;
    } else {
      if (field.hasOwnProperty('initialDisabled'))
        behavioursComponent['disabled'] = !field.initialDisabled;
    }
    return behavioursComponent;
  };

  const checkFormValues = ({
    values,
    fields,
  }: {
    values: IRow;
    fields: (ISearchField | IEditField)[];
  }) => {
    let checkedValues = false;
    if (selectedRow === undefined) return checkedValues;
    else if (isEmpty(selectedRow))
      fields.forEach((field) => {
        for (let key in values)
          if (field.key === key) {
            if (!field.initialValue || values[key] !== field.initialValue)
              checkedValues = true;
            // else if (!field)
            //   checkedValues = true;
            // else if()
            //   checkedValues = true;
            //TODO check appParams
            //TODO check UserParams
          }
      });
    else
      for (let key in selectedRow) {
        for (let valuesKey in values) {
          if (valuesKey === key) {
            // eslint-disable-next-line eqeqeq
            if (values[key] != selectedRow[key]) checkedValues = true;
          }
        }
      }
    return checkedValues;
  };

  /**
   * This function checks all the behaviours of a field and returns an Object that contain which Behaviours are displayed or not.
   * @param {Object} field - Behaviour field
   * @param {Object} values - Form values
   * @param {Object} behavioursComponent - Object which will store the diferent field behaviours
   */
  const checkBehaviours = (field: ICompleteField, values: IRow, form: any) => {
    let isGroupVisible = false;
    let isGroupDisabled = false;
    let isGroupMandatory = false;
    let behavioursComponent: IBehaviourComponent = {};
    field.behaviours!.forEach((behaviourGroup) => {
      let behavioursComponent = {} as IBehaviourComponent;
      behaviourGroup.forEach(async (behaviour) => {
        const { type, operand, value } = behaviour;
        // DR: Workaround to handle case wich N centers have the same region in DermoAdminValidatorDashboard combos
        let fieldValue = behaviour.key2 ? values[behaviour.key] + ',' + values[behaviour.key2] : values[behaviour.key];

        if (type === 'combo') {
          if (
            fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== ''
          ) {
            if (field.parentValue === '' && values[field.key]) {
              behavioursComponent = setComboState(field, true);
              setParentValue(field.key, fieldValue);
              updateChildCombo(field.comboId!, fieldValue, field.key);
            } else if (fieldValue === field.parentValue) {
              behavioursComponent = setComboState(field, true);
            } else if (fieldValue !== field.parentValue) {
              behavioursComponent = setComboState(field, true);
              if (behaviour.params) {
                updateChildCombo(field.comboId!, fieldValue, field.key);
                const formChange =
                  parentProps.props.params.type === 'edit'
                    ? checkFormValues({ values, fields })
                    : formHasChanged;
                if (formChange) {
                  delete values[field.key];
                  setFormData({ componentId, values });
                }
                form.resetFields();
              }
              setParentValue(field.key, fieldValue);
            }
          } else {
            behavioursComponent = setComboState(field, false);

            if (values[field.key] !== undefined) {
              delete values[field.key];
              setFormData({ componentId, values });
              form.resetFields();
            }
            if (field.parentValue !== '') setParentValue(field.key, '');
          }
        } else {
          if (type !== undefined && !(type in behavioursComponent))
            behavioursComponent = {
              ...behavioursComponent,
              [type]: true,
            };

          let behaviourValue: Primitive = value;

          //Date & Time formatting
          if (fieldValue && moment.isMoment(fieldValue)) {
            let format;
            fields.forEach((f: ISearchField | IEditField) => {
              if (f.key === behaviour.key) {
                format = f.format;
              }
            });
            fieldValue = moment(fieldValue).format(format);
            if (format === 'HH:mm:ss') {
              fieldValue = new Date('01/01/2011 '.concat(fieldValue));
              behaviourValue = new Date('01/01/2011 '.concat(behaviourValue!));
            } else {
              fieldValue = new Date(fieldValue);
              behaviourValue = new Date(behaviourValue!);
            }
          }

          let check = checkOperands(behaviourValue!, operand!, fieldValue);

          if (!check) {
            behavioursComponent[type!] = false;
          }
        }
      });
      if (behavioursComponent['visible']) isGroupVisible = true;
      if (behavioursComponent['disabled']) isGroupDisabled = true;
      if (behavioursComponent['mandatory']) isGroupMandatory = true;
    });

    behavioursComponent['visible'] = isGroupVisible ? true : false;
    behavioursComponent['disabled'] = isGroupDisabled ? true : false;
    behavioursComponent['mandatory'] = isGroupMandatory ? true : false;

    return behavioursComponent;
  };

  useEffect(() => {
    const {
      visible,
      disabled,
      mandatory,
      initialVisibility,
      initialDisabled,
      initialMandatory,
    } = field;
    let behavioursDisplayed = {};

    const behavioursComponent = checkBehaviours(field, values, form);

    for (let key in behavioursComponent) {
      switch (key) {
        case 'visible':
          if (forceVisible === null || forceVisible === undefined) {
            if (initialVisibility) {
              if (visible && behavioursComponent[key]) {
                handleChangeField({
                  id: field.key,
                  value: '',
                  type: field.type,
                });

                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: false,
                };
              }
              if (!visible && !behavioursComponent[key])
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: true,
                };
            } else if (initialVisibility === false) {
              if (!visible && behavioursComponent[key]) {
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: true,
                };
              }

              if (visible && !behavioursComponent[key]) {
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: false,
                };
                handleChangeField({
                  id: field.key,
                  value: '',
                  type: field.type,
                });
              }
            }
          }
          break;
        case 'disabled':
          if (initialDisabled) {
            if (disabled && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: false,
              };

            if (!disabled && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: true,
              };
          } else if (initialDisabled === false) {
            if (!disabled && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: true,
              };
            if (disabled && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: false,
              };
          }

          break;
        case 'mandatory':
          if (initialMandatory) {
            if (mandatory && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: false,
              };
            if (!mandatory && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: true,
              };
          } else if (initialMandatory === false) {
            if (!mandatory && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: true,
              };
            if (mandatory && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: false,
              };
          }
          break;
        default:
          break;
      }
    }

    if (!isEmpty(behavioursDisplayed)) {
      handleChildBehaviours(
        field.key,
        behavioursDisplayed,
        forceVisible,
        isParentDisabled,
      );
    }
  });

  field.paramsDisabled = paramsDisabled;
  if (forceVisible) field.visible = forceVisible;

  if (hidden || forceVisible === false) {
    let width = field.width;
    if (field.noWidth) width = 0;
    return <Col md={width} key={field.key} />;
  }

  return Fields(field, parentProps);
}
