import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatDermoValidationDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL } = config.COMPONENT.COLUMN;

  const componentId = 'WebeatDermoValidationDashboard';

  const customButtons = [
    'webeatValidateDermoButton',
    'webeatInvalidateDermoButton',
  ];

  const components = [
    // Search
    {
      params: {
        type: 'search',
        componentId: 'webeatDermoValidationSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'state',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'dermoValidation.state' }),
          key: 'state',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    // Table
    {
      params: {
        type: 'table',
        componentId: 'webeatDermoValidationList',
        rowKey: 'id',
        panelHeader: 'Dermo Validation list',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'fullName',
        customButtons,
      },
      fields: [
        {
          title: formatMessage({ id: 'dermoEventPage.name' }),
          key: 'fullName',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.userId' }),
          key: 'userId',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.email' }),
          key: 'email',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.state' }),
          key: 'state',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.chain' }),
          key: 'chain',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.region' }),
          key: 'region',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.pos' }),
          key: 'pointOfSale',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.idDermo' }),
          key: 'dermoId',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.creationDate' }),
          key: 'creationDate',
          render: 'datetime',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.validationDate' }),
          key: 'validationDate',
          render: 'datetime',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'dermoValidation.idValidator' }),
          key: 'validatorId',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        // TODO: enable this options when the edit part is available
        // canCreate: true,
        // canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
  ];

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatDermoValidationDashboard);
