import { TDashboardComposerListProps } from '../../dashboards/DashboardComposer';

function tableMethods(parentProps: TDashboardComposerListProps) {
  return parentProps;
}

function editMethods(parentProps: TDashboardComposerListProps) {
  return parentProps;
}

const methods = { table: tableMethods, edit: editMethods };

export default methods;
