export enum tableTypes {
  TABLE_INITIAL_STATE = '[TABLE] set initial state',
  SET_INITIAL_TABLE_STATE = '[TABLE] set initial component state',
  TABLE_CONFIGURED_COLUMNS = '[TABLE] set configuration',
  TABLE_FETCHING_DATA = '[TABLE] fetching data',
  TABLE_FETCHED_DATA = '[TABLE] fetched data',
  TABLE_CREATE_DATA = '[TABLE] create data',
  TABLE_DELETED_DATA = '[TABLE] deleted data',
  TABLE_SELECTED_ROWS = '[TABLE] row (multi) selected',
  TABLE_SELECTED_ROW = '[TABLE] row (single) selected',
  TABLE_SET_FORM_STATE_FLAG = '[TABLE] edit form has changed',
  TABLE_FETCHED_IMAGE_MODAL = '[TABLE] Show/hide image modal',
  TABLE_FETCHING_IMAGE_MODAL = '[TABLE] loading image modal',
  TABLE_SEARCH_SENDING_DATA_ERROR = '[TABLE] seach sending data error',
  RESET_TABLE_DATA = '[TABLE] reset component data',
  TABLE_SET_CHILD_ROW = '[TABLE] Set child selectedRow',
  TABLE_LOAD_DATA_TRIGGER = '[TABLE] Set loadData trigger',
  TABLE_SET_LOAD_DATA = '[TABLE] Edit loadData',
  TABLE_RESET_COMPONENT = '[TABLE] Reset table component',
  TABLE_SET_INITIAL_PARAMS = '[TABLE] set initial table params',
  TABLE_RESET_CONTENT = '[TABLE] Reset table content',
  TABLE_DELETING_DATA = '[TABLE] Deleting data',
  TABLE_UPDATE_SELECTED_ROW = '[TABLE] Add media resource',
  TABLE_UPDATE_QUERY_PARAMS = '[TABLE] Update table query params',
}
