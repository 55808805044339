import React from 'react';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import config from '../config';

import store from '../store';
import { ReducersState } from '../reducers';

import { setLanguage } from './appActions';

// import 'moment/locale/es';
// import 'moment/locale/ca';
import 'moment/locale/en-gb';

// import antES from 'antd/lib/locale-provider/es_ES';
import antEn from 'antd/lib/locale-provider/en_GB';
// import antCa from 'antd/lib/locale-provider/ca_ES';

import en_US from '../locale/static/en_US.json';
// import es_ES from '../locale/static/es_ES.json';
// import ca_ES from '../locale/static/ca_ES.json';

import var_en_US from '../locale/var/var_en_US.json';
// import var_es_ES from '../locale/var/var_es_ES.json';
// import var_ca_ES from '../locale/var/var_ca_ES.json';

import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { IRow } from './AppInterfaces';

interface ContextProps {
  children: JSX.Element;
}

type TContextListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContextProps;

class ContextProvider extends React.PureComponent<TContextListProps, {}> {
  constructor(props: TContextListProps) {
    super(props);
    const { setLanguage } = this.props;
    const language = config.LOCALE.FIXED
      ? config.LOCALE.DEFAULT
      : (navigator.languages && navigator.languages[0]) || navigator.language;
    setLanguage(language);
  }

  render() {
    const messages: IRow = {
      antdMessages: {
        // 'es-ES': antES,
        'en-US': antEn,
        // 'ca-ES': antCa
      },
      appMessages: {
        // 'es-ES': {
        //   ...es_ES,
        //   ...var_es_ES
        // },
        'en-US': {
          ...en_US,
          ...var_en_US,
        },
        // 'ca-ES': {
        //   ...ca_ES,
        //   ...var_ca_ES
        // }
      },
    };

    const { children } = this.props;
    const { locale } = store.getState().app;

    const currentLocale = 'en-US';
    // (navigator.languages && navigator.languages[0]) || navigator.language;

    moment.locale(locale);
    return (
      <ConfigProvider locale={messages.antdMessages[locale]}>
        <IntlProvider
          locale={locale}
          messages={messages.appMessages[currentLocale]}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state: ReducersState) => ({
  locale: state.app.locale,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setLanguage,
    },
    dispatch,
  );

const LocaleContext = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextProvider);

export default ({ children }: { children: JSX.Element }) => (
  <Provider store={store}>
    <LocaleContext>{children}</LocaleContext>
  </Provider>
);
