import React, { FC, Fragment, useState } from 'react';
import { Button, Icon, List, Modal, Row } from 'antd';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';
import { useIntl } from 'react-intl';
import { ReducersState } from '../../reducers';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import './DeleteMassiveUsersButton.css';
import { BoPermissionsEnum } from '../../challenge/Enums/permissions.enum';

export default function DeleteMassiveUsersButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;
  return <DeleteMassiveUsers />;
}

type ButtonProps = ReturnType<typeof mapStateToProps>;

interface IMassiveDeleteResponse {
  data: {
    usersErrorDelete: string[];
    usersNotFound: string[];
    usersDeleted: string[];
  };
}

const DeleteMassiveButton: FC<ButtonProps> = ({ userPermissions }) => {
  const intl = useIntl();
  const [listUsers, setListUsers] = useState<string[]>();
  const [modalVisible, setModalVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [errorMails, setErrorMails] = useState<string[]>([]);
  const [notFoundMails, setNotFoundMails] = useState<string[]>([]);
  const [successMails, setSuccessMails] = useState<string[]>([]);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [visibleModalResponse, setVisibleModalResponse] =
    useState<boolean>(false);

  const deleteMassivePermission = userPermissions?.find(
    (permission) => permission.textMachine === BoPermissionsEnum.MASSIVE_DELETE,
  );

  if (!deleteMassivePermission) return <React.Fragment />;

  const handleClose = () => {
    setListUsers([]);
    setModalVisible(false);
    setIsButtonDisabled(true);
  };

  const handleCloseResponse = () => {
    setVisibleModalResponse(false);
    handleClose();
    setErrorMails([]);
    setNotFoundMails([]);
    setSuccessMails([]);
  };

  const handleConfirmModal = () => {
    setVisibleModalConfirm(true);
  };

  const handleDelete = () => async () => {
    setVisibleModalConfirm(false);
    setVisibleModalResponse(true);
    setLoadingResponse(true);

    try {
      //LLAMDA DELETE USERS
      const response: IMassiveDeleteResponse = await api.deleteCall({
        dataPath: `${apiPaths.CALL.DELETE_MASSIVE_USERS}`,
        callConfig: { data: { mails: listUsers } },
      });
      setErrorMails(response.data.usersErrorDelete);
      setNotFoundMails(response.data.usersNotFound);
      setSuccessMails(response.data.usersDeleted);
    } catch (err) {
      console.error(err);
      setVisibleModalResponse(false);
    } finally {
      handleClose();
      setLoadingResponse(false);
    }
  };

  const modalConfirm = (
    <Modal
      visible={visibleModalConfirm}
      onCancel={() => setVisibleModalConfirm(false)}
      footer={null}>
      <div className="massive-delete title">
        {intl.formatMessage({
          id: 'users.massive-delete-message-confirm',
        })}
      </div>
      <div className="massive-delete advice">
        {intl.formatMessage({
          id: 'users.massive-delete-message-warning',
        })}
      </div>
      <Row className="massive-delete container-buttons">
        <Button onClick={() => setVisibleModalConfirm(false)}>
          {intl.formatMessage({ id: 'form.edit.cancel' })}
        </Button>
        <Button
          className="massive-delete.button-cancel"
          type="primary"
          onClick={handleDelete()}>
          {intl.formatMessage({ id: 'form.apply' })}
        </Button>
      </Row>
    </Modal>
  );

  const modalResponse = (
    <Modal
      visible={visibleModalResponse}
      onCancel={handleCloseResponse}
      centered
      footer={null}>
      <div className="massive-delete title">
        {intl.formatMessage({
          id: 'users.massive-delete-message-correctly',
        })}
      </div>
      <List
        loading={loadingResponse}
        itemLayout="horizontal"
        dataSource={successMails}
        renderItem={(item: string) => (
          <List.Item>
            <Icon
              className="massive-delete icon"
              type="check-circle"
              style={{ color: 'green' }}
            />
            {item}
          </List.Item>
        )}
      />
      <div className="massive-delete title">
        {intl.formatMessage({ id: 'users.massive-delete-message-error' })}
      </div>
      <List
        loading={loadingResponse}
        itemLayout="horizontal"
        dataSource={errorMails}
        renderItem={(item: string) => (
          <List.Item>
            <Icon
              className="massive-delete icon"
              type="close-circle"
              style={{ color: 'red' }}
            />
            {item}
          </List.Item>
        )}
      />
      <div className="massive-delete title">
        {intl.formatMessage({ id: 'users.massive-delete-not-found' })}
      </div>
      <List
        loading={loadingResponse}
        itemLayout="horizontal"
        dataSource={notFoundMails}
        renderItem={(item: string) => (
          <List.Item>
            <Icon
              className="massive-delete icon"
              type="question-circle"
              style={{ color: 'yellow' }}
            />
            {item}
          </List.Item>
        )}
      />
    </Modal>
  );

  const modalDeleteMassiveUsers = (
    <Modal
      visible={modalVisible}
      footer={null}
      onCancel={handleClose}
      centered
      closable
      destroyOnClose
      className="assignModal">
      <h1>
        <FormattedMessage id="custom-buttons.delete-massive-users-modal-title" />
      </h1>
      <p>
        <FormattedMessage id="custom-buttons.delete-massive-users-modal-text" />
      </p>
      <TextArea
        style={{ height: '300px', resize: 'vertical' }}
        rows={1000}
        cols={50}
        onChange={(e) => {
          const users: string[] = e.target.value
            .split('\n')
            .filter((user) => user !== '');

          setListUsers(users ?? []);
          setIsButtonDisabled(users.length === 0);
        }}
        placeholder="Users emails"
      />
      <Row className="DeleteMassiveUsers__title" type="flex" align="middle">
        <Button
          style={{ margin: '10px 2% 0', width: '46%' }}
          onClick={handleClose}>
          {formatMessage({ id: 'form.edit.cancel' })}
        </Button>
        <Button
          type="primary"
          disabled={isButtonDisabled}
          style={{ margin: '10px 2% 0', width: '46%' }}
          onClick={handleConfirmModal}>
          {formatMessage({ id: 'collapsible.delete' })}
        </Button>
      </Row>
    </Modal>
  );

  return (
    <>
      {modalConfirm}
      {modalResponse}
      {modalDeleteMassiveUsers}
      <Button
        style={{ marginRight: '12px' }}
        onClick={() => setModalVisible(true)}
        type="primary">
        {formatMessage({
          id: 'custom-buttons.delete-massive-users',
        })}
      </Button>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    userPermissions: state.app.userPermissions,
  };
};

const DeleteMassiveUsers = connect(mapStateToProps, {})(DeleteMassiveButton);
