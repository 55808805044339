import React from 'react';

export default function rol() {
  return (
    <>
      <svg viewBox="0 0 496 496" className="svgIconFill">
        <path d="m496 414.207031v-60.421875l-23.144531-5.914062c-.160157-.390625-.320313-.78125-.488281-1.167969l12.183593-20.535156-42.726562-42.726563-20.535157 12.183594c-.394531-.167969-.785156-.328125-1.167968-.488281l-5.914063-23.136719h-60.421875l-5.914062 23.144531c-.390625.160157-.78125.320313-1.167969.488281l-20.535156-12.183593-42.726563 42.726562 12.183594 20.535157c-.167969.394531-.328125.785156-.488281 1.167968l-23.136719 5.914063v60.421875l23.144531 5.914062c.160157.390625.320313.78125.488281 1.167969l-12.183593 20.535156 42.726562 42.726563 20.535157-12.183594c.394531.167969.785156.328125 1.167968.488281l5.914063 23.136719h60.421875l5.914062-23.144531c.390625-.160157.78125-.320313 1.167969-.488281l20.535156 12.183593 42.726563-42.726562-12.183594-20.535157c.167969-.394531.328125-.785156.488281-1.167968zm-31.542969 25.097657-25.152343 25.152343-17.097657-10.144531-3.847656 1.839844c-2.480469 1.175781-5.015625 2.238281-7.625 3.160156l-4.03125 1.429688-4.910156 19.257812h-35.578125l-4.910156-19.257812-4.03125-1.429688c-2.609376-.914062-5.152344-1.976562-7.625-3.160156l-3.847657-1.839844-17.097656 10.144531-25.152344-25.152343 10.144531-17.097657-1.839843-3.847656c-1.175781-2.480469-2.238281-5.015625-3.160157-7.625l-1.429687-4.03125-19.265625-4.910156v-35.578125l19.257812-4.910156 1.429688-4.03125c.914062-2.609376 1.976562-5.152344 3.160156-7.625l1.839844-3.847657-10.144531-17.097656 25.152343-25.152344 17.097657 10.144531 3.847656-1.839843c2.480469-1.175781 5.015625-2.238281 7.625-3.160157l4.03125-1.429687 4.910156-19.265625h35.578125l4.910156 19.257812 4.03125 1.429688c2.609376.914062 5.152344 1.976562 7.625 3.160156l3.847657 1.839844 17.097656-10.144531 25.152344 25.152343-10.144531 17.097657 1.839843 3.847656c1.175781 2.480469 2.238281 5.015625 3.160157 7.625l1.429687 4.03125 19.265625 4.910156v35.578125l-19.257812 4.910156-1.429688 4.03125c-.914062 2.609376-1.976562 5.152344-3.160156 7.625l-1.839844 3.847657zm0 0" />
        <path d="m384 320c-35.289062 0-64 28.710938-64 64s28.710938 64 64 64 64-28.710938 64-64-28.710938-64-64-64zm0 112c-26.472656 0-48-21.527344-48-48s21.527344-48 48-48 48 21.527344 48 48-21.527344 48-48 48zm0 0" />
        <path d="m384 352c-17.648438 0-32 14.351562-32 32s14.351562 32 32 32 32-14.351562 32-32-14.351562-32-32-32zm0 48c-8.824219 0-16-7.175781-16-16s7.175781-16 16-16 16 7.175781 16 16-7.175781 16-16 16zm0 0" />
        <path d="m202.601562 135.457031c2.679688 7.855469 7.34375 14.78125 13.398438 20.222657v6.785156l-38.039062 14.261718c-15.527344 5.816407-25.960938 20.867188-25.960938 37.449219v41.824219h192v-41.824219c0-16.582031-10.433594-31.632812-25.953125-37.457031l-38.046875-14.261719v-6.777343c6.054688-5.433594 10.71875-12.359376 13.398438-20.222657 15.074218-2.585937 26.601562-15.664062 26.601562-31.457031v-32c0-39.695312-32.296875-72-72-72s-72 32.304688-72 72v32c0 15.792969 11.527344 28.871094 26.601562 31.457031zm61.398438 30.328125-16 26.664063-16-26.664063v-.585937c5.015625 1.785156 10.382812 2.800781 16 2.800781s10.984375-1.023438 16-2.800781zm-43.433594 12.046875 16.128906 26.871094-16.023437 8.007813-10.351563-31.039063zm107.433594 36.34375v25.824219h-160v-25.824219c0-9.953125 6.257812-18.976562 15.574219-22.472656l11.761719-4.40625 15.992187 47.984375 36.671875-18.335938 36.671875 18.335938 15.992187-47.984375 11.769532 4.414063c9.308594 3.488281 15.566406 12.511718 15.566406 22.464843zm-42.320312-32.503906-10.351563 31.046875-16.023437-8.007812 16.128906-26.878907zm-37.679688-29.671875c-17.648438 0-32-14.351562-32-32v-32.542969c11.710938-1.234375 22.761719-5.503906 32-12.769531 9.238281 7.273438 20.289062 11.535156 32 12.769531v32.542969c0 17.648438-14.351562 32-32 32zm48-34.222656v-27.546875c4.761719 2.777343 8 7.882812 8 13.777343 0 5.894532-3.238281 10.992188-8 13.769532zm-48-101.777344c30.871094 0 56 25.121094 56 56v4.449219c-4.726562-2.753907-10.144531-4.449219-16-4.449219h-1.375c-12.457031 0-24.167969-4.847656-32.96875-13.65625l-5.65625-5.664062-5.65625 5.664062c-8.808594 8.808594-20.511719 13.65625-32.96875 13.65625h-1.375c-5.855469 0-11.273438 1.695312-16 4.449219v-4.449219c0-30.878906 25.128906-56 56-56zm-48 74.222656v27.546875c-4.761719-2.777343-8-7.882812-8-13.777343 0-5.894532 3.238281-10.992188 8-13.769532zm0 0" />
        <path d="m0 496h160v-208h-160zm16-192h128v176h-128zm0 0" />
        <path d="m32 320h96v16h-96zm0 0" />
        <path d="m32 352h16v16h-16zm0 0" />
        <path d="m64 352h64v16h-64zm0 0" />
        <path d="m32 384h96v16h-96zm0 0" />
        <path d="m32 416h96v16h-96zm0 0" />
        <path d="m112 448h16v16h-16zm0 0" />
        <path d="m32 448h64v16h-64zm0 0" />
        <path d="m213.65625 357.65625-11.3125-11.3125-37.65625 37.65625 37.65625 37.65625 11.3125-11.3125-18.34375-18.34375h60.6875v-16h-60.6875zm0 0" />
        <path d="m88 128c0-4.40625 3.585938-8 8-8h36.6875l-18.34375 18.34375 11.3125 11.3125 37.65625-37.65625-37.65625-37.65625-11.3125 11.3125 18.34375 18.34375h-36.6875c-13.230469 0-24 10.769531-24 24v144h16zm0 0" />
        <path d="m384 128v100.6875l-18.34375-18.34375-11.3125 11.3125 37.65625 37.65625 37.65625-37.65625-11.3125-11.3125-18.34375 18.34375v-100.6875c0-13.230469-10.769531-24-24-24h-40v16h40c4.414062 0 8 3.59375 8 8zm0 0" />
      </svg>
    </>
  );
}
