import { Button, Descriptions, Icon, Row } from 'antd';
import React from 'react';
import { FailedEmailsList } from './FailedEmailsList';
import {
  IFailedEmail,
  ITransactionDetails,
} from './massPointsManagement.interface';
import { useIntl } from 'react-intl';

export interface IValidateStepProps {
  transactionDetails: ITransactionDetails;
  validateFailedEmails: IFailedEmail[] | null;
}
export function ValidateStep({
  transactionDetails,
  validateFailedEmails,
}: IValidateStepProps) {
  const intl = useIntl();

  if (!transactionDetails.emails.value || !validateFailedEmails) return null;

  const numTotalEmails = transactionDetails.emails.value.length;
  const numValidEmails = numTotalEmails - validateFailedEmails.length;
  const failedEmailsLabel = intl.formatMessage(
    {
      id: 'points.management.bulk.modal.validate.description',
    },
    { numValidEmails, numTotalEmails },
  );
  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item
        label={intl.formatMessage({
          id: 'points.management.button.modal.input.description',
        })}>
        {transactionDetails.description.value}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({
          id: 'points.management.button.modal.input.points-short',
        })}>
        {transactionDetails.amount.value}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({
          id: 'points.management.button.modal.input.emails',
        })}>
        {validateFailedEmails.length > 0 ? (
          <>
            <p>{failedEmailsLabel}</p>
            <p>
              <Icon type="warning" />{' '}
              {intl.formatMessage({
                id: 'points.management.bulk.modal.validate.failed-emails-label',
              })}
            </p>
            <FailedEmailsList failedEmails={validateFailedEmails} />
          </>
        ) : (
          failedEmailsLabel
        )}
      </Descriptions.Item>
    </Descriptions>
  );
}

export interface IValidateStepFooterProps {
  isLoading: boolean;
  onBackClick: CallableFunction;
  onSubmitClick: CallableFunction;
}
export function ValidateStepFooter({
  isLoading,
  onBackClick,
  onSubmitClick,
}: IValidateStepFooterProps) {
  const intl = useIntl();

  return (
    <Row>
      <Button onClick={() => onBackClick()} disabled={isLoading}>
        {intl.formatMessage({ id: 'stepper.back' })}
      </Button>
      <Button
        type="primary"
        onClick={() => onSubmitClick()}
        loading={isLoading}>
        {intl.formatMessage({
          id: 'points.management.button.modal.form.submit',
        })}
      </Button>
    </Row>
  );
}
