import * as React from 'react';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Context from './Context';
import { checkTranslationsLabels } from '../locale/locale';

function App() {
  React.useEffect(() => {
    checkTranslationsLabels();
  }, []);
  return (
    <Context>
      <Router>
        <Routes />
      </Router>
    </Context>
  );
}

export default App;
