import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import {
  ChallengeTypeEnum,
  FrontOfficeChallengePageEnum,
} from '../../challenge/Enums';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { getFrontUrl, isPINPlatform } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

export default function PreviewStaticPageButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  return <ConnectedPreview selectedRow={selectedRow} />;
}

interface OwnButtonProps {
  selectedRow: IRow;
}

type ButtonProps = ReturnType<typeof mapStateToProps> & OwnButtonProps;

const ButtonPreviewComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [userToken, setUserToken] = useState('');
  const idChallenge = isPINPlatform() ? selectedRow?.slug : selectedRow?.idChallenge;
  const { startDate, startVisDate, endDate } = selectedRow;
  const isCorrectDate = (startDate && startVisDate && endDate) !== null;

  useEffect(() => {
    async function fetchUserToken() {
      const userToken = await api.postDataCall({
        dataPath: apiPaths.CALL.PREVIEW,
        data: {},
        callConfig: {},
      });
      setUserToken(userToken.data.accessToken);
    }

    fetchUserToken();
  }, []);

  const getFrontChallengeURL = (idChallenge: string | number): string => {
    const FRONT_URL = getFrontUrl(currentPlatform, platforms);

    let challengeURL: string = FrontOfficeChallengePageEnum.TALK;

    switch (selectedRow?.idChallengeType?.idChallengeType) {
      case ChallengeTypeEnum.FORMATION:
        challengeURL = `${FrontOfficeChallengePageEnum.FORMATION}/${idChallenge}`;
        break;
      case ChallengeTypeEnum.TALK:
        challengeURL = `${FrontOfficeChallengePageEnum.TALK}/${idChallenge}`;
        break;
      case ChallengeTypeEnum.CONGRESS: 
        challengeURL = `${FrontOfficeChallengePageEnum.CONGRESS}/${idChallenge}`;
        break;
      case ChallengeTypeEnum.CAMPAIGN:
        challengeURL = `${FrontOfficeChallengePageEnum.CAMPAIGN}/${idChallenge}`;
        break;
      case ChallengeTypeEnum.ANNUAL_CAMPAIGN:
        challengeURL = `${FrontOfficeChallengePageEnum.ANNUAL_CAMPAIGN}`;
        break;
      case ChallengeTypeEnum.PEARLS:
        challengeURL = `${FrontOfficeChallengePageEnum.PEARLS}/${idChallenge}`;
        break;
      default:
        selectedRow?.idChallengeType &&
          console.warn(
            `FrontOffice challenge url not defined to challenge type: ${selectedRow?.idChallengeType?.name}`,
          );
    }

    return `${FRONT_URL}impersonate?token=${userToken}&targetURL=/${challengeURL}&redirect=1`;
  };

  const previewChallengeModal = () => {
    return (
      <Modal
        visible={modalVisible}
        className="previewModalPage"
        onCancel={() => setModalVisible(false)}
        closable
        destroyOnClose
        centered
        footer={null}>
        <iframe
          title="iframe_frontoffice"
          height="100%"
          width="95%"
          src={getFrontChallengeURL(idChallenge)}
          frameBorder="0"
        />
      </Modal>
    );
  };

  return (
    <>
      {previewChallengeModal()}
      <Button
        onClick={() => setModalVisible(true)}
        disabled={isEmpty(selectedRow) || !isCorrectDate}
        type="primary">
        <FormattedMessage id="challenge.media.preview" />
      </Button>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};

const ConnectedPreview = connect(mapStateToProps, {})(ButtonPreviewComponent);
