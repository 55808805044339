import { Button, Checkbox, List, Modal, Row, Icon } from 'antd';
import React, { Fragment, useEffect, useState, FC } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { PIN_PlatformID_Enum } from '../../shared/enums/platform.enum';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import { ReducersState } from '../../reducers';
import './DuplicateButton.css';
import { connect } from 'react-redux';
import { IRow } from '../../app/AppInterfaces';

export default function DuplicateStaticPageButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  return <DuplicateStaticPageButtonComponet selectedRow={selectedRow} />;
}

interface OwnButtonProps {
  selectedRow: IRow;
}

interface platformInterface {
  platformCode: string;
  platformName: string;
}

type ButtonProps = ReturnType<typeof mapStateToProps> & OwnButtonProps;

const DuplicateStaticPageButtonComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
  platformCode,
}) => {
  const intl = useIntl();
  const [visibleModalPlatforms, setVisibleModalPlatforms] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visibleModalResponse, setVisibleModalResponse] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    platformInterface[]
  >([]);
  const [errorPlatforms, setErrorPlatforms] = useState<platformInterface[]>([]);
  const [successPlatforms, setSuccessPlatforms] = useState<platformInterface[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [dataSource, setDataSource] = useState<platformInterface[]>([]);

  useEffect(() => {
    setLoading(true);
    if (dataSource.length === 0) {
      let enablePlatforms: string[] = Object.values(PIN_PlatformID_Enum);

      const listOfPlatforms: platformInterface[] = platforms
        .filter(
          (platform) =>
            platform.platform_code !== platformCode &&
            enablePlatforms.includes(platform.platform_code),
        )
        .map((platform) => {
          return {
            platformCode: platform.platform_code,
            platformName: platform.description,
          };
        });
      if (listOfPlatforms.length > 0) setDataSource(listOfPlatforms);
    }
    setLoading(false);
  }, [
    visibleModalConfirm,
    visibleModalPlatforms,
    dataSource,
    platformCode,
    platforms,
  ]);

  const handleEditPlatforms = (item: platformInterface) => {
    if (selectedPlatforms.includes(item)) {
      setSelectedPlatforms(
        selectedPlatforms.filter((platform) => platform !== item),
      );
    } else setSelectedPlatforms([...selectedPlatforms, item]);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks

  const closeComponent = () => {
    setSelectedPlatforms([]);
    setDataSource([]);
    setErrorPlatforms([]);
    setSuccessPlatforms([]);
    setVisibleModalPlatforms(false);
  };

  const handleDuplicateChallenges = async () => {
    setVisibleModalConfirm(false);
    setVisibleModalPlatforms(false);
    closeComponent();
    setLoadingResponse(true);
    setVisibleModalResponse(true);
    selectedPlatforms.map(async (platform) => {
      try {
        let platformURL: string = '';
        platforms?.map((platformInList: any) => {
          if (platformInList.platform_code === platform.platformCode)
            platformURL = platformInList.value;
        });

        api.setAxiosBaseUrl(platformURL);
        const response = await api.postDataCall({
          dataPath: `${apiPaths.CALL.CREATE_FULL_PAGE}`,
          data: selectedRow,
          callConfig: {},
        });

        setSuccessPlatforms((prevState) => prevState.concat(platform));
        return response;
      } catch (err) {
        setErrorPlatforms((prevState) => prevState.concat(platform));
        console.group(err);
      }
      return true;
    });

    api.setAxiosBaseUrl(currentPlatform);
    setLoadingResponse(false);
  };

  if (!selectedRow.type) return <React.Fragment />;
  return (
    <>
      <Button type="primary" onClick={() => setVisibleModalPlatforms(true)}>
        {intl.formatMessage({ id: 'button-title.duplicate-staticPage' })}
      </Button>

      <Modal
        visible={visibleModalResponse}
        onCancel={() => setVisibleModalResponse(false)}
        footer={null}>
        <div className="duplicate title">
          {intl.formatMessage({
            id: 'challenge.duplicate-message-correctly',
          })}
        </div>
        <List
          loading={loadingResponse}
          itemLayout="horizontal"
          dataSource={successPlatforms}
          renderItem={(item: platformInterface) => (
            <List.Item>
              <Icon
                className="duplicate icon"
                type="check-circle"
                style={{ color: 'green' }}
              />
              {item.platformName}
            </List.Item>
          )}
        />
        <div className="duplicate title">
          {intl.formatMessage({ id: 'challenge.duplicate-message-error' })}
        </div>
        <List
          loading={loadingResponse}
          itemLayout="horizontal"
          dataSource={errorPlatforms}
          renderItem={(item: platformInterface) => (
            <List.Item>
              <Icon
                className="duplicate icon"
                type="close-circle"
                style={{ color: 'red' }}
              />
              {item.platformName}
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        visible={visibleModalConfirm}
        onCancel={() => setVisibleModalConfirm(false)}
        footer={null}>
        <div className="duplicate title">
          {intl.formatMessage({ id: 'challenge.duplicate-message-confirm' })}
        </div>
        <Row className="duplicate container-buttons">
          <Button onClick={() => setVisibleModalConfirm(false)}>
            {intl.formatMessage({ id: 'form.edit.cancel' })}
          </Button>
          <Button
            className="duplicate.button-cancel"
            type="primary"
            onClick={() => handleDuplicateChallenges()}>
            {intl.formatMessage({ id: 'form.apply' })}
          </Button>
        </Row>
      </Modal>

      <Modal
        visible={visibleModalPlatforms}
        onCancel={() => closeComponent()}
        footer={null}>
        <div className="duplicate title">
          {intl.formatMessage({ id: 'staticPage.duplicate-message' })}
        </div>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item: platformInterface) => (
            <List.Item
              actions={[
                <Checkbox onChange={() => handleEditPlatforms(item)} />,
              ]}>
              {item.platformName}
            </List.Item>
          )}
        />

        <Row className="duplicate container-button-center">
          <Button
            disabled={selectedPlatforms.length === 0}
            type="primary"
            onClick={() => setVisibleModalConfirm(true)}>
            {intl.formatMessage({ id: 'button-title.duplicate-staticPage' })}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms ?? [],
    platformCode:
      state.app.platforms?.find(({ value }) => {
        return value === state.app.currentPlatform;
      })?.platform_code ?? '',
  };
};

const DuplicateStaticPageButtonComponet = connect(
  mapStateToProps,
  {},
)(DuplicateStaticPageButtonComponent);
