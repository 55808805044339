import { Button, Col, Divider, Form, Icon, Input, Popconfirm, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ReducersState } from '../../../reducers';
import { IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { ResourceTypeEnum } from '../../Enums';
import {
  editRecursiveResource,
  handleAddChildResource,
  handleRemoveParentResource,
} from '../../challengeActions';
import Material from '../ChallengeMaterials/Material';
import CheckoutProduct from '../Checkout/CheckoutProduct';
import ExpertsGroup from '../ExpertsGroup/ExpertsGroup';
import Speaker from '../ExpertsGroup/Speaker';
import QuestionResource from '../Quiz/QuestionResource';
import QuestionResourceDermo from '../Quiz/QuestionResourceDermo';
import {
  blankAnswer,
  blankProduct,
  blankSpeaker,
  blankExternalRedeemPointsPoints,
  blankLearningQuizAnswer,
} from '../ResourceConfigs';
import SellOutPointsRange from '../SellOut/SellOutPointsRange';
import ShoppingCartGroup from '../ShoppingCart/ShoppingCartGroup';
import StepsContent from '../Steps/StepsContent';
import TextLinkItem from '../TextLink/TextLinkItem';
import SellInImagePointsRange from '../SellIn/SellInImagePointsRange';
import IncludedItem from '../IncludedItemsList/IncludedItem';
import CheckoutDonationPoints from '../CheckoutDonation/CheckoutDonationPoints';
import CheckoutSupplementaryProduct from '../Checkout/CheckoutSupplementaryProduct';
import { IRow } from '../../../app/AppInterfaces';
import CheckoutPaymentGatewayProduct from '../CheckoutPaymentGateway/CheckoutPaymentGatewayProduct';
import WebeatCheckoutProduct from '../WebeatCheckout/WebeatCheckoutProduct';
import ExternalRedeemPointsPoints from '../ExternalRedeemPoints/ExternalRedeemPointsPoints';
import LearningQuizQuestionResource from '../WebeatLearningQuiz/LearningQuizQuestionResource';

const {
  CHALLENGE_MATERIALS,
  CHECKOUT,
  CHECKOUT_PAYMENT_GATEWAY,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT,
  CHECKOUT_PRODUCT,
  CHECKOUT_SUPPLEMENTARY_PRODUCT,
  CHECKOUT_DONATION,
  CHECKOUT_DONATION_POINTS,
  EVENT,
  MATERIAL,
  QUESTION,
  QUIZ,
  QUIZ_DERMO,
  SELL_IN_POINTS_RANGE,
  SELL_IN,
  SELL_OUT_POINT_RANGE,
  SELL_OUT,
  SHOPPING_CART_GROUP,
  SHOPPING_CART,
  SPEAKER,
  STEPS,
  STEPS_CONTENT,
  TEXT_LINK,
  TEXT_LINK_ITEM,
  INCLUDED_ITEMS_LIST,
  INCLUDED_ITEM,
  EXPERTS_GROUP,
  WEBEAT_CHECKOUT,
  WEBEAT_CHECKOUT_PRODUCT,
  EXTERNAL_REDEEM_POINTS,
  EXTERNAL_REDEEM_POINTS_POINTS,
  WEBEAT_LEARNING_QUIZ,
  WEBEAT_LEARNING_QUIZ_QUESTION,
} = ResourceTypeEnum;

interface IChildProps {
  childIndex: number;
  children: JSX.Element;
  childResource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  title: string;
  values: IRow;
}
type IChildConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IChildProps;

const ChildResource = ({
  childIndex,
  children,
  childResource: {
    name: childName,
    idResourceType: { idResourceType },
  },
  editRecursiveResource,
  editResource,
  form: { getFieldDecorator },
  handleAddChildResource,
  handleRemoveParentResource,
  selectedResource,
  title,
}: IChildConnectedProps) => {
  const { formatMessage } = useIntl();
  const MAX_SPEAKERS = 6;

  const isQuestion = idResourceType === QUESTION;

  const isSpeaker = idResourceType === SPEAKER;

  const isExpertsGroup = idResourceType === EXPERTS_GROUP;

  const isImageRange = idResourceType === SELL_IN_POINTS_RANGE;

  const isTextLink = idResourceType === TEXT_LINK_ITEM;

  const isCheckoutDurationPoints = idResourceType === CHECKOUT_DONATION_POINTS;

  const isCheckoutSupplementaryProduct =
    idResourceType === CHECKOUT_SUPPLEMENTARY_PRODUCT;

  const isCheckoutProduct =
    idResourceType === CHECKOUT_PRODUCT ||
    idResourceType === WEBEAT_CHECKOUT_PRODUCT;

  const isCheckoutPaymentGateway =
    idResourceType === CHECKOUT_PAYMENT_GATEWAY_PRODUCT;

  const isSellOutPointRange = [SELL_OUT_POINT_RANGE].includes(idResourceType);

  const isExternalRedeemPoints = [EXTERNAL_REDEEM_POINTS_POINTS].includes(
    idResourceType,
  );

  const isLearningQuizQuestion = idResourceType === WEBEAT_LEARNING_QUIZ_QUESTION;

  const showIndex =
    !isExpertsGroup &&
    !isSpeaker &&
    !isCheckoutProduct &&
    !isImageRange &&
    !isCheckoutSupplementaryProduct;

  const limitLength = idResourceType === INCLUDED_ITEM ? 23 : 250;

  const renderAddComponent = ![
    SELL_OUT_POINT_RANGE,
    SELL_IN_POINTS_RANGE,
    STEPS_CONTENT,
    STEPS_CONTENT,
    CHECKOUT_PRODUCT,
    WEBEAT_CHECKOUT_PRODUCT,
    CHECKOUT_SUPPLEMENTARY_PRODUCT,
    CHECKOUT_DONATION,
    MATERIAL,
    INCLUDED_ITEM,
  ].includes(idResourceType);
  
  let customizedChildIndex;
  if (isLearningQuizQuestion) {
    customizedChildIndex = childIndex+1;
  }

  const inputKey = (isQuestion || isLearningQuizQuestion) ? 'question' : 'shopping-group';

  const isDisabledDeleteSpeakersButton =
    isSpeaker && children.props.speakersNumber <= 1;

  const component = editResource ? (
    <Row className="QuizQuestion" type="flex" gutter={[24, 0]}>
      <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }} order={0}>
        {!isCheckoutDurationPoints && (
          <Form.Item className="Quiz__input">
            {title} {showIndex && (customizedChildIndex ?? childIndex)}
          </Form.Item>
        )}
      </Col>
      <Col xs={22} md={16} xl={17} order={1}>
        {!isTextLink &&
          !isCheckoutDurationPoints &&
          !isExternalRedeemPoints && (
            <Form.Item className="Quiz__input">
              {getFieldDecorator(inputKey.concat(childIndex.toString()), {
                initialValue: childName,
                rules: [
                  {
                    required: true,
                    message: formatMessage({ id: 'update.required' }),
                    max: 250,
                  },
                ],
              })(
                isSellOutPointRange ? (
                  <div className="QuizQuestion__media--ellipsis">
                    {childName}
                  </div>
                ) : (
                  <Input
                    onBlur={(e) =>
                      editRecursiveResource({
                        value: e.target.value,
                        parentArrayPosition: childIndex,
                      })
                    }
                    maxLength={(isQuestion || isLearningQuizQuestion) ? 250 : limitLength}
                  />
                ),
              )}
            </Form.Item>
          )}
      </Col>
      <Col xs={2} order={2}>
        <Form.Item className="Quiz__input">
          <Popconfirm
            title={formatMessage({ id: 'pop.title.delete' })}
            icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
            okText={formatMessage({ id: 'pop.accept' })}
            cancelText={formatMessage({ id: 'pop.cancel' })}
            onConfirm={() => handleRemoveParentResource(childIndex)}
            disabled={isDisabledDeleteSpeakersButton}>
            <Button
              className={
                isDisabledDeleteSpeakersButton
                  ? 'QuizQuestion__deleteButton__disabled'
                  : 'QuizQuestion__deleteButton'
              }
              icon="close"
              disabled={isDisabledDeleteSpeakersButton}
            />
          </Popconfirm>
        </Form.Item>
      </Col>
    </Row>
  ) : (
    <Row className="QuizQuestion" type="flex" gutter={[24, 24]}>
      <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
        {title} {showIndex && (customizedChildIndex ?? childIndex)}
      </Col>
      <Col xs={24} md={18} xl={19}>
        <div className="QuizQuestion__media--ellipsis">{childName}</div>
      </Col>
    </Row>
  );
  const expertGroup = selectedResource.resourceList?.find(
    (resource) =>
      resource.idResourceType.idResourceType === ResourceTypeEnum.EXPERTS_GROUP,
  );
  const speakersList = expertGroup?.resourceList;

  function isDisabled() {
    return (
      isExternalRedeemPoints &&
      isExpertsGroup &&
      speakersList &&
      speakersList.length >= MAX_SPEAKERS
    );
  }

  function getButtonMessageId() {
    const messagesMap = new Map([
      [true, 'challenge.addProduct'],
      [isExpertsGroup, 'Add Speaker'],
      [isQuestion, 'challenge.addAnswer'],
      [isLearningQuizQuestion, 'challenge.addAnswer'],
      [isExternalRedeemPoints, 'external-redeem-points.addPoints'],
    ]);
    return messagesMap.get(true) || 'challenge.addProduct';
  }

  function getBlankResource(): IResource {
    const _blankResource = blankProduct(selectedResource, childIndex);
    const resourcesMap = new Map([
      [true, _blankResource],
      [
        isExpertsGroup,
        blankSpeaker(
          selectedResource.resourceList![childIndex].idResource,
          selectedResource.resourceList![childIndex].resourceList,
        ),
      ],
      [isQuestion, blankAnswer(selectedResource, childIndex)],
      [isLearningQuizQuestion, blankLearningQuizAnswer(selectedResource, childIndex)],
      [
        isExternalRedeemPoints,
        blankExternalRedeemPointsPoints(
          selectedResource.resourceList![childIndex].idResource,
          selectedResource.resourceList![childIndex].resourceList,
        ),
      ],
    ]);
    return resourcesMap.get(true) || _blankResource;
  }
  function handleClick() {
    const _blankResource: IResource = getBlankResource();
    handleAddChildResource(_blankResource, childIndex);
  }

  const addComponentButton = editResource &&
    !isSpeaker &&
    !isTextLink &&
    !isCheckoutDurationPoints &&
    !isCheckoutPaymentGateway &&
    !isExternalRedeemPoints && (
      <Row
        className="QuizQuestion__btnAddAnswer"
        style={{ justifyContent: 'center' }}>
        <Button disabled={isDisabled()} type="primary" onClick={handleClick}>
          {formatMessage({ id: getButtonMessageId() })}
        </Button>
      </Row>
    );
  return (
    <>
      {component}
      {children}
      {renderAddComponent && addComponentButton}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
      handleAddChildResource,
      handleRemoveParentResource,
    },
    dispatch,
  );

const mapStateToProps = (state: ReducersState) => {
  return {
    selectedResource: state.challenge.selectedResource,
  };
};

const ConnectedChild = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChildResource);

interface IChildrenRow {
  handleDeleteDetail?: (detailArrayPosition: number) => void;
  handleAddDetail?: (newDetail: IResourceDetail) => void;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  idResourceType: ResourceTypeEnum;
  mainIdResource: number;
  resourceList?: IResource[];
  renderExtraFields: false | JSX.Element;
  values: IRow;
}

const ChildrenRow = ({
  handleDeleteDetail,
  handleAddDetail,
  editResource,
  form,
  handleDeleteImages,
  idResourceType,
  mainIdResource,
  resourceList,
  values,
}: IChildrenRow) => {
  const { formatMessage } = useIntl();
  let childIdResourceType: ResourceTypeEnum[] | null;
  let childComponent:
    | ((_resource: IResource, index: number) => JSX.Element)
    | null;

  switch (idResourceType) {
    case QUIZ:
      childIdResourceType = [QUESTION];
      childComponent = (_resource: IResource, index: number) => (
        <QuestionResource
          handleDeleteDetail={handleDeleteDetail}
          handleAddDetail={handleAddDetail}
          _resource={_resource}
          editResource={editResource}
          form={form}
          handleDeleteImages={handleDeleteImages}
          questionIndex={index}
          values={values}
        />
      );
      break;
    case QUIZ_DERMO:
      childIdResourceType = [QUESTION];
      childComponent = (_resource: IResource, index: number) => (
        <QuestionResourceDermo
          handleDeleteDetail={handleDeleteDetail}
          handleAddDetail={handleAddDetail}
          _resource={{ ..._resource }}
          editResource={editResource}
          form={form}
          questionIndex={index}
          values={values}
        />
      );
      break;
    case WEBEAT_LEARNING_QUIZ:
      childIdResourceType = [WEBEAT_LEARNING_QUIZ_QUESTION];
      childComponent = (_resource: IResource, index: number) => (
        <LearningQuizQuestionResource
          handleDeleteDetail={handleDeleteDetail}
          handleAddDetail={handleAddDetail}
          _resource={{ ..._resource }}
          editResource={editResource}
          form={form}
          questionIndex={index}
          values={values}
        />
      );
      break;
    case SHOPPING_CART:
      childIdResourceType = [SHOPPING_CART_GROUP];
      childComponent = (_resource: IResource, index: number) => (
        <ShoppingCartGroup
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case CHALLENGE_MATERIALS:
      childIdResourceType = [MATERIAL];
      childComponent = (_resource: IResource, index: number) => (
        <Material
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case EVENT:
      childIdResourceType = [EXPERTS_GROUP];
      childComponent = (_resource: IResource, index: number) => (
        <ExpertsGroup
          resource={_resource}
          editResource={editResource}
          form={form}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          arrayPosition={index}
        />
      );
      break;
    case EXPERTS_GROUP:
      childIdResourceType = [SPEAKER];
      const speakers = resourceList?.filter(
        (resourceChild) =>
          resourceChild.idResourceType.idResourceType === SPEAKER &&
          resourceChild.status,
      );
      const speakersNumber = speakers?.length ? speakers.length : 0;
      childComponent = (_resource: IResource, index: number) => (
        <Speaker
          _resource={_resource}
          editResource={editResource}
          form={form}
          parentArrayPosition={index}
          childArrayPosition={
            _resource.resourceList && _resource.resourceList.length > 0
              ? 0
              : undefined
          }
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          canEditResourceName={false}
          speakersNumber={speakersNumber}
        />
      );
      break;
    case EXTERNAL_REDEEM_POINTS:
      childIdResourceType = [EXTERNAL_REDEEM_POINTS_POINTS];
      childComponent = (_resource: IResource, index: number) => (
        <ExternalRedeemPointsPoints
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case CHECKOUT:
      childIdResourceType = [CHECKOUT_PRODUCT, CHECKOUT_SUPPLEMENTARY_PRODUCT];
      childComponent = (_resource: IResource, index: number) => {
        if (
          _resource.idResourceType.idResourceType ===
          ResourceTypeEnum.CHECKOUT_SUPPLEMENTARY_PRODUCT
        )
          return (
            <CheckoutSupplementaryProduct
              _resource={_resource}
              editResource={editResource}
              form={form}
              groupIndex={index}
              handleDeleteImages={handleDeleteImages}
              mainIdResource={mainIdResource}
              values={values}
            />
          );
        return (
          <CheckoutProduct
            _resource={_resource}
            editResource={editResource}
            form={form}
            groupIndex={index}
            handleDeleteImages={handleDeleteImages}
            mainIdResource={mainIdResource}
            values={values}
          />
        );
      };
      break;
    case CHECKOUT_PAYMENT_GATEWAY:
      childIdResourceType = [CHECKOUT_PAYMENT_GATEWAY_PRODUCT];
      childComponent = (_resource: IResource, index: number) => {
        return (
          <CheckoutPaymentGatewayProduct
            _resource={_resource}
            editResource={editResource}
            form={form}
            groupIndex={index}
            handleDeleteImages={handleDeleteImages}
            mainIdResource={mainIdResource}
            values={values}
          />
        );
      };
      break;
    case WEBEAT_CHECKOUT:
      childIdResourceType = [WEBEAT_CHECKOUT_PRODUCT];
      childComponent = (_resource: IResource, index: number) => {
        return (
          <WebeatCheckoutProduct
            _resource={_resource}
            editResource={editResource}
            form={form}
            groupIndex={index}
            handleDeleteImages={handleDeleteImages}
            mainIdResource={mainIdResource}
            values={values}
          />
        );
      };
      break;
    case CHECKOUT_DONATION:
      childIdResourceType = [CHECKOUT_DONATION_POINTS];
      childComponent = (_resource: IResource, index: number) => (
        <CheckoutDonationPoints
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case STEPS:
      childIdResourceType = [STEPS_CONTENT];
      childComponent = (_resource: IResource, index: number) => (
        <StepsContent
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;

    case TEXT_LINK:
      childIdResourceType = [TEXT_LINK_ITEM];
      childComponent = (_resource: IResource, index: number) => (
        <TextLinkItem
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;

    case SELL_OUT:
      childIdResourceType = [SELL_OUT_POINT_RANGE];
      childComponent = (_resource: IResource, index: number) => (
        <SellOutPointsRange
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case SELL_IN:
      childIdResourceType = [SELL_IN_POINTS_RANGE];
      childComponent = (_resource: IResource, index: number) => (
        <SellInImagePointsRange
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;
    case INCLUDED_ITEMS_LIST:
      childIdResourceType = [INCLUDED_ITEM];
      childComponent = (_resource: IResource, index: number) => (
        <IncludedItem
          _resource={_resource}
          editResource={editResource}
          form={form}
          groupIndex={index}
          handleDeleteImages={handleDeleteImages}
          mainIdResource={mainIdResource}
          values={values}
        />
      );
      break;

    default:
      childIdResourceType = null;
      childComponent = null;
      break;
  }

  if (childIdResourceType === null || childComponent === null) return null;

  const resourceChildren: JSX.Element[] = [];

  resourceList
    ?.sort((a: IResource, b: IResource) => a.order - b.order)
    .forEach((_resource, index) => {
      const {
        status,
        idResourceType: { idResourceType, name: typeName },
      } = _resource;

      if (status && childIdResourceType?.includes(idResourceType))
        resourceChildren.push(
          <Col span={24} key={index}>
            <Divider className="resourceDivider" />
            <ConnectedChild
              key={index}
              childIndex={index}
              childResource={_resource}
              editResource={editResource}
              form={form}
              title={formatMessage({
                id: `tree-resource.${typeName.toLocaleLowerCase()}`,
              })}
              values={values}>
              {childComponent!(_resource, index)}
            </ConnectedChild>
          </Col>,
        );
    });

  return <>{resourceChildren}</>;
};

export default ChildrenRow;
