import React, { FC } from 'react';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import { useIntl } from 'react-intl';
import { Col, Form, Input, Row } from 'antd';
import ScanLandingMarkdownInput from './ScanLandingMarkdown';
import { INPUT_MAX_LENGTH } from '../constants';
import ScanLandingLabelWithTooltip from './ScanLandingLabelWithTooltip';
import UploadComponent from './UploadComponent';
import { UPLOAD_ACTION_IMAGE, UPLOAD_ACTION_IMAGE_URL } from '../../shared';

export interface ScanLandingReviewComponentModel {
  review: string;
  reviewerName: string;
  reviewerTitle: string;
  reviewerImage?: string;
}

const REVIEW_MAX_LENGTH = 135;

const ScanLandingReviewComponent: FC<
  ScanLandingComponentProps<ScanLandingReviewComponentModel> &
    ScanLandingComponentUploadProps
> = ({
  index,
  form,
  values,
  disabled,
  handleOnChange,
  handleValidateMarkdownInput,
  token,
}) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <div>
      <Form.Item
        label={
          <ScanLandingLabelWithTooltip
            label={formatMessage({
              id: 'scanLanding.component.review.review',
            })}
            tooltipTitle={formatMessage(
              {
                id: 'scanLanding.component.review.review.tooltip',
              },
              { maxLength: REVIEW_MAX_LENGTH },
            )}
          />
        }>
        {getFieldDecorator(`components[${index}].model.review`, {
          initialValue: values.review ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.review.review.required-error',
              }),
            },
            {
              validator: (_: any, value: string) =>
                handleValidateMarkdownInput(REVIEW_MAX_LENGTH, value),
              message: formatMessage({
                id: 'scanLanding.component.review.review.max-length-error',
              }),
            },
          ],
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.review`)?.length
            }
            value={form.getFieldValue(`components[${index}].model.review`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.review`, value);
            }}
          />,
        )}
      </Form.Item>
      <Row gutter={32}>
        <Col sm={24} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'scanLanding.component.review.reviewerName',
            })}>
            {getFieldDecorator(`components[${index}].model.reviewerName`, {
              initialValue: values.reviewerName ?? '',
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'scanLanding.component.review.reviewerName.required-error',
                  }),
                },
              ],
            })(
              <Input
                maxLength={INPUT_MAX_LENGTH}
                disabled={disabled}
                placeholder={formatMessage({
                  id: 'scanLanding.component.review.reviewerName.placeholder',
                })}
              />,
            )}
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'scanLanding.component.review.reviewerTitle',
            })}>
            {getFieldDecorator(`components[${index}].model.reviewerTitle`, {
              initialValue: values.reviewerTitle ?? '',
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'scanLanding.component.review.reviewerTitle.required-error',
                  }),
                },
              ],
            })(
              <Input
                maxLength={INPUT_MAX_LENGTH}
                disabled={disabled}
                placeholder={formatMessage({
                  id: 'scanLanding.component.review.reviewerTitle.placeholder',
                })}
              />,
            )}
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'scanLanding.component.review.reviewerImage',
            })}>
            {getFieldDecorator(`components[${index}].model.reviewerImage`, {
              initialValue: values.reviewerImage ?? '',
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <UploadComponent
                {...{
                  value: values.reviewerImage ?? '',
                  setValue: (value) => {
                    handleOnChange(
                      `components[${index}].model.reviewerImage`,
                      value,
                      true,
                    );
                  },
                  fieldName: `components[${index}].model.reviewerImage`,
                  token,
                  disabled,
                  format: 'image',
                  uploadActions: [UPLOAD_ACTION_IMAGE, UPLOAD_ACTION_IMAGE_URL],
                }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ScanLandingReviewComponent;
