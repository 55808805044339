import { queryTypes } from './queryTypes';
import qs from 'query-string';
import config from '../config';
import { IBreadcrumb } from '../breadcrumb/SmartBreadcrumbInterfaces';
import { QueryActionTypes } from './queryActions';

const initialParams = {} as { q?: string };

const initialBreadcrumbsJSON = localStorage.getItem(
  config.LOCAL_STORAGE.BREADCRUMBS,
);
const initialBreadcrumbs =
  initialBreadcrumbsJSON &&
  initialBreadcrumbsJSON !== 'undefined' &&
  initialBreadcrumbsJSON !== undefined
    ? JSON.parse(initialBreadcrumbsJSON)
    : ([] as IBreadcrumb[]);

export type QueryState = {
  breadcrumbs: IBreadcrumb[];
  params: { q?: string; sort?: string };
  queryParamsLoaded?: boolean;
  editFormNavigation?: boolean;
  searchFormNavigation?: boolean;
};

const initialState = {
  breadcrumbs: initialBreadcrumbs,
  params: initialParams,
  queryParamsLoaded: false,
};

export function queryReducer(
  state = initialState,
  action: QueryActionTypes,
): QueryState {
  switch (action.type) {
    case queryTypes.QUERY_SET_INITIAL_PARAMS:
      return {
        ...state,
        params: { q: action.payload.initialParams },
      };
    case queryTypes.QUERY_LOAD_QUERYPARAMS:
      return {
        ...state,
        queryParamsLoaded: true,
      };
    case queryTypes.QUERY_SET_NAVIGATION:
      return {
        ...state,
        editFormNavigation: true,
        searchFormNavigation: true,
      };
    case queryTypes.QUERY_SET_COMPONENT_NAVIGATION:
      return {
        ...state,
        [action.payload.component]: false,
      };
    case queryTypes.ROUTER_LOCATION_CHANGE:
      const { search } = action.payload.location;

      if (!search) {
        return {
          ...state,
          params: initialParams,
        };
      }

      return {
        ...state,
        params: qs.parse(search),
      };

    case queryTypes.QUERY_PUSH_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
      };

    case queryTypes.QUERY_GO_BACK:
      return {
        ...state,
        breadcrumbs: action.payload.newBreadcrumbs,
      };
    default:
      return state;
  }
}
