import { EditFormRenderProps } from '../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../tables/TableRender';
import AddCenter from './buttons/AddCenter';
import AddSurveyMailButton from './buttons/AddSurveyMailButton';
import AddVademecumButton from './buttons/AddVademecumButton';
import AssignPointsButton from './buttons/AssignPointsButton';
import AssignUsersButton from './buttons/AssignUsersButton';
import BaseButton from './buttons/BaseButton';
import ChallengeUsersButton from './buttons/ChallengeUsersButton';
import ChallengeUsersList from './buttons/ChallengeUsersList';
import CleanLabeledProducts from './buttons/CleanLabeledProducts';
import CreateDataReplicaButton from './buttons/DataReplica/CreateDataReplicaButton';
import DeleteDermoButton from './buttons/DeleteDermoButton';
import DeleteMassiveUsers from './buttons/DeleteMassiveUsersButton';
import DisclaimerSpecialtiesButtons from './buttons/DisclaimerSpecialtiesButtons';
import DisclaimerSpecialtiesAndRolesSelect from './selects/DisclaimerSpecialtiesAndRolesSelect';
import DuplicateChallengeButton from './buttons/DuplicateChallengeButton';
import DuplicateStaticPageButton from './buttons/DuplicateStaticPageButton';
import EditExpirationPointsDates from './buttons/EditExpirationPointsDates';
import GlobalActionAssignPoints from './buttons/GlobalActionAssignPoints';
import ImpersonateButton from './buttons/ImpersonateButton';
import ActivateQrCsvParserButton from './buttons/InvalidateQrCsvParserButton';
import ListOfValuesI18nButton from './buttons/ListOfValuesI18nButton';
import MaterialsI18nButton from './buttons/MaterialsI18nButton';
import MenuI18nButton from './buttons/MenuI18nButton';
import PearlsButton from './buttons/PearlsButton';
import PearlsReviewerButton from './buttons/PearlsReviewerButton';
import PointsManagementButton from './buttons/PointsManagement';
import PreviewChallengeButton from './buttons/PreviewChallengeButton';
import PreviewMailButton from './buttons/PreviewMailButton';
import StaticPagePreview from './buttons/PreviewStaticPageButton';
import RejectDermoButton from './buttons/RejectDermoButton';
import ReplicateChallengeButton from './buttons/ReplicateChallengeButton';
import RequestOrderButton from './buttons/RequestOrderButton';
import ResetPasswordButton from './buttons/ResetPasswordButton';
import RolesI18nButton from './buttons/RolesI18nButton';
import SellInReportButton from './buttons/SellInReportButton';
import ShoppingCartOrderButton from './buttons/ShoppingCartOrderButton';
import SpecialtyI18nButton from './buttons/SpecialtyI18nButton';
import TagsMailButton from './buttons/TagsMailButton';
import TokensUserList from './buttons/TokenListButton';
import TokensArnSurveyButton from './buttons/TokensArnSurveyButton';
import TrainingReportButton from './buttons/TrainingUsersButton';
import StatisticChallengeButton from './buttons/StatisticChallengeButton';
import TransmuteToButton from './buttons/TransmuteToButton';
import UploadFilesButton from './buttons/UploadFilesButton';
import ValidateDermoButton from './buttons/ValidateDermoButton';
import ValidateUserEmailButton from './buttons/ValidateUserEmailButton';
import CleanAllCacheButton from './buttons/cleanAllCacheButton';
import CleanCacheButton from './buttons/cleanCacheButton';
import DeleteCustomerCartItems from './buttons/deleteCustomerCartItems';
import BulkEditLandingInfo from './buttons/landingProduct/BulkEditLandingInfo';
import BulkEditQrProducts from './buttons/qrProducts/BulkEditQrProducts';
import SoftDeleteUserButton from './buttons/SoftDeleteUserButton';
import WebeatValidateDermoButton from './buttons/WebeatValidateDermoButton';
import WebeatInvalidateDermoButton from './buttons/WebeatInvalidateDermoButton';
import WebeatMassPointsManagementButton from './buttons/massPointsManagement/WebeatMassPointsManagement';
import LiconMassPointsManagementButton from './buttons/massPointsManagement/LiconMassPointsManagement';

interface ICustomButtons {
  [key: string]: <
    T extends
      | ITableRenderListProps
      | EditFormRenderProps
      | SearchFormRenderProps,
  >(
    parentProps: T,
  ) => JSX.Element;
}

const customButtons: ICustomButtons = {
  ActivateQrCsvParserButton: ActivateQrCsvParserButton,
  addCenter: AddCenter,
  addSurveyToMail: AddSurveyMailButton,
  addVademecumButton: AddVademecumButton,
  assignPointsButton: AssignPointsButton,
  assignUsersButton: AssignUsersButton,
  baseButton: BaseButton,
  challengeUsers: ChallengeUsersButton,
  challengeUsersList: ChallengeUsersList,
  cleanAllCacheButton: CleanAllCacheButton,
  cleanCacheButton: CleanCacheButton,
  createDataReplicaButton: CreateDataReplicaButton,
  duplicateChallengeButton: DuplicateChallengeButton,
  editExpirationPointsDates: EditExpirationPointsDates,
  bulkEditLandingInfo: BulkEditLandingInfo,
  bulkEditQrProducts: BulkEditQrProducts,
  impersonateButton: ImpersonateButton,
  listOfValuesI18nButton: ListOfValuesI18nButton,
  materialsI18nButton: MaterialsI18nButton,
  menuI18nButton: MenuI18nButton,
  pearlsButton: PearlsButton,
  pearlsReviewerButton: PearlsReviewerButton,
  pointsManagementButton: PointsManagementButton,
  previewChallengeButton: PreviewChallengeButton,
  previewMailButton: PreviewMailButton,
  replicateChallengeButton: ReplicateChallengeButton,
  requestOrderButton: RequestOrderButton,
  resetPasswordButton: ResetPasswordButton,
  rolesI18nButton: RolesI18nButton,
  sellInReportButton: SellInReportButton,
  shoppingCartOrderButton: ShoppingCartOrderButton,
  disclaimerSpecialtiesButtons: DisclaimerSpecialtiesButtons,
  disclaimerSpecialtiesAndRolesSelect: DisclaimerSpecialtiesAndRolesSelect,
  specialtyI18nButton: SpecialtyI18nButton,
  statisticChallengeButton: StatisticChallengeButton,
  staticPagePreviewButton: StaticPagePreview,
  duplicateStaticPageButton: DuplicateStaticPageButton,
  tagsMailButton: TagsMailButton,
  trainingReportButton: TrainingReportButton,
  transmuteToButton: TransmuteToButton,
  uploadFilesButton: UploadFilesButton,
  tokensArnSurveyButton: TokensArnSurveyButton,
  deleteCustomerCartItems: DeleteCustomerCartItems,
  deleteMassiveUsers: DeleteMassiveUsers,
  cleanLabeledProducts: CleanLabeledProducts,
  globalActionAssignPoints: GlobalActionAssignPoints,
  tokensUserList: TokensUserList,
  validateDermoButton: ValidateDermoButton,
  rejectDermoButton: RejectDermoButton,
  deleteDermoButton: DeleteDermoButton,
  softDeleteUserButton: SoftDeleteUserButton,
  validateUserButton: ValidateUserEmailButton,
  liconMassPointsManagementButton: LiconMassPointsManagementButton,
  webeatValidateDermoButton: WebeatValidateDermoButton,
  webeatInvalidateDermoButton: WebeatInvalidateDermoButton,
  webeatMassPointsManagementButton: WebeatMassPointsManagementButton,
};

export default customButtons;
