import React from 'react';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { ReducersState } from '../../reducers';
import { Form } from 'antd';
import apiPaths from '../../apiPaths';
import configDev from '../../configDev';
import config from '../../config';

import {
  getTableData,
  setTableLoadData,
  setSelectedRow,
} from '../../tables/tableActions';
import {
  setDrawerVisibility,
  setSelectedTab,
} from '../../dashboards/dashboardActions';
import {
  setFieldsConfig,
  setInitialState,
  setFormData,
  setTriggerGetTableData,
  resetSearchForm,
  setFormHasChanged,
  setResetFormTrigger,
  setFilterVisibility,
  setSelectedTabSearch,
} from './searchActions';
import { getComboData } from '../../combos/comboActions';
import { editNavigationState, cleanBreadcrumb } from '../../app/queryActions';
import { logout } from '../../auth/authActions';

import SearchFormRender from './SearchFormRender';
import { appComponents } from '../../components';
import { feedbackController } from '../../utils/feedback';
import { formConstructor, makeGlobalSearchField } from '../formConstructor';
import { loadConfig, saveConfig } from '../../utils/localStorage';
import { qComposer } from '../../utils/apiUtils';
import '../FormStyles.css';

import { TDashboardComposerListProps } from '../../dashboards/DashboardComposer';
import {
  ISearchParams,
  IComponentGroup,
  IGroupField,
  ICompleteField,
  IFormSettings,
} from '../formInterfaces';
import { GlobalSearch } from './searchReducer';
import { ISearchField } from '../../fields/FieldsInterfaces';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IRow, IRecord } from '../../app/AppInterfaces';
// import advancedSearch from '../../fields/advancedSearch';

const flatten = require('flat').flatten;

const isTableTarget = (targetId: string) =>
  targetId !== '' && appComponents[targetId].type === 'table';

interface OwnProps {
  params: ISearchParams;
  fields: ISearchField[];
  groups: IComponentGroup[];
  options: IFormSettings;
}

export type ISearchFormListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps &
  FormComponentProps &
  TDashboardComposerListProps;

export class SearchForm extends React.PureComponent<ISearchFormListProps, {}> {
  constructor(props: ISearchFormListProps) {
    super(props);
    const {
      params,
      fields,
      groups,
      fieldsConfig,
      setInitialState,
      setTableLoadData,
      setTriggerGetTableData,
      queryParams,
      userPermissions,
    } = this.props;
    const { q } = queryParams;
    const { componentId, visible } = params;
    let userConfig = loadConfig();
    let sortedFieldsConfig: IGroupField[] = [];
    let globalSearchField = {} as GlobalSearch;

    if (!fieldsConfig || isEmpty(fieldsConfig)) {
      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['searchFormConfig']
      ) {
        sortedFieldsConfig =
          userConfig[config.USER.USERID][componentId]['searchFormConfig'];
        globalSearchField =
          userConfig[config.USER.USERID][componentId]['searchFormConfig'][
            'globalSearchField'
          ];
      } else {
        globalSearchField = makeGlobalSearchField(fields);
        sortedFieldsConfig = formConstructor(
          groups,
          fields,
          userPermissions!,
          params,
        );

        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) {
            userConfig = {};
          }

          if (!userConfig[config.USER.USERID]) {
            userConfig = { ...userConfig, [config.USER.USERID]: {} };
          }

          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                searchFormConfig: { sortedFieldsConfig, globalSearchField },
              },
            },
          };
          saveConfig(newConfig);
        }
      }

      let values = this.setInitialValues(sortedFieldsConfig) as {
        [key: string]: any;
      };
      if (!isEmpty(q)) {
        q!.split(config.QUERY.AND).forEach((element) => {
          const key = element.split(config.QUERY.ID_OPERATOR)[0];
          const value = element.split(config.QUERY.ID_OPERATOR)[1];
          fields.forEach((field) => {
            if (field.key === key && value)
              values = { ...values, [key]: value.toString() };
          });
          if (value) values = { ...values, [key]: value.toString() };
        });
      }
      setInitialState({
        componentId,
        targetId: appComponents[componentId].targetId,
        fieldsConfig: sortedFieldsConfig,
        globalSearchField,
        values,
        resetFormTrigger: false,
        formHasChanged: false,
      });

      if (!isEmpty(values) && visible !== false) {
        setTableLoadData({ componentId: appComponents[componentId].targetId });
        setTriggerGetTableData({ componentId, value: true });
      }
    }
  }

  componentDidMount() {
    const {
      getDataTrigger,
      queryParams,
      setTriggerGetTableData,
      values,
      params,
      setFormData,
      fields,
      isM2M,
      isModalDashboard,
      modalDashboardConfig,
    } = this.props;
    const { q } = queryParams;
    const { componentId } = params;
    let initialValues = { ...values };
    fields.forEach((field) => {
      if (field.hasOwnProperty('initialValue')) {
        if (
          !config.COMPONENT.FORM.RESET_ON_UNMOUNT &&
          values &&
          field.initialValue !== values[field.key]
        ) {
        } else initialValues[field.key] = field.initialValue;
      }
    });

    if (!isEmpty(q) && !isM2M) {
      q!.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(config.QUERY.ID_OPERATOR)[0];
        const value = element.split(config.QUERY.ID_OPERATOR)[1];
        fields.forEach((field) => {
          if (field.key === key && value)
            initialValues = { ...initialValues, [key]: value.toString() };
        });
      });
    }

    //TODO DELETE --->
    if (this.props.componentId === 'M2MProductsAccesoriesDashboard') {
      initialValues.fkVendor = {
        pkVendor: this.props.parentSelectedRow.fkVendor.pkVendor,
      };
    }
    initialValues = flatten(initialValues, { safe: true });
    if (isModalDashboard && 'initialParams' in modalDashboardConfig)
      initialValues = {
        ...initialValues,
        [modalDashboardConfig.initialParams.key]:
          modalDashboardConfig.initialParams.value,
      };
    const { isAdvancedSearch, advancedSearchConfig } = this.props;
    if (isAdvancedSearch && advancedSearchConfig.filter)
      for (let key in advancedSearchConfig.filter)
        initialValues = {
          ...initialValues,
          [key]: advancedSearchConfig.filter[key],
        };
    //END TODO DELETE

    setFormData({ componentId, values: initialValues });

    if (getDataTrigger) {
      this.getData(initialValues);
      setTriggerGetTableData({ componentId, value: false });
    }
  }

  async componentDidUpdate(prevProps: ISearchFormListProps) {
    const {
      queryParams,
      values,
      params,
      fieldsConfig,
      setFieldsConfig,
      hasNavigated,
      editNavigationState,
      getDataTrigger,
      setTriggerGetTableData,
      form,
      resetFormTrigger,
      setResetFormTrigger,
      fields,
      setFormData,
      isModalDashboard,
      modalDashboardConfig,
    } = this.props;

    const { componentId } = params;
    const { q } = queryParams;
    let control = false;
    let initialValues = { ...values };
    let query: { [key: string]: string } = {};

    if (
      q === undefined &&
      prevProps.queryParams.q !== undefined /*||
      (q === prevProps.queryParams.q && routerKey !== prevProps.routerKey)*/
    ) {
      let resetValues = {};
      fields.forEach((field) => {
        if (
          field.hasOwnProperty('initialValue') &&
          field.mustRender !== false
        ) {
          resetValues = { ...resetValues, [field.key]: field.initialValue };
        }
      });

      setFormData({ componentId, values: resetValues });
      setTriggerGetTableData({ componentId, value: true });
      form.resetFields();
    } else {
      if (!isEmpty(q)) {
        q!.split(config.QUERY.AND).forEach((element) => {
          const key = element.split(config.QUERY.ID_OPERATOR)[0];
          const value = element.split(config.QUERY.ID_OPERATOR)[1];
          fields.forEach((field) => {
            if (field.key === key && value)
              initialValues = { ...initialValues, [key]: value.toString() };
          });
          if (value) {
            query = { ...query, [key]: value };
          }
        });
      }

      if (getDataTrigger) {
        const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
          if (
            field.hasOwnProperty('initialValue') &&
            field.initialValue !== values[field.key]
          ) {
            initialValues[field.key] = field.initialValue;
          }
          return field;
        };

        this.changeFieldsConfig(fieldsConfig, fieldsConfigBehaviourSelector);
        this.getData(initialValues);
        setTriggerGetTableData({ componentId, value: false });
      } else if (hasNavigated) {
        fields.forEach((field) => {
          if (
            field.hasOwnProperty('initialValue') &&
            initialValues[field.key] !== undefined
          ) {
            initialValues = {
              ...initialValues,
              [field.key]: field.initialValue,
            };
          }
        });
        if (isModalDashboard && 'initialParams' in modalDashboardConfig)
          initialValues = {
            ...initialValues,
            [modalDashboardConfig.initialParams.key]:
              modalDashboardConfig.initialParams.value,
          };

        if (!isEmpty(query)) {
          const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
            for (let key in query) {
              if (field.key === key && !field.disabled) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector,
          );

          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }

        setFormData({ componentId, values: initialValues });
        this.getData(initialValues);
        setTriggerGetTableData({ componentId, value: false });
        editNavigationState({ component: 'searchFormNavigation' });
      } else if (!isEmpty(query)) {
        const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
          for (let key in query) {
            if (
              field.key === key &&
              !field.disabled &&
              !field.hasOwnProperty('parentValue')
            ) {
              field.disabled = true;
              control = true;
            }
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector,
        );
        if (control) setFieldsConfig(componentId, newFieldsConfig);
      } else if (isEmpty(q)) {
        const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
          if (
            field.hasOwnProperty('disabled') &&
            field.type === 'combo' &&
            field.disabled !== field.initialDisabled &&
            !field.hasOwnProperty('parentValue')
          ) {
            field.disabled = field.initialDisabled;
            control = true;
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector,
        );

        if (control) setFieldsConfig(componentId, newFieldsConfig);
      }
      if (resetFormTrigger) {
        await setResetFormTrigger({ componentId });
        form.resetFields();
      }
    }
  }

  getData = (values: IRow) => {
    const {
      params,
      fields,
      filters,
      getTableData,
      targetId,
      isM2M,
      getM2MTableData,
      primaryKey,
      path,
      dataPath,
      mainKeyValue,
      joinKey,
      foreignKey,
      getSelected,
      navigationId,
      selectedRow,
      breadcrumbs,
      isModalDashboard,
      modalDashboardConfig,
      globalSearchField,
    } = this.props;

    const { disableFindPageByIdRequests } = params;

    let submitValues = { ...values };
    for (let key in submitValues) {
      if (
        submitValues[key] === 'null' ||
        submitValues[key] === undefined ||
        parseInt(submitValues[key]) < 0
        //submitValues[key] === '-999999'
      ) {
        delete submitValues[key];
      }
    }
    const composition = qComposer(fields, submitValues, globalSearchField);

    let q = composition
      ? (filters ? filters + config.QUERY.AND : '') + composition
      : filters;

    if (isModalDashboard && 'initialParams' in modalDashboardConfig) {
      if (q !== '') q += config.QUERY.AND;
      q += `${modalDashboardConfig.initialParams.key}${config.QUERY.ID_OPERATOR}${modalDashboardConfig.initialParams.value}`;
    }

    if (isM2M) {
      getM2MTableData({
        m2mDataPath: this.getM2MDataPath(path!),
        dataPath: this.getM2MDataPath(dataPath!),
        queryParams: { q },
        primaryKey: primaryKey!,
        mainKeyValue,
        joinKey: joinKey!,
        foreignKey,
        getSelected,
        navigationId,
      });
    } else {
      const dataPath = isModalDashboard
        ? `${appComponents[targetId].path}`
        : (!selectedRow || isEmpty(selectedRow)) &&
          !isEmpty(
            breadcrumbs[breadcrumbs.length - 1].child &&
              !disableFindPageByIdRequests,
          )
        ? `${appComponents[targetId].path}/Page/${
            breadcrumbs[breadcrumbs.length - 1].child.value
          }`
        : `${appComponents[targetId].path}`;
      getTableData({
        dataPath,
        componentId: targetId,
        queryParams: { q },
      });
    }
  };

  setInitialValues = (fieldsConfig: IGroupField[]): IRecord => {
    let values: IRecord = {};

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      if (field.initialValue !== undefined) {
        values = {
          ...values,
          [field.key]: field.initialValue,
        };
      }
      return field;
    };
    this.changeFieldsConfig(fieldsConfig, fieldsConfigBehaviourSelector);

    return values;
  };

  getM2MDataPath = (path: string): string => {
    const { pathExtended, pathExtendedData } = this.props;

    const _key = pathExtendedData?.key ?? '';
    const _value = pathExtendedData?.value ?? '';

    const paths = {
      default: path!,
      extended: pathExtended?.replace(`{{${_key}}}`, _value.toString())!,
    };

    return pathExtendedData ? paths.extended : paths.default;
  };

  /**
   * This function makes a call to the API in order to get requested data.
   * First of all, the function validates the fields and then it composes a query which will be used in the api call
   */
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      targetId,
      values,
      form,
      getTableData,
      filters,
      fields,
      params,
      isM2M,
      getM2MTableData,
      primaryKey,
      mainKeyValue,
      path,
      dataPath,
      joinKey,
      foreignKey,
      getSelected,
      navigationId,
      globalSearchField,
    } = this.props;
    form.validateFields(async (err: Error, formValues: IRow) => {
      let submitValues = { ...values, ...formValues };
      for (let key in submitValues) {
        if (submitValues[key] === null || submitValues[key] === undefined)
          delete submitValues[key];
        else if (typeof submitValues[key] === 'object') {
          for (let k in submitValues[key]) {
            if (
              submitValues[key][k] === null ||
              submitValues[key][k] === undefined
            )
              delete submitValues[key][k];
          }
          if (isEmpty(submitValues[key])) delete submitValues[key];
        }
      }
      if (!err && isTableTarget(targetId)) {
        fields.forEach((f) => {
          if (f.type === 'time' && submitValues[f.key]) {
            let newTime = submitValues[f.key].split(':');
            newTime = new Date(
              Date.UTC(1970, 1, 1, newTime[0], newTime[1], newTime[2]),
            );
            const hours = newTime.getHours().toString();
            const minutes = newTime.getMinutes().toString();
            const seconds = newTime.getSeconds().toString();
            submitValues[f.key] = hours.concat(':', minutes, ':', seconds);
          } else if (f.type === 'rangerpicker' && submitValues[f.key]) {
            const firstDate = submitValues[f.key][0];
            const secondDate = submitValues[f.key][1];
            submitValues[f.key] = `${firstDate.year()}-${
              firstDate.month() + 1
            }-${firstDate.date()}.${secondDate.year()}-${
              secondDate.month() + 1
            }-${secondDate.date()}`;
          } else if (f.type === 'date' && submitValues[f.key]) {
            if (moment.isMoment(submitValues[f.key])) {
              submitValues[f.key].set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
              if (f.operand === 'greater')
                submitValues[f.key].set({
                  day: submitValues[f.key].day() + 1,
                });
              if (f.operand === 'less')
                submitValues[f.key].set({
                  day: submitValues[f.key].day() - 1,
                });
              submitValues[f.key] = submitValues[f.key].toISOString();
            }
          }
        });

        for (let key in submitValues) {
          if (
            submitValues[key] === null ||
            submitValues[key] === undefined ||
            parseInt(submitValues[key]) < 0
          ) {
            delete submitValues[key];
          }
        }

        const composition = qComposer(fields, submitValues, globalSearchField);
        let q = composition
          ? (filters ? filters + config.QUERY.AND : '') + composition
          : filters;

        if (params.navigationParams && !isM2M) {
          q +=
            config.QUERY.AND +
            params.navigationParams +
            config.QUERY.ID_OPERATOR +
            params.navigationParams;
        }

        if (this.props.isModalDashboard && this.props.modalDashboardConfig) {
          if (q !== '') q += config.QUERY.AND;
          q +=
            this.props.modalDashboardConfig.initialParams.key +
            config.QUERY.ID_OPERATOR +
            this.props.modalDashboardConfig.initialParams.value;
        }

        if (this.props.filterVisible)
          this.props.setFilterVisibility({
            componentId: this.props.params.componentId,
            filterVisible: false,
          });

        isM2M
          ? await getM2MTableData({
              m2mDataPath: this.getM2MDataPath(path!),
              dataPath: this.getM2MDataPath(dataPath!),
              queryParams: { q },
              primaryKey: primaryKey!,
              mainKeyValue,
              joinKey: joinKey!,
              foreignKey,
              getSelected,
              navigationId,
            })
          : await getTableData({
              dataPath: appComponents[targetId].path,
              componentId: targetId,
              queryParams: { q },
            });
      }
    });
  };

  /**
   * This function resets the Search form values
   * If the form has boolean inputs, also resets its states in Redux.
   */
  handleReset = async () => {
    const {
      params,
      values,
      resetSearchForm,
      fields,
      queryParams,
      fieldsConfig,
      formHasChanged,
      setFormHasChanged,
      isModalDashboard,
      modalDashboardConfig,
      getTableData,
      filters,
      path,
      dataPath,
      isM2M,
      getM2MTableData,
      primaryKey,
      mainKeyValue,
      joinKey,
      foreignKey,
      getSelected,
      navigationId,
      globalSearchField,
      targetId,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let boolVals: IRow = {};
    for (let key in values) {
      if (typeof values[key] === 'boolean') boolVals[key] = values[key];
      //if (typeof values[key] === 'radio') radioValue[key] = values[key];
    }
    fields.forEach((field) => {
      if (field.hasOwnProperty('initialValue')) {
        boolVals[field.key] = field.initialValue;
      }
    });

    if (!isEmpty(q)) {
      q!.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(config.QUERY.ID_OPERATOR)[0];
        const value = element.split(config.QUERY.ID_OPERATOR)[1];
        fields.forEach((field) => {
          if (field.key === key && value)
            boolVals = { ...boolVals, [key]: value.toString() };
        });
      });
    }

    if (isModalDashboard && modalDashboardConfig.initialParams)
      boolVals = {
        ...boolVals,
        [modalDashboardConfig.initialParams.key]:
          modalDashboardConfig.initialParams.value,
      };

    setFormData({ componentId, values: { ...boolVals } });

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      let control = true;
      if (field.hasOwnProperty('parentValue') && field.parentValue !== '') {
        field.parentValue = '';
      }
      if (!field.hasOwnProperty('initialValue')) {
        if (field.behaviours !== undefined) {
          field.behaviours.forEach((behaviourGroup) => {
            behaviourGroup.forEach((behaviour) => {
              fields.forEach((f) => {
                if (
                  f.key === behaviour.key &&
                  !f.hasOwnProperty('initialValue')
                ) {
                  control = false;
                }
              });
            });
          });
        }
        if (!control) {
          if (field.hasOwnProperty('initialVisibility')) {
            field.visible = field.initialVisibility;
          }
          if (field.hasOwnProperty('initialDisabled')) {
            field.disabled = field.initialDisabled;
          }
          if (field.hasOwnProperty('initialMandatory')) {
            field.mandatory = field.initialMandatory;
          }
        }
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );

    await resetSearchForm({
      componentId,
      fieldsConfig: newFieldsConfig,
      values: { ...boolVals },
    });

    if (formHasChanged) {
      setFormHasChanged({
        componentId,
        formHasChanged: false,
      });

      const composition = qComposer(fields, boolVals, globalSearchField);
      let q = composition
        ? (filters ? filters + config.QUERY.AND : '') + composition
        : filters;

      if (this.props.isModalDashboard && this.props.modalDashboardConfig) {
        if (q !== '') q += config.QUERY.AND;
        q +=
          this.props.modalDashboardConfig.initialParams.key +
          config.QUERY.ID_OPERATOR +
          this.props.modalDashboardConfig.initialParams.value;
      }

      isM2M
        ? await getM2MTableData({
            m2mDataPath: this.getM2MDataPath(path!),
            dataPath: this.getM2MDataPath(dataPath),
            queryParams: { q },
            primaryKey: primaryKey!,
            mainKeyValue,
            joinKey: joinKey!,
            foreignKey,
            getSelected,
            navigationId,
          })
        : await getTableData({
            dataPath: appComponents[targetId].path,
            componentId: targetId,
            queryParams: { q },
          });
    }
  };

  handleChangeTab = (activeKey: string) => {
    const { setSelectedTabSearch, params } = this.props;
    const { componentId } = params;
    setSelectedTabSearch({
      componentId,
      selectedTab: activeKey,
    });
  };

  /**
   * Updates the value of a field
   * @param {string} type - Field type
   * @param {int} id - Field identifier
   * @param {*} value - New field value
   * @param {string} format - Date format for types: date, datetime, time
   */
  handleChangeField = async ({
    type,
    id,
    value,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS+0000',
  }: {
    type: string;
    id: string;
    value: any;
    format?: string;
  }) => {
    const { params, values, form, setFormData, setFormHasChanged } = this.props;
    const { componentId } = params;

    if (value === null || isEmpty(value)) delete values[id];
    else
    switch (type) {
      case 'date':
      case 'datetime':
      case 'time': {
        values[id] =
          format === 'timestamp' ? value.unix() : value.format(format);
        break;
      }
      case 'rangerpicker': {
        const newVal: string[] = [];
        value.forEach((v: string) => {
          newVal.push(v);
        });
        values[id] = newVal.join('.');
        break;
      }
      default: {
        values[id] = value;
      }
    }

    setFormHasChanged({
      componentId,
      formHasChanged: true,
    });

    form.setFieldsValue({
      ...values,
      [id]: values[id]
    });
    setFormData({ componentId, values });
  };

  handleAddData = () => {
    const {
      setSelectedRow,
      setDrawerVisibility,
      setSelectedTab,
      dashboardId,
      params,
      setFilterVisibility,
      cleanBreadcrumb,
    } = this.props;
    const { componentId } = params;

    setSelectedTab({
      dashboardId,
      componentId: '',
      tabIndex: '0',
    });
    setSelectedRow({
      componentId: appComponents[componentId].targetId,
      selectedRow: {},
    });

    cleanBreadcrumb();
    setFilterVisibility({
      componentId,
      filterVisible: false,
    });
    setDrawerVisibility({ dashboardId, visible: true });
  };

  handleChangeFilterVisibility = () => {
    const { params, setFilterVisibility, filterVisible } = this.props;
    const { componentId } = params;
    setFilterVisibility({
      componentId,
      filterVisible: !filterVisible,
    });
  };

  /**
   * Updates the options of a child combo depending on parent selected item.
   * @param comboId {Object} - object with the information of the child.
   * @param id {int} - the identifier of the selected item in parent combo.
   */
  updateChildCombo = (comboId: string, id: string | number, key: string) => {
    const { getComboData, params } = this.props;
    const { componentId } = params;
    let queryParams = { id: comboId } as IRow;
    if (id) queryParams = { ...queryParams, param: id };
    getComboData({
      dataPath: apiPaths.COMBO,
      comboId,
      fieldKey: key,
      componentId,
      queryParams,
    });
  };

  /**
   * This function is used to set new parents value on a combo child.
   * @param {String} key Field key from the combo child.
   * @param {String} value New combo parent value
   */
  setParentValue = (key: string, value: any) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      if (field.key === key) {
        field.parentValue = value;
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );
    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleChangeFieldOperator = (f: ICompleteField, operand: string) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;
    let newFieldsfields = fieldsConfig.slice();

    newFieldsfields.forEach((field) => {
      field.subgroupRows.forEach((subgroupRow) => {
        subgroupRow.forEach((subgroup) => {
          subgroup.fieldRow.forEach((fieldRow) => {
            fieldRow.forEach((field) => {
              if (f.key === field.key) field.operand = operand;
            });
          });
        });
      });
    });
    setFieldsConfig(componentId, newFieldsfields);
  };

  handleChildBehaviours = (
    targetKey: string,
    behavioursDisplayed: any, //TODO con behaviours...
    forceVisible?: boolean,
    forceDisabled?: boolean,
  ) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      if (field.key === targetKey) {
        if (forceVisible !== null && forceVisible !== undefined)
          field.visible = forceVisible;
        else if (behavioursDisplayed.hasOwnProperty('visible'))
          field.visible = behavioursDisplayed['visible'];

        if (field.type === 'combo' && field.hasOwnProperty('parentValue')) {
          if (behavioursDisplayed.hasOwnProperty('disabled')) {
            field.disabled = behavioursDisplayed['disabled'];
          }
        } else {
          if (forceDisabled !== null && forceDisabled !== undefined)
            field.disabled = forceDisabled;
          else if (behavioursDisplayed.hasOwnProperty('disabled'))
            field.disabled = behavioursDisplayed['disabled'];
        }

        if (behavioursDisplayed.hasOwnProperty('mandatory'))
          field.mandatory = behavioursDisplayed['mandatory'];
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );

    setFieldsConfig(componentId, newFieldsConfig);
  };

  /**
   * This function will look into each field, and modify its params or not depending on the condition we are introducing
   * @param {Array} fieldsConfig - Array of groups, subgroups and fields which would be renderer on the Component
   * @param {function} fieldsConfigBehaviourSelector - Condition which will change or not current field
   * @return {Array} - New fieldsConfig
   */
  changeFieldsConfig = (
    fieldsConfig: IGroupField[],
    fieldsConfigBehaviourSelector: (field: ICompleteField) => ICompleteField,
  ): IGroupField[] => {
    fieldsConfig?.forEach((field) => {
      field.subgroupRows.forEach((subgroupRow) => {
        subgroupRow.forEach((subgroup) => {
          subgroup.fieldRow.forEach((fieldRow) => {
            fieldRow.forEach((field) => {
              if (fieldsConfigBehaviourSelector(field))
                field = fieldsConfigBehaviourSelector(field);
            });
          });
        });
      });
    });
    return fieldsConfig;
  };

  render() {
    const { globalSearchField } = this.props;
    if (globalSearchField) {
      return (
        <SearchFormRender {...this} globalSearchField={globalSearchField} />
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setTableLoadData,
      setFieldsConfig,
      setInitialState,
      setFormData,
      getTableData,
      setSelectedRow,
      getComboData,
      feedback: feedbackController,
      logout,
      editNavigationState,
      setTriggerGetTableData,
      resetSearchForm,
      setFormHasChanged,
      setDrawerVisibility,
      setResetFormTrigger,
      setFilterVisibility,
      setSelectedTabSearch,
      setSelectedTab,
      cleanBreadcrumb,
    },
    dispatch,
  );

const mapStateToProps = (state: ReducersState, ownProps: OwnProps) => {
  return {
    filterVisible: state.searches[ownProps.params.componentId].filterVisible,
    targetId: state.searches[ownProps.params.componentId].targetId,
    getDataTrigger: state.searches[ownProps.params.componentId].getDataTrigger,
    resetFormTrigger:
      state.searches[ownProps.params.componentId].resetFormTrigger,
    hasNavigated: state.query.searchFormNavigation,
    queryParams: state.query.params,
    getSelected: state.m2m.getSelected,
    formHasChanged: state.searches[ownProps.params.componentId].formHasChanged,
    routerKey: state.router.location.key,
    fieldsConfig: state.searches[ownProps.params.componentId].fieldsConfig,
    globalSearchField:
      state.searches[ownProps.params.componentId].globalSearchField,
    values: state.searches[ownProps.params.componentId].values,
    m2mIsLoading: state.m2m.isLoading,
    m2mQueryParams: state.m2m.queryParams,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    filters:
      state.tables[appComponents[ownProps.params.componentId].targetId].filters,
    combos: state.combos,
    isLoading:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .isLoading,
    // errors: state.app.errors,
    accessToken: state.auth.accessToken,
    userPermissions: state.app.permissions,
    breadcrumbs: state.query.breadcrumbs,
    pathExtendedData: state.m2m.pathExtended!,
  };
};

const InjectedSearchForm = injectIntl(SearchForm);
const WrappedSearchForm = Form.create()(InjectedSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSearchForm);
