import config from '../config';
import { getPlatformBaseUrl } from './platformUtils';

function isUSA() {
  return config.APP.PLATFORM_CODE === 'pin-us';
}

function getAppLocaleCode() {
  let apiUrl = getPlatformBaseUrl();
  if (apiUrl[apiUrl.length - 1] === '/') {
    apiUrl = apiUrl.substring(0, apiUrl.length - 1);
  }
  const split = apiUrl.split('/');
  return config.APP.ENVIRONMENT === 'local' ? '' : '/'+split[split.length - 1];
}

export function getFrontPinUrl() {
  let url = `${config.APP.FRONT_PIN_URL}${getAppLocaleCode()}`;
  if (isUSA()) url = url.replace('/med', '');
  return url;
}