export const combineDateAndTime = (date: string, time: string) => {
  if (!date || !time) return '';

  const dateTime = new Date(`${date}, ${time}`);

  return dateTime;
};

export const formatDate = (date: string | Date) => {
  if (!date) return '';

  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();
  date = `${year}-${month}-${day}`;

  return date;
};

export const localeHour = (dateString: string) => {
  if (!dateString) return '';
  return new Date(dateString).toLocaleTimeString();
};
