import React from 'react';
export default function app() {
  return (
    <>
      <svg viewBox="0 0 64 64">
        <path d="M2 20v36h60V20m-44 0v36m0-20h44"></path>
        <path d="M2 8h60v12H2z"></path>
        <circle data-name="layer1" cx="8" cy="14" r="1"></circle>
        <circle data-name="layer1" cx="15" cy="14" r="1"></circle>
        <circle data-name="layer1" cx="22" cy="14" r="1"></circle>
      </svg>
    </>
  );
}
