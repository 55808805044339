import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const PharmacyDashboard = (props: any) => {
  const { MID, SMALL } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'pharmacySearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idPharmacy',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'zipcode',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'accountName',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'id Pharmacy',
          key: 'idPharmacy',
          type: 'number',
          operand: 'like',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Account Name',
          key: 'accountName',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          globalSearch: true,
        },
        {
          title: 'ZIP code',
          key: 'zipcode',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          globalSearch: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: false,
        canCreate: false,
        canDelete: false,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'pharmacyList',
        rowKey: 'idPharmacy',
        panelHeader: 'Pharmacy List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'accountName',
      },
      fields: [
        {
          title: 'Name',
          key: 'accountName',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },

        {
          title: 'Street',
          key: 'street',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Street number',
          key: 'streetNumber',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'City',
          key: 'city',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'ZIP code',
          key: 'zipcode',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Region Name',
          key: 'regionName',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Latitude',
          key: 'coordinatesLatitude',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Longitude',
          key: 'coordinatesLongitude',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Landline Phone',
          key: 'landlinePhone',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Mobile Phone',
          key: 'mobilePhone',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'CIF',
          key: 'pharmCif',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: false,
        multiple: false,
        canCreate: false,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'pharmacyEdit',
        panelHeader: 'pharmacy Edit',
        primaryKey: 'idPharmacy',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'accountName',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'street',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'streetNumber',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'city',
                  yPosition: 0,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'zipcode',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'regionName',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'coordinatesLatitude',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'coordinatesLongitude',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'landlinePhone',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'mobilePhone',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Acount Name',
          key: 'accountName',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Street',
          key: 'street',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Street number',
          key: 'streetNumber',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'City',
          key: 'city',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'ZIP code',
          key: 'zipcode',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Region Name',
          key: 'regionName',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Coordinates Latitude',
          key: 'coordinatesLatitude',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Coordinates Longitude',
          key: 'coordinatesLongitude',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Landline Phone',
          key: 'landlinePhone',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
        {
          title: 'Mobile Phone',
          key: 'mobilePhone',
          type: 'text',
          validationType: 'text',
          disabled: true,
          mandatory: false,
          length: 50,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: false,
        canCreate: false,
        canDelete: false,
      },
    },
  ];

  const componentId = 'PharmacyDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default PharmacyDashboard;
