import { IRecord, IRow } from '../app/AppInterfaces';
import { Button, Dropdown, Menu } from 'antd';
import React, { Fragment, JSX, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { feedback } from '../utils';
import XLSX from 'xlsx';
import config from '../config';
import * as api from '../api';
import { dataType } from '../export/DataExport';
import { ColumnProps } from 'antd/lib/table';

export const ExportInformation = (
  dataSource: IRecord[],
  path: string,
  columns: ColumnProps<IRecord>[],
  searchFiled: string,
  filter: string,
  entityKey: string,
  query: string,
  realSearchField: string,
  afterResponse?: (response: any) => any
): JSX.Element => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const handleExportOnClick = async ({ key }: { key: string }) => {
    setExportLoading(true);
    let response = await getAllDataPIN();
    console.log('response', response);
    if (afterResponse) response = afterResponse(response);
    setExportLoading(false);

    if (response.status === 200 && response.data) {
      const data = response.data[entityKey];

      switch (key) {
        case 'AC':
          processData(data, 'csv');
          break;
        case 'AX':
          console.log(data, columns);
          // @ts-ignore
          let [xlsxData, headers] = dataToXLS(data, columns);
          console.log([xlsxData, headers]);

          let wsheet = [];
          const wbook = XLSX.utils.book_new();

          // @ts-ignore
          wsheet[0] = XLSX.utils.json_to_sheet(xlsxData, {
            header: Object.values(headers),
          });
          XLSX.utils.book_append_sheet(wbook, wsheet[0], 'valueSheet');

          XLSX.writeFile(wbook, `data.xlsx`, {
            bookType: 'xlsx',
            type: 'binary',
          });
          break;
        default:
          break;
      }
    } else {
      feedback({
        type: 'notification',
        method: 'info',
        message: 'No se ha podido realizar la acción',
      });
    }
  };

  const getAllDataPIN = async () => {

    const params = `?q=${filter}${realSearchField}:${encodeURIComponent(
      query,
    )}&sort=ASC&field=${searchFiled}&size=99999&platformStatus=true&offset`;
    return api.getDataCall({
      dataPath: `${path}/${params}`,
      callConfig: {},
    });
  };

  const processData = (dataSource: IRecord[], format: string) => {
    let text: string | null = null;
    let type, blob;
    let fileName = `data.${format}`;

    if (format === dataType.CSV.format) {
      text = dataToCSV(dataSource);
      type = dataType.CSV.type;
      if (text !== null) blob = new Blob([text], { type: type });
    }

    if (isEmpty(text)) return;

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const dataToXLS = (data: IRecord[]): [IRow[], any] | null => {
    if (isEmpty(data)) {
      return null;
    }
    let result: IRow[] = [];
    let headers: IRow = {};

    // HEADER
    columns.forEach((column) => {
      const columnIndex = column.dataIndex ?? '';
      headers[columnIndex] = column.title;
    });

    // BODY

    data.forEach((item) => {
      let newValue: IRow = {};
      columns.forEach((column) => {
        const dataIndex = column.dataIndex ?? '';
        const _columnTitle = headers[dataIndex];

        if (column && item) {
          newValue[_columnTitle] = isArray(item[dataIndex])
            ? JSON.stringify(item[dataIndex])
            : item[dataIndex];
        }
      });
      result.push(newValue);
    });

    return [result, headers];
  };

  const dataToCSV = (data: IRecord[]) => {
    if (isEmpty(data)) {
      return null;
    }

    let result = '';

    columns.forEach((column) => {
      if (column !== columns[columns.length - 1])
        result += column.title + config.EXPORT.COLUMN_DELIMITER;
      else result += column.title + config.EXPORT.LINE_DELIMITER;
    });

    let ctrl: boolean;
    data.forEach((item) => {
      ctrl = false;
      columns.forEach((column) => {
        const dataIndex = column.dataIndex ?? '';

        if (ctrl) result += config.EXPORT.COLUMN_DELIMITER;
        let newValue = '';
        if (column && item) {
          newValue = item[dataIndex];
        }
        result += newValue;
        ctrl = true;
      });
      result += config.EXPORT.LINE_DELIMITER;
    });
    return result;
  };

  const menu = (
    <Menu onClick={handleExportOnClick}>
      <Menu.Item key="AC">.csv</Menu.Item>
      <Menu.Item key="AX">.xls</Menu.Item>
    </Menu>
  );

  return (
    <Fragment key="user-info_button">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={exportLoading || isEmpty(dataSource)}>
        <Button
          className="single-btn"
          style={{ float: 'right', marginRight: 10 }}
          icon="export">
          Export
        </Button>
      </Dropdown>
    </Fragment>
  );
};
