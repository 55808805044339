import React, { FC } from 'react';
import { Form, Select, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  addEmailToArray,
  checkEmailValue,
} from '../../challenge/AdminVisibility/AdminVisibilityForm';
import { IResource } from '../../challenge/ChallengeInterfaces';
import { useIntl } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';

interface Props extends FormComponentProps {
  newUsers: string[];
  selectedTalk: string | null;
  setNewUsers: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedTalk: React.Dispatch<React.SetStateAction<string | null>>;
  setUsersHaveChanged: React.Dispatch<React.SetStateAction<boolean>>;
  talks: IResource[];
}

const BookUsersForm: FC<Props> = ({
  form,
  newUsers,
  selectedTalk,
  setNewUsers,
  setSelectedTalk,
  setUsersHaveChanged,
  talks,
}) => {
  const intl = useIntl();
  const isMultiEvent = () => talks.length >= 2;

  const handleChange = (values: string[]) => {
    let selectUsers: string[] = [];
    values.forEach((_value) => {
      if (_value.indexOf(',') >= 0) {
        const newUsers = _value.split(',');
        newUsers.forEach((_user) => {
          selectUsers = [...addEmailToArray(selectUsers, _user)];
        });
      } else if (
        checkEmailValue(_value) &&
        !selectUsers.includes(checkEmailValue(_value)!)
      ) {
        selectUsers.push(checkEmailValue(_value)!);
      }
    });

    setNewUsers(selectUsers);

    form.setFieldsValue({ note: [...selectUsers] });
    form.resetFields();

    setUsersHaveChanged(true);
  };

  const addNewValues = (value: string, options: any) => {
    let selectUsers: string[] = newUsers.slice();

    if (value) {
      if (value.indexOf(',') >= 0) {
        const newUsers = value.split(',');
        newUsers.forEach((_user) => {
          if (
            checkEmailValue(_user) &&
            !selectUsers.includes(checkEmailValue(_user)!)
          )
            selectUsers.push(checkEmailValue(_user)!);
        });
      } else if (
        checkEmailValue(value) &&
        !selectUsers.includes(value.trim())
      ) {
        selectUsers.push(checkEmailValue(value)!);
      }

      setNewUsers(selectUsers);

      form.setFieldsValue({ note: [...selectUsers] });

      setUsersHaveChanged(true);
    }
  };

  const handleSelectTalk = (value: string, options: any) => {
    setUsersHaveChanged(true);
    setSelectedTalk(value);
  };

  const renderTalks =
    talks &&
    talks.map((_talk) => (
      <Select.Option key={_talk.idResource.toString()}>
        {_talk.name}
      </Select.Option>
    ));

  const renderOptions =
    newUsers &&
    newUsers.map((_user) => <Select.Option key={_user}>{_user}</Select.Option>);

  return (
    <Form className="assignUsersForm">
      {isMultiEvent() && (
        <Row style={{ marginBottom: '20px' }}>
          <Row style={{ marginBottom: '10px' }}>
            {intl.formatMessage({ id: 'bookUsers.talks' })}
          </Row>
          {form.getFieldDecorator('event', {
            initialValue: selectedTalk,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              style={{ width: '100%', height: '50px' }}
              onSelect={(value: string | number | LabeledValue, options: any) =>
                typeof value === 'string'
                  ? handleSelectTalk(value, options)
                  : console.error('Not expected')
              }>
              {renderTalks}
            </Select>
          )}
        </Row>
      )}
      {form.getFieldDecorator('note', { initialValue: newUsers })(
        <Row style={{ marginBottom: '20px' }}>
          <Row style={{ marginBottom: '10px' }}>
            {intl.formatMessage({ id: 'bookUsers.usersList' })}
          </Row>
          <Select
            mode="tags"
            style={{ width: '100%', height: '50px' }}
            onChange={handleChange}
            onSelect={(value: string, options: any) =>
              addNewValues(value, options)
            }>
            {renderOptions}
          </Select>
        </Row>
      )}
    </Form>
  );
};

export default Form.create<Props>({ name: 'book_users_form' })(BookUsersForm);
