import { Button, Checkbox, Input, List, Modal, notification, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IResource } from '../../challenge/ChallengeInterfaces';
import { ChallengeTypeEnum } from '../../challenge/Enums';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import './ChallengeUsers.css';
interface IUser {
  idUser: number;
  email: string;
}

const INITIAL_LIMIT = 12;
const LIMIT_INCREMENT = 5;

export default function ChallengeUsersList<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const intl = useIntl();
  const [users, setUsers] = useState<IUser[]>([]);
  const [visibleModalUsers, setVisibleModalUsers] = useState(false);
  const [visibleModalResources, setVisibleModalResources] = useState(false);

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<null | string>(null);
  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectedResources, setSelectedResources] = useState<number[]>([]);
  const [resourcesDisplay, setResourcesDisplay] = useState<IResource[]>([]);

  if (!isTableProps(parentProps)) return <Fragment />;

  const { params, selectedRow } = parentProps.props;
  const { rowKey } = params;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetchUsers = useCallback(async () => {
    setLoading(true);

    await api
      .getDataCall({
        dataPath: `${apiPaths.CALL.CHALLENGE_USERS_PROGRESS}/${selectedRow[rowKey]}`,
        callConfig: {},
      })
      .then((res) => {
        if (res.data) setUsers(res.data);
      })
      .catch((err) => {
        notification.error({ message: 'Could not load challenge users' });
        closeComponent();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedRow, rowKey]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isEmpty(selectedRow) && visibleModalUsers) {
      (async () => {
        await fetchUsers();
        setResourcesDisplay(
          selectedRow?.resourceList?.filter(
            (resource: IResource) =>
              resource.status === true && resource.indResource === null,
          ),
        );
      })();
    }
  }, [selectedRow, visibleModalUsers, fetchUsers, visibleModalResources]);

  /**
   * Reset users resources
   */
  const handleResetUserResource = async () => {
    setLoading(true);

    const usersSelected: number[] = selectedUsers ?? [];
    const resourcesSelected: number[] = selectedResources ?? [];

    if (!usersSelected.length) users.map(({ idUser }) => usersSelected.push(idUser));

    if (!resourcesSelected.length) {
      resourcesDisplay.map(({ idResource }) => 
        resourcesSelected.push(idResource),
      );
    } 

    const usersId: string = usersSelected.join(',');
    const resourcesId: string = resourcesSelected.join(',');
    const challengeId = selectedRow[rowKey];

    await api
      .deleteCall({
        dataPath: `${apiPaths.CALL.CHALLENGE_USERS_PROGRESS}/${challengeId}?idUsers=${usersId}&idResources=${resourcesId}`,
        callConfig: {},
      })
      .then(async (res) => {
        if (res.status === 200) {
          closeComponent();
        } else await fetchUsers();
      })
      .catch(() =>
        notification.error({ message: 'Could not reset user progress' }),
      )
      .finally(() => setLoading(false));
  };

  const closeComponent = () => {
    setSelectedUsers([]);
    setSelectedResources([]);
    setVisibleModalUsers(false);
    setVisibleModalResources(false);
    setUsers([]);
    setResourcesDisplay([]);
    setLimit(INITIAL_LIMIT);
    setFilter(null);
  };

  const closeModalResources = () => {
    setSelectedResources([]);
    setVisibleModalResources(false);
    setResourcesDisplay([]);
    setLimit(INITIAL_LIMIT);
    setFilter(null);
  };

  const handleShowMore = () => {
    setLimit(limit + LIMIT_INCREMENT);
  };

  const filteredData = filter
    ? users.filter((user) => user.email.includes(filter))
    : [...users];

  // const resourcesDisplay: IResource[] = selectedRow?.resourceList?.filter(
  //   (resource: IResource) =>
  //     resource.status === true && resource.indResource === null,
  // );

  let dataSource: IUser[] =
    filteredData.length > limit ? filteredData.slice(0, limit) : filteredData;

  const handleEditActivate = (idUser: number) => {
    let idUsersSelected = selectedUsers.filter((id) => id !== idUser);
    if (idUsersSelected.length === selectedUsers.length)
      setSelectedUsers([...selectedUsers, idUser]);
    else setSelectedUsers(idUsersSelected);
  };

  const handleEditActivateResource = (idResource: number) => {
    let idResourceSelected = selectedResources.filter(
      (id) => id !== idResource,
    );
    if (idResourceSelected.length === selectedResources.length)
      setSelectedResources([...selectedResources, idResource]);
    else setSelectedResources(idResourceSelected);
  };

  const disabledResetUserButton = () => {
    return isEmpty(selectedRow)
    || [ChallengeTypeEnum.CERTIFIED_COURSE].includes(
      selectedRow.idChallengeType.idChallengeType,
    )
  }

  return (
    <>
      <Button
        type="primary"
        disabled={disabledResetUserButton()}
        onClick={() => setVisibleModalUsers(true)}>
        {intl.formatMessage({ id: 'button-title.reset-users' })}
      </Button>
      <Modal
        visible={visibleModalUsers}
        onCancel={() => closeComponent()}
        footer={null}>
        <Row className="challenge-users header">
          <div className="challenge-users title">
            {intl.formatMessage({ id: 'title.search-users' })}
          </div>
          <Input
            onChange={(e) => setFilter(e.target.value)}
            allowClear
            placeholder="User email"
          />
        </Row>
        <Row className="challenge-users container-buttons">
          <Button
            disabled={
              selectedUsers.length === 0 ||
              users.length === selectedUsers.length
            }
            type="primary"
            onClick={() => setVisibleModalResources(true)}>
            {intl.formatMessage({ id: 'button-title.reset-users' })}
          </Button>
          <Button
            disabled={
              (selectedUsers.length > 0 &&
                selectedUsers.length < users.length) ||
              users.length === 0
            }
            type="primary"
            onClick={() => setVisibleModalResources(true)}>
            {intl.formatMessage({ id: 'button-title.reset-all-users' })}
          </Button>
        </Row>

        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={dataSource}
          loadMore={
            filteredData.length > limit && (
              <Row
                type="flex"
                justify="center"
                className="challenge-users show-more">
                <Button size="small" onClick={() => handleShowMore()}>
                  {intl.formatMessage({ id: 'button-title.show-more' })}
                </Button>
              </Row>
            )
          }
          renderItem={(item) => (
            <List.Item
              actions={[
                <Checkbox
                  disabled={loading}
                  onChange={() => handleEditActivate(item.idUser)}
                />,
              ]}>
              {item.email}
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        visible={visibleModalResources}
        onCancel={() => closeModalResources()}
        footer={null}>
        <Row className="challenge-users header">
          <div className="challenge-users title">
            {intl.formatMessage({ id: 'title.resources-to-reset' })}
          </div>
        </Row>
        <Row className="challenge-users container-buttons">
          <Button
            disabled={
              selectedResources.length === 0 ||
              selectedResources.length === resourcesDisplay.length
            }
            type="primary"
            onClick={() => handleResetUserResource()}>
            {intl.formatMessage({ id: 'button-title.reset-resources' })}
          </Button>
          <Button
            size="small"
            disabled={
              selectedResources.length > 0 &&
              selectedResources.length < resourcesDisplay.length
            }
            type="primary"
            onClick={() => handleResetUserResource()}>
            {intl.formatMessage({ id: 'button-title.reset-all-resources' })}
          </Button>
        </Row>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={resourcesDisplay}
          loadMore={
            resourcesDisplay?.length > limit && (
              <Row
                type="flex"
                justify="center"
                className="challenge-users show-more">
                <Button size="small" onClick={() => handleShowMore()}>
                  {intl.formatMessage({ id: 'button-title.show-more' })}
                </Button>
              </Row>
            )
          }
          renderItem={(item: any) => (
            <List.Item
              actions={[
                <Checkbox
                  disabled={loading}
                  onChange={() => handleEditActivateResource(item.idResource)}
                />,
              ]}>
              <strong>{item.idResourceType.name}</strong>
              {'-'}
              {item.name}
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}
