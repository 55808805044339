import React, { useState } from 'react';
import { Button, Modal, Row } from 'antd';
import { useIntl } from 'react-intl';

import { Step } from './massPointsManagement.enum';
import {
  IFailedEmail,
  ITransactionDetails,
} from './massPointsManagement.interface';
import { EditStep, EditStepFooter } from './EditStep';
import { ValidateStep, ValidateStepFooter } from './ValidateStep';
import { CompleteStep, CompleteStepFooter } from './CompleteStep';
import { callBulkCreateTransactions } from './massPointsManagement.utils';

export enum MassPointsManagementPlatformEnum {
  LICON = 0,
  WEBEAT = 1,
}

export interface MassPointsManagementButtonProps {
  platform: MassPointsManagementPlatformEnum;
}

const DEFAULT_MAX_CUSTOMERS = 200;

const INITIAL_TRANSACTION_DETAILS: ITransactionDetails = {
  emails: { value: undefined, validationError: undefined },
  description: { value: undefined, validationError: undefined },
  amount: { value: undefined, validationError: undefined },
};

export default function MassPointsManagementButton({
  platform,
}: MassPointsManagementButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [formStep, setFormStep] = useState<Step>(Step.Edit);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] =
    useState<ITransactionDetails>(INITIAL_TRANSACTION_DETAILS);
  const [validateFailedEmails, setValidateFailedEmails] = useState<
    IFailedEmail[] | null
  >(null);
  const [failedEmails, setFailedEmails] = useState<IFailedEmail[] | null>(null);
  const intl = useIntl();

  const handleCloseModal = () => {
    setModalVisible(false);
    setTransactionDetails(INITIAL_TRANSACTION_DETAILS);
    setFormStep(Step.Edit);
  };

  const bulkCreateTransactions = async (validateOnly: boolean) => {
    setIsLoading(true);
    const response = await callBulkCreateTransactions(
      transactionDetails,
      validateOnly,
      platform,
    );
    setIsLoading(false);

    if (response?.status !== 200 || !response.data) {
      throw new Error('Response not ok or data not available.');
    }
    return response;
  };

  const addValidationError = <Key extends keyof ITransactionDetails>(
    property: Key,
    validationError: string | undefined,
  ) => {
    setTransactionDetails((oldTransactionDetails) => {
      const newTransactionDetails = {
        ...oldTransactionDetails,
        [property]: {
          ...oldTransactionDetails[property],
          validationError,
        },
      };
      return newTransactionDetails;
    });
  };

  const validateTransactionDetail = <Key extends keyof ITransactionDetails>(
    property: Key,
    value: ITransactionDetails[Key]['value'],
  ): boolean => {
    switch (property) {
      case 'amount':
        if (!Number.isInteger(value)) {
          addValidationError(
            property,
            intl.formatMessage({
              id: 'points.management.button.modal.input.points.msn',
            }),
          );
          return false;
        }
        break;

      case 'description':
        if (!value) {
          addValidationError(
            property,
            intl.formatMessage({
              id: 'points.management.button.modal.input.description.msn',
            }),
          );
          return false;
        }
        break;

      case 'emails':
        if (!Array.isArray(value) || value.length === 0) {
          addValidationError(
            property,
            intl.formatMessage({
              id: 'points.management.button.modal.input.emails.emptyMsn',
            }),
          );
          return false;
        }
        if (value.length > DEFAULT_MAX_CUSTOMERS) {
          addValidationError(
            property,
            intl.formatMessage(
              {
                id: 'points.management.button.modal.input.emails.limitExceededMsn',
              },
              { limit: DEFAULT_MAX_CUSTOMERS },
            ),
          );
          return false;
        }
        break;
    }

    addValidationError(property, undefined);
    return true;
  };

  const validateStateTransactionDetails = (): boolean => {
    const validateProperties: (keyof ITransactionDetails)[] = [
      'amount',
      'description',
      'emails',
    ];
    return validateProperties
      .map((property) =>
        validateTransactionDetail(property, transactionDetails[property].value),
      )
      .reduce((ok, currentOk) => ok && currentOk, true);
  };

  const handleTransactionDetailsChange = <
    Key extends keyof ITransactionDetails,
  >(
    property: Key,
    value: ITransactionDetails[Key]['value'],
  ) => {
    setTransactionDetails((oldTransactionDetails) => {
      const newTransactionDetails = {
        ...oldTransactionDetails,
        [property]: { ...oldTransactionDetails[property], value },
      };
      return newTransactionDetails;
    });
    validateTransactionDetail(property, value);
  };

  const validateAction = async () => {
    try {
      if (!validateStateTransactionDetails()) {
        throw new Error('Some fields do not pass validation.');
      }
      const response = await bulkCreateTransactions(/* validateOnly = */ true);
      setValidateFailedEmails(response.data.failedEmails);
      setFormStep(Step.Validate);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const performAction = async () => {
    try {
      const response = await bulkCreateTransactions(/* validateOnly = */ false);
      setFailedEmails(response.data.failedEmails);
      setFormStep(Step.Complete);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (formStep) {
      case Step.Edit:
        return (
          <EditStep
            transactionDetails={transactionDetails}
            onTransactionDetailsChange={handleTransactionDetailsChange}
          />
        );
      case Step.Validate:
        return (
          <ValidateStep
            transactionDetails={transactionDetails}
            validateFailedEmails={validateFailedEmails}
          />
        );
      case Step.Complete:
        return <CompleteStep failedEmails={failedEmails} />;
    }
  };

  const getModalFooter = () => {
    switch (formStep) {
      case Step.Edit:
        return (
          <EditStepFooter
            onCloseModalClick={handleCloseModal}
            onValidateClick={validateAction}
          />
        );

      case Step.Validate:
        return (
          <ValidateStepFooter
            isLoading={isLoading}
            onBackClick={() => setFormStep(Step.Edit)}
            onSubmitClick={performAction}
          />
        );

      case Step.Complete:
        return (
          <CompleteStepFooter
            isLoading={isLoading}
            onCloseModalClick={handleCloseModal}
          />
        );
    }
  };

  return (
    <>
      <Button
        style={{ marginLeft: '10px' }}
        onClick={() => setModalVisible(true)}
        type="primary"
      >
        {intl.formatMessage({ id: 'points.management.bulk.button.title' })}
      </Button>
      <Modal
        style={{ minHeight: '40vh' }}
        visible={modalVisible}
        onCancel={handleCloseModal}
        closable={false}
        footer={getModalFooter()}
      >
        <Row style={{ marginBottom: '1rem', fontSize: '1.6rem' }}>
          {intl.formatMessage({ id: 'points.management.bulk.button.title' })}
        </Row>
        <Row>{renderStep()}</Row>
      </Modal>
    </>
  );
}
