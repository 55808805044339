import React from 'react';
import { Icon, Row, Col } from 'antd';

import './NotFound.css';
import { useIntl } from 'react-intl';

const NotFound = () => {
  const intl = useIntl();
  return (
    <div className="notFound">
      <Row>
        <Col md={24}>
          <Icon className="notFound__icon" type="frown-o" />
          <div className="notFound__text">
            {intl.formatMessage({ id: 'page.notFound' })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
