import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ScanLandingRoleEnum } from '../constants';
import { ComboDataPlatform } from '../../combos/ComboInterfaces';

export type ScanLandingPreviewProps = FormComponentProps & {
  platformsConsumablesSelected: {
    consumables: number[];
    platforms: string[];
  }[];
  platformsCombo: ComboDataPlatform[] | undefined;
  consumablesCombo: { value: string; description: string }[];
  disabled: boolean;
  hasRole: boolean;
};

const rolesCombo: string[] = Object.values(ScanLandingRoleEnum);

const ScanLandingPreview: FC<ScanLandingPreviewProps> = ({
  form,
  platformsConsumablesSelected,
  platformsCombo = [],
  consumablesCombo = [],
  disabled = false,
  hasRole = false,
}) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  const webeatFrontOfficeUrl = process.env.REACT_APP_WEBEAT_FRONT_OFFICE_URL;

  const [openModal, setOpenModal] = useState(false);
  const [consumablesToSelect, setConsumablesToSelect] = useState<
    { value: string; description: string }[]
  >([]);

  const platformSelected = form.getFieldValue('platform');

  useEffect(() => {
    if (consumablesCombo.length) {
      const consumablesIdsSet = new Set<number>();
      platformsConsumablesSelected.forEach((platformConsumable) => {
        if (platformConsumable.platforms.includes(platformSelected)) {
          platformConsumable.consumables.forEach((consumable) => {
            consumablesIdsSet.add(consumable);
          });
        }
      });
      const consumablesIds = Array.from(consumablesIdsSet);
      if (consumablesIds.length) {
        form.setFieldsValue({ consumable: '' });
        const consumables = consumablesIds.map((consumable) => {
          return consumablesCombo?.find(
            (c) => c.value.toString() === consumable.toString(),
          );
        }) as { value: string; description: string }[];
        setConsumablesToSelect(consumables);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformSelected]);

  const platformsIdsSet = new Set<string>();
  platformsConsumablesSelected.forEach((item) => {
    item.platforms.forEach((platform) => {
      if (platform.startsWith('webeat')) {
        platformsIdsSet.add(platform);
      }
    });
  });
  const platformsIds = Array.from(platformsIdsSet);
  const platformsToSelect =
    platformsIds.map((platform) => {
      return platformsCombo.find((p) => p.platform_code === platform);
    }) || [];

  const platformInitialValue =
    platformsToSelect.length === 1 ? platformsToSelect[0]?.platform_code : '';

  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  const isSubmitButtonDisabled =
    !form.getFieldValue('platform') || !form.getFieldValue('consumable');

  const handleSubmit = () => {
    const platformId = form.getFieldValue('platform');
    const platformIdCountryQueryParam = platformId.substring(
      platformId.length - 2,
    );
    const consumableIdUrlQueryParam = form.getFieldValue('consumable');
    const roleNameUrlQueryParam = form.getFieldValue('role');
    const urlToOpen = `${webeatFrontOfficeUrl}/${platformIdCountryQueryParam}/preview-scan-landing/mobile-view?consumableId=${consumableIdUrlQueryParam}${
      roleNameUrlQueryParam ? `&roleName=${roleNameUrlQueryParam}` : ''
    }`;
    window.open(urlToOpen, '_blank');
  };

  return (
    <>
      <Button
        onClick={handleToggleModal}
        type="default"
        icon="eye"
        disabled={disabled}
        style={{ marginLeft: 10 }}>
        {formatMessage({ id: 'scanLanding.form.preview' })}
      </Button>
      <Modal
        title={formatMessage({ id: 'scanLanding.preview.form.title' })}
        style={{ top: 20 }}
        visible={openModal}
        onOk={handleSubmit}
        okText={formatMessage({ id: 'scanLanding.preview.form.submit' })}
        okButtonProps={{ disabled: isSubmitButtonDisabled }}
        onCancel={handleToggleModal}>
        <Form form={form} layout="vertical">
          <Row>
            <Col sm={24}>
              <Form.Item
                label={formatMessage({
                  id: 'scanLanding.preview.form.platform',
                })}
                required>
                {getFieldDecorator('platform', {
                  initialValue: platformInitialValue,
                })(
                  <Select
                    style={{ width: '100%' }}
                    showArrow
                    showSearch
                    filterOption
                    optionFilterProp="children"
                    allowClear
                    placeholder={formatMessage({
                      id: 'scanLanding.preview.form.platform.placeholder',
                    })}>
                    {platformsToSelect?.map((platform) => (
                      <Select.Option key={platform?.platform_code}>
                        {platform?.description}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Form.Item
                label={formatMessage({
                  id: 'scanLanding.preview.form.consumable',
                })}
                required>
                {getFieldDecorator('consumable', {
                  initialValue: '',
                })(
                  <Select
                    style={{ width: '100%' }}
                    showArrow
                    showSearch
                    filterOption
                    optionFilterProp="children"
                    allowClear
                    disabled={!form.getFieldValue('platform')}
                    placeholder={formatMessage({
                      id: 'scanLanding.preview.form.consumable.placeholder',
                    })}>
                    {consumablesToSelect?.map(
                      (consumable: { value: string; description: string }) => (
                        <Select.Option
                          key={consumable.value}
                          value={consumable.value}>
                          {consumable.description}
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          {hasRole ? (
            <Row>
              <Col sm={24}>
                <Form.Item
                  label={formatMessage({
                    id: 'scanLanding.preview.form.customerRole',
                  })}>
                  {getFieldDecorator('role', {
                    initialValue: '',
                  })(
                    <Select
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      disabled={!form.getFieldValue('platform')}
                      placeholder={formatMessage({
                        id: 'scanLanding.preview.form.customerRole.placeholder',
                      })}>
                      {rolesCombo.map((role) => (
                        <Select.Option key={role}>{role}</Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default Form.create<ScanLandingPreviewProps>({
  name: 'scanLandingPreview',
})(ScanLandingPreview);
