import React, { Fragment, useState } from 'react';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Button, Modal, notification } from 'antd';
import { FormattedMessage } from 'react-intl';

export default function WebeatInvalidateDermoButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const handleWebeatDermoInvalidation = async () => {
    try {
      const response = await api.putDataCall({
        dataPath: apiPaths.WEBEAT.DERMOADVISOR.INVALIDATE,
        data: {
          roleRequestId: selectedRow.id,
        },
        callConfig: {},
      });

      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'dermo.deny.notification.title',
          }),
          description: formatMessage({
            id: 'dermo.deny.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
      throw new Error('Error on deny dermoadvisor');
    } finally {
      await tableProps.handleReloadData();
      setShowConfirmModal(false);
    }
  };

  const buttonsDisabled =
    isEmpty(selectedRow) || selectedRow.state !== 'PENDING';

  return (
    <>
      <Button
        onClick={() => setShowConfirmModal(true)}
        disabled={buttonsDisabled}
        type="primary">
        {formatMessage({ id: 'dermo.deny.button' })}
      </Button>
      <Modal
        visible={showConfirmModal}
        closable={false}
        centered
        onOk={handleWebeatDermoInvalidation}
        onCancel={() => setShowConfirmModal(false)}
        className="modalConfirm">
        <FormattedMessage
          id="invalidation.dermoUser.text"
          values={{
            user: selectedRow.email,
            strong: (chunks: string) => <strong>{chunks}</strong>,
          }}
        />
      </Modal>
    </>
  );
}
