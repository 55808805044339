const {
  REACT_APP_BASE_URL,
  REACT_APP_FRONT_URL,
  REACT_APP_TIMEZONE_NAME,
  REACT_APP_PLATFORM_CODE,
  REACT_APP_PLATFORM_TYPE,
} = process.env;

const mockData = {
  MENU: [
    {
      disabled: false,
      icon: 50,
      id: 4,
      permission: null,
      nameOfMenu: 'Consumables',
      order: 0,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 53,
          id: 27,
          permission: null,
          nameOfMenu: 'Campaigns',
          order: 0,
          parentId: null,
          route: '/consumable-campaigns-dashboard',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 51,
          id: 27,
          permission: null,
          nameOfMenu: 'Consumables Config',
          order: 1,
          parentId: null,
          route: '/consumables-dashboard',
          status: true,
          visible: true,
          welcome: true,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 52,
          id: 27,
          permission: null,
          nameOfMenu: 'Consumables Variants',
          order: 2,
          parentId: null,
          route: '/variants-dashboard',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 53,
          id: 27,
          permission: null,
          nameOfMenu: 'Landing',
          order: 3,
          parentId: null,
          route: '/consumable-landing',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 53,
          id: 27,
          permission: null,
          nameOfMenu: 'Scan Landing',
          order: 4,
          parentId: null,
          route: '/scan-landing',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 53,
          id: 27,
          permission: null,
          nameOfMenu: 'Campaign products',
          order: 0,
          parentId: null,
          route: '/campaign-consumable-dashboard',
          status: true,
          visible: false,
          welcome: false,
          subMenu: [],
          mustRender: false,
        },
      ],
    },
    {
      disabled: false,
      icon: 1,
      id: 1,
      permission: null,
      nameOfMenu: 'Admin',
      order: 0,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      subMenu: [
        {
          disabled: false,
          icon: 1,
          id: 11,
          permission: null,
          nameOfMenu: 'Back-office Platforms',
          order: 0,
          parentId: null,
          route: '/webeat-back-office-platforms',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 1,
          id: 12,
          permission: null,
          nameOfMenu: 'Back-office Menu',
          order: 1,
          parentId: null,
          route: '/webeat-back-office-menus',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
      ],
      mustRender: true,
    },
    {
      disabled: false,
      icon: 2,
      id: 2,
      permission: null,
      nameOfMenu: 'Application',
      order: 1,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 2,
          id: 21,
          permission: null,
          nameOfMenu: 'Global Actions',
          order: 0,
          parentId: null,
          route: '/webeat-actions',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 22,
          permission: null,
          nameOfMenu: 'Mailing',
          order: 1,
          parentId: null,
          route: '/webeat-mailing',
          status: true,
          visible: true,
          welcome: true,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 51,
          id: 23,
          permission: null,
          nameOfMenu: 'QR products',
          order: 2,
          parentId: null,
          route: '/webeat-qr-products',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 24,
          permission: null,
          nameOfMenu: 'Pharmacy',
          order: 3,
          parentId: null,
          route: '/webeat-pharmacy',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 25,
          permission: null,
          nameOfMenu: 'List of Values',
          order: 4,
          parentId: null,
          route: '/webeat-list-of-values',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 26,
          permission: null,
          nameOfMenu: 'Tags',
          order: 4,
          parentId: null,
          route: '/webeat-tag',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 26,
          permission: null,
          nameOfMenu: 'Activate Qr',
          order: 4,
          parentId: null,
          route: '/webeat-activateQrs',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 28,
          permission: null,
          nameOfMenu: 'FAQ Section',
          order: 4,
          parentId: null,
          route: '/webeat-faq-section',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 2,
          id: 30,
          permission: null,
          nameOfMenu: 'FAQ Question',
          order: 4,
          parentId: null,
          route: '/webeat-faq-question',
          status: true,
          visible: false,
          welcome: false,
          subMenu: [],
          mustRender: false,
        },
      ],
    },
    {
      disabled: false,
      icon: 1,
      id: 3,
      permission: null,
      nameOfMenu: 'Platform Config',
      order: 2,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 13,
          id: 31,
          permission: null,
          nameOfMenu: 'Challenge type',
          order: 0,
          parentId: null,
          route: '/webeat-challenge-type',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 14,
          id: 31,
          permission: null,
          nameOfMenu: 'Resource type',
          order: 1,
          parentId: null,
          route: '/webeat-resource-type',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 1,
          id: 31,
          permission: null,
          nameOfMenu: 'Parameters',
          order: 1,
          parentId: null,
          route: '/webeat-parameters',
          status: true,
          visible: true,
          welcome: false,
          subMenu: [],
          mustRender: true,
        },
        {
          disabled: false,
          icon: 7,
          id: 32,
          permission: null,
          nameOfMenu: 'Restrictions',
          order: 1,
          parentId: null,
          route: '/webeat-restrictions',
          status: true,
          visible: true,
          welcome: true,
          subMenu: [],
          mustRender: true,
        },
      ],
    },
    {
      disabled: false,
      icon: 1,
      id: 4,
      permission: null,
      nameOfMenu: 'Modules',
      order: 3,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 26,
          id: 41,
          permission: null,
          nameOfMenu: 'Static pages',
          order: 0,
          parentId: null,
          route: '/webeat-static-pages',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 3,
          id: 23,
          permission: null,
          nameOfMenu: 'Html Content',
          order: 1,
          parentId: null,
          route: '/webeat-html-content',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 29,
          id: 23,
          permission: null,
          nameOfMenu: 'Search Management',
          order: 1,
          parentId: null,
          route: '/search-management',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 25,
          id: 42,
          permission: null,
          nameOfMenu: 'Menu',
          order: 1,
          parentId: null,
          route: '/webeat-menu',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 5,
          id: 46,
          permission: null,
          nameOfMenu: 'Customers',
          order: 1,
          parentId: null,
          route: '/webeat-customers',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 25,
          id: 49,
          permission: null,
          nameOfMenu: 'Child',
          order: 6,
          route: '/webeatMenuChildView',
          status: true,
          visible: false,
          welcome: false,
          mustRender: false,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 28,
          id: 43,
          permission: null,
          nameOfMenu: 'Challenge',
          order: 2,
          parentId: null,
          route: '/webeat-challenge',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 2,
          id: 45,
          permission: null,
          nameOfMenu: 'CMS',
          order: 4,
          parentId: null,
          route: '/webeat-html-content',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 3,
          id: 47,
          permission: null,
          nameOfMenu: 'Meta Tag Rule',
          order: 1,
          parentId: null,
          route: '/meta-tag-rule',
          status: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 17,
          id: 50,
          permission: null,
          nameOfMenu: 'Data replica',
          order: 5,
          parentId: null,
          route: '/webeat-data-replica',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
      ],
    },
    {
      disabled: false,
      icon: 1,
      id: 4,
      permission: null,
      nameOfMenu: 'Dermo',
      order: 4,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 10,
          id: 51,
          permission: null,
          nameOfMenu: 'Events',
          order: 1,
          parentId: null,
          route: '/webeat-dermo-event',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 10,
          id: 51,
          permission: null,
          nameOfMenu: 'Dermo Validation',
          order: 2,
          parentId: null,
          route: '/webeat-dermo-validation',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
        {
          disabled: false,
          icon: 10,
          id: 51,
          permission: null,
          nameOfMenu: 'Dermo Users',
          order: 3,
          parentId: null,
          route: '/webeat-dermo-users',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
      ],
    },
    // Subscription modal
    {
      disabled: false,
      icon: 11,
      id: 5,
      permission: null,
      nameOfMenu: 'Subscription modal',
      order: 5,
      parentId: null,
      route: '',
      status: true,
      visible: true,
      welcome: false,
      mustRender: true,
      subMenu: [
        {
          disabled: false,
          icon: 11,
          id: 52,
          permission: null,
          nameOfMenu: 'Subscription modal',
          order: 1,
          parentId: null,
          route: '/webeat-subscription-modal',
          status: true,
          visible: true,
          welcome: true,
          mustRender: true,
          subMenu: [],
        },
      ],
    },
  ],
  LOGIN_VALUES: { user: 'admin', password: 'admin' },
  PERMISSIONS: [],
  USER: {},
  PARAMS: {},
  PLATFORMS: [
    {
      description: 'local',
      extra: REACT_APP_FRONT_URL || '',
      idPlatform: '1',
      idPermission: 1,
      platform_code: REACT_APP_PLATFORM_CODE || '',
      platform_type: REACT_APP_PLATFORM_TYPE || '',
      timezonename: REACT_APP_TIMEZONE_NAME || '',
      value: REACT_APP_BASE_URL || '',
    },
  ],
};

export default mockData;
