import { Button, Col, Divider, Row } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import { ResourceTypeEnum } from '../../Enums';
import { blankSellInProduct } from '../ResourceConfigs';
import RenderDetail from '../TreeResource/TreeDetails';
import SellInProduct from './SellInProduct';

const { SELL_IN_PRODUCT } = ResourceTypeEnum;

const SellIn = ({
  editRecursiveResource,
  editResource,
  form,
  handleAddParentResource,
  handleRemoveParentResource,
  resource,
}: IResourceTree): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items

  if (!details?.length) return <Fragment />;

  const addComponentButton = editResource && (
    <Row
      className="QuizQuestion__btnAddAnswer"
      style={{ justifyContent: 'center' }}>
      <Button
        onClick={() => {
          handleAddParentResource(
            blankSellInProduct(resource.idResource, resource.resourceList),
          );
        }}>
        <FormattedMessage id="challenge.addProduct" />
      </Button>
    </Row>
  );

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) =>
          RenderDetail({
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            resource,
            mainIdResource: resource.idResource,
          }),
        )}

      <Col span="24">
        <Divider className="resourceDivider" />
        <Row className="QuizQuestion" type="flex" gutter={[24, 24]}>
          <Col span={24}>
            <div className="QuizQuestion__media--ellipsis">
              <FormattedMessage id="tree-resource.sell_in_products_title" />
            </div>
          </Col>
        </Row>
        <Row gutter={[12, 24]} justify="start" className="sell-in-products-row">
          {resource.resourceList?.map((resource, index) => {
            if (
              !resource.status ||
              resource.idResourceType.idResourceType !== SELL_IN_PRODUCT
            )
              return;

            return (
              <Col sm={24} md={12} lg={8}>
                <SellInProduct
                  editRecursiveResource={editRecursiveResource}
                  editResource={editResource}
                  form={form}
                  handleRemoveParentResource={handleRemoveParentResource}
                  resource={resource}
                  resourceIndex={index}
                />
              </Col>
            );
          })}
        </Row>
        {addComponentButton}
      </Col>
    </>
  );
};

export default SellIn;
