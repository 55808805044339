import React from 'react';

export default function budget() {
  return (
    <>
      <svg viewBox="0 0 512 512" className="svgIconFill">
        <path d="m512 310.699219v-109.398438h-55.277344c-4.609375-16.984375-11.347656-33.238281-20.125-48.539062l39.101563-39.101563-77.355469-77.355468-39.101562 39.097656c-15.304688-8.773438-31.554688-15.511719-48.542969-20.125v-55.277344h-109.398438v55.277344c-16.988281 4.609375-33.238281 11.351562-48.542969 20.125l-39.101562-39.101563-77.355469 77.355469 39.101563 39.101562c-8.773438 15.304688-15.515625 31.554688-20.125 48.542969h-55.277344v109.398438h55.277344c4.609375 16.984375 11.347656 33.238281 20.125 48.539062l-39.101563 39.101563 77.355469 77.355468 39.101562-39.101562c15.304688 8.777344 31.554688 15.515625 48.542969 20.128906v55.277344h109.398438v-55.277344c16.984375-4.609375 33.238281-11.351562 48.539062-20.125l39.101563 39.101563 77.355468-77.355469-39.097656-39.101562c8.773438-15.304688 15.511719-31.554688 20.125-48.542969zm-78.730469 87.640625-34.929687 34.933594-34.863282-34.867188-10.199218 6.671875c-18.585938 12.15625-38.992188 20.617187-60.648438 25.144531l-11.929687 2.496094v49.28125h-49.398438v-49.28125l-11.929687-2.496094c-21.65625-4.527344-42.0625-12.988281-60.648438-25.144531l-10.199218-6.671875-34.863282 34.863281-34.929687-34.929687 34.863281-34.863282-6.671875-10.199218c-12.15625-18.585938-20.613281-38.992188-25.144531-60.648438l-2.492188-11.929687h-49.285156v-49.398438h49.285156l2.492188-11.929687c4.53125-21.65625 12.988281-42.0625 25.144531-60.648438l6.671875-10.199218-34.863281-34.863282 34.929687-34.929687 34.863282 34.863281 10.199218-6.671875c18.585938-12.15625 38.992188-20.613281 60.648438-25.144531l11.929687-2.496094v-49.28125h49.398438v49.28125l11.929687 2.496094c21.65625 4.527344 42.0625 12.988281 60.648438 25.144531l10.199218 6.671875 34.863282-34.863281 34.929687 34.929687-34.863281 34.863282 6.671875 10.199218c12.15625 18.585938 20.613281 38.992188 25.144531 60.648438l2.492188 11.929687h49.285156v49.398438h-49.285156l-2.492188 11.929687c-4.53125 21.65625-12.988281 42.0625-25.144531 60.648438l-6.671875 10.199218zm0 0" />
        <path d="m256 142.546875c-62.558594 0-113.453125 50.894531-113.453125 113.453125s50.894531 113.453125 113.453125 113.453125 113.453125-50.894531 113.453125-113.453125-50.894531-113.453125-113.453125-113.453125zm0 196.90625c-46.015625 0-83.453125-37.4375-83.453125-83.453125s37.4375-83.453125 83.453125-83.453125 83.453125 37.4375 83.453125 83.453125-37.4375 83.453125-83.453125 83.453125zm0 0" />
      </svg>
    </>
  );
}
