import { Button, Modal, Row, Input, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function PearlsReviewerButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reviewerEmail, setReviewerEmail] = useState<string>('');
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      await api
        .getDataCall({
          dataPath: apiPaths.CALL.PEARL_REVIEWER_EMAIL,
          callConfig: {},
        })
        .then((res) => {
          if (res.data) setReviewerEmail(res.data.email);
        })
        .catch((err) => {
          notification.error({
            message: formatMessage({
              id: 'pearls.get-reviewer-email.notification.error',
            }),
          });
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirmModal]);

  const handleUpdateReviewerEmail = async () => {
    try {
      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.PEARL_REVIEWER_EMAIL,
        data: {
          email: reviewerEmail,
        },
        callConfig: {},
      });

      if (response?.status === 200 && response?.data) {
        notification.info({
          message: formatMessage({
            id: 'pearls.updated-reviewer-email.notification.title',
          }),
          description: formatMessage({
            id: 'pearls.updated-reviewer-email.notification.description',
          }),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const buttonsDisabled = isEmpty(parentProps.props.data);

  return (
    <>
      <Button
        style={{ marginRight: '20px' }}
        onClick={() => setShowConfirmModal(true)}
        disabled={buttonsDisabled}
        type="primary">
        {formatMessage({ id: 'pearls.button-reviewer' })}
      </Button>
      <Modal
        visible={showConfirmModal}
        closable={false}
        centered
        okText="Update"
        onOk={handleUpdateReviewerEmail}
        onCancel={() => setShowConfirmModal(false)}
        className="modalConfirm">
        <Row className="isdin-custom-modal__container">
          <Row className="isdin-custom-modal__title">
            {formatMessage({ id: 'pearls.reviewer-email' })}
          </Row>
          <Input
            onChange={(e) => setReviewerEmail(e.target.value)}
            allowClear
            placeholder="User email"
            value={reviewerEmail}
          />
        </Row>
      </Modal>
    </>
  );
}
