import { Button, Col, Divider, Form, Icon, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';
import UploadComponent from '../../../challenge/Components/UploadComponent';
import { ComboData } from '../../../combos/ComboInterfaces';
import { setLoading } from '../../../contentPage/redux/contentPageActions';
import { ILandingProduct } from './ProductLandingInterfaces';
import { trim } from 'lodash';
import { feedback } from '../../../utils';

interface OwnProps {
  disabled: boolean;
  landingProduct: ILandingProduct | undefined;
  loading: boolean;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
  setLandingProduct: React.Dispatch<
    React.SetStateAction<ILandingProduct | undefined>
  >;
  token: string;
  visible: boolean;
}

interface IItem {
  value: string | null;
  description: string | null;
}

type Props = FormComponentProps & OwnProps;

const LandingBulkEditForm: FC<Props> = ({
  disabled,
  form,
  landingProduct,
  loading,
  setValidated,
  setLandingProduct,
  token,
  visible,
}) => {
  const { formatMessage } = useIntl();
  const [landingProducts, setLandingProducts] = useState<[]>();

  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    if (landingProducts) return;

    setLoading(true);
    getLandingProducts()
      .then((res) => {
        if (!res?.data) {
          console.error('Landing products has no data!');
          return;
        }
        setLandingProducts(res.data);
      })
      .catch((err) => {
        console.error('Error fetching landing products response', err);
      })
      .finally(() => setLoading(false));
  }, [landingProducts]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields(async (err: any) => {
      if (!err) {
        const fieldsValue = getFieldsData();
        if (!Object.keys(fieldsValue).length) {
          setValidated(false);
          feedback({
            type: 'notification',
            method: 'error',
            message: 'form.save.empty.error',
          });
          return;
        }
        setValidated(true);
        setLandingProduct(getFieldsData());
      } else {
        setValidated(false);
      }
    });
  };

  const getFieldsData = () => {
    const newData = form.getFieldsValue();
    return Object.fromEntries(
      Object.entries(newData).filter(
        ([f, v]) => f !== 'landingProductToCopyFrom' && v != null && !!trim(v),
      ),
    );
  };

  const handleLandingProductChange = (value: any) => {
    if (value) {
      const findProduct: IItem | undefined = landingProducts?.find(
        (product: IItem) => product.description === value,
      );

      if (findProduct && 'value' in findProduct) {
        setLoading(true);
        getParentLandingProduct(findProduct['value'])
          .then((res) => {
            form.setFieldsValue({
              productNameLanding: res?.data.productNameLanding,
              productImage: res?.data.productImage,
              productDescription: res?.data.productDescription,
              productMainImageDesktop: res?.data.productMainImageDesktop,
              productMainImageMobile: res?.data.productMainImageMobile,
              productVideo: res?.data.productVideo,
              productVideoTitle: res?.data.productVideoTitle,
              productVideoDescription: res?.data.productVideoDescription,
              productVideoThumbnail: res?.data.productVideoThumbnail,
              productContent: res?.data.productContent,
            });
          })
          .finally(() => setLoading(false));
      }
    }
  };

  async function getLandingProducts() {
    try {
      const landingProductsResponse = await api.getCombo({
        id: 'landingProducts',
      });
      return landingProductsResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async function getParentLandingProduct(productId: any) {
    try {
      const parentLandingProductResponse = await api.getDataCallById({
        dataPath: apiPaths.CALL.LANDING_PRODUCT,
        registerId: productId,
        callConfig: {},
      });
      return parentLandingProductResponse;
    } catch (error) {
      console.error(error);
    }
  }

  const UploadImage = (fieldName: string) => {
    return (
      <UploadComponent
        {...{
          disabled: disabled,
          setValue: (value) => form.setFieldsValue({ [fieldName]: value }),
          value: form.getFieldValue(fieldName),
          handleChangeField: () => handleUploadChange(fieldName),
          token,
          fieldName: fieldName,
        }}>
        <Row className="media">
          <Button
            className="uploadButton uploadButton__margin-top"
            disabled={disabled}>
            <Icon type="upload" />
            {formatMessage({
              id: 'upload.buttonTitle',
            })}
          </Button>
        </Row>
      </UploadComponent>
    );
  };

  /**
   * @warning DO NOT DELETE, required by UploadComponent.tsx
   * @todo Review delete/change behavior on UploadComponent.tsx
   * */
  const handleUploadChange = (fieldName: string): void => {};

  return (
    <Form>
      <Row type="flex" align="top">
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.copy-from.title',
            })}>
            {getFieldDecorator('landingProductToCopyFrom', {
              initialValue: null,
            })(
              <Select
                showSearch
                placeholder={formatMessage({
                  id: 'qrproduct.dashboard.landing.copy-from.placeholder',
                })}
                style={{ width: '100%', height: '50px' }}
                onChange={(value) => handleLandingProductChange(value)}
                disabled={disabled}>
                {landingProducts
                  ?.filter(
                    ({ description, value }: ComboData) => value && description,
                  )
                  .map((item: ComboData) => (
                    <Select.Option
                      key={item.value.toString()}
                      value={item.description}>
                      {item.description}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" align="top" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Divider orientation="left">
          {formatMessage({
            id: 'qrproduct.dashboard.landing.section.product',
          })}
        </Divider>
        <Col xs={22} md={20}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.product.name.landing',
            })}>
            {getFieldDecorator('productNameLanding', {
              initialValue: null,
            })(<Input disabled={disabled} maxLength={35} />)}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.image',
            })}>
            {getFieldDecorator('productImage', {
              initialValue: null,
            })(UploadImage('productImage'))}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.description',
            })}>
            {getFieldDecorator('productDescription', {
              initialValue: null,
            })(
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                allowClear
                maxLength={50}
                disabled={disabled}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" align="top" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Divider orientation="left">
          {formatMessage({
            id: 'qrproduct.dashboard.landing.section.banner',
          })}
        </Divider>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.banner.desktop',
            })}>
            {getFieldDecorator('productMainImageDesktop', {
              initialValue: null,
            })(UploadImage('productMainImageDesktop'))}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.banner.mobile',
            })}>
            {getFieldDecorator('productMainImageMobile', {
              initialValue: null,
            })(UploadImage('productMainImageMobile'))}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" align="top" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Divider orientation="left">
          {formatMessage({
            id: 'qrproduct.dashboard.landing.section.video',
          })}
        </Divider>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.video.title',
            })}>
            {getFieldDecorator('productVideoTitle', {
              initialValue: null,
            })(<Input disabled={disabled} maxLength={100} />)}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.video',
            })}>
            {getFieldDecorator('productVideo', {
              initialValue: null,
            })(<Input disabled={disabled} />)}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.video.description',
            })}>
            {getFieldDecorator('productVideoDescription', {
              initialValue: null,
            })(
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                allowClear
                maxLength={250}
                disabled={disabled}
              />,
            )}
          </Form.Item>
        </Col>
        <Col xs={22} md={12}>
          <Form.Item
            label={formatMessage({
              id: 'qrproduct.dashboard.landing.video.thumbnail',
            })}>
            {getFieldDecorator('productVideoThumbnail', {
              initialValue: null,
            })(UploadImage('productVideoThumbnail'))}
          </Form.Item>
        </Col>
      </Row>
      <Row
        type="flex"
        align="top"
        justify="space-between"
        style={{ marginTop: '10px' }}>
        <Col xs={22}>
          <Button
            disabled={disabled}
            htmlType="button"
            icon="check"
            loading={loading}
            onClick={handleSubmit}
            type="primary">
            {formatMessage({
              id: 'points.management.button.modal.form.validate',
            })}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const LandingBulkEditFormWithProps = Form.create<Props>({
  onFieldsChange(props, fields) {
    props.setValidated(false);
  },
})(LandingBulkEditForm);

export default LandingBulkEditFormWithProps;
