import apiPaths from '../apiPaths';
import * as api from '../api';

import qs from 'query-string';
import { authTypes } from './authTypes';
import { appTypes } from '../app/appTypes';
import config from '../config';
import { saveState } from '../utils/localStorage';
import { feedback } from '../utils/feedback';
import { Dispatch } from 'redux';
import { IRow } from '../app/AppInterfaces';

export const login = (data?: IRow) => async (dispatch: any) => {
  dispatch({
    type: authTypes.ENABLE_LOADING,
  });
  if (config.SECURITY === 0 || (config.SECURITY === 1 && data)) {
    dispatch({
      type: authTypes.AUTH_USER,
    });
    const status = { action: 'fetch', status: 200, message: '' }; // fake response
    return status;
  } else if (config.SECURITY === 2 && data) {
    data.platform = config.APP.PLATFORM;
    const stringData = qs.stringify(data);
    try {
      const loginResponse = await api.loginCall({
        dataPath: apiPaths.AUTH.LOGIN,
        data: stringData,
      });
      const refreshToken = loginResponse.data.refreshToken;
      const accessToken = 'Bearer ' + loginResponse.data.accessToken;

      saveState({
        auth: {
          isAuthorized: true,
          accessToken,
          refreshToken,
        },
      });
      dispatch({
        type: authTypes.SET_TOKENS,
        payload: { refreshToken, accessToken },
      });
      dispatch({
        type: authTypes.AUTH_USER,
      });
      dispatch({
        type: authTypes.DISABLE_LOADING,
      });
      const status = {
        action: 'login',
        status: loginResponse.status,
        message: '',
      };
      return status;
    } catch (err) {
      dispatch({
        type: authTypes.DISABLE_LOADING,
      });

      if (!err.response) return { action: 'login', status: 500, message: '' };
      const status = {
        action: 'login',
        status: err.response.status,
        message: err.response.data.error_description,
      };
      return status;
    }
  }
};

interface LogoutUnauth {
  type: authTypes.UNAUTH_USER;
}

interface LogoutRemoveToken {
  type: authTypes.REMOVE_TOKEN;
}

interface LogoutRemoveProps {
  type: appTypes.APP_REMOVE_STORE;
}

export const logout =
  () =>
  async (
    dispatch: Dispatch<LogoutUnauth | LogoutRemoveProps | LogoutRemoveToken>,
  ) => {
    switch (config.SECURITY) {
      case 0:
        break;
      case 1:
        dispatch({ type: authTypes.UNAUTH_USER });
        break;
      case 2:
        dispatch({ type: authTypes.UNAUTH_USER });
        dispatch({ type: authTypes.REMOVE_TOKEN });
        dispatch({ type: appTypes.APP_REMOVE_STORE });

        saveState({
          auth: {
            isAuthorized: false,
            accessToken: '',
            refreshToken: '',
          },
        });
        break;
      default:
        break;
    }
  };

export const passwordUpdate = (data: any) => async (dispatch: any) => {
  try {
    const response = await api.updatePasswordCall(apiPaths.AUTH.UPDATE, data);
    dispatch(setModal({ visible: true }));
    return response;
  } catch (err) {
    dispatch(setModal({ visible: false }));
    return feedback({
      type: 'notification',
      action: 'update.password',
      method: 'error',
      title: 'error.title',
      message: 'update.error.token',
    });
  }
};

export const setModal =
  ({ visible }: { visible: boolean }) =>
  (dispatch: any) => {
    dispatch({
      type: authTypes.SUCCESS_MODAL,
      payload: { visible },
    });
  };
