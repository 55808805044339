import React from "react"
import { ITableRenderListProps } from "./TableRender";
import { IRow } from "../app/AppInterfaces";
import { Button, Form, Input, Tabs, Select } from "antd";
import { isEmpty } from "lodash";
import * as api from '../api';

const MODAL_TABS = {
  SAME_SITE: 'same-site',
  WEBEAT_SITE: 'webeat-site'
}


export const DuplicateElementComponent = ({
  parentProps,
  selectedRow
}: {
  parentProps: ITableRenderListProps;
  selectedRow: IRow;
}) => {
  const { props: { params }, handleReloadData } = parentProps;

  const { duplicateRow } = params;

  const [modalTab, setModalTab] = React.useState(
    params.duplicateRow?.sameSite ? MODAL_TABS.SAME_SITE :
      params.duplicateRow?.webeatSites ? MODAL_TABS.WEBEAT_SITE :
        null);
  const [visible, setVisible] = React.useState(false);
  const [prettyKeyValue, setPrettyKeyValue] = React.useState('');
  const [webeatSiteValue, setWebeatSiteValue] = React.useState<string | undefined>(undefined);
  const [webeatSiteList, setWebeatSiteList] = React.useState([]);
  const [loadingHandleDuplicateData, setLoadingHandleDuplicateData] = React.useState(false);

  React.useEffect(() => {
    if (duplicateRow?.webeatSites && visible) {
      api.getDataCall({
        callConfig: {},
        dataPath: '/config/webeat-active-platforms',
      }).then((response) => {
        setWebeatSiteList(response.data)
      })
    }
  }, [duplicateRow, visible])

  if (!duplicateRow) return null;
  const {
    title,
    description,
    sameSite,
    key: prettyKey,
    webeatSites,
    idKey,
    duplicatePath
  } = duplicateRow;


  const handleDuplicateData = () => {
    setLoadingHandleDuplicateData(true)
    const idUseKey = idKey || 'id'

    const data = {
      [idUseKey]: selectedRow.id
    }

    if (prettyKey) {
      data[prettyKey] = prettyKeyValue
    }

    if (webeatSites) {
      data['siteId'] = webeatSiteValue
    }

    api.postDataCall({
      callConfig: {},
      dataPath: duplicatePath,
      data
    }).then((response) => {
      handleReloadData()
      setVisible(false)
    }).finally(() => {
      setLoadingHandleDuplicateData(false)
    })
  }

  return (
    <>
      <Button
        type="ghost"
        icon="copy"
        className="single-btn"
        disabled={isEmpty(selectedRow)}
        onClick={() => setVisible(true)}
      >
        Duplicate
      </Button>
      {visible &&
        <div className={"webeat-custom-modal-back"}>
          <div className="webeat-custom-modal">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="modal-tab">
              {modalTab && <Tabs
                activeKey={modalTab}
                onChange={(key) => {
                  setWebeatSiteValue(undefined)
                  setPrettyKeyValue('')
                  setModalTab(key)
                } }
              >
                {sameSite && <Tabs.TabPane tab="Same Site" key={MODAL_TABS.SAME_SITE}>
                  {
                    prettyKey &&
                    <Form className="pretty-key">
                      <Form.Item>
                        <Input
                          type="text"
                          value={prettyKeyValue}
                          onChange={(e) => setPrettyKeyValue(e.target.value)}
                          placeholder={prettyKey}
                        />
                      </Form.Item>
                    </Form>
                  }
                </Tabs.TabPane>}
                {webeatSites && <Tabs.TabPane tab="Webeat Site" key={MODAL_TABS.WEBEAT_SITE}>
                  {
                    prettyKey &&
                    <Form className="pretty-key">
                      <Form.Item>
                        <Input
                          type="text"
                          value={prettyKeyValue}
                          onChange={(e) => setPrettyKeyValue(e.target.value)}
                          placeholder={prettyKey}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Select
                          mode="default"
                          showSearch
                          dropdownMatchSelectWidth={false}
                          disabled={false}
                          value={webeatSiteValue}
                          placeholder="Select a webeat site"
                          onChange={(value: string) => {
                            setWebeatSiteValue(value)
                          }}
                        >
                          {
                            webeatSiteList.map((option: any) => (
                              <Select.Option
                                key={option}
                                value={option.toString()}>
                                {option}
                              </Select.Option>
                            ))
                          }

                        </Select>
                      </Form.Item>
                    </Form>
                  }
                </Tabs.TabPane>}
              </Tabs>}
              {
                !modalTab &&
                <div className="modal-tab-content">
                  <p>At less one tab have to be enable</p>
                </div>
              }
              <div className="modal-footer">
                <Button
                  type="primary"
                  disabled={
                    (modalTab === MODAL_TABS.SAME_SITE && !prettyKeyValue) ||
                    (modalTab === MODAL_TABS.WEBEAT_SITE && (!prettyKeyValue || !webeatSiteValue))
                  }
                  loading={loadingHandleDuplicateData}
                  onClick={() => handleDuplicateData()}
                >
                  Confirm
                </Button>
                <Button
                  type="ghost"
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}