import { Collapse, Form } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import UploadComponent from './UploadComponent';
import { ScanLandingMainComponentTypesEnum } from '../enums';
import { UPLOAD_ACTION_IMAGE, UPLOAD_ACTION_IMAGE_URL } from '../../shared';

interface ImageProps {
  url: string;
}
export interface ScanLandingMainComponentModel {
  type: ScanLandingMainComponentTypesEnum;
  mobile?: ImageProps;
  tablet?: ImageProps;
  desktop?: ImageProps;
}

type DeviceOptions = Array<
  keyof Pick<ScanLandingMainComponentModel, 'mobile' | 'tablet' | 'desktop'>
>;
const DEVICE_OPTIONS: DeviceOptions = ['mobile', 'tablet', 'desktop'];
const REQUIRED_DEVICES: DeviceOptions = ['mobile'];

const ScanLandingMainComponent: FC<
  ScanLandingComponentProps<ScanLandingMainComponentModel> &
    ScanLandingComponentUploadProps
> = ({ index, form, values, token, disabled, handleOnChange }) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <Collapse
      bordered={false}
      style={{
        fontSize: '16px',
        backgroundColor: '#f7f7f7',
      }}
      defaultActiveKey={REQUIRED_DEVICES.map((device) => `main.${device}`)}>
      {DEVICE_OPTIONS.map((device) => (
        <Collapse.Panel
          key={`main.${device}`}
          showArrow={false}
          header={formatMessage({
            id: `scanLanding.component.main.${device}`,
          })}>
          <Form.Item
            label={formatMessage({ id: `scanLanding.component.main.url` })}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
            }}>
            {getFieldDecorator(`components[${index}].model.${device}.url`, {
              initialValue: values[device]?.url ?? '',
            })(
              <UploadComponent
                {...{
                  value: values[device]?.url ?? '',
                  setValue: (value) => {
                    handleOnChange(
                      `components[${index}].model.${device}.url`,
                      value,
                      true,
                    );
                  },
                  fieldName: `components[${index}].model.${device}.url`,
                  token,
                  disabled,
                  format: 'image',
                  uploadActions: [UPLOAD_ACTION_IMAGE, UPLOAD_ACTION_IMAGE_URL],
                }}
              />,
            )}
          </Form.Item>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default ScanLandingMainComponent;
