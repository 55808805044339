import { Button, Modal, Row } from 'antd';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';
import { EditFormRenderProps } from '../../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../../tables/TableRender';
import { isTableProps } from '../../../utils';
import QrProductBulkEditFormWithProps from './QrProductBulkEditForm';
import { IBulkUpdateQrProducts } from './QrProductInterfaces';
import { IQrProduct } from './QrProductInterfaces';

const BulkEditQrProducts = <
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(
  parentProps: T,
) => {
  const { formatMessage } = useIntl();

  const [disabled, setDisabled] = useState(false);
  const [qrProduct, setQrProduct] = useState<IQrProduct>();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);

  if (!isTableProps(parentProps)) return <Fragment />;
  const tableProps: ITableRenderListProps = parentProps;

  const { isLoading, selectedRowKeys } = tableProps.props;

  const handleModalClose = () => {
    handleModalToggle(false);
  };

  const handleModalOpen = () => {
    handleModalToggle(true);
  };

  const handleModalToggle = (toggle: boolean) => {
    setDisabled(!toggle);
    setValidated(!toggle);
    setVisible(toggle);
  };

  const handleModalSubmit = () => {
    setLoading(true);
    saveData()
      .then(() =>
        setTimeout(() => {
          tableProps.handleReloadData()
          handleModalClose();
        }, 600),
      )
      .catch((err: any) => console.error(err))
      .finally(() => setLoading(false));
  };

  const saveData = async () => {
    const data: IBulkUpdateQrProducts = {
      productArr: (selectedRowKeys as (string | number)[]).map((row) =>
        typeof row === 'string' ? parseInt(row) : row,
      ),
      productContent: { ...qrProduct },
    };
    const response = await api.postDataCall({
      dataPath: apiPaths.CALL.QR_PRODUCT_BULK,
      data: data,
      callConfig: {},
    });
    return response;
  };

  const getModalFooter = (
    <Row>
      <Button
        type="danger"
        htmlType="submit"
        onClick={handleModalClose}
        loading={loading}>
        {formatMessage({ id: 'form.edit.cancel' })}
      </Button>
      <Button
        type="primary"
        disabled={!validated}
        onClick={handleModalSubmit}
        loading={loading}>
        {formatMessage({
          id: 'form.edit.submit',
        })}
      </Button>
    </Row>
  );

  return (
    <>
      <Button
        disabled={isLoading || loading || !selectedRowKeys?.length}
        icon="form"
        onClick={() => handleModalOpen()}
        type="primary">
        {formatMessage({
          id: 'custom-buttons.edit-qr-products.bulk-edit',
        })}
      </Button>
      <Modal
        className="request-order-modal"
        closable={false}
        footer={getModalFooter}
        onCancel={handleModalClose}
        onOk={handleModalClose}
        visible={visible}>
        <h2 className="request-order-modal__title">
          {formatMessage({
            id: 'qrproduct.dashboard.products.bulk-edit.title',
          })}
        </h2>
          <QrProductBulkEditFormWithProps
            disabled={disabled}
            qrProduct={qrProduct}
            loading={loading}
            setValidated={setValidated}
            setQrProduct={setQrProduct}
            visible={visible}
          />
      </Modal>
    </>
  );
};

export default BulkEditQrProducts;
