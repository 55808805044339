import { Button, Icon, Input, Row, Tooltip } from 'antd';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import './Collapsible.css';

interface ICollapsible {
  children: JSX.Element;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  editable?: {
    disabled?: boolean | undefined;
    canEdit: boolean;
    editableFunction: ({ value }: { value: string }) => void;
    canDelete: boolean;
    deleteFunction: (index: number) => void;
    deleteId: number;
    setEditing: () => void;
    saveChanges: () => void;
    discardChanges: () => void;
    isEditVisible?: boolean;
  };
  extra?: JSX.Element | JSX.Element[];
  extraEditButtons?: JSX.Element[];
  forceCollapse?: boolean;
  forceShowContent?: boolean;
  headerBackgroundColor?: string;
  scrollable?: boolean;
  showAllContent?: boolean;
  size?: 'small' | 'mid' | 'large';
  title: string;
  titleLimit?: number | undefined;
  type?: string;
  typeToolTip?: string;
  isExperience?: boolean;
}

export default function Collapsible(props: ICollapsible) {
  const {
    children,
    collapsible = true,
    editable,
    extra,
    extraEditButtons,
    forceCollapse,
    forceShowContent,
    headerBackgroundColor,
    scrollable,
    size,
    showAllContent,
    title,
    titleLimit,
    type,
    typeToolTip,
    isExperience,
  } = props;
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(
    props.defaultCollapsed !== undefined ? props.defaultCollapsed : true,
  );

  if (editable?.canEdit && collapsed) setCollapsed(false);

  let contentClassName;

  switch (true) {
    case forceCollapse:
      contentClassName = 'content__on';
      break;
    case forceShowContent:
      contentClassName = 'content';
      break;
    case editable?.canEdit:
      contentClassName = 'contentInEdition';
      break;
    case scrollable:
      contentClassName = collapsed ? 'contentQuiz__on' : 'contentQuiz';
      break;
    case showAllContent:
      contentClassName = collapsed ? 'showAll__on' : 'showAll';
      break;
    case collapsed:
      contentClassName = 'content__on';
      break;
    default:
      contentClassName = 'content';
      break;
  }

  return (
    <div className="collapsible">
      <Row
        className="header"
        style={{
          backgroundColor:
            headerBackgroundColor !== undefined ? headerBackgroundColor : '',
        }}>
        {!showAllContent && editable?.canEdit ? (
          <Row type="flex" justify="space-between" align="top">
            <div>
              {isExperience && <span className="required-asteric">*</span>}
              <Input
                size="small"
                className="editableTitle"
                maxLength={titleLimit}
                placeholder={intl.formatMessage({
                  id: 'collapsible.title.placeholder',
                })}
                required={isExperience}
                defaultValue={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editable.editableFunction({ value: e.target.value });
                }}
              />
              {isExperience && title?.length === 0 && (
                <p className="required-error-text">Required</p>
              )}
              <div className="extra__editable">{extra}</div>
            </div>
            <div>
              <Button
                type="primary"
                className="editableTitle__buttons--save"
                onClick={() => editable.saveChanges()}>
                {intl.formatMessage({ id: 'form.save' })}
              </Button>
              <Button
                type="primary"
                className="editableTitle__buttons--cancel"
                onClick={() => editable.discardChanges()}>
                {intl.formatMessage({ id: 'form.edit.cancel' })}
              </Button>
            </div>
          </Row>
        ) : (
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            onClick={() =>
              collapsible && !forceCollapse && setCollapsed(!collapsed)
            }
            className="collapsible__talk">
            <div className={'header'.concat('__', size ? size : 'mid')}>
              {type && (
                <div className="header__titleType">
                  {intl.formatMessage({
                    id: `component-type.${type.toLowerCase()}`,
                  })}
                  {typeToolTip && (
                    <Tooltip className="fieldTooltip" title={typeToolTip}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  )}
                </div>
              )}
              <span>{title}</span>
            </div>
            <div className="icon">
              {collapsed && !forceCollapse && !forceShowContent ? (
                <Icon type="ellipsis" />
              ) : (
                (!forceCollapse || forceShowContent) && (
                  <>
                    {extraEditButtons?.map((button, i) => (
                      <Fragment key={i}>{button}</Fragment>
                    ))}
                    {editable && (
                      <>
                        {editable.isEditVisible && (
                          <Button
                            className="actionButton"
                            title={
                              editable.disabled
                                ? intl.formatMessage({
                                    id: 'collapsible.editDisabled',
                                  })
                                : intl.formatMessage({ id: 'collapsible.edit' })
                            }
                            icon="edit"
                            disabled={editable.disabled}
                            onClick={() => editable?.setEditing()}
                          />
                        )}
                        <Button
                          className="actionButton"
                          title={
                            editable.disabled
                              ? intl.formatMessage({
                                  id: 'collapsible.deleteDisabled',
                                })
                              : intl.formatMessage({ id: 'collapsible.delete' })
                          }
                          size="small"
                          icon="delete"
                          disabled={editable.disabled}
                          onClick={() =>
                            editable.deleteFunction(editable.deleteId)
                          }
                        />
                      </>
                    )}
                  </>
                )
              )}
            </div>
          </Row>
        )}
      </Row>
      <Row className={contentClassName}>
        <Row className="collapsibleBody">{children}</Row>
      </Row>
    </div>
  );
}
