import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConsumablesDefaultPlatform } from '../utils';

const ScanLandingDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const componentId = 'ScanLanding';

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'scanLandingSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'scanLandingId',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'consumableId',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'platform',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'scanLanding.id' }),
          key: 'scanLandingId',
          type: 'text',
          operand: 'equal',
          globalSearch: false,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'scanLanding.name',
          }),
          key: 'name',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'scanLanding.consumable.id' }),
          key: 'consumableId',
          type: 'selector',
          selectorPath: 'consumable/get-consumable-id-list',
          comboId: 'consumableId',
          operand: 'equal',
          globalSearch: false,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'scanLanding.platform',
          }),
          key: 'platform',
          type: 'text',
          operand: 'like',
          globalSearch: false,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: isConsumablesDefaultPlatform(),
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'scanLandingList',
        rowKey: 'id',
        panelHeader: formatMessage({ id: 'scanLanding.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'title',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: formatMessage({ id: 'scanLanding.id' }),
          key: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'scanLanding.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: false,
        canDelete: isConsumablesDefaultPlatform(),
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'scanLandingEdit',
        panelHeader: formatMessage({ id: 'scanLanding.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
        isScanLanding: true,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'title',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'images.main',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'images.desktop',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'landingVideo.url',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'landingVideo.thumbnail',
                  yPosition: 5,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'landingVideo.title',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'landingVideo.description',
                  yPosition: 7,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'locale',
                  yPosition: 8,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'active',
                  yPosition: 9,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'scanLanding.consumable.id' }),
          key: 'consumableId',
          type: 'selector',
          selectorPath: 'consumable/get-consumable-id-list',
          comboId: 'consumableId',
        },
        {
          title: formatMessage({ id: 'scanLanding.id' }),
          key: 'id',
          type: 'text',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'scanLanding.title' }),
          key: 'title',
          type: 'text',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'scanLanding.description' }),
          key: 'description',
          type: 'textarea',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'scanLanding.image.hero' }),
          key: 'images.main',
          type: 'imageConsumable',
          mandatory: true,
          module: 'consumable',
        },
        {
          title: formatMessage({ id: 'scanLanding.image.desktop' }),
          key: 'images.desktop',
          type: 'imageConsumable',
          mandatory: false,
          module: 'consumable',
        },
        {
          title: formatMessage({ id: 'scanLanding.landingVideo.url' }),
          key: 'landingVideo.url',
          type: 'url',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'scanLanding.landingVideo.thumbnail',
          }),
          key: 'landingVideo.thumbnail',
          type: 'imageConsumable',
          mandatory: false,
          disabled: false,
          module: 'consumable',
        },
        {
          title: formatMessage({
            id: 'scanLanding.landingVideo.title',
          }),
          key: 'landingVideo.title',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'scanLanding.landingVideo.description',
          }),
          key: 'landingVideo.description',
          type: 'textarea',
          mandatory: false,
          disabled: false,
          length: 1200,
        },
        {
          title: 'locale',
          key: 'locale',
          type: 'text',
          mandatory: true,
          disabled: true,
          initialValue: 'es-ES',
        },
        {
          title: 'active',
          key: 'active',
          type: 'boolean',
          mandatory: true,
          disabled: true,
          initialValue: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: false,
        canDelete: false,
      },
    },
  ];

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ScanLandingDashboard;
