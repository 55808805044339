import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const PermissionsDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'permissionspermissionsSearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idPermissions',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 12
                },
                // {
                //     key: 'optimisticLocking',
                //     yPosition: 1,
                //     xPosition: 4,
                //     mustRender: true,
                //     width: 6
                // },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 10
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'permissions.id' }),
          key: 'idPermissions',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'permissions.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: false,
          disabled: false,
          globalSearch: true
        },
        {
          title: formatMessage({ id: 'permissions.description' }),
          key: 'description',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false
        },
        // {
        //   title: 'Optimistic Locking',
        //   key: 'optimisticLocking',
        //   type: 'number',
        //   operand: 'like',
        //   min: 0,
        //   length: 0,
        //   mandatory: false,
        //   disabled: false
        // },
        {
          title: formatMessage({ id: 'permissions.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'permissionspermissionsList',
        rowKey: 'idPermissions',
        panelHeader: 'Permissions List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name'
      },
      fields: [
        {
          title: formatMessage({ id: 'permissions.id' }),
          key: 'idPermissions',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL
        },
        {
          title: formatMessage({ id: 'permissions.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID
        },
        {
          title: formatMessage({ id: 'permissions.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE
        },
        // {
        //      title: 'Optimistic Locking',
        //      key: 'optimisticLocking',
        //      position: 3,
        //      mustRender: true,
        //      align: 'left',
        //      sorter: false,
        //      visible: true,
        // },
        {
          title: formatMessage({ id: 'permissions.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'permissionspermissionsEdit',
        panelHeader: 'Permissions Edit',
        primaryKey: 'idPermissions',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12
                },
                // {
                //     key: 'optimisticLocking',
                //     yPosition: 1,
                //     xPosition: 3,
                //     mustRender: true,
                //     width: 6
                // },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'permissions.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 20,
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'permissions.description' }),
          key: 'description',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false
        },
        // {
        //     title: 'Optimistic Locking',
        //     key: 'optimisticLocking',
        //                 type: 'number',
        //     unique: false,
        //     min: 0,
        //     length: 0,
        //     mandatory: false,
        //     disabled: false,
        // },
        {
          title: formatMessage({ id: 'permissions.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          unique: false,
          min: 0,
          length: 0,
          mandatory: false,
          disabled: true,
          initialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'permissionspermissionsI18nList',
        rowKey: 'idPermI18n',
        panelHeader: 'Permissions I18n List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        navigationRoute: 'PermissionsI18nview',
        navigationId: 'idParent.idPermissions',
        tabIndex: 1,
        disabled: true
      },
      fields: [
        {
          title: 'Id Language',
          key: 'idLanguage',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Parent',
          key: 'idParent.name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Id Perm I18n',
          key: 'idPermI18n',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: 'Language',
          key: 'language',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'permissions.name' }),
          key: 'name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        // {
        //   title: 'Optimistic Locking',
        //   key: 'optimisticLocking',
        //   position: 5,
        //   mustRender: true,
        //   align: 'left',
        //   sorter: false,
        //   visible: true
        // },
        {
          title: formatMessage({ id: 'permissions.status' }),
          key: 'status',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    }
  ];

  const componentId = 'PermissionsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default PermissionsDashboard;
