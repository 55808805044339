import { Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, Fragment } from 'react';
import { useWebeatHomeSectionContext } from '../../../WebeatHomeSectionFields';
import Item from 'antd/lib/list/Item';
import { parseInt } from 'lodash';

interface ProductSelectorProps {
  fieldConfig: any;
  handleChange: (key: string, value: any) => void;
  constentState?: any;
  index: number;
}

const ProductSelectorItem: FC<ProductSelectorProps> = ({
  constentState,
  fieldConfig,
  handleChange,
  index,
}) => {
  const context = useWebeatHomeSectionContext();

  return (
    <Fragment>
      <Title level={4}>{'*Select Product type'}</Title>

      <Select
        showSearch
        filterOption={(input, option: any) => {
          const { children } = option.props.children.props;
          const [code, , description] = children;
          const optionValue = description.toString().toLowerCase();
          const inputValue = input.toLowerCase();

          if (!isNaN(parseInt(input))) {
              const optionValueWithCode = `${code} : ${description}`.toLowerCase();
              return optionValueWithCode.startsWith(inputValue);
          }

          return optionValue.startsWith(inputValue);
        }}
        className="webeat-home-section-hero-block"
        dropdownMatchSelectWidth={false}
        value={
          constentState[fieldConfig.key][index]['name'] || 'Select Product'
        }
        loading={!context || !context.combos || !context.combos.products}
        placeholder={'Select Product'}
        onSelect={(value: any) => {
          handleChange(
            fieldConfig.key,
            constentState[fieldConfig.key].map((item: Item, idx: number) =>
              idx === index
                ? {
                    ...item,
                    id: value,
                    name: context.combos.products?.filter(
                      (product: any) => product.id === value,
                    )[0]['name' as any],
                    pageUrl: context.combos.products?.filter(
                      (product: any) => product.id === value,
                    )[0]['url' as any],
                  }
                : item,
            ),
          );
        }}>
        {context.combos.products?.map((option: any) => (
          <Select.Option
            key={option.id.toString()}
            value={option.id.toString()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {option.id} : {option.name}
            </div>
          </Select.Option>
        ))}
      </Select>
    </Fragment>
  );
};

export default ProductSelectorItem;
