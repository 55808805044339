import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ChallengeTypeDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'challengeTypechallengeTypeSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                // {
                //   key: 'idChallengeType',
                //   yPosition: 1,
                //   xPosition: 1,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //   key: 'name',
                //   yPosition: 1,
                //   xPosition: 2,
                //   mustRender: true,
                //   width: 12
                // },
                // {
                //     key: 'optimisticLocking',
                //     yPosition: 1,
                //     xPosition: 3,
                //     mustRender: true,
                //     width: 6
                // },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeType.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        // {
        //     title: 'Optimistic Locking',
        //     key: 'optimisticLocking',
        //                 type: 'number',
        //     operand: 'like',
        //     min: 0,
        //     length: 0,
        //     mandatory: false,
        //     disabled: false,
        // },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'challengeTypechallengeTypeList',
        rowKey: 'idChallengeType',
        panelHeader: 'Challenge Type List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challengeType.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Description',
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'challengeTypechallengeTypeEdit',
        panelHeader: 'Challenge Type Edit',
        primaryKey: 'idChallengeType',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'textMachine',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'challengeType.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'label.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeType.description' }),
          key: 'description',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeType.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idChallengeType',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    //m2m start

    {
      params: {
        type: 'table',
        componentId: 'challengetypeResourcetypeList',
        rowKey: 'idResourceType',
        panelHeader: 'Resource Type List',
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        tabIndex: 1,
        navigationId: 'idChallengeType.idChallengeType',
      },
      fields: [
        {
          title: formatMessage({ id: 'resourceType.id' }),
          key: 'idResourceType',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'resourceType.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'resourceType.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'resourceType.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
      m2m: {
        dashboardId: 'M2MChallengeResourceTypeRestrictionDashboard',
        path: '/challengeResourceType',
        primaryKey: 'idCrType',
        joinKey: 'idChallengeType.idChallengeType',
        joinForeignKey: 'idResourceType.idResourceType',
        components: [
          {
            params: {
              type: 'search',
              componentId: 'm2mchallentetyperesourcetypeSearch',
              panelHeader: 'Permissions Search ---',
              panelKey: 'm2mrolespermissionsSearch',
              navigationParams: props.query.params.q || '',
              isTabItem: false,
            },
            groups: [
              {
                index: 0,
                title: '',
                mustRender: true,
                subgroups: [
                  {
                    index: 0,
                    title: '',
                    mustRender: true,
                    yPosition: 0,
                    xPosition: 0,
                    width: 24,
                    fields: [
                      {
                        key: 'status',
                        mustRender: true,
                        yPosition: 0,
                        xPosition: 0,
                        width: 12,
                      },
                    ],
                  },
                ],
              },
            ],
            fields: [
              {
                title: formatMessage({ id: 'permissions.status' }),
                key: 'status',
                type: 'comboCustom',
                comboId: 'status',
                operand: 'equal',
                min: 0,
                length: 0,
                mandatory: false,
                disabled: false,
                initialValue: 'true',
              },
              {
                title: formatMessage({ id: 'permissions.name' }),
                key: 'name',
                type: 'text',
                operand: 'like',
                min: 0,
                validationType: 'text',
                length: 20,
                mandatory: false,
                disabled: false,
                globalSearch: true,
              },
            ],
            settings: {
              fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
              fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
            },
          },
          {
            params: {
              type: 'table',
              componentId: 'm2mchallentetyperesourcetypeList',
              rowKey: 'idResourceType',
              panelHeader: 'Resouce type List',
              panelKey: 'm2mrolespermissionsList',
              isMainTable: true,
              navigationParams: props.query.params.q || '',
            },
            fields: [
              {
                title: formatMessage({ id: 'resourceType.id' }),
                key: 'idResourceType',
                position: 0,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                width: SMALL,
              },
              {
                title: formatMessage({ id: 'resourceType.name' }),
                key: 'name',
                position: 1,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: MID,
              },
              {
                title: formatMessage({ id: 'resourceType.description' }),
                key: 'description',
                position: 2,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: LARGE,
              },
              {
                title: formatMessage({ id: 'resourceType.status' }),
                key: 'status',
                render: 'comboCustom',
                comboId: 'status',
                position: 3,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                width: SMALL,
              },
            ],
            settings: {
              size: config.COMPONENT.TABLE.SIZE,
              bordered: config.COMPONENT.TABLE.BORDERED,
              editable: false,
              multiple: true,
              canCreate: false,
              canDelete: false,
              scroll: { x: true },
            },
            pagination: {
              defaultPageSize: 50,
              pageSizeOptions: ['5', '10', '20', '50'],
              showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
              showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
              showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
              size: config.COMPONENT.TABLE.SIZE,
            },
          },
        ],
      },
    },
    //m2m end
    // {
    //   params: {
    //     type: 'table',
    //     componentId: 'challengeTypechallengeList',
    //     rowKey: 'idChallenge',
    //     panelHeader: 'Challenge List',
    //     navigationParams: props.query.params.q || '',
    //     isMainTable: false,
    //     navigationRoute: 'Challengeview',
    //     navigationId: 'idChallengeType.idChallengeType',
    //     tabIndex: 2
    //   },
    //   fields: [
    //     {
    //       title: formatMessage({ id: 'challenge.id' }),
    //       key: 'idChallenge',
    //       position: 0,
    //       align: 'left',
    //       sorter: false,
    //       visible: false,
    //       width: SMALL
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.id' }),
    //       key: 'idChallengeType.name',
    //       position: 1,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: MID
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.name' }),
    //       key: 'name',
    //       position: 2,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: LARGE
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.start_date' }),
    //       key: 'startDate',
    //       position: 3,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: MID
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.start_vis_date' }),
    //       key: 'startVisDate',
    //       position: 4,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: MID
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.end_date' }),
    //       key: 'endDate',
    //       position: 5,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: MID
    //     },
    //     {
    //       title: formatMessage({ id: 'challengeType.end_vis_date' }),
    //       key: 'endVisDate',
    //       position: 6,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: MID
    //     },
    //     // {
    //     //      title: 'Optimistic Locking',
    //     //      key: 'optimisticLocking',
    //     //      position: 5,
    //     //      mustRender: true,
    //     //      align: 'left',
    //     //      sorter: false,
    //     //      visible: true,
    //     // },
    //     {
    //       title: formatMessage({ id: 'challengeType.status' }),
    //       key: 'status',
    //       render: 'comboCustom',
    //       comboId: 'status',
    //       position: 7,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true,
    //       width: SMALL
    //     }
    //   ],
    //   settings: {
    //     size: config.COMPONENT.TABLE.SIZE,
    //     bordered: config.COMPONENT.TABLE.BORDERED,
    //     editable: true,
    //     multiple: false,
    //     canCreate: true,
    //     canDelete: false,
    //     scroll: { x: true }
    //   },
    //   pagination: {
    //     defaultPageSize: 50,
    //     pageSizeOptions: ['5', '10', '20', '50'],
    //     showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
    //     showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
    //     showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
    //     size: config.COMPONENT.TABLE.SIZE
    //   }
    // },
    // {
    //   params: {
    //     type: 'table',
    //     componentId: 'challengeTypechallengeTypeI18nList',
    //     rowKey: 'idChTI18n',
    //     panelHeader: 'Challenge Type I18n List',
    //     navigationParams: props.query.params.q || '',
    //     isMainTable: false,
    //     navigationRoute: 'ChallengeTypeI18nview',
    //     navigationId: 'idParent.idChallengeType',
    //     tabIndex: 3,
    //     disabled: true
    //   },
    //   fields: [
    //     {
    //       title: 'Id Ch TI18n',
    //       key: 'idChTI18n',
    //       position: 0,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     },
    //     {
    //       title: 'Id Language',
    //       key: 'idLanguage',
    //       position: 1,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     },
    //     {
    //       title: 'Id Parent',
    //       key: 'idParent.name',
    //       position: 2,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     },
    //     {
    //       title: 'Language',
    //       key: 'language',
    //       position: 3,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     },
    //     {
    //       title: 'Name',
    //       key: 'name',
    //       position: 4,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     },
    //     // {
    //     //      title: 'Optimistic Locking',
    //     //      key: 'optimisticLocking',
    //     //      position: 5,
    //     //      mustRender: true,
    //     //      align: 'left',
    //     //      sorter: false,
    //     //      visible: true,
    //     // },
    //     {
    //       title: 'Status',
    //       key: 'status',
    //       position: 6,
    //       mustRender: true,
    //       align: 'left',
    //       sorter: false,
    //       visible: true
    //     }
    //   ],
    //   settings: {
    //     size: config.COMPONENT.TABLE.SIZE,
    //     bordered: config.COMPONENT.TABLE.BORDERED,
    //     editable: true,
    //     multiple: false,
    //     canCreate: true,
    //     canDelete: false,
    //     scroll: { x: true }
    //   },
    //   pagination: {
    //     defaultPageSize: 50,
    //     pageSizeOptions: ['5', '10', '20', '50'],
    //     showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
    //     showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
    //     showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
    //     size: config.COMPONENT.TABLE.SIZE
    //   }
    // }
  ];

  const componentId = 'ChallengeTypeDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ChallengeTypeDashboard;
