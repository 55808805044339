import { Button, Modal, Row, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ComboData } from '../../../combos/ComboInterfaces';
import { behaviorCombo } from '../../../combosCustom';
import { IResource } from '../../ChallengeInterfaces';
import WebeatDependencyModalController from './DependencyModal.controller';

export interface IDependencyModal {
  idChallenge: string;
  resource: IResource;
  challengeResources: IResource[];
  dependencyModalVisibility?: boolean;
  refreshChallengeData: () => Promise<void>;
  setDependencyModalVisibility: (visibility: boolean) => void;
}

export default function WebeatAddDependencyModal({
  idChallenge,
  resource,
  challengeResources,
  dependencyModalVisibility,
  refreshChallengeData,
  setDependencyModalVisibility,
}: IDependencyModal) {
  const { formatMessage } = useIntl();
  const controller = WebeatDependencyModalController({
    idChallenge,
    resource,
    challengeResources,
    dependencyModalVisibility,
    refreshChallengeData,
    setDependencyModalVisibility,
  });

  const {
    availableResources,
    dependenciesList,
    handleClose,
    handleRemoveDependency,
    handleSelectBehavior,
    handleSelectMultiParent,
    handleSelectMultiParentStatus,
    handleSubmit,
    selectedBehavior,
    setDependenciesList,
  } = controller;

  return (
    <Modal
      visible={dependencyModalVisibility}
      onCancel={handleClose}
      onOk={handleSubmit}>
      <Row style={{ marginBottom: '60px', fontSize: '1.6rem' }}>
        {formatMessage({ id: 'set_dependency' })}
      </Row>
      <Button
        onClick={() => {
          setDependenciesList((current) => [
            ...current,
            {
              idResource: resource.idResource,
              idDependency: null,
              trigger: null,
            },
          ]);
        }}
        style={{ marginBottom: '20px' }}>
        {formatMessage({ id: 'add_resource_dependency' })}
      </Button>
      {dependenciesList?.length > 0 && (
        <Select
          value={selectedBehavior?.toString()}
          onSelect={(value: any) => handleSelectBehavior(value)}
          style={{ float: 'right', width: '200px' }}>
          {behaviorCombo.map(({ value, description }) => (
            <Select.Option value={value.toString()} key={value.toString()}>
              {description}
            </Select.Option>
          ))}
        </Select>
      )}
      {
        // TODO: handle id's
      }
      {dependenciesList?.map((dependency, index) => (
        <Row style={{ minHeight: '20vh' }} key={index}>
          {formatMessage({ id: 'parent_resource' })}
          <Select
            allowClear
            value={dependency.idDependency?.toString()}
            onSelect={(value: string) => {
              handleSelectMultiParent(value, index);
            }}>
            {availableResources.map(
              ({
                idResource,
                name,
                idResourceType: { name: typeName },
              }: IResource) => (
                <Select.Option
                  value={idResource.toString()}
                  key={idResource.toString()}
                  disabled={Boolean(
                    dependenciesList.find(
                      ({ idDependency: dependencyResource }) =>
                        dependencyResource === idResource,
                    ),
                  )}>
                  {typeName} : {_.capitalize(name)}
                </Select.Option>
              ),
            )}
          </Select>
          {formatMessage({ id: 'parent_status' })}
          <Select
            disabled={!Boolean(dependenciesList[index].idDependency)}
            value={dependenciesList[index].trigger?.toString()}
            onSelect={(value: string) => {
              handleSelectMultiParentStatus(value, index);
            }}>
            {dependency.options?.map((parent: ComboData) => (
              <Select.Option value={parent.value.toString()} key={parent.value}>
                {parent.description}
              </Select.Option>
            ))}
          </Select>
          <Button
            block
            type="danger"
            icon="delete-outlined"
            onClick={() => handleRemoveDependency(index)}
            style={{ marginTop: '10px', marginBottom: '40px' }}>
            {formatMessage({ id: 'remove_resource_dependency' })}
          </Button>
        </Row>
      ))}
    </Modal>
  );
}
