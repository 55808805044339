import React, { FC } from 'react';
import { Table, Icon } from 'antd';

interface Props {
  dataSource: { [key: string]: any }[];
  handleEdit: (record: any) => void;
}

const AddCentersTable: FC<Props> = ({
  dataSource,
  handleEdit
}): JSX.Element => {
  const columns = [
    {
      title: 'CENTER',
      dataIndex: 'idCenter.name',
      key: 'idCenter.idCenter'
    },
    {
      title: 'ROLE',
      dataIndex: 'idRole.name',
      key: 'idRole.idRole'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => {
        return <div>{record.status ? 'Active' : 'Deleted'}</div>;
      }
    },
    {
      title: '',
      key: 'action',
      render: (text: any, record: any) => (
        <div
          className="assignCenter__editButton"
          onClick={() => handleEdit(record)}>
          <Icon type="edit" className="assignCenter__editButton--icon" />
        </div>
      )
    }
  ];

  return <Table dataSource={dataSource} columns={columns} />;
};

export default AddCentersTable;
