import { m2mTypes } from './m2mTypes';
import * as api from '../api';
import config from '../config';
import { QueryParams } from '../tables/tableInterfaces';
import { Dispatch } from 'redux';
import { ReducersState } from '../reducers';
import { TableData } from '../tables/tableInterfaces';
import { IRow } from '../app/AppInterfaces';
const unflatten = require('flat').unflatten;

export interface IObject {
  [key: string]: string | number | IObject;
}

// export const setInitialState = ({
//   searchFields = [],
//   tableFields = [],
//   queryParams,
//   parentQueryParams
// }) => dispatch => {
//   const sortedTableFields = tableFields
//     .filter(c => {
//       return c.mustRender;
//     })
//     .sort((a, b) => {
//       return a.position - b.position;
//     })
//     .map((c, i) => {

//       delete c.onHeaderCell;
//       return (c = {
//         ...c,
//         position: i
//       });
//     });

//   const mainKey = parentQueryParams.q.split(config.QUERY.ID_OPERATOR)[0];
//   const mainKeyValue = Number(parentQueryParams.q.split(config.QUERY.ID_OPERATOR)[1]);

//   dispatch({
//     type: m2mTypes.M2M_SET_INITIAL_STATE,
//     payload: {
//       searchFields,
//       tableFields: sortedTableFields,
//       queryParams,
//       mainKey,
//       mainKeyValue
//     }
//   });
// };

interface SetGetSelected {
  type: m2mTypes.M2M_GET_SELECTED_ROWS;
  payload: { getSelected: boolean };
}

export const setGetSelected =
  ({ getSelected }: { getSelected: boolean }) =>
  (dispatch: Dispatch<SetGetSelected>) => {
    dispatch({
      type: m2mTypes.M2M_GET_SELECTED_ROWS,
      payload: { getSelected },
    });
  };

interface SetSelectedM2MDashboard {
  type: m2mTypes.M2M_SET_ACTIVE_DASHBOARD;
  payload: { activeDashboard: string };
}

export const setSelectedM2MDashboard =
  ({ activeDashboard }: { activeDashboard: string }) =>
  (dispatch: Dispatch<SetSelectedM2MDashboard>) => {
    dispatch({
      type: m2mTypes.M2M_SET_ACTIVE_DASHBOARD,
      payload: { activeDashboard },
    });
  };

interface AddingM2MRecord {
  type: m2mTypes.M2M_ADDING_RECORD;
  payload: { isLoading: boolean };
}

interface AddedM2MRecord {
  type: m2mTypes.M2M_ADDED_RECORD;
  payload: { isLoading: boolean };
}

export const addM2Mrecord =
  ({
    dataPath,
    foreignKey,
    record,
    mainKeyValue,
    joinForeignKey,
    joinKey,
  }: {
    dataPath: string;
    foreignKey: string;
    record: { [key: string]: string | number };
    mainKeyValue: string;
    joinForeignKey: string;
    joinKey: string;
  }) =>
  async (dispatch: Dispatch<AddingM2MRecord | AddedM2MRecord>) => {
    dispatch({
      type: m2mTypes.M2M_ADDING_RECORD,
      payload: { isLoading: true },
    });

    try {
      const data = unflatten({
        [joinKey]: mainKeyValue,
        [joinForeignKey]: record[foreignKey],
        swCheckWarning: false,
        status: true,
      });

      const response = await api.postDataCall({
        dataPath,
        data,
      });

      if (response.data) {
        dispatch({
          type: m2mTypes.M2M_ADDED_RECORD,
          payload: { isLoading: false },
        });
      }
      const status = { action: 'create', status: response.status };
      return status;
    } catch (err) {
      if (!err.response) return { action: 'create', status: {} };
      const status = {
        action: 'create',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

interface RemovingM2MRecord {
  type: m2mTypes.M2M_REMOVING_RECORD;
  payload: { isLoading: boolean };
}

interface RemovedM2MRecord {
  type: m2mTypes.M2M_REMOVED_RECORD;
  payload: { isLoading: boolean };
}

export const removeM2Mrecord =
  ({
    dataPath,
    record,
    m2mPrimaryKey,
  }: {
    dataPath: string;
    record: { [key: string]: string | number };
    m2mPrimaryKey: string;
  }) =>
  async (dispatch: Dispatch<RemovingM2MRecord | RemovedM2MRecord>) => {
    dispatch({
      type: m2mTypes.M2M_REMOVING_RECORD,
      payload: { isLoading: true },
    });
    
    try {
      const response = await api.deleteDataCallById({
        dataPath,
        registerId: record[m2mPrimaryKey],
        callConfig: {
          data: record,
        },
      });

      dispatch({
        type: m2mTypes.M2M_REMOVED_RECORD,
        payload: { isLoading: false },
      });

      const status = { action: 'delete', status: response.status };
      return status;
    } catch (err) {
      if (!err.response) return { action: 'delete', status: {} };
      const status = {
        action: 'delete',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

interface SetM2MTableParams {
  type: m2mTypes.M2M_SET_TABLE_PARAMS;
  payload: Partial<QueryParams>;
}

export const setM2MTableParams =
  (queryParams: Partial<QueryParams>) =>
  (dispatch: Dispatch<SetM2MTableParams>) => {
    dispatch({
      type: m2mTypes.M2M_SET_TABLE_PARAMS,
      payload: { ...queryParams },
    });
  };

export interface IM2MPathExtended {
  key: string;
  value: string;
}
interface SetM2MPathExtended {
  type: m2mTypes.M2M_SET_PATH_EXTENDED;
  payload: Partial<QueryParams>;
}

export const setM2MPathExtended =
  (pathExtended: IM2MPathExtended) =>
  (dispatch: Dispatch<SetM2MPathExtended>) => {
    dispatch({
      type: m2mTypes.M2M_SET_PATH_EXTENDED,
      payload: { ...pathExtended },
    });
  };

interface FetchingM2MTableData {
  type: m2mTypes.M2M_FETCHING_RECORDS;
  payload: { queryParams: QueryParams; isLoading: boolean };
}

interface FetchedM2MTableData {
  type: m2mTypes.M2M_FETCHED_RECORDS;
  payload: {
    data: TableData;
    selectedRecordsId: number[];
    isLoading: boolean;
  };
}

interface FetchM2MParams {
  q: string;
  size: number;
}

export const getM2MTableData =
  ({
    dataPath,
    m2mDataPath,
    queryParams,
    primaryKey,
    joinKey,
    mainKeyValue,
    foreignKey,
    getSelected,
    navigationId,
  }: {
    dataPath: string;
    m2mDataPath: string;
    queryParams: QueryParams;
    primaryKey: string;
    joinKey: string;
    mainKeyValue: string;
    foreignKey: string;
    getSelected: boolean;
    navigationId: string;
  }) =>
  async (
    dispatch: Dispatch<FetchingM2MTableData | FetchedM2MTableData>,
    getState: () => ReducersState,
  ) => {
    let nextParams: QueryParams = {};
    let filteredRecordsArray: IRow[] = [];
    const currentParams = getState().m2m.queryParams;
    let data;
    let selectedRecordsId: number[] = [];

    if (queryParams.q) {
      queryParams.size
        ? (nextParams = queryParams)
        : (nextParams = { q: queryParams.q, size: currentParams.size });
      if (!(queryParams.field && queryParams.sort))
        nextParams = {
          ...nextParams,
          sort:
            currentParams.sort === 'descend' || currentParams.sort === 'DESC'
              ? 'DESC'
              : 'ASC',
          field: currentParams.field,
        };
    } else {
      nextParams = {
        ...currentParams,
        ...queryParams,
      };
    }

    dispatch({
      type: m2mTypes.M2M_FETCHING_RECORDS,
      payload: {
        queryParams: { ...currentParams, ...nextParams },
        isLoading: true,
      },
    });

    if (getSelected) {
      if (nextParams.q)
        nextParams.q +=
          config.QUERY.AND +
          navigationId +
          config.QUERY.ID_OPERATOR +
          mainKeyValue;
      else
        nextParams.q = navigationId + config.QUERY.ID_OPERATOR + mainKeyValue;
    }

    if (getState().m2m.getSelected !== getSelected) nextParams.page = 0;
    try {
      const callConfig = {
        params: nextParams,
      };

      //1. Get outher DATA
      const outherResponse = await api.getDataCall({
        dataPath: dataPath,
        callConfig,
      });

      let outherContent = outherResponse.data.content;

      try {
        const params: FetchM2MParams = {
          q: `${joinKey}${config.QUERY.ID_OPERATOR}${mainKeyValue}`,
          size: 500,
        };

        //2. Get M2M DATA
        const m2mResponse = await api.getDataCall({
          dataPath: m2mDataPath,
          callConfig: { params },
        });

        const m2mContent = m2mResponse.data.content;

        if (m2mResponse.data && !getSelected) {
          outherContent.forEach((entityRecord: any) => {
            let entity = { ...entityRecord };
            m2mContent.forEach((m2mRecord: any) => {
              if (
                m2mRecord[foreignKey][foreignKey] ===
                  entityRecord[foreignKey] ||
                m2mRecord[foreignKey] === entityRecord[foreignKey]
              ) {
                entity = {
                  ...entityRecord,
                  [primaryKey]: m2mRecord[primaryKey],
                  optimisticLocking: m2mRecord['optimisticLocking'],
                };
                selectedRecordsId.push(entityRecord[foreignKey]);
              }
            });
            filteredRecordsArray.push({ ...entity });
          });
          data = {
            ...outherResponse.data,
            content: filteredRecordsArray,
          };
          dispatch({
            type: m2mTypes.M2M_FETCHED_RECORDS,
            payload: {
              data,
              selectedRecordsId: selectedRecordsId,
              isLoading: false,
            },
          });
        } else if (m2mResponse.data && getSelected) {
          outherContent.forEach((entityRecord: any) => {
            m2mContent.forEach((m2mRecord: any) => {
              if (
                m2mRecord[foreignKey][foreignKey] === entityRecord[foreignKey]
              ) {
                entityRecord[primaryKey] = m2mRecord[primaryKey];
                entityRecord.optimisticLocking = m2mRecord['optimisticLocking'];
              }
            });
            selectedRecordsId.push(entityRecord[foreignKey]);
          });
          data = {
            ...outherResponse.data,
            content: outherContent,
          };
          dispatch({
            type: m2mTypes.M2M_FETCHED_RECORDS,
            payload: {
              data,
              selectedRecordsId,
              isLoading: false,
            },
          });
        }
        const status = { action: 'fetch', status: m2mResponse.status };
        return status;
      } catch (err) {
        if (!err.m2mResponse) return { action: 'fetch', status: {} };
        const status = {
          action: 'fetch',
          status: err.m2mResponse.status,
          message: err.m2mResponse.data.message,
        };
        return status;
      }
    } catch (err) {
      if (!err.outherResponse) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.outherResponse.status,
        message: err.outherResponse.data.message,
      };
      return status;
    }
  };

interface SetM2MModalVisibility {
  type: m2mTypes.M2M_SET_MODAL_VISIBILITY;
  payload: { visible: boolean };
}

export const setM2MModalVisibility =
  (isVisible: boolean) => (dispatch: Dispatch<SetM2MModalVisibility>) => {
    dispatch({
      type: m2mTypes.M2M_SET_MODAL_VISIBILITY,
      payload: { visible: isVisible },
    });
  };

interface ResetM2M {
  type: m2mTypes.M2M_RESET;
}

export const resetM2M = () => (dispatch: Dispatch<ResetM2M>) => {
  dispatch({
    type: m2mTypes.M2M_RESET,
  });
};

export type M2MActionTypes =
  | SetM2MModalVisibility
  | ResetM2M
  | SetM2MTableParams
  | SetGetSelected
  | SetSelectedM2MDashboard
  | AddingM2MRecord
  | AddedM2MRecord
  | RemovingM2MRecord
  | RemovedM2MRecord
  | FetchingM2MTableData
  | FetchedM2MTableData
  | SetM2MPathExtended;
