import { Button, Col, Modal, Row } from 'antd';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';
import { EditFormRenderProps } from '../../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../../tables/TableRender';
import { isTableProps } from '../../../utils';
import LandingBulkEditFormWithProps from './LandingBulkEditForm';
import {
  ICreateMassiveLandingProduct,
  ILandingProduct,
} from './ProductLandingInterfaces';

const BulkEditLandingInfo = <
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(
  parentProps: T,
) => {
  const { formatMessage } = useIntl();

  const [disabled, setDisabled] = useState(false);
  const [landingProduct, setLandingProduct] = useState<ILandingProduct>();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);

  if (!isTableProps(parentProps)) return <Fragment />;
  const tableProps: ITableRenderListProps = parentProps;

  const { isLoading, selectedRowKeys, token } = tableProps.props;

  const handleModalClose = () => {
    handleModalToggle(false);
  };

  const handleModalOpen = () => {
    handleModalToggle(true);
  };

  const handleModalToggle = (toggle: boolean) => {
    setDisabled(!toggle);
    setValidated(!toggle);
    setVisible(toggle);
  };

  const handleModalSubmit = () => {
    saveData()
      .then(() =>
        setTimeout(() => {
          handleModalClose();
        }, 600),
      )
      .catch((err: any) => console.error(err))
      .finally(() => setLoading(false));
  };

  const saveData = () => {
    setLoading(true);
    const data: ICreateMassiveLandingProduct = {
      productArr: (selectedRowKeys as (string | number)[]).map((row) =>
        typeof row === 'string' ? parseInt(row) : row,
      ),
      productContent: { ...landingProduct },
    };
    return api.postDataCall({
      dataPath: apiPaths.CALL.LANDING_PRODUCT_MASSIVE,
      data: data,
      callConfig: {},
    });
  };

  const getModalFooter = () => {
    return (
      <Row>
        <Button
          type="danger"
          htmlType="submit"
          onClick={handleModalClose}
          loading={loading}>
          {formatMessage({ id: 'form.edit.cancel' })}
        </Button>
        <Button
          type="primary"
          disabled={!validated}
          onClick={handleModalSubmit}
          loading={loading}>
          {formatMessage({
            id: 'form.edit.submit',
          })}
        </Button>
      </Row>
    );
  };

  return (
    <>
      <Button
        disabled={isLoading || loading || !selectedRowKeys?.length}
        icon="form"
        onClick={() => handleModalOpen()}
        type="primary">
        {formatMessage({
          id: 'custom-buttons.edit-landing-info.bulk-edit',
        })}
      </Button>
      <Modal
        className="request-order-modal"
        closable={false}
        footer={getModalFooter()}
        onCancel={handleModalClose}
        onOk={handleModalClose}
        visible={visible}>
        <Row type="flex" align="middle">
          <Col xs={24}>
            <div className="request-order-modal__title">
              {formatMessage({
                id: 'qrproduct.dashboard.landing.bulk-edit.title',
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <LandingBulkEditFormWithProps
              disabled={disabled}
              landingProduct={landingProduct}
              loading={loading}
              setValidated={setValidated}
              setLandingProduct={setLandingProduct}
              token={token}
              visible={visible}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BulkEditLandingInfo;
