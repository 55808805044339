import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const DisclaimersDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'disclaimersSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'disclaimer.title' }),
          key: 'title',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          disabled: true,
          mandatory: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'disclaimersList',
        rowKey: 'idDisclaimer',
        panelHeader: formatMessage({ id: 'disclaimer.list' }),
        navigationParams: props.query.params.q || '',
        prettierKey: 'title',
        isMainTable: true,
        tableType: 0,
      },
      fields: [
        {
          title: formatMessage({ id: 'disclaimer.id' }),
          key: 'idDisclaimer',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'disclaimer.title' }),
          key: 'title',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          defaultSortOrder: 'ascend',
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'disclaimer.message' }),
          key: 'message',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'disclaimer.hereditary' }),
          key: 'applyToChilds',
          type: 'comboCustom',
          comboId: 'optional',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          render: 'comboCustom',
          comboId: 'status',
          width: MID,
        },
        {
          title: formatMessage({ id: 'Disclaimer Type' }),
          key: 'disclaimerType',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          type: 'combo',
          comboId: 'disclaimerType',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'disclaimersEdit',
        panelHeader: formatMessage({ id: 'disclaimer.edit' }),
        primaryKey: 'idDisclaimer',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idDisclaimer',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
                {
                  key: 'title',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'disclaimerType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'disclaimerFields',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'specialties',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'roleTypes',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'message',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'applyToChilds',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'idDisclaimer' }),
          key: 'idDisclaimer',
          type: 'number',
        },
        {
          title: 'Specialty',
          key: 'specialty',
          type: 'combo',
          comboId: 'specialty',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'disclaimer.disclaimerTitle' }),
          key: 'title',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 300,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'disclaimer.disclaimerType' }),
          key: 'disclaimerType',
          type: 'combo',
          comboId: 'disclaimerType',
          mandatory: true,
          initialDisabled: false,
          behaviours: [
            [
              {
                key: 'idDisclaimer',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'disclaimer.message' }),
          key: 'message',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'disclaimerType',
                value: 'operational',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          key: 'disclaimerFields',
          type: 'customField',
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'disclaimerType',
                value: 'commercial',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: 'Specialties',
          key: 'specialties',
          type: 'combo',
          comboId: 'specialty',
          multiSelect: true,
          multiSelectId: 'idSpecialty',
          disabled: false,
          initialVisibility: false,
          tooltip: formatMessage({
            id: 'disclaimer.commercial-specialties.tooltip',
          }),
          behaviours: [
            [
              {
                key: 'disclaimerType',
                value: 'commercial',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: 'RoleType',
          key: 'roleTypes',
          type: 'combo',
          comboId: 'roleType',
          disabled: false,
          initialVisibility: false,
          multiSelect: true,
          multiSelectId: 'idRoleType',
          behaviours: [
            [
              {
                key: 'disclaimerType',
                value: 'commercial',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'disclaimer.message' }),
          key: 'message',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'disclaimer.hereditary' }),
          key: 'applyToChilds',
          type: 'comboCustom',
          comboId: 'optional',
          unique: false,
          initialValue: 'false',
          mandatory: true,
          disabled: false,
          initialVisibility: false,
          behaviours: [
            [
              {
                key: 'disclaimerType',
                value: 'operational',
                operand: 'like',
                type: 'visible',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'disclaimersUrlsList',
        rowKey: 'slugUUID',
        panelHeader: formatMessage({ id: 'disclaimer.pages' }),
        navigationParams: props.query.params.q || '',
        isMainTable: false,
        tabIndex: 1,
        navigationId: 'showOnlyAssignedToDisclaimer',
        customButtons: ['disclaimerSpecialtiesAndRolesSelect'],
      },
      fields: [
        {
          title: formatMessage({ id: 'disclaimersUrlsList.slugUUID' }),
          key: 'slugUUID',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'disclaimersUrlsList.name' }),
          key: 'slugName',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'disclaimersUrlsList.slug' }),
          key: 'slug',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'disclaimersUrlsList.specialties' }),
          key: 'associatedSpecialties',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'disclaimersUrlsList.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
      m2m: {
        dashboardId: 'M2MPagesDisclaimerDashboard',
        path: '/disclaimer-url',
        pathExtended:
          '/disclaimer-url/with-specialty/{{associate-with-specialty}}',
        primaryKey: 'idDisclaimerURL',
        joinKey: 'idDisclaimer',
        joinForeignKey: 'slugUUID',
        editButtonTitle: 'disclaimers-url--button-specialty--all',
        components: [
          {
            params: {
              type: 'search',
              componentId: 'm2mpagesDisclaimersSearch',
              panelHeader: 'Pages Search ---',
              panelKey: 'm2mpagesDisclaimersSearch',
              navigationParams: props.query.params.q || '',
              isTabItem: false,
            },
            groups: [
              {
                index: 0,
                title: '',
                mustRender: true,
                subgroups: [
                  {
                    index: 0,
                    title: '',
                    mustRender: true,
                    yPosition: 0,
                    xPosition: 0,
                    width: 24,
                    fields: [
                      {
                        key: 'status',
                        mustRender: true,
                        yPosition: 0,
                        xPosition: 0,
                        width: 12,
                      },
                    ],
                  },
                ],
              },
            ],
            fields: [
              {
                title: formatMessage({ id: 'pageDisclaimers.status' }),
                key: 'status',
                type: 'comboCustom',
                comboId: 'status',
                operand: 'equal',
                min: 0,
                length: 0,
                mandatory: false,
                disabled: false,
                initialValue: 'true',
              },
              {
                title: formatMessage({ id: 'disclaimersUrlsList.name' }),
                key: 'slugName',
                type: 'text',
                operand: 'like',
                min: 0,
                validationType: 'text',
                length: 20,
                mandatory: false,
                disabled: false,
                globalSearch: true,
              },
            ],
            settings: {
              fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
              fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
            },
          },
          {
            params: {
              type: 'table',
              componentId: 'm2mpagesDisclaimersList',
              rowKey: 'slugUUID',
              panelHeader: 'Page Enabled List --',
              panelKey: 'm2mpagesDisclaimersList',
              isMainTable: true,
              navigationParams: props.query.params.q || '',
            },
            fields: [
              {
                title: formatMessage({ id: 'disclaimersUrlsList.slugUUID' }),
                key: 'slugUUID',
                position: 0,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: false,
                width: SMALL,
              },
              {
                title: formatMessage({ id: 'disclaimersUrlsList.name' }),
                key: 'slugName',
                position: 1,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: SMALL,
              },
              {
                title: formatMessage({ id: 'disclaimersUrlsList.slug' }),
                key: 'slug',
                position: 2,
                mustRender: true,
                align: 'left',
                sorter: false,
                visible: true,
                width: LARGE,
              },
            ],
            settings: {
              size: config.COMPONENT.TABLE.SIZE,
              bordered: config.COMPONENT.TABLE.BORDERED,
              editable: false,
              multiple: true,
              canCreate: false,
              canDelete: false,
              scroll: { x: true },
            },
            pagination: {
              defaultPageSize: 50,
              pageSizeOptions: ['5', '10', '20', '50'],
              showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
              showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
              showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
              size: config.COMPONENT.TABLE.SIZE,
            },
          },
        ],
      },
    },
  ];

  const componentId = 'DisclaimersDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default DisclaimersDashboard;
