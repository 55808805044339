import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ConsumableLandingDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const componentId = 'ConsumableLanding';

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'consumableLandingSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id_landing',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({
            id: 'consumableLanding.title',
          }),
          key: 'title',
          type: 'text',
          operand: 'like',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'consumableLanding.id' }),
          key: 'id_landing',
          type: 'text',
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'consumableLandingList',
        rowKey: 'id',
        panelHeader: formatMessage({ id: 'consumableLanding.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'title',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: formatMessage({ id: 'consumableLanding.id' }),
          key: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumableLanding.title' }),
          key: 'title',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'consumableLanding.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'consumableLandingEdit',
        panelHeader: formatMessage({ id: 'consumableLanding.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'title',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'images.main',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'images.desktop',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'landingVideo.url',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'landingVideo.thumbnail',
                  yPosition: 5,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'landingVideo.title',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'landingVideo.description',
                  yPosition: 7,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'locale',
                  yPosition: 8,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'active',
                  yPosition: 9,
                  xPosition: 0,
                  mustRender: false,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'consumableLanding.id' }),
          key: 'id',
          type: 'text',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'consumableLanding.title' }),
          key: 'title',
          type: 'text',
          mandatory: true,
        },
        {
          title: formatMessage({ id: 'consumableLanding.description' }),
          key: 'description',
          type: 'textarea',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'consumableLanding.image.hero' }),
          key: 'images.main',
          type: 'imageConsumable',
          mandatory: true,
          module: 'consumable',
        },
        {
          title: formatMessage({ id: 'consumableLanding.image.desktop' }),
          key: 'images.desktop',
          type: 'imageConsumable',
          mandatory: false,
          module: 'consumable',
        },
        {
          title: formatMessage({ id: 'consumableLanding.landingVideo.url' }),
          key: 'landingVideo.url',
          type: 'url',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'consumableLanding.landingVideo.thumbnail',
          }),
          key: 'landingVideo.thumbnail',
          type: 'imageConsumable',
          mandatory: false,
          disabled: false,
          module: 'consumable',
        },
        {
          title: formatMessage({
            id: 'consumableLanding.landingVideo.title',
          }),
          key: 'landingVideo.title',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'consumableLanding.landingVideo.description',
          }),
          key: 'landingVideo.description',
          type: 'textarea',
          mandatory: false,
          disabled: false,
          length: 1200,
        },
        {
          title: 'locale',
          key: 'locale',
          type: 'text',
          mandatory: true,
          disabled: true,
          initialValue: 'es-ES',
        },
        {
          title: 'active',
          key: 'active',
          type: 'boolean',
          mandatory: true,
          disabled: true,
          initialValue: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
  ];

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ConsumableLandingDashboard;
