import { Checkbox, Input } from 'antd';
import React, { FC, Fragment } from 'react';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import FormItem from 'antd/lib/form/FormItem';

import './WebeatProductsSections.css';

import { isValidUrl } from './validate.fields';

import ProductsDragableComponent from './components/products-dragable.component';

export const RenderProductsFields: FC<{
  handleChange: (key: string, value: any) => void;
  fieldConfig: {
    key: string;
    label: string;
    subLabel?: string;
    type: string;
    placeholder: string;
    required?: boolean;
    maxLength?: number;
  };
  value: any;
  contentState?: any;
  homeId?: string;
}> = ({ handleChange, fieldConfig, value, contentState, homeId }) => {
  let error;
  if (fieldConfig?.maxLength) {
    error = value?.length > fieldConfig?.maxLength!;
  }

  if (fieldConfig.type === 'input') {
    return (
      <FormItem
        style={{
          maxWidth: 400,
        }}
        className="webeat-home-section-hero-input"
        colon={false}
        validateStatus={
          error || (fieldConfig.required && !value) ? 'error' : ''
        }
        required={fieldConfig.required}
        help={
          (error &&
            `It should contain a maximum caracters of: ${fieldConfig?.maxLength}.`) ||
          (fieldConfig.required && !value && `It should required.`)
        }>
        <Input
          value={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
          placeholder={fieldConfig.placeholder}
        />
      </FormItem>
    );
  }

  if (fieldConfig.type === 'checkbox') {
    return (
      <>
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}>
          {fieldConfig.label}
        </Checkbox>
      </>
    );
  }

  if (fieldConfig.type === 'title') {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'media-url') {
    if (value === null || value === undefined) {
      value = {
        checked: false,
        url: '',
      };
    }

    return (
      <>
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              checked: e.target.checked ? true : false,
              url: value['url'],
            })
          }>
          {fieldConfig.label}
        </Checkbox>

        {value['checked'] && (
          <Fragment>
            <br />
            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={!isValidUrl(value['url']) ? 'error' : ''}
              required={fieldConfig.required}
              help={
                !isValidUrl(value['url']) ? 'The URL format is not valid.' : ''
              }>
              <Input
                placeholder="Enter an URL"
                value={value['url']}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    url: e.target.value,
                  })
                }
              />
            </FormItem>

            <br />
          </Fragment>
        )}
      </>
    );
  }

  if (fieldConfig.type === 'products-dragable') {
    return (
      <div>
        <div className="webeat-home-section-hero-spacer"></div>
        <ProductsDragableComponent
          homeId={homeId}
          value={value}
          fieldConfig={fieldConfig}
          handleChange={handleChange}
          constentState={contentState}
        />
      </div>
    );
  }

  return null;
};
