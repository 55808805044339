import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatRestrictionsDashboard = (props: any) => {
  const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatRestrictionsSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'filterType',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'entityFilterType',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'platformFeature.id',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'webeatRestrictionsPage.filterType' }),
          key: 'filterType',
          type: 'selector',
          operand: 'equal',
          min: 0,
          comboId: 'filterTypes',
          selectorPath: '/platform-feature-filter-entity/filter-types',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.entityFilterType',
          }),
          key: 'entityFilterType',
          type: 'selector',
          operand: 'equal',
          min: 0,
          comboId: 'entityFilterTypes',
          selectorPath: '/platform-feature-filter-entity/entity-filter-types',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.platformFeature',
          }),
          key: 'platformFeature.id',
          type: 'selector',
          operand: 'equal',
          min: 0,
          comboId: 'platformFeatures',
          selectorPath: '/platform-feature-filter-entity/platform-features',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatRestrictionsList',
        rowKey: 'id',
        panelHeader: props.intl.formatMessage({
          id: 'labels.restrictions',
        }),
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tabIndex: -1,
      },
      fields: [
        {
          title: props.intl.formatMessage({ id: 'labels.id' }),
          key: 'id',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.platformFeature',
          }),
          key: 'platformFeature.featureName',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
        },
        {
          title: formatMessage({ id: 'webeatRestrictionsPage.filterType' }),
          key: 'filterType',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.entityFilterType',
          }),
          key: 'entityFilterType',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.value',
          }),
          key: 'value',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'text',
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatRestrictionsEdit',
        panelHeader: props.intl.formatMessage({
          id: 'labels.restrictions-edit',
        }),
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'filterType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'platformFeature.id',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'entityFilterType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'webeat-restriction',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'value',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: false,
                  width: 4,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: '',
          key: 'webeatRestrictionStates',
          type: 'selector',
          comboId: 'webeatRestrictionStates',
          selectorPath: '/platform-feature-filter-entity/states',
        },
        {
          title: formatMessage({ id: 'webeatRestrictionsPage.filterType' }),
          key: 'filterType',
          type: 'selector',
          comboId: 'filterTypes',
          selectorPath: '/platform-feature-filter-entity/filter-types',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.platformFeature',
          }),
          key: 'platformFeature.id',
          type: 'selector',
          comboId: 'platformFeatures',
          selectorPath: '/platform-feature-filter-entity/platform-features',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'webeatRestrictionsPage.entityFilterType',
          }),
          key: 'entityFilterType',
          type: 'selector',
          comboId: 'entityFilterTypes',
          selectorPath: '/platform-feature-filter-entity/entity-filter-types',
          mandatory: true,
          disabled: false,
          initialValue: 'COUNTRIES_STATES',
        },
        {
          title: formatMessage({ id: 'webeatRestrictionsPage.value' }),
          key: 'webeat-restriction',
          type: 'customField',
        },
        {
          title: formatMessage({ id: 'webeatRestrictionsPage.Value' }),
          key: 'value',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: true,
        },
        {
          title: '',
          key: 'webeatRestrictionListOfValues',
          type: 'selector',
          comboId: 'webeatRestrictionListOfValues',
          selectorPath: '/platform-feature-filter-entity/list-of-values',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'webeatRestrictionsDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatRestrictionsDashboard);
