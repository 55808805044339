import { Col, Divider, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import {
  editRecursiveResource,
  handleAddParentResource,
  handleRemoveParentResource,
} from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum, ResourceTypeEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';
import Speaker from './Speaker';

const { SPEAKER } = ResourceTypeEnum;
const { EXPERTS_GROUP_ICON } = ResourceTypeDetailEnum;

interface IExpertsGroup {
  resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  mainIdResource: number;
  arrayPosition: number;
}

type Props = ReturnType<typeof mapStateToProps> &
  IExpertsGroup &
  ReturnType<typeof mapDispatchToProps>;

const ExpertsGroup = ({
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  mainIdResource,
  arrayPosition,
}: Props): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  const speakers = resource.resourceList?.filter(
    (resourceChild) =>
      resourceChild.idResourceType.idResourceType === SPEAKER &&
      resourceChild.status,
  );
  const speakersNumber = speakers?.length ?? 0;

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          const { idResourceTypeD } = detail.idResourceTypeD;
          let combo: ComboData[] | undefined = undefined;
          if (idResourceTypeD === EXPERTS_GROUP_ICON) {
            combo =
              combos.challengechallengeEdit?.iconType?.iconType?.data ?? [];
          }
          return RenderDetail({
            combo,
            detail,
            detailArrayPosition: index,
            parentArrayPosition: arrayPosition,
            editRecursiveResource,
            editResource,
            form,
            resource,
            mainIdResource: resource.idResource,
          });
        })}

      <Col span="24">
        <Divider className="resourceDivider" />

        <Row className="QuizQuestion" type="flex" gutter={[24, 24]}>
          <Col span={24}>
            <div className="QuizQuestion__media--ellipsis">
              <FormattedMessage id="tree-resource.experts_group_speakers_list" />
            </div>
          </Col>
        </Row>
        <Row gutter={[12, 24]} justify="start" className="sell-in-products-row">
          {resource.resourceList
            ?.sort((a, b) => a.order - b.order)
            .map((resourceChild, index) => {
              if (
                !resourceChild.status ||
                resourceChild.idResourceType.idResourceType !== SPEAKER
              )
                return;

              return (
                <Col span={24} id={`${index}`}>
                  <Speaker
                    _resource={resourceChild}
                    editResource={editResource}
                    form={form}
                    parentArrayPosition={arrayPosition}
                    childArrayPosition={index}
                    handleDeleteImages={handleDeleteImages}
                    mainIdResource={mainIdResource}
                    canEditResourceName={true}
                    speakersNumber={speakersNumber}
                  />
                </Col>
              );
            })}
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      editRecursiveResource,
      handleAddParentResource,
      handleRemoveParentResource,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsGroup);
