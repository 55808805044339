import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ReducersState } from '../../../reducers';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import RenderDetail from '../TreeResource/TreeDetails';
import { DetailsTypesEnum } from '../../Enums';

const { TEXT, TEXT_AREA, BOOLEAN, NUMBER
} = DetailsTypesEnum;

type ConnectedQuiz = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IResourceTree;

const LearningQuiz: FC<ConnectedQuiz> = ({
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  resource,
}): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  return (
    <>
      {details
        ?.sort((a, b) => a.detail.order - b.detail.order)
        .sort((a, b) => {
          const typeA = a.detail.idResourceTypeD.type;
          const typeB = b.detail.idResourceTypeD.type;

          if (typeA === TEXT && typeB !== TEXT) return -1;
          if (typeA === TEXT_AREA && typeB !== TEXT_AREA && typeB !== TEXT) return -1;
          if (typeA === BOOLEAN && typeB !== BOOLEAN && typeB !== TEXT_AREA && typeB !== TEXT) return -1;
          if (typeA === NUMBER && typeB !== NUMBER && typeB !== BOOLEAN && typeB !== TEXT_AREA && typeB !== TEXT) return -1;
          return 1;
        })
        .map(({ index, detail }) =>
          RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            resource,
            mainIdResource: resource.idResource,
          })
        )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LearningQuiz);
