import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Checkbox } from 'antd';
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

import config from '../config';
import { ComboData } from '../combos/ComboInterfaces';
import { Column } from './tableInterfaces';
import { IRecord, Primitive, IRow } from '../app/AppInterfaces';
import { ReducersState } from '../reducers';
import moment from 'moment';
import { isPINPlatform, loadTimezoneName } from '../utils';
import { replaceEmptyValues } from './replaceEmptyValues';

/**ISDIN CUSTOM FUNCTION */
const checkMultiEventValue = (record: IRow, key: string) => {
  const keysArray = key.split('.');
  const events = record[keysArray[0]];
  if (events.length) events.filter((event: IRow) => event.status);

  switch (events.length) {
    case 0:
      return null;
    case 1:
      return events[0][keysArray[1]];
    default:
      let statusResource = 0;
      events.forEach((event: IRow) => {
        if (event.statusResource !== 0) statusResource = event.statusResource;
      });
      return statusResource;
  }
};
/** END ISDIN CUSTOM FUNCTION */

const stringToNumber = (value: Primitive) => {
  return typeof value === 'string'
    ? parseInt(value)
    : typeof value === 'number'
    ? value
    : '';
};

/**
 * This function returns column formated by render type
 * @param {Object} column
 */
const ColumnRender = (
  column: Column,
  value: Primitive,
  record: IRecord,
  combos: ReducersState['combos'],
  componentId: string,
  handleOpenFile: Function,
) => {
  isPINPlatform() &&
    value === null &&
    (value = replaceEmptyValues(column, value));

  // custom isdin
  if (record && column.isArray && column.arrayPosition !== undefined) {
    if (column.key === 'resourceM2MList.statusResource') {
      value = checkMultiEventValue(record, column.key);
    } else {
      const arrayKey = column.key.split('.');
      const objectValue: any = record[arrayKey[0]][column.arrayPosition];
      if (objectValue && column.fieldKey) {
        value = objectValue[arrayKey[1]]?.[column.fieldKey] || ' ';
      } else if (objectValue) value = objectValue[arrayKey[1]];
    }
  }
  // end custom isdin
  if (column.render) {
    const currentTimezone = loadTimezoneName();

    switch (column.render) {
      case 'check':
        let checked;
        if (typeof value === 'boolean') checked = value;
        return <Checkbox checked={checked} disabled={true} />;
      case 'time':
        return value && <FormattedTime value={value || false} />;
      case 'date':
        if (!value) return;

        return (
          <FormattedDate
            value={value}
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        );
      case 'datetime':
        if (!value) return;
        const dateValue = value
          ? moment
              .utc(value.toString())
              .tz(currentTimezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : undefined;

        return dateValue;
      case 'webeat-datetime':
        if (!value) return;
        return new Date(value.toString()).toLocaleString();
      case 'currency':
        const currencyValue = stringToNumber(value);
        if (!currencyValue && currencyValue !== 0) return;
        return (
          <FormattedNumber
            value={currencyValue}
            style={column.render}
            currency={config.APP.CURRENCY_NAME}
          />
        );
      case 'percent':
        const formattedValue = stringToNumber(value);
        if (!formattedValue && formattedValue !== 0) return;
        return (
          <FormattedNumber
            value={formattedValue / 100}
            minimumFractionDigits={2}
            style={column.render}
          />
        );
      case 'number':
        const numberValue = stringToNumber(value);
        if (!numberValue && numberValue !== 0) return;
        return (
          <FormattedNumber
            value={value}
            minimumFractionDigits={column.precision ?? 2}
          />
        );

      case 'document':
        return (
          value && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{value}</span>
              <Button
                icon="picture"
                className="static-button"
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  event.stopPropagation();
                  // const fkFolder: string =
                  //   column.folder!.indexOf('.') < 0
                  //     ? record[column.folder!]
                  //     : record[
                  //         column.folder!.slice(0, column.folder!.indexOf('.'))
                  //       ][
                  //         column.folder!.slice(column.folder!.indexOf('.') + 1)
                  //       ];
                  handleOpenFile({
                    fkFolder: record[column.folder!],
                    docName: record[column.key],
                  });
                }}
                disabled={isEmpty(value)}
              />
            </div>
          )
        );
      // case 'image':
      //   return (
      //     value && (
      //       <div>
      //         <Button
      //           icon="picture"
      //           className="static-button"
      //           onClick={(event: MouseEvent) => {
      //             event.stopPropagation();
      //             handleOpenImage(value, column.title);
      //           }}
      //           disabled={isEmpty(value)}
      //           title={value}
      //         />
      //       </div>
      //     )
      //   );
      case 'comboCustom':
        if (value === null || value === undefined || value < 0) return;
        let newValue = '';
        if (
          combos.combosCustom[column.comboId!].data &&
          !isEmpty(combos.combosCustom[column.comboId!].data)
        )
          combos.combosCustom[column.comboId!].data.forEach((o: ComboData) => {
            if (o.value === value!.toString()) {
              newValue = o.description;
            }
            return o;
          });
        return <div>{newValue}</div>;
      case 'combo':
      case 'selector':
        if (value === null || value === undefined || value < 0) return;
        let comboDesc = '';
        if (
          combos.combos[componentId] &&
          combos.combos[componentId][column.key] &&
          combos.combos[componentId][column.key][column.comboId!] &&
          combos.combos[componentId][column.key][column.comboId!].data
        )
          combos.combos[componentId][column.key][column.comboId!].data.forEach(
            (o: ComboData) => {
              if (o.value?.toString() === value!.toString()) {
                comboDesc =
                  column.comboId === 'brands' ? o.label! : o.description;
              }
              return o;
            },
          );
        return <div>{comboDesc}</div>;
      case 'link':
        return (
          <a
            href={`${config.API.STATIC_URL}/documents/${
              record[column.linkKey!]
            }`}
            target="_blank"
            rel="noopener noreferrer">
            {value}
          </a>
        );
      default:
        return value;
    }
  } else return value;
};

export default ColumnRender;
