import { Button, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { deleteCall } from '../../api';
import apiPaths from '../../apiPaths';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';

export default function CleanLabeledProducts<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const { formatMessage } = useIntl();

  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  const cleanLabel = async () => {
    try {
      const resp = await deleteCall({
        dataPath: `${apiPaths.CALL.LABEL_REDEEM_PRODUCTS_CLEAN}`,
        callConfig: { data: selectedRow.productsToRemove },
      });

      if (resp?.data === '') {
        notification.success({
          message: formatMessage({
            id: 'labelRedeemProducts.notification.success',
          }),
          duration: 3,
        });

        parentProps.handleReloadData();
      } else
        notification.error({
          message: `${resp.data}`,
          duration: 3,
        });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Button
        onClick={() => cleanLabel()}
        disabled={isEmpty(selectedRow) || !selectedRow?.productsNotMagento}
        type="primary">
        {formatMessage({ id: 'labelRedeemProducts.button.cleanLabel' })}
      </Button>
    </>
  );
}
