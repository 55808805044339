import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  notification,
  Progress,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Upload from 'rc-upload';
import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { authTypes } from '../../auth/authTypes';
import config from '../../config';
import {
  getURLType,
  IFileDimensions,
  isImageFileFormat,
  isVideoFileFormat,
  isYoutubeFormat,
  IUploadActions,
  typesOfSelectEnum,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_IMAGE_URL,
} from '../../shared';
import store from '../../store';
import {
  getPlatformBaseUrl,
  getValidFormats,
  isConPlatform,
  loadItem,
  PLATFORM_CODE_KEY,
  saveState,
} from '../../utils';
import { ResourceTypeEnum } from '../../challenge/Enums';
import {
  validateCoverVideoUrl,
  VideoTypeEnum,
} from '../../challenge/Components/Resource.utils';

const {
  VIDEO,
  IMAGE,
  TEXT: YOUTUBE_URL,
  VIDEO_URL_VIMEO,
  DOCUMENT,
  QUIZ: PDF_FILE,
} = ResourceTypeEnum;
const IMAGE_URL = 70;

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
const getInputFileType = (
  value: string,
  format: 'image' | 'pdf' | 'video' | 'document',
  materialType?: typesOfSelectEnum,
  uploadActions?: IUploadActions[],
) => {
  const fileType = getURLType(value, format, materialType, uploadActions);
  if (
    (fileType === IMAGE &&
      uploadActions?.some(
        (action) => action.id === UPLOAD_ACTION_IMAGE_URL.id,
      ) &&
      isValidUrl(value)) ||
    (fileType === YOUTUBE_URL && format === 'image')
  ) {
    return IMAGE_URL;
  }
  return fileType;
};

const DEFAULT_ENTITY_ID = '00000000-0000-0000-0000-000000000000';
const DEAFULT_MODULE = 'consumable';

export type UploadFormats = 'image' | 'pdf' | 'video' | 'document';
interface OwnProps {
  children?: React.ReactNode;
  disabled?: boolean;
  fileDimensions?: IFileDimensions;
  format?: UploadFormats;
  loading?: boolean;
  maxSize?: number | null;
  token: string;
  value?: any | any[];
  uploadActions?: IUploadActions[];
  fieldName: string;
  setValue: (value?: string | null, fileType?: typesOfSelectEnum) => void;
  materialType?: typesOfSelectEnum;
  smallPreviewButton?: boolean;
  initialUploadButtonSpan?: number;
}

export default function UploadComponent({
  children,
  disabled,
  fileDimensions,
  format = 'image',
  loading,
  setValue,
  maxSize = null,
  // maxSize = SIZE_LIMIT, -- TODO define size restrictions
  token,
  value,
  uploadActions = [UPLOAD_ACTION_IMAGE],
  fieldName,
  materialType,
  smallPreviewButton,
  initialUploadButtonSpan = 24,
}: OwnProps) {
  const { formatMessage } = useIntl();
  const apiRootUrl = getPlatformBaseUrl();
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  const storageRootUrl = config.BUCKET.WEBEAT_BUCKET;

  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileType, setFileType] = useState(
    getInputFileType(value, format, materialType, uploadActions),
  );
  const [selectedAction, setSelectedAction] = useState<typesOfSelectEnum>(
    parseInt(fileType.toString()) || typesOfSelectEnum.IMAGE,
  );

  const validFormats = getValidFormats(format, selectedAction, fileType);
  const hasMultipleActions = uploadActions.length > 1;

  const seePreview = Boolean(value?.trim())
    ? fileType === IMAGE
      ? isImageFileFormat(value)
      : fileType === IMAGE_URL
      ? isValidUrl(value)
      : fileType === VIDEO
      ? isVideoFileFormat(value)
      : fileType === YOUTUBE_URL
      ? isYoutubeFormat(value)
      : false
    : false;

  const seeRemove =
    Boolean(value?.trim()) &&
    [VIDEO, IMAGE, DOCUMENT, typesOfSelectEnum.FILE].includes(fileType);

  const multipleActionsSpan = hasMultipleActions ? 4 : 0;
  const removeComponentSpan = seeRemove ? 2 : 0;
  const previewComponentSpan = seePreview
    ? smallPreviewButton
      ? 4
      : hasMultipleActions
      ? 6
      : 8
    : 0;
  const uploadComponentSpan =
    initialUploadButtonSpan -
    multipleActionsSpan -
    removeComponentSpan -
    previewComponentSpan;
  const urlComponentSpan =
    22 - multipleActionsSpan - removeComponentSpan - previewComponentSpan;

  if (format === 'image') {
    switch (fileType) {
      case ResourceTypeEnum.DOCUMENT:
        format = 'pdf';
        break;
      case ResourceTypeEnum.VIDEO:
      case ResourceTypeEnum.VIDEO_URL_VIMEO:
      case ResourceTypeEnum.IMAGE:
      default:
        format = 'image';
        break;
    }
  }

  const getFileType = () => {
    if (format === 'image' || format === 'video') {
      return 'MEDIA';
    } else if (format === 'pdf') {
      return 'PDF';
    } else if (format === 'document') {
      return 'DOC';
    } else {
      return 'DOC';
    }
  };

  const setNewType = (newFileType: typesOfSelectEnum) => {
    setSelectedAction(newFileType);
    switch (newFileType) {
      case typesOfSelectEnum.YOUTUBE_URL:
        setFileType(YOUTUBE_URL);
        break;
      case typesOfSelectEnum.VIDEO_URL_VIMEO:
        setFileType(VIDEO_URL_VIMEO);
        break;
      case typesOfSelectEnum.VIDEO:
        setFileType(VIDEO);
        break;
      case typesOfSelectEnum.IMAGE_URL:
        setFileType(IMAGE_URL);
        break;
      case typesOfSelectEnum.IMAGE:
        setFileType(IMAGE);
        break;
      case typesOfSelectEnum.FILE:
      case typesOfSelectEnum.DOCUMENT:
      default:
        setFileType(DOCUMENT);
        break;
    }
    setValue(null, newFileType);
  };

  const getUploadPath = () => {
    return `${apiRootUrl}${
      apiPaths.UPLOAD
    }/${DEFAULT_ENTITY_ID}/${getFileType()}?module=${DEAFULT_MODULE}`;
  };

  async function getMeta(url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.height, img.width]);
      img.onerror = reject;
      img.src = url;
    });
  }

  const checkFileDimensions = ([height, width]: any) => {
    return height === fileDimensions?.height && width === fileDimensions?.width;
  };

  const validateFile = (file: RcFile) => {
    const { type, size, name } = file;

    switch (true) {
      case maxSize !== null && size > maxSize:
        return {
          message: formatMessage({ id: 'upload.error.select' }),
          description: formatMessage({ id: 'upload.size' }),
        };
      case type === '':
        const format =
          name.indexOf('.') >= 0 ? name.slice(name.lastIndexOf('.') + 1) : '';
        if (!validFormats.includes(format))
          return {
            message: formatMessage({ id: 'upload.error.select' }),
            description: formatMessage({ id: 'upload.format' }),
          };
        break;
      case !validFormats.includes(type):
        return {
          message: formatMessage({ id: 'upload.error.select' }),
          description: formatMessage({ id: 'upload.format' }),
        };
    }

    return false;
  };

  const handleCheckFile = (file: RcFile, options: any, other: any) => {
    const error = validateFile(file);
    if (error)
      notification.error({
        message: error.message || formatMessage({ id: 'upload.error.default' }),
        description: error.description,
      });
    return error ? false : true;
  };

  const handleRemove = (
    file: UploadFile<any>,
  ): boolean | void | Promise<boolean | void> => {
    setValue(null, selectedAction);
  };

  const handleSuccess = async (response: {
    [key: string]: string;
  }): Promise<void> => {
    setUploading(false);
    let control = true;

    if (!isConPlatform() && fileDimensions) {
      const imageDimensions: any = await getMeta(response.url);
      if (!checkFileDimensions(imageDimensions)) control = false;
      if (!control) {
        notification.error({
          message: formatMessage({ id: 'upload.error.dimensions' }),
          description: formatMessage(
            { id: 'upload.error.dimensions-expected' },
            {
              currentHeight: imageDimensions[0],
              currentWidth: imageDimensions[1],
              expectedHeight: fileDimensions.height,
              expectedWidth: fileDimensions.width,
            },
          ),
        });
        return;
      }
    }

    let _message = '';
    switch (fileType) {
      case PDF_FILE:
        _message = 'upload.file.success';
        break;
      case VIDEO:
        _message = 'upload.video.success';
        break;
      case IMAGE:
        _message = 'upload.image.success';
        break;
      case DOCUMENT:
        _message = 'upload.document.success';
    }

    setValue(response.url ? response.url.trim() : null, selectedAction);
    notification.success({
      message: formatMessage({ id: _message }),
      duration: 3,
    });
  };

  const handleFileTypeChange = (_fileType: typesOfSelectEnum) => {
    if (Boolean(value)) {
      Modal.confirm({
        title: formatMessage({ id: 'upload.image.modalTitle' }),
        okText: formatMessage({ id: 'pop.accept' }),
        cancelText: formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setNewType(_fileType);
        },
        onCancel() {
          return;
        },
      });
    } else {
      setNewType(_fileType);
    }
  };

  function handleChangeVideoInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim();
    const youtubeId = validateCoverVideoUrl(value, VideoTypeEnum.YOUTUBE);
    setValue(youtubeId.value, selectedAction);
  }

  function handleChangeVimeoInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim();
    const vimeoId = validateCoverVideoUrl(value, VideoTypeEnum.VIMEO);
    setValue(vimeoId.value, selectedAction);
  }

  const handleError = (err: any, response: any, file: any) => {
    if (response.code === 401)
      api
        .refreshCall()
        .then((response: any) => {
          const { accessToken, refreshToken } = response.data;
          const auth = {
            accessToken: `Bearer ${accessToken}`,
            refreshToken: refreshToken,
            isAuthorized: true,
          };
          saveState({ auth });
          store.dispatch({
            type: authTypes.SET_TOKENS,
            payload: {
              accessToken: `Bearer ${accessToken}`,
              refreshToken: refreshToken,
            },
          });
          notification.warning({
            message: formatMessage({ id: 'upload.image.session-refreshed' }),
          });
        })
        .catch((_err) => {
          notification.warning({
            message: formatMessage({ id: 'server.session.expired' }),
          });
          api.forceLogout();
        })
        .finally(() => {
          setUploading(false);
        });
  };

  const handleRemoveConfirmation = (data: any) => {
    Modal.confirm({
      title: formatMessage({ id: 'upload.image.modalTitle' }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      async onOk() {
        setValue(null, selectedAction);
      },
      onCancel() {},
    });
  };

  const typeSelector = hasMultipleActions && (
    <Col span={multipleActionsSpan}>
      <Select
        disabled={disabled}
        defaultValue={selectedAction}
        value={selectedAction}
        className="fileTypeSelect"
        dropdownClassName="fileTypeSelect--options"
        onChange={handleFileTypeChange}
        id={fieldName}>
        {uploadActions.map(({ icon, id, label }) => {
          return (
            <Select.Option value={id} className="fileTypeSelect" key={id}>
              <Icon type={icon} />
              {label && (
                <span>
                  {formatMessage({
                    id: label,
                  })}
                </span>
              )}
            </Select.Option>
          );
        })}
      </Select>
    </Col>
  );

  const urlComponent = format !== 'pdf' && YOUTUBE_URL === fileType && (
    <>
      <Col className={fieldName} span={smallPreviewButton ? 2 : 1}>
        <Tooltip
          className="fieldTooltip"
          title={formatMessage({ id: 'upload.video.youtubeID.tooltip' })}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </Col>
      <Col className={fieldName} span={urlComponentSpan}>
        <Input
          disabled={disabled}
          onChange={(e) => {
            handleChangeVideoInput(e);
          }}
          allowClear
          value={value}
          defaultValue={
            Boolean(value) &&
            getInputFileType(value, format, materialType) === YOUTUBE_URL
              ? value
              : ''
          }
        />
      </Col>
    </>
  );

  const imageUrlComponent = fileType === IMAGE_URL && (
    <Col className={fieldName} span={uploadComponentSpan}>
      <Input
        disabled={disabled}
        onChange={(e) => {
          handleChangeVideoInput(e);
        }}
        allowClear
        value={value}
        defaultValue={
          Boolean(value) &&
          getInputFileType(value, format, materialType) === IMAGE_URL
            ? value
            : ''
        }
      />
    </Col>
  );

  const vimeoUrlComponent = format !== 'pdf' &&
    VIDEO_URL_VIMEO === fileType && (
      <>
        <Col className={fieldName}>
          <Tooltip
            className="fieldTooltip"
            title={formatMessage({ id: 'upload.video.vimeoID.tooltip' })}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </Col>
        <Col
          className={fieldName}
          span={seePreview ? (hasMultipleActions ? 10 : 12) : 14}>
          <Input
            disabled={disabled}
            onChange={(e) => {
              handleChangeVimeoInput(e);
            }}
            value={value}
            allowClear
            defaultValue={
              Boolean(value) &&
              getInputFileType(value, format, materialType) === VIDEO_URL_VIMEO
                ? value
                : ''
            }
          />
        </Col>
      </>
    );

  const uploadInputChildren = children ?? (
    <Button icon="upload" style={{ width: '100%' }} disabled={disabled}>
      {formatMessage({ id: `scanLanding.file.upload` })}
    </Button>
  );
  const uploadInput = [VIDEO, IMAGE, DOCUMENT, typesOfSelectEnum.FILE].includes(
    fileType,
  ) && (
    <Col span={uploadComponentSpan}>
      <Upload
        loading={loading ?? false}
        name="upload"
        disabled={disabled ?? false}
        action={getUploadPath()}
        headers={{
          Authorization: token,
          platform: config.APP.PLATFORM,
          'x-isdin-platform': platformCode || config.APP.PLATFORM_CODE,
        }}
        beforeUpload={handleCheckFile}
        onError={handleError}
        onStart={() => setUploading(true)}
        onSuccess={handleSuccess}
        handleRemove={handleRemove}
        fileList={value ? [{ id: value }] : []}>
        {uploadInputChildren}
      </Upload>
    </Col>
  );

  const previewComponent = seePreview && (
    <Col
      span={previewComponentSpan}
      style={{ paddingLeft: '0px', textAlign: 'center' }}>
      <Button
        className="uploadButton uploadButton__margin-top"
        onClick={() => setShowModal(true)}>
        <Icon type="eye" />
        {!smallPreviewButton
          ? formatMessage({
              id: 'challenge.media.preview',
            })
          : null}
      </Button>
    </Col>
  );

  const removeComponent = seeRemove && (
    <Col
      span={removeComponentSpan}
      style={{ paddingLeft: '0px', textAlign: 'center' }}>
      <Button
        className="removeButton"
        onClick={handleRemoveConfirmation}
        disabled={disabled ?? false}
        type="danger">
        <Icon type="delete" />
      </Button>
    </Col>
  );

  return (
    <Row type="flex" align="middle" gutter={[12, 0]}>
      {typeSelector}
      {uploadInput}
      {urlComponent}
      {imageUrlComponent}
      {vimeoUrlComponent}
      {previewComponent}
      {removeComponent}

      <Modal
        visible={showModal}
        centered
        onCancel={() => setShowModal(false)}
        footer={false}
        className="previewModal"
        destroyOnClose>
        <Row
          className={`uploadButton__preview ${
            fileType === YOUTUBE_URL
              ? 'uploadButton__preview--youtube-video'
              : ''
          }`}>
          {(fileType === IMAGE || fileType === IMAGE_URL) && (
            <img
              width="854"
              height="480"
              loading="lazy"
              style={{ objectFit: 'contain' }}
              //Workaround to show consumable images despite the webeat platform
              src={
                !value?.startsWith('http') ? `${storageRootUrl}${value}` : value
              }
              alt={
                !value?.startsWith('http') ? `${storageRootUrl}${value}` : value
              }
            />
          )}
          {fileType === VIDEO && (
            <video width="95%" height="auto" controls>
              {/* Workaround to show consumable images despite the webeat platform */}
              <source
                src={
                  !value?.startsWith('http')
                    ? `${storageRootUrl}${value}`
                    : value
                }
                type="video/mp4"
              />
            </video>
          )}
          {fileType === YOUTUBE_URL && (
            <iframe
              title="youtube_url"
              width="95%"
              height="auto"
              src={
                !value?.startsWith('http')
                  ? `https://www.youtube.com/embed/${value}`
                  : value
              }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </Row>
      </Modal>
      <Modal
        visible={uploading}
        closable={false}
        footer={false}
        className="uploadingModal">
        <Row type="flex" align="middle" className="uploadingModal__row">
          <Row className="uploadingModal__container">
            <div className="uploadingModal__title">Uploading file..</div>
            <Progress percent={100} status="active" />
          </Row>
        </Row>
      </Modal>
    </Row>
  );
}
