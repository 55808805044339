import React, { useEffect } from 'react';
import { ReducersState } from '../../../reducers';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { IResource, IResourceDetail } from '../../ChallengeInterfaces';
import { Row, Input, Checkbox, Col, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import '../Resource.css';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import {
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../challengeActions';
import { QuizQuestionTypeEnum, ResourceTypeDetailEnum } from '../../Enums/resourceTypeDetail.enum'

interface AnswerResource {
  value: string;
  form: WrappedFormUtils<any>;
  _resource: IResource;
  allResources?: IResource[];
  questionIndex: number;
  answerIndex: number;
  idQuestion: number;
  editResource: boolean;
  answersNumber?: number;
  currentQuestionType?: QuizQuestionTypeEnum;
}

type AnswerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AnswerResource;

function AnswerResource(props: AnswerProps) {
  const {
    _resource,
    allResources,
    form,
    questionIndex,
    answerIndex,
    idQuestion,
    editResource,
    answersNumber,
    handleRemoveChildResource,
    editRecursiveResource,
    currentQuestionType,
  } = props;
  const answerResource = _resource;
  const details = _resource.resourceDetailList!;
  const intl = useIntl();
  let correct: { _detail: IResourceDetail; index: number };
  let resourcesDetails: IResourceDetail[] = [];

  details.forEach((_detail, i) => {
    if ( _detail.idResourceTypeD.idResourceTypeD === ResourceTypeDetailEnum.CORRECT) 
      correct = { _detail, index: i };
  });

  allResources?.forEach((resource) => {
    if (resource.resourceDetailList){
      resourcesDetails.push(resource.resourceDetailList[0])
    }
  })

  const deleteButtonDisable = !answersNumber || answersNumber <= 1;

  useEffect(() => {
    if(currentQuestionType === QuizQuestionTypeEnum.ALL_VALID && questionIndex && answerIndex >= 0){
      // let value = 'false';
      // switch (currentQuestionType){
      //   case QuizQuestionTypeEnum.UNIQUE:
      //     value = (answerIndex === 0).toString()
      //     break;
      //   case QuizQuestionTypeEnum.MULTIRESPONSE:
      //     value = (answerIndex <= 1).toString()
      //     break;
      //   case QuizQuestionTypeEnum.ALL_VALID:
      //     value = 'true'
      //     break;
      // }
      editRecursiveResource({
        value: "true",
        parentArrayPosition: questionIndex,
        childArrayPosition: answerIndex,
        detailArrayPosition: correct!.index,
      });

      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionType, questionIndex, answerIndex, correct!.index]);
  
  return correct! ? (
    editResource ? (
      //RESPUESTA EDITABLE = TRUE
      <Row className="QuizAnswer" type="flex" gutter={[24, 24]}>
        <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
          <Form.Item className="Quiz__input" style={{ float: 'left' }}>
            <Button
              disabled={deleteButtonDisable}
              icon="minus-circle"
              className={
                deleteButtonDisable ? 'deleteButton__disabled' : 'deleteButton'
              }
              onClick={() => {
                handleRemoveChildResource({
                  idAnswer: answerResource.idResource,
                  idQuestion,
                  questionArrayPosition: questionIndex,
                  answerArrayPosition: answerIndex,
                });
              }}
            />
          </Form.Item>
          <Form.Item className="Quiz__input" style={{ float: 'left' }}>
            Answer {answerIndex + 1}
          </Form.Item>
        </Col>

        <Col xs={22} md={16} xl={17}>
          <Form.Item className="Quiz__input">
            {form.getFieldDecorator(
              'answer'.concat(questionIndex.toString(), answerIndex.toString()),
              {
                initialValue: answerResource.name,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'update.required' }),
                    max: 250,
                  },
                ],
              },
            )(
              <Input
                // size="small"
                onBlur={(e) => {
                  editRecursiveResource({
                    value: e.target.value,
                    parentArrayPosition: questionIndex,
                    childArrayPosition: answerIndex,
                  });
                }}
                maxLength={250}
              />,
            )}
          </Form.Item>
        </Col>

        <Col xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Item className="Quiz__input">
            {form.getFieldDecorator(
              questionIndex.toString().concat(answerIndex.toString()),
            )(
              <Checkbox
                className="QuizAnswer__checkbox"
                checked={
                  (correct!._detail.value === 'true' ||
                  correct!._detail.value === true
                    ? true
                    : false) 
                }
                disabled={currentQuestionType === QuizQuestionTypeEnum.ALL_VALID}
                onChange={(e: CheckboxChangeEvent) => {
                  let checkedAnswers = resourcesDetails.find(detail => detail.value === true || detail.value === 'true')
                  // Update selected answer to uncheck if is UNIQUE answer
                  if(currentQuestionType === QuizQuestionTypeEnum.UNIQUE && checkedAnswers && e.target.checked){
                    const indexToUpdate = resourcesDetails.findIndex(detail => detail.value === true || detail.value === 'true')
                    editRecursiveResource({
                      value: 'false',
                      parentArrayPosition: questionIndex,
                      childArrayPosition: indexToUpdate,
                      detailArrayPosition: correct!.index,
                    });
                  }
                  editRecursiveResource({
                    value: e.target.checked.toString(),
                    parentArrayPosition: questionIndex,
                    childArrayPosition: answerIndex,
                    detailArrayPosition: correct!.index,
                  });
                  
                }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    ) : (
      //RESPUESTA EDITABLE = FALSE
      <Row className="QuizAnswer">
        <Row className="QuizAnswer__container" type="flex" gutter={[24, 24]}>
          <Col xs={24} md={6} xl={5} style={{ fontWeight: 600 }}>
            <div className="QuizQuestion__media--ellipsis">
              Answer {answerIndex + 1}
            </div>
          </Col>
          <Col xs={22} md={16} xl={17}>
            {answerResource.name}
          </Col>
          <Col xs={2}>
            <Form.Item className="Quiz__input">
              {form.getFieldDecorator(
                questionIndex.toString().concat(answerIndex.toString()),
              )(
                <Checkbox
                  className="QuizAnswer__checkbox"
                  disabled
                  checked={
                    (correct! && correct!._detail.value === 'true'
                      ? true
                      : false) 
                  }
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Row>
    )
  ) : null;
}

const mapStateToProps = (state: ReducersState) => {
  return {
    editing:
      state.challenge.editingChallenge || state.challenge.editingResource,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnswerResource);
