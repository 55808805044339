import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { IRow } from '../../app/AppInterfaces';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ReducersState } from '../../reducers';
import { ITableRenderListProps } from '../../tables/TableRender';
import { getFrontUrl } from '../../utils';
import { isTableProps } from '../../utils/propsUtils';

export default function ImpersonateButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;

  return <ConnectedImpersonate selectedRow={selectedRow} />;
}

// --- IMPERSONATE BUTTON COMPONENT ---- //

interface OwnButtonProps {
  selectedRow: IRow;
}

type ButtonProps = ReturnType<typeof mapStateToProp> & OwnButtonProps;

const ButtonComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
}) => {
  const FRONT_URL = getFrontUrl(currentPlatform, platforms);
  const handleImpersonate = async (selectedRow: IRow) => {
    if (
      selectedRow &&
      selectedRow.idUser !== undefined &&
      selectedRow.idUser !== null
    ) {
      const userToken = await api.postDataCallById({
        dataPath: apiPaths.CALL.IMPERSONATE,
        id: selectedRow.uuidUser,
        data: {},
        callConfig: {},
      });
      if (
        'status' in userToken &&
        userToken.status === 200 &&
        userToken.data &&
        userToken.data.accessToken
      ) {
        const win = window.open(
          `${FRONT_URL}impersonate?token=${userToken.data.accessToken}`,
          '_blank',
        );
        if (win) win.focus();
      }
    }
  };

  return (
    <Button
      onClick={() => handleImpersonate(selectedRow)}
      disabled={isEmpty(selectedRow)}
      type="primary">
      Impersonate
    </Button>
  );
};

const mapStateToProp = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};

const ConnectedImpersonate = connect(mapStateToProp, {})(ButtonComponent);
