import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const LandingProductDashboard = (props: any) => {
  const { SMALL } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'landingProductSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idQrProduct.productType', //idQrProduct.productType
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idQrProduct.productGroup', //idQrProduct.productGroup
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idQrProduct.idProduct', //idQrProduct.idProduct
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'showGrid',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.and.size',
          }),
          key: 'productNameLanding',
          type: 'text',
          operand: 'like',
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'idQrProduct.productType',
          type: 'combo',
          comboId: 'productTypeCombo',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'idQrProduct.productGroup',
          type: 'text',
          operand: 'like',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idQrProduct.idProduct',
          type: 'text',
          operand: 'like',
        },
        {
          title: 'Show in grid',
          key: 'showGrid',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.id.product.grid-order',
          }),
          key: 'gridOrder',
          type: 'number',
          operand: 'equal',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'landingProductList',
        rowKey: 'idLandingProduct',
        panelHeader: 'Landing Product List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'productNameLanding',
      },
      fields: [
        {
          title: 'Id landing product',
          key: 'idLandingProduct',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.landing',
          }),
          key: 'productNameLanding',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.code' }),
          key: 'idQrProduct.productCode',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'idQrProduct.productType',
          position: 3,
          render: 'combo',
          comboId: 'productTypeCombo',
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idQrProduct.idProduct',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'idQrProduct.productGroup',
          position: 5,
          render: 'combo',
          comboId: 'productGroupCombo',
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Show in grid',
          key: 'showGrid',
          position: 6,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          render: 'check',
          width: SMALL,
        },
        {
          title: 'Grid order',
          key: 'gridOrder',
          position: 7,
          mustRender: true,
          align: 'center',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'landingProductEdit',
        panelHeader: 'QR Product Edit',
        primaryKey: 'idLandingProduct',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'productNameLanding',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'showGrid',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'productImage',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'gridOrder',
                  yPosition: 2,
                  xPosition: 4,
                  mustRender: true,
                  width: 6,
                },

                {
                  key: 'productDescription',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'productMainImageDesktop',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'productMainImageMobile',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'productVideo',
                  yPosition: 4,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'productVideoTitle',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'productVideoDescription',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'productVideoThumbnail',
                  yPosition: 4,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.code' }),
          key: 'productCode',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.and.size',
          }),
          key: 'productNameAndSize',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.product.name.landing',
          }),
          key: 'productNameLanding',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 35,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.id.product.sap' }),
          key: 'idQrProduct.idProduct',
          type: 'number',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.id.product.grid-order',
          }),
          key: 'gridOrder',
          type: 'number',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.status' }),
          key: 'productStatus',
          type: 'comboCustom',
          comboId: 'productStatus',
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.bu' }),
          key: 'bu',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.points' }),
          key: 'points',
          type: 'number',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Show in grid',
          key: 'showGrid',
          type: 'comboCustom',
          comboId: 'optional',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Product image',
          key: 'productImage',
          type: 'image',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Product description',
          key: 'productDescription',
          type: 'textarea',
          length: 50,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.type' }),
          key: 'idQrProduct.productType',
          type: 'combo',
          comboId: 'productTypeCombo',
          mandatory: true,
          forceDisabled: true,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.product.group' }),
          key: 'idQrProduct.productGroup',
          type: 'combo',
          comboId: 'productGroupCombo',
          mandatory: false,
          forceDisabled: true,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.banner.mobile',
          }),
          key: 'productMainImageMobile',
          type: 'image',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.banner.desktop',
          }),
          key: 'productMainImageDesktop',
          type: 'image',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'qrproduct.dashboard.landing.video' }),
          key: 'productVideo',
          type: 'url',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.video.title',
          }),
          key: 'productVideoTitle',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.video.description',
          }),
          key: 'productVideoDescription',
          type: 'textarea',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({
            id: 'qrproduct.dashboard.landing.video.thumbnail',
          }),
          key: 'productVideoThumbnail',
          type: 'image',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'LandingProductDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default LandingProductDashboard;
