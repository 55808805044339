import { searchTypes } from './searchTypes';
import { SearchActionTypes } from './searchActions';
import { IGroupField } from '../formInterfaces';
import { IRow } from '../../app/AppInterfaces';

export interface GlobalSearch {
  title: string;
  key: string;
  type: string;
  fieldTargets: string[];
}

interface search {
  targetId: string;
  fieldsConfig: IGroupField[];
  globalSearchField: GlobalSearch;
  values: IRow;
  formHasChanged: boolean;
  getDataTrigger: boolean;
  filterVisible: boolean;
  resetFormTrigger: boolean;
}

export interface SearchState {
  [key: string]: search;
}
const initialState: SearchState = {};

export function searchReducer(
  state = initialState,
  action: SearchActionTypes
): any {
  switch (action.type) {
    case searchTypes.SET_INITIAL_SEARCH_STATE:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case searchTypes.SEARCH_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {
          targetId: action.payload.targetId,
          fieldsConfig: action.payload.fieldsConfig,
          globalSearchField: action.payload.globalSearchField,
          values: action.payload.values,
          formHasChanged: action.payload.formHasChanged,
          getDataTrigger: false,
          filterVisible: false,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case searchTypes.SEARCH_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case searchTypes.SEARCH_SET_VALUES:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values
        }
      };
    case searchTypes.SEARCH_SET_INITIAL_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          getDataTrigger: action.payload.value
        }
      };
    case searchTypes.SEARCH_SET_FIELDS_CONFIG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          fieldsConfig: action.payload.newFieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case searchTypes.SEARCH_RESET_FORM:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values,
          fieldsConfig: action.payload.fieldsConfig,
          formHasChanged: false,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case searchTypes.SEARCH_SET_FORM_HAS_CHANGED:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          formHasChanged: action.payload.formHasChanged
        }
      };
    case searchTypes.SEARCH_CHANGE_TAB:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedTab: action.payload.selectedTab
        }
      };
    case searchTypes.SEARCH_CLEAN_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case searchTypes.SEARCH_FILTER_VISIBLE:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          filterVisible: action.payload.filterVisible
        }
      };
    default:
      return state;
  }
}
