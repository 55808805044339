import { Button, Modal, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { IRow } from '../app/AppInterfaces';
import WebeatAdminPoints from './AdminPoints/WebeatAdminPoints';
import { UpdateRecordProps } from './Challenge';
import { SetChallengeLoading } from './challengeActions';
import { IResource } from './ChallengeInterfaces';

interface Props {
  form: WrappedFormUtils<any>;
  selectedRow: IRow;
  buttonDisabled: boolean;
  constructResources: (resources: IRow) => IResource[];
  formHasChanged: boolean;
  handleResetChallenge: () => void;
  handleUpdateRecord: (
    e: React.MouseEvent,
    props: UpdateRecordProps,
    form: WrappedFormUtils<any>,
  ) => Promise<void>;
  isLoading: boolean;
  setLoading: (
    isLoading: boolean,
  ) => (dispatch: Dispatch<SetChallengeLoading>) => void;
  sortingResources: boolean;
  updateProps: UpdateRecordProps;
  refreshChallengeData: () => Promise<void>;
  isExperienceProduct: boolean;
}

export default function WebeatChallengeHeaderButtons(props: Props) {
  const [pointsModalVisible, setPointsModalVisible] = useState(false);

  const intl = useIntl();

  const {
    buttonDisabled,
    constructResources,
    form,
    formHasChanged,
    handleResetChallenge,
    handleUpdateRecord,
    isLoading,
    selectedRow,
    setLoading,
    sortingResources,
    updateProps,
    refreshChallengeData,
    isExperienceProduct,
  } = props;

  const handleAdminPoints = (visible: boolean) => {
    setPointsModalVisible(visible);
  };

  const handleReset = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        handleResetChallenge();
        form.resetFields();
      },
      onCancel() {},
    });
  };

  return (
    <Fragment>
      <WebeatAdminPoints
        {...{
          constructResources,
          isLoading,
          pointsModalVisible,
          selectedRow,
          setPointsModalVisible,
          setLoading,
          refreshChallengeData,
        }}
      />
      <Row className="ChallengeBuilder__headerButtons">
        {!isExperienceProduct ? (
          <Button
            className="ChallengeBuilder__pointsButton"
            type="primary"
            onClick={() => handleAdminPoints(true)}
            loading={isLoading}
            disabled={formHasChanged || buttonDisabled || sortingResources}>
            {intl.formatMessage({ id: 'challenge.button.points' })}
          </Button>
        ) : null}
        <Button
          className="ChallengeBuilder__saveButton"
          type="primary"
          onClick={(e: React.MouseEvent) =>
            handleUpdateRecord(e, updateProps, form)
          }
          loading={isLoading}
          disabled={!formHasChanged || buttonDisabled || sortingResources}>
          {intl.formatMessage({ id: 'form.save' })}
        </Button>
        <Button
          className="ChallengeBuilder__restoreButton"
          type="primary"
          onClick={(e: React.MouseEvent) => handleReset()}
          loading={isLoading}
          disabled={!formHasChanged || buttonDisabled || sortingResources}>
          {intl.formatMessage({ id: 'form.reset' })}
        </Button>
      </Row>
    </Fragment>
  );
}
