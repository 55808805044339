import { Row, Button, Icon } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { ITransactionDetails } from './massPointsManagement.interface';
import Text from 'antd/lib/typography/Text';

export interface IUploadEmailsButtonProps {
  emails: ITransactionDetails['emails'];
  onChange: (emails: string[]) => void;
}
export function UploadEmailsButton({
  emails,
  onChange,
}: IUploadEmailsButtonProps) {
  const intl = useIntl();

  const handleCsvUpload = (e: Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.addEventListener('load', (e) => {
      const content = e.target?.result;
      if (typeof content !== 'string') return;

      const emails = content
        .split(/(?:;.*)?(?:\r\n|\r|\n)/)
        .filter((email) => email);
      onChange(emails);
    });
  };

  const uploadCsv = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.addEventListener('change', handleCsvUpload);
    input.click();
  };

  return (
    <>
      <Row>
        <Button
          onClick={uploadCsv}
          type={emails.validationError ? 'danger' : undefined}
        >
          <Icon type="upload" />{' '}
          {intl.formatMessage({
            id: 'points.management.bulk.modal.input.upload-csv.button'
          })}
        </Button>
        {emails.value &&
          ` ${intl.formatMessage(
            { id: 'points.management.button.modal.input.emails.helper' },
            { numEmails: emails.value.length },
          )}`}
      </Row>
      <Row style={{ fontSize: '0.8rem' }}>
        {intl.formatMessage({
          id: 'points.management.bulk.modal.input.upload-csv.helper',
        })}
      </Row>
      <Row>
        {emails.validationError && (
          <Text type="danger">{emails.validationError}</Text>
        )}
      </Row>
    </>
  );
}
