import { Button } from 'antd';
import React from 'react';
import {
  IPageComponent,
  IPageDetail,
  isChallengeComponent,
  PageComponentChallenge,
  PageComponentEnum,
  PageDetailEnum,
} from '../shared';

const {
  TEXT,
  CAROUSEL,
  BANNER,
  FAQ,
  LINKED_IMAGE,
  PRODUCT_SECTION,
  VIDEO_SECTION,
  WYSWYG,
  REDEEM_POINTS,
  CHALLENGE_TYPE,
  USER_POINTS,
  WEBEAT_CTA,
  WEBEAT_TEXT,
  WEBEAT_FAQ,
} = PageComponentEnum;

const {
  IMAGE,
  PRODUCT_ITEM,
  TEXT: TEXT_DETAIL,
  LINK,
  VIDEO,
  WYSWYG: WYSWYG_DETAIL,
  PRODUCT: PRODUCT_LINK,
  USER_POINTS: USER_POINTS_DETAIL,
  FAQ_ITEM,
  WEBEAT_CTA_DETAIL,
  MARKDOWN,
  WEBEAT_FAQ_DETAIL,
} = PageDetailEnum;

const MAX_BANNER_ITEMS = 1,
  MAX_LINKED_IMAGES = 1,
  MAX_LINKED_TEXTS = 1,
  MAX_VIDEO_ITEMS = 1,
  MAX_WEBEAT_CTA = 1,
  MAX_WEBEAT_TEXT = 1,
  MAX_WEBEAT_FAQ = 25,
  MAX_TEXTS = 1,
  MAX_CHALLENGE_ITEMS = 3,
  MAX_CAROUSEL_ITEMS = 6,
  MIN_REDEEM_POINTS_PRODUCTS = 4;

export const MAX_FAQ_ITEMS = 25;

interface Props {
  component: IPageComponent;
  handleAddDetail: (component: IPageComponent, type: number) => void;
  formatMessage: any;
}

export const componentButtons = ({
  handleAddDetail,
  component,
  formatMessage,
}: Props) => {
  let { type, componentDetails } = component;

  const getComponentElements = (
    componentDetails: IPageDetail[],
    types: number[],
  ): IPageDetail[] => {
    return componentDetails.filter(
      (detail) => detail.status && types.includes(detail.type),
    );
  };

  const getMaxChallengeItems = (componentDetails: IPageDetail[]) => {
    return component.type.toString() ===
      PageComponentChallenge.EXPERIENCE_COMPONENT.toString()
      ? false
      : getComponentElements(componentDetails, [LINK]).length >=
          MAX_CHALLENGE_ITEMS;
  };

  const getButtonName = (type: number) => {
    let name = '';

    switch (type) {
      case IMAGE:
        name = 'contentPage.button.add-image';
        break;
      case TEXT_DETAIL:
        name = 'contentPage.button.add-text';
        break;
      case LINK:
        name = 'contentPage.button.add-challenge';
        break;
      case WYSWYG:
        name = 'contentPage.button.add-custom-html';
        break;
      case VIDEO:
        name = 'contentPage.button.add-video';
        break;
      case WEBEAT_CTA_DETAIL:
        name = 'contentPage.button.add-cta';
        break;
      case MARKDOWN:
        name = 'contentPage.button.add-text-markdown';
        break;
      case WEBEAT_FAQ_DETAIL:
        name = 'contentPage.button.add-text-faq';
        break;
      case PRODUCT_LINK:
        name = 'contentPage.button.add-product';
        break;
      case PRODUCT_ITEM:
        name = 'contentPage.button.add-product';
        break;
      case USER_POINTS_DETAIL:
        name = 'contentPage.button.add-points';
        break;
      case FAQ_ITEM:
        name = 'contentPage.button.add-faq';
    }

    return name;
  };

  const availableDetails: { type: number; disabled: boolean }[] = [];

  if (isChallengeComponent(type)) type = CHALLENGE_TYPE;

  switch (type) {
    case BANNER:
      let imageDisabled =
        getComponentElements(componentDetails, [IMAGE]).length >=
        MAX_BANNER_ITEMS;
      let bannerTextDisabled =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push(
        { type: IMAGE, disabled: imageDisabled },
        { type: TEXT_DETAIL, disabled: bannerTextDisabled },
      );
      break;

    case CAROUSEL:
      let mediaDisabled =
        getComponentElements(componentDetails, [IMAGE, VIDEO]).length >=
        MAX_CAROUSEL_ITEMS;
      availableDetails.push(
        { type: IMAGE, disabled: mediaDisabled },
        { type: VIDEO, disabled: mediaDisabled },
      );
      break;

    case LINKED_IMAGE:
      let linkedImageDisabled =
        getComponentElements(componentDetails, [IMAGE]).length >=
        MAX_LINKED_IMAGES;
      let linkedTextDisabled =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_LINKED_TEXTS;
      availableDetails.push(
        { type: IMAGE, disabled: linkedImageDisabled },
        { type: TEXT_DETAIL, disabled: linkedTextDisabled },
      );
      break;

    case TEXT:
      let textDetailDisabled =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push({
        type: TEXT_DETAIL,
        disabled: textDetailDisabled,
      });
      break;

    case USER_POINTS:
      let userPointsContentDisabled =
        getComponentElements(componentDetails, [USER_POINTS_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push({
        type: USER_POINTS_DETAIL,
        disabled: userPointsContentDisabled,
      });
      break;

    case CHALLENGE_TYPE:
      let challengeDisabled = component.featured
        ? true
        : getMaxChallengeItems(componentDetails);
      let challengeTextDisabled =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push(
        { type: LINK, disabled: challengeDisabled },
        { type: TEXT_DETAIL, disabled: challengeTextDisabled },
      );
      break;

    case WYSWYG:
      let wysDisabled =
        getComponentElements(componentDetails, [WYSWYG_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push({ type: WYSWYG_DETAIL, disabled: wysDisabled });
      break;

    case PRODUCT_SECTION:
      let _productDescription =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_TEXTS;
      let _productItems = component.featured
        ? true
        : getMaxChallengeItems(componentDetails);
      availableDetails.push(
        { type: TEXT_DETAIL, disabled: _productDescription },
        { type: PRODUCT_ITEM, disabled: _productItems },
      );
      break;

    case REDEEM_POINTS:
      let _disabled =
        getComponentElements(componentDetails, [PRODUCT_LINK]).length >=
        MIN_REDEEM_POINTS_PRODUCTS;
      let challengeTextDisabledRedeem =
        getComponentElements(componentDetails, [TEXT_DETAIL]).length >=
        MAX_TEXTS;
      availableDetails.push(
        { type: PRODUCT_LINK, disabled: _disabled },
        { type: TEXT_DETAIL, disabled: challengeTextDisabledRedeem },
      );
      break;

    case VIDEO_SECTION:
      let _videoDisabled =
        getComponentElements(componentDetails, [VIDEO]).length >=
        MAX_VIDEO_ITEMS;
      if (!_videoDisabled)
        availableDetails.push({ type: VIDEO, disabled: _videoDisabled });
      break;

    case WEBEAT_CTA:
      let _ctaDisabled =
        getComponentElements(componentDetails, [WEBEAT_CTA_DETAIL]).length >=
        MAX_WEBEAT_CTA;
      if (!_ctaDisabled)
        availableDetails.push({
          type: WEBEAT_CTA_DETAIL,
          disabled: _ctaDisabled,
        });
      break;

    case WEBEAT_TEXT:
      let _textDisabled =
        getComponentElements(componentDetails, [MARKDOWN]).length >=
        MAX_WEBEAT_TEXT;
      if (!_textDisabled)
        availableDetails.push({
          type: MARKDOWN,
          disabled: _textDisabled,
        });
      break;

    case WEBEAT_FAQ:
      let _faqsDisabled =
        getComponentElements(componentDetails, [WEBEAT_FAQ_DETAIL]).length >=
        MAX_WEBEAT_FAQ;
      if (!_faqsDisabled)
        availableDetails.push({
          type: WEBEAT_FAQ_DETAIL,
          disabled: _faqsDisabled,
        });
      break;

    case FAQ:
      let _faqDisabled =
        getComponentElements(componentDetails, [FAQ_ITEM]).length >=
        MAX_FAQ_ITEMS;
      availableDetails.push({ type: FAQ_ITEM, disabled: _faqDisabled });
      break;
    default:
      break;
  }

  return availableDetails.map((detail, i: number) => (
    <Button
      className="Component__bottomButtons"
      disabled={detail.disabled}
      key={i}
      type="primary"
      onClick={() => {
        handleAddDetail(component, detail.type);
      }}>
      {formatMessage({ id: getButtonName(detail.type) })}
    </Button>
  ));
};
