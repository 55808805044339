import React, { FC } from 'react';
import { ScanLandingFormProps } from '../ScanLandingForm';

export interface RepeatedConsumablePlatformsModel {
  id: string;
  name: string;
  consumableId: number;
  platform: string;
}
export interface ScanLandingValidationModalProps
  extends Pick<ScanLandingFormProps, 'intl' | 'consumablesCombo'> {
  repeatedConsumablePlatforms: Array<RepeatedConsumablePlatformsModel>;
}

const ScanLandingValidationModal: FC<ScanLandingValidationModalProps> = ({
  intl,
  consumablesCombo,
  repeatedConsumablePlatforms,
}) => {
  const { formatMessage } = intl;
  const literalNumber =
    repeatedConsumablePlatforms.length > 1 ? 'plural' : 'singular';

  return (
    <div style={{ marginTop: '24px' }}>
      <p>
        {formatMessage({
          id: `scanLanding.validation.modal.intro.${literalNumber}`,
        })}
      </p>
      <ul
        style={{
          maxHeight: '350px',
          overflowX: 'hidden',
          overflowY: 'auto',
          marginBottom: '24px',
        }}>
        {repeatedConsumablePlatforms.map((row) => (
          <li>
            {formatMessage(
              { id: 'scanLanding.validation.modal.list' },
              {
                scanLandingId: row.id,
                scanLandingName: row.name,
                consumableName: consumablesCombo.find(
                  (consumable) => Number(consumable.value) === row.consumableId,
                )?.description,
                platform: row.platform,
                b: (chunks: string) => <b>{chunks}</b>,
              },
            )}
          </li>
        ))}
      </ul>
      <p>
        {formatMessage({
          id: `scanLanding.validation.modal.closing.${literalNumber}`,
        })}
        <br />
        {formatMessage({ id: 'scanLanding.validation.modal.confirmation' })}
      </p>
    </div>
  );
};

export default ScanLandingValidationModal;
