import { IMethods } from '../customDashboards';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { ISmartRenderProps } from '../../tables/SmartTableRender';

function editMethods(parentProps: EditFormRenderProps): EditFormRenderProps {
  return { ...parentProps };
}

function searchMethods(
  parentProps: SearchFormRenderProps
): SearchFormRenderProps {
  return { ...parentProps };
}

function tableMethods(parentProps: ISmartRenderProps): ISmartRenderProps {
  return { ...parentProps };
}

const methods: IMethods = {
  search: searchMethods,
  table: tableMethods,
  edit: editMethods
};

export default methods;
