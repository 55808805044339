import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './Login.css';
import { Modal, Input, Form, Button } from 'antd';
import config from '../config';
import { setModal } from './authActions';
import { ReducersState } from '../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../app/AppInterfaces';
import { FormComponentProps } from 'antd/lib/form/Form';
import { feedback } from '../utils/feedback';
import apiPaths from '../apiPaths';
import * as api from '../api';

const ButtonGroup = Button.Group;
const FormItem = Form.Item;

interface OwnProps {
  intl: any;
}

type IForgotModalListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  FormComponentProps &
  OwnProps;

class ForgotModal extends React.PureComponent<IForgotModalListProps, {}> {
  handleSubmitModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { form, setModal } = this.props;
    form.validateFields(async (err: any, values: IRow) => {
      if (!err) {
        try {
          await api.recoveryPasswordCall(
            apiPaths.AUTH.RECOVERY,
            values.mailLogin
          );
          feedback({
            type: 'modal',
            action: 'recovery',
            title: 'recovery.confirm',
            message: 'recovery.msg'
          });
          setModal({ visible: false });
          this.props.form.setFieldsValue({ mailLogin: '' });
        } catch (err) {
          return feedback({
            type: 'notification',
            action: 'recovery',
            method: 'error',
            title: 'error.title',
            message: 'recovery.error'
          });
        }
      }
    });
  };

  showModal = () => {
    const { setModal } = this.props;
    setModal({
      visible: true
    });
  };

  handleCancel = () => {
    const { setModal } = this.props;
    setModal({
      visible: false
    });
  };

  render() {
    const { intl, form, visible } = this.props;
    const { getFieldDecorator } = form;

    const forgotPassword = config.LOGIN.FORGOT_PASSWORD && (
      <Button
        className="buttonForgotPassword"
        type="link"
        onClick={this.showModal}>
        {intl.formatMessage({ id: 'login.forgot' })}
      </Button>
    );
    return (
      <div>
        {forgotPassword}
        <Modal
          className="forgotPassword"
          visible={visible}
          footer={false}
          onCancel={this.handleCancel}
          title={intl.formatMessage({ id: 'password.recovery' })}>
          <p>{intl.formatMessage({ id: 'password.email.message' })}</p>
          <Form onSubmit={this.handleSubmitModal}>
            <FormItem>
              {getFieldDecorator('mailLogin', {
                rules: [
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'password.email' })
                  },
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'password.email.null' })
                  }
                ]
              })(<Input />)}
            </FormItem>
            <ButtonGroup
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px'
              }}>
              <Button onClick={this.handleCancel}>
                {intl.formatMessage({ id: 'pop.cancel' })}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginLeft: '10px' }}>
                {intl.formatMessage({ id: 'pop.accept' })}
              </Button>
            </ButtonGroup>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: ReducersState) => {
  return {
    visible: state.auth.visible
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ setModal }, dispatch);

const injectedForgotModal = injectIntl(Form.create()(ForgotModal));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectedForgotModal);
