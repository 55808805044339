import React from 'react';

export default function label(props) {
  return (
    <svg
      enable-background="new 0 0 32 32"
      version="1.1"
      viewBox="0 0 32 32">
      <g id="Layer_1" />
      <g id="Layer_2">
        <g>
          <polygon
            fill="none"
            points="    2,20 18,4 28,4 28,14 12,30   "
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <circle
            cx="23"
            cy="9"
            fill="none"
            r="2"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2"
            x1="24"
            x2="30"
            y1="8"
            y2="2"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2"
            x1="17"
            x2="9"
            y1="11"
            y2="19"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2"
            x1="21"
            x2="17"
            y1="15"
            y2="19"
          />
        </g>
      </g>
    </svg>
  );
}
