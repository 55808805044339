import React from 'react';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import I18nComponent from './I18nComponent';
import apiPaths from '../../apiPaths';
import { isTableProps } from '../../utils/propsUtils';


export default function MenuI18nButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, params } = tableProps.props;
    const { rowKey } = params;

    const path = apiPaths.CALL.MENUI18N;
    const id = selectedRow[rowKey];
    const originalValue = selectedRow.name;
    const i18Key = 'idMenuI18n';

    return I18nComponent({
      i18Key,
      id,
      originalValue,
      path,
      rowKey,
      selectedRow
    });
  }
  return <></>;
}
