import { Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { JSX, useCallback, useEffect, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import * as api from '../api';

import '../forms/FormStyles.css';
import CustomSearch from './CustomSearch';
import CustomTable from './CustomTable';
import './IsdinCustom.css';

import { IRecord } from '../app/AppInterfaces';
import { UserStatus } from './Shared';
import { ExportInformation } from './ExportInformation';

interface Props {
  intl: typeof InjectedIntlProps;
  columns: ColumnProps<IRecord>[];
  customButtons?: Array<
    (
      selectedRow: IRecord,
      loading: boolean,
      reloadData: () => Promise<void>,
    ) => JSX.Element
  >;
  path: string;
  rowKey: string;
  searchField: string;
  entityKey: string;
  searchPlaceHolder?: string;
  filter?: string;
  isPinEmbraceUsers?: boolean;
  isPinEmbraceCenters?: boolean;
  hideSearch?: boolean;
  hideTableArrows?: boolean;
  exportButton?: boolean;
  afterResponse?: (response:any) => any;
}

export const CUSTOM_DEFAULT_SIZE = 50;

const getDefaultSortName = (columns: ColumnProps<IRecord>[]) => {
  const defaultColumn = columns.filter((column) => column.defaultSortOrder);
  return defaultColumn[0].dataIndex || '';
};

export function CustomDashboard({
  columns,
  customButtons,
  entityKey,
  intl,
  path,
  rowKey,
  searchField,
  searchPlaceHolder,
  filter = '',
  isPinEmbraceUsers = false,
  isPinEmbraceCenters = false,
  exportButton,
  hideSearch = false,
  hideTableArrows = false,
  afterResponse,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IRecord[]>([]);
  const [selectedRow, setSelectedRow] = useState<IRecord>({});

  const [query, setQuery] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState<string>(UserStatus.ACTIVE);
  const [page, setPage] = useState(0);
  let statusUsers = '';
  const [sort, setSort] = useState<{ name: string; order: 'ASC' | 'DESC' }>({
    name: getDefaultSortName(columns),
    order: 'ASC',
  });

  const defaultOffset = { page: 0, offset: '' };

  const [savedOffsets, setSavedOffset] = useState<
    { page: number; offset: string }[]
  >([defaultOffset]);

  useEffect(() => {
    refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async ({
                                  params,
                                  showMore,
                                  showLess,
                                  reset,
                                  isSubmit,
                                }: {
    params: string;
    showMore?: boolean;
    showLess?: boolean;
    reset?: boolean;
    isSubmit?: boolean;
  }) => {
    try {
      setLoading(true);

      let response = await api.getDataCall({
        dataPath: `${path}/${params}`,
        callConfig: {},
      });
      if (afterResponse) response = afterResponse(response);
      if (Array.isArray(response.data?.[entityKey]))
        setDataSource(response.data[entityKey]);

      if (response.data?.offset) {
        const fetchedOffset: string = response.data.offset;

        if (isSubmit) {
          let newOffsets = savedOffsets.slice();

          newOffsets[newOffsets.length - 1].offset = fetchedOffset;

          setSavedOffset(newOffsets);
        }

        if (reset) {
          setSavedOffset([{ page: 0, offset: fetchedOffset }]);
        }

        if (showMore) {
          const newPage: number = page + 1;

          setSavedOffset([
            ...savedOffsets,
            { page: newPage, offset: fetchedOffset },
          ]);
        }

        if (showLess) {
          const newOffsets = JSON.parse(JSON.stringify(savedOffsets));

          newOffsets.splice(savedOffsets.length - 1, 1);

          setSavedOffset(newOffsets);
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [entityKey, page, path, savedOffsets, afterResponse]);

  const realSearchField = React.useMemo(() => {
    let result = searchField;
    if (isPinEmbraceUsers) {
      if (filterType !== '') result = `user${filterType}`;
    }

    if (isPinEmbraceCenters) {
      if (filterType !== '') result = `center${filterType}`;
    }
    return result;
  }, [searchField, filterType, isPinEmbraceUsers, isPinEmbraceCenters])

  const getParams = ({
    newSort,
    offset,
    page = 0
  }: {
    newSort?: { name: string; order: 'ASC' | 'DESC' };
    offset: string;
    page: number;
  }) => {
    const order = newSort?.order ?? sort.order;
    const name = newSort?.name ?? sort.name;

    if (isPinEmbraceUsers) {
      statusUsers = filterStatus === UserStatus.ACTIVE ? 'true' : 'false';
    }

    return `?q=${filter}${realSearchField}:${encodeURIComponent(
      query,
    )}&sort=${order}&field=${name}&size=${CUSTOM_DEFAULT_SIZE}&offset=${offset}&platformStatus=${statusUsers}&page=${page}`;
  };

  const handleSearchMore = async () => {
    const newPage = page + 1;

    const params = getParams({
      offset: savedOffsets[savedOffsets.length - 1].offset,
      page: newPage
    });
    await fetchData({ params, showMore: true });
    setPage(newPage);
  };

  const handleSearchLess = async () => {
    const newPage = page - 1;

    const newOffset =
      savedOffsets.length >= 3
        ? savedOffsets[savedOffsets.length - 3].offset
        : '';

    const params = getParams({
      offset: newOffset,
      page: newPage
    });

    await fetchData({ params, showLess: true });

    setPage(newPage);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    await refreshGrid();
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: {
      field: string;
      order: string;
    },
  ) => {
    const field: string = sorter.field;
    const order: string = sorter.order;

    setPage(0);
    setSavedOffset([defaultOffset]);

    const newSort: { name: string; order: 'ASC' | 'DESC' } = {
      name: field,
      order: order === 'ascend' ? 'ASC' : 'DESC',
    };

    setSort({ ...newSort });

    const params = getParams({ newSort, offset: '', page: 0 });
    await fetchData({ params, reset: true });
  };

  const refreshGrid = async () => {
    const offset =
      savedOffsets.length > 1
        ? savedOffsets[savedOffsets.length - 1].offset
        : '';

    const params = getParams({
      offset,
      page: 0
    });

    await fetchData({ params, isSubmit: true });
  };


  return (
    <Row>

      {!hideSearch ? <CustomSearch
        {...{
          intl,
          query,
          setQuery,
          handleSubmit,
          searchPlaceHolder,
          setFilterType,
          setFilterStatus,
          isPinEmbraceUsers,
          isPinEmbraceCenters
        }}
      /> : null}

      {customButtons && (
        <Row style={{ marginBottom: '10px' }}>
          {customButtons.map((button) =>
            button(selectedRow, loading, refreshGrid),
          )}
          {exportButton &&
            ExportInformation(
              dataSource,
              path,
              columns,
              searchField,
              filter,
              entityKey,
              query,
              realSearchField,
              afterResponse
            )}
        </Row>
      )}

      <CustomTable
        {...{
          columns,
          dataSource,
          handleTableChange,
          loading,
          page,
          rowKey,
          selectedRow,
          setSelectedRow,
          handleSearchMore,
          handleSearchLess,
          hideTableArrows
        }}
      />
    </Row>
  );
}
