export enum searchTypes {
  SEARCH_SET_VALUES = '[SEARCH] set values',
  SEARCH_INITIAL_STATE = '[SEARCH] set initial state',
  SEARCH_SET_FIELDS_CONFIG = '[SEARCH] set new fields config',
  SET_INITIAL_SEARCH_STATE = '[SEARCH] set initial component state',
  SEARCH_SET_INITIAL_DATA = '[SEARCH] set initial table data',
  SEARCH_RESET_FORM = '[SEARCH] reset search form',
  SEARCH_SET_FORM_HAS_CHANGED = '[SEARCH] set form has changed',
  SEARCH_CLEAN_RESET_COMPONENT = '[SEARCH] reset clean component',
  SEARCH_CHANGE_TAB = '[SEARCH] set search selected tab',
  SEARCH_RESET_COMPONENT = '[SEARCH] reset search form component',
  SEARCH_FILTER_VISIBLE = '[SEARCH] set filter visible'
}
