import React from 'react';
export default function challenge() {
  return (
    <>
      <svg viewBox="-67 0 512 512.00101" className="svgIconFill">
        <path d="m188.96875 107.972656c-50.023438 0-90.722656 40.695313-90.722656 90.71875 0 50.027344 40.699218 90.722656 90.722656 90.722656s90.71875-40.695312 90.71875-90.722656c0-50.023437-40.695312-90.71875-90.71875-90.71875zm0 0" />
        <path d="m377.933594 260.089844-27.511719-61.398438 27.511719-61.398437-58.34375-33.503907-13.832032-65.84375-66.898437 7.191407-49.890625-45.136719-49.894531 45.136719-66.894531-7.1875-13.832032 65.84375-58.347656 33.5 27.511719 61.398437-27.511719 61.398438 58.347656 33.503906 7.492188 35.671875-62.28125 132.339844 65 .851562 42.085937 49.542969 61.21875-130.089844 17.105469 15.472656 17.101562-15.472656 61.222657 130.089844 42.082031-49.542969 65-.851562-62.28125-132.339844 7.496094-35.671875zm-188.964844 59.320312c-66.566406 0-120.71875-54.152344-120.71875-120.71875s54.152344-120.71875 120.71875-120.71875c66.5625 0 120.71875 54.15625 120.71875 120.71875 0 66.566406-54.15625 120.71875-120.71875 120.71875zm0 0" />
      </svg>
    </>
  );
}
